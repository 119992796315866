import { normalize, schema } from 'normalizr';

//const contract_room = new schema.Entity('contract_room');
const contract = new schema.Entity('contract' /*  { id: [contract_room] } */);
const contract_room_price = new schema.Entity('contract_room_price');
const update_room_prices = new schema.Entity(
  'contract_room_price',
  /*  {},
  {
    idAttribute: 'id',
    processStrategy: (value, parent, key) => {
      return { ...value, price: value.price };
    },
  }, */
);
const UpdatedPriceState = new schema.Entity(
  'contract_room_price',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

const contract_room = new schema.Entity('contract_room', {
  contract_room_price: [contract_room_price],
});

const updated_contract_room = new schema.Entity('contract_room', {
  contract_room_price: [update_room_prices],
});
const response = {
  contract_room: [contract_room],
  contract: [contract],
  priceState: [contract_room_price],
  state_contract_room: [contract_room],
  updated_contract_room: [updated_contract_room],
  UpdatedPriceState: [UpdatedPriceState],
};

export const normalizeContractRooms = data => normalize(data, [response]);
