import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../../Common/formElements/AppTextInput";
import GridContainer from "../../GridContainer";
// import AppSelectBox from "../../../../@jumbo/components/Common/formElements/AppSelectBox";
// import TextField from "@material-ui/core/TextField";
import { editAdminUserService } from "services/user";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, red } from "@material-ui/core/colors";
import Update from "@mui/icons-material/Update";
import {
  loginUser,
  companyData,
  // officeMemo,
  // get_destinations,
} from "redux/selector/companyHasOfficeSelector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import Checkbox from "@mui/material/Checkbox";

import { isTurkish } from "@jumbo/utils/isTurkish";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
  },
  dark: {
    "& .MuiChip-label": {
      color: "white",
    },
  },
  light: {
    "& .MuiChip-label": {
      color: "#000", // black
    },
  },
}));

export default function UpdateUserDialog({ open, setOpen, userData }) {
  const theme = createTheme({
    palette: {
      primary: blue,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });

  // const themeMode = localStorage.getItem("theme-type");
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  /*   const [passwordConfirm, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState(''); */
  const authUser = useSelector(loginUser);

  const [userInfo, setUserInfo] = useState({
    fullname: userData.fullname,
    email: userData.email,
    isActive: userData.isActive,
  });
  const [errorData, setErrorData] = useState({
    fullname: "",
    email: "",
  });

  const [save, setSave] = useState(false);

  const { error_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { success_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const companies = useSelector(companyData);

  useEffect(() => {
    if (
      userInfo.fullname &&
      userInfo.email &&
      save &&
      error_message == "" &&
      success_message == "success"
    ) {
      MySwal.fire(
        "Success",
        intl.formatMessage({ id: "user.has.been.updated" }),
        "success"
      );
      setOpen(false);
    } else {
      if (error_message.email) {
        setErrorData({ ...errorData, email: error_message.email[0] });
      }
    }
  }, [error_message, success_message]);

  const updateUserModal = (updatedUser) => {
    Swal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage(
        {
          id: "you.want.to.update.user",
        },
        { name: updatedUser }
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
      confirmButtonText: intl.formatMessage({ id: "yes.update.it." }),
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmitClick();
      }
    });
  };

  const onSubmitClick = () => {
    const authorityLevel = userData.authority_level;
    const user_id = userData.id;
    const companyId = Object.values(companies)[0].id;
    const updateUser = {
      ...userInfo,
      user_id,
      authorityLevel,
      companyId,
    };

    if (!userInfo.fullname || !userInfo.email) {
      setErrorData({
        ...errorData,
        fullname: userInfo.fullname ? "" : requiredMessage,
        email: userInfo.email ? "" : requiredMessage,
      });
    }

    if (userInfo.fullname && userInfo.email) {
      if (userData.email === authUser.email && userInfo.isActive === false) {
        MySwal.fire(
          "Error",
          intl.formatMessage({ id: "you.cannot.deactivate.yourself" }),
          "error"
        );
      } else {
        dispatch(editAdminUserService(updateUser));
      }
      setSave(true);
    }
  };
  const onCancelClick = () => {
    setOpen(false);
  };
  const handleChange = (name) => (event) => {
    setUserInfo({ ...userInfo, [name]: event.target.value });
    setErrorData({ ...errorData, [name]: "" });
  };

  const handleError = (name) => {
    setErrorData({ ...errorData, [name]: requiredMessage });
    setUserInfo({ ...userInfo, [name]: "" });
  };
  const handleStatus = (event) => {
    setUserInfo({ ...userInfo, isActive: event.target.checked });
  };
  const isTrueEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-])+([.])+[a-zA-Z0-9.-]{2,4}$/.test(
      email
    );
  };
  // const isTurkishName = (name) => {
  //   return /^[A-Za-z\s]+$/.test(name);
  // };
  return (
    <Dialog
      open={open}
      className={classes.dialogRoot}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        Update {userData.fullname} User
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              label={<IntlMessages id="name" />}
              value={userInfo.fullname}
              required
              helperText={errorData.fullname}
              onChange={(e) => {
                if (e.target.value.trim().length != 0) {
                  handleChange("fullname")(e);
                } else {
                  handleError("fullname");
                }
              }}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              disabled={
                authUser.authority_level == 20 && authUser.id != userData.id
              }
              label={<IntlMessages id="email" />}
              value={userInfo.email}
              error={
                userInfo.email !== ""
                  ? isTrueEmail(userInfo.email)
                    ? false
                    : true
                  : true
              }
              onChange={(e) => {
                if (e.target.value.trim().length != 0) {
                  handleChange("email")(e);
                } else {
                  handleError("email");
                }
              }}
              required
              helperText={
                userInfo.email !== ""
                  ? isTrueEmail(userInfo.email)
                    ? errorData.email
                    : "Please enter a valid email with English characters"
                  : errorData.email
              }
            />
          </Grid>
        </GridContainer>
        {authUser.authority_level == 11 && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <Checkbox
                checked={userInfo.isActive}
                onChange={handleStatus}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                style={{ padding: 0 }}
              />
              <span style={{ marginLeft: 10 }}>Active</span>
            </Grid>
          </GridContainer>
        )}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                disabled={isTrueEmail(userInfo.email) ? false : true}
                startIcon={<Update />}
                onClick={() => updateUserModal(userData.fullname)}
              >
                <IntlMessages id="update" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
