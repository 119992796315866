import React, { useState, Suspense, lazy, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputBase from "@material-ui/core/InputBase";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { TableContainer, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getOfferService } from "services/offers";
import { getPriorityService } from "services/priority";
import { getBoardSupplamentService } from "services/boardSupplament";
import { MemoizedContractHotelDetails } from "./ContractHotelDetails";
import { MemoizedContractCompanyDetails } from "./ContractCompanyDetails";
import { MemoizedContractRoom } from "./ContractRoom";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { getAdditionalPaxService } from "services/additionalPax";

const Kickback = lazy(() => import("./Kickback"));
const AddPaxRates = lazy(() => import("./AddPaxRates"));
const Extras = lazy(() => import("./Extras"));
const Offers = lazy(() => import("./Offer/Offers"));
const Restrictions = lazy(() => import("./Restrictions/RestrictionsTable"));
const SpecialNotes = lazy(() => import("./SpecialNotes"));
const PaymentCondition = lazy(() => import("./PaymentCondition"));
const ContractTermsConditions = lazy(() =>
  import("./ContractTermsAndConditions/ContractTermsConditions")
);
const ContractCancellationPolicies = lazy(() =>
  import("./ContractCancellationPolicies/ContractCancelationPolicies")
);
const BoardSupplament = lazy(() => import("./BoardSupplament"));

const useStyles = makeStyles(theme => ({
  expand: {
    maxHeight: "30px",
    transform: "rotate(0deg)",
    textAlign: "left",
    marginLeft: "auto",
    fontSize: 11,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    maxHeight: "30px",
    fontSize: 11,
    transform: "rotate(180deg)"
  },
  root: {
    minWidth: "100%",
    fontSize: 11,
    height: "100%",
    padding: "12px"
  },
  bottomNavigate: {
    width: 400,
    fontSize: 11,
    backgroundColor: theme.palette.background.default
  },
  rootCard: {
    minWidth: 600,
    fontSize: 11,
    margin: "0 auto"
  },
  cardContent: {
    padding: 4,
    textAlign: "left",
    "&:last-child": {
      paddingBottom: 2
    }
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "48%",
    fontSize: 11,
    marginTop: "0.1%",
    marginBottom: "2%"
  },
  formControlTextField: {
    marginTop: "0.1%",
    margin: theme.spacing(4),
    fontSize: 11,
    minWidth: "94%"
  },
  title: {
    marginTop: "1%",
    fontSize: 11,
    margin: theme.spacing(4)
  },
  textStyle: {
    //margin: theme.spacing(2),
    //marginTop: '0.2%',
    fontSize: 11
  },
  cardActions: {
    fontSize: 11,
    maxWidth: "94%",
    display: "block",
    cursor: "pointer"
  },
  button: {
    fontSize: 11,
    margin: "0 auto",
    marginLeft: "10px"
  },
  addRoomButton: {
    backgroundColor: "cadetblue",
    fontSize: 10
  },
  buttonCard: {
    fontSize: 11,
    marginTop: "0.6%"
  },
  textField: {
    minWidth: 450
  },
  textFieldiforDetail: {
    minWidth: 450,
    maxHeight: 30
  },
  formInCard: {
    minWidth: 100,
    fontSize: 10,
    margin: "0 auto",
    maxHeight: "55px",
    align: "center"
  },
  numberColumns: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  collapse: {
    margin: "0 auto"
  },

  container: {
    paddingBottom: 4,
    paddingRight: 4,
    marginTop: 4,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    maxHeight: 400
  }
}));

export default function Contract(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOfferService(selectedContract));
    dispatch(getAdditionalPaxService(selectedContract));
    dispatch(getPriorityService(selectedContract));
    dispatch(getBoardSupplamentService(selectedContract));
  }, [dispatch]);
  const { selectedContract } = useSelector(({ contract }) => contract);

  const [productExpanded, setProductExpanded] = useState(false);
  const [officeExpanded, setOfficeExpanded] = useState(false);
  const [additionalPaxExpanded, setAdditionalPaxExpanded] = useState(false);
  const [kickbackExpanded, setKickbackExpanded] = useState(false);
  const [hotelExtrasExpanded, setHotelExtrasExpanded] = useState(false);
  const [offersExpanded, setOffersExpanded] = useState(false);
  const [boardSupplementExpanded, setBoardSupplementExpanded] = useState(false);
  const [restrictionsExpanded, setRestrictionsExpanded] = useState(false);
  const [specialConExpanded, setSpecialConExpanded] = useState(false);
  const [paymentConExpanded, setPaymentConExpanded] = useState(false);
  const [termsConExpanded, setTermsConExpanded] = useState(false);
  const [cancelPolExpanded, setCancelPolExpanded] = useState(false);

  const { contracts } = useSelector(({ contract }) => contract);

  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.delete === 1
  );

  const { hotels } = useSelector(({ hotels }) => hotels);
  const selectedHotel = hotels[contracts[selectedContract].hotel_id];
  const handleExpandClick = () => {
    setProductExpanded(!productExpanded);
  };
  const handleExpandOfficeClick = () => {
    setOfficeExpanded(!officeExpanded);
  };
  const additionalPaxClick = () => {
    /* additionalPaxExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setAdditionalPaxExpanded(!additionalPaxExpanded);
  };

  const kickbackClick = () => {
    setKickbackExpanded(!kickbackExpanded);
  };

  const hotelExtrasClick = () => {
    /* hotelExtrasExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setHotelExtrasExpanded(!hotelExtrasExpanded);
  };
  const boardSupplementClick = () => {
    /* boardSupplementExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setBoardSupplementExpanded(!boardSupplementExpanded);
  };
  const offersClick = () => {
    /* offersExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setOffersExpanded(!offersExpanded);
  };
  const restrictionsClick = () => {
    /* restrictionsExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setRestrictionsExpanded(!restrictionsExpanded);
  };
  const specialConClick = () => {
    /* specialConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setSpecialConExpanded(!specialConExpanded);
  };
  const cancelPolClick = () => {
    /* specialConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setCancelPolExpanded(!cancelPolExpanded);
  };
  const paymentCondOnCLick = () => {
    /* paymentConExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setPaymentConExpanded(!paymentConExpanded);
  };

  const termsConOnCLick = () => {
    /* termsConditionsExpanded True olduğunda ilgili componente gidecek card içerisinde o görüntülenecek */
    setTermsConExpanded(!termsConExpanded);
  };

  const intl = useIntl();

  return (
    <div className={classes.root}>
      <InputBase
        inputProps={{
          style: {
            marginTop: "0.1%",
            textAlign: "center",
            color:
              localStorage.getItem("theme-type") === "dark" ? "white" : "black"
          }
        }}
        defaultValue={intl.formatMessage({ id: "contract.details" })}
        fullWidth
        disabled
        margin="dense"
      />

      <MemoizedContractHotelDetails selectedHotel={selectedHotel} />
      <MemoizedContractCompanyDetails
        contracts={contracts}
        selectedContract={selectedContract}
      />

      <TableContainer component={Paper}>
        <MemoizedContractRoom
          isAuthAdd={isAuthAdd}
          isAuthUpdate={isAuthUpdate}
          isAuthDelete={isAuthDelete}
        />
      </TableContainer>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={additionalPaxClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Additional Pax Rate" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: additionalPaxExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={additionalPaxExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {additionalPaxExpanded ? (
                <AddPaxRates
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>

      {contracts[selectedContract].contract_has_board.length > 1 ? (
        <Card className={classes.buttonCard + " visibleOverflow"}>
          <CardContent className={classes.cardContent}>
            <CardActions
              className={classes.cardActions}
              onClick={boardSupplementClick}
            >
              <Button className={classes.button} size="small">
                <IntlMessages id="Board Supplament" />
                <IconButton
                  className={
                    (classes.expand,
                    {
                      [classes.expandOpen]: boardSupplementExpanded
                    })
                  }
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Button>
            </CardActions>

            <Collapse in={boardSupplementExpanded} timeout="auto">
              {/* <BoardSupplament contractRooms={contractRooms} /> */}
              <Suspense fallback={<div>Loading...</div>}>
                {boardSupplementExpanded ? (
                  <BoardSupplament
                    isAuthAdd={isAuthAdd}
                    isAuthUpdate={isAuthUpdate}
                    isAuthDelete={isAuthDelete}
                  />
                ) : (
                  ""
                )}
              </Suspense>
            </Collapse>
          </CardContent>
        </Card>
      ) : (
        ""
      )}
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={offersClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="Offers" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: offersExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={offersExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {offersExpanded ? (
                <Offers
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={hotelExtrasClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Extras & Other Supplaments" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: hotelExtrasExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={hotelExtrasExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {hotelExtrasExpanded ? (
                <Extras
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>

      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={restrictionsClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Restrictions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: restrictionsExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={restrictionsExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {restrictionsExpanded ? (
                <Restrictions
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={kickbackClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="Kickbacks" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: kickbackExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={kickbackExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {kickbackExpanded ? (
                <Kickback
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions className={classes.cardActions} onClick={cancelPolClick}>
            <Button className={classes.button} size="small">
              <IntlMessages id="cancel.policies.details" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: cancelPolExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={cancelPolExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {cancelPolExpanded ? (
                <ContractCancellationPolicies
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={paymentCondOnCLick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Payment Conditions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: paymentConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={paymentConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {paymentConExpanded ? (
                <PaymentCondition
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={specialConClick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Special Conditions and Notes" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: specialConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={specialConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {specialConExpanded ? (
                <SpecialNotes
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.buttonCard + " visibleOverflow"}>
        <CardContent className={classes.cardContent}>
          <CardActions
            className={classes.cardActions}
            onClick={termsConOnCLick}
          >
            <Button className={classes.button} size="small">
              <IntlMessages id="Terms and Conditions" />
              <IconButton
                className={
                  (classes.expand,
                  {
                    [classes.expandOpen]: termsConExpanded
                  })
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </Button>
          </CardActions>
          <Collapse in={termsConExpanded} timeout="auto">
            <Suspense fallback={<div>Loading...</div>}>
              {termsConExpanded ? (
                <ContractTermsConditions
                  isAuthAdd={isAuthAdd}
                  isAuthUpdate={isAuthUpdate}
                  isAuthDelete={isAuthDelete}
                />
              ) : (
                ""
              )}
            </Suspense>
          </Collapse>
        </CardContent>
      </Card>
    </div>
  );
}

export const MemoizedContract = React.memo(Contract);
