import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Common from "./Common";
import Auth from "./Auth";
import allotmentUpdate from "./allotmentUpdate";
import hotels from "./hotels";
import markups from "./markups";
import Company from "./Company";
import boards from "./boards";
import productTypes from "./productTypes";
import stopSale from "./stopSale";
import contract from "./contract";
import rooms from "./rooms";
import destinations from "./destinations";
import AdminCompany from "./CompanyHasOffice";
import User from "./User";
import reservation from "./reservations";
import Permission from "./Permission";
import operators from "./operators";
import starRates from "./starRates";
import markets from "./market";
import currencies from "./currency";
import periods from "./periods";
import onSaleContract from "./onSaleContract";
import seasons from "./season";
import services from "./services";
import openSale from "./openSale";
import boardSupplament from "./boardSupplament";
import extras from "./extras";
import allotmentType from "./allotmentType";
import rateTypes from "./rateType";
import addPaxRates from "./addPaxRates";
import restrictions from "./restrictions";
import stayTypes from "./stayTypes";
import offerTypes from "./offerTypes";
import specialNotes from "./specialNotes";
import cancellationPoliciesDetail from "./cancellationPoliciesDetail";
import days from "./days";
import restrictionStatus from "./restrictionStatus";
import cincoutType from "./cincoutType";
import allotmentActionType from "./allotmentActionType";
import offers from "./offers";
import kickbacks from "./kickbacks";
import banks from "./banks";
import paymentMethods from "./paymentMethods";
import paymentConditions from "./paymentConditions";
import termsConditions from "./termsConditions";
import applyType from "./applyType";
import arrivalStay from "./arrivalStay";
import multiple from "./multiple";
import upgradeTypes from "./upgradeType";
import routes from "./routes";
import priority from "./priority";
import giataCodes from "./giataCodes";
import airportCode from "./airportCode";
import operatorHotels from "./operatorHotels";
import hotelMatch from "./hotelMatch";
import operatorRooms from "./operatorRooms";
import roomMatch from "./roomMatch";
import operatorContracts from "./operatorContracts";
import offerList from "./offerList";
import operatorGlobalRooms from "./operatorGlobalRooms";
import getReservationList from "./reservationList";
import hasLoaded from "./hasLoaded";
import versions from "./versions";
import mappedBoards from "./boardMap";

export default history =>
  combineReducers({
    router: connectRouter(history),
    addPaxRates,
    airportCode,
    allotmentActionType,
    allotmentType,
    allotmentUpdate,
    applyType,
    arrivalStay,
    auth: Auth,
    banks,
    boards,
    boardSupplament,
    cancellationPoliciesDetail,
    cincoutType,
    common: Common,
    company: Company,
    companyHasOffice: AdminCompany,
    contract,
    currencies,
    days,
    destinations,
    extras,
    giataCodes,
    hotels,
    hotelMatch,
    kickbacks,
    markets,
    markups,
    mappedBoards,
    multiple,
    offers,
    offerTypes,
    offerList,
    onSaleContract,
    openSale,
    operators,
    operatorHotels,
    operatorContracts,
    operatorRooms,
    operatorGlobalRooms,
    paymentConditions,
    paymentMethods,
    periods,
    permission: Permission,
    productTypes,
    priority,
    rateTypes,
    reservation,
    restrictions,
    restrictionStatus,
    rooms,
    roomMatch,
    routes,
    seasons,
    services,
    specialNotes,
    starRates,
    stayTypes,
    stopSale,
    termsConditions,
    upgradeTypes,
    user: User,
    getReservationList,
    hasLoaded,
    versions
  });
