import MaterialTable from "material-table";
import { Button, Grid, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MemoizedBotLogDetail } from "./BotLogDetail";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, blue, red, yellow } from "@material-ui/core/colors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingIcon from "@mui/icons-material/Pending";
import { MemoizedOfferDetailModal } from "./OfferDetailModal";

const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: blue[300],
    },
    inherit: red,
    default: yellow,
  },
});

function OfferTable() {
  const [openLogModal, setOpenLogModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [offerRowData, setOfferRowData] = useState({});
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { botOffers } = useSelector(({ offers }) => offers);

  const columns = [
    {
      title: "Hotel Name",
      field: "get_hotel.name",
      render: (rowData) => {
        return hotels[rowData.data.hotelId]?.name;
      },
    },
    {
      title: "Operator",
      field: "data.operators",
      render: (rowData) => {
        let opString = "";
        let operator = rowData.data.operators.map((item) => {
          return item.label;
        });
        opString = operator.toString();
        return opString;
      },
    },
    {
      title: "Processing Time",
      field: "createdAt",
      render: (rowData) => {
        return (
          new Date(rowData.createdAt).getFullYear() +
          "-" +
          ("0" + (new Date(rowData.createdAt).getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + new Date(rowData.createdAt).getDate()).slice(-2) +
          "  " +
          ("0" + new Date(rowData.createdAt).getHours()).slice(-2) +
          ":" +
          ("0" + new Date(rowData.createdAt).getMinutes()).slice(-2) +
          ":" +
          ("0" + new Date(rowData.createdAt).getSeconds()).slice(-2)
        );
      },
    },
  ];

  return (
    <div>
      <MemoizedBotLogDetail
        openLogModal={openLogModal}
        setOpenLogModal={setOpenLogModal}
        rowData={rowData}
        title="OFFER"
      />
      <MemoizedOfferDetailModal
        openDetailModal={openDetailModal}
        setOpenDetailModal={setOpenDetailModal}
        offerRowData={offerRowData}
        title="OFFER"
      />
      <Grid item xs={12}>
        <MaterialTable
          title="Offer Table"
          data={botOffers}
          columns={columns}
          options={{
            actionsColumnIndex: -1,
            search: false,
            addRowPosition: "first",
            paging: false,
          }}
          localization={{
            header: { actions: "   Status" },
          }}
          actions={[
            {
              icon: () => (
                <ThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    variant="outlined"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setOpenDetailModal(false)}
                  >
                    Detail
                  </Button>
                </ThemeProvider>
              ),
              onClick: (event, rowData) => {
                setOpenDetailModal(true);
                setOfferRowData(rowData.data.offers);
              },
            },
            (rowData) => ({
              icon: () => (
                <ThemeProvider theme={theme}>
                  <IconButton
                    color={
                      rowData.data.operators.every((t) => t.status == 302)
                        ? "primary"
                        : rowData.data.operators.every((t) => t.status == 300)
                        ? "default"
                        : "red"
                    }
                    variant="outlined"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setOpenLogModal(false)}
                  >
                    {rowData.data.operators.every((t) => t.status == 302)
                      ? rowData.data.operators.filter((t) => t.status == 302)
                          .length
                      : rowData.data.operators.filter((t) => t.status != 302)
                          .length}
                    /{rowData.data.operators.length}
                    {rowData.data.operators.filter((t) => t.status == 300)
                      .length > 0 ? (
                      <PendingIcon
                        fontSize="medium"
                        style={{ color: "#ff9800" }}
                      />
                    ) : rowData.data.operators.every((t) => t.status == 302) ? (
                      <CheckCircleOutlineIcon
                        style={{ color: "#13D44A" }}
                        fontSize="medium"
                      />
                    ) : (
                      <WarningAmberIcon
                        style={{ color: "#F51101" }}
                        fontSize="medium"
                      />
                    )}
                  </IconButton>
                </ThemeProvider>
              ),
              onClick: (event, rowData) => {
                setOpenLogModal(true);
                const data = [];
                rowData.data.operators.forEach((operator) => {
                  data.push({
                    operator: operator,
                  });
                });
                setRowData(data);
              },
            }),
          ]}
        />
      </Grid>
    </div>
  );
}

export default React.memo(OfferTable);
