import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import StatisticsCard from "./StatisticsCard";
import ContractView from "./ContractView";
import moment from "moment";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    color: theme.palette.common.white,
    marginRight: "50px",
  },
  titleRoot: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: 0.4,
    margin: "50px",
    marginLeft: "50px",
  },
}));

function MonthView() {
  const classes = useStyles();
  const intl = useIntl();
  const { contracts } = useSelector(({ contract }) => contract);

  const today = moment();
  const begginingOfCurrentWeek = moment(today.startOf("month")).format(
    "YYYY-MM-DD"
  );
  const endOfWeek = moment(today.endOf("month")).format("YYYY-MM-DD");

  let totalContract = [];
  let draftContract = [];
  let onSaleContracts = [];
  let signedContracts = [];
  let revisedContracts = [];
  let confirmContracts = [];
  Object.values(contracts).map((item) => {
    const created_date = item.created_date;
    const signed_date = item.signed_date;
    const published_date = item.published_date;
    const revised_date =
      item.updated_date !== undefined ?? item.updated_at.split("T")[0];
    const confirm_date = item.confirm_date;
    if (
      moment(created_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      )
    ) {
      totalContract.push(item);
    }
    if (
      moment(created_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      ) &&
      item.contract_statuses_id == 1
    ) {
      draftContract.push(item);
    } else if (
      moment(signed_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      ) &&
      item.contract_statuses_id == 3
    ) {
      signedContracts.push(item);
    } else if (
      moment(published_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      ) &&
      item.contract_statuses_id == 4
    ) {
      onSaleContracts.push(item);
    } else if (
      moment(revised_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      ) &&
      item.contract_statuses_id == 5
    ) {
      revisedContracts.push(item);
    } else if (
      moment(confirm_date).isBetween(
        begginingOfCurrentWeek,
        endOfWeek,
        undefined,
        []
      ) &&
      item.contract_statuses_id == 2
    ) {
      confirmContracts.push(item);
    }
  });

  return (
    <div style={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pl: 1,
          ml: "70px",
          mt: "-47px",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="#246e89"
          title={intl.formatMessage({ id: "total.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={totalContract.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>

        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="#388ea3"
          title={intl.formatMessage({ id: "draft.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={draftContract.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>
        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="#55afc0"
          title={intl.formatMessage({ id: "onsale.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={onSaleContracts.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>
        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="#91d1d3"
          title={intl.formatMessage({ id: "signed.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={signedContracts.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>
        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="#bae7e0"
          title={intl.formatMessage({ id: "revised.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={revisedContracts.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>

        <StatisticsCard
          className={classes.cardRoot}
          backgroundColor="rgb(205 235 230)"
          title={intl.formatMessage({ id: "confirm.contract" })}
          titleProps={{
            variant: "inherit",
            component: "h4",
            className: classes.titleRoot,
          }}
          amount={confirmContracts.length}
          description={intl.formatMessage({ id: "this.week" })}
        >
          <ContractView />
        </StatisticsCard>
      </Box>
    </div>
  );
}

export default MonthView;
