import {
  GET_BOARD_MAP,
  ADD_BOARD_MAP,
  DELETE_BOARD_MAP
} from "../../redux/actions/types";

export const getBoardMap = res => dispatch => {
  dispatch({
    type: GET_BOARD_MAP,
    payload: res.data.data
  });
};

export const addBoardMap = res => dispatch => {
  dispatch({
    type: ADD_BOARD_MAP,
    payload: res.data.data
  });
};

export const deleteMapBoard = res => dispatch => {
  dispatch({
    type: DELETE_BOARD_MAP,
    payload: res
  });
};
