import {
  addService,
  deleteService,
  getServices,
  updateService
} from "redux/actions/services";
import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
export const getServiceService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("service")
    .then(res => {
      dispatch(getServices(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addServiceService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("service", data)
    .then(res => {
      dispatch(addService(res));
      sweetAlerts("success", "Service created cuccessfully!");
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateServiceService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .put("service", data)
    .then(res => {
      dispatch(updateService(res));
      sweetAlerts("success", "Service updated successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const deleteServiceService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`service/${id}`)
    .then(res => {
      dispatch(deleteService(res));
      sweetAlerts("success", "Service deleted successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendNewServiceLog(res, id);
    })
    .catch(e => {
      sweetAlerts("error", e.response.data.message);
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
