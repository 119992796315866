import {
  GET_TERMS_CONDITIONS,
  ADD_TERMS_CONDITIONS,
  UPDATE_TERMS_CONDITIONS,
  DELETE_TERMS_CONDITIONS,
  GET_CONTRACT_TERMS_CONDITIONS,
  ADD_CONTRACT_TERMS_CONDITIONS,
  UPDATE_CONTRACT_TERMS_CONDITIONS,
  DELETE_CONTRACT_TERMS_CONDITIONS,
  GET_ALLCONTRACT_TERMS_CONDITIONS
} from "../actions/types";

const initialState = {
  termsConditions: [],
  contractTermsConditions: [],
  allContractTermsConditions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TERMS_CONDITIONS:
      return { ...state, termsConditions: action.payload };

    case ADD_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: [...state.termsConditions, action.payload]
      };

    case UPDATE_TERMS_CONDITIONS:
      const index = state.termsConditions.findIndex(
        term => term.id === action.payload.id
      );
      const newTerm = [...state.termsConditions];
      newTerm[index] = action.payload;
      return {
        ...state,
        termsConditions: newTerm
      };

    case DELETE_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: state.termsConditions.filter(
          term => term.id !== action.payload.id
        )
      };
    case GET_CONTRACT_TERMS_CONDITIONS:
      return { ...state, contractTermsConditions: action.payload };

    case GET_ALLCONTRACT_TERMS_CONDITIONS:
      return { ...state, allContractTermsConditions: action.payload };

    case ADD_CONTRACT_TERMS_CONDITIONS:
      return {
        ...state,
        contractTermsConditions: [
          ...state.contractTermsConditions,
          action.payload
        ]
      };

    case UPDATE_CONTRACT_TERMS_CONDITIONS:
      const newIndex = state.contractTermsConditions.findIndex(
        term => term.id === action.payload.id
      );
      const newConTerm = [...state.contractTermsConditions];
      newConTerm[newIndex] = action.payload;
      return {
        ...state,
        contractTermsConditions: newConTerm
      };

    case DELETE_CONTRACT_TERMS_CONDITIONS:
      return {
        ...state,
        contractTermsConditions: state.contractTermsConditions.filter(
          term => term.id !== action.payload.id
        )
      };

    default:
      return state;
  }
};
