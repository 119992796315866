import { normalizeOperatorHotels } from "redux/schemas/operatorHotels";
import { GET_OPERATOR_HOTELS } from "../actions/types";

const initialState = {
  SHhotels: [],
  DNhotels: [],
  WBhotels: [],
  LMXhotels: [],
  FORhotels: [],
  SIThotels: [],
  WEBBEDShotels: [],
  JUMBOhotels: [],
  LMThotels: [],
  XPURhotels: [],
  IVChotels: [],
  SDNhotels: [],
  JUhotels: [],
  W2Mhotels: [],
  PThotels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATOR_HOTELS:
      const normalizedData = normalizeOperatorHotels(action.payload);
      return {
        ...state,
        SHhotels: normalizedData.entities.SH,
        DNhotels: normalizedData.entities.DN,
        WBhotels: normalizedData.entities.WB,
        PPhotels: normalizedData.entities.PP,
        LMXhotels: normalizedData.entities.LMX,
        FORhotels: normalizedData.entities.FOR,
        SIThotels: normalizedData.entities.SIT,
        WEBBEDShotels: normalizedData.entities.WEBBEDS,
        JUMBOhotels: normalizedData.entities.JUMBO,
        LMThotels: normalizedData.entities.LMT,
        XPURhotels: normalizedData.entities.XPUR,
        IVChotels: normalizedData.entities.IVC,
        SDNhotels: normalizedData.entities.SDN,
        JUhotels: normalizedData.entities.JU,
        W2Mhotels: normalizedData.entities.W2M,
        PThotels: normalizedData.entities.PT,
      };
    default:
      return state;
  }
};
