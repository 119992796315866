import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useLocation } from "react-router";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";

import {
  sidebarNavs,
  masterSidebarNavs,
  sidebarNavsOfficeUser
} from "../menus";
import { authUserMemo } from "redux/selector/companyHasOfficeSelector";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    paddingBottom: "8rem",
    transition: "all 0.3s ease",

    ".Cmt-modernLayout &": {
      height: "calc(80% - 7px)"
    },
    ".Cmt-miniLayout &": {
      height: "calc(80% - 9px)"
    },
    // ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
    //   height: "calc(80% - 16px)",
    // },
    // overflow: "visible!important",
    // "&:hover": {
    //   overflow: "hidden!important",
    // },
    "MuiCollapse-root makeStyles-navCollapseItem-511 MuiCollapse-entered &": {
      overflow: "visible!important"
    }
  }
}));

const SideBar = () => {
  const location = useLocation();
  const classes = useStyles();
  const authUserState = useSelector(authUserMemo);

  const { companiesId } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.result
  );
  const { companies } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const [companyName, setCompanyName] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  useEffect(() => {
    let compName = [];
    companiesId.map(id => {
      compName.push(companies[id].name);
    });
    setCompanyName(compName);
  }, [companies, isEmpty]);

  if (companies && isEmpty === true) {
    setIsEmpty(false);
  }

  const isMaster = () => {
    if (location.pathname.includes("/master")) {
      return masterSidebarNavs;
    }
    if (authUserState === 30) {
      return sidebarNavsOfficeUser;
    } else {
      return sidebarNavs(companyName);
    }
  };
  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={isMaster()} />
    </PerfectScrollbar>
  );
};

export default SideBar;
