import { GET_RESTRICTIONS, UPDATE_RESTRICTIONS, DELETE_RESTRICTIONS, ADD_RESTIRICTION } from './types';

export const getRestrictions = res => dispatch => {
  dispatch({
    type: GET_RESTRICTIONS,
    payload: res.data.data,
  });
};
// Occupancy Start
export const addOccupancy = res => dispatch => {
  dispatch({
    type: ADD_RESTIRICTION,
    payload: res.data.data,
  });
};
export const updateOccupancy = res => dispatch => {
  dispatch({
    type: UPDATE_RESTRICTIONS,
    payload: res.data,
  });
};
export const deleteOccupancy = res => dispatch => {
  dispatch({
    type: DELETE_RESTRICTIONS,
    payload: res.data.data,
  });
};
// Occupancy End

// CloseOut Start
export const addCloseOut = res => dispatch => {
  dispatch({
    type: ADD_RESTIRICTION,
    payload: res.data.data,
  });
};
export const updateCloseOut = res => dispatch => {
  dispatch({
    type: UPDATE_RESTRICTIONS,
    payload: res.data,
  });
};
export const deleteCloseOut = res => dispatch => {
  dispatch({
    type: DELETE_RESTRICTIONS,
    payload: res.data.data,
  });
};
// CloseOut End

// Release Start
export const addRelease = res => dispatch => {
  dispatch({
    type: ADD_RESTIRICTION,
    payload: res.data.data,
  });
};
export const updateRelease = res => dispatch => {
  dispatch({
    type: UPDATE_RESTRICTIONS,
    payload: res.data,
  });
};
export const deleteRelease = res => dispatch => {
  dispatch({
    type: DELETE_RESTRICTIONS,
    payload: res.data.data,
  });
};
// Release End

// Allotment Start
export const addAllotment = res => dispatch => {
  dispatch({
    type: ADD_RESTIRICTION,
    payload: res.data.data,
  });
};
export const updateAllotment = res => dispatch => {
  dispatch({
    type: UPDATE_RESTRICTIONS,
    payload: res.data,
  });
};
export const deleteAllotment = res => dispatch => {
  dispatch({
    type: DELETE_RESTRICTIONS,
    payload: res.data.data,
  });
};
// Allotment End

// Check-in / Check-out Start
export const addCinCout = res => dispatch => {
  dispatch({
    type: ADD_RESTIRICTION,
    payload: res.data.data,
  });
};
export const updateCinCout = res => dispatch => {
  dispatch({
    type: UPDATE_RESTRICTIONS,
    payload: res.data,
  });
};
export const deleteCinCout = res => dispatch => {
  dispatch({
    type: DELETE_RESTRICTIONS,
    payload: res.data.data,
  });
};
// Check-in / Check-out End
