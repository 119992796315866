// ** React
import React from "react";

// ** React Router
import { useHistory } from "react-router-dom";

// ** MUI Core Imports
import { Button, Card, CardContent, Typography } from "@material-ui/core";

// ** MUI Imports
import { Grid } from "@mui/material";

// ** Component Imports
import BankInformation from "./BankInformation";

// ** Third Party Components
import Swal from "sweetalert2";

//DESC - Dummy bank data
const bankData = [
  { bankName: "Türkiye", IBAN: "TR330006100519786457841326" },
  { bankName: "Birleşik Krallık", IBAN: "GB29NWBK60161331926819" },
  { bankName: "Fransa", IBAN: "FR1420041010050500013M02606" }
];

//DESC - Toast init
const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 2000
});

const PaymentTransactions = () => {
  // ** History
  const history = useHistory();

  //DESC - Copy IBAN to clipboard
  const IBANCopyHandler = IBAN => {
    navigator.clipboard.writeText(IBAN);
    Toast.fire({
      title: "IBAN copied to clipboard"
    });
  };

  //DESC - Redirect to transaction informations page
  const transactionInformationHandler = () =>
    history.push("/payment/transactionInformations");

  //** Renderers
  const renderBankData = () =>
    bankData.map(bank => (
      <BankInformation
        key={bank.IBAN}
        bankName={bank.bankName}
        IBAN={bank.IBAN}
        onClick={() => IBANCopyHandler(bank.IBAN)}
      />
    ));

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <Card>
          <CardContent>
            <Grid
              item
              style={{
                marginBottom: 30,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography variant="h1">Bank Information</Typography>
              <Typography variant="h1">Balance: 300$</Typography>
            </Grid>
            <Grid container>{renderBankData()}</Grid>
            <Grid item sx={12} style={{ textAlign: "end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={transactionInformationHandler}
              >
                Transaction Information
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentTransactions;
