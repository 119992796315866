import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow, EditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addBoardsService,
  deleteBoardsService,
  getBoardsService,
  updateBoardsService,
} from "services/boards";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);

export default function NewBoard({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState({
    codeLookup: {},
    codeOptions: [],
    nameLookup: {},
    nameOptions: [],
  });
  const { boards } = useSelector(({ boards }) => boards);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const contracts = useSelector((state) => state.contract.contracts);
  const intl = useIntl();
  const hasBoard = (board_id) => {
    var has = true;
    Object.values(contracts).forEach((contract) => {
      contract.contract_has_board.forEach((board) => {
        if (boards[contract_has_board[board].board_id].id == board_id) {
          has = false;
        }
      });
    });
    return has;
  };

  useEffect(() => {
    if (boards.length > 0) {
      dispatch(getBoardsService());
    }
  }, [dispatch]);

  useEffect(() => {
    let newTableData = {
      codeLookup: {},
      codeOptions: [],
      nameLookup: {},
      nameOptions: [],
    };
    Object.values(boards).map((board) => {
      newTableData.codeLookup[board.code] = board.code;
      newTableData.codeOptions.push({ value: board.code, label: board.code });
      newTableData.nameLookup[board.name] = board.name;
      newTableData.nameOptions.push({ value: board.name, label: board.name });
    });
    setTableData(newTableData);
  }, [boards]);

  const tableColumns = [
    {
      title: intl.formatMessage({ id: "code" }),
      field: "code",
      lookup: tableData.codeLookup,
      validate: (rowData) => (rowData.code ? true : false),
      editComponent: (props) => (
        <TextField
          //select
          label={intl.formatMessage({ id: "code" })}
          style={{ width: "100%" }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {/* {tableData.codeOptions.map(option => (
           <MenuItem key={option.value} value={option.value}>
             {option.label}
           </MenuItem>
         ))} */}
        </TextField>
        /*  <SingleSelect
         id="code"
         options={tableData.codeOptions}
         color="primary"
         SelectProps={{
           isCreatable: true,
         }}
         onKeyDown={e => handleKeyDown(e)}
         ref={ref => {
           ref = ref;
         }}
         value={props.value}
         placeholder="Code"
         onChange={value => props.onChange(value)}
       /> */
      ),
    },
    {
      title: intl.formatMessage({ id: "name" }),
      field: "name",
      lookup: tableData.nameLookup,
      validate: (rowData) => (rowData.name ? true : false),
      editComponent: (props) => (
        <TextField
          //select
          label={<IntlMessages id="name" />}
          value={props.value}
          style={{ width: "100%" }}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {/* {tableData.nameOptions.map(option => (
           <MenuItem key={option.value} value={option.value}>
             {option.label}
           </MenuItem>
         ))} */}
        </TextField>
      ),
    },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={Object.values(boards)}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: "first",
        headerStyle: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      }}
      actions={[
        !isAuthAdd && {
          icon: "add",
          disabled: false,
          position: "toolbar",
          tooltip: "You are not authorized",
        },
      ]}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: (props) => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={(e) => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({
                          id: "are.you.sure?",
                        }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({
                          id: "yes",
                        }),
                        cancelButtonText: intl.formatMessage({
                          id: "no",
                        }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({
                          id: "are.you.sure?",
                        }),

                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({
                          id: "yes",
                        }),
                        cancelButtonText: intl.formatMessage({
                          id: "no",
                        }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
      }}
      icons={{
        Add: (props) => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="red"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
      }}
      editable={{
        deleteTooltip: (row) =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: (row) =>
          isAuthUpdate
            ? hasBoard(row.id)
              ? "Update"
              : "This board is related with another contract."
            : "You are not authorized",
        isDeletable: (row) => (isAuthDelete ? true : false),
        isEditable: (row) => (isAuthUpdate && hasBoard(row.id) ? true : false),
        onRowAdd: isAuthAdd
          ? (newData) =>
              new Promise((resolve) => {
                const otherBoards = Object.values(boards);
                setTimeout(() => {
                  if (newData.code && newData.name) {
                    if (
                      otherBoards.filter(
                        // her ikisi farklıysa
                        (board) =>
                          board.code.toLowerCase() ==
                            newData.code.toLowerCase() ||
                          board.name.toLowerCase() == newData.name.toLowerCase()
                      ).length == 0
                    ) {
                      dispatch(addBoardsService(newData));
                      resolve();
                    } else if (
                      otherBoards.filter(
                        (board) =>
                          board.code.toLowerCase() == newData.code.toLowerCase()
                      ).length !== 0 &&
                      otherBoards.filter(
                        (board) =>
                          board.name.toLowerCase() == newData.name.toLowerCase()
                      ).length !== 0
                    ) {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: intl.formatMessage({
                          id: "this.board.is.already.exist",
                        }),
                      });
                      resolve();
                    } else if (
                      otherBoards.filter(
                        (board) =>
                          board.code.toLowerCase() == newData.code.toLowerCase()
                      ).length !== 0
                    ) {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: intl.formatMessage({
                          id: "board.already.exists",
                        }),
                      });
                      resolve();
                    } else {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: intl.formatMessage({
                          id: "this.board.is.already.exist",
                        }), // and name is in use
                      });
                      resolve();
                    }
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            const otherBoards = Object.values(boards).filter(
              (board) => board.code !== oldData.code
            );
            setTimeout(() => {
              if (
                oldData.code !== newData.code ||
                oldData.name !== newData.name
              ) {
                if (
                  otherBoards.filter(
                    // her ikisi farklıysa
                    (board) =>
                      board.code.toLowerCase() == newData.code.toLowerCase() ||
                      board.name.toLowerCase() == newData.name.toLowerCase()
                  ).length == 0
                ) {
                  dispatch(updateBoardsService(newData));
                  resolve();
                } else if (
                  otherBoards.filter(
                    (board) =>
                      board.code.toLowerCase() == newData.code.toLowerCase()
                  ).length !== 0 && // her ikisi aynıysa
                  otherBoards.filter(
                    (board) =>
                      board.name.toLowerCase() == newData.name.toLowerCase()
                  ).length !== 0
                ) {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: intl.formatMessage({
                      id: "this.board.is.already.exist",
                    }),
                  });
                  resolve();
                } else if (
                  otherBoards.filter(
                    (board) =>
                      board.code.toLowerCase() == newData.code.toLowerCase()
                  ).length !== 0
                ) {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: intl.formatMessage({
                      id: "this.board.is.already.exist",
                    }),
                  });
                  resolve();
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: intl.formatMessage({ id: "this.name.is.in.use" }), // and name is in use
                  });
                  resolve();
                }
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: intl.formatMessage({ id: "nothing.has.changed" }),
                });
                resolve();
              }
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (hasBoard(oldData.id)) {
                dispatch(deleteBoardsService(oldData.id));
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "this.board.is.related.with.another.contract",
                  }),
                });
              }

              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
