import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../../Common/formElements/AppTextInput";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import GridContainer from "../../GridContainer";
import { addOfficeService } from "services/office";
import {
  getRegionsService,
  getCountriesService,
  getCitiesService,
  getSubCitiesService,
  getDivisionsService,
  getDivisionCitiesService
} from "services/destinations";
import { useDispatch, useSelector } from "react-redux";
import {
  normalizeCity,
  normalizeRegion,
  normalizeSubCity,
  normalizeCountry,
  normalizeDivisions
} from "redux/selector/destinationSelector";
import { nonEnterprise } from "redux/selector/companyHasOfficeSelector";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import SaveIcon from "@mui/icons-material/Save";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import CmtList from "../../../../@coremat/CmtList";
import { TextField } from "@material-ui/core";
import { PhoneFormatCustom } from "../../Common/NumberFormat/NumberFormat";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  option: {
    fontSize: 15
    /* '& > span': {
      marginRight: 10,
      fontSize: 18,
    }, */
  },
  form: {
    "& .MuiFormHelperText-contained": {
      color: "#f44336"
    },
    "& .MuiFormHelperText-root": {
      color: "#f44336"
    }
  }
}));

const themeCancel = createTheme({
  palette: {
    primary: red
  }
});

// function PhoneNumberInput({ onChange, value, ...other }) {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   useEffect(() => {
//     if (!phoneNumber && value) {
//       setTimeout(() => {
//         setPhoneNumber(value);
//       }, 300);
//     }
//   }, [phoneNumber, value]);

//   const onNumberChange = number => {
//     setPhoneNumber(number.formattedValue);
//     onChange(number.formattedValue);
//   };

//   return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
// }

export default function AddOfficeDialog({
  open,
  setOpen,
  countryCache,
  divisionCache,
  divisionCityCache,
  cityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setDivisionCityCache,
  setCityCache,
  setSubCityCache,
  companiesData
}) {
  const theme = createTheme({
    palette: {
      primary: green
    }
  });
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const companyState = useSelector(nonEnterprise);
  const { error_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { success_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const regionsState = useSelector(normalizeRegion);
  const countriesState = useSelector(normalizeCountry);
  const citiesState = useSelector(normalizeCity);
  const subCitiesState = useSelector(normalizeSubCity);
  const divisionState = useSelector(normalizeDivisions);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const [email, setEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [division, setDivision] = useState("");
  const [city, setCity] = useState("");
  const [subcity, setSubcity] = useState("");
  const [district, setDistrict] = useState("");
  const [nameError, setErrorName] = useState("");
  const [findCompanyError, setFindCompanyError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorCountry, setErrorCountry] = useState("");

  /* const [countryCache, setCountryCache] = useState({});
  const [cityCache, setCityCache] = useState({});
  const [subCityCache, setSubCityCache] = useState({}); */

  // Website Validation
  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);

  useEffect(() => {
    dispatch(getRegionsService());
  }, [dispatch]);

  useEffect(() => {
    if (region) {
      setCountryCache({ ...countryCache, [region]: countriesState });
    }
  }, [countriesState]);

  useEffect(() => {
    if (
      country &&
      countriesState.find(countryState => countryState.id == country)
        ?.has_division == 0
    ) {
      setCityCache({ ...cityCache, [country]: citiesState });
    }
    // if (division && countriesState.find(countryState => countryState.id == country)?.has_division === 1) {
    //   setDivisionCityCache({ ...divisionCityCache, [country]: { ...divisionCityCache[country], [division]: citiesState } });
    // }
  }, [citiesState]);
  useEffect(() => {
    if (
      country &&
      countriesState.find(countryState => countryState.id == country)
        ?.has_division == 1
    ) {
      setDivisionCache({ ...divisionCache, [country]: divisionState });
    }
  }, [divisionState]);

  useEffect(() => {}, [country, division, city]);

  useEffect(() => {
    if (city) {
      setSubCityCache({ ...subCityCache, [city]: subCitiesState });
    }
  }, [subCitiesState]);

  useEffect(() => {
    if (
      name &&
      companyId &&
      region &&
      country &&
      error_message == "" &&
      success_message == "success"
    ) {
      newOfficeAlert();
      setEmailError("");
      setEmail("");
      setName("");
      setPhone("");
    } else {
      if (error_message.email) {
        setEmailError(error_message.email[0]);
      }
    }
  }, [error_message, success_message]);

  const newOfficeAlert = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "office.successfully.added" }),
      text: intl.formatMessage({ id: "do.you.want.to.add.new.office" }),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" })
    }).then(result => {
      if (result.isConfirmed) {
        setPhone("");
        setEmail("");
        setName("");
      } else {
        setOpen(false);
      }
    });
  };

  const onSubmitClick = () => {
    /* setCompanyId(selectCompanyInfo.id); */
    const office = {
      name,
      country,
      companyId,
      region,
      city,
      subcity,
      district,
      phone,
      email
    };
    if (!name) {
      setErrorName(requiredMessage);
    }
    if (!companyId) {
      setFindCompanyError(requiredMessage);
    }
    if (!region) {
      setErrorRegion(requiredMessage);
    }
    if (!country) {
      setErrorCountry(requiredMessage);
    }
    if (phone && phone.length < 18) {
      setPhoneError("Please enter valid phone number");
    }

    if (name && companyId && region && country && !phoneError) {
      if (phone ? phone.length >= 18 : true) {
        dispatch(addOfficeService(office));
      }
    }
  };
  const onCancelClick = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      className={classes.dialogRoot}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <IntlMessages id="create.new.office" />
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            required
            variant="outlined"
            label={<IntlMessages id="office.name" />}
            value={name}
            helperText={nameError}
            onChange={e => {
              if (e.target.value.trim().length != 0) {
                setName(e.target.value);
                setErrorName("");
              } else {
                setErrorName(requiredMessage);
                setName("");
              }
            }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="outlined"
            label={<IntlMessages id="email" />}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            helperText={emailError}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            label={<IntlMessages id="phone" />}
            fullWidth
            variant="outlined"
            size="small"
            placeholder="+xx (xxx) xxx-xxxx"
            // placeholder={intl.formatMessage({ id: 'phone' })}
            className={classes.form}
            value={phone ? phone : ""}
            onChange={e => {
              setPhone(e.target.value);
              setPhoneError("");
            }}
            helperText={phoneError}
            name="tel"
            id="outlined-required"
            autoComplete="nope"
            InputProps={{
              inputComponent: PhoneFormatCustom
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="country-select-demo"
              options={companiesData}
              classes={{
                option: classes.option
              }}
              autoHighlight
              getOptionLabel={
                option =>
                  option.name /* + ' ' + ' (' + ' ' + option.company_id + ' ' + ' )' */
              }
              onChange={(e, value) => {
                if (value) {
                  setCity(value.city_id);
                  setRegion(value.region_id);
                  setCountry(value.country_id);
                  setDivision(value.division_id ? value.division_id : "");
                  setSubcity(value.subcity_id);
                  if (!countryCache[value.region_id]) {
                    dispatch(
                      getCountriesService(["region_id", value.region_id])
                    );
                  }
                  if (value.division_id) {
                    if (!divisionCache[value.country_id]) {
                      dispatch(
                        getDivisionsService(["country_id", value.country_id])
                      );
                    }

                    dispatch(
                      getDivisionCitiesService([
                        "division_id",
                        value.division_id
                      ])
                    );
                  } else {
                    if (!cityCache[value.country_id]) {
                      dispatch(
                        getCitiesService(["country_id", value.country_id])
                      );
                    }
                  }

                  if (!subCityCache[value.city_id]) {
                    dispatch(getSubCitiesService(["city_id", value.city_id]));
                  }
                  setCompanyId(value.id);
                  setFindCompanyError("");
                } else {
                  setDivision("");
                  setCity("");
                  setRegion("");
                  setCountry("");
                  setSubcity("");
                  setCompanyId("");
                  setFindCompanyError(requiredMessage);
                }
              }}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  required
                  label={<IntlMessages id="company" />}
                  variant="outlined"
                  helperText={findCompanyError}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password" // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          {companyId && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="country-select-demo"
                options={regionsState}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                value={
                  region !== "" && regionsState.length !== 0
                    ? regionsState[regionsState.findIndex(i => i.id === region)]
                    : null
                }
                getOptionLabel={option => option.name}
                onChange={(e, value) => {
                  if (value) {
                    if (!countryCache[value.id]) {
                      dispatch(getCountriesService(["region_id", value.id]));
                    }
                    setRegion(value.id);
                    setErrorRegion("");
                  } else {
                    setRegion("");
                    setErrorRegion(requiredMessage);
                  }
                  setDivision("");
                  setCity("");
                  setCountry("");
                  setSubcity("");
                  setDistrict("");
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    required
                    label={<IntlMessages id="continent" />}
                    helperText={errorRegion}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            {countryCache[region] && countryCache[region].length != 0 && (
              <Autocomplete
                id="country-select-demo"
                options={countryCache[region]}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                getOptionLabel={option => option.name}
                value={
                  country !== "" && countryCache[region].length !== 0
                    ? countryCache[region][
                        countryCache[region].findIndex(i => i.id === country)
                      ] /* normCountriesState[normCountriesState.findIndex(i => i.id === country)] */
                    : null
                }
                onChange={(e, value) => {
                  if (value) {
                    if (value.has_division == 0) {
                      dispatch(getCitiesService(["country_id", value.id]));
                    } else if (value.has_division == 1) {
                      dispatch(getDivisionsService(["country_id", value.id]));
                    }
                    setCountry(value.id);
                    setErrorCountry("");
                  } else {
                    setCountry("");
                    setErrorCountry(requiredMessage);
                  }
                  setDivision("");
                  setCity("");
                  setSubcity("");
                  setDistrict("");
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    required
                    label={<IntlMessages id="country" />}
                    helperText={errorCountry}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          {countriesState.find(i => i.id === country)?.has_division === 1 && (
            <>
              <Grid item xs={12} sm={6}>
                {divisionCache[country] && divisionCache[country].length != 0 && (
                  <Autocomplete
                    id="country-select-demo"
                    options={divisionCache[country]}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    value={
                      division !== "" && divisionCache[country].length !== 0
                        ? divisionCache[country][
                            divisionCache[country].findIndex(
                              i => i.id == division
                            )
                          ]
                        : null
                    }
                    onChange={(e, value) => {
                      if (value) {
                        if (!subCityCache[value.id]) {
                          dispatch(
                            getDivisionCitiesService(["division_id", value.id])
                          );
                        }
                        setDivision(value.id);
                      } else {
                        setDivision("");
                      }
                      setCity("");
                      setSubcity("");
                      setDistrict("");
                    }}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="state" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password"
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {division && citiesState.length != 0 && (
                  <Autocomplete
                    id="country-select-demo"
                    options={citiesState}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    getOptionLabel={option => option.name}
                    value={
                      city !== "" && citiesState.length !== 0
                        ? citiesState[citiesState.findIndex(i => i.id === city)]
                        : null
                    }
                    onChange={(e, value) => {
                      if (value) {
                        if (!subCityCache[value.id]) {
                          dispatch(getSubCitiesService(["city_id", value.id]));
                        }
                        setCity(value.id);
                      } else {
                        setCity("");
                      }
                      setSubcity("");
                      setDistrict("");
                    }}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label={<IntlMessages id="city" />}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password"
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
          {countriesState.find(i => i.id === country)?.has_division === 0 && (
            <Grid item xs={12} sm={6}>
              {cityCache[country] && cityCache[country].length != 0 && (
                <Autocomplete
                  id="country-select-demo"
                  options={cityCache[country]}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  value={
                    city !== "" && cityCache[country].length !== 0
                      ? cityCache[country][
                          cityCache[country].findIndex(i => i.id === city)
                        ]
                      : null
                  }
                  onChange={(e, value) => {
                    if (value) {
                      if (!subCityCache[value.id]) {
                        dispatch(getSubCitiesService(["city_id", value.id]));
                      }
                      setCity(value.id);
                    } else {
                      setCity("");
                    }
                    setSubcity("");
                    setDistrict("");
                  }}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label={<IntlMessages id="city" />}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                      }}
                    />
                  )}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            {subCityCache[city] && subCityCache[city].length != 0 && (
              <Autocomplete
                id="country-select-demo"
                options={subCityCache[city]}
                classes={{
                  option: classes.option
                }}
                autoHighlight
                getOptionLabel={option => option.name}
                value={
                  subcity !== "" && subCityCache[city].length !== 0
                    ? subCityCache[city][
                        subCityCache[city].findIndex(i => i.id === subcity)
                      ]
                    : null
                }
                onChange={(e, value) => {
                  if (value) {
                    setSubcity(value.id);
                  } else {
                    setSubcity("");
                  }
                  setDistrict("");
                }}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label={<IntlMessages id="sub.city" />}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                  />
                )}
              />
            )}
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            {subcity && (
              <AppTextInput
                variant="outlined"
                label={<IntlMessages id="district" />}
                onBlur={e => setDistrict(e.target.value)}
              />
            )}
          </Grid>
        </GridContainer>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button
              style={{ marginLeft: "991px" }}
              variant="contained"
              color="primary"
              onClick={onCancelClick}
            >
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={onSubmitClick}
              >
                <IntlMessages id="save" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
