import { normalize, schema } from 'normalizr';

const operators = new schema.Entity(
  'operators',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  operators: [operators],
  addStateOperators: [operators],
  updateStateOperators: [operators],
  deleteStateOperators: [operators],
};

export const normalizeOperators = data => normalize(data, [response]);
