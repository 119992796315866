import React from "react";
import countries from "../../../../../data/countries.json";

export const getMarketCountries = (data) => {
  let filteredData = data.map((row) => {
    return Object.values(row);
  });
  let allCountries = countries;
  filteredData = filteredData.filter(
    (row) => row[0] !== "" && typeof row[0] === "string"
  );
  const marketIndex = filteredData
    .map((row, index) => {
      return row[0] === "M A R K E T S" ? index : null;
    })
    .filter((item) => item !== null)[0];
  const excludeCountriesIndex = filteredData
    .map((row, index) => {
      return row[0]?.includes("NOT VALID") ? index : null;
    })
    .filter((item) => item !== null)[0];

  const specialConditionsIndex = filteredData
    .map((row, index) => {
      return row[0]?.includes(
        "S P E C I A L  C O N D I T I O N S  &  N O T E S"
      )
        ? index
        : null;
    })
    .filter((item) => item !== null)[0];

  let includeCountries = "";
  for (let i = marketIndex + 2; i < excludeCountriesIndex; i++) {
    includeCountries += filteredData[i][0];
  }
  let excludeCountries = "";
  for (let i = excludeCountriesIndex + 1; i < specialConditionsIndex; i++) {
    excludeCountries += filteredData[i][0];
  }

  const excludeCountriesArray = excludeCountries
    .replaceAll(" ", "")
    .replaceAll(".", "")
    .split(",");
  const ExcludemarketCountriesInfo = excludeCountriesArray.reduce(
    (acc, market) => {
      allCountries.forEach((country) => {
        if (
          country.name.toLocaleLowerCase() === market.trim().toLocaleLowerCase()
        ) {
          acc.push(country);
        }
      });
      return acc;
    },
    []
  );

  if (
    includeCountries.toLowerCase().includes("all") &&
    (includeCountries.toLowerCase().includes("markets") ||
      excludeCountries.toLowerCase().includes("markets."))
  ) {
    const countries = Object.values(allCountries)
      .map((country) => {
        if (ExcludemarketCountriesInfo.find((item) => item.id === country.id)) {
          return null;
        } else {
          return country.id;
        }
      })
      .filter((item) => item !== null);
    return {
      validCountries: countries,
      invalidCountries: ExcludemarketCountriesInfo,
    };
  }
  const includeCountriesArray = includeCountries.split(",");

  const markets = includeCountriesArray.reduce((acc, market) => {
    countries.forEach((country) => {
      if (
        country.name.toLocaleLowerCase() === market.trim().toLocaleLowerCase()
      ) {
        acc.push(country.id);
      }
    });
    return acc;
  }, []);

  return {
    validCountries: markets,
    invalidCountries: ExcludemarketCountriesInfo,
  };
};
