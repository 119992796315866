import axios from "../axios";
import {
  getMarketingPromotion,
  addMarketingPromotion,
  updateMarketingPromotion,
  deleteMarketingPromotion,
} from "../../redux/actions/kickbacks";

/**PROXOLAB LOGGER **/
import sendLogService from "../proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const addMarketingPromotionService = (marketingPromotion) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .post("marketingPromotion", marketingPromotion)
    .then(({ data }) => {
      dispatch(addMarketingPromotion(data.data));
      sendLogService.marketingPromotionServiceLog(data);
    });
};
export const getMarketingPromotionService = (contractId) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("marketingPromotion", {
      params: {
        contractId,
      },
    })
    .then(({ data }) => {
      dispatch(getMarketingPromotion(data.data));
    });
};

export const updateMarketingPromotionService = (marketingPromotion) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .put("marketingPromotion", marketingPromotion)
    .then(({ data }) => {
      dispatch(updateMarketingPromotion(data.data));
      sendLogService.marketingPromotionServiceLog(data);
    });
};
export const updateMarketingPromotionAmountService = (marketingPromotion) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .put(`marketingPromotionAmount`, marketingPromotion)
    .then((res) => {
      return res;
    });
};

export const deleteMarketingPromotionService = (marketingPromotion) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .delete(`marketingPromotion/${marketingPromotion.id}`)
    .then(() => {
      dispatch(deleteMarketingPromotion(marketingPromotion));
      sendLogService.marketingPromotionServiceLog(marketingPromotion);
    });
};
