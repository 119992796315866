import {
  Backdrop,
  Card,
  CardContent,
  Fade,
  Modal,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-grid-system";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Typography } from "antd";

const useStyles = makeStyles((theme) => ({
  modal: {
    alignItems: "center",
    textAlign: "-webkit-center",
    padding: "150px",
    justifyContent: "center",
    overflow: "scroll",
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "rgb(21 21 19 / 1%)" : "",
  },
  rootcard: {
    width: "95%",
    position: "center",
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "rgb(21 21 19 / 1%)" : "",
  },
  root: {
    width: "80%",
    position: "center",
  },
}));
export default function BotLogDetail({
  openLogModal,
  setOpenLogModal,
  rowData,
  title,
}) {
  const classes = useStyles();

  const statusForLogFunc = (rowData) => {
    let status = "";
    status = rowData.operator.status.toString();
    return status.endsWith("02") ? (
      <CheckCircleOutlineIcon style={{ color: "#13D44A" }} fontSize="large" />
    ) : status.endsWith("04") ? (
      <WarningAmberIcon style={{ color: "#F51101" }} fontSize="large" />
    ) : status.endsWith("03") ? (
      <ErrorOutlineIcon fontSize="large" style={{ color: "#990000" }} />
    ) : (
      <PendingIcon fontSize="large" style={{ color: "#ff9800" }} />
    );
  };
  const resultLogFunc = (rowData) => {
    let status = rowData.operator.status.toString();
    let message = rowData.operator?.message;

    return status.endsWith("02") ? (
      message ? (
        <Typography variant="subtitle1">{message}</Typography>
      ) : (
        <IntlMessages
          id={
            title == "INFO"
              ? "hotels.and.rooms.information.has.been.successfully.retrieved"
              : "transaction.success"
          }
        />
      )
    ) : status.endsWith("01") ? (
      message ? (
        <Typography variant="subtitle1">{message}</Typography>
      ) : (
        <IntlMessages id="transaction.failed" />
      )
    ) : status.endsWith("03") ? (
      message ? (
        <Typography variant="subtitle1">{message}</Typography>
      ) : (
        <IntlMessages
          id={
            title == "INFO"
              ? "failed.to.check.hotels.and.room.information.retrieval"
              : "could.not.be.controlled"
          }
        />
      )
    ) : status.endsWith("00") ? (
      message ? (
        <Typography variant="subtitle1">{message}</Typography>
      ) : (
        <IntlMessages
          id={
            title == "INFO"
              ? "the.process.of.obtaining.information.about.hotels.and.rooms.has.been.started"
              : "transaction.started"
          }
        />
      )
    ) : status.endsWith("04") ? (
      message ? (
        <Typography variant="subtitle1">{message}</Typography>
      ) : (
        <IntlMessages
          id={
            title == "INFO"
              ? "failed.to.retrieve.hotels.and.room.information"
              : "operation.failed"
          }
        />
      )
    ) : (
      <IntlMessages id="an.error.occurred.while.checking" />
    ); // 05 durumu
  };
  return (
    <>
      <Modal
        className={classes.modal}
        open={openLogModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLogModal}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.rootcard}>
              <Row>
                <Col md={11}>
                  <CmtCardHeader
                    title={title + " LOG INFORMATION"}
                  ></CmtCardHeader>
                </Col>
                <Col md={1}>
                  <IconButton
                    color="inherit"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setOpenLogModal(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Col>
              </Row>
              <MaterialTable
                data={rowData}
                columns={[
                  {
                    title: "",
                    width: "1%",
                    cellStyle: { textAlign: "center" },
                    field: "1",
                    render: (rowData) => {
                      return statusForLogFunc(rowData);
                    },
                  },
                  {
                    title: "Operator",
                    field: "operator",
                    render: (rowData) => {
                      return rowData.operator.label;
                    },
                  },
                  {
                    title: "Status",
                    field: "operator",
                    render: (rowData) => {
                      return resultLogFunc(rowData);
                    },
                  },
                ]}
                options={{
                  search: false,
                  showTitle: false,
                  addRowPosition: "first",
                  toolbar: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 30, 40],
                }}
              />
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

export const MemoizedBotLogDetail = React.memo(BotLogDetail);
