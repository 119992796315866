import { normalizeTasks } from "redux/schemas/room";
import {
  GET_ROOM,
  ADD_ROOM,
  ADD_ROOMS,
  UPDATE_ROOM,
  DELETE_ROOM
} from "../actions/types";

const initialState = {
  rooms: {
    roomType: [],
    promos: [],
    subTypes: [],
    types: [],
    views: [],
    grades: [],
    bases: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROOM: {
      const normalizeData = normalizeTasks([action.payload]);
      return {
        ...state,
        rooms: {
          ...state.rooms,
          roomType: normalizeData.entities.roomType
            ? normalizeData.entities.roomType
            : [],
          promos: normalizeData.entities.promos
            ? normalizeData.entities.promos
            : [],
          subTypes: normalizeData.entities.subTypes
            ? normalizeData.entities.subTypes
            : [],
          types: normalizeData.entities.types
            ? normalizeData.entities.types
            : [],
          views: normalizeData.entities.views
            ? normalizeData.entities.views
            : [],
          grades: normalizeData.entities.grades
            ? normalizeData.entities.grades
            : [],
          bases: normalizeData.entities.bases
            ? normalizeData.entities.bases
            : []
        }
      };
    }
    case ADD_ROOM: {
      const normalizeData = normalizeTasks([action.payload]);

      return {
        ...state,
        rooms: {
          ...state.rooms,
          roomType: {
            ...state.rooms.roomType,
            [normalizeData.result[0].roomType]:
              normalizeData.entities.roomType[normalizeData.result[0].roomType]
          }
        }
      };
    }
    case ADD_ROOMS: {
      const roomObj = {};
      const rooms = action.payload.reduce((acc, curr) => {
        const obj = { roomType: [curr] };
        const normalizeData = normalizeTasks([obj]);
        acc = {
          ...acc,
          [normalizeData.result[0].roomType]:
            normalizeData.entities.roomType[normalizeData.result[0].roomType]
        };
        return acc;
      }, roomObj);

      return {
        ...state,
        rooms: {
          ...state.rooms,
          roomType: {
            ...state.rooms.roomType,
            ...rooms
          }
        }
      };
    }
    case UPDATE_ROOM: {
      const normalizeData = normalizeTasks([action.payload]);
      return {
        ...state,
        rooms: {
          ...state.rooms,
          roomType: {
            ...state.rooms.roomType,
            [normalizeData.result[0].roomType]:
              normalizeData.entities.roomType[normalizeData.result[0].roomType]
          }
        }
      };
    }
    case DELETE_ROOM: {
      const deleted_rooms = Object.keys(state.rooms.roomType)
        .filter(id => Number(id) !== Number(action.payload?.id))
        .reduce((obj, key) => {
          obj[key] = state.rooms.roomType[key];
          return obj;
        }, {});
      return {
        ...state,
        rooms: {
          ...state.rooms,
          roomType: deleted_rooms
        }
      };
    }
    default:
      return state;
  }
};
