import { GET_DAYS } from '../actions/types';

const initialState = {
  days: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DAYS: {
      return { ...state, days: action.payload.days ? action.payload.days : [] };
    }
    default:
      return state;
  }
};
