import * as React from "react";
import Button from "@mui/material/Button";
//import Slide from '@mui/material/Slide';
import { IconButton, Tooltip } from "@material-ui/core";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

//const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ContractSyncModal(props) {
  const prop = props.props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  return parseInt(prop) === 1 ? (
    <Tooltip title="Contract Synced">
      <IconButton>
        <Button onClick={handleClickOpen}>
          <DoneAllIcon color="success" />
        </Button>
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="Contract Could Not Be Synchronized">
      <IconButton>
        <Button onClick={handleClickOpen}>
          <ErrorOutlineIcon color="error" />
        </Button>
      </IconButton>
    </Tooltip>
  );
}
