import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import {
  getAdminCompanies,
  addAdminCompany,
  updateAdminCompany,
  deleteAdminCompany,
  fetchCompanyError,
  fetchCompanySuccess
} from "../redux/actions/CompanyHasOffice";
import {
  getCompanies,
  addMasterCompany,
  editMasterCompany
} from "../redux/actions/Company";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getMasterCompaniesService = (callbackFunc) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("/master/companies")
    .then(({ data }) => {
      dispatch(getCompanies(data.data));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getAdminCompaniesService = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("companies")
    .then(({ data }) => {
      dispatch(getAdminCompanies(data));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      Swal.fire({
        title: "Error",
        text: "Company not found",
        icon: "error",
        confirmButtonText: "Ok"
      });
    });
};

export const addAdminCompanyService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("company", data)
    .then((res) => {
      dispatch(addAdminCompany(res));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendCompanyLog(res, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      dispatch(fetchCompanyError(e.response.data));
      sendLogService.errorlog(e, data);
    });
};

export const updateAdminCompanyService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("company", data)
    .then((res) => {
      dispatch(updateAdminCompany(res));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendCompanyLog(res, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      // dispatch(fetchCompanyError(e.response.data));
      sendLogService.errorlog(e, data);
      Swal.fire({
        title: "Error",
        text: "Company could not be updated",
        icon: "error",
        confirmButtonText: "Ok"
      });
    });
};

export const deleteAdminCompanyService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`company/${data.id}`)
    .then((res) => {
      dispatch(deleteAdminCompany(res));
      dispatch(fetchSuccess());
      sendLogService.sendCompanyLog(res, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const addMasterCompanyService = (data, callbackFunc) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios.post("/master/companies", data).then(({ data }) => {
    dispatch(addMasterCompany(data.data));
    dispatch(fetchSuccess());
    if (callbackFunc) callbackFunc();
    sendLogService.masterCompanyServiceLogs(callbackFunc, data);
  });
  /*    .catch(e => {
      dispatch(fetchError(Object.values(JSON.parse(e.response.data))[0]));
       sendLogService.errorlog(e, data);
    }); */
};

export const updateMasterCompanyService = (data, callbackFunc) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put(`/master/companies`, data)
    .then(({ data }) => {
      dispatch(editMasterCompany(data.data));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
      sendLogService.masterCompanyServiceLogs(callbackFunc, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
