import React, { useEffect, useState, useCallback, lazy } from "react";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  PagingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import CopyContract from "./CopyContract/CopyContract";
import ContractResyncModal from "./ContractResyncModal";
import { Row } from "react-grid-system";
import TextField from "@material-ui/core/TextField";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, green, red, blueGrey, amber } from "@material-ui/core/colors";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { getMarkupsService } from "services/markup";
import { getOperatorService } from "services/operator";
import { getMarketService } from "services/market";
import { getCurrenciesService } from "services/currency";
import { getSeasonService } from "services/season";
import { getStarRatesService } from "services/starRate";
import { getProductTypesService } from "services/productTypes";
import { getRegionsService } from "services/destinations";
import { getBoardsService } from "services/boards";
import { getRateTypesService } from "services/rateType";
import { getAllotmentTypeService } from "services/allotmentType";
import { SET_SELECTED_CONTRACT } from "redux/actions/types";
import { getBanksService } from "services/banks";
import { getPaymentMethodsService } from "services/paymentMethods";
import { getPaymentConditionsService } from "services/paymentConditions";
import { getStayTypesService } from "services/stayType";
import { getCancellationPoliciesServices } from "services/cancellationPoliciesDetail";
import { getAdminCompaniesService } from "services/company";
import { getReservationsService } from "services/reservation";
import { getOnSaleContractService } from "services/onSaleContract";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import ContractSyncModal from "./ContractSyncModal";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import ContractInfo from "./ContractGraph/ContractInfo";
import ReservationInfo from "./ReservationGraph/ReservationInfo";
import ReservationGraph from "./ReservationGraph/ReservationGraph";
import CmtCard from "../../../@coremat/CmtCard";
import CmtCardHeader from "../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../@coremat/CmtCard/CmtCardContent";
import ContractGraph from "./ContractGraph/ContractGraph";
import Swal from "sweetalert2";
import moment from "moment";
import { changeContractStatusService } from "services/contract";
import IntlMessages from "@jumbo/utils/IntlMessages";
const MemoizedContractPdf = lazy(() => import("../Reports/ContractPdf"));
const MemoizedEditContract = lazy(() => import("./EditContract"));

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    "& .Cmt-card-content": {
      marginTop: -15
    }
  },
  cmtCardStyle: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  graphStyle: {
    marginTop: "4%",
    marginLeft: "10%"
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold
  },
  order2: {
    [theme.breakpoints.up("md")]: {
      order: 2
    }
  },
  order3: {
    [theme.breakpoints.up("md")]: {
      order: 3
    }
  },
  row: {
    marginTop: "1rem",
    marginLeft: "1rem !important",
    minWidth: "14rem"
  },
  text: {
    width: "15%"
  }
}));

export default function NewDashboard() {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { selectedCompany } = useSelector(({ auth }) => auth);
  const { paymentMethods } = useSelector(
    ({ paymentMethods }) => paymentMethods
  );

  const [status, setStatus] = useState([
    { name: "DRAFT", id: 1 },
    { name: "CONFIRM", id: 2 },
    { name: "SIGNED", id: 3 },
    { name: "ON SALE", id: 4 },
    { name: "REVISED", id: 5 }
  ]);
  let history = useHistory();
  const redirect = () => {
    history.push("/contract/newcontract");
    dispatch({ type: SET_SELECTED_CONTRACT, payload: 0 });
  };
  useEffect(async () => {
    await firstLoader();
  }, []);
  const themeButton = createTheme({
    palette: {
      primary: blue
    }
  });
  const theme = createTheme({
    palette: {
      primary: blueGrey,
      secondary: amber
    }
  });
  const firstLoader = async () => {
    if (paymentMethods.length === 0) {
      await dispatch(
        getContractService(
          authUser.authority_level,
          localStorage.getItem("selectedCompany")
        )
      );
      await dispatch(getOnSaleContractService());
      await dispatch(getRegionsService());
      await dispatch(getAdminCompaniesService());
      await dispatch(getBoardsService());
      await dispatch(getHotelService());
      await dispatch(getProductTypesService());
      await dispatch(getSeasonService());
      await dispatch(getMarketService());
      await dispatch(getOperatorService());
      await dispatch(getMarkupsService());
      await dispatch(getCurrenciesService());
      await dispatch(getRateTypesService());
      await dispatch(getBanksService());
      await dispatch(getPaymentMethodsService());
      await dispatch(getPaymentConditionsService());
      await dispatch(getAllotmentTypeService());
      await dispatch(getStayTypesService());
      await dispatch(getCancellationPoliciesServices());
      await dispatch(getReservationsService());
      await dispatch(getStarRatesService());
    }
  };
  const graphNames = [
    {
      value: "Contract",
      label: <IntlMessages id="contract" />
    },
    {
      value: "Reservation",
      label: <IntlMessages id="reservation" />
    }
  ];
  const [selectGraph, setSelectGraph] = useState("Contract");
  const [selectedContract, setSelectedContract] = useState(null);
  const [copyContractOpen, setCopyContractOpen] = useState(false);
  const [editMarkupOpen, setEditMarkupOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [contractSync, setContractSync] = useState(null);
  const memoizedIsClickedEdit = useCallback((contractId) => {
    dispatch({ type: SET_SELECTED_CONTRACT, payload: contractId });
  }, []);

  const contracts = useSelector((state) => {
    let newContracts = [];
    if (
      Object.values(state.hotels.hotels).length > 0 &&
      state.productTypes.productTypes.length > 0 &&
      Object.values(state.operators.operators).length > 0 &&
      Object.values(state.seasons.seasons).length > 0 &&
      Object.values(state.destinations.regions).length > 0 &&
      Object.values(state.boards.boards).length > 0 &&
      Object.values(state.allotmentType.allotmentType).length > 0 &&
      Object.values(state.currencies.currencies).length > 0 &&
      Object.values(state.companyHasOffice.entities.users).length > 0 &&
      Object.values(state.contract.contract_has_board).length > 0
    ) {
      Object.values(state.contract.contracts).forEach((contract) => {
        let contractHotel = state.hotels.hotels[contract.hotel_id];
        let newContract = {
          ...contract,
          product_name: contractHotel.name,
          star_rating_name: contractHotel.star_rating + " Star",
          type_of_product: state.productTypes.productTypes.find(
            (element) => element.id === contractHotel.product_type_id
          ).name,
          season_name: Object.values(state.seasons.seasons).find(
            (element) => element.id === contract.season_id
          ).name,
          operator_string: (function() {
            let operator_string = "";
            contract.operators.forEach((operator) => {
              operator_string += state.operators.operators[operator].code + " ";
            });
            return operator_string;
          })(),
          region_name: (function() {
            let region_string = "";
            Object.values(state.destinations.regions).forEach((region) => {
              if (region.id === contractHotel.region_id) {
                region_string += region.code + " ";
              }
            });
            return region_string;
          })(),
          contract_status: status.find(
            (st) => st.id === contract.contract_statuses_id
          ).name,
          sync_name: <ContractSyncModal props={contract.sync} />,

          board_string: (function() {
            let board_string = "";
            contract.contract_has_board.forEach((board) => {
              board_string +=
                state.boards.boards[
                  state.contract.contract_has_board[board].board_id
                ].code + " ";
            });
            return board_string;
          })(),
          allotment_name:
            state.allotmentType.allotmentType[contract.allotment_type_id].name,
          currency_name: state.currencies.currencies[contract.currency].code,
          user_name:
            state.companyHasOffice.entities.users[contract.user_id].fullname
        };
        newContracts.push(newContract);
      });
    }
    return newContracts;
  });
  const handleClickOpen = (contractId, statusId) => {
    if (statusId == 2) {
      Swal.fire({
        text: <IntlMessages id="contract.draft.text" />,
        title: <IntlMessages id="contract.draft" />,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`
      }).then((result) => {
        //Read more about isConfirmed, isDenied below
        if (result.isConfirmed) {
          dispatch(
            changeContractStatusService({
              contract_id: contractId,
              status_id: "1"
            })
          );
          memoizedIsClickedEdit(contractId);
          setEditOpen(true);
        }
      });
    } else {
      memoizedIsClickedEdit(contractId);
      setEditOpen(true);
    }
  };

  const [columns] = useState([
    {
      title: "Edit",
      name: "edit",
      getCellValue: (rowData) => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedContract(rowData.id);
                setEditOpen(true);
                handleClickOpen(rowData.id, rowData.contract_statuses_id);
              }}
              color="primary"
            >
              <IntlMessages id="UPDATE" />
            </Button>
          </ThemeProvider>
        );
      }
    },
    {
      title: "Copy Contract",
      name: "copy",
      getCellValue: (rowData) => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedContract(rowData.id);
                setCopyContractOpen(true);
              }}
              color="primary"
            >
              <IntlMessages id="COPY" />
            </Button>
          </ThemeProvider>
        );
      }
    },
    {
      title: "View Pdf",
      name: "viewPdf",
      getCellValue: (rowData) => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              variant="outlined"
              onClick={() => {
                setPdfOpen(true);
                setSelectedContract(rowData.id);
              }}
              color="primary"
            >
              <IntlMessages id="view.pdf" />
            </Button>
          </ThemeProvider>
        );
      }
    },
    { title: "Product Name", name: "product_name" },
    { title: "Star Rating", name: "star_rating_name", hide: true },
    { title: "Type of Product", name: "type_of_product", hide: true },
    { title: "Season Name", name: "season_name" },
    { title: "Operator", name: "operator_string" },
    { title: "Region", name: "region_name" },
    {
      title: "Start Date",
      name: "start_date",
      render: (props) => {
        return moment(props.start_date).format("DD-MM-YYYY");
      }
    },
    { title: "End Date", name: "end_date", dateSetting: { locale: "en-US" } },
    { title: "Created Date", name: "created_date", hide: true },
    { title: "Signed Date", name: "signed_date", hide: true },
    { title: "Published Date", name: "published_date", hide: true },
    { title: "Contract Status", name: "contract_status" },
    {
      title: "Sync",
      name: "sync_name"
    },
    {
      title: "Markup",
      name: "markup",
      width: "200%",
      getCellValue: (rowData) => {
        return (
          <ThemeProvider theme={themeButton}>
            <Button
              color="primary"
              variant="outlined"
              disabled={
                rowData.contract_statuses_id == "1" ||
                rowData.contract_statuses_id == "2"
              }
              onClick={() => {
                setSelectedContract(rowData.id);
                setEditMarkupOpen(true);
                setContractSync(rowData.sync);
              }}
            >
              {rowData.contract_statuses_id == 1
                ? "Assign Markup"
                : "Edit Markup"}
            </Button>
          </ThemeProvider>
        );
      }
    },
    { title: "Base Board", name: "board_string", hide: true },
    { title: "Allotment Type", name: "allotment_name" },
    { title: "Currency", name: "currency_name", hide: true },
    { title: "User", name: "user_name" }
  ]);
  const [defaultHiddenColumnNames] = useState([
    "star_rating_name",
    "created_date",
    "signed_date",
    "published_date",
    "board_string",
    "allotment_name"
  ]);
  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: "edit", togglingEnabled: false },
    { columnName: "copy", togglingEnabled: false },
    { columnName: "viewPdf", togglingEnabled: false },
    { columnName: "markup", togglingEnabled: false }
  ]);

  const classes = useStyles();
  const [filteringStateColumnExtensions] = useState([
    { columnName: "edit", filteringEnabled: false },
    { columnName: "copy", filteringEnabled: false },
    { columnName: "viewPdf", filteringEnabled: false },
    { columnName: "markup", filteringEnabled: false }
  ]);

  let totalContract = Object.values(contracts).length;
  let draftContract = [];
  let onSaleContracts = [];
  //let totalReservation = Object.values(reservations)?.length;
  Object.values(contracts).map((item) => {
    if (item.contract_statuses_id == 1) {
      draftContract.push(item);
    } else if (item.contract_statuses_id == 4) {
      onSaleContracts.push(item);
    }
  });
  const info = [
    {
      label: <IntlMessages id="total.contract" />,
      value: totalContract,
      color: "orange"
    },
    {
      label: <IntlMessages id="draft.contract" />,
      value: draftContract.length,
      color: "green"
    },
    {
      label: <IntlMessages id="onsale.contract" />,
      value: onSaleContracts.length,
      color: "blue"
    }
  ];
  const resInfo = [
    { label: " Total Reservation", value: 30, color: "orange" },
    { label: "Cancelled Reservation", value: 20, color: "blue" },
    { label: "Sended Reservation", value: 10, color: "green" }
  ];
  return (
    <>
      <Paper>
        <div style={{ overflowX: "scroll" /*grafik için eklendi*/ }}>
          <Typography variant="h6" component="h4" color="primary">
            <Box sx={{ fontSize: 16, fontWeight: 1000, m: 2 }}>DASHBOARD</Box>
          </Typography>
          <Grid rows={contracts} columns={columns}>
            <FilteringState
              defaultFilters={[]}
              columnExtensions={filteringStateColumnExtensions}
            />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            <PagingPanel />
            <ThemeProvider theme={theme}>
              <Row className={classes.row}>
                <Button
                  variant="contained"
                  onClick={redirect}
                  startIcon={<LibraryAddIcon />}
                  color={"primary"}
                >
                  Add Contract
                </Button>
                <Button
                  variant="contained"
                  /* onClick={() => handleClickOpen()} */
                  startIcon={<ImportExportIcon />}
                  style={{ marginLeft: "5px" }}
                  color={"secondary"}
                >
                  Import Contract
                </Button>
              </Row>
            </ThemeProvider>
            <TableColumnVisibility
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              columnExtensions={tableColumnVisibilityColumnExtensions}
            />
            <TableFilterRow />
            <Toolbar />
            <ColumnChooser />
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pl: 1,
              ml: 2,
              mt: -5,
              flexGrow: 1,
              justifyContent: "flex-start"
            }}
          >
            <TextField
              className={classes.text}
              select
              label="GRAPHICS"
              placeholder="GRAPHICS"
              name="graphics"
              size="small"
              focused
              variant="outlined"
              color="warning"
              value={selectGraph}
              onChange={(event) => setSelectGraph(event.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            >
              {graphNames.map((graph) => (
                <MenuItem key={graph.value} value={graph.value}>
                  {graph.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {selectGraph == "Contract" ? (
            <CmtCard className={classes.cmtCardStyle}>
              <div>
                <CmtCardHeader
                  title="Contract Informations"
                  titleProps={{
                    variant: "h1",
                    component: "div"
                  }}
                />
                <CmtCardContent>
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.titleSpace}
                  >
                    General Info
                  </Typography>
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.titleSpace}
                  >
                    Contract
                  </Typography>
                  <ContractInfo data={info} />
                </CmtCardContent>
              </div>
              <div className={classes.graphStyle}>
                <CmtCardContent>
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.titleSpace}
                  >
                    Contract
                  </Typography>
                  <ContractGraph />
                </CmtCardContent>
              </div>
            </CmtCard>
          ) : (
            <CmtCard className={classes.cmtCardStyle}>
              <div>
                <CmtCardHeader
                  title=" Reservation Informations"
                  titleProps={{
                    variant: "h1",
                    component: "div"
                  }}
                />
                <CmtCardContent>
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.titleSpace}
                  >
                    General Info
                  </Typography>
                  <ReservationInfo data={resInfo} />
                </CmtCardContent>
              </div>
              <div className={classes.graphStyle}>
                <CmtCardContent>
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.titleSpace}
                  >
                    Reservation
                  </Typography>
                  <ReservationGraph />
                </CmtCardContent>
              </div>
            </CmtCard>
          )}
          <CopyContract
            copyContractOpen={copyContractOpen}
            setCopyContractOpen={setCopyContractOpen}
            selectedContract={selectedContract}
          ></CopyContract>

          <ContractResyncModal
            editMarkupOpen={editMarkupOpen}
            setEditMarkupOpen={setEditMarkupOpen}
            selectedContract={selectedContract}
            contractSync={contractSync}
          ></ContractResyncModal>

          <MemoizedContractPdf
            contractId={selectedContract}
            pdfOpen={pdfOpen}
            setPdfOpen={setPdfOpen}
          ></MemoizedContractPdf>
          <MemoizedEditContract
            onClick={() => memoizedIsClickedEdit(selectedContract)}
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            contract_id={selectedContract}
            type={"edit"}
          ></MemoizedEditContract>
        </div>
      </Paper>
    </>
  );
}
