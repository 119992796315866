import {
  GET_TURNOVER_BASIS,
  ADD_TURNOVER_BASIS,
  UPDATE_TURNOVER_BASIS,
  DELETE_TURNOVER_BASIS,
  GET_CONTRIBUTION_FEE,
  ADD_CONTRIBUTION_FEE,
  UPDATE_CONTRIBUTION_FEE,
  DELETE_CONTRIBUTION_FEE,
  ADD_REPRESENTATIVE_FEE,
  GET_REPRESENTATIVE_FEE,
  UPDATE_REPRESENTATIVE_FEE,
  DELETE_REPRESENTATIVE_FEE,
  ADD_ADVERTISING_FEE,
  GET_ADVERTISING_FEE,
  UPDATE_ADVERTISING_FEE,
  DELETE_ADVERTISING_FEE,
  ADD_MARKETING_PROMOTION,
  GET_MARKETING_PROMOTION,
  UPDATE_MARKETING_PROMOTION,
  DELETE_MARKETING_PROMOTION,
  ADD_CATALOGUE_CONTRIBUTION,
  GET_CATALOGUE_CONTRIBUTION,
  UPDATE_CATALOGUE_CONTRIBUTION,
  DELETE_CATALOGUE_CONTRIBUTION
} from "redux/actions/types";

const initialState = {
  turnoverBasis: [],
  contributionFee: [],
  representativeFee: [],
  advertisingFee: [],
  marketingPromotion: [],
  catalogueContribution: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TURNOVER_BASIS:
      return {
        ...state,
        turnoverBasis: [ ...state.turnoverBasis, action.payload ]
      };
    case GET_TURNOVER_BASIS:
      return {...state, turnoverBasis: action.payload};
    case UPDATE_TURNOVER_BASIS:
      return {
        ...state,

        turnoverBasis: state.turnoverBasis.reduce((turnoverBasis, el) => {
          if (action.payload.find(e => e.id === el.id)) {
            return turnoverBasis.concat(
              action.payload.find(e => e.id === el.id)
            );
          }
          return turnoverBasis.concat(el);
        }, [])
      };
    case DELETE_TURNOVER_BASIS:
      return {
        ...state,
        turnoverBasis: state.turnoverBasis.filter(
          turnoverBasis => turnoverBasis.id !== action.payload.id
        )
      };

    case ADD_CONTRIBUTION_FEE:
      return {
        ...state,
        contributionFee: [ ...state.contributionFee, action.payload ]
      };
    case GET_CONTRIBUTION_FEE:
      return {...state, contributionFee: action.payload};
    case UPDATE_CONTRIBUTION_FEE:
      return {
        ...state,
        contributionFee: state.contributionFee.map(
          contributionFee =>
            contributionFee.id === action.payload.id
              ? action.payload
              : contributionFee
        )
      };
    case DELETE_CONTRIBUTION_FEE:
      return {
        ...state,
        contributionFee: state.contributionFee.filter(
          contributionFee => contributionFee.id !== action.payload.id
        )
      };
    case ADD_REPRESENTATIVE_FEE:
      return {
        ...state,
        representativeFee: [ ...state.representativeFee, action.payload ]
      };
    case GET_REPRESENTATIVE_FEE:
      return {...state, representativeFee: action.payload};
    case UPDATE_REPRESENTATIVE_FEE:
      return {
        ...state,
        representativeFee: state.representativeFee.map(
          representativeFee =>
            representativeFee.id === action.payload.id
              ? action.payload
              : representativeFee
        )
      };
    case DELETE_REPRESENTATIVE_FEE:
      return {
        ...state,
        representativeFee: state.representativeFee.filter(
          representativeFee => representativeFee.id !== action.payload.id
        )
      };
    case ADD_ADVERTISING_FEE:
      return {
        ...state,
        advertisingFee: [ ...state.advertisingFee, action.payload ]
      };
    case GET_ADVERTISING_FEE:
      return {...state, advertisingFee: action.payload};
    case UPDATE_ADVERTISING_FEE:
      return {
        ...state,
        advertisingFee: state.advertisingFee.map(
          advertisingFee =>
            advertisingFee.id === action.payload.id
              ? action.payload
              : advertisingFee
        )
      };
    case DELETE_ADVERTISING_FEE:
      return {
        ...state,
        advertisingFee: state.advertisingFee.filter(
          advertisingFee => advertisingFee.id !== action.payload.id
        )
      };
    case ADD_MARKETING_PROMOTION:
      return {
        ...state,
        marketingPromotion: [ ...state.marketingPromotion, action.payload ]
      };
    case GET_MARKETING_PROMOTION:
      return {...state, marketingPromotion: action.payload};
    case UPDATE_MARKETING_PROMOTION:
      return {
        ...state,
        marketingPromotion: state.marketingPromotion.map(
          marketingPromotion =>
            marketingPromotion.id === action.payload.id
              ? action.payload
              : marketingPromotion
        )
      };
    case DELETE_MARKETING_PROMOTION:
      return {
        ...state,
        marketingPromotion: state.marketingPromotion.filter(
          marketingPromotion => marketingPromotion.id !== action.payload.id
        )
      };
    case ADD_CATALOGUE_CONTRIBUTION:
      return {
        ...state,
        catalogueContribution: [
          ...state.catalogueContribution,
          action.payload
        ]
      };
    case GET_CATALOGUE_CONTRIBUTION:
      return {...state, catalogueContribution: action.payload};
    case UPDATE_CATALOGUE_CONTRIBUTION:
      return {
        ...state,
        catalogueContribution: state.catalogueContribution.map(
          catalogueContribution =>
            catalogueContribution.id === action.payload.id
              ? action.payload
              : catalogueContribution
        )
      };
    case DELETE_CATALOGUE_CONTRIBUTION:
      return {
        ...state,
        catalogueContribution: state.catalogueContribution.filter(
          catalogueContribution =>
            catalogueContribution.id !== action.payload.id
        )
      };

    default:
      return state;
  }
};
