// ** React Imports
import React from "react";

// ** MUI Imports
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

// ** MUI Icons
import FileCopyIcon from "@material-ui/icons/FileCopy";

const BankInformation = props => {
  const { bankName, IBAN, onClick } = props;
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20
      }}
    >
      <Typography variant="h3">Banka: {bankName}</Typography>
      <Typography variant="h3">IBAN: {IBAN}</Typography>
      <Tooltip title="Copy IBAN" style={{ padding: 0 }}>
        <IconButton aria-label="delete" onClick={onClick}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default React.memo(BankInformation);
