import React from "react";
import { PeriodHeader } from "./getPeriods";
import { renameObjKey } from "./renameObjectKeys";

export const getReductions = (data) => {
  const basicRoomHeaderIndex = Object.values(data[7]).indexOf(
    "Basic Room for CHD Reduction"
  );
  const headers = Object.entries(data[7]).slice(1, basicRoomHeaderIndex);
  const get3rdPersonIndex = Object.values(data).findIndex(
    (row) =>
      row.hasOwnProperty("__EMPTY") &&
      row.__EMPTY === "3rd person reduction/rate"
  );

  const get3rdPersonIndex2 = Object.values(data).findIndex(
    (row, index) =>
      index > get3rdPersonIndex &&
      row.hasOwnProperty("__EMPTY") &&
      row.__EMPTY === "3rd person reduction/rate"
  );
  // Eğer family room yok ise ve normal room dan 2 tablo var ise 2. tablonun reductionlarının bitiş noktasını bulmak için gerekli index
  const earlyBookingIndex = Object.values(data).findIndex(
    (row) =>
      row.hasOwnProperty("__EMPTY") &&
      row.__EMPTY === "E  A  R  L  Y     B  O  O  K  I  N  G  S"
  );
  // Eğer family room yok ise ve normal room dan 2 tablo var ise 1. tablonun reductionlarının bitiş noktasını bulmak için gerekli index
  const stopReductionIndex = Object.values(data).findIndex(
    (row, index) =>
      index > get3rdPersonIndex &&
      row.hasOwnProperty("__EMPTY") &&
      row.__EMPTY === "P   R   I   C   E   S     &     P  E  R  I  O  D  S"
  );
  const getFamilyRoomIndex = Object.values(data).findIndex(
    (row) => row.hasOwnProperty("__EMPTY_3") && row.__EMPTY_3 === "FAMILY ROOMS"
  );

  // get all rows between standart rooms and family rooms
  const normalBoardsandReductions = data.filter(
    (row, index) =>
      index >= get3rdPersonIndex &&
      index <
        (getFamilyRoomIndex != -1 ? getFamilyRoomIndex : stopReductionIndex)
  );
  const normalBoardsandReductions2 = data.filter(
    (row, index) => index >= get3rdPersonIndex2 && index < earlyBookingIndex
  );

  const normalReductions = normalBoardsandReductions.reduce(
    (acc, row, index) => {
      const reductionObject = {};
      const headerKeys = headers.map(([key, value]) => key);
      Object.keys(row).map((key) => {
        if (headerKeys.includes(key)) {
          reductionObject[data[7][key]] = row[key];
        } else {
          reductionObject[key] = row[key];
        }
      });
      return (acc = {
        ...acc,
        [index]: reductionObject,
      });
    },
    {}
  );
  const normalReductions2 = normalBoardsandReductions2.reduce(
    (acc, row, index) => {
      const reductionObject = {};
      const headerKeys = headers.map(([key, value]) => key);
      Object.keys(row).map((key) => {
        if (headerKeys.includes(key)) {
          reductionObject[data[7][key]] = row[key];
        } else {
          reductionObject[key] = row[key];
        }
      });
      return (acc = {
        ...acc,
        [index]: reductionObject,
      });
    },
    {}
  );

  const getReductionsIndex = Object.values(data).findIndex(
    (row) =>
      row?.__rowNum__ > data[getFamilyRoomIndex]?.__rowNum__ &&
      row?.__EMPTY === "3rd person reduction/rate"
  );

  if (getFamilyRoomIndex && getFamilyRoomIndex != -1) {
  }
  // get family room reductions
  const familyRoomReductions = data.filter(
    (row, index) =>
      getFamilyRoomIndex &&
      getFamilyRoomIndex != -1 &&
      index >= getReductionsIndex
  );

  const familyReductions = familyRoomReductions.reduce((acc, row, index) => {
    const reductionObject = {};
    const headerKeys = headers.map(([key, value]) => key);
    Object.keys(row).map((key) => {
      if (headerKeys.includes(key)) {
        reductionObject[data[7][key]] = row[key];
      } else {
        reductionObject[key] = row[key];
      }
    });
    return (acc = {
      ...acc,
      [index]: reductionObject,
    });
  }, {});

  // const renamedObject = boardsandReductions.map((row) => {
  //   periodHeders.map(([key, headerPeriod], index) => {
  //     if (row[key] !== "" && row[key] !== undefined ) {
  //        if(headerPeriod ===
  //     }
  //   });
  //   return row;
  // });
  if (getFamilyRoomIndex && getFamilyRoomIndex == -1) {
    return {
      normalReductions: normalReductions,
      normalReductions2: normalReductions2,
      familyReductions: {},
    };
  } else {
    return {
      normalReductions: normalReductions,
      familyReductions: familyReductions,
    };
  }
};

// const getReductionWithPeriods = (data,headers) => {
//   data.reduce((acc, row, index) => {
//     const reductionObject = {};
//     const headerKeys = headers.map(([key, value]) => key);
//     Object.keys(row).map((key) => {
//       if (headerKeys.includes(key)) {
//         reductionObject[data[7][key]] = row[key];
//       } else {
//         reductionObject[key] = row[key];
//       }
//     });
//     return (acc = {
//       ...acc,
//       [index]: reductionObject
//     });
//   }, {});

//   return data;
// };
