import {
  GET_HOTELS,
  ADD_HOTEL,
  UPDATE_HOTEL,
  DELETE_HOTEL,
  SELECTED_HOTEL
} from "./types";

export const getHotel = res => dispatch => {
  dispatch({
    type: GET_HOTELS,
    payload: res.data.data
  });
};
export const addHotel = res => dispatch =>
  dispatch({
    type: ADD_HOTEL,
    payload: res.data.data
  });

export const updateHotel = res => dispatch =>
  dispatch({
    type: UPDATE_HOTEL,
    payload: res.data.data
  });

export const deleteHotel = res => dispatch =>
  dispatch({
    type: DELETE_HOTEL,
    payload: res.data.data
  });
export const setSelectedHotel = id => dispatch => {
  dispatch({
    type: SELECTED_HOTEL,
    payload: id
  });
};
export const addMeetPointHotel = res => dispatch =>
  dispatch({
    type: ADD_HOTEL,
    payload: res.data.data.hotel
  });
