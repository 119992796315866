import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { getMasterCompaniesService } from "services/company";
import { setCurrentMasterCompany } from "redux/actions/Company";
import CompanyListRow from "./CompanyListRow";
import CompanyTableHead from "./CompanyTableHead";
import CompanyTableToolbar from "./CompanyTableToolbar";
import { getComparator, stableSort } from "../../../utils/tableHelper";
import AddEditCompany from "./AddEditCompany";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { useDebounce } from "../../../utils/commonHelper";
import useStyles from "./index.style";
import CompanyDetailView from "./CompanyDetailView";
import NoRecordFound from "./NoRecordFound";

const CompaniesModule = () => {
  const classes = useStyles();
  const { masterCompanies } = useSelector(({ company }) => company);
  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [companiesFetched, setCompaniesFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [filteredCompany, setFilteredCompany] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMasterCompaniesService(() => {
        setCompaniesFetched(true);
      })
    );
  }, [dispatch, filterOptions, debouncedSearchTerm]);

  useEffect(() => {
    setFilteredCompany(
      masterCompanies.filter((comp) =>
        comp.name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, masterCompanies]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCompanyView = (company) => {
    dispatch(setCurrentMasterCompany(company));
    setOpenViewDialog(true);
  };

  const handleCompanyEdit = (company) => {
    dispatch(setCurrentMasterCompany(company));
    setOpenCompanyDialog(true);
  };
  const handleCloseCompanyDialog = () => {
    setOpenCompanyDialog(false);
    dispatch(setCurrentMasterCompany(null));
  };

  const handleCompanyDelete = (company) => {
    setSelectedUser(company);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CompanyTableToolbar
          selected={selected}
          setSelected={setSelected}
          onUserAdd={setOpenCompanyDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <CompanyTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredCompany.length}
            />
            <TableBody>
              {filteredCompany.length ? (
                stableSort(filteredCompany, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <CompanyListRow
                      key={index}
                      row={row}
                      onCompanyEdit={handleCompanyEdit}
                      onCompanyDelete={handleCompanyDelete}
                      onCompanyView={handleCompanyView}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {/*    {isFilterApplied ? (
                      <NoRecordFound>{'There are no records found. Loading users...'}</NoRecordFound>
                    ) : ( */}
                    <NoRecordFound>
                      {filteredCompany
                        ? "There are no records found."
                        : "Loading companies..."}
                    </NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filteredCompany.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openCompanyDialog && (
        <AddEditCompany
          open={openCompanyDialog}
          onCloseDialog={handleCloseCompanyDialog}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedUser.name}`}
        content="Are you sure, you want to  delete this user?"
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default CompaniesModule;
