import { Typography } from "@material-ui/core";
import React from "react";

import { MultiSelect } from "react-multi-select-component";

export default function index(props) {
  const {
    label,
    options,
    handleChange,
    error,
    value,
    className,
    ...rest
  } = props;
  const theme = window.localStorage.getItem("theme-type")
    ? window.localStorage.getItem("theme-type")
    : "light";
  let MultiSelectClass = error
    ? "mso error " + theme + " " + className
    : "mso " + theme + " " + className;

  return (
    <React.Fragment>
      {/* If there is a value show the label, if there is no value add padding for the label part */}
      {value.length > 0 && label && (
        <Typography
          style={{ zIndex: 1, color: error ? "red" : "", textAlign: "initial" }}
        >
          {label}
        </Typography>
      )}
      {value.length === 0 && label && (
        <Typography
          style={{
            paddingTop: 18,
            color: error ? "red" : "",
            textAlign: "initial"
          }}
        ></Typography>
      )}
      <MultiSelect
        className={MultiSelectClass}
        options={options}
        value={value}
        onChange={handleChange}
        overrideStrings={{
          selectSomeItems: label ? `Select ${label}` : "Select"
        }}
        {...rest}
      />
    </React.Fragment>
  );
}
