import React, { useEffect, useState } from "react";

import { getAdminCompaniesService } from "services/company";
import { useDispatch, useSelector } from "react-redux";
import { authUserMemo } from "redux/selector/companyHasOfficeSelector";
import CompanyLoginScreen from "./CompanyLogin";


export default function AdminOperations() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  const authUserState = useSelector(authUserMemo);
  const [countryCache, setCountryCache] = useState({});
  const [divisionCache, setDivisionCache] = useState({});
  const [divisionCityCache, setDivisionCityCache] = useState({});
  const [cityCache, setCityCache] = useState({});
  const [subCityCache, setSubCityCache] = useState({});


  return authUserState === 11 || authUserState === 20 ? (
    <CompanyLoginScreen
      countryCache={countryCache}
      divisionCache={divisionCache}
      divisionCityCache={divisionCityCache}
      cityCache={cityCache}
      subCityCache={subCityCache}
      setCountryCache={setCountryCache}
      setDivisionCache={setDivisionCache}
      setDivisionCityCache={setDivisionCityCache}
      setCityCache={setCityCache}
      setSubCityCache={setSubCityCache}
    />
  ) : (
    <h1>Girmeye Yetkiniz Yok</h1>
  );
}
