import { GET_SERVICES, ADD_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from './types';

export const getServices = res => dispatch => {
  dispatch({
    type: GET_SERVICES,
    payload: res.data.data,
  });
};

export const addService = res => dispatch => {
  dispatch({
    type: ADD_SERVICE,
    payload: res.data.data,
  });
};

export const updateService = res => dispatch =>
  dispatch({
    type: UPDATE_SERVICE,
    payload: res.data.data,
  });

export const deleteService = res => dispatch =>
  dispatch({
    type: DELETE_SERVICE,
    payload: res.data.data,
  });
