import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import { getPermissionsByAuthUserService } from "services/permission";
import { getRoutesService } from "services/route";
import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import {
  //resetReducer,
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser
} from "../../../redux/actions/Auth";

import axios from "./config";
import { loggerEndpoint } from "services/axiosLogger";
import jwt from "jwt-decode";
import sendLogService from "services/proxolabLogger/Logger";
import Swal from "sweetalert2";
const JWTAuth = {
  onRegister: ({ name, email, password }) => dispatch => {
    dispatch(fetchStart());
    axios
      .post("auth/register", {
        email,
        password,
        name
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem("token", data.token.access_token);
          axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
          dispatch(fetchSuccess());
          dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
        } else {
          dispatch(fetchError(data.error));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  },

  onLogin: ({ email, password }) => dispatch => {
    try {
      //
      dispatch(fetchStart());
      console.log("test");

      axios
        .post("login", {
          email,
          password
        })
        .then(async ({ data }) => {
          // console.log(data);
          // console.log(jwt(data.token.access_token));
          // jwt(localStorage.getItem("token"));
          if (data) {
            await localStorage.setItem("token", data.token.access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
            dispatch(fetchSuccess("Login Successful"));
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
            dispatch(getRoutesService());
            let parsedToken = jwt(data.token.access_token);
            await fetch(loggerEndpoint + "userlogininfo", {
              headers: {
                Authorization: localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              method: "POST",
              body: JSON.stringify({
                email: parsedToken.email,
                id: parsedToken.id,
                name: parsedToken.fullname,
                location: window.location,
                project: "bot_proxolab"
              })
            })
              .then(function(res) {
                window.location.reload();
              })
              .catch(function(res) {
                console.log(res);
              });
          }
        })
        .catch(error => {
          dispatch(fetchError(""));
          Swal.fire({
            title: "Error!",
            text: error.response.data.status,
            icon: "error",
            confirmButtonText: "Cool"
          });
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  },
  onMasterLogin: ({ email, password }) => dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post("master/login", {
          email,
          password
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem("token", data.token.access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token.access_token}`;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  },
  onLogout: () => dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem("token");
    // localStorage.removeItem('token');
    // localStorage.removeItem('selectedCompany');
    dispatch(setAuthUser(null));
    axios
      .post("logout", token)
      .then(async data => {
        if (data) {
          let parsedToken = jwt(data.token.access_token);
          await fetch(loggerEndpoint + "userlogoutinfo", {
            headers: {
              Authorization: localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
              email: parsedToken.email,
              id: parsedToken.id,
              name: parsedToken.fullname,
              location: window.location,
              project: "bot_proxolab"
            })
          })
            .then(function(res) {
              window.location.reload();
            })
            .catch(function(res) {
              console.log(res);
            });

          await dispatch(fetchSuccess());
          await localStorage.removeItem("token");
          await localStorage.removeItem("selectedCompany");
          await localStorage.removeItem("userCompanies");
          await localStorage.removeItem("selectedOffice");
          await localStorage.removeItem("userOffices");
          dispatch(setAuthUser(null));
        } else {
          dispatch(fetchError(data.error));
        }
        // dispatch(resetReducer());
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  },

  getAuthUser: (loaded = false, token) => dispatch => {
    if (!token) {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    dispatch(updateLoadUser(loaded));
    axios
      .get("user")
      .then(({ data }) => {
        if (data.result) {
          dispatch(setAuthUser(data.user));
          dispatch(getPermissionsByAuthUserService());
        } else {
          localStorage.removeItem("token");
          dispatch(updateLoadUser(true));
        }
      })
      .catch(error => {
        localStorage.removeItem("token");
        dispatch(updateLoadUser(true));
      });
  },

  onForgotPassword: () => dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(setForgetPassMailSent(true));
      dispatch(fetchSuccess());
    }, 300);
  },
  getSocialMediaIcons: () => <div> </div>
};

export default JWTAuth;
