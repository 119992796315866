import { normalizeAirportCodes } from 'redux/schemas/airportCode';
import { GET_AIRPORT_CODE } from '../actions/types';

const initialState = {
  airportCodes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AIRPORT_CODE:
      const normalizeAirportCodes = normalizeAirportCodes(action.payload);
      return { ...state, airportCodes: normalizeAirportCodes };
    default:
      return state;
  }
};
