import { GET_STAY_TYPES } from '../actions/types';

const initialState = {
  stayTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STAY_TYPES:
      return { ...state, stayTypes: action.payload };
    default:
      return state;
  }
};
