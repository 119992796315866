import { Box, LinearProgress } from '@material-ui/core';
import React from 'react';

export default function Loading() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}
