import React from "react";
import { Grid, TextField } from "@material-ui/core";

const TextFilter = props => {
  const { placeholder, name, onChangeHandler } = props;

  return (
    <Grid item xs={12} sm={6} md={3} lg={2} style={{ padding: 6 }}>
      <TextField
        style={{ width: "100%" }}
        required
        placeholder={placeholder}
        name={name}
        onChange={onChangeHandler}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  );
};
export default TextFilter;
