import { GET_CURRENCY } from './types';
import { normalizeCurrencies } from 'redux/schemas/currencies';

export const getCurrencies = res => dispatch => {
  const normalizedCurrencies = normalizeCurrencies(res.data.data);
  dispatch({
    type: GET_CURRENCY,
    payload: normalizedCurrencies.entities,
  });
};
