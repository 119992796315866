import React from "react";
import moment from "moment";
import { getDynamicValue } from "./getDynamicValue";
import { getReductions } from "./getReductionsBoards";
export const getPeriods = (data) => {
  const periods = [];

  const basicRoomHeaderIndex = Object.values(data[7]).indexOf(
    "Basic Room for CHD Reduction"
  );

  const periodHeders = Object.values(data[7]).splice(
    1,
    basicRoomHeaderIndex - 1
  );
  const minimumNights = getDynamicValue(data[4], "Minimum nights");
  const reductions = getReductions(data);

  let reductionRelease;
  if (Object.values(reductions.normalReductions).length > 0) {
    reductionRelease = Object.values(reductions.normalReductions).find(
      (reduction) => reduction.__EMPTY === "RELEASE DAYS"
    );
  } else {
    reductionRelease = Object.values(reductions.familyReductions).find(
      (reduction) => reduction.__EMPTY === "RELEASE DAYS"
    );
  }
  const releaseIndex = Object.values(data).findIndex(
    (row, index) =>
      row.hasOwnProperty("__EMPTY") && row.__EMPTY === "RELEASE DAYS"
  );
  const getFromPeriods = [];
  const getToPeriods = [];

  data.forEach((row, index) => {
    if (row.__EMPTY_2 === "FROM") {
      getFromPeriods.push(row);
    }
    if (row.__EMPTY_2 === "TO") {
      getToPeriods.push(row);
    }
  });

  periodHeders.map((headerPeriod, index) => {
    Object.entries(data[7]).map(([key, dataPeriod], index) => {
      if (dataPeriod === headerPeriod) {
        for (let i = 0; i < getFromPeriods.length; i++) {
          if (
            index < releaseIndex &&
            getFromPeriods[i] !== undefined &&
            getFromPeriods[i][key] !== "" &&
            getFromPeriods[i][key] !== undefined
          ) {
            const periodObject = {};
            periodObject.from = getFromPeriods[i][key];
            periodObject.to = getToPeriods[i][key];
            periodObject.code = headerPeriod;
            periodObject.min_stay = minimumNights;
            periodObject.release = reductionRelease[headerPeriod];
            periods.push(periodObject);
          }
        }
      }
    });
  });

  periods.sort((a, b) => {
    return moment(a.from, "DD.MM.YYYY").diff(moment(b.from, "DD.MM.YYYY"));
  });

  const formattedPeriods = periods.map((period, index) => {
    const formattedTo = moment(period.to, "DD.MM.YYYY").format("YYYY-MM-DD");
    const formattedFrom = moment(period.from, "DD.MM.YYYY").format(
      "YYYY-MM-DD"
    );

    return {
      ...period,
      from: formattedFrom,
      to: formattedTo,
    };
  });

  //   const fromPeriodsDates = getPeriodsDates(getFromPeriods, "FROM");

  //   const toPeriodsDates = getPeriodsDates(getToPeriods , "TO");

  //   console.log(fromPeriodsDates);
  //   console.log(toPeriodsDates);

  //   periodHeders.forEach((period, headIndex) => {
  //      fromPeriodsDates.map((fromPeriod, dateIndex) => {
  //         fromPeriod[headIndex] && (periods[period] = fromPeriod[headIndex]);

  // periodHeders.forEach((period, index) => {
  //     periods[period] = {
  //         from: fromPeriodDates[index],
  //         to: toPeriodDates[index],
  //         period : period
  //     }
  // })

  //   debugger;
  //   console.log(periods);
  return formattedPeriods;
};
