import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  addAddPaxRates,
  getAddPaxRates,
  deleteAddPaxRates,
  updateAddPaxRates
} from "../redux/actions/addPaxRates";
import axios from "./axios";

/** PROXOLAB LOGGER * */
import sendLogService from "./proxolabLogger/Logger.js";
/** PROXOLAB LOGGER * */

export const addAdditionalPaxService = data => dispatch => {
  return axios
    .post("/additionalPax", data)
    .then(res => {
      const { periods, ...additionalPax } = data;
      periods.forEach(period => {
        additionalPax[period.id] = period.value;
      });
      additionalPax.id = res.data.data.id;
      dispatch(addAddPaxRates(additionalPax));
      sendLogService.additionalPaxServiceLog(res, data);
      return res.data.code;
    })
    .catch(err => {
      sendLogService.errorlog(err, data);
    });
};

export const getAdditionalPaxService = contractId => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("/additionalPax", {
      params: {
        contractId
      }
    })
    .then(({ data }) => {
      const additionalPax = data.reduce((addPax, value) => {
        const { get_pax_rates, get_pax_rooms, ...additionPax } = value;
        const periods = get_pax_rates.forEach(paxRate => {
          const { period_id, value } = paxRate;
          additionPax[period_id] = value;
        });
        const rooms = get_pax_rooms.map(room => {
          const { contract_room_id } = room;
          return `${contract_room_id}`;
        });
        additionPax.periods = periods;
        additionPax.rooms = rooms;
        addPax.push(additionPax);
        return addPax;
      }, []);
      dispatch(getAddPaxRates(additionalPax));
    });
};

export const deleteAdditionalPaxService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .delete(`/additionalPax/${id}`)
    .then(res => {
      dispatch(deleteAddPaxRates(id));
      sendLogService.additionalPaxServiceLog(res, id);
    })
    .catch(err => {
      sendLogService.errorlog(err, id);
    });
};

export const updateAdditionalPaxService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .put(`/additionalPax`, data)
    .then(res => {
      const { periods, ...additionalPax } = data;
      periods.forEach(period => {
        additionalPax[period.id] = period.value;
      });

      dispatch(updateAddPaxRates(additionalPax));
      sendLogService.additionalPaxServiceLog(res, data);
      return res.data.code;
    })
    .catch(err => {
      sendLogService.errorlog(err, data);
    });
};

export const addMeetingPointAdditionalPaxService = data => dispatch => {
  return axios
    .post("/createAddPaxes", data)
    .then(res => {
      sendLogService.additionalPaxServiceLog(res, data);
      return res.data.code;
    })
    .catch(err => {
      sendLogService.errorlog(err, data);
    });
};
