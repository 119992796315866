export const getRooms = (data) => {
  const standartRooms = [];
  const standartRooms2 = [];

  const basicRoomHeaderIndex = Object.values(data[7]).indexOf(
    "Basic Room for CHD Reduction"
  );
  let getFirstMaximumIndex = Object.entries(data[7]).findIndex(
    ([key, value]) => {
      return value === "Maximum";
    }
  );

  const headers = Object.entries(data[7]).map(([key, value], index) => {
    // get first maximum
    if (index === getFirstMaximumIndex) {
      return [key, "Max Person"];
    } else if (getFirstMaximumIndex + 1 === index) {
      return [key, "Min Person"];
    } else if (getFirstMaximumIndex + 2 === index) {
      return [key, "Max Adult"];
    } else if (getFirstMaximumIndex + 3 === index) {
      return [key, "Min Adult"];
    } else if (getFirstMaximumIndex + 4 === index) {
      return [key, "Max Child"];
    } else if (getFirstMaximumIndex + 5 === index) {
      return [key, "Min Child"];
    } else {
      return [key, value];
    }
  });

  const getroomIndex = Object.values(data).findIndex(
    (row) =>
      row.hasOwnProperty("__EMPTY_3") && row.__EMPTY_3 === "STANDARD ROOMS"
  );
  const getroomIndex2 = Object.values(data).findIndex(
    (row, index) =>
      row.hasOwnProperty("__EMPTY_3") &&
      row.__EMPTY_3 === "STANDARD ROOMS" &&
      index > getroomIndex
  );

  if (data[getroomIndex + 1].__rowNum__ !== data[getroomIndex].__rowNum__ + 1) {
    return standartRooms;
  }
  if (
    getroomIndex2 &&
    getroomIndex2 != -1 &&
    data[getroomIndex2 + 1]?.__rowNum__ !== data[getroomIndex2]?.__rowNum__ + 1
  ) {
    return standartRooms2;
  }

  const get3rdPersonIndex = Object.values(data).findIndex(
    (row) =>
      row.hasOwnProperty("__EMPTY") &&
      (row.__EMPTY === "3rd person reduction/rate" ||
        row.__EMPTY === "3rd person reduction")
  );
  for (let i = getroomIndex + 1; i < get3rdPersonIndex; i++) {
    standartRooms.push(data[i]);
  }

  if (getroomIndex2 && getroomIndex2 != -1) {
    const get3rdPersonIndex2 = Object.values(data).findIndex(
      (row, index) =>
        row.hasOwnProperty("__EMPTY") &&
        index > getroomIndex2 &&
        (row.__EMPTY === "3rd person reduction/rate" ||
          row.__EMPTY === "3rd person reduction")
    );
    for (let i = getroomIndex2 + 1; i < get3rdPersonIndex2; i++) {
      standartRooms2.push(data[i]);
    }
  }

  const standartRoomPrices = standartRooms.reduce((acc, room) => {
    const roomObject = {};
    roomObject.roomName = room.__EMPTY_1;
    roomObject.roomCode = room.__EMPTY;
    headers.map(([key, headerPeriod], index) => {
      if (room[key] !== "" && room[key] !== undefined) {
        roomObject[headerPeriod] = room[key];
      }
    });
    acc.push(roomObject);
    return acc;
  }, []);

  if (getroomIndex2 && getroomIndex2 != -1) {
    const standartRoomPrices2 = standartRooms2.reduce((acc, room) => {
      const roomObject = {};
      roomObject.roomName = room?.__EMPTY_1;
      roomObject.roomCode = room?.__EMPTY;
      headers.map(([key, headerPeriod], index) => {
        if (room[key] !== "" && room[key] !== undefined) {
          roomObject[headerPeriod] = room[key];
        }
      });
      standartRoomPrices.push(roomObject);
    }, []);
  }

  return standartRoomPrices;
};
