import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getOperatorRooms,
  getMatchedRoomsByHotel
} from "redux/actions/operatorRooms";
import axios from "./axios";

export const getOperatorRoomsService = (operator_ids, hotelId) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("/operatorRooms", {
      params: { operator_ids, global_hotel_id: hotelId }
    })
    .then(res => {
      dispatch(getOperatorRooms(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getMatchedRoomsByHotelService = hotelId => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("/matchedRooms", { params: { global_hotel_id: hotelId } })
    .then(res => {
      dispatch(getMatchedRoomsByHotel(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
