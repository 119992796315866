import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import {
  getPaymentConditions,
  addPaymentConditions,
  updatePaymentConditions,
  deletePaymentConditions,
} from 'redux/actions/paymentConditions';
import axios from './axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/** PROXOLAB LOGGER * */
import sendLogService from './proxolabLogger/Logger.js';
/** PROXOLAB LOGGER * */

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
export const getPaymentConditionsService = contract_id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('paymentConditions', {
      params: {
        cId: contract_id,
      },
    })
    .then(res => {
      dispatch(getPaymentConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addPaymentConditionsService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('paymentConditions', data)
    .then(res => {
      dispatch(addPaymentConditions(res));
      dispatch(fetchSuccess());
      sendLogService.paymentServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updatePaymentConditionsService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('paymentConditions', data)
    .then(res => {
      dispatch(updatePaymentConditions(res));
      dispatch(fetchSuccess());
      sendLogService.paymentServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deletePaymentConditionsService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`paymentConditions/${id}`)
    .then(res => {
      dispatch(deletePaymentConditions(res));
      dispatch(fetchSuccess());
      sendLogService.paymentServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
