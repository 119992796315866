function IsInfiniteLoop(contract_rooms, new_ref_id, contract_room_no) {
  let temp_ref_id = Object.values(contract_rooms).find(
    i => i.contract_room_no == new_ref_id
  ).reference_rate_id;
  if (Number(temp_ref_id) === Number(contract_room_no)) {
    return false;
  } else {
    if (
      Number(temp_ref_id) == 0 ||
      new_ref_id == 0 ||
      contract_room_no == new_ref_id
    ) {
      return true;
    } else {
      return IsInfiniteLoop(contract_rooms, temp_ref_id, contract_room_no);
    }
  }
}

export default IsInfiniteLoop;
