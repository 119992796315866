import { normalize, schema } from 'normalizr';

const services = new schema.Entity(
  'services',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  services: [services],
  addStateServices: [services],
  updateStateServices: [services],
  deleteStateServices: [services],
};
export const normalizeServices = data => normalize(data, [response]);
