import { GET_MULTIPLE } from '../actions/types';
import { normalizeMultiple } from '../schemas/multiple';

const initialState = {
  multiple: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MULTIPLE:
      const normalizedMultiple = normalizeMultiple(action.payload);
      return { ...state, multiple: normalizedMultiple.entities.multiple };
    default:
      return state;
  }
};
