import { normalizeRoomMatches } from "redux/schemas/roomMatch";
import {
  GET_ROOM_MATCH,
  ADD_ROOM_MATCH,
  UPDATE_ROOM_MATCH,
  DELETE_ROOM_MATCH
} from "../../redux/actions/types";

const initialState = {
  matched_rooms: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROOM_MATCH:
      let data = [];
      data["rooms"] = action.payload;
      const normalizedData = normalizeRoomMatches([data]);

      return {
        ...state,
        matched_rooms: normalizedData.entities.rooms
      };

    case UPDATE_ROOM_MATCH:
      let updatedNormalizedData = [];
      if (action.payload.key == "add") {
        // delete action.payload["key"];
        const updatedData = [];
        updatedData["updatedRooms"] = action.payload;
        updatedData["stateRooms"] = state.matched_rooms;
        updatedNormalizedData = normalizeRoomMatches([updatedData]);
        console.log(updatedData);
        console.log(updatedNormalizedData);
      } else {
        // delete action.payload["key"];
        action.payload.map(room => {
          state.matched_rooms[room].global_room_id = null;
        });
        updatedNormalizedData = state.matched_rooms;
      }
      return {
        ...state,
        matched_rooms: updatedNormalizedData.entities
          ? updatedNormalizedData.entities.matched_rooms
          : updatedNormalizedData
      };
    default:
      return state;
  }
};
