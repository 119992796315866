// import axios from "axios";
// import jwt from "jwt-decode"
// export let goDomain ="http://localhost:8080"

// export let apihost = "http://localhost:80";
// const urls={
//   "Demo Company":`http://127.0.0.1:8000/`,
//   "Keyf Travel":`http://127.0.0.1:8000/`
// }
// const token = localStorage.getItem("token")?localStorage.getItem("token"):"";
// let parsedToken = token != "" ? jwt(token):"";

// export default axios.create({
//   baseURL: urls[parsedToken["company_name"]],
//   headers: {
//     "Content-Type": "application/json",
//     // Authorization: `Bearer ${token}`,
//   },
// });

//live and stage

import axios from "axios";
import jwt from "jwt-decode"

export let apihost = "https://bot.proxolab.com";
export let goDomain = "https://bot.proxolab.com:81";
const urls={
  "Meeting Point":`https://mpbot.mundosai.com/`,
  "Keyf Travel":`https://ktbot.mundosai.com/`,
  "UITESTTEAM":`https://stagebot.mundosai.com/`
}
const token = localStorage.getItem("token")?localStorage.getItem("token"):"";
let parsedToken = token != "" ? jwt(token):"";

export default axios.create({
  baseURL: urls[parsedToken["company_name"]],
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  },
});


