import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import CardHeader from "@mui/material/CardHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Button,
  FormControl,
  Select,
  Grid,
  MenuItem,
  Dialog,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { getHotelService } from "services/hotels";
import { getRoomService } from "services/rooms";
import { getOperatorService } from "services/operator";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getOfferMongoDBService, sendBotOffersService } from "services/offers";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import OfferListModal from "./OfferListModal";
import { getMatchedRoomsByHotelService } from "services/operatorRooms";
import { getContractsByHotelService } from "services/operatorContracts";
import _ from "lodash";
import { peakpointCookieController } from "@jumbo/utils/cookieController";
import { getMatchHotelDataService } from "services/hotelmatch";
import { getOperatorHotelsService } from "services/operatorhotels";
import Swal from "sweetalert2";
import OfferTable from "./OfferTable";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import MultiSelect from "../Common/MultiSelect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useIntl } from "react-intl";
import { getBoardsService } from "services/boards";
import { isNumeric } from "@jumbo/utils/isNumeric";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "auto",
  },
  container: {
    // margin: theme.spacing(1),
    paddingTop: "10px",
  },
  rootCard: {
    width: "100%",
    // margin: "0 auto",
    marginTop: "20px",
    boxShadow: "0px 0px 10px #949494",
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    "& .MuiCardContent-root": {
      height: "25em",
    },
    overflow: "inherit",
    "& .MuiCard-root ": {
      overflow: "inherit",
      boxShadow:
        "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
      borderRadius: "4px",
    },
  },
  rootCardItem: {
    display: "flex",
    justifyContent: "space-around",
  },
  gridStyle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(2),
    justifyContent: "flex-start",
  },

  gridStyle2: {
    textAlign: "right",
  },
  grid: {
    display: "flex",
    width: "100%",
  },
  textFields: {
    marginRight: theme.spacing(3),
  },
  Button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(-4),
    justifyContent: "flex-start",
    letterSpacing: "0.25px !important",
    paddingBottom: theme.spacing(2),
  },
  AddButton: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(-4),
    justifyContent: "flex-start",
    letterSpacing: "0.25px !important",
    paddingBottom: theme.spacing(2),
    backgroundColor: "#ffa726",
  },
  Button2: {
    display: "flex",
    gap: "15px",
    margin: "32px 4px 8px 4px",
  },
  padding: {
    padding: "25px 15px 15px 15px",
  },
  margin: {
    marginTop: "35px",
  },
  multiselectStyle: {
    "&  .dropdown-content": {
      position: "absolute !important",
      color:
        window.localStorage.getItem("theme-type") == "dark" ? "white" : "grey",
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#363636"
          : "white",
    },
    "& .dropdown-container": {
      color:
        window.localStorage.getItem("theme-type") == "dark" ? "white" : "grey",
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#363636"
          : "white",
    },
    "& .dropdown-content .panel-content": {
      color:
        window.localStorage.getItem("theme-type") == "dark" ? "white" : "grey",
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#363636"
          : "white",
    },
    "&  .search input:focus": {
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark" ? "#363636" : "",
    },
    "& .select-item.selected": {
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark" ? "#636262" : "",
    },
    "& .select-item:hover": {
      backgroundColor:
        window.localStorage.getItem("theme-type") == "dark" ? "#636262" : "",
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "0.8rem",
      },
    },
  },
});

const operatorIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16];

export default function Offers() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    dispatch(getHotelService());
    dispatch(getRoomService());
    dispatch(getOperatorService());
    dispatch(getMatchHotelDataService());
    dispatch(getOperatorHotelsService(operatorIds));
    dispatch(getBoardsService());
  }, [dispatch]);
  const childAdultCounter = {
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
  };
  // const [errorControlFromRoom, setErrorControlFromRoom] = useState(true);
  // const [amountError, setAmountError] = useState(true);
  // const [rateError, setRateError] = useState(true);
  // const [applyError, setApplyError] = useState(true);
  // const [errorControlToRoom, setErrorControlToRoom] = useState(true);
  // const [subTypeError, setSubTypeError] = useState(true);
  const [selected, setSelected] = useState({
    hotelId: "",
    sellStartDate: "",
    sellEndDate: "",
    operators: [],
    contracts: "",
    offer_type: "",
    offer_sub_type: "",
    pax1_percent: "",
    pax2_percent: "",
    pax3_percent: "",
    pax4_percent: "",
    valid_for_extras: false,
    offer_type_name: "",
  });
  //For keyboard date picker
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;

  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const MySwal = withReactContent(Swal);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { contracts } = useSelector(
    ({ operatorContracts }) => operatorContracts
  );
  const { operators } = useSelector(({ operators }) => operators);
  const { matched_rooms } = useSelector(({ operatorRooms }) => operatorRooms);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { types } = useSelector(({ rooms }) => rooms.rooms);
  const { promos } = useSelector(({ rooms }) => rooms.rooms);
  const { subTypes } = useSelector(({ rooms }) => rooms.rooms);
  const { views } = useSelector(({ rooms }) => rooms.rooms);
  const { grades } = useSelector(({ rooms }) => rooms.rooms);
  const { bases } = useSelector(({ rooms }) => rooms.rooms);
  const [tableFlag, setTableFlag] = useState(false);
  const { botOffers } = useSelector(({ offers }) => offers);
  const [validForExtras, setValidForExtras] = useState(false);
  const [allRooms, setAllRooms] = useState(false);

  const [jumboControl, setJumboControl] = useState(false);
  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthGet = permissionsByAuthUser.some(
    (permission) => 6 == permission.route_id && permission.get === 1
  );
  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => 6 == permission.route_id && permission.post === 1
  );
  const {
    SHhotels,
    WBhotels,
    PPhotels,
    WEBBEDShotels,
    IVChotels,
    JUMBOhotels,
    W2Mhotels,
    PThotels,
  } = useSelector(({ operatorHotels }) => operatorHotels);
  const { authUser } = useSelector(({ auth }) => auth);

  const initialOffersState = [
    {
      stayStartDate: "",
      stayEndDate: "",
      applyRateType: "",
      applyType: "",
      offerRoom: [],
      offerName: "",
      minStay: 1,
      maxStay: 99,
      max_child: "",
      min_child: "",
      percent: "",
      amount: "",
      stayNights: "",
      freeNight: "",
      free_night_type: "",
      multiple: "",
      payment_start_date: "",
      payment_end_date: "",
      payment_percent: "",
      pax1_percent: "",
      pax2_percent: "",
      pax3_percent: "",
      pax4_percent: "",
      valid_for_extras: validForExtras,
      all_rooms: false,
    },
  ];
  const [offers, setOffers] = useState(initialOffersState);
  const [control, setControl] = useState(false);
  const [showOfferDiscounts, setShowOfferDiscounts] = useState(false);
  const [contractsOptions, setContractsOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [hotelName, setHotelName] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isLMTSelected, setisLMTSelected] = useState(false);
  const [buttonControl, setButtonControl] = useState(false);
  const [minStayControl, setMinStayControl] = useState(false);

  useEffect(() => {
    if (Object.values(matched_hotels) !== []) {
      let hotelsInfo = [];
      Object.values(matched_hotels).map((item) => {
        hotelsInfo.push({
          id: hotels[item.global_hotel_id]?.id,
          name: hotels[item.global_hotel_id]?.name,
        });
      });
      setHotelName(hotelsInfo);
    }
  }, [matched_hotels, hotels]);

  // Her 10 saniyede bir istek atacak ve eğer istek atıldığında active data gelirse modal açık kaldığı sürece bekleyecek olan useEffect
  useEffect(() => {
    if (control && authUser) {
      const interval = setInterval(() => {
        dispatch(
          getOfferMongoDBService({
            userEmail: authUser.email,
            hotelId: selected.hotelId,
          })
        );
      }, 10 * 1000);
      return () => clearInterval(interval);
    }
  }, [control]);
  useEffect(() => {
    let matchedHotel = Object.values(matched_hotels);
    let chunkOperator = [];
    let cOperator = [];
    let matchedRooms = Object.values(matched_rooms);
    let selectedOperator = Object.values(offerTypes).find((offer_type) => {
      return offer_type.id == selected.offer_type;
    });
    if (selected.offer_type && selectedOperator && matchedRooms.length > 0) {
      let offerHasSubType = "subTypes" in selectedOperator;

      let offer_type_operator = offerHasSubType
        ? Object.values(offerTypes).find((offer_type) => {
            return offer_type.id == selected.offer_type;
          })?.subTypes[selected.offer_sub_type]
        : Object.values(offerTypes).find((offer_type) => {
            return offer_type.id == selected.offer_type;
          })?.operators;
      Object.values(matchedHotel).map((item) => {
        let keys = Object.keys(item);
        keys.forEach((value) => {
          if (value !== "global_hotel_id") {
            if (
              selected.hotelId == item[value].global_hotel_id &&
              (offer_type_operator
                ? offer_type_operator?.includes(value)
                : true) &&
              matchedRooms.find(
                (room) => room.operator_hotel_id == item[value].id
              ) != undefined
            ) {
              chunkOperator.push({
                id: item[value].operator_id,
                label: operators[item[value].operator_id].name,
                code: value,
              });
              cOperator.push(item[value].operator_id);
            }
          }
        });
      });
      setSelected({
        ...selected,
        operators: selected.operators.filter(
          (room) => cOperator.indexOf(room.id) !== -1
        ),
      });
      setOperatorOptions(chunkOperator);
    }
  }, [
    operators,
    selected.hotelId,
    selected.offer_type,
    selected.offer_sub_type,
    matched_rooms,
  ]);

  //global rooms state will change by selected hotel
  useEffect(() => {}, [
    selected.operators,
    // selected.hotelId,
    // selected.offer_type,
    // selected.offer_sub_type,
    //operatore bağlı gelmesi gerektiği için
  ]);

  //contracts state will change by selected hotel and operator
  useEffect(() => {
    let selectedOperators = selected.operators?.filter((op) => op.id !== 3);
    if (selectedOperators?.length > 0) {
      dispatch(
        getContractsByHotelService(
          _.map(selectedOperators, "id"),
          selected.hotelId
        )
      );
    }
    let selectedJumboOperators = selected.operators?.find((op) => op.id == 9);
    if (selectedJumboOperators != undefined && selectedJumboOperators) {
      setJumboControl(true);
    } else {
      setJumboControl(false);
    }
  }, [selected.operators]);

  useEffect(() => {
    let chunkContracts = [];
    if (contracts) {
      Object.values(contracts).map((contract) => {
        chunkContracts.push({
          id: contract.contract_id,
          label: contract.contract_name,
        });
      });
      setContractsOptions(chunkContracts);
    }
  }, [contracts]);

  let addDateFields = () => {
    setOffers([
      ...offers,
      {
        stayStartDate: "",
        stayEndDate: "",
        applyRateType: "",
        applyType: "",
        offerRoom: allRooms ? offers[0].offerRoom : "",
        offerName: "",
        minStay: 1,
        maxStay: 99,
        max_child: "",
        min_child: "",
        percent: "",
        amount: "",
        stayNights: "",
        freeNight: "",
        free_night_type: "",
        multiple: "",
        payment_start_date: "",
        payment_end_date: "",
        payment_percent: "",
        pax1_percent: "",
        pax2_percent: "",
        pax3_percent: "",
        pax4_percent: "",
        valid_for_extras: validForExtras,
        all_rooms: allRooms,
      },
    ]);
  };
  let removeDateFields = (i) => {
    let newOffers = [...offers];
    newOffers.splice(i, 1);
    setOffers(newOffers);
  };

  const disabledControl = () => {
    if (
      selected.hotelId != "" &&
      !buttonControl &&
      selected.hotelId != {} &&
      selected.operators.length > 0
    ) {
      return false;
    }
    return true;
  };
  const handleChange = (name, value) => {
    // Operatörlerde jumbo seçili ise max ve min child eklemek için kontrole eklediğimiz değişken setliyoruz
    if (name == "operators") {
      let selectedJumboOperators = value?.find((op) => op.id == 9);
      if (selectedJumboOperators != undefined && selectedJumboOperators) {
        setJumboControl(true);
      } else {
        setJumboControl(false);
      }
    }
    if (name == "hotel_id") {
      setTableFlag(false);
      setControl(true);
      setSelected({
        hotelId: value.id,
        sellStartDate: "",
        sellEndDate: "",
        operators: [],
        contracts: "",
        offer_type: "",
        rooms: [],
        offer_sub_type: "",
        contracts: "",
      });
      setOffers(initialOffersState);
      dispatch(
        getOfferMongoDBService({
          userEmail: authUser.email,
          hotelId: value.id,
        })
      );
      if (!value.hasOwnProperty("id")) {
        setControl(false);
      }
      dispatch(getMatchedRoomsByHotelService(value.id));
      setisLMTSelected(false);
    } else if (name == "sell_start_date") {
      setSelected({
        ...selected,
        sellStartDate: moment(value).format("YYYY-MM-DD"),
      });
    } else if (name == "sell_end_date") {
      setSelected({
        ...selected,
        sellEndDate: moment(value).format("YYYY-MM-DD"),
      });
    } else if (name == "offer_type") {
      // operatorIsInSelected(value);
      setisLMTSelected(false);
      setOffers(initialOffersState);
      setSelected({
        ...selected,
        hotelId: selected.hotelId,
        operators: [],
        endDate: null,
        startDate: null,
        rooms: [],
        offer_type: value,
        offer_sub_type: "",
        contracts: "",

        valid_for_extras: validForExtras,
      });
    } else if (name == "offer_sub_type") {
      setSelected({ ...selected, offer_sub_type: value });
    } else if (name == "valid_for_extras") {
      setSelected({ ...selected, valid_for_extras: value });
    } else if (name == "onlyOperatorSH") {
      setSelected({
        ...selected,
        contracts: value,
        SHcontract: value,
      });
    } else if (name == "onlyOperatorPP") {
      setSelected({
        ...selected,
        contracts: value,
        PPcontract: value,
      });
    } else if (name == "onlyOperatorWB") {
      setSelected({
        ...selected,
        WBcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorWEBBEDS") {
      setSelected({
        ...selected,
        WEBBEDScontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorIVC") {
      setSelected({
        ...selected,
        IVCcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorW2M") {
      setSelected({
        ...selected,
        W2Mcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorPT") {
      setSelected({
        ...selected,
        PTcontract: value,
        contracts: value,
      });
    } else if (name == "JUMBOcontract" || name == "onlyOperatorJUMBO") {
      //if jumbo selected rooms change when operator contract selected
      //jumbocontract set in handlechangeoperator
      handleChangeOperator(selected.operators, value, name);
    } else if (name == "all_rooms") {
      let getRoomValue = multiDataSetter("name", "id", roomOptions);
      if (value) {
        offers.map((offer) => {
          offer["all_rooms"] = value;
          offer["offerRoom"] = getRoomValue;
        });
      } else {
        offers.map((offer) => {
          offer["all_rooms"] = value;
          offer["offerRoom"] = [];
        });
      }
    } else {
      setSelected({ ...selected, [name]: value });
    }
  };

  const hasOfferRooms = (offers) => {
    return offers.every((offer) => {
      return (
        offer.hasOwnProperty("offerRoom") &&
        offer.offerRoom !== " " &&
        offer.offerRoom.length > 0
      );
    });
  };

  const hasOfferName = (offers) => {
    return offers.every((offer) => {
      return offer.hasOwnProperty("offerName") && offer.offerName !== "";
    });
  };

  const hasOfferType = (offers) => {
    return offers.every((offer) => {
      return (
        selected.hasOwnProperty("offer_type") &&
        selected.offer_type !== "" &&
        (selected.type == 3 || selected.type == 4
          ? selected.hasOwnProperty("offer_sub_type") &&
            selected.offer_sub_type !== ""
          : true)
      );
    });
  };
  const contractControl = () => {
    if (selected.operators) {
      let contractIds = selected.operators.reduce((acc, current) => {
        if (
          current.id == 1 ||
          current.id == 2 ||
          current.id == 4 ||
          current.id == 8 ||
          current.id == 9 ||
          current.id == 12 ||
          current.id == 16 ||
          current.id == 15
        ) {
          acc.push(current.id);
        }
        return acc;
      }, []);
      if (contractIds.length >= 2) {
        return contractIds;
      } else {
        return contractIds;
      }
    }
  };

  let contractOptions = contractControl();

  let ShContracts = [];
  let PpContracts = [];
  let WBcontracts = [];
  let WEBBEDScontracts = [];
  let JUMBOcontracts = [];
  let IVCcontracts = [];
  let W2Mcontracts = [];
  let PTcontracts = [];

  const newOpContracts = () => {
    let filteredHotels = {
      ...SHhotels,
      ...PPhotels,
      ...WBhotels,
      ...WEBBEDShotels,
      ...IVChotels,
      ...JUMBOhotels,
      ...W2Mhotels,
      ...PThotels,
    };

    Object.values(filteredHotels).map((item) => {
      if (contracts) {
        Object.values(contracts).map((contract) => {
          if (item.id == contract.operator_hotel_id) {
            contract["operator_id"] = item.operator_id;
          }
        });
      }
    });

    contracts &&
      Object.values(contracts).map((cont) => {
        if (cont.operator_id == 1) {
          PpContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 2) {
          ShContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 4) {
          WBcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 8) {
          WEBBEDScontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 9) {
          JUMBOcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 12) {
          IVCcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 15) {
          W2Mcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 16) {
          PTcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        }
      });
  };

  const hasOperatorsWithContract = (selected) => {
    let hasOperatorsWithContracts = selected.operators.reduce((acc, op) => {
      if (op.id == 1) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 2 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 12 ||
              op.id == 16 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("PPcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 2) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 12 ||
              op.id == 16 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("SHcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 4) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 12 ||
              op.id == 16 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("WBcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 8) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 4 ||
              op.id == 9 ||
              op.id == 12 ||
              op.id == 16 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("WEBBEDScontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 9) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 12 ||
              op.id == 16 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("JUMBOcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 12) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 15 ||
              op.id == 16
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("IVCcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 15) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 16 ||
              op.id == 12
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("W2Mcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      } else if (op.id == 16) {
        if (
          selected.operators.find(
            (op) =>
              op.id == 1 ||
              op.id == 2 ||
              op.id == 4 ||
              op.id == 8 ||
              op.id == 9 ||
              op.id == 12 ||
              op.id == 15
          ) === undefined
        ) {
          selected.contracts !== undefined && selected.contracts !== ""
            ? acc.push(true)
            : acc.push(false);
        } else {
          selected.hasOwnProperty("PTcontract")
            ? acc.push(true)
            : acc.push(false);
        }
      }
      return acc;
    }, []);

    return hasOperatorsWithContracts.includes(false) ? false : true;
  };
  const isRoomMatchedinSelectedOperator = (offers, operators, hotelId) => {
    let control = true;
    let from_room = {};
    let to_room = {};
    operators.map((operator) => {
      let operatorHotel = eval(operator.code + "hotels");
      let selectedOperatorHotelId = Object.values(operatorHotel).find(
        (opHotel) => {
          return opHotel.global_hotel_id == hotelId;
        }
      )?.id;
      offers.map((offer) => {
        if (offer.from_room.id == offer.to_room.id) {
          sweetAlerts("warning", `Odalar aynı olamaz !`);
          control = false;
        } else {
          from_room = Object.values(matched_rooms).find((matched_room) => {
            return (
              matched_room.global_room_id == offer.from_room.id &&
              selectedOperatorHotelId == matched_room.operator_hotel_id
            );
          });
          to_room = Object.values(matched_rooms).find((matched_room) => {
            return (
              matched_room.global_room_id == offer.to_room.id &&
              selectedOperatorHotelId == matched_room.operator_hotel_id
            );
          });
          if (!(from_room && to_room)) {
            sweetAlerts("warning", `Eşleşmemiş odalar var!`);
            control = false;
          } else {
            if (operator.id == 4) {
              let from_room_type = from_room?.name.split(" -")[0];
              let to_room_type = to_room?.name.split(" -")[0];

              if (from_room_type == to_room_type) {
                sweetAlerts(
                  "warning",
                  `Seçilen operatörde oda tipleri aynı olduğu için upgrade yapılamıyor`
                );
                control = false;
              } else {
                offer.from_room[operator.label] = from_room;
                offer.to_room[operator.label] = to_room;
                control = true;
              }
            } else {
              offer.from_room[operator.label] = from_room;
              offer.to_room[operator.label] = to_room;
              control = true;
            }
          }
        }
      });
    });
    return control;
  };
  const handleChangeOperator = (
    event,
    jumbo_contract_id = null,
    name = null
  ) => {
    if (!jumbo_contract_id) {
      //if LMT selected
      if (event.filter((item) => item.id == 10).length > 0) {
        setisLMTSelected(true);
      }
      //if LMT select calnceled
      else if (event.find((item) => item.id == 10) == undefined) {
        setisLMTSelected(false);
      }
      handleChange("operators", event);
      setContractsOptions([]);
    }
    let chunkRooms = [];
    let selectedRoomsTemp = [];
    //global rooms state will change by operators
    if (event.length) {
      event.map((operator) => {
        let operator_hotel_id =
          matched_hotels[selected.hotelId][operator.code]["id"];
        let global_room_ids = _.uniq(
          _.map(
            Object.values(matched_rooms).filter((room) => {
              //if operator selected jumbo , rooms set after jumbo contracts select
              // if (operator.id != 9) {
              return room.operator_hotel_id == operator_hotel_id;
              // }
              // else {
              //   let jumboContractId = Object.values(contracts).find(
              //     (cont) => cont.contract_id == jumbo_contract_id
              //   );
              //   return (
              //     room.operator_hotel_id == operator_hotel_id &&
              //     room.operator_contract_id == jumboContractId?.id
              //   );
              // }
            }),
            "global_room_id"
          )
        );
        global_room_ids.map((global_room_id) => {
          let temp_room = roomType[global_room_id];
          let room_name =
            types[temp_room.type_id].name +
            " " +
            grades[temp_room.grade_id].name +
            " " +
            (temp_room.base_id > 1 ? bases[temp_room.base_id].name + " " : "") +
            (temp_room.view_id > 1 ? views[temp_room.view_id].name + " " : "") +
            (temp_room.sub_type_id > 1
              ? subTypes[temp_room.sub_type_id].name + " "
              : "") +
            (temp_room.promo_id > 1 ? promos[temp_room.promo_id].name : "");
          let tempRoom = {
            id: global_room_id,
            label: roomType ? roomType[global_room_id].code : "",
            name: room_name,
          };
          selected.rooms.map((room) => {
            room.id == global_room_id &&
              selectedRoomsTemp.findIndex((a) => a.id == room.id) === -1 &&
              selectedRoomsTemp.push(room);
          });
          if (chunkRooms.findIndex((a) => a.id == tempRoom.id) === -1) {
            chunkRooms.push(tempRoom);
          }
        });
        //if operator selected jumbo ,jumbo contract set here cause
        //yukarda olursa iki yerde de selected set edildiği için hata veriyor sayfayı patlatıyor.
        if (!jumbo_contract_id) {
          setRoomOptions(chunkRooms);
          setSelected({
            ...selected,
            rooms: selectedRoomsTemp,
            operators: event,
          });
        } else if (name == "JUMBOcontract") {
          setRoomOptions(chunkRooms);
          setSelected({
            ...selected,
            rooms: selectedRoomsTemp,
            operators: event,
            JUMBOcontract: jumbo_contract_id,
          });
        } else if ((name = "onlyOperatorJUMBO")) {
          setRoomOptions(chunkRooms);
          setSelected({
            ...selected,
            rooms: selectedRoomsTemp,
            operators: event,
            contracts: jumbo_contract_id,
            JUMBOcontract: jumbo_contract_id,
          });
        }
      });
    } else {
      setRoomOptions([]);
      setSelected({ ...selected, rooms: [], operators: [], contracts: "" });
    }
  };

  const errorTostAlert = () => {
    Swal.fire({
      toast: "true",
      position: "top-end",
      icon: "success",
      title:
        "Your transaction has been sent. Please wait until the process is finished.",

      showConfirmButton: false,
      timer: 2000,
    });
  };
  //bota gereksiz inputların gitmemesi için istenilmeyen inputları kaldırıyor
  const setOfferData = () => {
    return offers.map((offer) => {
      let picked = {};
      switch (selected.offer_type) {
        case 1:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            stayNights,
            freeNight,
            free_night_type,
            multiple,
            valid_for_extras,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            stayNights,
            freeNight,
            free_night_type,
            multiple,
            valid_for_extras,
          }))(offer);
          return picked;
        case 2:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            percent,
            payment_end_date,
            payment_start_date,
            payment_percent,
            valid_for_extras,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            percent,
            payment_end_date,
            payment_start_date,
            payment_percent,
            valid_for_extras,
          }))(offer);
          return picked;
        case 3:
          picked =
            selected.offer_sub_type == "Rate"
              ? (({
                  stayStartDate,
                  stayEndDate,
                  offerName,
                  offerRoom,
                  amount,
                  applyType,
                  applyRateType,
                  minStay,
                  maxStay,
                  min_child,
                  max_child,
                }) => ({
                  stayStartDate,
                  stayEndDate,
                  offerName,
                  offerRoom,
                  amount,
                  applyType,
                  applyRateType,
                  minStay,
                  maxStay,
                  min_child,
                  max_child,
                }))(offer)
              : (({
                  stayStartDate,
                  stayEndDate,
                  offerName,
                  offerRoom,
                  percent,
                  minStay,
                  maxStay,
                  min_child,
                  max_child,
                  valid_for_extras,
                }) => ({
                  stayStartDate,
                  stayEndDate,
                  offerName,
                  offerRoom,
                  percent,
                  minStay,
                  maxStay,
                  min_child,
                  max_child,
                  valid_for_extras,
                }))(offer);
          return picked;
        case 4:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            from_room,
            to_room,
            minStay,
            maxStay,
            min_child,
            max_child,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            from_room,
            to_room,
            minStay,
            maxStay,
            min_child,
            max_child,
          }))(offer);
          return picked;
        case 5:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            percent,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            percent,
          }))(offer);
          return picked;
        case 6:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            pax1_percent,
            pax2_percent,
            pax3_percent,
            pax4_percent,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            pax1_percent,
            pax2_percent,
            pax3_percent,
            pax4_percent,
          }))(offer);
          return picked;
        case 7:
          picked = (({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            pax1_percent,
            pax2_percent,
            pax3_percent,
            pax4_percent,
          }) => ({
            stayStartDate,
            stayEndDate,
            offerName,
            offerRoom,
            minStay,
            maxStay,
            min_child,
            max_child,
            pax1_percent,
            pax2_percent,
            pax3_percent,
            pax4_percent,
          }))(offer);
          return picked;
      }
    });
  };

  const checkEmptyValues = () => {
    let control = false;
    if (selected.offer_type) {
      offers.map((offer) => {
        //min Stay control
        if (selected.offer_type == 1 && offer.minStay < offer.stayNights) {
          sweetAlerts(
            "warning",
            `Min stay can not be greater than stay night value !`
          );
          control = false;
        }
        // if (
        //   selected.offer_type == 1 &&
        //   !(
        //     offer.stayStartDate &&
        //     offer.stayEndDate &&
        //     offer.offerName != "" &&
        //     offer.offerRoom &&
        //     offer.minStay != "" &&
        //     offer.maxStay != "" &&
        //     offer.stayNights != "" &&
        //     offer.freeNight != "" &&
        //     offer.free_night_type != "" &&
        //     offer.multiple != ""
        //   )
        // ) {
        //   sweetAlerts("error", "Please fill all the fields");
        //   control = false;
        //  }
        else if (
          selected.offer_type == 2 &&
          !(
            offer.stayStartDate &&
            offer.stayEndDate &&
            offer.offerName != "" &&
            offer.offerRoom &&
            offer.minStay != "" &&
            offer.maxStay != "" &&
            offer.percent != "" &&
            offer.payment_start_date != "" &&
            offer.payment_end_date != "" &&
            offer.payment_percent != ""
          )
        ) {
          sweetAlerts("error", "Please fill all the fields");
          control = false;
        } else if (selected.offer_type == 3) {
          if (selected.offer_sub_type == "Rate") {
            if (
              !(
                offer.stayStartDate &&
                offer.stayEndDate &&
                offer.offerName != "" &&
                offer.offerRoom &&
                offer.amount != "" &&
                offer.applyType &&
                offer.applyRateType
              )
            ) {
              sweetAlerts("error", "Please fill all the fields");
              control = false;
            } else {
              control = true;
            }
          } else if (selected.offer_sub_type == "Discount") {
            if (
              !(
                offer.stayStartDate &&
                offer.stayEndDate &&
                offer.offerName != "" &&
                offer.offerRoom &&
                offer.percent != ""
              )
            ) {
              sweetAlerts("error", "Please fill all the fields");
              control = false;
            } else {
              control = true;
            }
          }
        } else if (
          selected.offer_type == 4 &&
          !(
            offer.stayStartDate &&
            offer.stayEndDate &&
            offer.offerName != "" &&
            offer.from_room &&
            offer.to_room
          )
        ) {
          sweetAlerts("error", "Please fill all the fields");
          control = false;
        } else if (
          selected.offer_type == 5 &&
          !(
            offer.stayStartDate &&
            offer.stayEndDate &&
            offer.offerName != "" &&
            offer.offerRoom &&
            offer.minStay != "" &&
            offer.maxStay != "" &&
            offer.percent != ""
          )
        ) {
          sweetAlerts("error", "Please fill all the fields");
          control = false;
        } else if (
          (selected.offer_type == 6 || selected.offer_type == 7) &&
          !(
            offer.stayStartDate &&
            offer.stayEndDate &&
            offer.offerName != "" &&
            offer.offerRoom &&
            offer.minStay != "" &&
            offer.maxStay != ""
          )
        ) {
          sweetAlerts("error", "Please fill all the fields");
          control = false;
        } else {
          control = true;
        }
      });
    }
    return control;
  };

  const checkDates = (off) => {
    let difference = new Date(off.stayEndDate) - new Date(off.stayStartDate);
    let total_days = difference / (1000 * 3600 * 24);
    let min_stay_flag = true;

    if (Number(off.minStay) > Number(total_days)) {
      if (Number(off.minStay) == 1 && Number(total_days) == 0) {
        min_stay_flag = true;
      } else {
        min_stay_flag = false;
      }
    } else {
      min_stay_flag = true;
    }
    return min_stay_flag;
  };

  const sendOfferData = () => {
    const sendData = {
      contracts: selected.contracts,
      hotelId: selected.hotelId,
      sellStartDate: selected.sellStartDate,
      sellEndDate: selected.sellEndDate,
      PPcontract: selected.PPcontract,
      SHcontract: selected.SHcontract,
      WBcontract: selected.WBcontract,
      WEBBEDScontract: selected.WEBBEDScontract,
      JUMBOcontract: selected.JUMBOcontract,
      IVCcontract: selected.IVCcontract,
      W2Mcontract: selected.W2Mcontract,
      PTcontract: selected.PTcontract,
      allOperators: selected.operators,
      offers: offers,
      pId: "",
      user: authUser,
      offerType: selected.offer_type,
      offerSubType: selected.offer_sub_type,
      offerType_name: offerTypes.find((offer_type) => {
        return offer_type.id == selected.offer_type;
      }).label,
    };

    {
      offers.map((offer) => {
        if (
          !offer.hasOwnProperty("from_room") &&
          !offer.hasOwnProperty("to_room")
        ) {
          offer["from_room"] = {
            name: "",
            value: "",
          };
          offer["to_room"] = {
            name: "",
            value: "",
          };
        }
      });
    }

    let controlEmptyValues = checkEmptyValues();
    let tempFlag = controlEmptyValues;
    let control_min_stay = true;
    if (moment(selected?.sellEndDate).isBefore(selected?.sellStartDate)) {
      sweetAlerts(
        "warning",
        `Selling end date cannot be earlier than Selling start date  !`
      );
      tempFlag = false;
      return true;
    }

    if (sendData.offers) {
      sendData.offers.some((off) => {
        if (minStayControl) {
          control_min_stay = checkDates(off);
        } else {
          off.minStay = "";
          off.maxStay = "";
        }

        if (
          off?.stayStartDate == "Invalid date" ||
          off?.stayEndDate == "Invalid date"
        ) {
          sweetAlerts("warning", `Please select valid date for stay dates !`);
          tempFlag = false;
          return true;
        } else if (control_min_stay == false) {
          sweetAlerts(
            "warning",
            `Minimum stay can not greater than stay start date and stay end date range!`
          );
          tempFlag = false;
          return true;
        } else if (moment(off?.stayEndDate).isBefore(off?.stayStartDate)) {
          sweetAlerts(
            "warning",
            `Stay end date cannot be earlier than stay start date  !`
          );
          tempFlag = false;
          return true;
        } else if (moment(off?.stayStartDate).isBefore(today)) {
          sweetAlerts(
            "warning",
            `Stay start date cannot be earlier than today  !`
          );
          tempFlag = false;
          return true;
        } else {
          tempFlag = true;
        }
        if (sendData.offerType == 2) {
          if (
            off?.payment_start_date == "Invalid date" ||
            off?.payment_end_date == "Invalid date"
          ) {
            sweetAlerts(
              "warning",
              `Please select valid date for payment dates !`
            );
            tempFlag = false;
            return true;
          } else if (
            moment(off?.payment_end_date).isBefore(off?.payment_start_date)
          ) {
            sweetAlerts(
              "warning",
              `Payment end date cannot be earlier than payment start date  !`
            );
            tempFlag = false;
            return true;
          } else if (moment(off?.payment_start_date).isBefore(today)) {
            sweetAlerts(
              "warning",
              `Payment start date cannot be earlier than today  !`
            );
            tempFlag = false;
            return true;
          } else {
            tempFlag = true;
          }
        }
      });
    }
    if (tempFlag && controlEmptyValues) {
      if (
        sendData.sellEndDate == "Invalid date" ||
        sendData.sellStartDate == "Invalid date"
      ) {
        tempFlag = false;
        sweetAlerts("warning", `Please select valid date !`);
      } else if (moment(sendData.sellStartDate).isBefore(today)) {
        sweetAlerts(
          "warning",
          `Selling start date cannot be earlier than today  !`
        );
        tempFlag = false;
      } else {
        if (
          (selected.offer_type != 4 ? hasOfferRooms(offers) : true) &&
          hasOfferName(offers) &&
          hasOfferType(offers)
        ) {
          if (!hasOperatorsWithContract(selected)) {
            sweetAlerts("warning", `Please select contract !`);
          } else {
            if (
              selected.offer_type === 4 &&
              !isRoomMatchedinSelectedOperator(
                offers,
                sendData.allOperators,
                sendData.hotelId
              )
            ) {
              return;
            }
            dispatch(sendBotOffersService(sendData, setButtonControl));
            errorTostAlert();
            // selected.operators.map((item) => {
            //   if (item.id == 1) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.SHcontract ||
            //         sendData.WBcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.PPcontract
            //           : sendData.contracts,
            //     };
            //     dispatch(sendOfferServicePP(bot_payload));
            //     errorTostAlert();
            //   } else if (item.id == 2) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.WBcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.SHcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceSH(bot_payload));
            //       errorTostAlert();
            //     }, 1);
            //   } else if (item.id == 3) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceDN(sendData));
            //       errorTostAlert();
            //     }, 2);
            //   } else if (item.id == 4) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.SHcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.WBcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceWB(bot_payload));
            //       errorTostAlert();
            //     }, 3);
            //   } else if (item.id == 5) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceLMX(sendData));
            //       errorTostAlert();
            //     }, 4);
            //   } else if (item.id == 6) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceFOR(sendData));
            //       errorTostAlert();
            //     }, 5);
            //   } else if (item.id == 7) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceSIT(sendData));
            //       errorTostAlert();
            //     }, 6);
            //   } else if (item.id == 8) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.WBcontract ||
            //         sendData.SHcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.WEBBEDScontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceWEBBEDS(bot_payload));
            //       errorTostAlert();
            //     }, 7);
            //   } else if (item.id == 9) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.WBcontract ||
            //         sendData.SHcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.WEBBEDScontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.IVCcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceJUMBO(bot_payload));
            //       errorTostAlert();
            //     }, 9);
            //   } else if (item.id == 10) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceLMT(sendData));
            //       errorTostAlert();
            //     }, 8);
            //   } else if (item.id == 11) {
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceXPUR(sendData));
            //       errorTostAlert();
            //     }, 8);
            //   } else if (item.id == 12) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.SHcontract ||
            //         sendData.WEBBEDScontract ||
            //         sendData.WBcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.W2Mcontract ||
            //         sendData.PTcontract
            //           ? sendData.IVCcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceIVC(bot_payload));
            //       errorTostAlert();
            //     }, 3);
            //   } else if (item.id == 15) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.SHcontract ||
            //         sendData.WEBBEDScontract ||
            //         sendData.WBcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.PTcontract
            //           ? sendData.W2Mcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServiceW2M(bot_payload));
            //       errorTostAlert();
            //     }, 3);
            //   } else if (item.id == 16) {
            //     let bot_payload = {
            //       ...sendData,
            //       contracts:
            //         sendData.PPcontract ||
            //         sendData.SHcontract ||
            //         sendData.WEBBEDScontract ||
            //         sendData.WBcontract ||
            //         sendData.JUMBOcontract ||
            //         sendData.IVCcontract ||
            //         sendData.W2Mcontract
            //           ? sendData.PTcontract
            //           : sendData.contracts,
            //     };
            //     setTimeout(() => {
            //       dispatch(sendOfferServicePT(bot_payload));
            //       errorTostAlert();
            //     }, 3);
            //   }
            // });
          }
        } else {
          sweetAlerts("error", "Please fill all the fields");
        }
      }
    }
  };
  useEffect(() => {
    if (flag == true) {
      sendOfferData();
    }
  }, [flag]);

  const offerTypes = [
    {
      label: "FREE NIGHT",
      id: 1,
      operators: [
        "DN",
        "WB",
        "SH",
        "PP",
        "WEBBEDS",
        "LMT",
        "JUMBO",
        "IVC",
        "W2M",
        "PT",
      ],
    },
    {
      label: "EARLY BOOKING",
      id: 2,
      operators: ["PP", "LMT", "JUMBO", "IVC", "W2M", "PT"],
    },
    {
      label: "SPECIAL OFFER",
      id: 3,
      subTypes: {
        Rate: ["DN", "WB", "LMT", "IVC"],
        Discount: [
          "DN",
          "WB",
          "LMX",
          "SIT",
          "FOR",
          "WEBBEDS",
          "PP",
          "SH",
          "LMT",
          "XPUR",
          "JUMBO",
          "IVC",
          "W2M",
          "PT",
        ],
      },
    },
    {
      label: "UPGRADE",
      id: 4,
      operators: ["WB", "PP", "WEBBEDS", "IVC", "W2M", "PT"],
    },
    { label: "LONG STAY", id: 5, operators: ["SH", "JUMBO", "IVC"] },
    { label: "CHILDREN OFFER", id: 6, operators: ["JUMBO"] },
    { label: "ADULT OFFER(NPAX)", id: 7, operators: ["JUMBO"] },
  ];
  let multipleOptions = ["1", "2", "3", "unlimited"];
  let freeNightType = ["Cheapest Night", "Last Night"];
  let applyRateTypeOptions = ["Per Person", "Per Room"];
  let applyTypeOptions = ["Total", "Per Night"];

  const adultChildInputs = (offer, idx, type) => {
    let arr = [];
    for (let index = 1; index < 5; index++) {
      let countOrder = childAdultCounter[index];
      let labelName = String(index) + "." + type + " Percent";
      let inputName = "pax" + index + "_percent";

      arr.push(
        <Grid className={classes.gridStyle} item xs={1}>
          <TextField
            id="outlined-basic"
            label={labelName}
            name={inputName}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            autoComplete="off"
            value={
              offers[idx][inputName] == ""
                ? offer[inputName]
                : offers[idx][inputName]
            }
            onChange={(event) => {
              let newOfferValues = [...offers];
              newOfferValues[idx][inputName] = event.target.value;
              setOffers(newOfferValues);
            }}
            onKeyPress={(e) => {
              if (isNumeric(e)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
      );
    }
    return <>{arr}</>;
  };

  const offerTypeInputs = useCallback(
    (offer, index, roomOptions, minStayControl) => {
      return (
        <>
          {//min_stay
          selected.offer_type && minStayControl ? (
            <Grid className={classes.gridStyle} item xs={1}>
              <TextField
                id="outlined-basic"
                label={<IntlMessages id="min.stay" />}
                name="minStay"
                disabled={!minStayControl}
                error={!offer.minStay}
                type="number"
                autoComplete="off"
                value={
                  offers[index]["minStay"] == ""
                    ? offer.minStay
                    : offers[index]["minStay"]
                }
                // error={
                //   selected.offer_type == 1
                //     ? offers[index].stayNights <= offers[index].minStay
                //       ? false
                //       : true
                //     : false
                // }
                onChange={(event) => {
                  let newOfferValues = [...offers];
                  newOfferValues[index]["minStay"] = parseInt(
                    event.target.value
                  );
                  setOffers(newOfferValues);
                }}
                onKeyPress={(e) => {
                  if (isNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
          {//max_stay ınput
          selected.offer_type && minStayControl ? (
            <Grid className={classes.gridStyle} item xs={1}>
              <TextField
                id="outlined-basic"
                label={<IntlMessages id="max.stay" />}
                name="maxStay"
                disabled={!minStayControl}
                error={!offer.maxStay}
                type="number"
                autoComplete="off"
                value={
                  offers[index]["maxStay"] == ""
                    ? offer.maxStay
                    : offers[index]["maxStay"]
                }
                onChange={(event) => {
                  let newOfferValues = [...offers];
                  newOfferValues[index]["maxStay"] = parseInt(
                    event.target.value
                  );
                  setOffers(newOfferValues);
                }}
                onKeyPress={(e) => {
                  if (isNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          ) : (
            <></>
          )}

          {//min_child
          jumboControl && (
            <Grid className={classes.gridStyle} item xs={1}>
              <TextField
                id="outlined-basic"
                label={<IntlMessages id="min.age" />}
                name="min_child"
                type="number"
                autoComplete="off"
                value={
                  offers[index]["min_child"] == ""
                    ? offer.min_child
                    : offers[index]["min_child"]
                }
                onChange={(event) => {
                  let newOfferValues = [...offers];
                  newOfferValues[index]["min_child"] = event.target.value;
                  setOffers(newOfferValues);
                }}
                onKeyPress={(e) => {
                  if (isNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          )}
          {//max_child ınput

          jumboControl ? (
            <Grid className={classes.gridStyle} item xs={1}>
              <TextField
                id="outlined-basic"
                label={<IntlMessages id="max.age" />}
                name="max_child"
                type="number"
                autoComplete="off"
                value={
                  offers[index]["max_child"] == ""
                    ? offer.max_child
                    : offers[index]["max_child"]
                }
                onChange={(event) => {
                  let newOfferValues = [...offers];
                  newOfferValues[index]["max_child"] = event.target.value;
                  setOffers(newOfferValues);
                }}
                onKeyPress={(e) => {
                  if (isNumeric(e)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          ) : (
            <></>
          )}

          {//free night input
          selected.offer_type == 1 ? (
            <>
              <Grid className={classes.gridStyle}>
                <TextField
                  id="outlined-basic"
                  label={<IntlMessages id="stay.nights" />}
                  name="stayNights"
                  error={offer.stayNights != "" ? false : true}
                  value={
                    offers[index]["stayNights"] == ""
                      ? offer.stayNights
                      : offers[index]["stayNights"]
                  }
                  autoComplete="off"
                  onChange={(event) => {
                    let newOfferValues = [...offers];
                    newOfferValues[index]["stayNights"] = event.target.value;
                    setOffers(newOfferValues);
                  }}
                  type="number"
                  onKeyPress={(e) => {
                    if (isNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item>
                <TextField
                  id="outlined-basic"
                  label={<IntlMessages id="free.night" />}
                  name="freeNight"
                  error={offer.freeNight != "" ? false : true}
                  value={
                    offers[index]["freeNight"] == ""
                      ? offer.freeNight
                      : offers[index]["freeNight"]
                  }
                  onChange={(event) => {
                    let newOfferValues = [...offers];
                    newOfferValues[index]["freeNight"] = event.target.value;
                    setOffers(newOfferValues);
                  }}
                  autoComplete="off"
                  type="number"
                  className={classes.textFields}
                  onKeyPress={(e) => {
                    if (isNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="type" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["free_night_type"] == ""
                        ? offer.free_night_type
                        : offers[index]["free_night_type"]
                    }
                    error={offer.free_night_type != "" ? false : true}
                    autoComplete="off"
                    onChange={(event) => {
                      let newOfferValues = [...offers];
                      newOfferValues[index]["free_night_type"] =
                        event.target.value;
                      setOffers(newOfferValues);
                    }}
                  >
                    {freeNightType.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>{" "}
                </FormControl>
              </Grid>
              <Grid className={classes.gridStyle} item xs={1}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="multiple" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["multiple"] == ""
                        ? offer.multiple
                        : offers[index]["multiple"]
                    }
                    error={offer.multiple != "" ? false : true}
                    autoComplete="off"
                    onChange={(event) => {
                      let newOfferValues = [...offers];
                      newOfferValues[index]["multiple"] = event.target.value;
                      setOffers(newOfferValues);
                    }}
                  >
                    {multipleOptions.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {//percent
          selected.offer_type == 2 ||
          (selected.offer_type == 3 &&
            selected?.offer_sub_type == "Discount") ||
          selected.offer_type == 5 ? (
            <Grid className={classes.gridStyle} item xs={1}>
              <TextField
                id="outlined-basic"
                label={<IntlMessages id="percent" />}
                fullWidth
                name="percent"
                error={offer.percent != "" ? false : true}
                value={
                  offers[index]["percent"] == ""
                    ? offer.percent
                    : offers[index]["percent"]
                }
                autoComplete="off"
                onChange={(event) => {
                  let newOfferValues = [...offers];
                  newOfferValues[index]["percent"] = event.target.value;
                  setOffers(newOfferValues);
                }}
                type="number"
                onKeyPress={(e) => {
                  if (isNumeric(e)) {
                    e.preventDefault();
                  }
                }}
                //className={classes.textFields}
              />
            </Grid>
          ) : (
            <></>
          )}
          {//amount

          selected.offer_type == 3 && selected?.offer_sub_type == "Rate" ? (
            <>
              <Grid className={classes.gridStyle} item xs={3}>
                <TextField
                  id="outlined-basic"
                  label={<IntlMessages id="amount" />}
                  fullWidth
                  name="amount"
                  // error={amountError}
                  error={!offer.amount}
                  value={
                    offers[index]["amount"] == ""
                      ? offer.amount
                      : offers[index]["amount"]
                  }
                  onChange={(event) => {
                    // if (event.target.value != "") {
                    //   setAmountError(false);
                    // }
                    let newOfferValues = [...offers];
                    newOfferValues[index]["amount"] = event.target.value;
                    setOffers(newOfferValues);
                  }}
                  type="number"
                  className={classes.textFields}
                  onKeyPress={(e) => {
                    if (isNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="rate.type" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["applyRateType"] == ""
                        ? offer.applyRateType
                        : offers[index]["applyRateType"]
                    }
                    // error={rateError}
                    error={!offer.applyRateType}
                    onChange={(event) => {
                      // if (event.target.value != "") {
                      //   setRateError(false);
                      // }
                      let newOfferValues = [...offers];
                      newOfferValues[index]["applyRateType"] =
                        event.target.value;
                      setOffers(newOfferValues);
                    }}
                  >
                    {applyRateTypeOptions.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={classes.gridStyle} item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="apply.type" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["applyType"] == ""
                        ? offer.applyType
                        : offers[index]["applyType"]
                    }
                    // error={applyError}
                    error={!offer.applyType}
                    onChange={(event) => {
                      // if (event.target.value != "") {
                      //   setApplyError(false);
                      // }
                      let newOfferValues = [...offers];
                      newOfferValues[index]["applyType"] = event.target.value;
                      setOffers(newOfferValues);
                    }}
                    autoComplete="off"
                  >
                    {applyTypeOptions.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {//upgrade offer inputs
          selected.offer_type == 4 ? (
            <>
              <Grid className={classes.gridStyle} item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="from.room" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["from_room"] == ""
                        ? offer.from_room
                        : offers[index]["from_room"]
                    }
                    autoComplete="off"
                    error={
                      !(offer.hasOwnProperty("from_room") && offer["from_room"])
                    }
                    // error={
                    //   errorControlFromRoom != "" ||
                    //   errorControlFromRoom != null ||
                    //   errorControlFromRoom != undefined
                    //     ? false
                    //     : true
                    // }
                    onChange={(event) => {
                      let newOfferValues = [...offers];
                      newOfferValues[index]["from_room"] = roomOptions.find(
                        (item) => item.name == event.target.value
                      );
                      setOffers(newOfferValues);
                    }}
                  >
                    {roomOptions.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={classes.gridStyle} item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="to.room" />
                  </InputLabel>
                  <Select
                    value={
                      offers[index]["to_room"] == ""
                        ? offer.to_room
                        : offers[index]["to_room"]
                    }
                    error={
                      !(offer.hasOwnProperty("to_room") && offer["to_room"])
                    }
                    // error={
                    //   errorControlFromRoom != "" ||
                    //   errorControlFromRoom != null ||
                    //   errorControlFromRoom != undefined
                    //     ? false
                    //     : true
                    // }
                    onChange={(event) => {
                      // setErrorControlToRoom(event.target.value);
                      let newOfferValues = [...offers];
                      newOfferValues[index]["to_room"] = roomOptions.find(
                        (item) => item.name == event.target.value
                      );
                      setOffers(newOfferValues);
                    }}
                  >
                    {roomOptions.map((item) => {
                      if (item?.id != offer.from_room?.id) {
                        return (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {//early booking payment dates
          selected.offer_type == 2 ? (
            <>
              <Grid className={classes.gridStyle} item xs={2}>
                <KeyboardDatePicker
                  name="payment_start_date"
                  disablePast
                  autoOk="true"
                  autoComplete="off"
                  label={<IntlMessages id="payment.start.date" />}
                  minDate={today}
                  format="DD/MM/YYYY"
                  error={
                    offers[index]["payment_start_date"] != "" ? false : true
                  }
                  value={
                    offers[index]["payment_start_date"] == ""
                      ? null
                      : moment(offer.payment_start_date).format("YYYY-MM-DD")
                  }
                  onChange={(date) => {
                    let newOfferValues = [...offers];
                    newOfferValues[index]["payment_start_date"] = moment(
                      date
                    ).format("YYYY-MM-DD");
                    setOffers(newOfferValues);
                  }}
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item xs={2}>
                <KeyboardDatePicker
                  name="payment_end_date"
                  disablePast
                  autoOk="true"
                  autoComplete="off"
                  label={<IntlMessages id="payment.end.date" />}
                  minDate={today}
                  format="DD/MM/YYYY"
                  error={offers[index]["payment_end_date"] != "" ? false : true}
                  value={
                    offers[index]["payment_end_date"] == ""
                      ? null
                      : moment(offer.payment_end_date).format("YYYY-MM-DD")
                  }
                  onChange={(date) => {
                    let newOfferValues = [...offers];
                    newOfferValues[index]["payment_end_date"] = moment(
                      date
                    ).format("YYYY-MM-DD");
                    setOffers(newOfferValues);
                  }}
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid className={classes.gridStyle} item xs={2}>
                <TextField
                  id="outlined-basic"
                  label={<IntlMessages id="payment.percent" />}
                  name="payment_percent"
                  error={offer.payment_percent != "" ? false : true}
                  value={
                    offers[index]["payment_percent"] == ""
                      ? offer.payment_percent
                      : offers[index]["payment_percent"]
                  }
                  autoComplete="off"
                  onChange={(event) => {
                    let newOfferValues = [...offers];
                    newOfferValues[index]["payment_percent"] =
                      event.target.value;
                    setOffers(newOfferValues);
                  }}
                  type="number"
                  onKeyPress={(e) => {
                    if (isNumeric(e)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {//early booking payment dates
          selected.offer_type == 6 || selected.offer_type == 7 ? (
            adultChildInputs(
              offer,
              index,
              selected.offer_type == 6 ? "Child" : "Adult"
            )
          ) : (
            <></>
          )}
        </>
      );
    },
    [selected.offer_type, selected.offer_sub_type, offers, jumboControl]
  );
  return (
    <div>
      <CardHeader
        title={intl.formatMessage({ id: "offers" })}
        sx={{ color: "#33658a", paddingLeft: "0px" }}
      />
      <Paper className={classes.padding}>
        <Grid container className={classes.container} justifyContent="center">
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={4}>
              <Autocomplete
                freeSolo
                id="selectedHotel"
                options={hotelName ? hotelName : []}
                getOptionLabel={(option) => (option?.name ? option.name : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={selected.hotelId ? false : true}
                    label={<IntlMessages id="hotel" />}
                  />
                )}
                onChange={(event, newValue) => {
                  handleChange("hotel_id", newValue ? newValue : {});
                }}
                disabled={!isAuthAdd}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>
                  <IntlMessages id="offer.type" />
                </InputLabel>
                <Select
                  label={<IntlMessages id="offer.type" />}
                  value={selected.offer_type ? selected.offer_type : ""}
                  error={selected.offer_type ? false : true}
                  onChange={(event) => {
                    handleChange("offer_type", event.target.value);
                  }}
                  disabled={!isAuthAdd}
                >
                  {offerTypes.map((item) => {
                    return <MenuItem value={item.id}>{item.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            {//subtype
            selected.offer_type == 3 ? (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="sub.type" />
                  </InputLabel>
                  <Select
                    label={<IntlMessages id="sub.type" />}
                    value={
                      selected.offer_sub_type ? selected.offer_sub_type : ""
                    }
                    // error={subTypeError}
                    error={!selected.offer_sub_type}
                    onChange={(event) => {
                      // if (event) {
                      //   setSubTypeError(false);
                      // }
                      handleChange("offer_sub_type", event.target.value);
                    }}
                    disabled={!isAuthAdd}
                  >
                    {Object.keys(
                      Object.values(offerTypes).find((offer_type) => {
                        return offer_type.id == selected.offer_type;
                      })?.subTypes
                    ).map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={3}>
              <ThemeProvider theme={theme}>
                <MultiSelect
                  error={
                    !selected.hasOwnProperty("operators") ||
                    selected.operators.length == 0
                  }
                  label="Operators"
                  className={classes.multiselectStyle}
                  options={multiDataSetter(
                    "label",
                    "id",
                    operatorOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
                  )}
                  disabled={!isAuthAdd}
                  value={multiDataSetter("label", "id", selected.operators)}
                  handleChange={(event) => handleChangeOperator(event)}
                />
              </ThemeProvider>
            </Grid>
            {contractOptions.length >= 2 ? (
              <>
                {newOpContracts()}
                <Grid container spacing={4}>
                  {contractOptions.includes(2) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="sun.hotels.contracts" />
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="sun.hotels.contracts" />}
                          value={selected.SHcontract ? selected.SHcontract : ""}
                          onChange={(event, value) => {
                            handleChange("SHcontract", event.target.value);
                          }}
                        >
                          {ShContracts
                            ? ShContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(1) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="peakpoint.contracts" />{" "}
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="peakpoint.contracts" />}
                          value={selected.PPcontract ? selected.PPcontract : ""}
                          onChange={(event, value) => {
                            handleChange("PPcontract", event.target.value);
                          }}
                        >
                          {PpContracts
                            ? PpContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(4) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="welcomebeds.contracts" />
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="welcomebeds.contracts" />}
                          value={selected.WBcontract ? selected.WBcontract : ""}
                          onChange={(event, value) => {
                            handleChange("WBcontract", event.target.value);
                          }}
                        >
                          {WBcontracts
                            ? WBcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {contractOptions.includes(8) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="webbeds.contracts" />
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="webbeds.contracts" />}
                          value={
                            selected.WEBBEDScontract
                              ? selected.WEBBEDScontract
                              : ""
                          }
                          onChange={(event, value) => {
                            handleChange("WEBBEDScontract", event.target.value);
                          }}
                        >
                          {WEBBEDScontracts
                            ? WEBBEDScontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(9) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="jumbo.contracts" />
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="jumbo.contracts" />}
                          value={
                            selected.JUMBOcontract ? selected.JUMBOcontract : ""
                          }
                          onChange={(event, value) => {
                            handleChange("JUMBOcontract", event.target.value);
                          }}
                        >
                          {JUMBOcontracts
                            ? JUMBOcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(12) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="ivector.contracts" />
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="ivector.contracts" />}
                          value={
                            selected.IVCcontract ? selected.IVCcontract : ""
                          }
                          onChange={(event, value) => {
                            handleChange("IVCcontract", event.target.value);
                          }}
                        >
                          {IVCcontracts
                            ? IVCcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(15) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="world2meet.contracts" />{" "}
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="world2meet.contracts" />}
                          value={
                            selected.W2Mcontract ? selected.W2Mcontract : ""
                          }
                          onChange={(event, value) => {
                            handleChange("W2Mcontract", event.target.value);
                          }}
                        >
                          {W2Mcontracts
                            ? W2Mcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(16) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>
                          <IntlMessages id="primetravel.contracts" />{" "}
                        </InputLabel>
                        <Select
                          label={<IntlMessages id="primetravel.contracts" />}
                          value={selected.PTcontract ? selected.PTcontract : ""}
                          onChange={(event, value) => {
                            handleChange("PTcontract", event.target.value);
                          }}
                        >
                          {PTcontracts
                            ? PTcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : contractOptions.length != 0 ? (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="contracts" />
                  </InputLabel>
                  <Select
                    label={<IntlMessages id="contracts" />}
                    value={selected.contracts ? selected.contracts : ""}
                    disabled={!isAuthAdd}
                    onChange={(event, value) => {
                      if (contractOptions.includes(4)) {
                        handleChange("onlyOperatorWB", event.target.value);
                      } else if (contractOptions.includes(2)) {
                        handleChange("onlyOperatorSH", event.target.value);
                      } else if (contractOptions.includes(1)) {
                        handleChange("onlyOperatorPP", event.target.value);
                      } else if (contractOptions.includes(8)) {
                        handleChange("onlyOperatorWEBBEDS", event.target.value);
                      } else if (contractOptions.includes(9)) {
                        handleChange("onlyOperatorJUMBO", event.target.value);
                      } else if (contractOptions.includes(12)) {
                        handleChange("onlyOperatorIVC", event.target.value);
                      } else if (contractOptions.includes(15)) {
                        handleChange("onlyOperatorW2M", event.target.value);
                      } else if (contractOptions.includes(16)) {
                        handleChange("onlyOperatorPT", event.target.value);
                      }
                    }}
                  >
                    {contractsOptions
                      ? contractsOptions.map((item, i) => (
                          <MenuItem value={item.id}>{item.label}</MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid className={classes.grid}>
            <Grid
              className={classes.gridStyle}
              item
              xs={4}
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <KeyboardDatePicker
                  name="sellStartDate"
                  format="DD/MM/YYYY"
                  autoOk="true"
                  autoComplete="off"
                  label={<IntlMessages id="sell.start.date" />}
                  disabled={!isAuthAdd}
                  value={
                    selected["sellStartDate"] == ""
                      ? null
                      : selected
                      ? moment(selected.sellStartDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(value) => {
                    handleChange("sell_start_date", value);
                  }}
                  minDate={today}
                  animateYearScrolling
                  error={selected.sellStartDate ? false : true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid className={classes.gridStyle} item xs={4}>
              <KeyboardDatePicker
                name="sellEndDate"
                format="DD/MM/YYYY"
                autoOk="true"
                autoComplete="off"
                label={<IntlMessages id="sell.end.date" />}
                disabled={!isAuthAdd}
                value={
                  selected["sellEndDate"] == ""
                    ? null
                    : selected
                    ? moment(selected.sellEndDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(value) => {
                  handleChange("sell_end_date", value);
                }}
                minDate={moment(selected.sellStartDate).format("YYYY-MM-DD")}
                animateYearScrolling
                error={selected.sellEndDate ? false : true}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {offers?.map((offer, index) => (
            <Card className={classes.rootCard}>
              <CardContent>
                {selected.offer_type != "" && selected.offer_type != 4 && (
                  <Grid
                    className={classes.rootCardItem}
                    alignItems="flex-end"
                    container
                  >
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={minStayControl}
                            onChange={(event) => {
                              if (event.target.checked) {
                                offer.minStay = 1;
                                offer.maxStay = 99;
                              } else {
                                offer.minStay = "";
                                offer.maxStay = "";
                              }
                              setMinStayControl(event.target.checked);
                            }}
                            name="minStayControl"
                          />
                        }
                        label="Use Min Stay"
                      />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={index != 0}
                            checked={offers[0]["all_rooms"]}
                            onChange={(event) => {
                              handleChange("all_rooms", event.target.checked);
                              setAllRooms(event.target.checked);
                            }}
                            name="All Rooms"
                          />
                        }
                        label="All Rooms"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container xs={12}>
                  <Grid
                    className={classes.rootCardItem}
                    container
                    xs={12}
                    alignItems="flex-end"
                  >
                    <Grid className={classes.gridStyle} item xs={2}>
                      <KeyboardDatePicker
                        name="stayStartDate"
                        disablePast
                        autoOk="true"
                        autoComplete="off"
                        label={<IntlMessages id="stay.start.date" />}
                        minDate={today}
                        disabled={!isAuthAdd}
                        format="DD/MM/YYYY"
                        error={
                          offers[index]["stayStartDate"] != "" ? false : true
                        }
                        value={
                          offers[index]["stayStartDate"] == ""
                            ? null
                            : moment(offer.stayStartDate).format("YYYY-MM-DD")
                        }
                        onChange={(date) => {
                          let newOfferValues = [...offers];
                          newOfferValues[index]["stayStartDate"] = moment(
                            date
                          ).format("YYYY-MM-DD");
                          setOffers(newOfferValues);
                        }}
                        animateYearScrolling
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid className={classes.gridStyle} item xs={2}>
                      <KeyboardDatePicker
                        name="stayEndDate"
                        disablePast
                        autoOk="true"
                        autoComplete="off"
                        format="DD/MM/YYYY"
                        error={
                          offers[index]["stayEndDate"] != "" ? false : true
                        }
                        disabled={!isAuthAdd}
                        value={
                          offers[index]["stayEndDate"] == ""
                            ? null
                            : moment(offer.stayEndDate).format("YYYY-MM-DD")
                        }
                        onChange={(date) => {
                          let newOfferValues = [...offers];
                          newOfferValues[index]["stayEndDate"] = moment(
                            date
                          ).format("YYYY-MM-DD");
                          setOffers(newOfferValues);
                        }}
                        label={<IntlMessages id="stay.end.date" />}
                        minDate={
                          offer.stayStartDate ? offer.stayStartDate : today
                        }
                        inputProps={{ min: offer.stayStartDate }}
                        animateYearScrolling
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid className={classes.gridStyle} item xs={2}>
                      <TextField
                        id="outlined-basic"
                        label={<IntlMessages id="offer.name" />}
                        fullWidth
                        autoComplete="off"
                        disabled={!isAuthAdd}
                        error={offer.offerName != "" ? false : true}
                        name="name"
                        value={
                          offer?.offerName
                          // offers[index]["offerName"] == ""
                          //   ? offer.offerName
                          //   : null
                        }
                        onChange={(event) => {
                          let newOfferValues = [...offers];
                          newOfferValues[index]["offerName"] =
                            event.target.value;
                          setOffers(newOfferValues);
                        }}
                        type="text"
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                        /* InputProps={{
                      min: 0,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }} */
                        className={classes.textFields}
                      />
                    </Grid>
                    {selected.offer_type != 4 ? (
                      <Grid
                        style={{ paddingTop: 0, marginTop: 15 }}
                        className={classes.gridStyle}
                        item
                        xs={2}
                      >
                        <FormControl
                          fullWidth
                          variant="outlined"
                          style={{ marginTop: "0.438rem" }}
                        >
                          <MultiSelect
                            error={
                              !offers[index].hasOwnProperty("offerRoom") ||
                              offers[index].offerRoom.length == 0
                            }
                            options={multiDataSetter("name", "id", roomOptions)}
                            className={classes.multiselectStyle}
                            disabled={allRooms}
                            value={multiDataSetter(
                              "label",
                              "id",
                              typeof offers[index].offerRoom == "string"
                                ? []
                                : offers[index].offerRoom
                            )}
                            handleChange={(event) => {
                              let newOfferValues = [...offers];
                              newOfferValues[index]["offerRoom"] = event;
                              setOffers(newOfferValues);
                            }}
                            label="Rooms"
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {isLMTSelected &&
                    (selected.offer_type == 1 ||
                      selected.offer_type == 2 ||
                      (selected.offer_type == 3 &&
                        selected.offer_sub_type == "Discount")) ? (
                      <Grid className={classes.gridStyle} item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={offers[index]["valid_for_extras"]}
                              onChange={(event) => {
                                handleChange(
                                  "valid_for_extras",
                                  event.target.checked
                                );
                                let newOfferValues = [...offers];
                                newOfferValues[index]["valid_for_extras"] =
                                  event.target.checked;
                                setOffers(newOfferValues);
                                setValidForExtras(event.target.checked);
                              }}
                              name="Valid For Extras"
                            />
                          }
                          label="Valid For Extras"
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <Grid className={classes.rootCardItem} container xs={12}>
                    {offerTypeInputs(offer, index, roomOptions, minStayControl)}
                  </Grid>
                  {index ? null : (
                    <Grid item xs={2}>
                      <Button
                        onClick={addDateFields}
                        sx={{ height: "15%", maxWidth: "5%" }}
                        variant="contained"
                        className={classes.AddButton}
                        startIcon={<AddIcon />}
                        disabled={!isAuthAdd}
                      >
                        <IntlMessages id="add" />
                      </Button>
                    </Grid>
                  )}
                  {index ? (
                    <Grid item xs={2}>
                      <Button
                        onClick={() => removeDateFields(index)}
                        sx={{ height: "15%" }}
                        variant="contained"
                        color="primary"
                        className={classes.Button}
                        startIcon={<RemoveCircleOutlineIcon />}
                      >
                        <IntlMessages id="remove" />
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Grid>

        <Grid justifyContent="flex-end" container xs={12}>
          <Grid
            item
            // container

            className={classes.Button2}
          >
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                onClick={() => setShowOfferDiscounts(true)}
              >
                <IntlMessages id="offer.list" />
              </Button>
            </ThemeProvider>

            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={disabledControl()}
                onClick={() => sendOfferData()}
                /*  disabled={
            !selected.hotelId ||
            !selected.contracts ||
            !offers.offerRate ||
            !offers.offerType ||
            !offers.operators ||
            !offers.rooms ||
            !selected.sellEndDate ||
            !selected.sellStartDate ||
            !offers.stayStartDate ||
            !offers.stayEndDate
          } */
              >
                <IntlMessages id="save" />
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Paper>
      <Grid className={classes.margin} item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={!(selected.hasOwnProperty("hotelId") && selected.hotelId)}
          onClick={() => setTableFlag(!tableFlag)}
          startIcon={
            tableFlag ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          <IntlMessages id="last.actions" />
        </Button>
      </Grid>
      <Grid item xs={12}>
        {tableFlag ? <OfferTable /> : ""}
      </Grid>
      <Dialog
        open={showOfferDiscounts}
        onClose={() => setShowOfferDiscounts(false)}
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        scroll="body"
      >
        <OfferListModal
          setShowOfferDiscounts={setShowOfferDiscounts}
          offerTypesinOperator={offerTypes}
        />
      </Dialog>
    </div>
  );
}
