import { normalize, schema } from "normalizr";

const eb_offer = new schema.Entity("eb_offer");
const rolling_eb_offer = new schema.Entity("rolling_eb_offer");
const free_night_offer = new schema.Entity("free_night_offer");
const long_stay_offer = new schema.Entity("long_stay_offer");
const turbo_eb_offer = new schema.Entity("turbo_eb_offer");
const upgrade_offer = new schema.Entity("upgrade_offer");
const special_discount_offer = new schema.Entity("special_discount_offer");
const special_rate_offer = new schema.Entity("special_rate_offer");
const senior_reduction_offer = new schema.Entity("senior_reduction_offer");
const honeymoon_reduction_offer = new schema.Entity(
  "honeymoon_reduction_offer"
);
const expedient_reduction_offer = new schema.Entity(
  "expedient_reduction_offer"
);
const get_board = new schema.Entity("get_board");
const selectedBoards = new schema.Entity("selectedBoards", {
  get_board: [get_board]
});
const rooms = new schema.Entity("rooms");
const get_rooms = new schema.Entity("get_room" /* {
  room: [rooms],
} */);
const get_operator = new schema.Entity("get_operator");
const selectedRoom = new schema.Entity("selectedRooms", {
  get_room: [get_rooms]
});
const selectedOperator = new schema.Entity("selectedOperators", {
  get_operator: [get_operator]
});
const upgradeSelectedRoom = new schema.Entity("upgradeSelectedRoom", {
  get_room: [get_rooms]
});
const upgradeSelectedBoard = new schema.Entity("upgradeSelectedBoard", {
  get_board: [get_board]
});

const offer = new schema.Entity("offers", {
  eb_offer: [eb_offer],
  selectedBoard: [selectedBoards],
  selectedRoom: [selectedRoom],
  selectedOperator: [selectedOperator],
  rolling_eb_offer: [rolling_eb_offer],
  free_night_offer: [free_night_offer],
  long_stay_offer: [long_stay_offer],
  turbo_eb_offer: [turbo_eb_offer],
  upgrade_offer: [upgrade_offer],
  special_discount_offer: [special_discount_offer],
  special_rate_offer: [special_rate_offer],
  senior_reduction_offer: [senior_reduction_offer],
  honeymoon_reduction_offer: [honeymoon_reduction_offer],
  expedient_reduction_offer: [expedient_reduction_offer],
  upgradeSelectedRoom: [upgradeSelectedRoom],
  upgradeSelectedBoard: [upgradeSelectedBoard]
});
export const normalizeTasks = data => normalize(data, [offer]);
