import React, { useEffect } from "react";
import { getOperatorService } from "services/operator";
import OperatorCredential from "./OperatorCredential";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";

const OperatorCredentialDetail = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOperatorService());
  }, [dispatch]);

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "95%",
      height: "100%",
      marginLeft: theme.spacing(7),
    },
    formElement: {
      margin: theme.spacing(2),
      fontSize: "19px",
      color: localStorage.getItem("theme-type") === "dark" ? "white" : "black",
      textAlign: "center",
    },
    formElement2: {
      margin: theme.spacing(2),
      textAlign: "-webkit-center",
    },
  }));
  const classes = useStyles();
  const { operators } = useSelector(({ operators }) => operators);
  return (
    <div>
      <Card className={classes.root}>
        <Typography className={classes.formElement}>
          <IntlMessages id="operator.credentials" />
        </Typography>
        <Grid className={classes.formElement2}>
          {Object.values(operators).map((item) => {
            return item.id <= 16 ? (
              <OperatorCredential
                opId={item.id}
                opName={item.name}
                opUser={item.username}
                opPassword={item.password}
              />
            ) : (
              <></>
            );
          })}
        </Grid>
      </Card>
    </div>
  );
};

export default OperatorCredentialDetail;
