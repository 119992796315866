import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getCancellationPolicies,
  addCancellationPolicies,
  updateCancellationPolicies,
  deleteCancellationPolicies,
  addCancellationPoliciesDetail,
  updateCancellationPoliciesDetail,
  deleteCancellationPoliciesDetail,
  getContractCancellationPolicies,
  addContractCancellationPolicies,
  updateContractCancellationPolicies,
  deleteContractCancellationPolicies,
  getAllContractCancellationPolicies
} from "redux/actions/cancellationPoliciesDetail";
import axios from "./axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/
const MySwal = withReactContent(Swal);
//Cancellation Policies
export const getCancellationPoliciesServices = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("cancellationpolicies")
    .then(res => {
      dispatch(getCancellationPolicies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addCancellationPoliciesServices = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("cancellationpolicies", data)
    .then(res => {
      dispatch(addCancellationPolicies(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateCancellationPoliciesServices = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("cancellationpolicies", data)
    .then(res => {
      dispatch(updateCancellationPolicies(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteCancellationPoliciesServices = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`cancellationpolicies/${id}`)
    .then(res => {
      dispatch(deleteCancellationPolicies(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, id);
    })
    .catch(e => {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `${e.response.data.message}`
      });
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

//Cancellation Policies Detail
export const addCancellationPoliciesDetailService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("cancellationpoliciesdetail", data)
    .then(res => {
      dispatch(addCancellationPoliciesDetail(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateCancellationPoliciesDetailService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("cancellationpoliciesdetail", data)
    .then(res => {
      dispatch(updateCancellationPoliciesDetail(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteCancellationPoliciesDetailService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`cancellationpoliciesdetail/${id}`)
    .then(res => {
      dispatch(deleteCancellationPoliciesDetail(res));
      dispatch(fetchSuccess());
      sendLogService.cancellationPoliciesServiceLog(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const getContractCancellationPoliciesService = contract_id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("contractCancellationPolicies", {
      params: {
        cId: contract_id
      }
    })
    .then(res => {
      dispatch(getContractCancellationPolicies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const getAllContractCancellationPoliciesService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`allContractCancellationPolicies`)
    .then(res => {
      dispatch(getAllContractCancellationPolicies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addContractCancellationPoliciesService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contractCancellationPolicies", data)
    .then(res => {
      dispatch(addContractCancellationPolicies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateContractCancellationPoliciesService = data => async dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  try {
    dispatch(fetchStart());
    const res = await axios.put("contractCancellationPolicies", data);
    await dispatch(updateContractCancellationPolicies(res));
    await dispatch(fetchSuccess());
    return res;
  } catch (e) {
    dispatch(fetchError(e.message));
    sendLogService.errorlog(e, data);
  }
};

export const deleteContractCancellationPoliciesService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`contractCancellationPolicies/${id}`)
    .then(res => {
      dispatch(deleteContractCancellationPolicies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
