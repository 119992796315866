import { GET_HOTEL_MATCH, ADD_HOTEL_MATCH, UPDATE_HOTEL_MATCH, DELETE_HOTEL_MATCH } from '../../redux/actions/types';

export const getHotelMatch = res => dispatch => {
  dispatch({
    type: GET_HOTEL_MATCH,
    payload: res.data.data,
  });
};
export const addHotelMatch = res => dispatch => {
  dispatch({
    type: ADD_HOTEL_MATCH,
    payload: res.data.data,
  });
};
export const updateHotelMatch = res => dispatch => {
  dispatch({
    type: UPDATE_HOTEL_MATCH,
    payload: res.data.data,
  });
};
export const deleteHotelMatch = res => dispatch => {
  dispatch({
    type: DELETE_HOTEL_MATCH,
    payload: res.data.data,
  });
};
