import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { utils, read } from "xlsx";

import { useDispatch, useSelector } from "react-redux";
import { getContractDetails } from "@jumbo/components/Reports/MeetPointExcel/utils/getContractDetails";
import { getHotelInfo } from "@jumbo/components/Reports/MeetPointExcel/utils/getHotalData";
import MultiSelect from "../../Common/MultiSelect";
import "../../../../../src/styles/css/contract.css";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import { getMarketCountries } from "@jumbo/components/Reports/MeetPointExcel/utils/getMarketCountries";
import { getHotelService } from "services/hotels";
import { getMarketService } from "services/market";
import cities from "../../../../data/cities.json";
import { addMeetingPointExcel, getMeetingPointRooms } from "services/excel";
import moment from "moment";
import { getFamilyRooms } from "@jumbo/components/Reports/MeetPointExcel/utils/getFamilyRooms";
import { getRooms } from "@jumbo/components/Reports/MeetPointExcel/utils/getRooms";
import { getReductions } from "@jumbo/components/Reports/MeetPointExcel/utils/getReductionsBoards";
import {
  countryMemo,
  nonEnterprise,
} from "redux/selector/companyHasOfficeSelector";
import { getPeriods } from "@jumbo/components/Reports/MeetPointExcel/utils/getPeriods";
import { getSpecialConditions } from "@jumbo/components/Reports/MeetPointExcel/utils/getSpecialConditions";
import { useIntl } from "react-intl";
import getEarlyBookings from "@jumbo/components/Reports/MeetPointExcel/getEarlyBookings";
import getSpecialDiscount from "@jumbo/components/Reports/MeetPointExcel/utils/getSpecialDiscount";
import DragDropFile from "@jumbo/components/Common/DragDropFile";
import { getCountriesService } from "services/destinations";
import { getOperatorService } from "services/operator";
import "../../../../../src/styles/css/alert.css";
import { getCurrenciesService } from "services/currency";
import { getAdminCompaniesService } from "services/company";
import { getBoardsService } from "services/boards";
import MeetingPointHotelMatch from "./MeetingPointHotelMatch";
import _, { add } from "lodash";
import { getMatchHotelDataService } from "services/hotelmatch";
import { getOperatorHotelsService } from "services/operatorhotels";
import MeetingPointRoomTable from "./MeetingPointRoomTable";
import MeetPointFirstRoomMatch from "./MeetPointFirstRoomMatch";
import Select from "react-select";
import { Autocomplete } from "@mui/material";
import { Checkbox, Typography, Pagination } from "antd";
import { MeetpointExcelSelectInputsForInvalidCountries } from "./MeetpointExcelSelectInputsForInvalidCountries";
import MeetpointExcelSelectInputsForValidCountries from "./MeetpointExcelSelectInputsForValidCountries";
import countries from "../../../../data/countries.json";
import { addOfferService } from "services/offers";
import { addMeetingPointAdditionalPaxService } from "services/additionalPax";
import { getCountryIdsByName } from "./utils/getCountryIdsByName";
import { getRooms2 } from "@jumbo/components/Reports/MeetPointExcel/utils/getRoom2";

// const Transition = React.forwardRef((props, ref) => (
//   <Slide direction="up" ref={ref} {...props} />
// ));

function MeetingPointExcelImport({
  excelData,
  setExcelData,
  handleImportClose,
}) {
  // const cityData = useRef(cities);
  // const subCityData = useRef(subCities);
  // const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  // let isAuthAdd = permissionsByAuthUser.some(
  //   (permission) => permission.route_id == 2 && permission.post === 1
  // );
  // let isAuthUpdate = permissionsByAuthUser.some(
  //   (permission) => permission.route_id == 2 && permission.put === 1
  // );
  // let isAuthDelete = permissionsByAuthUser.some(
  //   (permission) => permission.route_id == 2 && permission.delete === 1
  // );
  const { operators } = useSelector(({ operators }) => operators);
  const { boards } = useSelector(({ boards }) => boards);
  const { authUser } = useSelector(({ auth }) => auth);
  const onlyCompany = useSelector(nonEnterprise);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { markets } = useSelector(({ markets }) => markets);
  // const { marketCountries } = useSelector(({ markets }) => markets);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { matched_rooms } = useSelector(({ operatorRooms }) => operatorRooms);

  let allCountries = countries;
  let operatorIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16];

  useEffect(() => {
    dispatch(getCountriesService(["", 0]));
    dispatch(getOperatorService());
    dispatch(getCurrenciesService());
    dispatch(getAdminCompaniesService());
    dispatch(getBoardsService());
    dispatch(getMarketService());
    dispatch(getHotelService());
    dispatch(getMatchHotelDataService());
    dispatch(getOperatorHotelsService(operatorIds));
  }, []);

  const [operatorState, setOperatorState] = useState([]);
  const [tempMarkets, setTempMarkets] = useState([]);

  // selected countries
  const [checkedList, setCheckedList] = useState([]);
  const [resMeetData, setResMeetData] = useState([]);

  let hotelInfo = useRef([]);
  let marketInfo = useRef([]);
  let contractInfo = useRef({});
  let roomInfo = useRef({});
  let roomInfo2 = useRef({});

  let familyRoomInfo = useRef({});
  let reductionInfo = useRef({});
  let periodsInfo = useRef([]);
  let matchedRooms = useRef([]);
  let earlyBookings = useRef([]);
  let specialDiscount = useRef([]);
  let specialConditions = useRef([]);
  // let hasHotel = true;
  let roomMatchData = useRef([]);
  // const [giataCode, setGiataCode] = useState(null);
  const hotel_id =
    Object.values(hotels).find((hotel) => {
      return hotel.name === hotelInfo?.current?.name;
    })?.id || null;
  const rate_types = { PP: 1, PR: 2, PPAX: 3, PA: 4, PPP: 5 };

  const [excludes, setexcludes] = useState([]);
  const getInfos = (sheets, cities) =>
    new Promise((resolve, reject) => {
      let secondPage = 0;
      sheets.map((sheet, index) =>
        Object.keys(sheet[0])[0].includes("Contract Pages[2]")
          ? (secondPage = index)
          : null
      );
      hotelInfo.current = getHotelInfo(sheets[0]);
      contractInfo.current = getContractDetails(sheets[0]);
      if (
        hotelInfo.current.hasOwnProperty("name") &&
        hotelInfo.current.hasOwnProperty("company") &&
        contractInfo.current.hasOwnProperty("seasonStartDate")
      ) {
        roomInfo.current = getRooms(sheets[0]);
        roomInfo2.current = getRooms2(sheets[0]);
        roomInfo.current.push(...getFamilyRooms(sheets[0]));
        reductionInfo.current = getReductions(sheets[0]);
        familyRoomInfo.current = getFamilyRooms(sheets[0]);
        periodsInfo.current = getPeriods(sheets[0]);
        earlyBookings.current = getEarlyBookings(sheets[secondPage]);
        specialDiscount.current = getSpecialDiscount(sheets[secondPage]);
        specialConditions.current = getSpecialConditions(sheets[secondPage]);
        marketInfo.current = getMarketCountries(sheets[secondPage]);
        getMatchedHotel();
        resolve();
      } else reject("Please check the file format");
      // hotelInfo.current = {
      //     ...hotelInfo.current,
      //     city_id :  cities[0].find((city) =>  hotelInfo.current.adress.includes(city.name)).id
      // }
      // if adress has city name then get c
    });
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedRoomInfo, setSelectedRoomInfo] = useState([]);
  const [hasContractUploaded, setHasContractUploaded] = useState(false);
  // const [showHotelMatch, setShowHotelMatch] = useState(true);
  const [showRoomMatchPage, setShowRoomMatchPage] = useState(false);
  const [showFirstRoomMatchPage, setShowFirstRoomMatchPage] = useState(false);
  const [contractRooms, setContractRooms] = useState([]);
  const [fileName, setFileName] = useState("");
  const getMeetingPointData = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let file = {};
    if (type === "drop" && e.dataTransfer.files && e.dataTransfer.files[0]) {
      file = e.dataTransfer.files[0];
    } else if (type === "click" && e.target.files && e.target.files[0]) {
      file = e.target.files[0];
    }
    setFileName(file.name);
    let x = file.name.split(".").pop();
    if (
      x != "xls" &&
      x != "csv" &&
      x != "XLS" &&
      x != "CSV" &&
      x != "xlsx" &&
      x != "XLSX"
    ) {
      MySwal.fire({
        icon: "warning",
        title: intl.formatMessage({ id: "wrong.file.type" }),
        text: intl.formatMessage({ id: "please.upload.only.csv.and.xls.file" }),
      });
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "binary" });
        const sheets = workbook.SheetNames.map((sheet) => {
          return utils.sheet_to_json(workbook.Sheets[sheet]);
        });

        getInfos(sheets, cities)
          .then(() => {
            setIsFilePicked(true);
            setShowRoomMatchPage(true);
            hasContractUploaded && setHasContractUploaded(false);
            tempMarkets !== [] && setTempMarkets([]);
            operatorState !== [] && setOperatorState([]);
          })
          .catch((err) => {
            setIsFilePicked(false);
            hasContractUploaded && setHasContractUploaded(false);
            tempMarkets !== [] && setTempMarkets([]);
            operatorState !== [] && setOperatorState([]);
            showRoomMatchPage && setShowRoomMatchPage(false);

            sweetAlerts("error", err ? err : "Please check the file format");
          });
      };
      reader.readAsBinaryString(file);
    }
  };

  // const hiddenFileInput = React.useRef(null);
  // const handleClick = () => {
  //   hiddenFileInput.current.click();
  // };

  // const checkDatas = (data) => {
  //   if (data.hotelInfo) {
  //     setHotelInformation(data.hotelInfo);

  //     setSeason({
  //       ...season,
  //       seasonType: data.hotelInfo.hotelSeason,
  //       seasonStartDate: data.hotelInfo?.seasonStartDate,
  //       seasonEndDate: data.hotelInfo?.seasonEndDate
  //     });
  //   }
  // };
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    content: {
      flex: "1",
      display: "flex",
      padding: "30px 15px",
      boxSizing: "border-box",
      flexDirection: "column",
      backgroundColor: "#ffffff",
    },
    root: {
      minWidth: "100%",
      margin: "0px",
      display: "flex",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
    dragDrop: {
      display: "flex",
      minWidth: "100%",
      justifyContent: "center",
      marginTop: "15px",
    },
    button: {
      padding: ".1rem",
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      cursor: "pointer",
      color: "white",
      width: "120px",
      padding: "1rem",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      margin: "0.5rem",
      padding: "0.5rem",
      border: "none",
    },
  }));
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const intl = useIntl();

  const countriesOperator =
    // To avoid re-rendering of the function
    (marketsEvent) => {
      let tempOperator = [];
      if (
        Object.values(markets).length > 0 &&
        Object.values(checkedList).length > 0 &&
        marketsEvent.length > 0
      ) {
        const selectedCountryIds = allCountries.reduce((acc, country) => {
          if (checkedList.includes(country.name)) {
            acc.push(country.id);
          }
          return acc;
        }, []);

        tempOperator = Object.values(operators).reduce((acc, operator) => {
          if (operator.countries.length > 0) {
            for (let i = 0; i < operator.countries.length; i++) {
              if (selectedCountryIds.includes(operator.countries[i])) {
                acc.push(operator);
                break;
              }
            }
          }
          return acc;
        }, []);
      }
      return tempOperator;
    };

  const getMarketCountryIds = (marketsEvent) => {
    // get Ids of selected countries

    const marketCountries = marketsEvent.reduce((acc, market) => {
      market.countries.forEach((country) => {
        if (
          !acc.includes(country) &&
          marketInfo.current.validCountries.includes(country)
        ) {
          acc.push(country);
        }
      });
      return acc;
    }, []);
    return marketCountries;
  };

  const getMarketsWithFilteredCountries = (
    selectedMarkets,
    contractCountries
  ) => {
    return selectedMarkets.map((market) => {
      market.countries = market.countries.filter((country) =>
        contractCountries.includes(country)
      );
      return market;
    });
  };

  const compname = "Meeting Point";
  const classes = useStyles();
  const [isMarketAdded, setIsMarketAdded] = useState(false);
  const apply_types = [
    {
      code: "PP",
      id: 1,
      label: "Per Person",
      value: 1,
      name: "Per Person",
    },

    {
      code: "PR",
      id: 2,
      label: "Per Room",
      value: 2,
      name: "Per Room",
    },
  ];

  const apply_stay_type = {
    "per day": 1,
    "per week": 2,
    "per stay": 3,
  };
  const formattedContractStartDate = moment(
    contractInfo.current.seasonStartDate,
    "DD.MM.YYYY"
  ).format("YYYY-MM-DD");
  const formattedContractEndDate = moment(
    contractInfo.current.seasonEndDate,
    "DD.MM.YYYY"
  ).format("YYYY-MM-DD");
  const roomIds = Object.values(contract_room)
    .map((room) => room.contract_id == selectedContract && room.id)
    .filter((room) => room !== false);
  const getEarlyBookingPayload = () => {
    if (earlyBookings.current === undefined || earlyBookings.current === null) {
      return null;
    }

    const offerDatas = earlyBookings.current[0].reduce((acc, row, index) => {
      const reduction = row[row.length - 1].split(" ");
      const excelApplyType = reduction[0] + " " + reduction[1];
      const excelStayType = reduction[2] + " " + reduction[3];
      const applyType = apply_types.find(
        (item) =>
          item.name.trim("").toLowerCase() ===
          excelApplyType.trim("").toLowerCase()
      );
      const applyStayType = apply_stay_type.hasOwnProperty(
        excelStayType.toLowerCase()
      )
        ? apply_stay_type[excelStayType.toLowerCase()]
        : 1;

      // string to int
      const excelDiscount = parseInt(
        earlyBookings.current[1][index]["A"].replace("%", "")
      );
      const paymentDiscount = parseInt(row[row.length - 2].replace("%", ""));

      const splittedSellingDate = earlyBookings.current[1][index][
        "Reservation realized between"
      ].split("/");
      const selling_start_date = splittedSellingDate[0];
      const selling_end_date = splittedSellingDate[1];

      const formattedSellingStartDate = moment(
        selling_start_date,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");
      const formattedSellingEndDate = moment(
        selling_end_date,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");

      const offerData = {
        arrival_stay:
          contractInfo.current.rateType.trim("").toLowerCase() === "arrival"
            ? 1
            : 2,
        apply_type: [applyType],
        apply_stay_type: applyStayType,
        contract_id: selectedContract,
        discount: excelDiscount,
        eb_list_date: row[row.length - 4],
        pay_date: row[row.length - 3],
        offer_code: earlyBookings.current[1][index]["EB NAME"],
        min_stay: contractInfo.current.minimumNights,
        payment_discount: paymentDiscount,
        selected_board_id:
          contractInfo.current.board !== undefined
            ? [contractInfo.current.board]
            : [2],
        selected_operator_id: contractInfo.current.operators,
        selected_room_id: roomIds,
        offer_confilict_id: [],
        export_type: true,
        offer_info: {
          offer_type_id: 1,
          name: earlyBookings.current[1][index]["EB NAME"],
          selling_start_date: formattedSellingStartDate,
          selling_end_date: formattedSellingEndDate,
          stay_date_from: formattedContractStartDate,
          stay_date_to: formattedContractEndDate,
        },
      };
      acc.push(offerData);
      return acc;
    }, []);
    return offerDatas;
  };

  const getSpecialDiscountOfferPayload = () => {
    if (specialDiscount.current === null) return null;
    const specialDiscounts = specialDiscount.current.specialDiscount;
    const excelRows = specialDiscount.current.filteredData;
    const specialDiscountIndex = specialDiscount.current.specialDiscountIndex;
    const offerDatas = specialDiscounts.reduce((acc, row) => {
      const reduction = excelRows[specialDiscountIndex][0]
        .split("(")[1]
        .split("/");
      const excelApplyType = reduction[0]
        .trim("")
        .replace(" ", "")
        .toLowerCase();
      const excelStayType = reduction[1]
        .trim("")
        .replace(")", "")
        .replaceAll(" ", "");
      const applyType =
        apply_types.find(
          (item) => item.code.toLowerCase() === excelApplyType
        ) || 1;
      //
      const applyStayType =
        Object.keys(apply_stay_type).find((item) =>
          item.includes(excelStayType)
        ) !== undefined
          ? apply_stay_type[
              Object.keys(apply_stay_type).find((item) =>
                item.includes(excelStayType)
              )
            ]
          : 1;

      const excelDiscount = row[6].split(" ")[0].replace("%", "");
      const selling_start_date = row[4];
      const selling_end_date = row[5];

      const formattedSellingStartDate = moment(
        selling_start_date,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");
      const formattedSellingEndDate = moment(
        selling_end_date,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");

      const offerData = {
        arrival_stay:
          contractInfo.current.rateType.trim("").toLowerCase() === "arrival"
            ? 1
            : 2,
        apply_type: [applyType],
        apply_stay_type: applyStayType,
        contract_id: selectedContract,
        discount: excelDiscount,
        offer_code: row[0],
        min_stay: row[1] !== "" ? row[1] : 1,
        max_stay: row[2] !== "" ? row[2] : 99,
        selected_board_id: [7],
        selected_board_id:
          contractInfo.current.board !== undefined
            ? [contractInfo.current.board]
            : [2],
        selected_operator_id: contractInfo.current.operators,
        selected_room_id: roomIds,
        offer_confilict_id: [],
        export_type: true,
        offer_info: {
          offer_type_id: 7,
          name: row[0],
          selling_start_date: formattedSellingStartDate,
          selling_end_date: formattedSellingEndDate,
          stay_date_from: formattedContractStartDate,
          stay_date_to: formattedContractEndDate,
        },
      };
      acc.push(offerData);
      return acc;
    }, []);

    return offerDatas;
  };

  const getAddPaxReductions = () => {
    const normalRaxReductions = getAddPaxes(
      reductionInfo.current.normalReductions,
      "PP"
    );
    const familyRaxReductions = getAddPaxes(
      reductionInfo.current.familyReductions,
      "PR"
    );

    let normalRaxReductions2;
    if (reductionInfo.current.normalReductions2) {
      normalRaxReductions2 = getAddPaxes(
        reductionInfo.current.normalReductions2,
        "PP"
      );
    } else {
      normalRaxReductions2 = false;
    }

    // check if normal reductions and family reductions are the same

    const hasDiffirenceBetweenReductions = Object.entries(
      normalRaxReductions
    ).some(([key, value]) => {
      return Object.values(value).some((item, index) => {
        if (item !== familyRaxReductions[key][index] && item.includes("%")) {
          return true;
        }
      });
    });
    return hasDiffirenceBetweenReductions
      ? {
          normalReductions: normalRaxReductions,
          familyReductions: familyRaxReductions,
          normalReductions2: normalRaxReductions2 ? normalRaxReductions2 : "",
          contract_id: normalRaxReductions.contract_id,
          rooms: normalRaxReductions.rooms,
        }
      : normalRaxReductions.adultReduction != ""
      ? {
          normalReductions: normalRaxReductions,
          familyReductions: familyRaxReductions,
          contract_id: normalRaxReductions.contract_id,
        }
      : {
          normalReductions: normalRaxReductions,
          familyReductions: familyRaxReductions,
          contract_id: normalRaxReductions.contract_id,
        };
  };

  // TODO  : Maybe move it to a helper file
  const getAddPaxes = (reduction, type) => {
    const rooms = contract_room;

    const thirdAdultReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "3rd person reduction/rate"
    );
    const fourthAdultReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "4th person reduction/rate "
    );
    const fifthAdultReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "5th person reduction/rate "
    );
    const sixthAdultReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "6th person reduction/rate "
    );
    const otherAdultReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "4th & 5th & 6th persons reduction/rate"
    );
    const childOneReductionRowIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "Child 1 Reduction/rate"
    );
    const childTwoReductionRowIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "Children 2  Reduction/rate"
    );
    const childThirdReductionRowIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "Children 3  Reduction/rate"
    );

    const otherChildsReductionsIndex = Object.values(reduction).findIndex(
      (item) => item?.__EMPTY === "Children 2 & 3 & 4 Reduction/rate"
    );
    const hasAdultReduction =
      thirdAdultReductionsIndex !== -1 &&
      reduction[thirdAdultReductionsIndex].hasOwnProperty("A") &&
      reduction[thirdAdultReductionsIndex].A !== ""
        ? true
        : false;

    const hasFourthAdultReduction =
      fourthAdultReductionsIndex !== -1 &&
      reduction[fourthAdultReductionsIndex].hasOwnProperty("A") &&
      reduction[fourthAdultReductionsIndex].A !== ""
        ? true
        : false;

    const hasFifthAdultReduction =
      fifthAdultReductionsIndex !== -1 &&
      reduction[fifthAdultReductionsIndex].hasOwnProperty("A") &&
      reduction[fifthAdultReductionsIndex].A !== ""
        ? true
        : false;
    const hasSixthAdultReduction =
      sixthAdultReductionsIndex !== -1 &&
      reduction[sixthAdultReductionsIndex].hasOwnProperty("A") &&
      reduction[sixthAdultReductionsIndex].A !== ""
        ? true
        : false;

    const hasOtherAdultReduction =
      otherAdultReductionsIndex !== -1 &&
      Object.keys(reduction[otherAdultReductionsIndex]).length > 1 &&
      reduction[otherAdultReductionsIndex].A !== ""
        ? true
        : false;
    // reduction[fourthAdultReductionIndex].hasOwnProperty("A")
    //   ? reduction[fourthAdultReductionIndex]["A"] !== ""
    //     ? true
    //     : false
    //   : false;

    const hasChildReduction =
      childOneReductionRowIndex !== -1 &&
      reduction[childOneReductionRowIndex].hasOwnProperty("A") &&
      reduction[childOneReductionRowIndex].A !== ""
        ? true
        : false;

    const hasChildTwoReduction =
      childTwoReductionRowIndex !== -1 &&
      reduction[childTwoReductionRowIndex].hasOwnProperty("A") &&
      reduction[childTwoReductionRowIndex].A !== ""
        ? true
        : false;

    const hasChildThirdReduction =
      childThirdReductionRowIndex !== -1 &&
      reduction[childThirdReductionRowIndex].hasOwnProperty("A") &&
      reduction[childThirdReductionRowIndex].A !== ""
        ? true
        : false;

    const hasOtherReductions =
      otherChildsReductionsIndex !== -1 &&
      Object.keys(reduction[otherChildsReductionsIndex]).length > 1 &&
      reduction[otherChildsReductionsIndex].A !== ""
        ? true
        : false;

    let filteredReductions = {
      adultReduction: hasAdultReduction
        ? reduction[thirdAdultReductionsIndex]
        : "",
      otherAdultReductions: hasOtherAdultReduction
        ? reduction[otherAdultReductionsIndex + 1]
        : "",
      adult4Reductions: hasFourthAdultReduction
        ? reduction[fourthAdultReductionsIndex + 1]
        : "",
      adult5Reductions: hasFifthAdultReduction
        ? reduction[fifthAdultReductionsIndex + 1]
        : "",
      adult6Reductions: hasSixthAdultReduction
        ? reduction[sixthAdultReductionsIndex + 1]
        : "",
      child1Reductions: hasChildReduction
        ? reduction[childOneReductionRowIndex]
        : "",
      child2Reductions: hasChildTwoReduction
        ? reduction[childTwoReductionRowIndex]
        : "",
      child3Reductions: hasChildThirdReduction
        ? reduction[childThirdReductionRowIndex]
        : "",
      child1ReductionsNext: hasChildReduction
        ? reduction[childOneReductionRowIndex + 1]
        : "",
      child2ReductionsNext: hasChildTwoReduction
        ? reduction[childTwoReductionRowIndex + 1]
        : "",

      child3ReductionsNext: hasChildThirdReduction
        ? reduction[childThirdReductionRowIndex + 1]
        : "",
      child234Reductions: hasOtherReductions
        ? reduction[otherChildsReductionsIndex]
        : "",
      child234ReductionsNext: hasOtherReductions
        ? reduction[otherChildsReductionsIndex + 1]
        : "",
      contract_id: selectedContract,
      rooms: roomIds,
    };

    if (filteredReductions.child1Reductions !== "") {
      filteredReductions.child1Reductions["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child1Reductions["age_from"] = item;
          } else {
            filteredReductions.child1Reductions["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child1ReductionsNext !== "") {
      filteredReductions.child1ReductionsNext["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child1ReductionsNext["age_from"] = item;
          } else {
            filteredReductions.child1ReductionsNext["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child2Reductions !== "") {
      filteredReductions.child2Reductions["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child2Reductions["age_from"] = item;
          } else {
            filteredReductions.child2Reductions["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child2ReductionsNext !== "") {
      filteredReductions.child2ReductionsNext["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child2ReductionsNext["age_from"] = item;
          } else {
            filteredReductions.child2ReductionsNext["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child3Reductions !== "") {
      filteredReductions.child3Reductions["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child3Reductions["age_from"] = item;
          } else {
            filteredReductions.child3Reductions["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child3ReductionsNext !== "") {
      filteredReductions.child3ReductionsNext["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child3ReductionsNext["age_from"] = item;
          } else {
            filteredReductions.child3ReductionsNext["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child234Reductions !== "") {
      filteredReductions.child234Reductions["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child234Reductions["age_from"] = item;
          } else {
            filteredReductions.child234Reductions["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }
    if (filteredReductions.child234ReductionsNext !== "") {
      filteredReductions.child234ReductionsNext["__EMPTY_2"]
        .split("-")
        .map((item, index) => {
          if (index === 0) {
            filteredReductions.child234ReductionsNext["age_from"] = item;
          } else {
            filteredReductions.child234ReductionsNext["age_to"] =
              parseFloat(item) + 0.99;
          }
        });
    }

    return filteredReductions;
  };

  // // TODO: do this on the backend when we have the full room data from the excel
  // const getRoomWithFilteredType = (roomType) => {
  //  return Object.values(contract_room)
  //   .reduce((acc, room) => {
  //     const roomInfoType = roomInfo.current.find(
  //       (roomInf) => roomInf.roomName === room.agency_room_name
  //     ).roomCode;
  //     if (roomIds.includes(room.id) && roomInfoType === roomType ) {
  //       acc.push(room.id);
  //     }
  //     return acc;
  //   }, [])
  // };

  const saveMeetingPoint = async () => {
    //dispatch(addHotelService(hotelInfo.current));

    const season_start_date = contractInfo.current.seasonStartDate
      .split(".")
      .reverse()
      .join("-");
    const season_end_date = contractInfo.current.seasonEndDate
      .split(".")
      .reverse()
      .join("-");

    // get season year
    const seasonYear = contractInfo.current.seasonStartDate.split(".")[2];

    const seasonData = {
      name: contractInfo.current.seasonCode,
      code: contractInfo.current?.seasonCode,
      start_date: season_start_date,
      end_date: season_end_date,
      type: contractInfo.current.seasonType,
    };
    const contract_rate_types =
      familyRoomInfo.current !== null &&
      familyRoomInfo.current !== undefined &&
      familyRoomInfo.current.length > 0
        ? roomInfo.current[0]?.roomCode === familyRoomInfo.current[0]?.roomCode
          ? [rate_types[roomInfo.current[0]?.roomCode]]
          : [
              rate_types[roomInfo.current[0]?.roomCode],
              rate_types[familyRoomInfo.current[0]?.roomCode],
            ]
        : [rate_types[roomInfo.current[0]?.roomCode]];
    let min_child_age =
      reductionInfo.current.normalReductions[2]?.__EMPTY_2 !== undefined &&
      reductionInfo.current.normalReductions[2]?.__EMPTY_2 !== ""
        ? reductionInfo.current.normalReductions[2]?.__EMPTY_2.split("-")[0]
        : null;
    const max_child_age =
      reductionInfo.current.normalReductions[2]?.__EMPTY_2 !== undefined &&
      reductionInfo.current.normalReductions[2]?.__EMPTY_2 !== ""
        ? reductionInfo.current.normalReductions[3]?.__EMPTY_2.split("-")[1]
        : null;

    hotelInfo.current.name.toLowerCase().includes("only adult")
      ? (contractInfo.current.checkChild = true)
      : (contractInfo.current.checkChild = false);
    contractInfo.current.min_child_age = parseInt(min_child_age);
    contractInfo.current.max_child_age = parseInt(max_child_age) + 0.99;

    contractInfo.current.board = Object.values(boards).find(
      (board) => board.code === contractInfo.current.mealPlan
    )?.id;
    contractInfo.current.company_id = onlyCompany[0]?.id;
    contractInfo.current.company_name = onlyCompany[0]?.name;

    contractInfo.current.contract_name =
      seasonData?.code + " " + " " + contractInfo.current?.currency;
    contractInfo.current.operators = operatorState.map(
      (operator) => operator.id
    );
    contractInfo.current.currencyCode = Object.values(currencies).find(
      (currency) => currency.code === contractInfo.current.currency
    )?.id;

    //const include_countries = getMarketCountryIds(tempMarkets);
    // const marketsWithFilteredCountries = getMarketsWithFilteredCountries(
    //   tempMarkets,
    //   include_countries
    // );
    const include_countries = getCountryIdsByName(allCountries, checkedList);
    const meetPointData = {
      markets: tempMarkets,
      contractHotel:
        Object.values(hotels).find(
          (hotel) => hotel.name === hotelInfo.current.name
        )?.id || null,
      hotel: selectedHotel || null,
      user: authUser.id,
      contractInfo: contractInfo.current,
      season: seasonData,
      contract_rate_types,
      periods: periodsInfo.current,
      specialConditions: specialConditions.current ?? null,
      include_countries: include_countries,
      contractRooms: selectedRoomInfo,
    };

    const res = await dispatch(addMeetingPointExcel(meetPointData));

    setResMeetData(res?.data?.data?.contract?.contract_room);

    setHasContractUploaded(true);

    Swal.fire({
      title: "Success",
      text: "Contract uploaded successfully.",
      icon: "success",
      confirmButtonText: "Ok",
    });
    setSelectedHotel(null);
    setCheckedList([]);
  };

  useEffect(() => {
    const addOffers = async () => {
      const earlyBookingPayload = getEarlyBookingPayload();
      const specialDiscount = getSpecialDiscountOfferPayload();
      if (earlyBookingPayload) {
        await earlyBookingPayload.forEach((element) => {
          dispatch(addOfferService(element));
        });
      }
      if (specialDiscount) {
        await specialDiscount.forEach((element) => {
          dispatch(addOfferService(element));
        });
      }
    };

    const addPax = async () => {
      const addPaxes = getAddPaxReductions();
      if (addPaxes) {
        if (
          addPaxes.hasOwnProperty("normalReductions2") &&
          addPaxes.normalReductions2 != ""
        ) {
          let tempRoom = selectedRoomInfo;
          let indexArr = resMeetData;
          let normalRoom = [];
          let normalRoom2 = [];
          tempRoom.map((room, index) => {
            roomInfo2.current.map((room2) => {
              if (room2.roomName == room.roomName) {
                let findRoom = resMeetData.find(
                  (meetRoom) =>
                    meetRoom.agency_room_name == room.agency_room_name &&
                    meetRoom.product_room_name == room.product_room_name
                );
                normalRoom2.push(findRoom);
                indexArr = indexArr.filter(
                  (meetRoom) => meetRoom.id != findRoom.id
                );
              }
            });
          });

          indexArr.map((room) => {
            let findRoom = resMeetData.find(
              (meetRoom) =>
                meetRoom.agency_room_name == room.agency_room_name &&
                meetRoom.product_room_name == room.product_room_name
            );
            normalRoom.push(findRoom);
          });

          addPaxes.normalReductions2.rooms = normalRoom2;
          addPaxes.normalReductions.rooms = normalRoom;
          addPaxes.familyReductions = null;

          await dispatch(
            addMeetingPointAdditionalPaxService(addPaxes)
          ).then((res) => {});
        } else {
          let tempRoom = selectedRoomInfo;
          let normalRoom = resMeetData;

          let familyRoom = [];

          tempRoom.map((room, index) => {
            familyRoomInfo.current.map((room2) => {
              if (room2.roomName == room.roomName) {
                let findRoom = resMeetData.find(
                  (meetRoom) =>
                    meetRoom.agency_room_name == room.agency_room_name &&
                    meetRoom.product_room_name == room.product_room_name
                );
                familyRoom.push(findRoom);
                normalRoom = normalRoom.filter(
                  (meetRoom) => meetRoom.id != findRoom.id
                );
              }
            });
          });

          addPaxes.normalReductions.rooms = normalRoom ? normalRoom : [];
          addPaxes.familyReductions.rooms = familyRoom;
          await dispatch(
            addMeetingPointAdditionalPaxService(addPaxes)
          ).then((res) => {});
        }
      }
    };

    if (hasContractUploaded) {
      addOffers();
      addPax();
    }
  }, [hasContractUploaded]);

  const getMatchedHotel = async () => {
    roomMatchData.current = roomInfo.current.map((room) => {
      return {
        operator_room_name: room.roomName,
        global_room_name: null,
      };
    });
    await getMeetingPointRooms({
      name: hotelInfo.current.name,
      operators: contractInfo.current.operators,
      rooms: roomInfo.current.map((room) => room.roomName.split(" ")),
    })
      .then((res) => {
        if (res.data.data) {
          matchedRooms.current = res.data.data
            .map((room) => ({
              operator_room_name: room.name,
              global_room_name: room.room_name,
            }))
            .filter((room) =>
              roomInfo.current.find(
                (item) => item.roomName === room.operator_room_name
              )
            );
          roomMatchData.current = roomInfo.current.map((room) => {
            return (
              matchedRooms.current.find(
                (item) => item.operator_room_name === room.roomName
              ) ?? {
                operator_room_name: room.roomName,
                global_room_name: null,
              }
            );
          });
        }
      })

      .catch((err) => {
        setShowRoomMatchPage(true);
      });
  };

  let countryInMarket = {};
  if (
    marketInfo.current.validCountries !== null &&
    marketInfo.current.validCountries !== undefined &&
    marketInfo.current.validCountries.length > 0
  ) {
    Object.entries(markets).forEach((key, value) => {
      let excludeCountries = [];
      if (key[1].countries !== null) {
        const hasExcelCountry =
          key[1].countries.find((country) =>
            marketInfo.current.validCountries?.includes(country)
          ) !== undefined
            ? true
            : false;
        key[1].countries.map((country) => {
          if (
            !marketInfo.current.validCountries?.includes(country) &&
            !excludeCountries.includes(country)
          ) {
            excludeCountries.push(country);
          }
        });
        key[1]["excludeCountries"] = excludeCountries;
        if (hasExcelCountry) {
          countryInMarket[value] = key[1];
        }
      }
    });
  }

  const handleMarketChange = (event) => {
    setTempMarkets(event);

    countriesOperator(event);
  };

  const [selectedHotel, setSelectedHotel] = useState(null);

  return (
    <>
      <Paper elevation={3} style={{ height: "100%" }}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "20px",
            marginTop: "10px",
          }}
        >
          {" "}
          Excel Import
        </div>
        <div className={classes.root}>
          <Grid container item xs={12} spacing={5} className={classes.root}>
            <div className={classes.dragDrop}>
              <DragDropFile
                onChange={getMeetingPointData}
                fileName={fileName}
              />
            </div>
            {isFilePicked && showRoomMatchPage && (
              <div className={classes.root}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={5}
                  className={classes.root}
                >
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <div id="InfoBanner">
                      <span className="reversed reversedRight">
                        <span>&#9888;</span>
                      </span>
                      <span className="reversed reversedLeft">
                        Please map excel rooms with global room. If you mapped
                        multiple excel room , please select base room. Base room
                        is the room that will be used for the contract.
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <MeetingPointRoomTable
                      matchedRooms={matchedRooms}
                      roomInfo={roomInfo}
                      roomMatchData={roomMatchData.current}
                      getEarlyBookingPayload={getEarlyBookingPayload}
                      getSpecialDiscountOfferPayload={
                        getSpecialDiscountOfferPayload
                      }
                      getAddPaxReductions={getAddPaxReductions}
                      setShowRoomMatchPage={setShowRoomMatchPage}
                      setSelectedRoomInfo={setSelectedRoomInfo}
                    />
                  </Grid>
                </Grid>{" "}
              </div>
            )}
            {/* !showRoomMatchPage  */}
            {isFilePicked && !hasContractUploaded && !showRoomMatchPage && (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={5}
                  style={{ alignItems: "flex-end", justifyContent: "center" }}
                >
                  <div id="InfoBanner">
                    <span className="reversed reversedRight">
                      <span>&#9888;</span>
                    </span>
                    <span className="reversed reversedLeft">
                      Please map global hotel with excel hotel
                    </span>
                  </div>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        GWG Hotel
                      </InputLabel>
                      <Input value={hotelInfo.current.name} disabled />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      className={classes.formControl}
                      fullWidth
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        options={Object.values(hotels)}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 300 }}
                        onChange={(event, value) => {
                          setSelectedHotel(value);
                        }}
                        value={selectedHotel}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Global Hotel"
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={5}
                  style={{ alignItems: "flex-end", justifyContent: "center" }}
                >
                  <div id="InfoBanner">
                    <span className="reversed reversedRight">
                      <span>&#9888;</span>
                    </span>
                    <span className="reversed reversedLeft">
                      Before importing excel file, please enter the following
                      data that is not in the excel file.
                    </span>
                  </div>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={5}
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <MultiSelect
                        options={multiDataSetter(
                          "name",
                          "id",
                          Object.values(countryInMarket).sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          )
                        )}
                        value={multiDataSetter("name", "id", tempMarkets)}
                        handleChange={(event) => handleMarketChange(event)}
                        label={"Markets"}
                        placeholder="Select Markets"
                        style={{
                          color:
                            contractInfo.base_board_id >= 0
                              ? "#A3A3A3"
                              : "black",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          border: tempMarkets.length > 0 && "1px solid #A3A3A3",
                          borderRadius: "5px",
                          flexDirection: "column",
                        }}
                      >
                        {tempMarkets.length > 0 && (
                          <>
                            <MeetpointExcelSelectInputsForValidCountries
                              markets={tempMarkets}
                              excelCountries={marketInfo.current.validCountries}
                              checkedList={checkedList}
                              setCheckedList={setCheckedList}
                            />

                            <MeetpointExcelSelectInputsForInvalidCountries
                              markets={tempMarkets}
                              excelCountries={
                                marketInfo.current.invalidCountries
                              }
                              checkedList={checkedList}
                              setCheckedList={setCheckedList}
                            />
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={2} style={{ alignItems: "center" }}>
                      <MultiSelect
                        options={multiDataSetter(
                          "name",
                          "id",
                          countriesOperator(tempMarkets).sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          )
                        )}
                        value={multiDataSetter("name", "id", operatorState)}
                        handleChange={(e) => setOperatorState(e)}
                        label={"Operators"}
                        labelledBy="Select"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {!hasContractUploaded && !showFirstRoomMatchPage && (
                  <Grid xs={12} style={{ textAlign: "center" }}>
                    <FormControl className={classes.button}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveMeetingPoint}
                        disabled={
                          operatorState.length === 0 ||
                          tempMarkets.length === 0 ||
                          showFirstRoomMatchPage ||
                          selectedHotel === null
                        }
                      >
                        Upload Contract
                      </Button>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>

        {/* {showRoomMatchPage && !hasHotel && (
          <MeetingPointHotelMatch hotelInfo={hotelInfo.current} />
        )} */}
      </Paper>
    </>
  );
}

export default React.memo(MeetingPointExcelImport);
