// import { normalize, schema } from "normalizr";

// const rooms = new schema.Entity("rooms");

// export const normalizeRoomMatches = (data) => normalize(data, [rooms]);

import { normalize, schema } from "normalizr";

const rooms = new schema.Entity("rooms");
const matched_rooms = new schema.Entity(
  "matched_rooms",
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    }
  }
);
const stateRooms = new schema.Entity("matched_rooms");
const response = {
  rooms: [rooms],
  updatedRooms: [matched_rooms],
  stateRooms: [matched_rooms]
};

export const normalizeRoomMatches = data => normalize(data, [response]);
