import React, { useEffect, useRef, useState } from "react";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

// MUI
import { Container, Row } from "react-grid-system";
import {
  Grid,
  CardContent,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography
} from "@material-ui/core";
import MaterialTable from "material-table";
import CmtCard from "@coremat/CmtCard";
import { makeStyles } from "@mui/styles";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getOperatorService } from "services/operator";
import { getContractService } from "services/contract";
import { getBoardsService } from "services/boards";
import { getHotelService } from "services/hotels";
import { getMarketService } from "services/market";
import { getCurrenciesService } from "services/currency";
import { getRoomService } from "services/rooms";
import { getReservationsService } from "services/reservation";

// Intl
import { useIntl } from "react-intl";
import TextFilter from "./ReservationReportComponents/TextFilter";
import DateRangeFilter from "./ReservationReportComponents/DateRangeFilter";
import IntlMessages from "@jumbo/utils/IntlMessages";

// Third Party
import _ from "lodash";
import moment from "moment";

// Styles
const useStyles = makeStyles(theme => ({
  filterContainer: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "8px",
    padding: 30,
    maxHeight: "500px",
    width: "500px"
  },
  roomButton: {
    backgroundColor: "#33658a !important",
    fontSize: "10px !important",
    color: "#fff !important"
  }
}));

export default function AccountingReport() {
  // Material table ref,  Intl, useStyles, dispatch
  const tableRef = useRef(null),
    intl = useIntl(),
    classes = useStyles(),
    dispatch = useDispatch();

  // States
  const [showOfferDetail, setShowOfferDetail] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [modalRooms, setModalRooms] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect
  useEffect(() => {
    setStartData();
  }, []);

  useEffect(() => {
    if (
      reservations !== null &&
      reservations !== undefined &&
      Object.values(reservations).length > 0
    ) {
      setTableData(tableDataHandler());
    }
  }, [loading]);

  // Selectors
  const { authUser } = useSelector(({ auth }) => auth);
  const { contracts } = useSelector(({ contract }) => contract);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operators } = useSelector(({ operators }) => operators);
  const { boards } = useSelector(({ boards }) => boards);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { contract_room } = useSelector(({ contract }) => contract);
  const {
    entities: { reservations }
  } = useSelector(({ reservation }) => reservation);
  const {
    entities: { rooms }
  } = useSelector(({ reservation }) => reservation);

  const reservation_rooms = useSelector(
    state => state.reservation?.entities?.rooms
  );
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);
  // Variables
  const textFilterValues = {
    operator: null,
    operatorBookingCode: null,
    hotelName: null,
    incomingBookingCode: null
  };

  const dateFilterValues = {
    createDate: [],
    cInDate: [],
    cOutDate: [],
    ebPaymentDate: []
  };

  // Material UI Table Columns
  const tableColumns = [
    {
      title: intl.formatMessage({ id: "operator" }),
      field: "operator"
    },
    {
      title: intl.formatMessage({ id: "operator.booking.code" }),
      field: "operatorBookingCode"
    },
    {
      title: intl.formatMessage({ id: "incoming.booking.code" }),
      field: "incomingBookingCode"
    },
    {
      title: intl.formatMessage({ id: "hotel.name" }),
      field: "hotelName"
    },
    {
      title: intl.formatMessage({ id: "hotel.commercial.name" }),
      field: "hotelCommercialName"
    },
    {
      title: intl.formatMessage({ id: "booking.status" }),
      field: "bookingStatus"
    },
    {
      title: intl.formatMessage({ id: "board.type" }),
      field: "boardType"
    },
    {
      title: intl.formatMessage({ id: "room.name" }),
      field: "roomName",
      render: rowData => {
        return (
          <Button
            size="small"
            variant="contained"
            className={classes.roomButton}
            onClick={() => showRoomModalHandler(rowData.roomName.split(" "))}
          >
            <IntlMessages id="show.rooms" />
          </Button>
        );
      }
    },
    {
      title: intl.formatMessage({ id: "selling.date.range" }),
      field: "bookingDate"
    },
    {
      title: intl.formatMessage({ id: "check.in.date.range" }),
      field: "cInDate"
    },
    {
      title: intl.formatMessage({ id: "check.out.date.range" }),
      field: "cOutDate"
    },
    {
      title: intl.formatMessage({ id: "number.of.nights" }),
      field: "numberOfNights"
    },
    {
      title: intl.formatMessage({ id: "adult" }),
      field: "adult"
    },
    {
      title: intl.formatMessage({ id: "child" }),
      field: "child"
    },
    {
      title: intl.formatMessage({ id: "infant" }),
      field: "infant"
    },
    {
      title: intl.formatMessage({ id: "purchase.price" }),
      field: "purchasePrice"
    },
    {
      title: intl.formatMessage({ id: "selling.price" }),
      field: "sellingPrice"
    },
    {
      title: intl.formatMessage({ id: "currency" }),
      field: "currency"
    },
    {
      title: intl.formatMessage({ id: "offer.name" }),
      field: "offerName",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "discount.amount" }),
      field: "discountAmount",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "contract.code" }),
      field: "contractCode"
    },
    {
      title: intl.formatMessage({ id: "eb.payment.date.range" }),
      field: "ebPaymentDate",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "eb.percent" }),
      field: "ebPercent",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "hotel.eb.amount" }),
      field: "hotelEbAmount",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "opt.eb.amount" }),
      field: "optEbAmount",
      hidden: !showOfferDetail
    },
    {
      title: intl.formatMessage({ id: "eb.opt.charged.amount" }),
      field: "ebOptToBeChargedAmount",
      hidden: !showOfferDetail
    }
  ];

  // Material table download CSV
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv")
      ? fileName
      : `Reservation Report.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // State Handlers
  const showOfferHandler = e => {
    setShowOfferDetail(e.target.checked);
    if (!e.target.checked) dateFilterValues.ebPaymentDate = [];
  };
  const showRoomModalHandler = rooms => {
    setModalRooms(rooms);
    setShowRoomModal(!showRoomModal);
  };

  // Variable Handlers
  const textFilterHandler = (key, e) =>
    (textFilterValues[key] = e.target.value);
  const dateFilterHandler = (key, data) => (dateFilterValues[key] = data);
  const filterDataHandler = () => {
    let newTableData = [...tableData];

    if (textFilterValues.operator !== null) {
      newTableData = filterByText(newTableData, "operator");
    }

    if (textFilterValues.operatorBookingCode !== null) {
      newTableData = filterByText(newTableData, "operatorBookingCode");
    }

    if (textFilterValues.hotelName !== null) {
      newTableData = filterByText(newTableData, "hotelName");
    }

    if (textFilterValues.incomingBookingCode !== null) {
      newTableData = filterByText(newTableData, "incomingBookingCode");
    }

    if (dateFilterValues.createDate.length) {
      newTableData = filterByDate(
        dateFilterValues.createDate[0],
        dateFilterValues.createDate[1],
        "bookingDate",
        newTableData
      );
    }

    if (dateFilterValues.cInDate.length) {
      newTableData = filterByDate(
        dateFilterValues.cInDate[0],
        dateFilterValues.cInDate[1],
        "cInDate",
        newTableData
      );
    }

    if (dateFilterValues.cOutDate.length) {
      newTableData = filterByDate(
        dateFilterValues.cOutDate[0],
        dateFilterValues.cOutDate[1],
        "cOutDate",
        newTableData
      );
    }

    setFilteredTableData(newTableData);
  };

  // Functions
  const setStartData = async () => {
    await dispatch(
      getContractService(
        authUser.authority_level,
        localStorage.getItem("selectedCompany")
      )
    );
    await dispatch(getBoardsService());
    await dispatch(getHotelService());
    await dispatch(getOperatorService());
    await dispatch(getMarketService());
    await dispatch(getReservationsService());
    await dispatch(getCurrenciesService());
    await dispatch(getRoomService());
    setLoading(!loading);
  };

  const filterByDate = (startDate, endDate, dateColumn, data) => {
    const start = moment(startDate, "YYYY-MM-DD").valueOf();
    const end = moment(endDate, "YYYY-MM-DD").valueOf();
    const filteredData = data.filter(val => {
      const compareDate = moment(val[dateColumn], "YYYY-MM-DD").valueOf();
      return compareDate >= start && compareDate <= end;
    });

    return filteredData;
  };

  const filterByText = (data, column) => {
    return data.filter(val =>
      val[column].toLowerCase().includes(textFilterValues[column].toLowerCase())
    );
  };

  const tableDataHandler = () => {
    return Object.values(reservations).map(reservation => {
      return (({
        selling_date,
        start_date,
        end_date,
        adult_num,
        child_num,
        infant_num,
        booking_status,
        operator_voucher_no,
        voucher_no
      }) => ({
        operator: operators[reservation.operator_id]?.name,
        hotelName: hotels[reservation.operator_id]?.name,
        bookingStatus: booking_status,
        boardType: boards[reservation.board_id]?.code,
        roomName: (function() {
          let room_string = "";
          reservation.rooms.forEach(room => {
            room_string +=
              roomType[
                contract_room[reservation_rooms[room]?.contract_room_id]
                  ?.room_id
              ]?.code + " ";
          });
          return room_string;
        })(),
        bookingDate: moment(selling_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
        cInDate: moment(start_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
        cOutDate: moment(end_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
        adult: adult_num,
        child: child_num,
        infant: infant_num,
        currency:
          currencies[contracts[reservation.contract_id]?.currency]?.code,
        contractCode: contracts[reservation.contract_id]?.contract_name,
        operatorBookingCode: operator_voucher_no,
        incomingBookingCode: voucher_no,
        hotelCommercialName: "--",
        numberOfNights: moment
          .duration(
            moment(
              moment(end_date, "YYYY-MM-DD").diff(
                moment(start_date, "YYYY-MM-DD")
              )
            )
          )
          .asDays(),
        purchasePrice: "--",
        sellingPrice: "--",
        offerName: "--",
        discountAmount: "--",
        ebPaymentDate: "--",
        ebPercent: "--",
        hotelEbAmount: "--",
        optEbAmount: "--",
        ebOptToBeChargedAmount: "--"
      }))(reservation);
    });
  };

  return (
    <>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row>
                <Grid container className={classes.filterContainer}>
                  <TextFilter
                    placeholder="Operator"
                    name="operator"
                    onChangeHandler={e => textFilterHandler("operator", e)}
                  />
                  <TextFilter
                    placeholder="Operator Booking Code"
                    name="operatorBookingCode"
                    onChangeHandler={e =>
                      textFilterHandler("operatorBookingCode", e)
                    }
                  />
                  <TextFilter
                    placeholder="Hotel Name"
                    name="hotelName"
                    onChangeHandler={e => textFilterHandler("hotelName", e)}
                  />
                  <TextFilter
                    placeholder="Incoming Booking Code"
                    name="incomingBookingCode"
                    onChangeHandler={e =>
                      textFilterHandler("incomingBookingCode", e)
                    }
                  />
                </Grid>
                <Grid container className={classes.filterContainer}>
                  <DateRangeFilter
                    message="selling.date.range"
                    onChangeHandler={(_, val) =>
                      dateFilterHandler("createDate", val)
                    }
                  />
                  <DateRangeFilter
                    message="check.in.date.range"
                    onChangeHandler={(_, val) =>
                      dateFilterHandler("cInDate", val)
                    }
                  />
                  <DateRangeFilter
                    message="check.out.date.range"
                    onChangeHandler={(_, val) =>
                      dateFilterHandler("cOutDate", val)
                    }
                  />
                  {showOfferDetail && (
                    <DateRangeFilter
                      message="eb.payment.date.range"
                      onChangeHandler={(_, val) =>
                        dateFilterHandler("ebPaymentDate", val)
                      }
                    />
                  )}
                </Grid>
                <Grid container className={classes.filterContainer}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Show Offer Details"
                    onChange={showOfferHandler}
                  />
                  <Grid
                    item
                    sm={1}
                    style={{
                      textAlign: "center",
                      backgroundColor: "gray",
                      borderRadius: "4px"
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={filterDataHandler}
                      style={{ borderStyle: "none", color: "white" }}
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Row>

              <Row>
                <Grid item sm={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={tableColumns}
                    data={
                      filteredTableData !== null ? filteredTableData : tableData
                    }
                    title="Accounting Report"
                    options={{
                      columnsButton: true,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30, 40],
                      actionsColumnIndex: -1,
                      tableLayout: "auto",
                      search: false,
                      exportButton: {
                        csv: true,
                        pdf: false
                      },
                      exportCsv: (columns, data) => {
                        const filteredCols = columns.filter(col => !col.hidden);

                        const headerRow = filteredCols.map(col => col.title);

                        const rows = data.map(({ tableData, ...row }) => {
                          return filteredCols.map(col => row[col.field]);
                        });

                        const {
                          exportDelimiter
                        } = tableRef.current.props.options;
                        const delimiter = exportDelimiter
                          ? exportDelimiter
                          : ",";
                        const csvContent = [headerRow, ...rows]
                          .map(e => e.join(delimiter))
                          .join("\n");

                        const csvFileName = tableRef.current.props.title;

                        downloadCsv(csvContent, csvFileName);
                      }
                    }}
                  />
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>

      <Modal
        open={showRoomModal}
        onClose={() => showRoomModalHandler(modalRooms)}
        aria-labelledby="reservation-rooms-title"
        aria-describedby="reservation-rooms-description"
      >
        <Box className={classes.modalContent}>
          <Typography
            id="reservation-rooms-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "30px" }}
          >
            RESERVATION ROOMS
          </Typography>
          {modalRooms?.map((roomName, idx) => (
            <Typography
              key={idx}
              className="reservation-rooms-description"
              sx={{ mt: 2 }}
              style={{ marginBottom: "6px" }}
            >
              • {roomName}
            </Typography>
          ))}
        </Box>
      </Modal>
    </>
  );
}
