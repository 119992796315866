import CancellationPolicies from '@jumbo/components/Contract/CancellationPoliciesDetail';
import { normalize, schema } from 'normalizr';
//const cancellationPoliciesDetail = new schema.Entity('cancellation_policies_detail');

const cancellation_policy_detail = new schema.Entity(
  'cancellation_policies_detail',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const cancellation_policy = new schema.Entity(
  'cancellation_policy',
  {
    cancellation_policies_detail: [cancellation_policy_detail],
  },
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA };
    },
  },
);

const response = {
  cancellation_policy: [cancellation_policy],
  state_cancellation_policies: [cancellation_policy],
  add_cancellation_policies: [cancellation_policy],
  updated_cancellation_policies: [cancellation_policy],
  deleted_cancellation_policies: [cancellation_policy],

  stateCanPolDetail: [cancellation_policy_detail],
  addCanPolDetail: [cancellation_policy_detail],
  updateCanPolDetail: [cancellation_policy_detail],
  deletedCanPolDetail: [cancellation_policy_detail],
};

export const normalizeTasks = data => normalize(data, [response]);
