import {
  GET_PERIOD,
  ADD_PERIOD,
  UPDATE_PERIOD,
  DELETE_PERIOD,
  PERIOD_ROW_STATUS
} from "./types";

export const getPeriod = res => dispatch => {
  dispatch({
    type: GET_PERIOD,
    payload: res.data.data
  });
};

export const addPeriod = res => dispatch =>
  dispatch({
    type: ADD_PERIOD,
    payload: res.data.data
  });

export const updatePeriod = res => dispatch =>
  dispatch({
    type: UPDATE_PERIOD,
    payload: res.data.data
  });

export const deletePeriod = res => dispatch =>
  dispatch({
    type: DELETE_PERIOD,
    payload: res.data.data
  });

export const periodRowStatus = res => dispatch => {
  dispatch({
    type: PERIOD_ROW_STATUS,
    payload: res
  });
};
