import {
  GET_OFFER_LIST,
  DELETE_OFFER_LIST,
  CLEAR_OFFER_LIST,
} from "../actions/types";

const initialState = {
  offerList: {
    SH: [],
    DN: [],
    WB: [],
    LMX: [],
    FORYOU: [],
    SIT: [],
    PP: [],
    WEBBEDS: [],
    JUMBO: [],
    LMT: [],
    XPUR: [],
    IVC: [],
    W2M: [],
    PT: [],
  },
};

const assignUId = (data, oprName) => {
  return data.map((value) => {
    const uid = (Math.random() + 1).toString(36).substring(7) + oprName;
    return { ...value, uid: uid };
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_LIST:
      return {
        ...state,
        offerList: {
          DN: action.payload.DN
            ? assignUId(action.payload.DN, "DN")
            : state.offerList.DN,
          SH: action.payload.SH
            ? assignUId(action.payload.SH, "SH")
            : state.offerList.SH,
          WB: action.payload.WB
            ? assignUId(action.payload.WB, "WB")
            : state.offerList.WB,
          PP: action.payload.PP
            ? assignUId(action.payload.PP, " PP")
            : state.offerList.PP,
          LMX: action.payload.LMX
            ? assignUId(action.payload.LMX, "LMX")
            : state.offerList.LMX,
          FORYOU: action.payload.FORYOU
            ? assignUId(action.payload.FORYOU, "FORYOU")
            : state.offerList.FORYOU,
          SIT: action.payload.SIT
            ? assignUId(action.payload.SIT, "SIT")
            : state.offerList.SIT,
          WEBBEDS: action.payload.WEBBEDS
            ? assignUId(action.payload.WEBBEDS, "WEBBEDS")
            : state.offerList.WEBBEDS,
          JUMBO: action.payload.JUMBO
            ? assignUId(action.payload.JUMBO, "JUMBO")
            : state.offerList.JUMBO,
          LMT: action.payload.LMT
            ? assignUId(action.payload.LMT, "LMT")
            : state.offerList.LMT,
          XPUR: action.payload.XPUR
            ? assignUId(action.payload.XPUR, "XPUR")
            : state.offerList.XPUR,
          IVC: action.payload.IVC
            ? assignUId(action.payload.IVC, "IVC")
            : state.offerList.IVC,
          W2M: action.payload.W2M
            ? assignUId(action.payload.W2M, "W2M")
            : state.offerList.W2M,
          PT: action.payload.PT
          ? assignUId(action.payload.PT, "PT")
          : state.offerList.PT,
        },
      };

    case DELETE_OFFER_LIST:
      let newState = [];
      let deletedUids = [];
      let opr = "";
      let payloadOperator = action.payload["hotel_offers"][0]["operator"];
      opr =
        payloadOperator == "WEBBEDS" || payloadOperator == "JUMBO"
          ? action.payload["hotel_offers"][0]["operator"]
          : payloadOperator == "SITALIA"
          ? "SIT"
          : payloadOperator == "WORLD 2 MEET"
          ? "W2M"
          : payloadOperator == "PRIME TRAVEL"
          ? "PT"
          : payloadOperator == "FORYOU" ||
            payloadOperator == "LMX" ||
            payloadOperator == "LMT" ||
            payloadOperator == "XPUR" ||
            payloadOperator == "IVC"
          ? payloadOperator
          : action.payload["hotel_offers"][0]["uid"].slice(-2);
      action.payload["hotel_offers"].map((pay) => {
        deletedUids.push(pay["uid"]);
      });
      state.offerList[opr].filter((s) => {
        if (!deletedUids.includes(s["uid"])) {
          newState.push(s);
        }
      });
      return {
        ...state,
        offerList: { ...state.offerList, [opr]: newState },
      };
    case CLEAR_OFFER_LIST:
      return {
        ...state,
        offerList: {
          SH: [],
          DN: [],
          WB: [],
          LMX: [],
          FORYOU: [],
          SIT: [],
          PP: [],
          WEBBEDS: [],
          LMT: [],
          XPUR: [],
          JUMBO: [],
          IVC: [],
          W2M: [],
          PT: [],
        },
      };

    default:
      return state;
  }
};
