import {
  GET_EXTRA,
  ADD_EXTRA,
  UPDATE_EXTRA,
  DELETE_EXTRA,
  GET_ALLEXTRA
} from "../actions/types";

const initialState = {
  extras: [],
  allExtras: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTRA:
      return { ...state, extras: action.payload };
    case GET_ALLEXTRA:
      return { ...state, allExtras: action.payload };
    case ADD_EXTRA:
      return {
        ...state,
        extras: [...state.extras, action.payload]
      };
    case UPDATE_EXTRA:
      const index = state.extras.findIndex(
        extra => extra.id === action.payload.id
      );
      const newExtra = [...state.extras];
      newExtra[index] = action.payload;
      return {
        ...state,
        extras: newExtra
      };
    case DELETE_EXTRA:
      return {
        ...state,
        extras: state.extras.filter(
          extra => extra.id !== Number(action.payload)
        )
      };
    default:
      return state;
  }
};
