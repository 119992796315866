// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Core Imports
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core";

// ** MUI DataGrid Import
import { DataGrid } from "@mui/x-data-grid";

// ** Redux
import { useDispatch, useSelector } from "react-redux";

// ** Services
import { getHotelService } from "services/hotels";

// ** AxiosLogger
import axiosLogger from "services/axiosLogger";

// ** Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// ** Third Party Libraries
import moment from "moment";

// ** Component Imports
import TransactionFilter from "./TransactionFilter";

// ** Prices
const products = [
  { id: 1, name: "Stop Sale", price: 0.05 },
  { id: 2, name: "Open Sale", price: 0.05 },
  { id: 3, name: "Offer", price: 0.05 },
  { id: 4, name: "Offer Delete", price: 0.05 },
  { id: 5, name: "Contract On Sale", price: 1 }
];

// ** Post URl
const url = "proxolab/api/v1/botclient/getLogData";

const TransactionInformation = () => {
  // ** States
  const [invoice, setInvoice] = useState(0);
  const [logData, setLogData] = useState(undefined);
  const [filteredLogData, setFilteredLogData] = useState(undefined);

  // ** Dispatch
  const dispatch = useDispatch();

  // ** Selectors
  const { authUser } = useSelector(state => state.auth);
  const { hotels } = useSelector(state => state.hotels);

  /** DESC
   * DataGrid columns
   * Columns are set here because hotel column requires redux data
   */
  const columns = [
    {
      field: "operationType",
      headerName: "Operation Type",
      maxWidth: 200,
      flex: 1,
      valueGetter: params => {
        if (params.row.data?.log_type) {
          return params.row.data.log_type;
        }

        return "-----";
      }
    },
    {
      field: "createdAt",
      type: "date",
      headerName: "Operation Date",
      flex: 1,
      valueGetter: params => {
        if (params.row.createdAt) {
          return moment(params.row.createdAt).format("DD/MM/YYYY hh:mm:ss a");
        }

        return "-----";
      }
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: params => {
        return params.row.price + " $";
      },
      flex: 1
    },
    {
      field: "hotel",
      headerName: "Hotel",
      valueGetter: params => {
        const hotelId = params.row.data.hotelId;

        // Check if there is any hotel data and normalized
        if (typeof hotels === "object") {
          if (Reflect.has(hotels, hotelId)) {
            return hotels[hotelId].name;
          }
        }

        return "-----";
      },
      flex: 1
    }
  ];

  useEffect(() => {
    getLogData();
    dispatch(getHotelService());
  }, []);

  //DESC: Get log data
  const getLogData = async () => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
    await axiosLogger
      .post(url, {
        email: authUser.email,
        filters: {}
      })
      .then(res => {
        setInvoice(res.data.totalPrice);
        setLogData(res.data.logData);
        setFilteredLogData(res.data.logData);
      });
  };

  //DESC: Handle Filter Submit
  const filterHandler = async data => {
    await axiosLogger
      .post(url, {
        email: authUser.email,
        filters: data
      })
      .then(res => {
        setFilteredLogData(res.data.logData);
      });
  };

  // ** Renderers
  const renderProducts = products.map(product => (
    <Grid item key={product.id}>
      <Card>
        <CardContent>
          {product.name}: {product.price} $
        </CardContent>
      </Card>
    </Grid>
  ));

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h1">Bank Information</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={5}>
                  <Grid item>
                    <Typography variant="h1">
                      Total Operation: {logData ? logData.length : 0}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1">
                      Invoice: {invoice ? invoice : 0} $
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Operation Prices</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="center" spacing={2}>
              {renderProducts}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <form autoComplete="off">
          <TransactionFilter hotels={hotels} filterHandler={filterHandler} />
        </form>
      </Grid>
      {logData && (
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <DataGrid
            position="fixed"
            rows={filteredLogData ? filteredLogData : logData}
            columns={columns}
            getRowId={row => row._id}
            // className={classes.table}
            pageSize={10}
            autoHeight
            // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector

            // componentsProps={{
            //   pagination: {
            //     labelRowsPerPage: CustomFooterStatusComponent()
            //   }
            // }}
            // initialState={{
            //   sorting: {
            //     sortModel: [{ field: "createdAt", sort: "desc" }]
            //   }
            // }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionInformation;
