import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getOfferList } from "redux/actions/offerList";
import axios from "./axios";
import axiosBot from "./axiosBot";
import { apihost } from "./axiosBot";

/* let bothost = "http://127.0.0.1:8000/";
let apihost = "http://localhost:82"; */

export const sendOfferListServiceSH = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/sunhotels/", data)
    .then(function(res) {
      res.data["SH"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServiceWEBBEDS = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/webbeds/", data)
    .then(function(res) {
      res.data["WEBBEDS"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServiceJUMBO = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/jumbo/", data)
    .then(function(res) {
      res.data["JUMBO"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServicePP = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/peakpoint/", data)
    .then(function(res) {
      res.data["PP"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServiceW2M = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/world2meet/", data)
    .then(function(res) {
      res.data["W2M"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServicePT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/primetravel/", data)
    .then(function(res) {
      res.data["PT"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceDN = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/dnata/", data)
    .then(function(res) {
      res.data["DN"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServiceWB = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/welcomebeds/", data)
    .then(function(res) {
      res.data["WB"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const sendOfferListServiceLMX = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/lmx/", data)
    .then(function(res) {
      res.data["LMX"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceXPUR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/xpur/", data)
    .then(function(res) {
      res.data["XPUR"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceFOR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/foryou/", data)
    .then(function(res) {
      res.data["FORYOU"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceSIT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/sitalia/", data)
    .then(function(res) {
      res.data["SIT"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceLMT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/lastminute/", data)
    .then(function(res) {
      res.data["LMT"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const sendOfferListServiceIVC = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("offerlist/ivector/", data)
    .then(function(res) {
      res.data["IVC"] = res.data.hotel_offers;
      dispatch(getOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
