import React, { useState, Suspense, useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable, { MTableEditRow } from "material-table";
import OfferAmountCalculate from "./Kickback/OfferAmountCalculate";
import { TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  controlContractRoominUseService,
  deleteContractRoomService,
  updateContractRoomService,
  controlContractRoomRateType,
  contractRoomBoardControl,
} from "services/contract";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import withReactContent from "sweetalert2-react-content";
import Rooms from "./Rooms";
import { Autocomplete } from "react-select-material-ui/node_modules/@mui/material";
import {
  board_selector,
  contract,
  contractHasBoard,
  contractRoom,
  contractRoomPrice,
  selected_contract,
} from "redux/selector/contract";
import { updateMarketingPromotionAmountService } from "services/kickback/marketingPromotion";
import { additionalPaxRates } from "redux/selector/additionalPax";
import { boardSupplament } from "redux/selector/boardSupplaments";
import { Extras } from "redux/selector/extras";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { marketingPromotions } from "redux/selector/marketingPromotion";
import { contractCancellationPolicy } from "redux/selector/cancellationPoliciesDetail";
import { period } from "redux/selector/periods";
import { room } from "redux/selector/rooms";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import IsInfiniteLoop from "./IsInfiniteLoop";
import { PriceFormatCustom } from "../Common/NumberFormat/NumberFormat";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import { orange } from "@material-ui/core/colors";
import Loader from "./Loader";
import { getBoardSupplamentService } from "services/boardSupplament";
import { getExtraService } from "services/extras";
import { getMarketingPromotionService } from "services/kickback/marketingPromotion";

import moment from "moment";

// const theme = createTheme({
//   palette: {
//     backgroundColor: orange[400],
//   },
// });

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};
const useStyles = makeStyles((theme) => ({
  addRoomButton: {
    backgroundColor: "cadetblue",
    fontSize: 10,
  },
  numberColumns: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  baseBoard: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  autocomplete: {
    minWidth: "60px",
  },
}));

export default function ContractRoom(props) {
  const { isAuthAdd, isAuthUpdate, isAuthDelete } = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const contracts = useSelector(contract);
  const boards = useSelector(board_selector);
  const { marketingPromotion } = useSelector(({ kickbacks }) => kickbacks);
  const contract_has_board = useSelector(contractHasBoard);
  const contract_room_price = useSelector(contractRoomPrice);
  const selectedContract = useSelector(selected_contract);
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  const { boardSupplaments } = useSelector(
    ({ boardSupplament }) => boardSupplament
  );
  const { offers } = useSelector(({ offers }) => offers);
  const { early_booking_offers } = useSelector(({ offers }) => offers);
  const { special_discount_offers } = useSelector(({ offers }) => offers);
  const currencies = useSelector(({ currencies }) => currencies.currencies);
  const contractCurrency = contracts[selectedContract].currency;
  const currencySymbol = currencies[contractCurrency].symbol;
  const { contract_room } = useSelector(({ contract }) => contract);
  // const contract_room = useSelector(contractRoom);
  const periods = useSelector(period);
  let contractRooms = {};
  const rooms = useSelector(room);
  const [childControl, setChildControl] = useState(
    !contracts[selectedContract]?.checkChild
  );
  let funcData = {
    contract_room: contract_room,
    contract_room_price: contract_room_price,
    boardSupplaments: boardSupplaments,
    offers: offers,
    early_booking_offers: early_booking_offers,
    special_discount_offers: special_discount_offers,
    currencySymbol: currencySymbol,
  };
  const [flag, setFlag] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const contractStatusControl =
    contracts[selectedContract].contract_statuses_id == 1 ||
    contracts[selectedContract].contract_statuses_id == 5
      ? true
      : false;
  const handleOpen = () => {
    setFlag(true);
  };
  const handleClose = () => {
    setFlag(false);
  };

  const sameCodeConroller = () => {
    let newPeriods = [];
    Object.values(periods).forEach((period) => {
      if (newPeriods.filter((value) => value.code == period.code).length == 0) {
        let sameCodesPeriods = [];
        sameCodesPeriods = Object.values(periods).filter(
          (item) => item.code === period.code
        );
        let newPeriod = { ...period };
        let newDateString = "";
        sameCodesPeriods.forEach((val) => {
          newDateString =
            newDateString + "," + val.start_date + "," + val.end_date;
        });
        newPeriod.newDateString = newDateString;
        newPeriods.push(newPeriod);
      }
    });
    return newPeriods;
  };

  const sameCodeConroller2 = () => {
    let newPeriods = [];
    Object.values(periods).forEach((period) => {
      let sameCodesPeriods = [];
      sameCodesPeriods = Object.values(periods).filter(
        (item) => item.code === period.code
      );
      if (sameCodesPeriods.length > 1 && !newPeriods.includes(period.id)) {
        sameCodesPeriods.map((per) => newPeriods.push(per.id));
      }
    });
    return newPeriods;
  };

  const tableRef = useRef();

  useEffect(() => {
    let mat = tableRef.current.props.data;
    mat.map((val) => {
      if (val.tableData.hasOwnProperty("editing")) {
        val.tableData.editing = undefined;
      }
    });
    dispatch(getMarketingPromotionService(selectedContract));
  }, []);

  let rateLookup = {};
  let boardLookup = {};
  let boardOptions = [];
  let data = [];
  let allotmentLookup = {};
  let allotmentOptions = [];
  Object.values(contracts[selectedContract].rate_types).map((rate) => {
    rateLookup[rate] = rateTypes[rate].name;
  });
  contracts[selectedContract].contract_has_board.map((chb) => {
    boardLookup[boards[contract_has_board[chb]?.board_id]?.id] =
      boards[contract_has_board[chb]?.board_id]?.code;
    boardOptions.push({
      value: boards[contract_has_board[chb]?.board_id]?.id,
      code: boards[contract_has_board[chb]?.board_id]?.code,
    });
  });

  //allotmentOptions[0] = { id: 0, no: "--" };
  contracts[selectedContract].contract_room.map((cr) => {
    allotmentOptions.push({
      id: contract_room[cr]?.contract_room_no,
      no: contract_room[cr]?.contract_room_no,
    });
    allotmentLookup[cr] = contract_room[cr]?.contract_room_no;
    data.push(contract_room[cr]);
  });
  // Object.values(allotmentLookup).filter(item => item.contract_room_no !== editRowID);

  contractRooms = data;

  const operations = [
    { id: 1, name: "add", sembol: "+" },
    { id: 2, name: "subtract", sembol: "-" },
    { id: 3, name: "divide", sembol: "÷" },
    { id: 4, name: "multiply", sembol: "x" },
  ];
  const contractCheckInfant = contracts[selectedContract].checkInf;
  const columns = [
    {
      title: "Room Id",
      field: "contract_room_no",
      editable: "never",
      render: (rowData) => {
        return rowData.contract_room_no
          ? rowData.contract_room_no
          : rowData.tableData.id + 1;
      },
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
    },
    {
      title: "Room Type",
      field: "room_id",
      render: (rowData) => {
        return rowData.room_id /* .room?.type_id  */
          ? rooms.types[roomType[rowData.room_id]?.type_id]?.name
          : "";
      },
      editable: "never",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
    },
    {
      title: "Room Code",
      render: (rowData) => {
        return rowData.room_id /* .room?.type_id  */
          ? roomType[rowData.room_id]?.code
          : "";
        // return rowData.room?.type_id ? rowData.room.code : '';
      },
      editable: "never",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
    },
    {
      title: "Agency Room Name",
      field: "agency_room_name",
      editable: "never",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
        height: 10,
      },
      align: "center",
    },
    {
      title: "Product Room Name",
      field: "product_room_name",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
      align: "center",
    },
    {
      title: "Allotment ID",
      field: "allotment_reference_id",
      lookup: allotmentLookup,
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!parseInt(rowData.allotment_reference_id),
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
    },
    {
      title: "No of Allotment",
      field: "allotment",
      type: "numeric",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!(parseInt(rowData.allotment) >= 0),
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <TextField
          error={parseInt(props.rowData.allotment) >= 0 ? false : true}
          type="number"
          onChange={(e) => {
            props.onChange(e.target.value >= 1000 ? 999 : e.target.value);
          }}
          value={props.value >= 1000 ? 999 : props.value}
          className={classes.numberColumns}
          variant="standard"
          InputProps={{ inputProps: { min: 0 } }}
        />
      ),
    },
    {
      title: "Min Payer",
      field: "min_payer",
      type: "numeric",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
        width: 50,
        minWidth: 50,
      },
      align: "center",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!parseInt(rowData.min_payer),
      editComponent: (props) => (
        <Tooltip
          title={
            parseInt(props.rowData.min_payer) > 0
              ? ""
              : "min payer 0dan büyük olmalı"
          }
        >
          <TextField
            error={parseInt(props.rowData.min_payer) ? false : true}
            type="number"
            variant="standard"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            className={classes.numberColumns}
            value={props.value >= 100 ? 99 : props.value}
            InputProps={{ inputProps: { min: 0, max: 99 } }}
          />
        </Tooltip>
      ),
    },
    //LINK - https://proxolab.atlassian.net/browse/MBOT-1451 buradaki dökümantasyona göre yaptım
    {
      title: "Min Pax",
      field: "min_pax",
      type: "numeric",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!(parseInt(rowData.min_pax) >= 0),
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <TextField
          error={parseInt(props.rowData.min_pax) > 0 ? false : true}
          type="number"
          onChange={(e) => {
            props.onChange(e.target.value >= 100 ? 99 : e.target.value);
          }}
          value={props.value >= 100 ? 99 : props.value}
          className={classes.numberColumns}
          variant="standard"
          InputProps={{ inputProps: { min: 0, max: 99 } }}
        />
      ),
    },
    {
      title: "Max Pax",
      field: "max_pax",
      type: "numeric",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!(
              parseInt(rowData.max_pax) >= 0 &&
              parseInt(rowData.max_pax) >= parseInt(rowData.min_pax) &&
              parseInt(rowData.min_adult) + parseInt(rowData.max_child) <=
                parseInt(rowData.max_pax) &&
              !!(parseInt(rowData.max_child) + parseInt(rowData.max_adult))
            ),
      editComponent: (props) => (
        <Tooltip
          title={
            props.rowData.max_pax < props.rowData.min_pax
              ? "min paxdan büyük olmalı"
              : ""
          }
        >
          <TextField
            error={
              parseInt(props.rowData.max_pax) >= 0 &&
              parseInt(props.rowData.max_pax) >=
                parseInt(props.rowData.min_pax) &&
              (contractCheckInfant
                ? parseInt(props.rowData.min_adult) +
                    parseInt(props.rowData.min_child) +
                    parseInt(props.rowData.max_infant) <=
                  parseInt(props.rowData.max_pax)
                : parseInt(props.rowData.min_adult) +
                    parseInt(props.rowData.min_child) <=
                  parseInt(props.rowData.max_pax))
                ? false
                : true
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0, max: 99 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Min Adt",
      field: "min_adult",
      type: "numeric",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!(
              parseInt(rowData.min_adult) > 0 &&
              parseInt(rowData.min_adult) <= parseInt(rowData.max_adult) &&
              parseInt(rowData.min_adult) <= parseInt(rowData.max_pax)
            ),
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <Tooltip
          title={
            parseInt(props.rowData.min_adult) >
              parseInt(props.rowData.max_adult) ||
            parseInt(props.rowData.min_adult) > parseInt(props.rowData.max_pax)
              ? "Min adult değerini kontrol ediniz"
              : ""
          }
        >
          <TextField
            error={
              parseInt(props.rowData.min_adult) > 0 &&
              parseInt(props.rowData.min_adult) <=
                parseInt(props.rowData.max_adult) &&
              parseInt(props.rowData.min_adult) <=
                parseInt(props.rowData.max_pax)
                ? false
                : true
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0, max: 99 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Max Adt",
      field: "max_adult",
      type: "numeric",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete"
          ? true
          : !!(
              parseInt(rowData.max_adult) >= 0 &&
              parseInt(rowData.max_adult) >= parseInt(rowData.min_adult) &&
              parseInt(rowData.max_adult) <= parseInt(rowData.max_pax)
            ),
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <Tooltip
          title={
            props.rowData.max_adult < props.rowData.min_adult ||
            props.rowData.max_adult > props.rowData.max_pax
              ? "Max adult değerini kontrol ediniz"
              : ""
          }
        >
          <TextField
            error={
              parseInt(props.rowData.max_adult) > 0 &&
              parseInt(props.rowData.max_adult) >=
                parseInt(props.rowData.min_adult) &&
              parseInt(props.rowData.max_adult) <=
                parseInt(props.rowData.max_pax)
                ? false
                : true
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0, max: 99 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Min Chd",
      field: "min_child",
      type: "numeric",
      validate: (rowData) =>
        childControl == true
          ? rowData.tableData?.editing == "delete"
            ? true
            : !!(
                parseInt(rowData.min_child) >= 0 &&
                parseInt(rowData.min_child) >=
                  parseInt(rowData.min_pax) - parseInt(rowData.min_adult) &&
                parseInt(rowData.min_adult) + parseInt(rowData.min_child) <=
                  parseInt(rowData.max_pax)
              )
          : true,
      editable: childControl == false ? "never" : "always",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <Tooltip
          title={
            parseInt(props.rowData.min_child) >
              parseInt(props.rowData.max_child) ||
            parseInt(props.rowData.min_child) +
              parseInt(props.rowData.min_adult) >
              parseInt(props.rowData.max_pax)
              ? "Min Child değerini kontrol ediniz!"
              : ""
          }
        >
          <TextField
            error={
              childControl
                ? parseInt(props.rowData.min_child) >= 0 &&
                  parseInt(props.rowData.max_child) >=
                    parseInt(props.rowData.min_child) &&
                  parseInt(props.rowData.min_child) +
                    parseInt(props.rowData.min_adult) <=
                    parseInt(props.rowData.max_pax)
                  ? false
                  : true
                : false
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0, max: 99 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Max Chd",
      field: "max_child",
      type: "numeric",
      validate: (rowData) =>
        childControl == true
          ? rowData.tableData?.editing == "delete"
            ? true
            : !!(parseInt(rowData.max_child) >= 0) &&
              parseInt(rowData.max_child) >= parseInt(rowData.min_child) &&
              parseInt(rowData.min_adult) + parseInt(rowData.max_child) <=
                parseInt(rowData.max_pax)
          : true,
      editable: childControl == false ? "never" : "always",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <Tooltip
          title={
            parseInt(props.rowData.max_child) <
              parseInt(props.rowData.min_child) ||
            parseInt(props.rowData.max_child) +
              parseInt(props.rowData.min_adult) >
              parseInt(props.rowData.max_pax)
              ? "Max Child değerini kontrol ediniz!"
              : ""
          }
        >
          <TextField
            error={
              childControl
                ? parseInt(props.rowData.max_child) >= 0 &&
                  parseInt(props.rowData.max_child) >=
                    parseInt(props.rowData.min_child) &&
                  (contractCheckInfant
                    ? parseInt(props.rowData.max_infant) +
                        parseInt(props.rowData.min_child) +
                        parseInt(props.rowData.min_adult) <=
                      parseInt(props.rowData.max_pax)
                    : parseInt(props.rowData.max_child) +
                        parseInt(props.rowData.min_adult) <=
                      parseInt(props.rowData.max_pax))
                  ? false
                  : true
                : false
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Max Infant",
      field: "max_infant",
      type: "numeric",
      validate: (rowData) =>
        childControl == true
          ? rowData.tableData?.editing == "delete"
            ? true
            : !!(parseInt(rowData.max_infant) >= 0) &&
              (contractCheckInfant
                ? parseInt(rowData.min_adult) +
                    parseInt(rowData.min_child) +
                    parseInt(rowData.max_infant) <=
                  parseInt(rowData.max_pax)
                : true)
          : true,
      editable: childControl == false ? "never" : "always",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      align: "center",
      editComponent: (props) => (
        <Tooltip
          title={
            contractCheckInfant &&
            parseInt(props.rowData.mix_child) +
              parseInt(props.rowData.max_infant) +
              parseInt(props.rowData.min_adult) >
              parseInt(props.rowData.max_pax)
              ? "Max Child değerini kontrol ediniz!"
              : ""
          }
        >
          <TextField
            error={
              childControl
                ? parseInt(props.rowData.max_infant) >= 0 &&
                  (contractCheckInfant
                    ? parseInt(props.rowData.min_adult) +
                        parseInt(props.rowData.min_child) +
                        parseInt(props.rowData.max_infant) <=
                      parseInt(props.rowData.max_pax)
                    : true)
                  ? false
                  : true
                : false
            }
            type="number"
            onChange={(e) => {
              props.onChange(e.target.value >= 100 ? 99 : e.target.value);
            }}
            value={props.value >= 100 ? 99 : props.value}
            className={classes.numberColumns}
            variant="standard"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Rate Type",
      lookup: rateLookup,
      field: "rate_id",
      render: (rowData) => rateTypes[rowData.rate_id]?.code,
      validate: (rowData) =>
        rowData.tableData?.editing == "delete" ? true : !!rowData.rate_id,
      align: "center",
      type: "numeric",
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
        width: 110,
        minWidth: 110,
      },
      headerStyle: {
        width: 110,
        minWidth: 110,
      },
    },
    {
      title: "Base Board Type",
      field: "board_id",
      validate: (rowData) =>
        rowData.tableData?.editing == "delete" ? true : !!rowData.board_id,
      lookup: boards,
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      render: (rowData) => {
        return rowData.board_id ? boards[rowData.board_id]?.code : "";
      },
      align: "center",
      editComponent: (props) => {
        props.rowData.board_id =
          boardOptions.length == 1
            ? boardOptions[0].value
            : props.rowData.board_id;

        return (
          <Autocomplete
            freeSolo
            options={boardOptions}
            onChange={(event, value) => {
              props.onChange(value?.value);
            }}
            classes={{ inputRoot: classes.autocomplete }}
            value={boards[props.rowData.board_id]}
            getOptionLabel={(option) => option?.code}
            renderInput={(params) => (
              <TextField
                error={props.rowData.board_id ? false : true}
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
    {
      title: "Ref Rate Type",
      field: "reference_rate_id",
      // lookup: allotmentLookup,
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
      },
      render: (rowData) => {
        return rowData.reference_rate_id > 0
          ? rowData.reference_rate_id
          : rowData.reference_rate_id != null
          ? allotmentLookup[rowData.id]
          : "";
      },
      align: "center",
      editComponent: (props) => {
        return (
          <Autocomplete
            options={allotmentOptions}
            onChange={(event, value) => {
              props.onChange(value?.id);
            }}
            defaultValue={allotmentLookup[props.rowData.id]}
            value={
              props.value ? props.value : allotmentLookup[props.rowData.id]
            }
            getOptionLabel={(option) => option?.no ?? option}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
    },
  ];
  const ControlContent = (data) => {
    let errorString = "";
    data.forEach((error) => {
      errorString = errorString + "-" + error + "<br></br>";
    });
    return errorString;
  };

  const controlRoomPriceInUse = (id) => {
    //selected contracts and without own contractroom filter
    let tempRooms = Object.values(contract_room).filter(
      (room) =>
        room.contract_room_no != id && room.contract_id == selectedContract
    );
    return tempRooms.every((room) => room.reference_rate_id !== id);
  };

  function roundPrice(value, exp) {
    if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) return NaN;

    // Shift
    value = value.toString().split("e");
    value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + exp : exp)));

    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
  }
  let isTherePrice = false;
  function priceCalculate(value, period_id, props) {
    isTherePrice = false;
    let price1 = 0;
    let price2 = Number(value);
    let price3 = 0;
    if (props.rowData.reference_rate_id !== null) {
      let ref_room_ids = Object.values(contract_room).filter(
        (item) =>
          item.contract_room_no === Number(props.rowData.reference_rate_id) &&
          item.contract_id === Number(props.rowData.contract_id)
      )[0]?.contract_room_price;
      ref_room_ids.map((ref_room_id) => {
        if (contract_room_price[ref_room_id]?.period_id === period_id) {
          price1 = contract_room_price[ref_room_id]?.price;
        }
      });
      //Referans alınan odanın bu periyodunda fiyat yok
      /* eğer referans alınan odanın periyoduna fiyat girilmemişse price1 null değerini taşıyor bu durumda isthereprice i true
      yapılıyor ki onrowupdate içerisinde kontrol sağlanabilsin */
      if (price1 === null) {
        isTherePrice = true;
        sweetAlerts(
          "error",
          intl.formatMessage({ id: "the.period.price.not.found.in.ref.room" })
        );
      }
      let whichOperation =
        operations[
          operations.findIndex(
            (item) => item.id === props.rowData["operation" + period_id]
          )
        ]?.name;
      switch (whichOperation) {
        case "add":
          price3 = price1 + price2;
          break;
        case "subtract":
          price3 = price1 - price2;
          break;
        case "multiply":
          price3 = price1 * price2;
          break;
        case "divide":
          price3 = price1 / price2;
          break;
        default:
          break;
      }
    }
    price3 = roundPrice(price3, 2);
    return price3;
  }
  let firstPeriod = Object.values(periods).filter(
    (i) => i.contract_id == selectedContract
  )[0].id;
  let lastPeriod = Object.keys(periods).length;

  sameCodeConroller().map((per, key) => {
    columns.push({
      title: "Price",
      type: "numeric",
      align: "center",
      field: `${per.id}`,
      cellStyle: {
        borderRight: "1px solid #A9A9A9",
        width: 130,
        minWidth: 130,
      },
      headerStyle: {
        width: 130,
        minWidth: 130,
      },
      validate: (rowData) => {
        let selctedcontractRoomArray = {
          operation_value: "",
          operation_id: "",
          price: "",
        };
        let temp_selctedcontractRoomArray = Object.values(
          contract_room_price
        ).find(
          (crp) => crp.period_id == per.id && crp.contract_room_id == rowData.id
        );
        if (
          temp_selctedcontractRoomArray !== null &&
          temp_selctedcontractRoomArray !== undefined
        ) {
          selctedcontractRoomArray = temp_selctedcontractRoomArray;
        }
        rowData[per.id] =
          rowData[per.id] !== undefined
            ? rowData[per.id]
            : selctedcontractRoomArray?.price;
        //rowdata içerisinde operation+per.id ve operation_value+per.id veri olmadığından burada atama yapılıyor
        rowData["operation" + per.id] =
          rowData["operation" + per.id] !== undefined
            ? rowData["operation" + per.id]
            : selctedcontractRoomArray["operation_id"];

        rowData["operation_value" + per.id] =
          rowData["operation_value" + per.id] !== undefined
            ? rowData["operation_value" + per.id]
            : selctedcontractRoomArray["operation_value"];
        //silme modunda validate true yapmak için
        if (rowData.tableData?.editing == "delete") {
          return true;
        } else {
          if (
            Number(rowData.reference_rate_id) > 0 &&
            Number(rowData.reference_rate_id) !==
              Number(rowData.contract_room_no)
          ) {
            if (
              rowData["operation" + per.id] > 0 &&
              rowData["operation_value" + per.id] > 0
            ) {
              return true;
            } else {
              return false;
            }
          } else if (
            !Number(rowData.reference_rate_id) > 0 ||
            Number(rowData.reference_rate_id) ===
              Number(rowData.contract_room_no)
          ) {
            if (rowData[per.id] && rowData[per.id] > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      },
      render: (rowData) => {
        let renderPrice = Object.values(contract_room_price).find(
          (crp) => crp.period_id == per.id && crp.contract_room_id == rowData.id
        )?.price;
        return renderPrice
          ? parseFloat(renderPrice).toFixed(2)
          : "" /* [rowData.contract_room_price[key]]?.price */;
      },
      editComponent: (props) => {
        //sameCode Period Varsa
        let sameCodePeriod = [];
        /* periyod idsi ve contract_room_id si eşleşen bir veri(temp_selctedcontractRoomArray) çıkmazsa
         selctedcontractRoomArray in kullanıldığı yerlere null gitmemesi için default değerler oluşturuluyor*/
        let selctedcontractRoomArray = {
          operation_value: "",
          operation_id: "",
          price: "",
        };
        let temp_selctedcontractRoomArray = Object.values(
          contract_room_price
        ).find(
          (crp) =>
            crp.period_id == per.id && crp.contract_room_id == props.rowData.id
        );
        if (
          temp_selctedcontractRoomArray !== null &&
          temp_selctedcontractRoomArray !== undefined
        ) {
          selctedcontractRoomArray = temp_selctedcontractRoomArray;
        }
        let keyOrId = 0;
        if (per.newDateString.split(",").length > 3) {
          sameCodePeriod = Object.values(periods).filter(
            (item) => item.code === per.code
          );
          sameCodePeriod.forEach(
            (scp) =>
              (props.rowData[scp.id] = props.rowData[sameCodePeriod[0].id])
          );
        } /* else {
          keyOrId = Object.values(contract_room_price).filter(
            crp => per.id == crp.period_id && crp.contract_room_id == props.rowData.id,
          )[0].id;
        } */
        props.rowData[per.id] =
          props.value !== undefined
            ? props.value
            : selctedcontractRoomArray?.price;
        //rowdata içerisinde operation+per.id ve operation_value+per.id veri olmadığından burada atama yapılıyor
        props.rowData["operation" + per.id] =
          props.rowData["operation" + per.id] !== undefined
            ? props.rowData["operation" + per.id]
            : selctedcontractRoomArray["operation_id"];

        props.rowData["operation_value" + per.id] =
          props.rowData["operation_value" + per.id] !== undefined
            ? props.rowData["operation_value" + per.id]
            : selctedcontractRoomArray["operation_value"];
        // props.rowData[per.id] = props.value;
        if (
          props.rowData.hasOwnProperty("reference_rate_id") &&
          props.rowData.reference_rate_id !== null &&
          props.rowData.reference_rate_id !== undefined &&
          !(
            Number(props.rowData.reference_rate_id) == 0 ||
            Number(props.rowData.reference_rate_id) ==
              Number(props.rowData.contract_room_no)
          )
        ) {
          props.rowData[per.id] = priceCalculate(
            props.rowData["operation_value" + per.id],
            per.id,
            props
          );
          return (
            <>
              <Select
                displayEmpty
                size="small"
                variant="standard"
                value={
                  props.rowData["operation" + per.id] !== undefined
                    ? props.rowData["operation" + per.id]
                    : selctedcontractRoomArray["operation_id"]
                }
                error={
                  selctedcontractRoomArray["operation_id"]
                    ? false
                    : props.rowData["operation" + per.id]
                    ? false
                    : true
                }
                onChange={(e) => {
                  props.onChange(
                    //props içerisinde hem operation+per.id inputa girilen değeri alıyor
                    ((props.rowData["operation" + per.id] = e.target.value),
                    //hem de girilen periyodun fiyatı için girilen bilgiler hesaplama fonksiyonuna gönderiliyor
                    (props.rowData[per.id] = priceCalculate(
                      props.rowData["operation_value" + per.id] !== undefined &&
                        null
                        ? props.rowData["operation_value" + per.id]
                        : selctedcontractRoomArray["operation_value"],
                      per.id,
                      props
                    )))
                  );
                }}
                onBlur={(e) => {
                  if (firstPeriod == per.id) {
                    props.onChange(
                      (props.rowData[firstPeriod] = e.target.value),
                      sameCodeConroller().map((period) => {
                        props.rowData[`operation${period.id}`] = e.target.value;
                      })
                    );
                  }
                }}
                style={{ width: "30%", marginRight: "3%" }}
                inputProps={{
                  "aria-label":
                    "Without label" /* , readOnly: isDisableCalcInput(per.id, props)  */,
                }}
                InputLabelProps={{ shrink: true }}
              >
                {operations.map((operation) => (
                  <MenuItem
                    name={operation.name}
                    key={operation.id}
                    value={operation.id}
                  >
                    {operation.sembol}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                className={classes.numberColumns}
                type="numeric"
                style={{ width: "67%" }}
                name="price"
                error={
                  selctedcontractRoomArray["price"]
                    ? false
                    : props.rowData[per.id]
                    ? false
                    : true
                }
                InputProps={{
                  inputComponent: PriceFormatCustom,
                }}
                value={
                  props.rowData["operation_value" + per.id] !== undefined
                    ? props.rowData["operation_value" + per.id]
                    : selctedcontractRoomArray["operation_value"]
                }
                /*  inputProps={{
                  readOnly: isDisableCalcInput(per.id, props),
                }} */
                onChange={(e) => {
                  /* props içerisinde ki periyodun fiyatının belirlenebilmesi için hesaplama fonksiyonundan dönen sonucu ait olduğu periyoda
                   ve hesaplanacak değeride operation_value+per.id ye atıyor */
                  let calculatedPrice = priceCalculate(
                    e.target.value,
                    per.id,
                    props
                  );

                  props.onChange(
                    (props.rowData[per.id] = calculatedPrice),
                    (props.rowData["operation_value" + per.id] = e.target.value)
                  );
                }}
                onBlur={(e) => {
                  if (firstPeriod == per.id && lastPeriod > 1) {
                    Swal.fire({
                      title: intl.formatMessage({
                        id: "does.it.apply.to.other.periods",
                      }),
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#41C329",
                      allowOutsideClick: false,
                      cancelButtonColor: "#d33",
                      confirmButtonText: intl.formatMessage({ id: "yes" }),
                      cancelButtonText: intl.formatMessage({ id: "no" }),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        props.onChange(
                          (props.rowData[firstPeriod] = e.target.value),
                          sameCodeConroller().map((period) => {
                            props.rowData[`operation_value${period.id}`] =
                              e.target.value;
                          })
                        );
                      }
                    });
                  }
                }}
              />
            </>
          );
        } else {
          isTherePrice = false;
          /* referans alınan odanın periyoduna fiyat girilmemişse istherePrice true değerini alıyor eğer böyle bir işlem yapılmışsa
           true değerinde kalmaması gerekli bu yüzden tekrar false değerine çevriliyor*/
          return (
            <TextField
              className={classes.numberColumns}
              type="numeric"
              name="price"
              error={
                selctedcontractRoomArray["price"]
                  ? false
                  : props.rowData[per.id]
                  ? false
                  : true
              }
              InputProps={{
                inputComponent: PriceFormatCustom,
              }}
              // id={selctedcontractRoomArray?.id}
              value={
                props.value !== undefined
                  ? props.value
                  : selctedcontractRoomArray["price"]
              }
              onChange={(e) => {
                props.rowData[per.id] = e.target.value;
                props.onChange(e.target.value);
              }}
              InputLabelProps={{
                readOnly: true,
              }}
            />
          );
        }
      },
    });
  });

  //NOTE -  Eğer contractroom base boardı değişir ve bu boarda ve odaya ait bir boardsupp var ise amount tekrar hesaplaması için hesapalama fonk. çağırıyoruz
  // function contractRoomBoardControlFunc(newData, oldData) {
  //   // Değişen odanın değişmeden önceki boardının kullanıldığı bir marketing promotion var mı onu buluyoruz.
  //   let findMarketingPromotion = marketingPromotion.filter(
  //     (mp) =>
  //       mp.room_type_id == newData.allotment_reference_id &&
  //       mp.board_id == oldData.board_id
  //   );
  //   // Değişen odanın değiştikten sonraki boardının kullanıldığı bir marketing promotion var mı onu buluyoruz.
  //   let findMarketingPromotion2 = marketingPromotion.filter(
  //     (mp) =>
  //       mp.room_type_id == newData.allotment_reference_id &&
  //       mp.board_id == newData.board_id
  //   );

  //   let supControl;
  //   //console.log(supControl, "sup1");
  //   if (findMarketingPromotion?.length > 0) {
  //     //Eğer marketing promotion var ise fiyat için bakabileceği bir supplament var mı onun kontrolünü yapıyoruz
  //     supControl = findMarketingPromotion.some((mp) => {
  //       return Object.values(boardSupplaments).filter((supp) => {
  //         return (
  //           mp.board_id == supp.to_board &&
  //           supp.room_types?.includes(newData.id)
  //         );
  //       }).length > 0
  //         ? true
  //         : false;
  //     });
  //   }

  //   // Eğer marketing fiyatı için bakılabilecek bir supplament var ise hesaplama fonksiyonuna gönderiyoruz.
  //   if (supControl == true) {
  //     //Oda güncellendikten sonra boardını eski board gönderdiği için resden gelen data ile fonksiyona giden datayı güncelliyorum.
  //     newData.contract_room_price = newData.contract_room_price.map((price) => {
  //       return price.id;
  //     });
  //     funcData.contract_room[newData.id] = newData;
  //     //Eğer birden fazla marketing var ise
  //     if (findMarketingPromotion?.length > 1) {
  //       let marketingData = [];
  //       findMarketingPromotion.map((promotion) => {
  //         let calculateAmount = OfferAmountCalculate(promotion, funcData);
  //         marketingData.push({
  //           id: promotion.id,
  //           amount: calculateAmount,
  //         });
  //       });
  //       dispatch(updateMarketingPromotionAmountService(marketingData)).then(
  //         (res) => {
  //           if (res.code == 200) {
  //             sweetAlerts(
  //               "success",
  //               intl.formatMessage({
  //                 id: "contract.room",
  //               }) +
  //                 intl.formatMessage({
  //                   id: "updated.successfully",
  //                 })
  //             );
  //           }
  //         }
  //       );
  //     }
  //     //Eğer 1 tane marketing var ise
  //     else {
  //       let calculateAmount = OfferAmountCalculate(
  //         findMarketingPromotion[0],
  //         funcData
  //       );
  //       let marketingData = [];
  //       marketingData.push({
  //         id: findMarketingPromotion[0].id,
  //         amount: calculateAmount,
  //       });
  //       dispatch(updateMarketingPromotionAmountService(marketingData)).then(
  //         (res) => {
  //           if (res.code == 200) {
  //             sweetAlerts(
  //               "success",
  //               intl.formatMessage({
  //                 id: "contract.room",
  //               }) +
  //                 intl.formatMessage({
  //                   id: "updated.successfully",
  //                 })
  //             );
  //           }
  //         }
  //       );
  //     }
  //   } else {
  //     //Eğer supplement yok ise ve marketing var ise marketing promotionu kontrol etmesi için yazı çıkartıyoruz eğer marketing yok ise direkt odayı güncelledik diyoruz
  //     findMarketingPromotion?.length > 0
  //       ? sweetAlerts(
  //           "success",
  //           intl.formatMessage({
  //             id: "contract.room",
  //           }) +
  //             intl.formatMessage({
  //               id: "updated.successfully",
  //             }) +
  //             intl.formatMessage({
  //               id: "but.check.marketing.promotion",
  //             })
  //         )
  //       : sweetAlerts(
  //           "success",
  //           intl.formatMessage({
  //             id: "contract.room",
  //           }) +
  //             intl.formatMessage({
  //               id: "updated.successfully",
  //             })
  //         );
  //   }
  // }

  return (
    <MaterialTable
      tableRef={tableRef}
      title=""
      columns={columns}
      data={contractRooms}
      icons={{
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
      }}
      options={{
        showTitle: false,
        toolbar: false,
        search: false,
        paging: false,
        actionsColumnIndex: 0,
        addRowPosition: "first",
      }}
      editable={{
        deleteTooltip: (row) =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: (row) =>
          isAuthUpdate ? "Update" : "You are not authorized",
        isDeletable: (row) =>
          isAuthDelete ? (contractStatusControl ? true : false) : false,
        isEditable: (row) =>
          isAuthUpdate ? (contractStatusControl ? true : false) : false,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(async () => {
              // if(newData.reference_rate_id!==null )
              // {
              //   // contract_room_price[crp].price
              // }
              let boardControl = false;
              if (newData.board_id != oldData.board_id) {
                boardControl = true;
              }

              let isUpdated = true;
              //contract room'un rate typemı değişti mi
              let control = newData.rate_id !== oldData.rate_id;
              // Eğer ilk contract room mu editliyorsa Swal modalını çıkartmaması için kontrol ediyoruz
              if (oldData.rate_id == null) {
                control = false;
                boardControl = false;
              }
              if (control || boardControl) {
                //Eğer odanın rate type değişirse
                if (control) {
                  // databaseden contraton roomuna ait addpax board supplements exra var mı diye kontrole yolluyoruz.
                  const res = await dispatch(
                    controlContractRoomRateType(newData.contract_id, newData.id)
                  );
                  if (res.data.length > 0) {
                    let modal2Control = false;
                    await Swal.fire({
                      title: "Are you sure?",
                      text: `In this contract ,  ${res.data} will be reset ! `,
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Create Room Information",
                    }).then((result) => {
                      //Eğer rate type değişme modalına hayır der ise update iptal ediyoruz
                      if (result.isDismissed) {
                        isUpdated = false;
                        newData["rate_type_change"] = false;
                      } else {
                        modal2Control = true;
                      }
                    });
                    //Eğer modala okay der ise board değiştimi değiştiyse boardın kontrolüne yolluyoruz
                    if (boardControl && modal2Control) {
                      let data = {
                        contract_id: newData.contract_id,
                        room_id: newData.id,
                        board_id: oldData.board_id,
                      };
                      const resBoard = await dispatch(
                        contractRoomBoardControl(data)
                      ).then((res) => {
                        let arrr = [];
                        if (Object.values(res).length > 0) {
                          if (res.hasOwnProperty("supplament")) {
                            res.supplament.map((sup) => {
                              arrr.push(
                                `Contract board is used in board supplament with ${sup} code.`
                              );
                            });
                            delete res.supplament;
                          }
                          if (res.hasOwnProperty("offer")) {
                            res.offer.map((off) => {
                              arrr.push(
                                `Contract board is used in offer with ${off} code.`
                              );
                            });
                            delete res.offer;
                          }
                          if (res.hasOwnProperty("extra")) {
                            res.extra.map((ex) => {
                              arrr.push(
                                `Contract board is used in extra  with ${ex} code.`
                              );
                            });
                            delete res.extra;
                          }

                          Object.values(res).map((res) => {
                            arrr.push(`Contract board is used in${res}`);
                          });

                          Swal.fire({
                            icon: "warning",
                            title: "Contract Room Board is used !!",
                            html: ControlContent(arrr),
                          });
                          isUpdated = false;
                          newData["rate_type_change"] = false;
                        }
                        //eğer board kullanılmıyorsa  güncelleme yap
                        else {
                          isUpdated = true;
                          newData["rate_type_change"] = true;
                        }
                      });
                    }
                    //eğer board kontrolünü gerektirecek bir şey yok ve rate type modalına evet dediyse rate type güncelle
                    else if (!boardControl && modal2Control) {
                      isUpdated = true;
                      newData["rate_type_change"] = true;
                    }
                  }
                } else if (boardControl) {
                  let data = {
                    contract_id: newData.contract_id,
                    room_id: newData.id,
                    board_id: oldData.board_id,
                  };
                  const resBoard = await dispatch(
                    contractRoomBoardControl(data)
                  ).then((res) => {
                    //Eğer board bir yerde kullanılıyorsa update iptal ediyoruz
                    let arrr = [];
                    if (Object.values(res).length > 0) {
                      if (res.hasOwnProperty("supplament")) {
                        res.supplament.map((sup) => {
                          arrr.push(
                            `Contract board is used in board supplament with ${sup} code.`
                          );
                        });
                        delete res.supplament;
                      }
                      if (res.hasOwnProperty("offer")) {
                        res.offer.map((off) => {
                          arrr.push(
                            `Contract board is used in offer with ${off} code.`
                          );
                        });
                        delete res.offer;
                      }
                      if (res.hasOwnProperty("extra")) {
                        res.extra.map((ex) => {
                          arrr.push(
                            `Contract board is used in extra  with ${ex} code.`
                          );
                        });
                        delete res.extra;
                      }
                      Object.values(res).map((res) => {
                        arrr.push(`Contract board is used in ${res}`);
                      });

                      Swal.fire({
                        icon: "warning",
                        title: "Contract Room Board is used !!",
                        html: ControlContent(arrr),
                      });
                      isUpdated = false;
                      newData["rate_type_change"] = false;
                    }
                    //eğer board kullanılmıyorsa  güncelleme yap
                    else {
                      isUpdated = true;
                    }
                  });
                }
              }
              resolve();

              if (isUpdated) {
                const contractRooms = Object.values(contract_room).filter(
                  (room) => room.contract_id == selectedContract
                );
                newData.reference_rate_id = newData.reference_rate_id
                  ? newData.reference_rate_id
                  : newData.contract_room_no;
                const isInfiniteLoop = IsInfiniteLoop(
                  contractRooms,
                  newData.reference_rate_id,
                  newData.contract_room_no
                );
                if (isTherePrice) {
                  sweetAlerts(
                    "warning",
                    intl.formatMessage({
                      id: "please.add.price.to.referenced.room",
                    })
                  );
                  reject();
                } else if (!isInfiniteLoop) {
                  //Bu oda başka bir odaya referans olduğu için referans alamazsınız
                  sweetAlerts(
                    "warning",
                    intl.formatMessage({
                      id: "this.room.is.referenced.to.another.room",
                    })
                  );
                  reject();
                } else {
                  let control = true;
                  newData.contract_room_price &&
                    newData.contract_room_price.map((crp) => {
                      {
                        if (
                          contract_room_price[crp].price == null ||
                          undefined ||
                          ""
                        ) {
                          if (
                            newData[contract_room_price[crp].period_id] ==
                              null ||
                            undefined ||
                            "" ||
                            parseInt(
                              newData[contract_room_price[crp].period_id]
                            ) <= 0
                          ) {
                            control = false;
                          }
                        }
                        if (
                          newData[contract_room_price[crp].period_id] == "" ||
                          parseInt(
                            newData[contract_room_price[crp].period_id]
                          ) <= 0
                        ) {
                          control = false;
                        }
                      }
                    });
                  if (
                    parseInt(newData.min_payer) < 0 ||
                    parseInt(newData.min_pax) < 0 ||
                    parseInt(newData.max_pax) < 0 ||
                    parseInt(newData.min_adult) < 0 ||
                    parseInt(newData.max_adult) < 0 ||
                    (childControl
                      ? newData.min_child < 0 ||
                        newData.max_child < 0 ||
                        newData.max_infant < 0
                      : false)
                  ) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "negative.value" })
                    );
                    reject();
                  } else if (parseInt(newData.min_pax) == 0) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({
                        id: "min.pax.value.must.be.greater.than.zero",
                      }) // min pax 0 olarak girilirse
                    );

                    reject();
                  } else if (
                    !newData.board_id ||
                    !newData.rate_id ||
                    !(newData.allotment || parseInt(newData.allotment) >= 0) ||
                    !(
                      newData.allotment_reference_id ||
                      parseInt(newData.allotment_reference_id) >= 0
                    ) ||
                    !(
                      parseInt(newData.min_pax) ||
                      parseInt(newData.min_pax) >= 0
                    ) ||
                    !(
                      parseInt(newData.max_pax) ||
                      parseInt(newData.max_pax) >= 0
                    ) ||
                    !(
                      parseInt(newData.min_adult) ||
                      parseInt(newData.min_adult) >= 0
                    ) ||
                    !(
                      parseInt(newData.max_adult) ||
                      parseInt(newData.max_adult) >= 0
                    ) ||
                    (childControl
                      ? !(
                          parseInt(newData.min_child) ||
                          parseInt(newData.min_child) >= 0
                        ) ||
                        !(
                          parseInt(newData.max_child) ||
                          parseInt(newData.max_child) >= 0
                        ) ||
                        !(
                          parseInt(newData.max_infant) ||
                          parseInt(newData.max_infant) >= 0
                        )
                      : false)
                  ) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "empty.value" })
                    );
                    reject();
                  } else if (!parseInt(newData.min_payer) > 0) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "invalid.min.payer" })
                    );
                    reject();
                  } else {
                    if (!control) {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({ id: "empty.or.invalid.price" })
                      );
                      reject();
                    } else {
                      newData["old_reference_rate_id"] =
                        oldData.reference_rate_id &&
                        oldData.reference_rate_id != newData.contract_room_no
                          ? oldData.reference_rate_id
                          : 0;

                      let findID = sameCodeConroller2().find((val) =>
                        newData.hasOwnProperty(`operation${val}`)
                      );
                      sameCodeConroller2().map((cod) => {
                        if (cod != findID) {
                          newData[`operation${cod}`] =
                            newData[`operation${findID}`];
                          newData[`operation_value${cod}`] =
                            newData[`operation_value${findID}`];
                        }
                      });
                      dispatch(updateContractRoomService(newData)).then(
                        (res) => {
                          if (res?.code == 201) {
                            sweetAlerts(
                              "success",
                              intl.formatMessage({ id: "contract.room" }) +
                                intl.formatMessage({
                                  id: "updated.successfully",
                                })
                            );

                            // Eğer PP olan odayı PR olarak değiştirirsek BoardSuplement kısmında edit yapınca patlamaması için BoardSuplement statesini değişiyoruz
                            dispatch(
                              getBoardSupplamentService(selectedContract)
                            );
                            dispatch(getExtraService(selectedContract));
                          }
                        }
                      );
                      resolve();
                    }
                  }
                }
              }
              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              //kontract roomun silmeden önceki kontrolü
              dispatch(
                controlContractRoominUseService(selectedContract, oldData.id)
              ).then((res) => {
                if (res) {
                  if (!controlRoomPriceInUse(oldData.contract_room_no)) {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({
                        id:
                          "room.price.referenced.please.edit.first.reference.room.price",
                      })
                    );
                  } else {
                    dispatch(deleteContractRoomService(oldData.id)).then(
                      (res) => {
                        if (res == 201) {
                          sweetAlerts(
                            "success",
                            intl.formatMessage({ id: "contract.room" }) +
                              intl.formatMessage({ id: "deleted.successfully" })
                          );
                        }
                      }
                    );
                  }
                }
              });
              resolve();
            }, 600);
          }),
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
            onEditingCanceled={(mode, rowData) => {
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (mode == "update") {
                    Swal.fire({
                      title: intl.formatMessage({ id: "are.you.sure?" }),
                      text: intl.formatMessage({
                        id: "do.you.want.to.cancel.the.changes",
                      }),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#41C329",
                      allowOutsideClick: false,
                      cancelButtonColor: "#d33",
                      confirmButtonText: intl.formatMessage({ id: "yes" }),
                      cancelButtonText: intl.formatMessage({ id: "no" }),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == "add") {
                    Swal.fire({
                      title: intl.formatMessage({ id: "are.you.sure?" }),
                      text: intl.formatMessage({
                        id: "do.you.want.to.cancel.the.changes",
                      }),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#41C329",
                      allowOutsideClick: false,
                      cancelButtonColor: "#d33",
                      confirmButtonText: intl.formatMessage({ id: "yes" }),
                      cancelButtonText: intl.formatMessage({ id: "no" }),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        props.onEditingCanceled(mode, rowData);
                        resolve();
                      } else if (result.isDenied) {
                        reject();
                      }
                    });
                  }
                  if (mode == "delete") {
                    props.onEditingCanceled(mode, rowData);
                  }
                });
              });
            }}
          />
        ),
        onRowAdd: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
        Header: (props) => (
          <TableHead className="table table-bordered" id={props}>
            <TableRow>
              <TableCell
                colSpan={1}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                <IntlMessages id="actions" />
              </TableCell>
              <TableCell
                colSpan={7}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                <IntlMessages id="rooms" />
              </TableCell>
              <TableCell
                colSpan={8}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                <IntlMessages id="occupancy" />
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              />
              <TableCell
                colSpan={10}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                <IntlMessages id="period" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                rowSpan={2}
                align="center"
                style={{
                  borderRight: "1px solid #A9A9A9",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                <Suspense fallback={<Loader />}>
                  <ColorButton
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#FFC107",
                      color: "#fff",
                      fontSize: 10,
                    }}
                    onClick={() => handleOpen()}
                    hidden={!contractStatusControl}
                    color="backgroundColor"
                    disabled={isAuthAdd ? false : true}
                  >
                    <IntlMessages id="add.room" />
                  </ColorButton>
                  {flag == true ? (
                    <Rooms
                      flag={flag}
                      handleClose={handleClose}
                      isAuthAdd={isAuthAdd}
                      isAuthUpdate={isAuthUpdate}
                      isAuthDelete={isAuthDelete}
                      modalId="roomsModal"
                      //paginationda seçilen row per page değeri modal kapanıp açıldığı için buradan props olarak göneriliyor.
                      rowPerPage={rowPerPage}
                      setRowPerPage={setRowPerPage}
                    />
                  ) : (
                    ""
                  )}
                </Suspense>
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="room.id" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="room.type" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="room.code" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="agency.room.name" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="product.room.name" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="allotment.id" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="no.of.allotment" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="min.payer" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="min.pax" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="max.pax" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="min.adt" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="max.adt" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="min.chd" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="max.chd" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="max.inf" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="rate.type" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="base.board.type" />
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
              >
                <IntlMessages id="ref.rate.type" />
              </TableCell>
              {/* BURASI PERİYOD GELİNCE MAPLE DÖNÜLCEK */}
              {sameCodeConroller().map((element) => {
                return (
                  <TableCell
                    colSpan={1}
                    align="center"
                    style={{ borderRight: "1px solid #A9A9A9", fontSize: 11 }}
                  >
                    {element.code}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {/* BURASI PERİYOD GELİNCE MAPLE DÖNÜLCEK */}
              {sameCodeConroller().map((element) => {
                return (
                  <>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #A9A9A9", fontSize: 12 }}
                    >
                      {element.newDateString.split(",").map((item, i) => {
                        if (
                          element.newDateString.split(",").length > 0 &&
                          i < element.newDateString.split(",").length / 2 - 1
                        ) {
                          let startDate = new Date(
                            element.newDateString.split(",")[2 * i + 1]
                          );
                          let endDate = new Date(
                            element.newDateString.split(",")[2 * i + 2]
                          );

                          return (
                            `${("0" + startDate.getDate()).slice(-2)}.${(
                              "0" +
                              (startDate.getMonth() + 1)
                            ).slice(-2)}` +
                            "\n" +
                            "-\n" +
                            "\n" +
                            `${("0" + endDate.getDate()).slice(-2)}.${(
                              "0" +
                              (endDate.getMonth() + 1)
                            ).slice(-2)}` +
                            "\t"
                          );
                        }
                      })}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
        ),
      }}
    />
  );
}

export const MemoizedContractRoom = React.memo(ContractRoom);
