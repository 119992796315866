export const getFamilyRooms = (data) => {
  const getFamilyRoomIndex = Object.values(data).findIndex(
    (row) => row.hasOwnProperty("__EMPTY_3") && row.__EMPTY_3 === "FAMILY ROOMS"
  );

  const basicRoomHeaderIndex = Object.values(data[7]).indexOf(
    "Basic Room for CHD Reduction"
  );
  let getFirstMaximumIndex = Object.entries(data[7]).findIndex(
    ([key, value]) => {
      return value === "Maximum";
    }
  );
  const headers = Object.entries(data[7]).map(([key, value], index) => {
    // get first maximum
    if (index === getFirstMaximumIndex) {
      return [key, "Max Person"];
    } else if (getFirstMaximumIndex + 1 === index) {
      return [key, "Min Person"];
    } else if (getFirstMaximumIndex + 2 === index) {
      return [key, "Max Adult"];
    } else if (getFirstMaximumIndex + 3 === index) {
      return [key, "Min Adult"];
    } else if (getFirstMaximumIndex + 4 === index) {
      return [key, "Max Child"];
    } else if (getFirstMaximumIndex + 5 === index) {
      return [key, "Min Child"];
    } else {
      return [key, value];
    }
  });
  const familyRooms = [];

  // return if there is no family rooms in the excel file
  if (
    getFamilyRoomIndex &&
    getFamilyRoomIndex != -1 &&
    data[getFamilyRoomIndex + 1].__rowNum__ !==
      data[getFamilyRoomIndex].__rowNum__ + 1
  ) {
    return familyRooms;
  }

  if (getFamilyRoomIndex && getFamilyRoomIndex != -1) {
    const get3rdPersonIndex = Object.values(data).findIndex(
      (row, index) =>
        index > getFamilyRoomIndex &&
        getFamilyRoomIndex != -1 &&
        row.hasOwnProperty("__EMPTY") &&
        row.__EMPTY === "3rd person reduction/rate"
    );
    for (let i = getFamilyRoomIndex + 1; i < get3rdPersonIndex; i++) {
      familyRooms.push(data[i]);
    }
  }

  const standartRoomPrices = familyRooms.reduce((acc, room) => {
    const roomObject = {};
    roomObject.roomName = room.__EMPTY_1;
    roomObject.roomCode = room.__EMPTY;
    headers.map(([key, headerPeriod], index) => {
      if (room[key] !== "" && room[key] !== undefined) {
        roomObject[headerPeriod] = room[key];
      }
    });
    acc.push(roomObject);
    return acc;
  }, []);

  return standartRoomPrices;
};
