import { GET_DAYS } from './types';
import { normalizeDays } from 'redux/schemas/days';

export const getDays = res => dispatch => {
  const normalizedDays = normalizeDays(res.data.data);

  dispatch({
    type: GET_DAYS,
    payload: normalizedDays.entities,
  });
};
