import { normalizeTasks } from "redux/schemas/room";
import {
  GET_ROOM,
  ADD_ROOM,
  ADD_ROOMS,
  UPDATE_ROOM,
  DELETE_ROOM,
  GET_EXCEL_ROOM,
  ADD_EXCEL_ROOM,
  UPDATE_EXCEL_ROOM,
  DELETE_EXCEL_ROOM
} from "./types";

export const getRoom = (res) => (dispatch) => {
  dispatch({
    type: GET_ROOM,
    payload: res.data
  });
};
export const addRoom = (res) => (dispatch) => {
  dispatch({
    type: ADD_ROOM,
    payload: res.data
  });
};

export const addRooms = (res) => (dispatch) => {
  dispatch({
    type: ADD_ROOMS,
    payload: res.data
  });
};

export const updateRoom = (res) => (dispatch) => {
  dispatch({
    type: UPDATE_ROOM,
    payload: res.data
  });
};

export const deleteRoom = (res) => (dispatch) =>
  dispatch({
    type: DELETE_ROOM,
    payload: res.data
  });
export const addExcelRoom = (res) => (dispatch) => {
  dispatch({
    type: ADD_EXCEL_ROOM,
    payload: res.data
  });
};
export const getExcelRoom = (res) => (dispatch) => {
  dispatch({
    type: GET_EXCEL_ROOM,
    payload: res.data
  });
};

export const updateExcelRoom = (res) => (dispatch) => {
  dispatch({
    type: UPDATE_EXCEL_ROOM,
    payload: res.data
  });
};
export const deleteExcelRooms = (res) => (dispatch) => {
  dispatch({
    type: DELETE_EXCEL_ROOM,
    payload: res.data
  });
};
