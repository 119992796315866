import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import ConstructionIcon from "@mui/icons-material/Construction";
import React, { createRef, useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { Delete, Edit } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  //deleteReservationService,
  getReservationsService
} from "services/reservation";
import { getBoardsService } from "services/boards";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { getRoomService } from "services/rooms";
import { getContractService } from "services/contract";
import { getCurrenciesService } from "services/currency";
import ReservationUpdate from "./ReservationUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CardContent
} from "@material-ui/core";
import { getPeriodService, getReservationPeriod } from "services/period";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import IntlMessages from "@jumbo/utils/IntlMessages";
import ActionsMenu from "./ActionsMenu";
import { DatePicker, Space } from "antd";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Container, Row } from "react-grid-system";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import CmtCard from "@coremat/CmtCard";
import rooms from "redux/reducers/rooms";
import reservation from "redux/schemas/reservation";
const MySwal = withReactContent(Swal);

export default function ShowReservation() {
  const tableRef = createRef();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { paymentMethods } = useSelector(
    ({ paymentMethods }) => paymentMethods
  );
  const intl = useIntl();

  const reservationIDs = useSelector(state => state.reservation.result);
  const reservations = useSelector(state => state.reservation?.entities?.reservations); //prettier-ignore
  const operators = useSelector(state => state.operators.operators);
  const hotels = useSelector(state => state.hotels.hotels);
  const markets_selector = useSelector(state => state.markets.markets);
  const roomTypes = useSelector(state => state.rooms);
  const boards = useSelector(state => state.boards.boards);
  const currencies = useSelector(state => state.currencies.currencies);
  const contracts_selector = useSelector(state => state.contract);
  const [reservationsData, setReservationsData] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [rowHandleInfo, setRowHandleInfo] = useState();
  const [roomModalStatus, setRoomModalStatus] = useState(false);
  const [roomModalData, setRoomModalData] = useState("");
  const { contracts } = useSelector(({ contract }) => contract);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { entities } = useSelector(({ reservation }) => reservation);
  const reservation_rooms = useSelector(
    state => state.reservation?.entities?.rooms
  );
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { markets } = useSelector(({ markets }) => markets);

  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 4 && permission.put === 1
  );

  const handleBookingStatus = booking_status => {
    switch (booking_status) {
      case 1:
        return " New ";
      case 2:
        return " Updated ";
      case 3:
        return " Cancel ";
      case 4:
        return "No Show ";
    }
  };

  const handleCommicationStatus = communication_status => {
    switch (communication_status) {
      case 1:
        return " Pending Sending ";
      case 2:
        return " Sent ";
    }
  };

  const handleHotelStatus = hotel_status => {
    switch (hotel_status) {
      case 1:
        return "Not Sent";
      case 2:
        return "Waiting";
      case 3:
        return "Cancelled";
      case 4:
        return "Confirmed";
    }
  };

  const handleReleaseDialogOpen = () => {
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Burada ki senaryo şöyle ki:
  /**
   * Datagrid'de basılan satırın id'sini alıyor reservasyon id'si ile eşitliyor
   * ve edit reservation'da reservasyon cancel dedği zaman booking statusunu 'cancel yapıyor
   */
  /**/ const handleOnCellClick = e => {
    // handleCellClick Satır elementlerinin referansını almak için kullanılan bir default DataGrid fonksiyonudur
    /**/ setRowHandleInfo(e);
    /**/
  };

  const handleClose = e => {
    if (e !== undefined) {
      /**/ let reservationCancelButtonValue = e.target.innerHTML;
      /**/ if (reservationCancelButtonValue == "Reservation Cancel") {
        /**/ let reservationsItems = Object.values(reservations);
        /**/ reservationsItems.map((val, key) => {
          /**/ if (rowHandleInfo.id == val.id) {
            val.booking_status = "Canceled";
            /**/
          }
          /**/
        });
      }
    }
    setOpen(false);
    setType(false);
  };

  const handleDelete = rowData => {
    //dispatch(deleteReservationService(rowData.id));
    setReleaseDialogOpen(false);
    Swal.fire({
      title: "Deleted!",
      text: intl.formatMessage({ id: "reservation.has.been.deleted" }),
      icon: "success"
    });
  };

  const getContractPeriods = async contract_id => {
    try {
      var result = await getReservationPeriod(contract_id);
      return result.data.data;
    } catch (e) {
      return [];
    }
  };

  const cancellationControl = async rowData => {
    var periods = await getContractPeriods(rowData.contract_id);
    var has_cancellation = false;
    var selectedPeriod = {};
    var dateDiff = 0;
    setSelectedReservation(rowData);
    if (periods.length > 0) {
      selectedPeriod = periods.find(period =>
        moment(rowData.start_date).isBetween(period.start_date, period.end_date)
      );

      dateDiff = moment(rowData.start_date).diff(moment(), "days") + 1;
      if (dateDiff < selectedPeriod.release) {
        handleReleaseDialogOpen();
      } else {
        handleDelete(rowData);
      }
    }
    if (periods.find(p => p.period_type === "Cancellation")) {
      has_cancellation = true;
    }
  };

  useEffect(() => {
    if (reservations !== undefined) {
      setReservationsData(Object.values(reservations));
    }
  }, [reservationIDs, reservations]);
  const dateToConvert = date => {
    var temp = "";
    temp = `${`0${new Date(date).getDate()}`.slice(-2)}/${`0${new Date(
      date
    ).getMonth() + 1}`.slice(-2)}/${new Date(date).getFullYear()}`;
    return temp;
  };
  /**************FILTER SECTION*********************/

  const [filterState, setFilterState] = useState({
    hotel_id: "",
    operator_id: "",
    voucher_no: "",
    hotel_status: "",
    user_id: "",
    selling_date: "",
    start_date: "",
    end_date: ""
  });
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);
  const [hotelStatus, setHotelStatus] = useState([
    { name: "NOT SENT", id: 1 },
    { name: "WAITING", id: 2 },
    { name: "CANCELLED", id: 3 },
    { name: "CONFIRMED", id: 4 }
  ]);
  const { RangePicker } = DatePicker;

  const filterHandleChange = e => {
    const { name, value } = e;
    setFilterState(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const handleChangeStart = (name, value) => {
    setFilterState({
      ...filterState,
      start_date: { from: value[0], to: value[1] }
    });
  };
  const handleChangeEnd = (name, value) => {
    setFilterState({
      ...filterState,
      end_date: { from: value[0], to: value[1] }
    });
  };
  const handleChangeSelling = (name, value) => {
    setFilterState({
      ...filterState,
      selling_date: { from: value[0], to: value[1] }
    });
  };
  const filterSelected = () => {
    if (reservations !== undefined || null) {
      let allReservations = Object.values(reservations)?.map(reservation => {
        let resHotel = hotels[reservation.hotel_id];
        let newReservation = {
          ...reservation,
          hotelStatus:
            reservation.hotel_status === 1
              ? "NOT SENT"
              : reservation.hotel_status === 2
              ? "WAITING"
              : reservation.hotel_status === 3
              ? "CANCELLED"
              : "CONFIRMED",
          bookingStatus:
            reservation.booking_status === 1
              ? "NEW"
              : reservation.booking_status === 2
              ? "UPDATED"
              : reservation.booking_status === 3
              ? "CANCELLED"
              : "NO SHOW",
          communicationStatus:
            reservation.communication_status === 1 ? "SENT" : "PENDING SENDING",
          operator_name: operators[reservation.operator_id]?.code,
          tourOpNo: "",
          voucher_no: reservation.voucher_no,
          lead_name: reservation.lead_name,
          product_name: resHotel?.name,
          checkInDate: reservation.start_date,
          checkOutDate: reservation.end_date,
          selling_date: reservation.selling_date,
          noOfNights:
            (Date.parse(reservation.end_date) -
              Date.parse(reservation.start_date)) /
            (1000 * 60 * 60 * 24),
          room_type: (function() {
            let room_string = "";
            reservation.rooms.forEach(room => {
              room_string +=
                roomType[
                  contract_room[reservation_rooms[room]?.contract_room_id]
                    ?.room_id
                ]?.code + " ";
            });
            return room_string;
          })(),
          board_name:
            boards[contract_has_board[reservation.board_id]?.board_id]?.code,
          adult_num: reservation.adult_num,
          child_num: reservation.child_num,
          infant_num: reservation.infant_num,
          amount: "",
          currency_name:
            currencies[contracts[reservation.contract_id]?.currency]?.code,
          contract_name: contracts[reservation.contract_id]?.contract_name,
          market_name: (function() {
            let market_string = "";
            if (reservation.contract_id !== 0) {
              contracts[reservation.contract_id].market.forEach(market => {
                market_string += markets[market].code + " ";
              });
            } else {
            }

            return market_string;
          })()
        };
        return newReservation;
      });
      Object.keys(filterState).forEach(filterKey => {
        if (filterState[filterKey] !== "") {
          if (
            filterKey !== "start_date" &&
            filterKey !== "end_date" &&
            filterKey !== "selling_date"
          ) {
            allReservations = allReservations.filter(
              res => res[filterKey] == filterState[filterKey]
            );
          } else {
            allReservations = allReservations.filter(res =>
              moment(res[filterKey]).isBetween(
                filterState[filterKey].from,
                filterState[filterKey].to,
                undefined,
                "[]"
              )
            );
          }
        }
      });
      setReservationsData(allReservations);
    }
  };
  /**************FILTER SECTION END*********************/
  const modalLoad = roomData => {
    new Promise(function(resolve, reject) {
      resolve(
        setRoomModalData(
          roomData.length
            ? roomData
                .map(roomId => {
                  return roomTypes.rooms.roomType[
                    contract_room[entities.rooms[roomId].contract_room_id]
                      .room_id
                  ].room_name;
                })
                .join(",\t")
            : ""
        )
      );
    }).then(roomsString => {
      setRoomModalStatus(true);
    });
  };

  const columns = [
    {
      headerName: intl.formatMessage({ id: "actions" }),
      field: "actions",
      sortable: false,
      width: 75,
      disableClickEventBubbling: true,
      renderCell: params => (
        <ActionsMenu
          params={params}
          isAuthUpdate={isAuthUpdate}
          handleClickOpen={handleClickOpen}
          setSelectedReservation={setSelectedReservation}
          setType={setType}
        />
      )
    },
    {
      headerName: intl.formatMessage({ id: "hotel.status" }),
      field: "hotel_status",
      width: 200,
      renderCell: params => {
        return handleHotelStatus(params.row.hotel_status);
      }
    },
    {
      headerName: intl.formatMessage({ id: "booking.status" }),
      field: "booking_status",
      width: 200,
      renderCell: params => {
        return handleBookingStatus(params.row.booking_status);
      }
    },
    {
      headerName: intl.formatMessage({ id: "hotel.name" }),
      field: "hotelName",
      width: 200,
      renderCell: params => {
        return hotels[params.row.hotel_id] !== undefined
          ? hotels[params.row.hotel_id].name
          : "";
      }
    },
    {
      headerName: intl.formatMessage({ id: "room.type" }),
      field: "roomType",
      width: 200,
      renderCell: params => {
        const rooms = params.row.rooms;
        if (rooms.length == 1) {
          return roomTypes.rooms.roomType[
            contract_room[entities.rooms[rooms[0]].contract_room_id]?.room_id
          ]?.room_name;
        }
        return (
          <Button variant="contained" onClick={() => modalLoad(rooms)}>
            Show Room Types
          </Button>
        );
        // return roomTypes.length&&params.row.rooms.length ? params.row.rooms.map(roomType => roomType === 1)[0].name : '';
      }
    },
    {
      headerName: intl.formatMessage({ id: "check.in.date" }),
      field: "start_date",
      width: 200,
      renderCell: params => {
        return params.row.start_date !== "0000-00-00"
          ? dateToConvert(params.row.start_date)
          : "00/00/0000";
      }
    },
    {
      headerName: intl.formatMessage({ id: "check.out.date" }),
      field: "end_date",
      editable: "never",
      width: 200,
      renderCell: params => {
        return params.row.end_date !== "0000-00-00"
          ? dateToConvert(params.row.end_date)
          : "00/00/0000";
      }
    },
    {
      headerName: intl.formatMessage({ id: "communication.status" }),
      field: "communication_status",
      width: 200,
      renderCell: params => {
        return handleCommicationStatus(params.row.communication_status);
      }
    },
    {
      headerName: intl.formatMessage({ id: "reservation.create.date" }),
      field: "selling_date",
      width: 200,
      renderCell: params => {
        return params.row.selling_date !== "0000-00-00"
          ? dateToConvert(params.row.selling_date)
          : "00/00/0000";
      }
    },
    {
      headerName: intl.formatMessage({ id: "tour.operator" }),
      field: "operator_id",
      width: 200,
      renderCell: params => {
        return operators[params.row.operator_id] !== undefined
          ? operators[params.row.operator_id].name
          : "";
      }
    },
    {
      headerName: intl.formatMessage({
        id: "tour.op.booking.no"
      }),
      field: "tourBookNo",
      width: 200
    },
    {
      headerName: intl.formatMessage({
        id: "proxo.booking.no"
      }),
      field: "voucher_no",
      width: 200
    },
    {
      headerName: intl.formatMessage({
        id: "creator.name"
      }),
      field: "creator_name",
      width: 200
    },
    {
      headerName: intl.formatMessage({ id: "stay.night" }),
      field: "stay_night",
      width: 200,
      editable: "never",
      renderCell: params => {
        var nights = Math.round(
          (Date.parse(params.row.end_date) -
            Date.parse(params.row.start_date)) /
            (1000 * 60 * 60 * 24)
        );
        return nights;
      }
    },

    {
      headerName: intl.formatMessage({ id: "board.type" }),
      field: "boardType",
      width: 200,
      renderCell: params => {
        let contractBoardId = params.row?.board_id;
        return boards[contractBoardId] ? boards[contractBoardId].code : "";
      }
    },
    {
      headerName: intl.formatMessage({
        id: "number.of.adult"
      }),
      field: "adult_num",
      width: 200
    },
    {
      headerName: intl.formatMessage({
        id: "number.of.child"
      }),
      field: "child_num",
      width: 200
    },
    {
      headerName: intl.formatMessage({
        id: "number.of.infant"
      }),
      field: "infant_num",
      width: 200
    },
    {
      headerName: intl.formatMessage({
        id: "amount"
      }),
      field: "amount",
      width: 200
    },
    {
      headerName: intl.formatMessage({ id: "currency" }),
      field: "currency",
      width: 200,
      renderCell: params => {
        if (params.row.contract_id != 0) {
          var currency_id =
            Object.values(contracts_selector.contracts).length !== 0
              ? Object.values(contracts_selector.contracts).find(
                  contract => contract.id === params.row.contract_id
                ).currency
              : "";
          return currencies[currency_id].name;
        } else {
          return "";
        }
      }
    },
    {
      headerName: intl.formatMessage({ id: "contract" }),
      field: "contract_id",
      width: 200,
      renderCell: params => {
        if (params.row.contract_id == 0) {
          if (params.formattedValue == 0) {
            return (params.formattedValue = "");
          }
        }
      }
    },
    {
      headerName: intl.formatMessage({ id: "market" }),
      field: "market",
      width: 200,
      renderCell: params => {
        var market_name = "";
        if (params.row.contract_id != 0) {
          var markets =
            Object.values(contracts_selector.contracts).length !== 0
              ? Object.values(contracts_selector.contracts).find(
                  contract => contract.id === params.row.contract_id
                ).market
              : "";
          markets.forEach(market => {
            market_name += markets_selector[market].name + ",";
          });
        }
        return market_name;
      }
    }
  ];
  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: "fixed"
    },
    table: {
      minWidth: 500,
      margin: "fixed",
      marginTop: "1%",
      color:
        localStorage.getItem("theme-type") === "dark"
          ? "white !important"
          : "black !important"
    }
  }));

  const classes = useStyles();

  const dateFormat = "YYYY/MM/DD";

  return (
    <div className={classes.root}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed" style={{ paddingBottom: "20px" }}>
                <Grid container spacing={10}>
                  <Grid item sm={3}>
                    <IntlMessages id="check.in.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeStart}
                      />
                    </Space>
                  </Grid>
                  <Grid item sm={3}>
                    <IntlMessages id="check.out.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeEnd}
                      />
                    </Space>
                  </Grid>

                  <Grid item sm={3}>
                    <IntlMessages id="selling.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeSelling}
                      />
                    </Space>
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="outlined-basic"
                      name="voucher_no"
                      label={intl.formatMessage({ id: "booking.no" })}
                      variant="standard"
                      onChange={(e, value) => {
                        let event = {
                          name: "voucher_no",
                          value: e.target.value
                        };
                        filterHandleChange(event);
                      }}
                    />
                  </Grid>
                  <Grid container spacing={10}>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(operators)}
                        onChange={(e, value) => {
                          let event = {
                            name: "operator_id",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.operator"
                            })}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: "hotel_id",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.product"
                            })}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <Autocomplete
                        options={hotelStatus}
                        onChange={(e, value) => {
                          let event = {
                            name: "hotel_status",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Confirm Status"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'opBookNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.code}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Opeator Booking No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotelConfirmeNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Hotel Confirmation No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid> */}
                    <Grid item sm={3}>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        style={{ backgroundColor: "gray" }}
                        onClick={() => filterSelected()}
                      >
                        <IntlMessages id="filter" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>

      <ReservationUpdate
        actionDialog={open}
        handleClose={handleClose}
        reservation={selectedReservation}
        type={type}
      />
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reservation Cancellation In Release"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <IntlMessages id="Reservation cancellation is within the release days. If it is cancelled, the penalty will apply. Do you want to continue?" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose}>
            {" "}
            <IntlMessages id="disagree" />{" "}
          </Button>
          <Button onClick={() => handleDelete(selectedReservation)} autoFocus>
            <IntlMessages id="agree" />
          </Button>
        </DialogActions>
      </Dialog>
      <ReservationUpdate dialog={open} />
      <CssBaseline position="fixed" />
      <CardHeader
        title={intl.formatMessage({ id: "show.reservation" })}
        sx={{ color: "#33658a", paddingLeft: "0px" }}
      />
      <Paper>
        <div position="fixed" style={{ height: 400, width: "100%" }}>
          <DataGrid
            onCellClick={handleOnCellClick}
            position="fixed"
            rows={reservationsData}
            columns={columns}
            className={classes.table}
            groupable
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700
              }
            }}
          />
        </div>
      </Paper>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setRoomModalStatus(false);
          setRoomModalData("");
        }}
        open={roomModalStatus}
      >
        <DialogTitle>
          <span
            style={{
              color:
                window.localStorage.getItem("theme-type") == "dark"
                  ? "rgba(255, 255, 255, 0.83)"
                  : "black"
            }}
          >
            Rooms
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {roomModalData}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
