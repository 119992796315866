import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getMarket,
  addMarket,
  updateMarket,
  deleteMarket,
} from "redux/actions/market";
import axios from "./axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    html: text,
  });
};
export const getMarketService = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("market")
    .then((res) => {
      dispatch(getMarket(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const addMarketService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("market", data)
    .then((res) => {
      dispatch(addMarket(res));
      dispatch(fetchSuccess());
      sweetAlerts(
        "success",
        "New market created successfully. <br> Please add country to market!"
      );
      sendLogService.sendMarketLog(res, data);
    })
    .catch((e) => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateMarketService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("market", data)
    .then((res) => {
      dispatch(updateMarket(res));
      sweetAlerts("success", "Market updated successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendMarketLog(res, data);
    })
    .catch((e) => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteMarketService = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`market/${id}`)
    .then((res) => {
      dispatch(deleteMarket(res));
      sweetAlerts("success", "Market deleted successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendMarketLog(res, id);
    })
    .catch((e) => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
