import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getTermsConditions,
  addTermsConditions,
  updateTermsConditions,
  deleteTermsConditions,
  getContractTermsConditions,
  addContractTermsConditions,
  updateContractTermsConditions,
  deleteContractTermsConditions,
  getAllContractTermsConditions
} from "redux/actions/termsConditions";
import axios from "./axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
export const getTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("termsConditions")
    .then(res => {
      dispatch(getTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("termsConditions", data)
    .then(res => {
      dispatch(addTermsConditions(res));
      sweetAlerts("success", "Terms & Condition Created Successfully!");
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("termsConditions", data)
    .then(res => {
      dispatch(updateTermsConditions(res));
      sweetAlerts("success", "Terms & Condition Updated Successfully!");
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteTermsConditionsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`termsConditions/${id}`)
    .then(res => {
      dispatch(deleteTermsConditions(res));
      sweetAlerts("success", "Terms & Condition Deleted Successfully!");
      dispatch(fetchSuccess());
      sendLogService.termsConditionsServiceLog(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const getAllContractTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`allContractTermsConditions`)
    .then(res => {
      dispatch(getAllContractTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getContractTermsConditionsService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("contractTerms")
    .then(res => {
      dispatch(getContractTermsConditions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addContractTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contractTerms", data)
    .then(res => {
      dispatch(addContractTermsConditions(res));
      sweetAlerts(
        "success",
        "Contract Terms & Condition Created Successfully!"
      );
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, data);
    })
    .catch(e => {
      sweetAlerts("error", e.response.data.message);
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateContractTermsConditionsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractTerms", data)
    .then(res => {
      dispatch(updateContractTermsConditions(res));
      sweetAlerts(
        "success",
        "Contract Terms & Condition Updated Successfully!"
      );
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteContractTermsConditionsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`contractTerms/${id}`)
    .then(res => {
      dispatch(deleteContractTermsConditions(res));
      sweetAlerts(
        "success",
        "Contract Terms & Condition Deleted Successfully!"
      );
      dispatch(fetchSuccess());
      sendLogService.conTermsServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
