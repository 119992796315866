import React, { useState, useRef, useEffect } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { Container, Row } from "react-grid-system";
import { Grid, CardContent } from "@material-ui/core";
import CmtCard from "@coremat/CmtCard";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import axios from "services/axios";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import countries from "../../../data/countries";
import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../Common/formElements/AppTextInput";
import IntlMessages from "@jumbo/utils/IntlMessages";

import { getHotelService } from "services/hotels";
import { changeForeignChars } from "@jumbo/utils/changeForeignChars";
import { utils, writeFile } from "xlsx";

import countriesData from "../../../data/countries";
const useStyles = makeStyles((theme) => ({
  selectBoxRoot: {
    marginBottom: 20,
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    fontSize: "8px",
    width: "100%",
  },
  test1: {
    margin: theme.spacing(2),
    minWidth: "100%",
    padding: "0px",
  },
}));

export default function HotelReport() {
  let countrieSelection = countriesData.map((countrie) => {
    return countrie;
  });
  const { hotels } = useSelector(({ hotels }) => hotels);
  let productSelection = Object.values(hotels).map((product) => {
    return product;
  });
  let countriesDataInfo = useRef(countriesData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHotelService());
  }, []);
  const classes = useStyles();
  const tableRef = useRef();
  const [filterState, setFilterState] = useState({
    hotel_name: "",
    country: "",
    city: "",
    giata_id: "",
  });
  const intl = useIntl();
  const [hotelData, setHotelData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [filteredSelectionCities, setFilteredSelectionCities] = useState([]);
  // const [countryIdState, setCountryIdState] = useState(0);
  // const [filterStatus, setFilterStatus] = useState(0);

  const filterHandleChange = (e) => {
    const { name, value } = e.target;

    if (name == "country") {
      setFilterState((prev) => ({
        ...prev,
        country: value,
        city: "",
      }));

      getCityList(value);
    } else {
      setFilterState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  let giataCodeData = (e) => {
    const { name, value } = e.target;
    setFilterState((prev) => ({
      ...prev,
      hotel_name: "",
      country: "",
      city: "",
      giata_id: value,
    }));
    /*  setCountryIdState(e.target.country_id) */
  };

  let changeGiataNull = () => {
    setFilterState((prev) => ({
      ...prev,
      giata_id: "",
    }));
  };
  const filterSelected = () => {
    if (!(filterState.hotel_name || filterState.country)) {
      Swal.fire(
        "Warning!",
        intl.formatMessage({ id: "please.enter.value.for.filtering" }),
        "warning"
      );
    } else {
      const url = "/filterHotel";
      const token = localStorage.getItem("token");
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios({
        method: "post",
        data: filterState,
        url: url,
      }).then((res) => {
        setHotelData(res.data.data);
      });
      // setFilterStatus(2);
    }
  };

  useEffect(() => {
    if (hotels && hotels != null && Object.values(hotels).length > 0) {
      let data = Object.values(hotels);

      setHotelData(data);
    }
  }, [hotels]);

  const getCityList = (countryCode) => {
    const url = "/city";
    const token = localStorage.getItem("token");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios({
      method: "post",
      data: {
        name: "country_id",
        id: countriesDataInfo.current.find((x) => x.code == countryCode).id,
      },
      url: url,
    }).then((res) => {
      let cities = [];
      res.data.data && res.data.data != null
        ? res.data.data.map((city) => {
            cities.push(city.name);
          })
        : (cities = []);
      setFilteredSelectionCities(cities);
    });
  };

  // useEffect(() => {
  //   let filteredSelectionCitiesTemp = [];
  //   const url = "/filterGiataCities";
  //   const token = localStorage.getItem("token");
  //   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  //   axios({
  //     method: "post",
  //     data: { country: filterState.country },
  //     url: url
  //   }).then(res => {
  //     res.data.data &&
  //       res.data.data.map(city => {
  //         filteredSelectionCitiesTemp.push(city);
  //       });
  //   });
  //   setFilteredSelectionCities(filteredSelectionCitiesTemp);
  // }, [filterState.country]);
  let filterByGiataCode = () => {
    const url = "/searchGiata";
    const token = localStorage.getItem("token");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios({
      method: "post",
      data: filterState,
      url: url,
    }).then((res) => {
      setHotelData(res.data.data);
      // setFilterStatus(1);
    });
  };

  /*  useEffect(() => {
     if (hotelData == null) {
       Swal.fire(
         "Warning!",
         intl.formatMessage({ id: "please.fill.all.the.fields" }),
         "warning"
       );
     }
   }, [hotelData]);
  */

  const tableColumns = [
    {
      title: "Product Name",
      field: "name",
    },
    {
      title: "Product Type",
      field: "product_type",
      render: (rowData) => {
        return rowData?.product_type?.name;
      },
      hidden: true,
    },
    {
      title: "Product Code",
      field: "product_code",
      render: (rowData) => {
        return rowData?.product_type?.code;
      },
      hidden: true,
    },
    {
      title: "Company",
      field: "company",
      hidden: true,
    },
    {
      title: "Tax No",
      field: "tax_no",
      hidden: true,
    },
    {
      title: "Tax Office",
      field: "tax_office",
      hidden: true,
    },
    {
      title: "Continent",
      field: "region",
      render: (rowData) => {
        return rowData?.region?.name;
      },
      hidden: true,
    },
    {
      title: "Country",
      field: "country",
      render: (rowData) => {
        return rowData?.country?.name;
      },
    },
    {
      title: "City",
      field: "city",
      render: (rowData) => {
        return rowData?.city?.name;
      },
    },
    {
      title: "Subcity",
      field: "sub_city",
      render: (rowData) => {
        return rowData?.sub_city?.name;
      },
      hidden: true,
    },
    {
      title: "District",
      field: "district",
      render: (rowData) => {
        return rowData?.district;
      },
      hidden: true,
    },
    {
      title: "Phone",
      field: "tel",
      hidden: true,
    },
    {
      title: "Fax",
      field: "fax",
      hidden: true,
    },
    {
      title: "E-mail",
      field: "e_mail",
      hidden: true,
    },
    {
      title: "Website",
      field: "web_page",
      hidden: true,
    },
    {
      title: "Star Rate",
      field: "star_rate",
      render: (rowData) => {
        return rowData?.star_rate?.value;
      },
      hidden: true,
    },

    {
      title: "Giata Code",
      field: "giata_code",
    },
    {
      title: "Airport Code",
      field: "airport_code",
      render: (rowData) => {
        return rowData?.airport_codes?.code;
      },
      hidden: true,
    },
  ];

  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={10}>
                  <Grid item sm={2} style={{ paddingTop: "0px" }}>
                    <FormControl className={classes.test1}>
                      <Autocomplete
                        freeSolo
                        options={Object.values(productSelection)}
                        value={
                          filterState.country
                            ? productSelection.find(
                                (product) =>
                                  product.name == filterState?.hotel_name
                              )
                            : null
                        }
                        onChange={(e, value) => {
                          filterHandleChange({
                            target: {
                              name: "hotel_name",
                              value: value?.name,
                            },
                          });
                        }}
                        inputValue={filterState.hotel_name ?? ""}
                        onInputChange={(e, value) => {
                          filterHandleChange({
                            target: {
                              name: "hotel_name",
                              value: value,
                            },
                          });
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField {...params} label="Product Name" />
                        )}
                      />
                    </FormControl>

                    {/* <TextField
                      required
                      value={
                        filterState.hotel_name == null
                          ? ""
                          : filterState.hotel_name
                      }
                      placeholder="Product Name"
                      name="hotel_name"
                      onChange={(e) => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /> */}
                  </Grid>
                  <Grid item sm={2} style={{ paddingTop: "0px" }}>
                    {/*       <TextField
                      required
                      placeholder="Country Code"
                      disabled={filterState.country_name ? true : false}
                      name="country"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    /> */}
                    <FormControl required className={classes.test1}>
                      <Autocomplete
                        options={countrieSelection}
                        onChange={(e, value) => {
                          filterHandleChange({
                            target: {
                              name: "country",
                              value: value?.code,
                              country_id: value?.id,
                            },
                          });
                        }}
                        value={
                          filterState.country
                            ? countrieSelection.find(
                                (country) => country.code == filterState.country
                              )
                            : null
                        }
                        getOptionLabel={(country) => country.name}
                        renderInput={(params) => (
                          <AppTextInput
                            {...params}
                            label={<IntlMessages id="Country" />}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2} style={{ paddingTop: "0px" }}>
                    {/*       <TextField
                      required
                      placeholder="City"
                      name="city"
                      onChange={e => {
                        filterHandleChange(e);
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    /> */}

                    <FormControl required className={classes.test1}>
                      <Autocomplete
                        value={filterState.city}
                        limitTags={1}
                        name="city"
                        /*  options={citiesObject.filter((cityCode) => {
                        return (cityCode.country_id == countryIdState)
                      })} */

                        options={
                          filteredSelectionCities ? filteredSelectionCities : []
                        }
                        onChange={(e, value) => {
                          filterHandleChange({
                            target: {
                              name: "city",
                              value: value,
                            },
                          });
                        }}
                        /*   getOptionLabel={
                        (city) =>
                          city.name
   
                      } */
                        renderInput={(params) => (
                          <AppTextInput
                            {...params}
                            label={<IntlMessages id="City" />}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => filterSelected()}
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        fontSize: "8px",
                      }}
                    >
                      Filter
                    </Button>
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      value={filterState.giata_id}
                      required
                      title="Inser minimum two characters"
                      placeholder="Giata Codes"
                      name="giata_id"
                      onChange={(e) => {
                        giataCodeData(e);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      variant="outlined"
                      disabled={
                        filterState.giata_id?.length <= 1 ? true : false
                      }
                      className={classes.button}
                      onClick={() => filterByGiataCode()}
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        fontSize: "8px",
                      }}
                    >
                      Only Giata
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <MaterialTable
                    tableRef={tableRef}
                    columns={tableColumns}
                    data={hotelData}
                    title=""
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30, 40],
                      actionsColumnIndex: -1,
                      tableLayout: "auto",
                      search: false,
                      addRowPosition: "first",
                      editCellStyle: "10px",
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },
                      exportDelimiter: ",",
                      exportFileName: "Hotel List",
                      exportAllData: true,
                      // exportCsv: (columns, data) => {
                      //   const headerRow = columns.map((col) => {
                      //     if (typeof col.title === "object") {
                      //       return col.title.props.text;
                      //     }
                      //     return col.title;
                      //   });
                      //   const dataRows = data?.map(({ tableData, ...row }) => [
                      //     changeForeignChars(row?.name),
                      //     row?.giata_id,
                      //     row?.country,
                      //     row?.city,
                      //   ]);
                      //   const {
                      //     exportDelimiter,
                      //   } = tableRef.current.props.options;
                      //   const delimiter = exportDelimiter
                      //     ? exportDelimiter
                      //     : ",";
                      //   const csvContent = [headerRow, ...dataRows]
                      //     .map((e) => e.join(delimiter))
                      //     .join("\n");
                      //   const csvFileName = tableRef.current.props.title;
                      //   downloadCsv(csvContent, csvFileName);
                      // },

                      exportCsv: (columns, data) => {
                        const headerRow = columns.map((col) => {
                          if (typeof col.title === "object") {
                            return col.title.props.text;
                          }
                          return col.title;
                        });
                        // for displaying default title of columns even if there is no data
                        let excelData = [
                          {
                            "Product Name": "",
                            "Product Type": "",
                            "Product Code": "",
                            Company: "",
                            "Tax No": "",
                            "Tax Office": "",
                            Continent: ",",
                            Country: "",
                            City: "",
                            Subcity: "",
                            District: "",
                            Phone: "",
                            Faks: "",
                            "E-mail": "",
                            Website: "",
                            "Star Rate": "",
                            "Giata Code": "",
                            "Airport Code": "",
                          },
                        ];
                        data = [...excelData, ...data];

                        const dataRows = data.map(({ tableData, ...row }) => {
                          return {
                            [headerRow[0]]: row?.name ?? "",
                            [headerRow[1]]: row?.product_type?.name ?? "",
                            [headerRow[2]]: row?.product_type?.code ?? "",
                            [headerRow[3]]: row?.company ?? "",
                            [headerRow[4]]: row?.tax_no ?? "",
                            [headerRow[5]]: row?.tax_office ?? "",
                            [headerRow[6]]: row?.region?.name ?? "",
                            [headerRow[7]]:
                              row?.country?.name?.toUpperCase() ?? "",
                            [headerRow[8]]:
                              row?.city?.name?.toUpperCase() ?? "",
                            [headerRow[9]]:
                              row?.sub_city?.name?.toUpperCase() ?? "",
                            [headerRow[10]]: row?.district ?? "",
                            [headerRow[11]]: row?.tel ?? "",
                            [headerRow[12]]: row?.fax ?? "",
                            [headerRow[13]]: row?.e_mail ?? "",
                            [headerRow[14]]: row?.web_page ?? "",
                            [headerRow[15]]: row?.star_rate?.value ?? "",

                            [headerRow[16]]: row?.giata_code ?? "",

                            [headerRow[17]]: row?.airport_codes?.code ?? "",
                          };
                        });

                        const worksheet = utils.json_to_sheet(dataRows);

                        const newWorkBook = utils.book_new();
                        utils.book_append_sheet(
                          newWorkBook,
                          worksheet,
                          "SheetJS"
                        );
                        writeFile(newWorkBook, "Hotel List.xlsx");
                      },
                    }}
                  />
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
    </div>
  );
}
