import React, { useState } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useSelector } from "react-redux";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Box } from "@material-ui/core";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { red, green, teal, orange } from "@material-ui/core/colors";
import moment from "moment";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};
export default function CopyPeriods({
  selectedSeason,
  selectedPeriod,
  setSelectedPeriod,
  checkedPeriod,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "94%",
      height: "100%",
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(2),
    },
    test2: {
      margin: theme.spacing(2),
      float: "right",
    },
  }));
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const themePeriods = createTheme({
    palette: {
      primary: teal,
    },
  });
  const themeMui = createTheme({
    palette: {
      primary: orange,
    },
  });
  const classes = useStyles();
  const { periods } = useSelector(({ periods }) => periods);
  const [season_start_date, setSeason_start_date] = useState(
    selectedSeason.start_date
  );
  const [nextStartDate, setNextStartDate] = useState(new Date());
  const [lastPeriod, setLastPeriod] = useState(0);
  const [cancelableData, setCancelableData] = useState([]);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.delete === 1
  );

  // girilen inputun bir sonraki gününü döndürür
  const tomorrowDate = (my_date) => {
    let tomorrow_date = new Date();
    tomorrow_date = new Date(new Date(my_date).getTime() + 24 * 60 * 60 * 1000);
    tomorrow_date = `${tomorrow_date.getFullYear()}-${`0${tomorrow_date.getMonth() +
      1}`.slice(-2)}-${`0${tomorrow_date.getDate()}`.slice(-2)}`;
    setNextStartDate(tomorrow_date);
    return tomorrow_date;
  };
  //Change start date according to editable end date
  const controlDates = (newData, oldData) => {
    let newId = oldData.tableData.id;
    if (selectedPeriod.at(-1).tableData?.id > newId) {
      let editingRow = selectedPeriod.find(
        (item) => item.tableData.id === newId + 1
      );
      editingRow.start_date = tomorrowDate(newData.end_date);
      setSelectedPeriod([
        ...selectedPeriod.filter((x) => x.start_date !== editingRow.start_date),
        editingRow,
      ]);
    }
  };

  //For Invalid Date Control
  const controlInvalidDate = (date) => {
    let invalidFlag = true;
    if (date == "Invalid date" || date == undefined) {
      invalidFlag = false;
    }
    return invalidFlag;
  };
  const seasonDateControl = () => {
    let seasonFlag = true;
    if (
      moment(selectedSeason.start_date).isAfter(selectedSeason.end_date) ||
      selectedSeason.start_date == "Invalid date" ||
      selectedSeason.end_date == "Invalid date"
    ) {
      seasonFlag = false;
    }
    return seasonFlag;
  };
  const intl = useIntl();

  const handleCancel = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage({
        id: "you.will.not.be.able.to.revert.this.action",
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" }),
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setOpen(!open);

        let containerSelectedPeriod = [];
        selectedPeriod.forEach((selectedItem) => {
          if (
            cancelableData?.every(
              (cancelableItem) =>
                cancelableItem?.tableData?.id !== selectedItem?.tableData?.id
            )
          ) {
            containerSelectedPeriod.push(selectedItem);
          }
        });
        setSelectedPeriod(containerSelectedPeriod);

        setCancelableData([]);
      }
    });
  };
  const validateValue = (rowData) => {
    let valueFlag = true;
    if (rowData < 0) {
      valueFlag = false;
      return "small";
    }
    return valueFlag;
  };
  const columns = [
    {
      title: <IntlMessages id="period" />,
      field: "code",
      validate: (rowData) => (rowData.code ? true : false),
      editComponent: (props) => {
        props.rowData.code = selectedPeriod.length == 0 ? "A" : props.value;
        return (
          <TextField
            id="code"
            value={selectedPeriod.length == 0 ? "A" : props.value}
            error={props.value ? false : true}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (!props.rowData.hasOwnProperty("id")) {
                let a = Object.values(selectedPeriod).filter(
                  (item) =>
                    item?.tableData?.id !== props.rowData?.id &&
                    item.code == props.value.toUpperCase()
                );
                if (a && a.length > 0) {
                  props.rowData.release = a[0].release;
                  props.rowData.min_stay = a[0].min_stay;
                } else {
                  props.rowData.release = "";
                  props.rowData.min_stay = "";
                }
              }
            }}
          />
        );
      },
    },
    {
      title: <IntlMessages id="start.date" />,
      field: "start_date",
      type: "date",
      aling: "center",
      render: (rowData) => {
        return moment(rowData.start_date).format("DD/MM/YYYY");
      },
      validate: (rowData) =>
        !controlInvalidDate(rowData.start_date) ? false : true,
      editComponent: (props) => (
        (props.rowData.start_date = props.rowData.start_date
          ? props.rowData.start_date
          : selectedPeriod.length !== 0
          ? tomorrowDate(selectedPeriod[selectedPeriod.length - 1]?.end_date)
          : selectedSeason.start_date),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              name="start_date"
              format="dd/MM/yyyy"
              autoOk="true"
              value={props.rowData.start_date}
              onChange={(date) => props.onChange(date._d.toLocaleDateString())}
              minDate={
                selectedPeriod.length
                  ? props.rowData?.tableData
                    ? props.rowData?.tableData?.id == 0
                      ? season_start_date
                      : periods[props.rowData?.tableData?.id - 1]?.start_date
                    : parseInt(lastPeriod) !== 0
                    ? tomorrowDate(
                        periods[lastPeriod] ? periods[lastPeriod].end_date : ""
                      )
                    : season_start_date
                  : season_start_date
              }
              InputLabelProps={{
                shrink: true,
              }}
              readOnly={true}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
    {
      title: <IntlMessages id="end_date">End Date</IntlMessages>,
      field: "end_date",
      type: "date",
      aling: "center",
      render: (rowData) => {
        return moment(rowData.end_date).format("DD/MM/YYYY");
      },
      validate: (rowData) =>
        !controlInvalidDate(rowData.end_date) ? false : true,
      editComponent: (props) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="end_date"
            format="dd/MM/yyyy"
            autoOk="true"
            value={
              props.value
                ? moment(props.value).format("YYYY-MM-DD")
                : moment(props.rowData.start_date).format("YYYY-MM-DD")
            }
            onChange={(date) =>
              props.onChange(moment(date).format("YYYY-MM-DD"))
            }
            minDate={props.rowData.start_date}
            maxDate={
              selectedPeriod.length
                ? selectedPeriod.at(-1).tableData?.id >
                  props.rowData?.tableData?.id
                  ? selectedPeriod[props.rowData?.tableData?.id]?.end_date
                  : selectedSeason.end_date
                : selectedSeason.end_date
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: <IntlMessages id="type"> Type </IntlMessages>,
      field: "type",
      type: "numeric",
      lookup: { 1: "Arrival", 2: "Stay" },
      aling: "center",
      validate: (rowData) => !!(rowData.type || rowData.type >= 0),
    },
    {
      title: <IntlMessages id="release"> Release </IntlMessages>,
      field: "release",
      type: "numeric",
      validate: (rowData) => validateValue(rowData.release),
      editComponent: (props) => {
        return (
          <TextField
            id="release"
            type={"number"}
            inputProps={{ min: 0 }}
            value={props.value}
            error={props.value ? false : true}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        );
      },
    },
    {
      title: <IntlMessages id="min_stay">Min Stay</IntlMessages>,
      field: "min_stay",
      type: "numeric",
      aling: "center",
      validate: (rowData) => validateValue(rowData.min_stay),
      editComponent: (props) => {
        return (
          <TextField
            id="mins_stay"
            type={"number"}
            inputProps={{ min: 0 }}
            value={props.value}
            error={props.value ? false : true}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        );
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setCancelableData([]);
  };
  return (
    <Box>
      <ThemeProvider theme={themePeriods}>
        <Button
          variant="contained"
          disabled={
            checkedPeriod ||
            selectedSeason.season_id == "" ||
            selectedSeason.season_id == undefined
          }
          onClick={handleClickOpen}
          color="primary"
        >
          <IntlMessages id="PERIODS" />
        </Button>
      </ThemeProvider>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onBackdropClick="false"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {" "}
          <IntlMessages id="periods" />{" "}
        </DialogTitle>
        <div className={classes.root}>
          <MaterialTable
            title=""
            columns={columns}
            data={selectedPeriod}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 40],
              search: false,
              actionsColumnIndex: -1,
              addRowPosition: "first",
            }}
            icons={{
              Add: (props) => (
                <ThemeProvider theme={themeMui}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxIcon />}
                  >
                    <IntlMessages id="add" />
                  </Button>
                </ThemeProvider>
              ),

              Check: () => <ModeCheckIcon style={{ color: "green" }} />,
              Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
            }}
            actions={[
              isAuthAdd
                ? undefined
                : {
                    icon: "add",
                    disabled: true,
                    position: "toolbar",
                    tooltip: "You are not authorized",
                  },
            ]}
            components={{
              OverlayLoading: () => <Loader />,
              EditRow: (props) => (
                <MTableEditRow
                  {...props}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === 27 ||
                      e.keyCode === 109 ||
                      e.keyCode === 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onEditingCanceled={(mode, rowData) => {
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        if (mode == "update") {
                          Swal.fire({
                            title: intl.formatMessage({ id: "are.you.sure?" }),
                            text: intl.formatMessage({
                              id: "do.you.want.to.cancel.the.changes",
                            }),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#41C329",
                            allowOutsideClick: false,
                            cancelButtonColor: "#d33",
                            confirmButtonText: intl.formatMessage({
                              id: "yes",
                            }),
                            cancelButtonText: intl.formatMessage({ id: "no" }),
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props.onEditingCanceled(mode);
                              resolve();
                            } else if (result.isDenied) {
                              reject();
                            }
                          });
                        }
                        if (mode == "add") {
                          Swal.fire({
                            title: intl.formatMessage({ id: "are.you.sure?" }),
                            text: intl.formatMessage({
                              id: "do.you.want.to.cancel.the.changes",
                            }),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#41C329",
                            allowOutsideClick: false,
                            cancelButtonColor: "#d33",
                            confirmButtonText: intl.formatMessage({
                              id: "yes",
                            }),
                            cancelButtonText: intl.formatMessage({ id: "no" }),
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props.onEditingCanceled(mode, rowData);
                              resolve();
                            } else if (result.isDenied) {
                              reject();
                            }
                          });
                        }
                        if (mode == "delete") {
                          props.onEditingCanceled(mode, rowData);
                        }
                      });
                    });
                  }}
                />
              ),
              onRowAdd: (props) => (
                <MTableEditRow
                  {...props}
                  onKeyDown={(e) => {
                    if (
                      e.keyCode === 27 ||
                      e.keyCode === 109 ||
                      e.keyCode === 189
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              ),
            }}
            editable={{
              deleteTooltip: (row) =>
                isAuthDelete
                  ? intl.formatMessage({ id: "delete" })
                  : intl.formatMessage({ id: "you.are.not.authorized" }),
              editTooltip: (row) =>
                isAuthUpdate
                  ? intl.formatMessage({ id: "edit" })
                  : intl.formatMessage({ id: "you.are.not.authorized" }),
              isDeletable: (rowData) =>
                isAuthDelete
                  ? rowData.hasOwnProperty("tableData") ??
                    rowData.tableData?.id == selectedPeriod.at(-1).tableData?.id
                    ? true
                    : false
                  : false,

              isEditable: (row) => (isAuthUpdate ? true : false),
              onRowAdd: isAuthAdd
                ? (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        newData.code = newData.code.toUpperCase();
                        if (
                          newData.code &&
                          newData.start_date &&
                          newData.end_date &&
                          newData.type &&
                          newData.release &&
                          newData.min_stay
                        ) {
                          if (
                            moment(newData.start_date).isAfter(newData.end_date)
                          ) {
                            sweetAlerts(
                              "error",
                              intl.formatMessage({ id: "dates.are.invalid" })
                            );
                            reject();
                          } else {
                            newData.code = newData.code?.toUpperCase();
                            //
                            setSelectedPeriod([...selectedPeriod, newData]);
                            setCancelableData([...cancelableData, newData]);
                            resolve();
                          }
                        } else {
                          sweetAlerts(
                            "error",
                            intl.formatMessage({
                              id: "please.fill.in.all.fields",
                            })
                          );
                          reject();
                        }
                      }, 1000);
                    })
                : undefined,
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (
                      newData.code &&
                      newData.start_date &&
                      newData.end_date &&
                      newData.type &&
                      newData.release &&
                      newData.min_stay
                    ) {
                      if (
                        moment(newData.start_date).isAfter(newData.end_date)
                      ) {
                        sweetAlerts(
                          "error",
                          intl.formatMessage({ id: "dates.are.invalid" })
                        );
                        reject();
                      } else {
                        controlDates(newData, oldData);
                        newData.code = newData.code.toUpperCase();
                        let newP = selectedPeriod.findIndex(
                          (element) =>
                            oldData.tableData.id == element.tableData.id
                        );
                        let newState = [...selectedPeriod];
                        newState[newP] = newData;
                        setSelectedPeriod(newState);
                        setCancelableData(newState);
                        resolve();
                      }
                    } else {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({ id: "please.fill.in.all.fields" })
                      );
                      reject();
                    }
                  }, 1000);
                }),

              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    let newState = [...selectedPeriod];
                    let newP = selectedPeriod.filter(
                      (element) => oldData.tableData.id != element.tableData.id
                    );
                    newState = newP;
                    setSelectedPeriod(newState);
                    resolve();
                  }, 1000);
                }),
            }}
          />
          <DialogActions>
            <ThemeProvider theme={themeCancel}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                startIcon={<SaveIcon />}
              >
                {intl.formatMessage({ id: "save" })}
              </Button>
            </ThemeProvider>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
}
export const MemoizedContractCopyPeriod = React.memo(CopyPeriods);
