// import axios from "axios";

// export let loggerEndpoint = "http://localhost:3030/proxolab/api/v1/";

// export default axios.create({
//     baseURL: "http://localhost:3030/",
//     headers: {
//         "Content-Type": "application/json",
//     },
// });



// live and stage logger


import axios from "axios";

export let loggerEndpoint = "https://bot.proxolab.com:82/proxolab/api/v1/";

export default axios.create({
    baseURL: "https://bot.proxolab.com:82/",
    headers: {
        "Content-Type": "application/json",
    },
});

