import React, { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { useSelector } from "react-redux";
import moment from "moment";
import MonthView from "./MonthView";

const monthMatch = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const statusMatch = {
  Draft: "1",
  Revised: "2",
  Signed: "3",
  OnSale: "4",
  Confirm: "5",
};

export default function ContractGraph() {
  const { contracts } = useSelector(({ contract }) => contract);
  const [monthContracts, setMonthContracts] = useState({});

  const graphDataCreator = () => {
    let newContractData = {};
    for (let index = 1; index <= 12; index++) {
      newContractData = {
        ...newContractData,
        [index]: {
          total: 0,
          "1": 0,
          "2": 0,
          "3": 0,
          "4": 0,
          "5": 0,
        },
      };
    }
    Object.values(contracts).map((contract) => {
      newContractData[moment(contract.created_date).format("M")]["total"] =
        newContractData[moment(contract.created_date).format("M")]["total"] + 1;

      if (contract.contract_statuses_id == 1) {
        newContractData[moment(contract.created_date).format("M")]["1"] =
          newContractData[moment(contract.created_date).format("M")]["1"] + 1;
      } else if (contract.contract_statuses_id == 2) {
        newContractData[moment(contract.confirm_date).format("M")]["2"] =
          newContractData[moment(contract.confirm_date).format("M")]["2"] + 1;
      } else if (contract.contract_statuses_id == 3) {
        newContractData[moment(contract.signed_date).format("M")]["3"] =
          newContractData[moment(contract.signed_date).format("M")]["3"] + 1;
      } else if (contract.contract_statuses_id == 4) {
        newContractData[moment(contract.published_date).format("M")]["4"] =
          newContractData[moment(contract.published_date).format("M")]["4"] + 1;
      } else if (contract.contract_statuses_id == 5) {
        newContractData[moment(contract.updated_at.split("T")[0]).format("M")][
          "5"
        ] =
          newContractData[
            moment(contract.updated_at.split("T")[0]).format("M")
          ]["5"] + 1;
      }
    });
    let lastGraphData = [];
    Object.keys(newContractData).forEach((month) => {
      lastGraphData.push({
        name: monthMatch[month],
        Total: newContractData[month].total,
        Draft: newContractData[month][statusMatch.Draft],
        Revised: newContractData[month][statusMatch.Revised],
        Signed: newContractData[month][statusMatch.Signed],
        OnSale: newContractData[month][statusMatch.OnSale],
        Confirm: newContractData[month][statusMatch.Confirm],
      });
    });
    setMonthContracts(lastGraphData);
  };
  useEffect(() => {
    graphDataCreator();
  }, [contracts]);

  return (
    <>
      <ResponsiveContainer width={1000} height={150}>
        <AreaChart
          data={monthContracts}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Tooltip
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "#0795F4" }}
            labelFormatter={function(value) {
              return `Month: ${value}`;
            }}
            cursor={false}
          />

          <XAxis dataKey="name" hide />
          <Area
            type="monotone"
            dataKey="Total"
            stackId="1"
            stroke="#246e89"
            fillOpacity={10}
            fill="#246e89"
          />
          <Area
            type="monotone"
            dataKey="Draft"
            stackId="1"
            stroke="#388ea3"
            fillOpacity={10}
            fill="#388ea3"
          />
          <Area
            type="monotone"
            dataKey="OnSale"
            stackId="4"
            stroke="#55afc0"
            fillOpacity={10}
            fill="#55afc0"
          />
          <Area
            type="monotone"
            dataKey="Revised"
            stackId="2"
            stroke="#91d1d3"
            fillOpacity={10}
            fill="#91d1d3"
          />
          <Area
            type="monotone"
            dataKey="Signed"
            stackId="3"
            stroke="#bae7e0"
            fillOpacity={10}
            fill="#bae7e0"
          />
          <Area
            type="monotone"
            dataKey="Confirm"
            stackId="5"
            stroke="rgb(205 235 230)"
            fillOpacity={10}
            fill="rgb(205 235 230)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div style={{ display: "flex" }}>
        <MonthView></MonthView>
      </div>
    </>
  );
}
