import { Table, Typography } from "antd";
import React from "react";

const columns = [
  {
    dataIndex: "country",
  },
];
export const MeetpointExcelSelectInputsForInvalidCountries = ({
  markets,
  excelCountries,
}) => {
  const plainOptions = excelCountries.reduce((acc, market) => {
    // get market countries if it's in excelCountries
    let index = 0;
    // add market countries to acc if it's not already in acc

    if (!acc.includes(market)) {
      acc.push({ key: index, country: market.name });
      index = index + 1;
    }

    return acc;
  }, []);

  return (
    <>
      <Typography
        style={{
          marginTop: "10px",
          borderBottom: "1px solid #A3A3A3",
        }}
      >
        Invalid Countries
      </Typography>
      <div>
        <Table
          columns={columns}
          dataSource={plainOptions}
          pagination={{
            position: ["bottomCenter"],
            size: "medium",
            defaultPageSize: 5,
          }}
          size="small"
        />
      </div>
    </>
  );
};
