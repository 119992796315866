import {random} from "lodash";
import axios from "../../services/axios";
import axiosBot from "../../services/axiosBot";
import {apihost} from "../../services/axiosBot";
import {goDomain} from "../../services/axiosBot";
import axios2 from "axios";

export const saveOperatorCredentials = (
  operatorCredentials,
  setOpen,
  setResponseStatus,
  setLoadingSave,
  setBookingListDisable,
  setLastMinuteErrorMessage
) => {
  //GET OPERATOR URL AND ID FROM NAME FOR DYNAMIC AXIOS
  let operatorName = operatorCredentials.operator_name;
  let operatorURL = operatorName.toLowerCase().replace(/\s/g, "");
  let operatorID = operatorCredentials.operator_id;
  /*****************************************************/

  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (operatorCredentials.operator_id == 1) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: "1",
      operator_name: operatorCredentials.operator_name
    };
    axios
      .post("operator/credentials/1", botPayload)
      .catch(err => {
        err.response.data.message === "Cookie is invalid" &&
          window.open(
            "https://www.peakpoint.travel/Extranet/alojamiento/",
            "_blank"
          );
        operatorCredentials["isCredentialCorrect"] = false;
      })
      .then(res => {
        operatorCredentials["isCredentialCorrect"] = true;
        let botPayload2 = {
          operator_id: operatorCredentials.operator_id.toString(),
          username: operatorCredentials.user_name,
          password: operatorCredentials.password,
          token: token
        };
        axios2
          .post(goDomain + "/api/infobot", JSON.stringify(botPayload2))
          .then(function(response){
            console.log(response);
          });
        setBookingListDisable(false);
      });
  } else if (operatorCredentials.operator_id == 15) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: "15",
      operator_name: operatorCredentials.operator_name
    };
    axios
      .post("operator/credentials/15", botPayload)
      .catch(err => {
        err.response.data.message === "Cookie is invalid" &&
          window.open(
            "https://extranet.w2m.travel/extranet/alojamiento/",
            "_blank"
          );
        operatorCredentials["isCredentialCorrect"] = false;
      })
      .then(res => {
        operatorCredentials["isCredentialCorrect"] = true;
        let botPayload2 = {
          operator_id: operatorCredentials.operator_id.toString(),
          username: operatorCredentials.user_name,
          password: operatorCredentials.password,
          token: token
        };
        axios2
          .post(goDomain + "/api/infobot", JSON.stringify(botPayload2))
          .then(function(response){
            console.log(response);
          });
        setBookingListDisable(false);
      });
  } else if (operatorCredentials.operator_id == 16) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: "16",
      operator_name: operatorCredentials.operator_name
    };
    axios
      .post("operator/credentials/16", botPayload)
      .catch(err => {
        err.response.data.message === "Cookie is invalid" &&
          window.open(
            "https://www.bedsopia.com/extranet/alojamiento/",
            "_blank"
          );
        operatorCredentials["isCredentialCorrect"] = false;
      })
      .then(res => {
        operatorCredentials["isCredentialCorrect"] = true;
        let botPayload2 = {
          operator_id: operatorCredentials.operator_id.toString(),
          username: operatorCredentials.user_name,
          password: operatorCredentials.password,
          token: token
        };
        axios2
          .post(goDomain + "/api/infobot", JSON.stringify(botPayload2))
          .then(function(response){
            console.log(response);
          });
        setBookingListDisable(false);
      });
  } else if (operatorCredentials.operator_id == 2) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: "2",
      operator_name: operatorCredentials.operator_name
    };
    const sunHotelFunc = () => {
      axios({
        method: "POST",
        url: `operator/credentials/${operatorCredentials.operator_id}`,
        data: botPayload
      })
        .then(res => {
          if (res.status == 200) {
            setResponseStatus("success");
            setBookingListDisable(false);
            setLoadingSave(false);
          } else if (res.status == 500) {
            setResponseStatus("error");
          }
        })
        .catch(function(error){});
    };

    axiosBot({
      method: "POST",
      url: "bot/2/sunhotelcred",
      data: botPayload
    })
      .then(res => {
        console.log({res});
        if (res.data.code == 200) {
          operatorCredentials["isCredentialCorrect"] = true;
          setResponseStatus("success");
          setOpen(true);
          setLoadingSave(false);

          sunHotelFunc();
        } else if (res.status == 500) {
          operatorCredentials["isCredentialCorrect"] = false;
          setResponseStatus("error");
        } else {
          setOpen(true);
          setLoadingSave(false);
          setBookingListDisable(true);
          operatorCredentials["isCredentialCorrect"] = false;
          setResponseStatus("error");
        }
        runBot(operatorCredentials);
      })
      .catch(function(error){});
  } else if (
    operatorCredentials.operator_id == 9 ||
    operatorCredentials.operator_id == 10 ||
    operatorCredentials.operator_id == 12
  ) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: operatorCredentials.operator_id,
      operator_name: operatorCredentials.operator_name
    };
    const operFunc = () => {
      axios({
        method: "POST",
        url: `operator/credentials/${operatorCredentials.operator_id}`,
        data: botPayload
      })
        .then(res => {
          if (res.status == 200) {
            setResponseStatus("success");
            setBookingListDisable(false);
            setLoadingSave(false);
          } else if (res.status == 500) {
            setResponseStatus("error");
          }
        })
        .catch(function(error){});
    };
    let credName = "";
    if (operatorID == 9) {
      credName = "jumboCred";
    } else if (operatorID == 10) {
      credName = "lastMinuteCred";
    } else if (operatorID == 12) {
      credName = "ivectorCred";
    }
    axiosBot({
      method: "POST",
      url: `bot/${operatorID}/${credName}`,
      data: botPayload
    })
      .then(res => {
        if (res.data.code == 200) {
          setResponseStatus("success");
          setOpen(true);
          setLoadingSave(false);
          runBot(operatorCredentials);
          operFunc();
        } else if (res.status == 500) {
          setResponseStatus("error");
        } else {
          setOpen(true);
          setLoadingSave(false);
          setBookingListDisable(false);
          setResponseStatus("error");
          if (
            res.data.message.startsWith(
              "Exceeded number of concurrent sessions"
            )
          ) {
            setLastMinuteErrorMessage("Max number of sessions reached! ");
          }
        }
      })
      .catch(function(error){});
  } else {
    axios
      .post(
        `operator/credentials/${operatorCredentials.operator_id}`,
        operatorCredentials
      )
      .then(res => {
        setOpen(true);
        setLoadingSave(false);
        setResponseStatus(res.data.status);
        operatorCredentials["isCredentialCorrect"] =
          res.data.status == "success" ? true : false;
        runBot(operatorCredentials);
        if (res.data.status === "success") {
          setBookingListDisable(false);
        }
      });
  }
};

export const runBot = operatorCredentials => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  let botPayload = {
    username: operatorCredentials.user_name,
    password: operatorCredentials.password,
    endpoint: apihost,
    data_id: random(20 * 20 * 14, 20 * 52 * 87),
    pxoUser: operatorCredentials.pxoUser,
    isCredentialCorrect: operatorCredentials["isCredentialCorrect"],
    operator_name: operatorCredentials.operator_name
  };
  axiosBot({
    method: "POST",
    // url: rq.session.bothost + '/bot/' + extranetId[data.extranet],
    url: "bot/" + operatorCredentials.operator_id,
    data: botPayload,
    headers: {
      Host: "127.0.0.1",
      Connection: "keep-alive"
    },
    timeout: 30000
  })
    .then(function(response){
      console.log(response);
    })
    .catch(function(error){});
};
