import { GET_ALLOTMENT_TYPE } from '../actions/types';

const initialState = {
  allotmentType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTMENT_TYPE:
      return { ...state, allotmentType: action.payload.allotmentTypes ? action.payload.allotmentTypes : [] };
    default:
      return state;
  }
};
