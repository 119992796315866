import { normalizeBoards } from 'redux/schemas/boards';
import { GET_BOARDS, ADD_BOARD, UPDATE_BOARD, DELETE_BOARD } from '../actions/types';

const initialState = {
  boards: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARDS:
      const data = [];
      data['boards'] = action.payload;
      const normalizedData = normalizeBoards([data]);
      const entities = normalizedData.entities;
      return {
        ...state,
        boards: entities.boards ? entities.boards : [],
      };
    case ADD_BOARD:
      const addData = [];
      addData['addBoard'] = [action.payload];
      addData['stateBoard'] = state.boards;
      const addNormalizedData = normalizeBoards([addData]);

      return {
        ...state,
        boards: addNormalizedData.entities.boards,
      };
    case UPDATE_BOARD:
      const updateData = [];
      updateData['updatedBoard'] = [action.payload];
      updateData['stateBoard'] = state.boards;
      const updateNormalizedData = normalizeBoards([updateData]);
      return {
        ...state,
        boards: updateNormalizedData.entities.boards,
      };
    case DELETE_BOARD:
      const deletedBoard = Object.keys(state.boards)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.boards[key];
          return obj;
        }, {});

      return {
        ...state,
        boards: deletedBoard,
      };
    default:
      return state;
  }
};
