import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getCurrencies } from 'redux/actions/currency';
import axios from './axios';

export const getCurrenciesService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('currency')
    .then(res => {
      dispatch(getCurrencies(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
