import { GET_HOTELS, ADD_HOTEL, UPDATE_HOTEL, DELETE_HOTEL, SELECTED_HOTEL } from '../actions/types';
import { normalizeHotels } from 'redux/schemas/hotels';

const initialState = {
  hotels: [],
  selectedHotel: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTELS:
      const data = [];
      data['hotels'] = action.payload;
      const normalizedData = normalizeHotels([data]);
      const entities = normalizedData.entities;
      return { ...state, hotels: entities.hotels ? entities.hotels : state.hotels };

    case ADD_HOTEL:
      const addData = [];
      addData['addHotels'] = [action.payload];
      addData['stateHotels'] = state.hotels;
      const addNormalizedData = normalizeHotels([addData]);
      return {
        ...state,
        hotels: addNormalizedData.entities.hotels ? addNormalizedData.entities.hotels : state.hotels,
        selectedHotel: [],
      };
    case UPDATE_HOTEL:
      const updatedData = [];
      updatedData['updatedHotels'] = [action.payload];
      updatedData['stateHotels'] = state.hotels;
      const updatedNormalizedData = normalizeHotels([updatedData]);
      const hotelId = updatedNormalizedData.result[0].updatedHotels[0];
      return {
        ...state,
        hotels: {
          ...state.hotels,
          [hotelId]: updatedNormalizedData.entities.hotels[hotelId],
        },
        selectedHotel: [],
      };
    case DELETE_HOTEL:
      const deletedHotel = Object.keys(state.hotels)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.hotels[key];
          return obj;
        }, {});

      return {
        ...state,
        hotels: deletedHotel,
        selectedHotel: [],
      };
    case SELECTED_HOTEL:
      return { ...state, selectedHotel: action.payload, func: 'update' };
    default:
      return state;
  }
};
