export const getSpecialConditions = (data) => {
  let filteredData = data.map((row) => {
    return Object.values(row);
  });

  filteredData = filteredData.filter((row) => row[0] !== "");
  const specialConditionsIndex = filteredData.findIndex((row, index) => {
    return row.some((cell, index) => {
      if (cell !== undefined && cell !== null) {
        return typeof cell === "string"
          ? cell.replaceAll(" ", "") === "SPECIALCONDITIONS&NOTES"
          : false;
      }
    });
  });
  if (specialConditionsIndex === -1) return null;
  const getMeetingPointIndex = filteredData.findIndex((row, index) => {
    return row.some((cell, index) => {
      if (cell !== undefined && cell !== null) {
        return typeof cell === "string"
          ? cell.replaceAll(" ", "") === "MEETINGPOINTTURKEY"
          : false;
      }
    });
  });
  if (getMeetingPointIndex === -1) return null;

  let specialConditions = "";

  filteredData
    .filter((row, index) => {
      return (
        index > specialConditionsIndex &&
        row[1] !== undefined &&
        row[1] !== null &&
        row[1] !== "" &&
        index < getMeetingPointIndex
      );
    })
    .forEach((element) => {
      specialConditions += element[1] + "\n";
    });

  return specialConditions;
};
