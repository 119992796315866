import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hotelMailService, hotelResponseService } from "services/reservation";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReservationUpdate from "./ReservationUpdate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  logoSize: {
    marginTop: "30px",
    height: "70px"
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    marginRight: theme.spacing(2)
  },
  menuText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    textTransform: "none"
  },

  greenButton: {
    backgroundColor: "#4caf50",
    color: "#ffffff"
    // you can add your specific CSS here.
  },
  redButton: {
    backgroundColor: "#f44336",
    color: "#ffffff"
  },
  appBar: {
    position: "relative"
  }
}));

function ReservationActions(props) {
  const { actionDialog, handleClose, reservation } = props;
  const dispatch = useDispatch();
  const [responseDialog, setResponseDialog] = useState(false);
  const handleHotelMail = () => {
    dispatch(hotelMailService(reservation.id));
    reservation.hotel_status = 2;
    reservation.communication_status = 2;
    MySwal.fire({
      title: "Email sent!",
      text: "The hotel has been notified.",
      icon: "success",
      confirmButtonText: "OK"
    });
  };
  const classes = useStyles();
  const handleResponseDialog = () => {
    setResponseDialog(true);
  };

  const handleResponseDialogClose = () => {
    setResponseDialog(false);
  };

  const handleHotelResponse = hotel_response => {
    dispatch(hotelResponseService(hotel_response, reservation.id));
    reservation.hotel_status = hotel_response;
    handleResponseDialogClose();
    MySwal.fire({
      title: "Response saved!",
      icon: "success",
      confirmButtonText: "OK"
    });
  };

  useEffect(() => {}, [
    reservation?.hotel_status,
    reservation?.booking_status,
    reservation?.communication_status
  ]);
  return (
    <div>
      <Dialog
        open={responseDialog}
        onClose={handleResponseDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hotel Response"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Press the Hotel's Reply
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleHotelResponse(3)}>Canceled</Button>
          <Button onClick={() => handleHotelResponse(4)} autoFocus>
            Confirmed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen open={actionDialog} TransitionComponent={Transition}>
        <Grid container>
          <Grid item xs={12}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h2"
                  component="div"
                  style={{ fontWeight: 600 }}
                >
                  Reservation Actions
                </Typography>
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <ReservationUpdate
              reservation={reservation}
              handleActionClose={handleClose}
              handleHotelMail={handleHotelMail}
              handleResponseDialog={handleResponseDialog}
              handleClose={handleClose}
              type={props.type}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

export default ReservationActions;
