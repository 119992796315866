import { normalize, schema } from 'normalizr';

const room_types = new schema.Entity('room_types');

const boardSupplaments = new schema.Entity(
  'boardSupplaments',
  { room_types: [room_types] },
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);
const reduction = new schema.Entity(
  'reduction',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  boardSupplaments: [boardSupplaments],
  addBoardSupplament: [boardSupplaments],
  stateBoardSupplament: [boardSupplaments],
  updateBoardSupplament: [boardSupplaments],
  reduction: [reduction],
  addReduction: [reduction],
  stateReduction: [reduction],
  updateReduction: [reduction],
};
export const normalizeBoardSupplaments = data => normalize(data, [response]);
