import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getOnSaleContract,
  addOnSaleContract
} from "redux/actions/onSaleContract";
import axios from "./axios";
import { apihost } from "./axiosBot";
import axiosBot from "./axiosBot";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getOnSaleContractService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  async function getOnSale() {
    await axios
      .get("onSaleContract")
      .then(res => {
        dispatch(getOnSaleContract(res));
        dispatch(fetchSuccess());
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  }
  return getOnSale();
};
export const addOnSaleContractService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("onSaleContract", data)
    .then(res => {
      dispatch(addOnSaleContract(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const onSaleContractBotService = data => async dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  /* axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`; */
  dispatch(fetchStart());
  data["endpoint"] = apihost /* axiosBot.defaults.baseURL */;
  data["botEndpoint"] = axiosBot.defaults.baseURL;
  return await axios
    .post("sendContractData", data, { timeout: 2400000 })
    .then(res => {
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
      return res.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
