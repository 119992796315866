import {
  GET_ROOM_MATCH,
  ADD_ROOM_MATCH,
  UPDATE_ROOM_MATCH,
  DELETE_ROOM_MATCH,
  UPDATE_OPERATOR_ROOMS
} from "../../redux/actions/types";

export const getRoomMatch = res => dispatch => {
  dispatch({
    type: GET_ROOM_MATCH,
    payload: res.data.data
  });
};
export const addRoomMatch = res => dispatch => {
  res.data.data["key"] = "add";
  dispatch({
    type: UPDATE_ROOM_MATCH,
    payload: res.data.data
  });
  dispatch({
    type: UPDATE_OPERATOR_ROOMS,
    payload: res.data.data
  });
};
export const updateRoomMatch = res => dispatch => {
  dispatch({
    type: UPDATE_ROOM_MATCH,
    payload: res.data.data
  });
};
export const deleteRoomMatch = res => dispatch => {
  res["key"] = "delete";
  dispatch({
    type: UPDATE_ROOM_MATCH,
    payload: res
  });
  dispatch({
    type: UPDATE_OPERATOR_ROOMS,
    payload: res
  });
};
