import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import {
  /* getOffices, */ addOffice,
  updateOffice,
  deleteOffice,
  destinationCountries,
  destinationCities,
  destinationDivisions,
  addDestination,
  updateDestination,
  fetchCompanyError,
  fetchCompanySuccess
} from "../redux/actions/CompanyHasOffice";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

/* export const getOfficesService = () => dispatch => {
  dispatch(fetchStart());
  return axios
    .get('offices')
    .then(({ data }) => {
      dispatch(getOffices(data.data));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
}; */

export const addOfficeService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("office", data)
    .then(res => {
      dispatch(addOffice(res));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendOfficeLog(res, data);
    })
    .catch(error => {
      dispatch(fetchError(error.response.data));
      dispatch(fetchCompanyError(error.response.data));
      sendLogService.errorlog(error, data);
    });
};

export const updateAdminOfficeService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("office", data)
    .then(res => {
      dispatch(updateOffice(res));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendOfficeLog(res, data);
    })
    .catch(error => {
      dispatch(fetchError(error.response.data));
      dispatch(fetchCompanyError(error.response.data));
      sendLogService.errorlog(error, data);
    });
};

export const deleteAdminOfficeService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`office/${data.id}`)
    .then(res => {
      dispatch(deleteOffice(res));
      dispatch(fetchSuccess());
      sendLogService.sendOfficeLog(res, data);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const destinationCountry = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("selectedCountryList", { array: data })
    .then(res => {
      dispatch(destinationCountries(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const destinationDivision = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("selectedDivisionList", { array: data })
    .then(res => {
      dispatch(destinationDivisions(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const destinationCity = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("selectedCityList", { array: data })
    .then(res => {
      dispatch(destinationCities(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const destinationDivisionCity = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("selectedDivisionCityList", { array: data })
    .then(res => {
      dispatch(destinationCities(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addOfficeDestination = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("addOfficeDestination", data)
    .then(res => {
      dispatch(addDestination(res));
      dispatch(fetchSuccess());
      let destinationData = destinationminimize(res.data.data);
      sendLogService.sendOfficeDestinationLog(res, destinationData);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateOfficeDestination = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("updateOfficeDestination", data)
    .then(res => {
      dispatch(updateDestination(res));
      dispatch(fetchSuccess());
      let destinationData = destinationminimize(res.data.data);
      sendLogService.sendOfficeDestinationLog(res, destinationData);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
const destinationminimize = data => {
  let jsondata = JSON.parse(data["destinations"]);
  let dataIds = {};
  jsondata.map(item => {
    dataIds[item.region_name] =
      dataIds[item.region_name] + "," + item.country_name;
  });

  return dataIds;
};
