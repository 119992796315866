import {
  GET_PAYMENT_CONDITIONS,
  ADD_PAYMENT_CONDITIONS,
  UPDATE_PAYMENT_CONDITIONS,
  DELETE_PAYMENT_CONDITIONS,
} from './types';

export const getPaymentConditions = res => dispatch => {
  dispatch({
    type: GET_PAYMENT_CONDITIONS,
    payload: res.data.data,
  });
};

export const addPaymentConditions = res => dispatch =>
  dispatch({
    type: ADD_PAYMENT_CONDITIONS,
    payload: res.data.data,
  });

export const updatePaymentConditions = res => dispatch =>
  dispatch({
    type: UPDATE_PAYMENT_CONDITIONS,
    payload: res.data.data,
  });

export const deletePaymentConditions = res => dispatch =>
  dispatch({
    type: DELETE_PAYMENT_CONDITIONS,
    payload: res.data.data,
  });
