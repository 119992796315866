import {
  GET_PRIORITY,
  ADD_PRIORITY,
  UPDATE_PRIORITY,
  DELETE_PRIORITY
} from "./types";
import { normalizePriority } from "redux/schemas/priority";

export const getPriority = res => dispatch => {
  const normalizedPriority = normalizePriority(res.data.data);
  dispatch({
    type: GET_PRIORITY,
    payload: normalizedPriority.entities
  });
};

export const addPriority = res => dispatch => {
  dispatch({
    type: ADD_PRIORITY,
    payload: res.data.data
  });
};

export const updatePriority = res => dispatch => {
  dispatch({
    type: UPDATE_PRIORITY,
    payload: res.data.data
  });
};

export const deletePriority = res => dispatch => {
  dispatch({
    type: DELETE_PRIORITY,
    payload: res.data.data
  });
};
