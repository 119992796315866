import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Grid,
  Button,
  Dialog,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getHotelService } from "services/hotels";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getRoomService } from "services/rooms";
import { getOperatorService } from "services/operator";
import "antd/dist/antd.css";
import { getOperatorRoomsService } from "services/operatorRooms";
import {
  addMatchRoomDataService,
  deleteMatchRoomDataService,
  getMatchRoomsDataService,
} from "services/roomMatch";
import Radio from "@mui/material/Radio";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MaterialTable, { MTableAction, MTableToolbar } from "material-table";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Loader from "../Loader";
import AddRoomType from "../AddRoomType";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { orange, red, green } from "@material-ui/core/colors";
import _ from "lodash";
import { getMatchHotelDataService } from "services/hotelmatch";
import { addMeetingPointExcelContractRoomService } from "services/contract";
import { useIntl } from "react-intl";
import { addOfferService } from "services/offers";
import { addMeetingPointAdditionalPaxService } from "services/additionalPax";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    color: localStorage.getItem("theme-type") === "dark" ? "#000" : "#fff",
    //textAlign: '-webkit-center',
    maxWidth: "100%",
    marginLeft: theme.spacing(15),
    marginRight: theme.spacing(15),
    fontSize: "12px!important",
  },
  gridStyle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "18%",
    maxWidth: "18%",
  },
  containerGridMTable: {
    marginLeft: theme.spacing(11),
    margin: theme.spacing(5),
    align: "center",
    maxWidth: "80rem",
    //minWidth: "80rem",
    maxHeight: "120rem",
    minHemaxHeight: "120rem",
  },
  globalRoomStyle: {
    border: "2px solid rgba(0, 0, 0, 0.05)",
    borderRadius: "7px",
    margin: theme.spacing(1),
    align: "center",
  },
  containerGrid: {
    margin: theme.spacing(5),
  },
  transfer: {
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "#000" : "#fff",

    marginBottom: theme.spacing(5),
    "&-body": {
      width: "100%",
      display: "flex",
      flex: "auto",
      flexDirection: "column",
      overflow: "hidden",
      "&-search-wrapper": {
        position: "relative",
        flex: "none",
      },
    },
    position: "relative",
    width: "auto",
    height: "auto",

    display: "flex",
    alignItems: "stretch",
  },
  textStyle: {
    width: "100%",
    height: "15px",
    textAlign: "center",
  },
  autoCompleteStyle: {
    width: "100%",
    height: "15px",
    textAlign: "center",
  },
  saveButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: "12px",
  },
  unmapButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#f44336",
    color: "#fff",
  },
  dataGridStyle: {
    marginLeft: theme.spacing(12),
  },

  dialogRoot: {
    "& .MuiDialog-paper": {
      padding: "40px 20px",
    },
  },
  dialogGrid: {
    marginTop: "20px",
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

export default function MeetingPointRoomTable({
  matchedRooms,
  roomInfo,
  roomMatchData,
  getEarlyBookingPayload,
  getSpecialDiscountOfferPayload,
  getAddPaxReductions,
  setShowRoomMatchPage,
  setSelectedRoomInfo,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHotelService());
    dispatch(getRoomService());
    dispatch(getOperatorService());
  }, [dispatch]);
  const history = useHistory();

  const { roomType } = useSelector(({ rooms }) => rooms.rooms);
  const { types } = useSelector(({ rooms }) => rooms.rooms);
  const { promos } = useSelector(({ rooms }) => rooms.rooms);
  const { subTypes } = useSelector(({ rooms }) => rooms.rooms);
  const { views } = useSelector(({ rooms }) => rooms.rooms);
  const { grades } = useSelector(({ rooms }) => rooms.rooms);
  const { bases } = useSelector(({ rooms }) => rooms.rooms);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operatorRooms } = useSelector(({ operatorRooms }) => operatorRooms);
  const { operators } = useSelector(({ operators }) => operators);
  const { selectedContract } = useSelector(({ contract }) => contract);

  const { SHhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { DNhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { matched_rooms } = useSelector(({ roomMatch }) => roomMatch);
  const { contracts, contract_room } = useSelector(({ contract }) => contract);

  const [selections, setSelections] = useState({});
  const [selectionRoom, setSelectionRoom] = useState();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [rooms_by_global, setRooms_by_global] = useState({});
  const [tempOperatorRooms, setTempOperatorRooms] = useState([]);
  const [tempRoomType, setTempRoomType] = useState([]);
  const [radiobutton, setradiobutton] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [flag, setFlag] = useState(false);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.delete === 1
  );
  const handleOpen = () => {
    setFlag(true);
  };
  const handleClose = () => {
    setFlag(false);
  };
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };
  let lastContractRoom = 0;
  let data = [];

  // contracts[selectedContract].contract_room.map((cr) => {
  //   data.push(contract_room[cr]);
  // });
  // lastContractRoom =
  //   contracts[selectedContract]?.contract_room[
  //     contracts[selectedContract]?.contract_room.length - 1
  //   ];

  const getRoomListData = () => {
    let baseRoomListData = [];

    roomMatchData.map((room) => {
      if (
        roomMatchData.filter((room) => room.global_room_name).length > 1 &&
        Object.values(baseRoomListData).find(
          (roomData) => roomData.room_name === room.global_room_name
        ) === undefined
      ) {
        baseRoomListData.push({
          room_name: room.global_room_name,
          base_room_name: "",
        });
      }
    });

    return baseRoomListData;
  };

  const getBaseRoomListData = () => {
    let baseRoomListData = getRoomListData().reduce((acc, room) => {
      const roomOptions = roomMatchData.filter(
        (matchedRoom) => matchedRoom.global_room_name === room.room_name
      );
      if (roomOptions.length > 0) {
        acc = {
          ...acc,
          [room.room_name]: roomOptions.map(
            (roomOption) => roomOption.operator_room_name
          ),
        };
      }
      return acc;
    }, {});
    return baseRoomListData;
  };

  const [baseRoomList, setBaseRoomList] = useState(getRoomListData());

  const [baseRoomOptions, setBaseRoomOptions] = useState(getBaseRoomListData());

  // for triggering re-render if the value is the same useEffect will not trigger
  const [hasRooms, setHasRooms] = useState(false);
  const hasOffers = useRef(false);

  useEffect(() => {
    const addOffers = async () => {
      if (hasRooms && hasOffers.current === false) {
        const earlyBookingPayload = getEarlyBookingPayload();
        const specialDiscount = getSpecialDiscountOfferPayload();
        if (earlyBookingPayload) {
          await earlyBookingPayload.forEach((element) => {
            dispatch(addOfferService(element)).then((res) => {
              hasOffers.current = true;
            });
          });
        }
        if (specialDiscount) {
          await specialDiscount.forEach((element) => {
            dispatch(addOfferService(element)).then((res) => {
              hasOffers.current = true;
            });
          });
        }
      }
    };
    const addPax = async () => {
      if (hasRooms) {
        const addPaxes = getAddPaxReductions();
        if (addPaxes) {
          await dispatch(
            addMeetingPointAdditionalPaxService(addPaxes)
          ).then((res) => {});
        }
      }
    };
    addOffers();
    addPax();
  }, [hasRooms]);

  useEffect(() => {
    Object.values(roomType).map((type) => {
      type["room_name"] =
        types[roomType[type.id].type_id].name +
        " " +
        grades[roomType[type.id].grade_id].name +
        " " +
        (roomType[type.id].base_id > 1
          ? bases[roomType[type.id].base_id].name + " "
          : "") +
        (roomType[type.id].view_id > 1
          ? views[roomType[type.id].view_id].name + " "
          : "") +
        (roomType[type.id].sub_type_id > 1
          ? subTypes[roomType[type.id].sub_type_id].name + " "
          : "") +
        (roomType[type.id].promo_id > 1
          ? promos[roomType[type.id].promo_id].name
          : "");

      type["base_room"] = "";
    });
    setTempRoomType(roomType);
  }, [roomType]);

  const classes = useStyles();
  const [roomList, setRoomList] = useState(roomMatchData);

  const columns = [
    {
      title: <IntlMessages id="room.name" />,
      field: "operator_room_name",
      align: "left",
      type: "string",
    },
    {
      title: <IntlMessages id="global.room.name" />,
      field: "global_room_name",
      type: "string",
      align: "left",
    },
  ];

  const handleChangeRadioButton = (event) => {
    setSelectionRoom(event);
  };

  const handleChangeOperatorRoom = (event) => {
    setTargetKeys(event);
  };

  const tableRef = React.useRef();

  function roomMatchFunc() {
    if (selectionRoom && targetKeys.length > 0) {
      if (tableRef.current) {
        tableRef.current.onAllSelected(false);
      }
      const matched_rooms = roomList.map((room) => {
        if (
          targetKeys.find(
            (targetKey) =>
              targetKey.operator_room_name === room.operator_room_name
          )
        ) {
          return {
            ...room,
            global_room_name: selectionRoom.room_name,
          };
        } else return room;
      });

      let baseRoomInfo = [...baseRoomList];

      if (matched_rooms.length > 0) {
        // check if multiple excel room matched with same global room
        const hasMultipleRoom =
          matched_rooms.filter(
            (room) =>
              room.global_room_name
                ?.trim()
                ?.replaceAll(" ", "")
                .toLowerCase() ===
              selectionRoom.room_name
                ?.trim()
                .replaceAll(" ", "")
                .toLowerCase()
          ).length > 1;
        const hasRoomListData = baseRoomList.some(
          (roomList) =>
            roomList.room_name
              ?.trim()
              ?.replaceAll(" ", "")
              .toLowerCase() ===
            selectionRoom.room_name
              ?.trim()
              .replaceAll(" ", "")
              .toLowerCase()
        );

        if (hasMultipleRoom && !hasRoomListData) {
          baseRoomInfo = [
            ...baseRoomList,
            { room_name: selectionRoom.room_name, base_room_name: "" },
          ];
        }
      }
      const getRoomOptions = baseRoomInfo.reduce((acc, room) => {
        const roomOptions = matched_rooms.filter(
          (matchedRoom) =>
            matchedRoom.global_room_name
              ?.trim()
              .replaceAll(" ", "")
              .toLowerCase() ===
            room.room_name
              .trim()
              .replaceAll(" ", "")
              .toLowerCase()
        );
        if (roomOptions.length > 0) {
          acc = {
            ...acc,
            [room.room_name]: roomOptions.map(
              (roomOption) => roomOption.operator_room_name
            ),
          };
        }
        return acc;
      }, {});

      // set base room options

      const filteredRoomList = baseRoomInfo.filter((room) =>
        Object.keys(getRoomOptions).includes(room.room_name)
      );
      setBaseRoomList(filteredRoomList);

      setBaseRoomOptions(getRoomOptions);
      setRoomList(matched_rooms);

      sweetAlerts("success", "Rooms Matched Successfully");
    } else {
      sweetAlerts("warning", "Please select Global Room and Target Room");
    }
  }

  const hasEveryRoomMatched = () => {
    const matchedRooms = roomList.every(
      (room) => room.global_room_name !== null
    );
    return matchedRooms;
  };

  const getMinPayerCount = (selectedRoomInfo) => {
    if (
      selectedRoomInfo["Max Adult"] == selectedRoomInfo["Max Child"] &&
      selectedRoomInfo["Max Adult"] == 1
    ) {
      return 1;
    } else {
      const addPaxCount = getAddPaxReductions();

      switch (selectedRoomInfo.roomCode) {
        case "PP":
          if (
            addPaxCount.normalReductions?.otherAdultReductions.hasOwnProperty(
              "A"
            ) &&
            addPaxCount.normalReductions?.otherAdultReductions["A"] !== ""
          ) {
            return selectedRoomInfo["Min Adult"];
          } else {
            return selectedRoomInfo["Min Adult"];
          }
        case "PR":
          if (
            addPaxCount.familyReductions?.otherAdultReductions.hasOwnProperty(
              "A"
            ) &&
            addPaxCount.familyReductions?.otherAdultReductions["A"] !== ""
          ) {
            return selectedRoomInfo["Min Adult"];
          } else {
            return selectedRoomInfo["Min Adult"];
          }
      }
    }
  };

  const changeOccupancy = (selectedRoomInfo, matchedRoomsWithBaseRoom) => {
    if (matchedRoomsWithBaseRoom.length === 0) {
      return selectedRoomInfo;
    }
    // get minimum person
    const minAdult = getMinValue("Min Adult", matchedRoomsWithBaseRoom);
    const minChild = getMinValue("Min Child", matchedRoomsWithBaseRoom);
    const minPax = getMinValue("Min Person", matchedRoomsWithBaseRoom);
    const maxAdult = getMaxValue("Max Adult", matchedRoomsWithBaseRoom);
    const maxChild = getMaxValue("Max Child", matchedRoomsWithBaseRoom);
    const maxPax = getMaxValue("Max Person", matchedRoomsWithBaseRoom);

    return {
      ...selectedRoomInfo,
      "Min Adult": minAdult,
      "Min Child": minChild,
      "Min Person": minPax,
      "Max Adult": maxAdult,
      "Max Child": maxChild,
      "Max Person": maxPax,
    };
  };

  const getMinValue = (key, matchedRoomsWithBaseRoom) => {
    const minValue = matchedRoomsWithBaseRoom.reduce((acc, room) => {
      if (room[key] < acc) {
        acc = room[key];
      }
      return acc;
    }, 100);
    return minValue;
  };

  const getMaxValue = (key, matchedRoomsWithBaseRoom) => {
    const maxValue = matchedRoomsWithBaseRoom.reduce((acc, room) => {
      if (room[key] > acc) {
        acc = room[key];
      }
      return acc;
    }, 0);
    return maxValue;
  };

  const saveSelectedRoomsasContractRooms = async () => {
    let selectedRoomInfos = [];

    let roomsWithNoInfo = "";
    const selectedBaseRoomList = baseRoomList.filter(
      (room) => room.base_room_name !== ""
    );
    // filter matched rooms by base room list
    const selectedAndMatchedRooms = roomList.reduce((acc, room) => {
      const hasBaseRoom = selectedBaseRoomList.some(
        (baseRoom) => baseRoom.room_name === room.global_room_name
      );

      if (room.global_room_name !== null && !hasBaseRoom) {
        acc = [...acc, room];
      } else if (room.global_room_name !== null && hasBaseRoom) {
        const baseRoom = selectedBaseRoomList.find(
          (baseRoom) => baseRoom.room_name === room.global_room_name
        );
        if (baseRoom.base_room_name === room.operator_room_name) {
          acc = [...acc, room];
        }
      }
      return acc;
    }, []);

    selectedAndMatchedRooms.map((selectedRoom, index) => {
      let selectedRoomInfo = roomInfo.current.find(
        (room) => room.roomName === selectedRoom.operator_room_name
      );

      let selectedRoomType = Object.values(roomType).find(
        (room) =>
          selectedRoom.global_room_name
            .trim()
            .replaceAll(" ", "")
            .toLowerCase() ===
          room.room_name
            .trim()
            .replaceAll(" ", "")
            .toLowerCase()
      );
      if (selectedRoomInfo !== undefined) {
        const splittedName = selectedRoomInfo.roomName.split("//");
        selectedRoomInfo["agency_room_name"] =
          splittedName[0].trim() + "//" + splittedName[1].trim();
        selectedRoomInfo["product_room_name"] = splittedName[2].trim();
        selectedRoomInfo["reference_rate_id"] = index + 1;

        const matchedRoomsWithBaseRoom = roomList.filter(
          (r) => r.global_room_name === selectedRoom.global_room_name
        );

        const matchedRoomInfo = roomInfo.current.filter((room) =>
          matchedRoomsWithBaseRoom.find(
            (r) => r.operator_room_name === room.roomName
          )
        );
        const newRoomOccupancyInfo = changeOccupancy(
          selectedRoomInfo,
          matchedRoomInfo
        );
        newRoomOccupancyInfo["min_payer"] = getMinPayerCount(
          newRoomOccupancyInfo
        );

        selectedRoomInfos.push({
          ...selectedRoom,
          ...newRoomOccupancyInfo,
          ...selectedRoomType,
        });
      }
    });

    selectedRoomInfos.forEach((room) => {
      if (room.hasOwnProperty("Base Room for Allotment")) {
        const baseRoom = selectedRoomInfos.find((r) =>
          r.operator_room_name.includes(room["Base Room for Allotment"])
        );
        if (baseRoom) {
          room["allotment_reference_id"] = baseRoom.agency_room_name;
        } else {
          room["allotment_reference_id"] = "";
        }
      } else {
        room["allotment_reference_id"] = "";
      }
    });
    setSelectedRoomInfo(selectedRoomInfos);
    setShowRoomMatchPage(false);
    Swal.fire({
      title: "Success",
      text: "Rooms Matched Successfully",
      icon: "success",
    });
  };
  return (
    <>
      <Card className={classes.root}>
        <Grid container className={classes.containerGridMTable}>
          {flag == true ? (
            <Dialog
              open={flag}
              className={classes.dialogRoot}
              scroll="body"
              maxWidth="lg"
              fullWidth="on"
              style={{ padding: 30 }}
            >
              <AddRoomType
                handleClose={handleClose}
                isAuthAdd={isAuthAdd}
                isAuthUpdate={isAuthUpdate}
                isAuthDelete={isAuthDelete}
                modalId="roomsModal"
                //paginationda seçilen row per page değeri modal kapanıp açıldığı için buradan props olarak göneriliyor.
                rowPerPage={rowPerPage}
                setRowPerPage={setRowPerPage}
              />
              <Grid
                item
                container
                xs={12}
                className={classes.dialogGrid}
                justifyContent="flex-end"
              >
                <ThemeProvider theme={themeCancel}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    <IntlMessages id="close" />
                  </Button>
                </ThemeProvider>
              </Grid>
            </Dialog>
          ) : (
            ""
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.dataGridStyle}>
            <MaterialTable
              columns={columns}
              tableRef={tableRef}
              data={roomList || []}
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                selection: true,
                addRowPosition: "first",
              }}
              onSelectionChange={(change) => {
                handleChangeOperatorRoom(change);
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.dataGridStyle}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                addRowPosition: "first",
              }}
              localization={{
                header: {
                  actions: "",
                },
              }}
              data={Object.values(tempRoomType) || []}
              columns={[
                {
                  title: <IntlMessages id="global.rooms" />,
                  field: "room_name",
                  cellStyle: {
                    /* height: "3rem", lineHeight: "37px",*/
                    fontSize: "15",
                  },
                },
              ]}
              actions={[
                {
                  icon: () => (
                    <ColorButton
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#FFC107",
                        color: "#fff",
                        fontSize: 10,
                        width: "120px",
                        height: "35px",
                      }}
                      onClick={() => handleOpen()}
                      color="backgroundColor"
                    >
                      <IntlMessages id="add.room" />
                    </ColorButton>
                  ),
                  position: "toolbar",
                  onClick: () => {
                    handleOpen();
                  },
                },
                {
                  icon: "radio",
                  position: "row",
                  onClick: (event, rowData) => (
                    setradiobutton({ [rowData.id]: true }),
                    handleChangeRadioButton(rowData)
                  ),
                },
              ]}
              components={{
                OverlayLoading: () => <Loader />,
                Action: (props) => {
                  if (props.action.position !== "toolbar") {
                    return (
                      <Radio
                        onClick={(event) =>
                          props.action.onClick(event, props.data)
                        }
                        checked={radiobutton[props?.data.id]}
                      />
                    );
                  } else return <MTableAction {...props} />;
                },
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.dataGridStyle}>
            <MaterialTable
              title={
                <Typography variant="h6" style={{ color: "#3f51b5" }}>
                  {" "}
                  Multiple Mapped Rooms
                </Typography>
              }
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                addRowPosition: "first",
              }}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              localization={{
                header: {
                  actions: "",
                },
              }}
              data={baseRoomList || []}
              columns={[
                {
                  title: "Excel Base Rooms",
                  field: "base_room_name",
                  cellStyle: {
                    fontSize: "15",
                  },
                  editComponent: (props) => {
                    return (
                      <Select
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        autoWidth={true}
                      >
                        {baseRoomOptions[props.rowData.room_name]?.map(
                          (item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          )
                        )}
                      </Select>
                    );
                  },
                },
                {
                  title: <IntlMessages id="global.rooms" />,
                  field: "room_name",
                  cellStyle: {
                    /* height: "3rem", lineHeight: "37px",*/
                    fontSize: "15",
                  },
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...baseRoomList];

                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setBaseRoomList([...dataUpdate]);
                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButtonStyle}
              // disabled={
              //   selections.hasOwnProperty("hotel_id") &&
              //   selectionRoom &&
              //   targetKeys.hasOwnProperty("ids") &&
              //   targetKeys.ids.length > 0
              //     ? false
              //     : true
              // }
              onClick={() => {
                roomMatchFunc();
              }}
            >
              <IntlMessages id="map.rooms" />
            </Button>
          </ThemeProvider>

          <ThemeProvider>
            <Button
              variant="contained"
              color="error"
              className={classes.unmapButtonStyle}
              disabled={targetKeys.length == 0}
              onClick={() => {
                const deleteRoomList = roomList.map((room) => {
                  if (
                    targetKeys.find(
                      (targetKey) =>
                        targetKey.operator_room_name === room.operator_room_name
                    )
                  ) {
                    return {
                      ...room,
                      global_room_name: null,
                    };
                  } else return room;
                });
                setRoomList(deleteRoomList);
              }}
            >
              <IntlMessages id="unmap.rooms" />
            </Button>
          </ThemeProvider>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
            marginLeft: "28%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            className={classes.cancelButtonStyle}
            onClick={saveSelectedRoomsasContractRooms}
            disabled={
              baseRoomList.length === 0
                ? false
                : baseRoomList.find((room) => room.base_room_name === "")
            }
          >
            <IntlMessages id="save.rooms" />
          </Button>
        </Grid>
      </Card>
    </>
  );
}
