import {
  GET_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT,
  UPDATE_BOARD_SUPPLAMENT,
  DELETE_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT_REDUCTION,
  UPDATE_BOARD_SUPPLAMENT_REDUCTION,
  DELETE_BOARD_SUPPLAMENT_REDUCTION,
} from './types';

export const getBoardSupplament = res => dispatch => {
  dispatch({ type: GET_BOARD_SUPPLAMENT, payload: res.data.data });
};
export const addBoardSupplament = res => dispatch => {
  dispatch({ type: ADD_BOARD_SUPPLAMENT, payload: res.data.data });
};
export const updateBoardSupplament = res => dispatch => {
  dispatch({ type: UPDATE_BOARD_SUPPLAMENT, payload: res.data.data });
};
export const deleteBoardSupplament = res => dispatch => {
  dispatch({ type: DELETE_BOARD_SUPPLAMENT, payload: res.data.data });
};

// For Reduction in Accordion Menu on boardSupplament
export const addBoardSupplamentReduction = res => dispatch => {
  dispatch({ type: ADD_BOARD_SUPPLAMENT_REDUCTION, payload: res.data.data });
};
export const updateBoardSupplamentReduction = res => dispatch => {
  dispatch({ type: UPDATE_BOARD_SUPPLAMENT_REDUCTION, payload: res.data.data });
};
export const deleteBoardSupplamentReduction = res => dispatch => {
  dispatch({ type: DELETE_BOARD_SUPPLAMENT_REDUCTION, payload: res.data.data });
};
