import { GET_BOARDS, ADD_BOARD, UPDATE_BOARD, DELETE_BOARD } from './types';
export const getBoards = res => dispatch => {
  
  dispatch({
    type: GET_BOARDS,
    payload: res.data.data,
  });
};

export const addBoard = res => dispatch => {
  dispatch({
    type: ADD_BOARD,
    payload: res.data.data,
  });
};

export const updateBoard = res => dispatch =>
  dispatch({
    type: UPDATE_BOARD,
    payload: res.data.data,
  });

export const deleteBoard = res => dispatch =>
  dispatch({
    type: DELETE_BOARD,
    payload: res.data.data,
  });
