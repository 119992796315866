import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const monthMatch = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const statusMatch = {
  Cancelled: "3",
  Confirmed: "4",
};
export default function ReservationGraph() {
  const [monthRes, setMonthRes] = useState({});
  const reservations = useSelector(
    (state) => state.reservation?.entities?.reservations
  );
  const graphDataCreator = () => {
    let newResData = {};
    for (let index = 1; index <= 12; index++) {
      newResData = {
        ...newResData,
        [index]: {
          total: 0,
          "3": 0,
          "4": 0,
        },
      };
    }
    if (reservations != undefined) {
      Object.values(reservations).map((reservation) => {
        newResData[moment(reservation.created_at.split("T")[0]).format("M")][
          "total"
        ] =
          newResData[moment(reservation.created_at.split("T")[0]).format("M")][
            "total"
          ] + 1;

        if (reservation.hotel_status == 3) {
          newResData[moment(reservation.created_at.split("T")[0]).format("M")][
            "3"
          ] =
            newResData[
              moment(reservation.created_at.split("T")[0]).format("M")
            ]["3"] + 1;
        } else if (reservation.hotel_status == 4) {
          newResData[moment(reservation.created_at.split("T")[0]).format("M")][
            "4"
          ] =
            newResData[
              moment(reservation.created_at.split("T")[0]).format("M")
            ]["4"] + 1;
        }
      });
    }
    let lastGraphData = [];
    Object.keys(newResData).forEach((month) => {
      lastGraphData.push({
        name: monthMatch[month],
        Total: newResData[month].total,
        Cancelled: newResData[month][statusMatch.Cancelled],
        Confirmed: newResData[month][statusMatch.Confirmed],
      });
    });
    setMonthRes(lastGraphData);
  };
  useEffect(() => {
    graphDataCreator();
  }, [reservations]);

  return (
    <ResponsiveContainer width={1000} height={150}>
      <AreaChart
        data={monthRes}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Tooltip
          labelStyle={{ color: "black" }}
          itemStyle={{ color: "rgb(72 170 116 / 87%)" }}
          labelFormatter={function(value) {
            return `Month: ${value}`;
          }}
          cursor={false}
        />

        <XAxis dataKey="name" hide />
        <Area
          type="monotone"
          dataKey="Total"
          stackId="1"
          stroke="#246e89"
          fillOpacity={10}
          fill="#246e89"
        />
        <Area
          type="monotone"
          dataKey="Cancelled"
          stackId="3"
          stroke="#388ea3"
          fillOpacity={10}
          fill="#388ea3"
        />
        <Area
          type="monotone"
          dataKey="Confirmed"
          stackId="4"
          stroke="#55afc0"
          fillOpacity={10}
          fill="#55afc0"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
