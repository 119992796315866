import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getOperatorHotels } from "redux/actions/operatorHotels";
import axios from "./axios";

export const getOperatorHotelsService = operatorIds => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("/operatorHotels", operatorIds)
    .then(res => {
      dispatch(getOperatorHotels(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
