import { GET_SPECIAL_NOTES, ADD_SPECIAL_NOTES, UPDATE_SPECIAL_NOTES, DELETE_SPECIAL_NOTES } from '../actions/types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const initialState = {
  specialNotes: [],
};
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIAL_NOTES:
      return {
        ...state,
        specialNotes: action.payload,
      };

    case ADD_SPECIAL_NOTES:
      const isequal = state.specialNotes.findIndex(spn => spn.id == action.payload.id);
      if (isequal >= 0) {
        sweetAlerts('error', 'Special Note Already Exists');
      } else {
        sweetAlerts('success', 'Note has succesfully added!');
        return {
          ...state,
          specialNotes: [...state.specialNotes, action.payload],
        };
      }

    case UPDATE_SPECIAL_NOTES:
      const specialTypeIndex = state.specialNotes.findIndex(note => note.id === action.payload.id);
      const updatedSpecialType = [...state.specialNotes];
      updatedSpecialType[specialTypeIndex] = action.payload;
      sweetAlerts('success', 'Note has succesfully updated!');
      return {
        ...state,
        specialNotes: updatedSpecialType,
      };
    case DELETE_SPECIAL_NOTES:
      const deleteSpecialNote = state.specialNotes.filter(note => note.id !== action.payload.id);
      return {
        ...state,
        specialNotes: deleteSpecialNote,
      };
    default:
      return state;
  }
};
