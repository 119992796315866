import {
  GET_REGIONS,
  GET_COUNTRIES,
  GET_DIVISIONS,
  GET_CITIES,
  GET_SUB_CITIES,
  RESET_CITIES,
  RESET_COUNTRIES,
  RESET_DIVISIONS,
  RESET_SUB_CITIES,
  GET_ALL_COUNTRIES,
} from './types';

export const getRegions = res => dispatch => {
  dispatch({
    type: GET_REGIONS,
    payload: res?.data.data,
  });
};
export const getCountries = res => dispatch => {
  dispatch({
    type: GET_COUNTRIES,
    payload: res?.data.data,
  });
};
export const getAllCountries = res => dispatch => {
  dispatch({
    type: GET_ALL_COUNTRIES,
    payload: res.data.data,
  });
}; 
export const getDivisions = res => dispatch => {
  dispatch({
    type: GET_DIVISIONS, 
    payload: res?.data.data,
  });
};

export const getCities = res => dispatch => {
  dispatch({
    type: GET_CITIES,
    payload: res.data.data,
  });
};
export const getSubCities = res => dispatch => {
  dispatch({
    type: GET_SUB_CITIES,
    payload: res?.data.data,
  });
};
export const resetCountries = () => dispatch => {
  dispatch({
    type: RESET_COUNTRIES,
  });
};

export const resetDivisions = () => dispatch => {
  dispatch({
    type: RESET_DIVISIONS,
  });
};

export const resetCities = () => dispatch => {
  dispatch({
    type: RESET_CITIES,
  });
};
export const resetSubCities = () => dispatch => {
  dispatch({
    type: RESET_SUB_CITIES,
  });
};
