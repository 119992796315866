import React from "react";
import { getDynamicValue } from "./getDynamicValue";

export const getContractDetails = (data) => {
  const contractDetails = {};
  const seasonCode = getDynamicValue(data[1], "Valid for season");
  const seasonStartDate = getDynamicValue(data[2], "Starting of season");
  const seasonEndDate = getDynamicValue(data[3], "Ending of season");
  const currency = getDynamicValue(data[1], "Currency");
  const rateType = getDynamicValue(data[3], "Rate basis");
  const minimumNights = getDynamicValue(data[4], "Minimum nights");
  const mealPlan = getDynamicValue(data[5], "Mealplan");

  seasonCode && (contractDetails["seasonCode"] = seasonCode);
  seasonStartDate && (contractDetails["seasonStartDate"] = seasonStartDate);
  seasonEndDate && (contractDetails["seasonEndDate"] = seasonEndDate);
  currency && (contractDetails["currency"] = currency);
  rateType && (contractDetails["rateType"] = rateType);
  minimumNights && (contractDetails["minimumNights"] = minimumNights);
  if (mealPlan && mealPlan == "AIU") {
    contractDetails["mealPlan"] = "AI+";
  } else {
    contractDetails["mealPlan"] = mealPlan;
  }

  let seasonType = 0;
  if (seasonStartDate && seasonEndDate) {
    // check if season type is summer or winter
    const seasonStartDateMonth = seasonStartDate.split(".")[1];
    const seasonEndDateMonth = seasonEndDate.split(".")[1];

    if (seasonStartDateMonth >= 3 && seasonEndDateMonth <= 10) {
      seasonType = 2;
    } else if (seasonStartDateMonth <= 2 && seasonEndDateMonth >= 10) {
      seasonType = 1;
    } else {
      seasonType = 3;
    }
  }

  seasonType && (contractDetails["seasonType"] = seasonType);

  return contractDetails;
};
