import { loggerEndpoint } from "../axiosLogger/index";
import jwt from "jwt-decode";
class SendLogService {
  constructor(user) {
    this.projectID = "bot_proxolab";
    this.location = window.location;
    this.token = localStorage.getItem("token");
    this.user =
      this.token && this.token != null
        ? {
            id: jwt(this.token).id,
            name: jwt(this.token).fullname,
            email: jwt(this.token).email
          }
        : "";
    this.header = {
      Authorization: this.token,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }
  /************************************************/
  // async sendLogout() {
  //   try {
  //     await fetch(loggerEndpoint + "userlogoutinfo", {
  //       headers: {
  //         Authorization: this.token,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify({
  //         email: this.user.email,
  //         id: this.user.id,
  //         name: this.user.name,
  //         location: this.location,
  //         project: this.projectID,
  //       }),
  //     })
  //       .then(function(res) {
  //         console.log(res);
  //       })
  //       .catch(function(res) {
  //         console.log(res);
  //       });
  //   } catch (err) {}
  // }
  // async sendLogin(comingTokenFromOnLoginToJWTAuth) {
  //   try {
  //     await fetch(loggerEndpoint + "userlogininfo", {
  //       headers: {
  //         Authorization: comingTokenFromOnLoginToJWTAuth,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify({
  //         email: this.user.email,
  //         id: this.user.id,
  //         name: this.user.name,
  //         location: this.location,
  //         project: this.projectID,
  //       }),
  //     })
  //       .then(function(res) {
  //         console.log(res);
  //       })
  //       .catch(function(res) {
  //         console.log(res);
  //       });
  //   } catch (err) {}
  // }
  async sendCompanyLog(res, data) {
    try {
      await fetch(loggerEndpoint + "companyservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendHotelLog(res, data) {
    try {
      await fetch(loggerEndpoint + "hotelservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendBoardLog(res, data) {
    try {
      await fetch(loggerEndpoint + "boardservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendMarketLog(res, data) {
    try {
      await fetch(loggerEndpoint + "marketservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeDestinationLog(res, data) {
    let deletedRes = {
      data: {
        status: res.status,
        message: res.message,
        company_id: res.company_id,
        created_at: res.created_at,
        updated_at: res.updated_at,
        data: data
      }
    };

    try {
      delete res.config.data;

      await fetch(loggerEndpoint + "officedestinationservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: deletedRes.data,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeLog(res, data) {
    try {
      await fetch(loggerEndpoint + "officeservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOperatorLog(res, data) {
    try {
      await fetch(loggerEndpoint + "operatorservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendProductTypesLog(res, data) {
    try {
      await fetch(loggerEndpoint + "producttypesservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendRoomLog(res, data) {
    try {
      await fetch(loggerEndpoint + "roomservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendSeasonLog(res, data) {
    try {
      await fetch(loggerEndpoint + "seasonservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendUserLog(res, data) {
    try {
      await fetch(loggerEndpoint + "userservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendNewServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "newservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "contractservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendPeriodServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "periodservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          // console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractRoomServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "contractroomservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendContractStatusServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "contractstatusservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
  async markupServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "markupservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cancellationPoliciesServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "cancellationpoliciesservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async termsConditionsServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "termsconditionsservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async additionalPaxServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "additionalpaxservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async representativeFeeServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "representativefeeservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async contributionFeeServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "contributionfeeservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async turnoverServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "turnoverservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async advertisingFeeServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "advertisingfeeservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async marketingPromotionServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "marketingpromotionservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async catalougeContributionServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "catalougecontributionservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async offerServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "offerservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async occupancyServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "occupancyservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async closeoutServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "closeoutservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "cincoutservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async cincoutServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "cincoutservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentRestServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "allotmentrestservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async releaseServiceLog(res, data) {
    try {
      await fetch(loggerEndpoint + "releaseservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async specialNotesServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "specialnotesservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async paymentServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "paymentservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async conTermsServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "contermsservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async boardSupplementServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "boardSupplementservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async bsReductionServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "bsreductionservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async extrasServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "extrasservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterCompanyServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "mastercompanyservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterUserServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "masteruserservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "allotmentservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async stopSaleLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "stopsaleservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async openSaleLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "opensaleservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async reservationServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "reservationservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async userPermissionServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "userpermissionservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async hotelMatchServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "hotelmatchservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
  async roomMatchServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "roommatchservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  /********************* EXTRANET LOGGER ************************/
  async SHLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "sh", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  /**************************************************************/
  async errorlog(res, data) {
    try {
      await fetch(loggerEndpoint + "error", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
}

let sendLogService = new SendLogService();

export default sendLogService;
