import {
  getRegions,
  getCountries,
  getCities,
  getSubCities,
  getAllCountries,
  getDivisions
} from "redux/actions/destinations";
import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";

export const getRegionsService = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("region")
    .then((res) => {
      dispatch(getRegions(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const getCountriesService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("country", { name: data[0], id: data[1] })
    .then((res) => {
      dispatch(getCountries(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getAllCountriesService = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("allCountry")
    .then((res) => {
      dispatch(getAllCountries(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getDivisionsService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("division", { name: data[0], id: data[1] })
    .then((res) => {
      dispatch(getDivisions(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getCitiesService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("city", { name: data[0], id: data[1] })
    .then((res) => {
      dispatch(getCities(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getDivisionCitiesService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("divisionCity", { name: data[0], id: data[1] })
    .then((res) => {
      dispatch(getCities(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getSubCitiesService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("subCity", { name: data[0], id: data[1] })
    .then((res) => {
      dispatch(getSubCities(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMasterRegionsService = () => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/region")
    .then((res) => {
      dispatch(getRegions(res));
    })
    .catch((e) => {});
};

export const getMasterCountriesService = (region) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/country", {
      params: {
        id: region.id
      }
    })
    .then((res) => {
      dispatch(getCountries(res));
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMasterDivisionsService = (country) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/division", {
      params: {
        id: country.id
      }
    })
    .then((res) => {
      dispatch(getDivisions(res));
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMasterCitiesService = (country) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/city", {
      params: {
        id: country.id
      }
    })
    .then((res) => {
      dispatch(getCities(res));
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMasterDivisionCitiesService = (division) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/divisionCity", {
      params: {
        id: division.id
      }
    })
    .then((res) => {
      dispatch(getCities(res));
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMasterSubCitiesService = (city) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios
    .get("master/subCity", {
      params: {
        id: city.id
      }
    })
    .then((res) => {
      dispatch(getSubCities(res));
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getSelectedCompanyRegions = () => {
  return axios.get("master/region");
};

export const getSelectedCompanyCountries = (regionId) => {
  return axios.get("master/country", {
    params: {
      id: regionId
    }
  });
};

export const getSelectedCompanyCities = (countryId) => {
  return axios.get("master/city", {
    params: {
      id: countryId
    }
  });
};

export const getSelectedCompanySubCities = (cityId) => {
  return axios.get("master/subCity", {
    params: {
      id: cityId
    }
  });
};

export const getSelectedCompanyDivisions = (countryId) => {
  return axios.get("master/division", {
    params: {
      id: countryId
    }
  });
};
export const getSelectedCompanyLocation = (data) => {
  return axios.get("master/companylocation", {
    params: {
      region_id: data.region,
      country_id: data.country,
      city_id: data.city,
      division_id: data.division,
      sub_city_id: data.subCity
    }
  });
};
