import { Checkbox, Table, Typography } from "antd";
import React, { useState } from "react";
import countries from "../../../../data/countries.json";

const columns = [
  {
    dataIndex: "country",
  },
];

const MeetpointExcelSelectInputsForValidCountries = ({
  markets,
  excelCountries,
  checkedList,
  setCheckedList,
}) => {
  let allCountries = countries;

  const plainOptions = markets.reduce((acc, market) => {
    // get market countries if it's in excelCountries
    let index = 0;
    const filteredMarketCountries = market.countries.filter((country) => {
      return excelCountries.includes(country);
    });
    // add market countries to acc if it's not already in acc
    filteredMarketCountries.forEach((country) => {
      // get country name
      const countryName = allCountries.find(
        (countryObject) => countryObject.id === country
      )?.name;
      if (countryName && !acc.includes(countryName)) {
        acc.push({ key: index, country: countryName });
        index = index + 1;
      }
    });
    return acc;
  }, []);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === plainOptions.length ? [] : plainOptions.map((r) => r.key)
    );
    setCheckedList((keys) =>
      keys.length === plainOptions.length
        ? []
        : plainOptions.map((r) => r.country)
    );
  };

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.key]);
      setCheckedList((list) => [...list, record.country]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.key);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
      setCheckedList((list) => list.filter((cId) => cId !== record.country));
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length < plainOptions.length
      }
      onChange={toggleSelectAll}
      style={{ minWidth: "100px" }}
    >
      Select All
    </Checkbox>
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onSelect: handleSelect,

    columnTitle: headerCheckbox,
  };
  return (
    <div>
      <Typography
        style={{
          marginTop: "10px",
          paddingLeft: "5px",
          borderBottom: "1px solid #A3A3A3",
        }}
      >
        Valid Countries
      </Typography>
      <div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={plainOptions}
          pagination={{
            position: ["bottomCenter"],
            size: "medium",
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30"],
          }}
          size="small"
        />
      </div>
    </div>
  );
};

export default MeetpointExcelSelectInputsForValidCountries;
