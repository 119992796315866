import React, { useEffect, useRef } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import SaveIcon from "@material-ui/icons/Save";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, deepOrange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
//import { addOperatorCredentialService } from "services/operatorCredential";
import { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { saveOperatorCredentials } from "@jumbo/utils/extranetHelpers";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { PasswordFormatCustom } from "../Common/NumberFormat/NumberFormat";
import Alert from "@mui/material/Alert";
import IntlMessages from "@jumbo/utils/IntlMessages";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import axios from "services/axios";
import {
  reservationListDNATA,
  reservationListIVC,
  reservationListJU,
  reservationListLMT,
  reservationListPP,
  reservationListSH,
  reservationListWB,
  reservationListWEBBEDS,
  reservationListW2M,
  reservationListPT,
} from "services/reservationList";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "95%",
    height: "100%",
    // marginLeft: theme.spacing(5),
    fontSize: "12px!important",
    "& .MuiGrid-container": {
      flexWrap: "nowrap!important",
      alignItems: "center !important",
    },
  },
  gridStyle: {
    marginLeft: theme.spacing(2),
    minWidth: "18%",
    "& input": {
      color:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#b5b5b5"
          : "#323232",
      boxShadow:
        "rgb(0 0 0 / 10%) 0px 10px 12px -3px, rgb(0 0 0 / 4%) 0px 5px 5px -3px",
    },
    "& label": {
      color:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#b5b5b5"
          : "#706d6d",
    },
  },

  containerGrid: {
    margin: theme.spacing(5),
    marginLeft: "-15px",
  },
  textStyle: {
    width: "100%",
    "& .MuiOutlinedInput-root fieldset": {
      borderColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#b5b5b5"
          : "#36e1e9",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#b5b5b5"
          : "#36e1e9",
    },
    "& label.Mui-focused": {
      color:
        window.localStorage.getItem("theme-type") == "dark"
          ? "white"
          : "#008c93",
    },
    "&:hover .MuiOutlinedInput-root fieldset": {
      borderColor:
        window.localStorage.getItem("theme-type") == "dark"
          ? "#b5b5ff"
          : "#008c93",
    },
  },
  bookingListButton: {
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
    backgroundColor: "#F37878",
    color: "fff",
    fontWeight: "700",
    width: "max-content",
  },
  buttonStyle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  visibilityButton: {
    marginRight: "-20px",
  },
  alert: {
    marginTop: theme.spacing(5),
    height: "1rem",
    width: "100%",
  },
}));

const MySwal = withReactContent(Swal);

export default function OperatorCredential(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  //For save button
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });
  const themeRes = createTheme({
    palette: {
      primary: deepOrange,
    },
  });

  //For alert messages function
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };
  const { authUser } = useSelector(({ auth }) => auth);
  const [userName, setUserName] = useState(props.opUser);
  const [password, setPassword] = useState(props.opPassword);
  const [lastMinuteErrorMessage, setLastMinuteErrorMessage] = useState("");
  const [bookingListDisable, setBookingListDisable] = useState(
    !(props.opUser && props.opPassword)
  );
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [responseStatus, setResponseStatus] = useState("");
  const [open, setOpen] = useState(true);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.delete === 1
  );

  //Close alert message after 5 seconds
  useEffect(() => {
    if (open === true) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [open]);
  const handleClickShowPassword = () => {
    if (props.opId !== 1 && props.opId !== 15 && props.opId !== 16) {
      setValues({ ...values, showPassword: !values.showPassword });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingBookingList, setLoadingBookingList] = useState(false);
  const saveOpCredential = () => {
    if (
      userName !== "" &&
      password !== "" &&
      userName !== null &&
      password !== null
    ) {
      let operatorCredentialDetail = {
        operator_id: props.opId,
        user_name: userName,
        password: password,
        operator_name: props.opName,
        pxoUser: authUser,
      };
      if (!(props.opId == 1 || props.opId == 15 || props.opId == 16)) {
        setLoadingSave(true);
      }
      saveOperatorCredentials(
        operatorCredentialDetail,
        setOpen,
        setResponseStatus,
        setLoadingSave,
        setBookingListDisable,
        setLastMinuteErrorMessage
      );
      //dispatch(addOperatorCredentialService(operatorCredentialDetail));

      //sweetAlerts('Success', 'Operator Credential is created succesfull!');
    } else {
      sweetAlerts("Warning", "Please fill the required fields!");
    }
  };

  const getReservationList = () => {
    if (userName !== "" && password !== "") {
      axios
        .get("getDbName")
        .then((res) => {
          if (props.opId == 1) {
            dispatch(reservationListPP(res.data.data, setLoadingBookingList));
          } else if (props.opId == 2) {
            dispatch(
              reservationListSH(res.data.data, setLoadingBookingList)
            ).then((res) => {
              if (res.code != 202) {
                errorBookingList(res.message);
              }
            });
          } else if (props.opId == 3) {
            dispatch(
              reservationListDNATA(res.data.data, setLoadingBookingList)
            ).then((res) => {
              if (res.data.code == 204) {
                return Swal.fire({
                  toast: "true",
                  position: "top-end",
                  icon: "error",
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 5000,
                });
              }
            });
          } else if (props.opId == 4) {
            dispatch(
              reservationListWB(res.data.data, setLoadingBookingList)
            ).then((res) => {
              if (res.code != 202) {
                errorBookingList(res.message);
              }
            });
          } else if (props.opId == 8) {
            dispatch(
              reservationListWEBBEDS(res.data.data, setLoadingBookingList)
            );
          } else if (props.opId == 10) {
            dispatch(reservationListLMT(res.data.data, setLoadingBookingList));
          } else if (props.opId == 12) {
            dispatch(reservationListIVC(res.data.data, setLoadingBookingList));
          } else if (props.opId == 14) {
            dispatch(reservationListJU(res.data.data, setLoadingBookingList));
          } else if (props.opId == 15) {
            dispatch(reservationListW2M(res.data.data, setLoadingBookingList));
          } else if (props.opId == 16) {
            dispatch(reservationListPT(res.data.data, setLoadingBookingList));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const errorBookingList = (message = "") => {
    return Swal.fire({
      toast: "true",
      position: "top-end",
      icon: "error",
      title: message + " First Save and Wait Operator Credential",
      showConfirmButton: false,
      timer: 5000,
    });
  };

  const us = useRef();
  const pass = useRef();

  if (responseStatus == "error") {
    us.current.value = "";
    pass.current.value = "";
    setTimeout(() => {
      setResponseStatus(true);
    }, 5000);
  }
  return (
    <div className={classes.root}>
      <Grid container xs={12} className={classes.containerGrid}>
        <Grid item xs={1} className={classes.gridStyle}>
          <Typography
            style={{
              fontWeight: "600",
              textAlign: "end",
              paddingRight: "3rem",
            }}
            sx={{
              fontSize: {
                lg: 30,
                md: 20,
                sm: 15,
                xs: 10,
              },
            }}
          >
            {props.opName}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.gridStyle}>
          <TextField
            inputRef={us}
            className={classes.textStyle}
            id="outlined-name"
            size="small"
            type={values.showPassword ? "text" : "password"}
            //defaultValue={values.showPassword!="password"||false?userName: Math.max(0, parseInt(userName) ).toString().slice(0,12)}
            value={userName}
            label={<IntlMessages id="username" />}
            disabled={isAuthAdd || isAuthUpdate ? false : true}
            InputProps={{
              shrink: true,
              inputComponent:
                props.opId != 1 &&
                props.opId != 15 &&
                props.opId != 16 &&
                (values.showPassword == "password" ||
                  values.showPassword == false)
                  ? PasswordFormatCustom
                  : "",
            }}
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>

        <Grid item xs={3} className={classes.gridStyle}>
          <TextField
            className={classes.textStyle}
            inputRef={pass}
            id="outlined-name"
            size="small"
            type={values.showPassword ? "text" : "password"}
            value={password}
            disabled={isAuthAdd || isAuthUpdate ? false : true}
            label={<IntlMessages id="password" />}
            InputProps={{
              shrink: true,
              inputComponent:
                props.opId != 1 &&
                props.opId != 15 &&
                props.opId != 16 &&
                (values.showPassword == "password" ||
                  values.showPassword == false)
                  ? PasswordFormatCustom
                  : "",
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>

        <Grid item xs={1} className={classes.visibilityButton}>
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          xs={2}
        >
          {/* <Grid item xs={1} style={{ marginRight: "30px" }}> */}
          <Box
            sx={{
              m: 1,
              position: "relative",
              boxShadow:
                "rgb(0 0 0 / 10%) 0px 10px 12px -3px, rgb(0 0 0 / 4%) 0px 5px 5px -3px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Button
                id={props.opId}
                onClick={saveOpCredential}
                variant="contained"
                size="medium"
                color="primary"
                style={{ color: "rgb(255, 255, 255)", fontWeight: "600" }}
                disabled={loadingSave || !isAuthAdd || !isAuthUpdate}
                startIcon={<SaveIcon />}
              >
                <IntlMessages id="SAVE" />
              </Button>
              {loadingSave && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </ThemeProvider>
          </Box>
          {/* <ThemeProvider theme={theme}>
      <Button
        id={props.opId}
        onClick={saveOpCredential}
        variant="contained"
        size="medium"
        color="primary"
        style={{ paddingRight: "10px" }}
        startIcon={<SaveIcon />}
      >
        <IntlMessages id="SAVE" />
      </Button>
    </ThemeProvider>{" "} */}
          {/* {loadingSave && (
    <Loader
      style={{ marginLeft: "2rem" }}
      color="primary"
      size="2rem"
      disableShrink
    />
  )} */}
          {/* </Grid> */}
          {props.opId != 5 &&
            props.opId != 6 &&
            props.opId != 7 &&
            props.opId != 9 &&
            props.opId != 11 && (
              <Box
                sx={{
                  m: 1,
                  position: "relative",
                  boxShadow:
                    "rgb(0 0 0 / 10%) 0px 10px 12px -3px, rgb(0 0 0 / 4%) 0px 5px 5px -3px",
                }}
              >
                <ThemeProvider theme={themeRes}>
                  <Button
                    id={props.opId}
                    onClick={getReservationList}
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.bookingListButton}
                    disabled={bookingListDisable || !isAuthAdd || !isAuthUpdate}
                    startIcon={<LocalLibraryIcon />}
                  >
                    <IntlMessages id="booking.list" />
                  </Button>
                  {loadingBookingList && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#ff4200",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ThemeProvider>
              </Box>
              // <Grid item xs={1}>
              //   <ThemeProvider theme={themeRes}>
              //     <Button
              //       id={props.opId}
              //       onClick={getReservationList}
              //       variant="contained"
              //       size="medium"
              //       color="primary"
              //       startIcon={<LocalLibraryIcon />}
              //       style={{ width: "max-content" }}
              //     >
              //       <IntlMessages id="booking.list" />
              //     </Button>
              //   </ThemeProvider>
              // </Grid>
            )}
          {/* <Grid></Grid> */}
        </Grid>
        {/* {loadingBookingList && (
  <Loader
    style={{ marginLeft: "2rem" }}
    color="primary"
    size="2rem"
    disableShrink
  />
)} */}
      </Grid>
      <Grid item xs={6} justifyContent="center">
        {responseStatus == "success" && open && (
          <Alert
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                X
              </Button>
            }
          >
            Successfully saved {props.opName} credentials. {props.opName}
            -Infobot started. This will take some time.
          </Alert>
        )}
        {responseStatus == "error" && open && (
          <Alert
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                X
              </Button>
            }
          >
            {props.opId == 10 && lastMinuteErrorMessage}
            Invalid {props.opName} Credentials {props.opName}-Infobot not
            started. Please try again.
          </Alert>
        )}
      </Grid>
    </div>
  );
}
