import React, {
  useEffect,
  lazy,
  Suspense,
  useState,
  useCallback,
  useRef,
} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import ContractBotTabPanel from "./ContractBotTabPanel";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersService } from "services/user";
import { getOperatorService } from "services/operator";
import { getOperatorHotelsService } from "services/operatorhotels";
import { getHotelService } from "services/hotels";
import {
  getContractService,
  getSearchedContractService,
} from "services/contract";
import { getMarkupsService } from "services/markup";
import { getMarketService } from "services/market";
import { getCurrenciesService } from "services/currency";
import { getSeasonService } from "services/season";
import { getStarRatesService } from "services/starRate";
import { getProductTypesService } from "services/productTypes";
import { getCountriesService, getRegionsService } from "services/destinations";
import { getBoardsService } from "services/boards";
import { getRateTypesService } from "services/rateType";
import { getAllotmentTypeService } from "services/allotmentType";
import { getBanksService } from "services/banks";
import { getPaymentMethodsService } from "services/paymentMethods";
import { getPaymentConditionsService } from "services/paymentConditions";
import { getStayTypesService } from "services/stayType";
import { getCancellationPoliciesServices } from "services/cancellationPoliciesDetail";
import { getAdminCompaniesService } from "services/company";
import { getReservationsService } from "services/reservation";
import { getOnSaleContractService } from "services/onSaleContract";
import { changeContractStatusService } from "services/contract";
import { getExtraService } from "services/extras";
import { getServiceService } from "services/services";
import { getRoomService } from "services/rooms";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { addDashboardLoaded } from "redux/actions/hasLoaded";
import "../../../styles/css/earth.css";
import moment from "moment";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import withReactContent from "sweetalert2-react-content";
import Loader from "./Loader";
import axios from "services/axios";
import { loggerEndpoint } from "../../../services/axiosLogger/index";
import { getVersions } from "redux/actions/versions";

const Contract = lazy(() => import("./DashboardContractPage"));
const Bot = lazy(() => import("./DashboardBotPage"));

export default function Dashboard() {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { authUser } = useSelector(({ auth }) => auth);

  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const hasLoaded = useSelector(state => state?.hasLoaded.hasLoaded); //prettier-ignore
  const [logs, setLogs] = useState([]);
  const { allUsers } = useSelector(({ user }) => user);
  const firstRender = useRef(true);
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;

  const dateFormat = new moment().format("YYYY-MM-DD");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterSelected, setFilterSelected] = useState({
    log_type: "all_logs",
    sell_start_date: "", //dateFormat,
    sell_end_date: "", //dateFormat,
    stay_start_date: "", //dateFormat,
    stay_end_date: "", //dateFormat,
    processing_start_time: moment(today)
      .add(-30, "days")
      .format("YYYY-MM-DD"),
    processing_end_time: dateFormat,
    user_email: [],
    operator_id: "",
    status: "",
    hotel_id: "",
    room_id: "",
    page: 1,
    limit: 2,
  });

  const [logCounts, setLogCounts] = useState({
    allCount: 0,
    infoCount: 0,
    openCount: 0,
    stopCount: 0,
    offerCount: 0,
    offerDeleteCount: 0,
    contractOnSaleCount: 0,
  });
  useEffect(() => {
    const firstLoader = async () => {
      let operatorIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,15,16];
      Promise.all([
        dispatch(
          getContractService(
            authUser.authority_level,
            localStorage.getItem("selectedCompany"),
            page,
            rowsPerPage
          )
        ),
        dispatch(getOnSaleContractService()),
        dispatch(getAllUsersService()),
        dispatch(getRegionsService()),
        dispatch(getAdminCompaniesService()),
        dispatch(getBoardsService()),
        dispatch(getHotelService()),
        dispatch(getOperatorHotelsService(operatorIds)),
        dispatch(getProductTypesService()),
        dispatch(getSeasonService()),
        dispatch(getMarketService()),
        dispatch(getOperatorService()),
        dispatch(getMarkupsService()),
        dispatch(getCurrenciesService()),
        dispatch(getRateTypesService()),
        dispatch(getBanksService()),
        dispatch(getPaymentMethodsService()),
        dispatch(getPaymentConditionsService()),
        dispatch(getAllotmentTypeService()),
        dispatch(getStayTypesService()),
        dispatch(getCancellationPoliciesServices()),
        dispatch(getReservationsService()),
        dispatch(getStarRatesService()),
        dispatch(getExtraService()),
        dispatch(getServiceService()),
        dispatch(getRoomService()),
        dispatch(getCountriesService(["", 0])),
        getVersionsService(),
      ]);
    };
    const getContract = async () => {
      await dispatch(
        getContractService(
          authUser.authority_level,
          localStorage.getItem("selectedCompany"),
          page,
          rowsPerPage,
          authUser.id
        )
      );
      setIsFinished(true);
    };
    if (hasLoaded.dashboard === false && firstRender.current) {
      firstLoader()
        .then(() => {
          dispatch(addDashboardLoaded(true));
          setIsFinished(true);
          firstRender.current = false;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getContract();
    }
  }, []);

  const handleFilterChange = useCallback(() => {
    if (filterSelected["user_email"].length == 0) {
      let emails = allUsers.map((user) => user.email);
      filterSelected["user_email"] = emails;
    }
    handleSubmit(filterSelected);
    logCountsFun();
  }, [allUsers]);

  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const logCountsFun = () => {
    axios({
      method: "get",
      url: loggerEndpoint + `botclient/countLogs`,
    }).then((res) => {
      setLogCounts({
        allCount:
          res.data.data.openSaleLogCount +
          res.data.data.infoLogCount +
          res.data.data.stopSaleLogCount +
          res.data.data.offerLogCount +
          res.data.data.offerDeleteLogCount +
          res.data.data.contractOnSaleCount,
        infoCount: res.data.data.infoLogCount,
        openCount: res.data.data.openSaleLogCount,
        stopCount: res.data.data.stopSaleLogCount,
        offerCount: res.data.data.offerLogCount,
        offerDeleteCount: res.data.data.offerDeleteLogCount,
        contractOnSaleCount: res.data.data.contractOnSaleCount,
      });
    });
  };
  const dateControl = (date) => {
    return moment(date) < moment(today).add(-30, "days") ? true : false;
  };
  const handleSubmit = (filterSelected) => {
    if (
      dateControl(filterSelected.processing_end_time) ||
      dateControl(filterSelected.processing_start_time)
      // dateControl(filterSelected.sell_end_date) ||
      // dateControl(filterSelected.sell_start_date) ||
      // dateControl(filterSelected.stay_end_date) ||
      // dateControl(filterSelected.stay_start_date)
    ) {
      sweetAlerts(
        "error",
        intl.formatMessage({
          id: "dates.must.be.within.the.last.30.days",
        }),
        "Ooops..."
      );
    } else {
      if (filterSelected["user_email"].length != 0) {
        //NOTE - Filtre atarken tarihler sorunu
        /*Filtrelerde tarih boş gidiyordu bunu önlemek için önce log tipine baktım log tipinde giden tarihleri kontrol ediyorum eğer tipe göre gidecek tarihin
         değeri boş ise default verilen değeri atadım eğer bir değeri varsa o değeri alıp filtre atsın dedim
    
        */
        // if (
        //   filterSelected.log_type == "all_logs" ||
        //   filterSelected.log_type == "contract_on_sale" ||
        //   filterSelected.log_type == "info"
        // ) {
        //   filterSelected.sell_end_date = "";
        //   filterSelected.sell_start_date = "";
        //   filterSelected.stay_end_date = "";
        //   filterSelected.stay_start_date = "";
        // } else if (
        //   filterSelected.log_type == "open_sale" ||
        //   filterSelected.log_type == "stop_sale"
        // ) {
        //   filterSelected.stay_end_date === ""
        //     ? (filterSelected.stay_end_date = dateFormat)
        //     : (filterSelected.stay_end_date = dateFormat);
        //   filterSelected.stay_start_date === ""
        //     ? (filterSelected.stay_start_date = dateFormat)
        //     : (filterSelected.stay_start_date = dateFormat);
        // } else {
        //   filterSelected.sell_end_date === ""
        //     ? (filterSelected.sell_end_date = dateFormat)
        //     : (filterSelected.sell_end_date = dateFormat);
        //   filterSelected.sell_start_date === ""
        //     ? (filterSelected.sell_start_date = dateFormat)
        //     : (filterSelected.stay_start_date = dateFormat);
        //   filterSelected.stay_end_date === ""
        //     ? (filterSelected.stay_end_date = dateFormat)
        //     : (filterSelected.stay_end_date = dateFormat);
        //   filterSelected.stay_start_date === ""
        //     ? (filterSelected.stay_start_date = dateFormat)
        //     : (filterSelected.stay_start_date = dateFormat);
        // }
        axios({
          method: "post",
          url: loggerEndpoint + `botclient`,
          data: filterSelected,
        }).then((res) => {
          let resThenLogs = res.data.data;
          let newLogs = [];
          filterSelected.log_type == "all_logs"
            ? (newLogs = [
                ...resThenLogs.openSaleService,
                ...resThenLogs.stopSaleService,
                ...resThenLogs.infoService,
                ...resThenLogs.shService,
                ...resThenLogs.offerDeleteService,
                ...resThenLogs.contractOnSaleService,
              ])
            : (newLogs = resThenLogs);
          newLogs.forEach((log) => {
            //listelemeye gönderilen logların id si yok _id si var bunu da datagrid kabul etmiyor
            log["id"] = log._id;
          });
          setLogs(newLogs);
        });
      }
    }
  };

  // run use callback function when users change

  if (logs?.length > 0) {
    logs.map((log) => {
      if (
        log.data.operator &&
        (log?.data?.log_type == "Info" ||
          log?.data?.log_type == "Contract On Sale")
      ) {
        log.data.operator.operator_name = log.data.operator.operator_name.toUpperCase();
      } else {
        if (
          log.data.hasOwnProperty("operators") &&
          log.data.operators.length > 0
        ) {
          log.data.operators.map((op) => {
            op.operator_name = op.operator_name?.toUpperCase();
          });
        }
      }
    });
  }
  let version = useRef([]);
  const getVersionsService = useCallback(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    axios
      .get("contractversions")
      .then((res) => {
        version.current = res.data.data;
        dispatch(getVersions(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const isFirstRender = useRef(true);

  const getPayload = (filters) => {
    let columnNames = [];
    let columnNameWithValues = {};
    let payload = {
      columnNames: [],
      columnNameWithValues: {},
    };

    if (filters) {
      filters.forEach((item) => {
        columnNames.push(item.columnName);
        columnNameWithValues[item.columnName] = item.value;
      });

      payload = {
        columnNames: columnNames,
        columnNameWithValues: columnNameWithValues,
      };
    }
    // ** just only change format to YYYY-MM-DD for date..
    if (
      payload.columnNameWithValues.hasOwnProperty("start_date") &&
      payload.columnNameWithValues.start_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.start_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.start_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("end_date") &&
      payload.columnNameWithValues.end_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.end_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.end_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("created_date") &&
      payload.columnNameWithValues.created_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.created_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.created_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("signed_date") &&
      payload.columnNameWithValues.signed_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.signed_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.signed_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("published_date") &&
      payload.columnNameWithValues.published_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.published_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.published_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("revised_date") &&
      payload.columnNameWithValues.revised_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.revised_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.revised_date = formattedDate;
    }
    if (
      payload.columnNameWithValues.hasOwnProperty("updated_date") &&
      payload.columnNameWithValues.updated_date?.length === 10
    ) {
      let formattedDate = moment(
        payload.columnNameWithValues.updated_date,
        "DD-MM-YYYY",
        false
      ).format("YYYY-MM-DD");
      payload.columnNameWithValues.updated_date = formattedDate;
    }
    return payload;
  };

  useEffect(() => {
    if (allUsers.length) {
      handleFilterChange();
    }
  }, [allUsers]);
  return (
    isFinished && (
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab label={<IntlMessages id="bot.operations" />} />
          <Tab label={<IntlMessages id="contract.operations" />} />
        </Tabs>
        <ContractBotTabPanel value={value} index={0}>
          <Suspense fallback={<Loader />}>
            <Bot
              logCounts={logCounts}
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              handleSubmit={handleSubmit}
              logs={logs}
            />
          </Suspense>
        </ContractBotTabPanel>
        <ContractBotTabPanel value={value} index={1}>
          <Suspense fallback={<Loader />}>
            <Contract
              getVersions={getVersionsService}
              getPayload={getPayload}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Suspense>
        </ContractBotTabPanel>
      </Paper>
    )
  );
}
