import { GET_OPEN_SALE, ADD_OPEN_SALE } from './types';

export const getOpenSale = res => dispatch => {
  dispatch({
    type: GET_OPEN_SALE,
    payload: res.data.data,
  });
};

export const addOpenSale = res => dispatch => {
  dispatch({
    type: ADD_OPEN_SALE,
    payload: res.data,
  });
};
