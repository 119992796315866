import { getDynamicValue } from "./getDynamicValue";
import cities from "../../../../../data/cities.json";
import subCities from "../../../../../data/subCities.json";
import countries from "../../../../../data/countries.json";
import regionsData from "../../../../../data/regionsData.json";
export const getHotelInfo = data => {
  const hotelInfo = {};
  const cityData = cities;
  const subCityData = subCities;

  const hotelName = data[1]["Accommodation Agreement"];
  const region =
    data[3].__EMPTY_3 === "Region" && data[3]["Accommodation Agreement"];
  const website = getDynamicValue(data[3], "Web");
  const address = getDynamicValue(data[4], "Address");
  const email = getDynamicValue(data[5], "Sales mail");
  const star = getDynamicValue(data[2], "Official category");
  const tel = data[1].__EMPTY_7 === "Tel" && data[1].__EMPTY_8;
  const fax = data[2].__EMPTY_7 === "Fax" && data[2].__EMPTY_8.trim();
  const airport =
    data[2].__EMPTY_3 === "Airport" && data[2]["Accommodation Agreement"];
  const airportCode = data[2].__EMPTY_5 === "Code" && data[2].__EMPTY_6;

  hotelName && (hotelInfo["name"] = hotelName);
  hotelName && (hotelInfo["company"] = hotelName);
  region && (hotelInfo["region"] = region);
  website && (hotelInfo["web_page"] = website);
  address && (hotelInfo["address"] = address);
  email && (hotelInfo["e_mail"] = email);
  star && (hotelInfo["star_rating"] = star);
  tel && (hotelInfo["tel"] = tel);
  fax && (hotelInfo["fax"] = fax);
  hotelInfo["product_type_id"] = 7;
  airport && (hotelInfo["airport"] = airport);
  airportCode && (hotelInfo["airportCode"] = airportCode);

  hotelInfo["tax_no"] = "N/A";
  hotelInfo["tax_office"] = "N/A";

  console.log(hotelInfo);
  const city = Object.values(cityData[0])
    .map(cityCountry => {
      return cityCountry.find(city => hotelInfo.address.includes(city.name));
    })
    .filter(city => city !== undefined)[0];
  if (city != undefined) {
    hotelInfo.region_id = city.region_id;
    hotelInfo.country_id = city.country_id;
    hotelInfo.city_id = city.id;
    hotelInfo.sub_city_id = Object.values(subCityData).find(subCity => {
      return subCity.city_id === city.id;
    })?.id;
  }
  // If address doesnt have city name, search for subcity

  if (city == undefined) {
    const subCity = Object.values(subCityData).find(subcity => {
      return hotelInfo.address
        .toUpperCase()
        .includes(subcity.name?.toString().toUpperCase());
    });
    if (subCity != undefined) {
      hotelInfo.region_id = subCity.region_id;

      hotelInfo.country_id = subCity.country_id;
      hotelInfo.city_id = subCity.city_id;
      hotelInfo.sub_city_id = subCity.id;
    }
  }
  let hotel_name_temp = "";
  let temp_name = hotelInfo.name;
  let hotel_name_string = "";
  temp_name = temp_name.split(" ");
  temp_name.forEach(element => {
    if (element.length >= 3) {
      hotel_name_string = hotel_name_string + element.slice(0, 3);
    }
  });
  if (hotelInfo.hasOwnProperty("region_id")) {
    regionsData.forEach(region => {
      if (region.id === hotelInfo.region_id) {
        hotel_name_temp = region.code;
      }
    });
  }

  if (hotelInfo.hasOwnProperty("country_id")) {
    countries.forEach(country => {
      if (country.id === hotelInfo.country_id) {
        hotel_name_temp = hotel_name_temp + country.code;
      }
    });
  }
  hotel_name_temp = hotel_name_string + hotel_name_temp;

  hotelInfo["hotel_code"] = hotel_name_temp.toUpperCase();
  return hotelInfo;
};
