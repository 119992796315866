import IntlMessages from "@jumbo/utils/IntlMessages";
import { Grid } from "@material-ui/core";
import { DatePicker, Space } from "antd";
import React from "react";

const DateRangeFilter = props => {
  const { message, onChangeHandler } = props;

  const { RangePicker } = DatePicker;
  return (
    <Grid item xs={12} sm={6} md={3} lg={2} style={{ padding: 6 }}>
      <IntlMessages id={message} />
      <RangePicker
        style={{ width: "100%" }}
        placeholder={["from", "to"]}
        onChange={onChangeHandler}
      />
    </Grid>
  );
};

export default DateRangeFilter;
