import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getExtras,
  getAllExtras,
  addExtras,
  updateExtras,
  deleteExtras
} from "redux/actions/extras";
import axios from "./axios";
/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getExtraService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`extra/${id}`)
    .then(res => {
      dispatch(getExtras(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const getAllExtraService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`allExtra`)
    .then(res => {
      dispatch(getAllExtras(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addExtraService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("extra", data)
    .then(res => {
      dispatch(addExtras(res));
      dispatch(fetchSuccess());
      sendLogService.extrasServiceLogs(res, data);
      return res.data.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateExtraService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("extra", data)
    .then(res => {
      dispatch(updateExtras(res));
      dispatch(fetchSuccess());
      sendLogService.extrasServiceLogs(res, data);
      return res.data.data;
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteExtraService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`extra/${id}`)
    .then(res => {
      dispatch(deleteExtras(res));
      dispatch(fetchSuccess());
      sendLogService.extrasServiceLogs(res, id);
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
