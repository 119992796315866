import React, { useEffect, useState } from "react";
import DropdownSelection from "./DropdownSelection";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Tooltip,
} from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { green, red, blue } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import {
  deleteOfferListSH,
  deleteOfferListFOR,
  deleteOfferListLMX,
  deleteOfferListDN,
  deleteOfferListPP,
  deleteOfferListWB,
  deleteOfferListSIT,
  deleteOfferListWEBBEDS,
  deleteOfferListJUMBO,
  deleteOfferListLMT,
  deleteOfferListXPUR,
  deleteOfferListIVC,
  deleteOfferListW2M,
  deleteOfferListPT,
} from "services/offerDelete";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  sendOfferListServiceSH,
  sendOfferListServicePP,
  sendOfferListServiceDN,
  sendOfferListServiceWB,
  sendOfferListServiceLMX,
  sendOfferListServiceFOR,
  sendOfferListServiceSIT,
  sendOfferListServiceWEBBEDS,
  sendOfferListServiceXPUR,
  sendOfferListServiceJUMBO,
  sendOfferListServiceLMT,
  sendOfferListServiceIVC,
  sendOfferListServiceW2M,
  sendOfferListServicePT,
} from "services/offerList";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import MultiSelect from "../Common/MultiSelect";
import { getContractsByHotelService } from "services/operatorContracts";
import { clearOfferList, getOfferList } from "redux/actions/offerList";
import Loader from "../Contract/Loader";
import { useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

const theme = createTheme({
  palette: {
    primary: green,
    secondary: red,
    tertiary: blue,
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "0.8rem",
      },
    },
  },
});

const customFilter = (value, key, offerLists) => {
  if (typeof value[key] == "object") {
    return value[key].find((offer) => {
      let x = offer.split("-");
      let y = x[2] + "-" + x[1] + "-" + x[0];
      return moment(offerLists[key]).format("DD-MM-YYYY") == y;
    });
  } else return value[key] == moment(offerLists[key]).format("DD-MM-YYYY");
};

function OfferListModal({ setShowOfferDiscounts, offerTypesinOperator }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const initialFilter = {
    sell_start_date: "",
    sell_end_date: "",
    stay_start_date: "",
    stay_end_date: "",
  };

  const [offerLists, setOfferList] = useState(initialFilter);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operators } = useSelector(({ operators }) => operators);
  const { offerList } = useSelector(({ offerList }) => offerList);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const [filteredData, setFilteredData] = useState([]);
  const [showIcon, setshowIcon] = useState(false);
  const [allOfferList, setAllOfferList] = useState([]);
  const [shLoading, setShLoading] = useState("");
  const [dnLoading, setDnLoading] = useState("");
  const [wbLoading, setWbLoading] = useState("");
  const [ppLoading, setPpLoading] = useState("");
  const [lmxLoading, setLmxLoading] = useState("");
  const [lmtLoading, setLmtLoading] = useState("");
  const [forLoading, setForLoading] = useState("");
  const [sitLoading, setSitLoading] = useState("");
  const [xpurLoading, setXPurLoading] = useState("");
  const [jumboLoading, setJumboLoading] = useState("");
  const [webbedsLoading, setWebbedsLoading] = useState("");
  const [w2mLoading, setW2mLoading] = useState("");
  const [ptLoading, setPtLoading] = useState("");
  const [ıvcLoading, setIvcLoading] = useState("");
  const [hotelName, setHotelName] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);

  const [showOffersActive, setShowOffersActive] = useState(false);

  //contracts state will change by selected hotel and operator
  const {
    SHhotels,
    WBhotels,
    PPhotels,
    WEBBEDShotels,
    JUMBOhotels,
    IVChotels,
    W2Mhotels,
    PThotels,
  } = useSelector(({ operatorHotels }) => operatorHotels);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 6 && permission.delete === 1
  );

  const { contracts } = useSelector(
    ({ operatorContracts }) => operatorContracts
  );


  let newOffersArray = [];
  let stayStart = [];
  let stayEnd = [];
  let seasonStart = [];
  let seasonEnd = [];


  const [selected, setSelected] = useState({
    hotelId: "",
    operators: [],
    contracts: "",
  });
  const [contractsOptions, setContractsOptions] = useState([]);
  useEffect(() => {
    if (Object.values(matched_hotels) !== []) {
      let hotelsInfo = [];
      Object.values(matched_hotels).map((item) => {
        hotelsInfo.push({
          id: hotels[item.global_hotel_id]?.id,
          name: hotels[item.global_hotel_id]?.name,
        });
      });
      setHotelName(hotelsInfo);
    }

    return () => {
      dispatch(clearOfferList());
    };
  }, []);
  useEffect(() => {
    let selectedOperators = selected.operators?.filter((op) => op.id !== 3);
    if (selectedOperators?.length > 0) {
      dispatch(
        getContractsByHotelService(
          _.map(selectedOperators, "id"),
          selected.hotelId
        )
      );
    }
  }, [selected.operators]);

  useEffect(() => {
    let chunkContracts = [];
    if (contracts) {
      Object.values(contracts).map((contract) => {
        chunkContracts.push({
          id: contract.contract_id,
          label: contract.contract_name,
        });
      });
      setContractsOptions(chunkContracts);
    }
  }, [contracts]);

  //operators state will change by selected hotel
  useEffect(() => {
    let matchedHotel = Object.values(matched_hotels);
    let chunkOperator = [];
    Object.values(matchedHotel).map((item) => {
      Object.keys(item).map((value) => {
        if (
          value != "global_hotel_id" &&
          selected.hotelId == item[value].global_hotel_id
        ) {
          chunkOperator.push({
            id: item[value].operator_id,
            label: value,
          });
        }
      });
    });
    setOperatorOptions(chunkOperator);
  }, [operators, selected.hotelId]);

  const listOffers = () => {
    const listData = {
      contracts: selected.contracts,
      hotelId: selected.hotelId,
      PPcontract: selected.PPcontract,
      SHcontract: selected.SHcontract,
      WBcontract: selected.WBcontract,
      WEBBEDScontract: selected.WEBBEDScontract,
      JUMBOcontract: selected.JUMBOcontract,
      IVCcontract: selected.IVCcontract,
      W2Mcontract: selected.W2Mcontract,
      PTcontract: selected.PTcontract,
    };
    let selectedOperatorDetection = "";
    let selectedOperatorDetectionResult = selected.operators.map((ope) => {
      if (Object.keys(listData).includes(ope.label + "contract")) {
        selectedOperatorDetection = ope.label + "contract";
      }
      return selectedOperatorDetection;
    });
    let hasTheContractBeenChosenControl = true;
    selectedOperatorDetectionResult.map((item) => {
      if (item) {
        if (!listData[item]) {
          hasTheContractBeenChosenControl = false;
        }
      }
    });
    if (selected.operators.length == 0) {
      Swal.fire({
        title: "Warning",
        text: "Please select at least one operator",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else if (hasTheContractBeenChosenControl) {
      selected.operators.map((item) => {
        if (item.id == 1) {
          let bot_payload = {
            ...listData,
            contracts: listData.PPcontract,
            hotelId: selected.hotelId,
          };
          setPpLoading("PeakPoint");
          dispatch(sendOfferListServicePP(bot_payload)).then((res) => {
            setPpLoading("");
          });
        } else if (item.id == 2) {
          let bot_payload = {
            ...listData,
            contracts: listData.SHcontract,
          };
          setShLoading("Sunhotel");
          dispatch(sendOfferListServiceSH(bot_payload)).then((res) => {
            setShLoading("");
          });
        } else if (item.id == 3) {
          setDnLoading("Dnata");
          dispatch(sendOfferListServiceDN(listData)).then((res) => {
            setDnLoading("");
          });
        } else if (item.id == 4) {
          let bot_payload = {
            ...listData,
            contracts: listData.WBcontract,
          };
          setWbLoading("Welcomebeds");
          dispatch(sendOfferListServiceWB(bot_payload)).then((res) => {
            setWbLoading("");
          });
        } else if (item.id == 5) {
          setLmxLoading("LMX");
          dispatch(sendOfferListServiceLMX(listData)).then((res) => {
            setLmxLoading("");
          });
        } else if (item.id == 6) {
          setForLoading("Foryou");
          dispatch(sendOfferListServiceFOR(listData)).then((res) => {
            setForLoading("");
          });
        } else if (item.id == 7) {
          setSitLoading("Sitalia");
          dispatch(sendOfferListServiceSIT(listData)).then((res) => {
            setSitLoading("");
          });
        } else if (item.id == 8) {
          let bot_payload = {
            ...listData,
            contracts: listData.WEBBEDScontract,
          };
          setWebbedsLoading("WEBBEDS");
          dispatch(sendOfferListServiceWEBBEDS(bot_payload)).then((res) => {
            setWebbedsLoading("");
          });
        } else if (item.id == 9) {
          let bot_payload = {
            ...listData,
            contracts: listData.JUMBOcontract,
          };
          setJumboLoading("JUMBO");
          dispatch(sendOfferListServiceJUMBO(bot_payload)).then((res) => {
            setJumboLoading("");
          });
        } else if (item.id == 11) {
          setXPurLoading("XPUR");
          dispatch(sendOfferListServiceXPUR(listData)).then((res) => {
            setXPurLoading("");
          });
        } else if (item.id == 10) {
          setLmtLoading("LMT");
          dispatch(sendOfferListServiceLMT(listData)).then((res) => {
            setLmtLoading("");
          });
        } else if (item.id == 12) {
          setIvcLoading("IVC");
          dispatch(sendOfferListServiceIVC(listData)).then((res) => {
            setIvcLoading("");
          });
        } else if (item.id == 15) {
          let bot_payload = {
            ...listData,
            contracts: listData.W2Mcontract,
            hotelId: selected.hotelId,
          };
          setW2mLoading("WORLD 2 MEET");
          dispatch(sendOfferListServiceW2M(bot_payload)).then((res) => {
            setW2mLoading("");
          });
        }
        else if (item.id == 16) {
          let bot_payload = {
            ...listData,
            contracts: listData.PTcontract,
            hotelId: selected.hotelId,
          };
          setPtLoading("PRIME TRAVEL");
          dispatch(sendOfferListServicePT(bot_payload)).then((res) => {
            setPtLoading("");
          });
        }
      });
    } else {
      Swal.fire({
        title: "Warning",
        text: "You have to choose contract",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const operatorsLoading = [
    shLoading,
    wbLoading,
    ppLoading,
    forLoading,
    lmxLoading,
    sitLoading,
    dnLoading,
    webbedsLoading,
    jumboLoading,
    xpurLoading,
    lmtLoading,
    ıvcLoading,
    w2mLoading,
    ptLoading,
  ];
  let loader = !operatorsLoading.every((i) => i == "");

  Object.keys(offerList).forEach((operator) => {
    if (offerList[operator] != undefined) {
      if (operator != "SH") {
        offerList[operator].forEach((offer) => {
          offer.operator_name = operator;
          newOffersArray.push(offer);
        });
      } else {
        if (offerList[operator].length != 0) {
          let offer = offerList[operator];
          offer["operator_name"] = operator;
          newOffersArray.push(offer);
        }
      }
    }
  });


  const operatorOfferTypeStandardization = (operator, name, type) => {
    if (
      (operator == "DN" && name == "% Discount") ||
      (operator == "WB" && name == "TOPPRO") ||
      operator == "LMX" ||
      operator == "FORYOU" ||
      operator == "SIT" ||
      (operator == "LMT" && name == "% off") ||
      operator == "XPUR" ||
      operator == "WEBBEDS" ||
      (operator == "JUMBO" && name == "percent") ||
      (operator == "IVC" && name == "Special Offer")
    ) {
      return type == "type" ? "SPECIAL OFFER" : "Discount";
    } else if (
      (operator == "DN" && name == "Free Night") ||
      (operator == "SH" && name == "Pay stay") ||
      (operator == "WB" && name == "NOCGRA") ||
      (operator == "PP" && name == "FREE NIGHT") ||
      (operator == "W2M" && name == "FREE NIGHT") ||
      (operator == "PT" && name == "FREE NIGHT") ||
      (operator == "JUMBO" && name == "nxm")
    ) {
      return type == "type" ? "FREE NIGHT" : "-";
    } else if (
      (operator == "DN" && name.includes(" Discount")) ||
      (operator == "WB" && name == "DESCUE") ||
      (operator == "JUMBO" && name == "fee")
    ) {
      return type == "type" ? "SPECIAL OFFER" : "Rate";
    } else if (
      (operator == "PP" && name == "EARLY BOOKING") ||
      (operator == "W2M" && name == "EARLY BOOKING") ||
      (operator == "PT" && name == "EARLY BOOKING") ||
      operator == "LMT"
    ) {
      return type == "type" ? "EARLY BOOKING" : "-";
    } else if (operator == "SH" && name == "Value percent") {
      return type == "type" ? "LONG STAY" : "-";
    } else if (
      (operator == "WB" && name == "MEJACO") ||
      (operator == "PP" && name == "UPGRADE") ||
      (operator == "PT" && name == "UPGRADE") ||
      (operator == "W2M" && name == "UPGRADE")

    ) {
      return type == "type" ? "UPGRADE" : "-";
    }
  };
  useEffect(() => {
    let temp = [];
    newOffersArray.map((opr) => {
      if (opr.operator_name == "DN") {
        let roomString = "";
        opr.roomList.length > 0
          ? opr.roomList.map((item) => {
              roomString = roomString + item + " ,";
            })
          : (roomString = "");
        temp.push({
          operator: "DNATA",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization(
            "DN",
            opr.Promotiontype,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "DN",
            opr.Promotiontype,
            "subType"
          ),
          offerName: opr.title,
          roomType: roomString,
          hotel_id: hotels,
          offer_id: opr.offer_id,
          discount: opr.PercentageDiscountToApply,
          sell_start_date: moment(opr.BookingFromDates).format("YYYY-MM-DD"),
          sell_end_date: moment(opr.BookingToDates).format("YYYY-MM-DD"),
          stay_start_date: moment(opr.StayFromDates).format("YYYY-MM-DD"),
          stay_end_date: moment(opr.StayToDates).format("YYYY-MM-DD"),
          sellDate:
            moment(opr.BookingFromDates).format("DD-MM-YYYY") +
            "//" +
            moment(opr.BookingToDates).format("DD-MM-YYYY"),
          stayDate:
            moment(opr.StayFromDates).format("DD-MM-YYYY") +
            "//" +
            moment(opr.StayToDates).format("DD-MM-YYYY"),
          uid: opr.uid,
        });
      }

      if (opr.operator_name == "LMX") {
        stayStart = [];
        stayEnd = [];
        seasonStart = [];
        seasonEnd = [];
        for (let index = 0; index <= 7; index++) {
          let seasons = opr["season" + index];
          let stays = opr["stay" + index];
          if (stays != "") {
            stayStart.push(
              stays
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            stayEnd.push(
              stays
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
          if (seasons != "") {
            seasonStart.push(
              seasons
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            seasonEnd.push(
              seasons
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
        }
        temp.push({
          operator: "LMX",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization("LMX", "", "type"),
          offerSubType: operatorOfferTypeStandardization("LMX", "", "subType"),
          offerName: opr.offers_id,
          offer_id: opr.offers_id,
          roomType: opr.room_code,
          discount: opr.extraPay + "%",
          sell_start_date: seasonStart,
          sell_end_date: seasonEnd,
          stay_start_date: stayStart,
          stay_end_date: stayEnd,
          hotel_id: hotels,
          sellDate: seasonStart[0] + "//" + seasonEnd[0],
          stayDate: stayStart[0] + "//" + stayEnd[0],
          uid: opr.uid,
        });
      }

      if (opr.operator_name == "FORYOU") {
        stayStart = [];
        stayEnd = [];
        seasonStart = [];
        seasonEnd = [];
        for (let index = 0; index <= 7; index++) {
          let seasons = opr["season" + index];
          let stays = opr["stay" + index];
          if (stays != "") {
            stayStart.push(
              stays
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            stayEnd.push(
              stays
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
          if (seasons != "") {
            seasonStart.push(
              seasons
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            seasonEnd.push(
              seasons
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
        }
        temp.push({
          operator: "FORYOU",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization("FORYOU", "", "type"),
          offerSubType: operatorOfferTypeStandardization(
            "FORYOU",
            "",
            "subType"
          ),
          offerName: opr.offers_id,
          offer_id: opr.offers_id,
          roomType: opr.room_code,
          discount: opr.extraPay + "%",
          sell_start_date: seasonStart,
          sell_end_date: seasonEnd,
          hotel_id: hotels,
          stay_start_date: stayStart,
          stay_end_date: stayEnd,
          sellDate: seasonStart[0] + "//" + seasonEnd[0],
          stayDate: stayStart[0] + "//" + stayEnd[0],
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "WB") {
        let tempStart = opr.start_date.split("/");
        let tempEnd = opr.end_date.split("/");
        let tempSellStart = opr.date_1.split("/");
        let tempSellEnd = opr.date_2.split("/");
        tempStart = tempStart[2] + "-" + tempStart[1] + "-" + tempStart[0];
        tempEnd = tempEnd[2] + "-" + tempEnd[1] + "-" + tempEnd[0];
        tempSellStart =
          tempSellStart[2] + "-" + tempSellStart[1] + "-" + tempSellStart[0];
        tempSellEnd =
          tempSellEnd[2] + "-" + tempSellEnd[1] + "-" + tempSellEnd[0];
        temp.push({
          operator: "WELCOMEBEDS",
          operatorCode: opr.operator_name,
          offer_id: opr.id,
          offerType: operatorOfferTypeStandardization("WB", opr.type, "type"),
          offerSubType: operatorOfferTypeStandardization(
            "WB",
            opr.type,
            "subType"
          ),
          offerName: opr.description,
          roomType: "-",
          hotel_id: hotels,
          contract_id: selected.WBcontract,
          discount: opr.promotion,
          sell_start_date: moment(tempSellStart).format("YYYY-MM-DD"),
          sell_end_date: moment(tempSellEnd).format("YYYY-MM-DD"),
          stay_start_date: moment(tempStart).format("YYYY-MM-DD"),
          stay_end_date: moment(tempEnd).format("YYYY-MM-DD"),
          sellDate:
            moment(tempSellStart).format("DD-MM-YYYY") +
            "//" +
            moment(tempSellEnd).format("DD-MM-YYYY"),
          stayDate:
            moment(tempStart).format("DD-MM-YYYY") +
            "//" +
            moment(tempEnd).format("DD-MM-YYYY"),
          uid: opr.uid,
        });
      }

      if (opr.operator_name == "SIT") {
        stayStart = [];
        stayEnd = [];
        seasonStart = [];
        seasonEnd = [];
        for (let index = 0; index <= 7; index++) {
          let seasons = opr["season" + index];
          let stays = opr["stay" + index];
          if (stays != "") {
            stayStart.push(
              stays
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            stayEnd.push(
              stays
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
          if (seasons != "") {
            seasonStart.push(
              seasons
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            seasonEnd.push(
              seasons
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
        }
        temp.push({
          operator: "SITALIA",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization("SIT", "", "type"),
          offerSubType: operatorOfferTypeStandardization("SIT", "", "subType"),
          offerName: opr.offers_id,
          offer_id: opr.offers_id,
          roomType: opr.room_code,
          discount: opr.extraPay + "%",
          sell_start_date: seasonStart,
          sell_end_date: seasonEnd,
          stay_start_date: stayStart,
          hotel_id: hotels,
          stay_end_date: stayEnd,
          sellDate: seasonStart[0] + "//" + seasonEnd[0],
          stayDate: stayStart[0] + "//" + stayEnd[0],
          uid: opr.uid,
        });
      }

      if (opr.operator_name == "WEBBEDS") {
        temp.push({
          operator: "WEBBEDS",
          operatorCode: opr.operator_name,
          offerType: opr.offerType,
          offerSubType: opr.offerSubType,
          offerName: opr.name,
          offer_id: opr.offer_id,
          roomType: opr.roomList,
          discount: opr.discount + "%",
          sell_start_date: opr.BookingFromDates,
          sell_end_date: opr.BookingToDates,
          stay_start_date: opr.StayFromDates,
          stay_end_date: opr.StayToDates,
          hotel_id: hotels,
          sellDate: opr.BookingFromDates + " // " + opr.BookingToDates,
          stayDate: opr.StayFromDates + " // " + opr.StayToDates,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "SH") {
        Object.values(opr).forEach((off) => {
          if (typeof off != "string") {
            temp.push({
              operator: "SUN HOTELS",
              operatorCode: opr.operator_name,
              offerType: operatorOfferTypeStandardization(
                "SH",
                off.offerType,
                "type"
              ),
              offerSubType: operatorOfferTypeStandardization(
                "SH",
                off.offerType,
                "subType"
              ),
              offerName: off.offerType,
              roomType: off.roomName,
              hotel_id: hotels,
              contract_id: selected.SHcontract,
              discount: off.discount,
              sell_start_date: off.sell_start_date,
              sell_end_date: off.sell_end_date,
              stay_start_date: off.stay_start_date,
              stay_end_date: off.stay_end_date,
              sellDate: off.sell_start_date + "//" + off.sell_end_date,
              stayDate: off.stay_start_date + "//" + off.stay_end_date,
              room: off.room,
              offerName: off.offerName,
              dates: off.dates,
              uid: off.uid,
            });
          }
        });
      }
      if (opr.operator_name == "PP") {
        let roomString = "";
        let booking_start = opr.bookingDate.split(" // ")[0];
        let booking_end = opr.bookingDate.split(" // ")[1];
        let stay_start = [];
        let stay_end = [];
        let stay = opr.stayDate.split("||");
        stay.map((s) => {
          stay_start.push(s.split("//")[0]);
          stay_end.push(s.split("//")[1]);
        });
        opr.room_id
          ? typeof opr.room_id == "object"
            ? opr.room_id.map((item) => {
                roomString = roomString + item + " ,";
              })
            : (roomString = opr.room_id)
          : (roomString = "");

        temp.push({
          operator: "PEAKPOINT",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization(
            "PP",
            opr.offerType,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "PP",
            opr.offerType,
            "subType"
          ),
          offerName: opr.offerName,
          //offerType: "-",
          rangosfechas: opr.rangosfechas,
          offer_id: opr.offerId,
          roomType: roomString,
          discount: opr.amount,
          contract_id: selected.PPcontract,
          hotel_id: hotels,
          sell_start_date: booking_start,
          sell_end_date: booking_end,
          stay_start_date: stay_start,
          stay_end_date: stay_end,
          sellDate: opr.bookingDate,
          stayDate: opr.stayDate,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "W2M") {
        let roomString = "";
        let booking_start = opr.bookingDate.split(" // ")[0];
        let booking_end = opr.bookingDate.split(" // ")[1];
        let stay_start = [];
        let stay_end = [];
        let stay = opr.stayDate.split("||");
        stay.map((s) => {
          stay_start.push(s.split("//")[0]);
          stay_end.push(s.split("//")[1]);
        });
        opr.room_id
          ? typeof opr.room_id == "object"
            ? opr.room_id.map((item) => {
                roomString = roomString + item + " ,";
              })
            : (roomString = opr.room_id)
          : (roomString = "");

        temp.push({
          operator: "WORLD 2 MEET",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization(
            "W2M",
            opr.offerType,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "W2M",
            opr.offerType,
            "subType"
          ),
          offerName: opr.offerName,
          rangosfechas: opr.rangosfechas,
          offer_id: opr.offerId,
          roomType: roomString,
          discount: opr.amount,
          contract_id: selected.W2Mcontract,
          hotel_id: hotels,
          sell_start_date: booking_start,
          sell_end_date: booking_end,
          stay_start_date: stay_start,
          stay_end_date: stay_end,
          sellDate: opr.bookingDate,
          stayDate: opr.stayDate,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "PT") {
        let roomString = "";
        let booking_start = opr.bookingDate.split(" // ")[0];
        let booking_end = opr.bookingDate.split(" // ")[1];
        let stay_start = [];
        let stay_end = [];
        let stay = opr.stayDate.split("||");
        stay.map((s) => {
          stay_start.push(s.split("//")[0]);
          stay_end.push(s.split("//")[1]);
        });
        opr.room_id
          ? typeof opr.room_id == "object"
            ? opr.room_id.map((item) => {
                roomString = roomString + item + " ,";
              })
            : (roomString = opr.room_id)
          : (roomString = "");

        temp.push({
          operator: "PRIME TRAVEL",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization(
            "PT",
            opr.offerType,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "PT",
            opr.offerType,
            "subType"
          ),
          offerName: opr.offerName,
          rangosfechas: opr.rangosfechas,
          offer_id: opr.offerId,
          roomType: roomString,
          discount: opr.amount,
          contract_id: selected.PTcontract,
          hotel_id: hotels,
          sell_start_date: booking_start,
          sell_end_date: booking_end,
          stay_start_date: stay_start,
          stay_end_date: stay_end,
          sellDate: opr.bookingDate,
          stayDate: opr.stayDate,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "JUMBO") {
        temp.push({
          operator: "JUMBO",
          operatorCode: opr.operator_name,
          offerSubType: "",
          offerName: opr.name,
          offerType: operatorOfferTypeStandardization(
            "JUMBO",
            opr.type,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "JUMBO",
            opr.type,
            "subType"
          ),
          offer_id: opr.id,
          roomType: opr.rooms,
          discount: opr.percent,
          contract_id: selected.JUMBOcontract,
          hotel_id: hotels,
          sell_start_date: opr.sell_from,
          sell_end_date: opr.sell_to,
          stay_start_date: opr.stay_from,
          stay_end_date: opr.stay_to,
          stayDate: opr.stayDaysString,
          sellDate: opr.sellDaysString,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "LMT") {
        let sell_from = opr.BookingFromDates.replaceAll("/", "-");
        let sell_to = opr.BookingToDates.replaceAll("/", "-");
        let stay_from = opr.StayFromDates.replaceAll("/", "-");
        let stay_to = opr.StayToDates.replaceAll("/", "-");
        temp.push({
          operator: "LMT",
          operatorCode: opr.operator_name,
          offerSubType: "",
          offerName: opr.name,
          offerType: operatorOfferTypeStandardization(
            "LMT",
            opr.offerType,
            "type"
          ),
          offerSubType: operatorOfferTypeStandardization(
            "LMT",
            opr.offerType,
            "subType"
          ),
          offer_id: opr.offer_id,
          roomType: opr.rooms,
          discount: opr.discount,
          hotel_id: hotels,
          sell_start_date: sell_from,
          sell_end_date: sell_to,
          stay_start_date: stay_from,
          stay_end_date: stay_to,
          sellDate: sell_from + "//" + sell_to,
          stayDate: stay_from + "//" + stay_to,
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "XPUR") {
        stayStart = [];
        stayEnd = [];
        seasonStart = [];
        seasonEnd = [];
        for (let index = 0; index <= 7; index++) {
          let seasons = opr["season" + index];
          let stays = opr["stay" + index];
          if (stays != "") {
            stayStart.push(
              stays
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            stayEnd.push(
              stays
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
          if (seasons != "") {
            seasonStart.push(
              seasons
                .split(" - ")[0]
                .replace(".", "-")
                .replace(".", "-")
            );
            seasonEnd.push(
              seasons
                .split(" - ")[1]
                .replace(".", "-")
                .replace(".", "-")
            );
          }
        }
        temp.push({
          operator: "XPUR",
          operatorCode: opr.operator_name,
          offerType: operatorOfferTypeStandardization("XPUR", "", "type"),
          offerSubType: operatorOfferTypeStandardization("XPUR", "", "subType"),
          offerName: opr.offers_id,
          offer_id: opr.offers_id,
          roomType: opr.room_code,
          discount: opr.extraPay + "%",
          sell_start_date: seasonStart,
          sell_end_date: seasonEnd,
          hotel_id: hotels,
          stay_start_date: stayStart,
          stay_end_date: stayEnd,
          sellDate: seasonStart[0] + "//" + seasonEnd[0],
          stayDate: stayStart[0] + "//" + stayEnd[0],
          uid: opr.uid,
        });
      }
      if (opr.operator_name == "IVC") {
        temp.push({
          operator: "IVC",
          operatorCode: "IVC",
          offerSubType: "",
          offerName: opr.offer_name + "//" + opr.offer_status,
          offerType: operatorOfferTypeStandardization(
            "IVC",
            opr.offer_type,
            "type"
          ),
          offerSubType: "-",
          offer_id: opr.offer_id,
          roomType: opr.room_name_string,
          discount: opr.offer_percentage,
          contract_id: selected.IVCcontract,
          hotel_id: hotels,
          sell_start_date: opr.sell_start_date,
          sell_end_date: opr.sell_end_date,
          stay_start_date: opr.stay_start_date,
          stay_end_date: opr.stay_end_date,
          stayDate: opr.stay_start_date + "//" + opr.stay_end_date,
          sellDate: opr.sell_start_date + "//" + opr.sell_end_date,
          uid: opr.uid,
        });
      }
    });
    setAllOfferList({
      ...allOfferList,
      temp,
    });
    let operatorLabelArr = selected.operators.map((sO) => {
      if (sO.label == "WB") {
        return "WELCOMEBEDS";
      } else if (sO.label == "DN") {
        return "DNATA";
      } else if (sO.label == "SIT") {
        return "SITALIA";
      } else if (sO.label == "PP") {
        return "PEAKPOINT";
      } else if (sO.label == "SH") {
        return "SUN HOTELS";
      } else if (sO.label == "FOR") {
        return "FORYOU";
      } else if (sO.label == "W2M") {
        return "WORLD 2 MEET";
      } else if (sO.label == "PT") {
        return "PRIME TRAVEL";
      }
      return sO.label;
    });

    temp = temp?.filter(
      (index) => operatorLabelArr.indexOf(index.operator) !== -1
    );
    setFilteredData(temp);
  }, [offerList]);


  const filterHandleChange = (e) => {
    const { name, value } = e;
    setOfferList((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterSelected = () => {
    let newList = allOfferList.temp;
    Object.keys(offerLists).map((item) => {
      if (offerLists[item] != "") {
        let objectKey = item;
        newList = newList.filter((offer) =>
          customFilter(offer, objectKey, offerLists)
        );
      }
    });
    setFilteredData(newList);
  };

  const [checked, setChecked] = React.useState([]);

  const resetSelectDelete = () => {
    setshowIcon(false);
    setChecked([]);
  };

  const handleToggle = (value) => {
    const newChecked = { ...checked };

    if (newChecked.hasOwnProperty(value.operator)) {
      if (newChecked[value.operator].includes(value.tableData.id)) {
        newChecked[value.operator] = newChecked[value.operator].filter(
          (op) => op !== value.tableData.id
        );
      } else {
        newChecked[value.operator].push(value.tableData.id);
      }
    } else {
      newChecked[value.operator] = [value.tableData.id];
    }
    setChecked(newChecked);
  };

  const handleToggleAll = (items) => {
    const newChecked = checked;
    const filteredType = filteredData.filter(
      (data) => data.operator === items.operator
    );

    if (
      newChecked.hasOwnProperty(items.operator) &&
      filteredType.length === newChecked[items.operator]?.length
    ) {
      setChecked({ ...checked, [items.operator]: [] });
      return;
    }

    setChecked({
      ...checked,
      [items.operator]: filteredType.map((type) => type.tableData.id),
    });
  };
  const selectAllHandler = () => {
    const grouped = _.groupBy(filteredData, "operator");
    const newData = {};
    Object.keys(grouped).map((key) => {
      newData[key] = grouped[key].map((operator) => operator.tableData.id);
    });
    setChecked(newData);
  };
  const sendAllSelectionDeleteOffers = () => {
    const pId = Math.floor(new Date().valueOf() * Math.random());
    let formattedOffers = [];
    checked &&
      Object.values(checked).map((checkedArray) => {
        Object.values(checkedArray).map((checked_id) => {
          const fd = filteredData.find(
            (elements) => elements.tableData.id == checked_id
          );
          if (fd !== undefined) {
            fd["hotel_id"] = selected.hotelId;
            formattedOffers.push(fd);
          }
        });
      });
    setChecked(formattedOffers);
    let SUNHOTEL = [];
    let DNATA = [];
    let WELCOMEBEDS = [];
    let FORYOU = [];
    let LMX = [];
    let PEAKPOINT = [];
    let SITALIA = [];
    let WEBBEDS = [];
    let JUMBO = [];
    let XPUR = [];
    let LASTMINUTE = [];
    let IVC = [];
    let WORLD2MEET = [];
    let PRIMETRAVEL = [];
    formattedOffers.map((item) => {
      if (item.operator == "SUN HOTELS") {
        item["operator_id"] = 2; //bu idleri başka şekilde yollasam daha iyi ne yapcam ben
        SUNHOTEL.push(item);
      } else if (item.operator == "DNATA") {
        item["operator_id"] = 3;
        DNATA.push(item);
      } else if (item.operator == "WELCOMEBEDS") {
        item["operator_id"] = 4;
        WELCOMEBEDS.push(item);
      } else if (item.operator == "FORYOU") {
        item["operator_id"] = 6;
        FORYOU.push(item);
      } else if (item.operator == "PEAKPOINT") {
        item["operator_id"] = 1;
        PEAKPOINT.push(item);
      } else if (item.operator == "SITALIA") {
        item["operator_id"] = 7;
        SITALIA.push(item);
      } else if (item.operator == "LMX") {
        item["operator_id"] = 5;
        LMX.push(item);
      } else if (item.operator == "WEBBEDS") {
        item["operator_id"] = 8;
        WEBBEDS.push(item);
      } else if (item.operator == "JUMBO") {
        item["operator_id"] = 9;
        JUMBO.push(item);
      } else if (item.operator == "LMT") {
        item["operator_id"] = 10;
        LASTMINUTE.push(item);
      } else if (item.operator == "XPUR") {
        item["operator_id"] = 11;
        XPUR.push(item);
      } else if (item.operator == "IVC") {
        item["operator_id"] = 12;
        IVC.push(item);
      } else if (item.operator == "WORLD 2 MEET") {
        item["operator_id"] = 15;
        WORLD2MEET.push(item);
      } else if (item.operator == "PRIME TRAVEL") {
        item["operator_id"] = 16;
        PRIMETRAVEL.push(item);
      }
    });
    const allOperators = Object.keys(checked).map((ch) => {
      return ch;
    });
    PEAKPOINT.length !== 0
      ? dispatch(deleteOfferListPP(PEAKPOINT, allOperators, pId))
      : (PEAKPOINT = []);
    SUNHOTEL.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListSH(SUNHOTEL, allOperators, pId));
        }, 1)
      : (SUNHOTEL = []);
    WELCOMEBEDS.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListWB(WELCOMEBEDS, allOperators, pId));
        }, 2)
      : (WELCOMEBEDS = []);
    FORYOU.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListFOR(FORYOU, allOperators, pId));
        }, 3)
      : (FORYOU = []);
    DNATA.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListDN(DNATA, allOperators, pId));
        }, 4)
      : (DNATA = []);
    WEBBEDS.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListWEBBEDS(WEBBEDS, allOperators, pId));
        }, 5)
      : (WEBBEDS = []);
    SITALIA.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListSIT(SITALIA, allOperators, pId));
        }, 6)
      : (SITALIA = []);
    LMX.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListLMX(LMX, allOperators, pId));
        }, 7)
      : (LMX = []);
    JUMBO.length !== 0 ? dispatch(deleteOfferListJUMBO(JUMBO)) : (JUMBO = []);
    LASTMINUTE.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListLMT(LASTMINUTE, allOperators, pId));
        }, 8)
      : (LASTMINUTE = []);
    XPUR.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListXPUR(XPUR, allOperators, pId));
        }, 11)
      : (XPUR = []);
    IVC.length !== 0
      ? setTimeout(() => {
          dispatch(deleteOfferListIVC(IVC, allOperators, pId));
        }, 12)
      : (IVC = []);
    WORLD2MEET.length !== 0
    ? setTimeout(() => {
      dispatch(deleteOfferListW2M(WORLD2MEET, allOperators, pId))
    }, 13)
    : (WORLD2MEET = []);
    PRIMETRAVEL.length !== 0
    ? setTimeout(() => {
      dispatch(deleteOfferListPT(PRIMETRAVEL, allOperators, pId))
    }, 14)
    : (PRIMETRAVEL = []);
    //eğer checked ve formattedoffers boşaltılmazsa önceki silinen offerlarla birlikte yolladığı için silinmicek olan offerları da silebiliyor
    setChecked([]);
    formattedOffers = [];
  };


  let ShContracts = [];
  let PpContracts = [];
  let WBcontracts = [];
  let WEBBEDScontracts = [];
  let IVCcontracts = [];
  let JUMBOcontracts = [];
  let W2MContracts = [];
  let PTContracts = [];


  const contractControl = () => {
    if (selected.operators) {
      let contractIds = selected.operators.reduce((acc, current) => {
        if (
          current.id == 1 ||
          current.id == 2 ||
          current.id == 4 ||
          current.id == 8 ||
          current.id == 9 ||
          current.id == 12 ||
          current.id == 15 ||
          current.id == 16 

        ) {
          acc.push(current.id);
        }
        return acc;
      }, []);
      if (contractIds.length >= 2) {
        return contractIds;
      } else {
        return contractIds;
      }
    }
  };

  let contractOptions = contractControl();


  const newOpContracts = () => {
    let filteredHotels = {
      ...SHhotels,
      ...PPhotels,
      ...WBhotels,
      ...WEBBEDShotels,
      ...JUMBOhotels,
      ...IVChotels,
      ...W2Mhotels,
      ...PThotels,
    };
    Object.values(filteredHotels).map((item) => {
      contracts !== undefined &&
        Object.values(contracts).map((contract) => {
          if (item.id == contract.operator_hotel_id) {
            contract["operator_id"] = item.operator_id;
          }
        });
    });

    if (contracts) {
      Object.values(contracts).map((cont) => {
        if (cont.operator_id == 1) {
          PpContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 2) {
          ShContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 4) {
          WBcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 8) {
          WEBBEDScontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 9) {
          JUMBOcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 12) {
          IVCcontracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 15) {
          W2MContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        } else if (cont.operator_id == 16) {
          PTContracts.push({
            id: cont.contract_id,
            label: cont.contract_name,
          });
        }
      });
    }
  };

  
  const handleChangeOperator = (event) => {
    handleChange("operators", event);
  };
  const handleChange = (name, value) => {
    if (name == "hotel_id") {
      dispatch(getOfferList({ data: {} }));
      /*  setTableFlag(false); */
      if (value.id === undefined) {
        setSelected({
          hotelId: value.id,
          operators: [],
        });
      } else {
        setSelected({
          ...selected,
          hotelId: value.id,
          operators: [],
        });
      }
    } else if (name == "onlyOperatorSH") {
      setSelected({
        ...selected,
        contracts: value,
        SHcontract: value,
      });
    } else if (name == "onlyOperatorPP") {
      setSelected({
        ...selected,
        contracts: value,
        PPcontract: value,
      });
    } else if (name == "onlyOperatorWB") {
      setSelected({
        ...selected,
        WBcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorWEBBEDS") {
      setSelected({
        ...selected,
        WEBBEDScontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorJUMBO") {
      setSelected({
        ...selected,
        JUMBOcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorIVC") {
      setSelected({
        ...selected,
        IVCcontract: value,
        contracts: value,
      });
    } else if (name == "onlyOperatorW2M") {
      setSelected({
        ...selected,
        contracts: value,
        W2Mcontract: value,
      });
    } else if (name == "onlyOperatorPT") {
      setSelected({
        ...selected,
        contracts: value,
        PTcontract: value,
      });
    }
     else if (name === "operators") {
      if (value.length === 0) {
        setSelected({
          hotelId: selected.hotelId,
          operators: value,
        });
      } else {
        const keysOfSelected = Object.keys(selected);
        const getOperatorKeys = keysOfSelected.reduce((acc, current) => {
          if (current.endsWith("contract")) {
            acc.push(current.split("contract")[0]);
          }
          return acc;
        }, []);

        const getLabels = value.map((item) => item.label);
        const keysThatShouldBeDeleted = getOperatorKeys.filter(
          (item) => !getLabels.includes(item)
        );

        const newSelected = Object.entries(selected).reduce((acc, current) => {
          const label = current[0].split("contract")[0];
          if (!keysThatShouldBeDeleted.includes(label)) {
            acc[current[0]] = current[1];
          }

          return acc;
        }, {});
        setSelected({
          ...newSelected,
          hotelId: selected.hotelId,
          operators: value,
        });
      }
    } else {
      setSelected({ ...selected, [name]: value });
    }
  };
 
  const tableColumns=[
    {
      title: <IntlMessages id="Select" />,
      field: "select",

      render: (rowData) => {
        console.log(rowData);
        const isChecked = checked[rowData.operator]?.includes(
          rowData.tableData.id
          );
        const isAllChecked =
          filteredData.filter((data) => data.operator === rowData.operator)
            .length === checked[rowData.operator]?.length;

        return (
          <>
            <DropdownSelection
              handleToggleAll={() => handleToggleAll(rowData)}
              handleToggle={() => handleToggle(rowData)}
              resetSelectDelete={() => resetSelectDelete()}
              sendAllSelectionDeleteOffers={() =>
                sendAllSelectionDeleteOffers()
              }
              checked={checked}
              dataId={rowData.tableData.id}
              operator={rowData.operator}
              text={isAllChecked ? "Unselect Opr" : "Select Opr"}
              textOnlyRow={isChecked ? "Unselect" : "Select"}
            />
            {isChecked && (
              <CheckIcon
                fontSize="medium"
                severity="success"
                style={{ color: "green" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: <IntlMessages id="operator" />,
      field: "operator",
    },
    {
      title: <IntlMessages id="offer.type" />,
      field: "offerType",
    },
    {
      title: <IntlMessages id="offer.sub.type" />,
      field: "offerSubType",
    },
    {
      title: <IntlMessages id="offer.name" />,
      field: "offerName",
    },
    {
      title: <IntlMessages id="room.type" />,
      field: "roomType",
    },
    {
      title: <IntlMessages id="discount/amount" />,
      field: "discount",
    },
    {
      title: <IntlMessages id="sell.date" />,
      field: "sellDate",
    },
    {
      title: <IntlMessages id="stay.date" />,
      field: "stayDate",
    },
  ]

  let operatorsNames = "";
  operatorsLoading.map((i) => {
    if (i != "") {
      operatorsNames = i + ", " + operatorsNames;
    }
  });

  const showOffersActiveHandler = () => {
    // DESC - If no hotel choosen
    if (!selected.hotelId) return false;

    // DESC - If no operator choosen
    if (!Reflect.has(selected, "operators")) return false;
    if (selected.operators.length === 0) return false;

    // DESC - If required contracts not choosen
    if (contracts !== undefined) {
      // DESC - Compare contract amount and filled contract amount
      const contractIds = Object.values(contracts).map(
        (contract) => contract.contract_id
      );

      let passed = 0;

      Object.keys(selected).forEach((key) => {
        if (key.endsWith("contract")) {
          if (contractIds.includes(selected[key])) {
            passed = passed + 1;
          }
        }
      });

      const hasOnlyOneOperator = hasOnlyOperatorWithContract();
      let shouldShow = true;
      if (hasOnlyOneOperator) {
        Object.entries(selected).forEach(([key, value]) => {
          if (key.endsWith("contract")) {
            if (value && selected["contracts"] !== value) {
              shouldShow = false;
            }
          }
        });
      }
      if (!shouldShow) {
        return false;
      }
      return contractOptions.length === passed;
    }

    return true;
  };
  useEffect(() => {
    setShowOffersActive(showOffersActiveHandler());
  }, [selected, contracts]);

  const hasOnlyOperatorWithContract = () => {
    const operatorLabels = ["SH", "WB", "PP", "WEBBEDS", "JUMBO","W2M","PT"];
    return selected.operators.filter((operator) => {
      return operatorLabels.includes(operator.label);
    }).length === 1
      ? true
      : false;
  };

  return (
    <Paper>
      <DialogTitle disableTypography>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <IntlMessages id="offer.list" />
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setShowOfferDiscounts(false);
                setFilteredData([]);
                setAllOfferList([]);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container spacing={3}>
          <Grid item xs={3}>
            <Autocomplete
              freeSolo
              label={<IntlMessages id="hotel" />}
              id="selectedHotel"
              options={hotelName}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  error={selected.hotelId ? false : true}
                  {...params}
                  label={<IntlMessages id="hotel" />}
                />
              )}
              onChange={(event, newValue) => {
                handleChange("hotel_id", newValue ? newValue : {});
              }}
            />
          </Grid>

          <Grid item xs={3} style={{ marginTop: "-0.625rem" }}>
            <ThemeProvider theme={theme}>
              <MultiSelect
                error={selected.operators.length == 0 ? true : false}
                label="Operators"
                options={multiDataSetter(
                  "label",
                  "id",
                  operatorOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
                )}
                value={multiDataSetter("label", "id", selected.operators)}
                handleChange={(event) => handleChangeOperator(event)}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={6}>
            {contractOptions.length >= 2 ? (
              <>
                {newOpContracts()}
                <Grid container spacing={4}>
                  {contractOptions.includes(2) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="sun.hotels.contracts" />
                        </InputLabel>
                        <Select
                          error={selected.SHcontract ? false : true}
                          label={<IntlMessages id="sun.hotels.contracts" />}
                          value={selected.SHcontract ? selected.SHcontract : ""}
                          onChange={(event, value) => {
                            handleChange("SHcontract", event.target.value);
                          }}
                        >
                          {ShContracts
                            ? ShContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(1) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="peakpoint.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selected.PPcontract ? false : true}
                          label={<IntlMessages id="peakpoint.contracts" />}
                          value={selected.PPcontract ? selected.PPcontract : ""}
                          onChange={(event, value) => {
                            handleChange("PPcontract", event.target.value);
                          }}
                        >
                          {PpContracts
                            ? PpContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(4) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="welcomebeds.contracts" />
                        </InputLabel>
                        <Select
                          error={selected.WBcontract ? false : true}
                          label={<IntlMessages id="welcomebeds.contracts" />}
                          value={selected.WBcontract ? selected.WBcontract : ""}
                          onChange={(event, value) => {
                            handleChange("WBcontract", event.target.value);
                          }}
                        >
                          {WBcontracts
                            ? WBcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(8) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="webbeds.contracts" />
                        </InputLabel>
                        <Select
                          error={selected.WEBBEDScontract ? false : true}
                          label={<IntlMessages id="webbeds.contracts" />}
                          value={
                            selected.WEBBEDScontract
                              ? selected.WEBBEDScontract
                              : ""
                          }
                          onChange={(event, value) => {
                            handleChange("WEBBEDScontract", event.target.value);
                          }}
                        >
                          {WEBBEDScontracts
                            ? WEBBEDScontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(9) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="jumbo.contracts" />
                        </InputLabel>
                        <Select
                          error={selected.JUMBOcontract ? false : true}
                          label={<IntlMessages id="jumbo.contracts" />}
                          value={
                            selected.JUMBOcontract ? selected.JUMBOcontract : ""
                          }
                          onChange={(event, value) => {
                            handleChange("JUMBOcontract", event.target.value);
                          }}
                        >
                          {JUMBOcontracts
                            ? JUMBOcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(12) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="ıvector.contracts" />
                        </InputLabel>
                        <Select
                          error={selected.IVCcontract ? false : true}
                          label={<IntlMessages id="ıvector.contracts" />}
                          value={
                            selected.IVCcontract ? selected.IVCcontract : ""
                          }
                          onChange={(event, value) => {
                            handleChange("IVCcontract", event.target.value);
                          }}
                        >
                          {IVCcontracts
                            ? IVCcontracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(15) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="world2meet.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selected.W2Mcontract ? false : true}
                          label={<IntlMessages id="world2meet.contracts" />}
                          value={selected.W2Mcontract ? selected.W2Mcontract : ""}
                          onChange={(event, value) => {
                            handleChange("W2Mcontract", event.target.value);
                          }}
                        >
                          {W2MContracts
                            ? W2MContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {contractOptions.includes(16) && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="primetravel.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selected.PTcontract ? false : true}
                          label={<IntlMessages id="primetravel.contracts" />}
                          value={selected.PTcontract ? selected.PTcontract : ""}
                          onChange={(event, value) => {
                            handleChange("PTcontract", event.target.value);
                          }}
                        >
                          {PTContracts
                            ? PTContracts.map((item, i) => (
                                <MenuItem value={item.id}>
                                  {item.label}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : contractOptions.length != 0 ? (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>
                    <IntlMessages id="contracts" />
                  </InputLabel>
                  <Select
                    label={<IntlMessages id="contracts" />}
                    value={selected.contracts ? selected.contracts : ""}
                    onChange={(event, value) => {
                      if (contractOptions.includes(4)) {
                        handleChange("onlyOperatorWB", event.target.value);
                      } else if (contractOptions.includes(2)) {
                        handleChange("onlyOperatorSH", event.target.value);
                      } else if (contractOptions.includes(1)) {
                        handleChange("onlyOperatorPP", event.target.value);
                      } else if (contractOptions.includes(8)) {
                        handleChange("onlyOperatorWEBBEDS", event.target.value);
                      } else if (contractOptions.includes(9)) {
                        handleChange("onlyOperatorJUMBO", event.target.value);
                      } else if (contractOptions.includes(12)) {
                        handleChange("onlyOperatorIVC", event.target.value);
                      } else if (contractOptions.includes(15)) {
                        handleChange("onlyOperatorW2M", event.target.value);
                      } else if (contractOptions.includes(16)) {
                        handleChange("onlyOperatorPT", event.target.value);
                      }
                    }}
                  >
                    {contractsOptions
                      ? contractsOptions.map((item, i) => (
                          <MenuItem value={item.id}>{item.label}</MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid justifyContent="flex-end" container item xs={12}>
            <ThemeProvider theme={theme}>
              {/* REVIEW: Tooltip ve operator seçildi kontrolü */}
              <Tooltip
                disableFocusListener
                title={
                  !(selected.operators.length > 0 && selected.hotelId != "")
                    ? "Choose a Operator and Hotel"
                    : ""
                }
                arrow
              >
                <>
                  {showOffersActive && (
                    <Button
                      variant="contained"
                      disabled={loader}
                      onClick={() => listOffers()}
                      color="primary"
                    >
                      {loader
                        ? operatorsNames.slice(0, -2) +
                          " " +
                          intl.formatMessage({ id: "loading" })
                        : intl.formatMessage({ id: "show.offers" })}
                    </Button>
                  )}

                  {loader && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </>
              </Tooltip>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 20, marginTop: 15 }} />
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <IntlMessages id="filter" />
          </Box>
        </Box>

        <Grid style={{ paddingTop: 0, marginTop: 5 }} container spacing={3}>
          <Grid item xs={3}>
            <KeyboardDatePicker
              name="sell_start_date"
              autoOk="true"
              label={<IntlMessages id="sell.start.date" />}
              format="DD/MM/YYYY"
              value={
                offerLists.sell_start_date === ""
                  ? null
                  : moment(offerLists.sell_start_date).format("YYYY-MM-DD")
              }
              onChange={(date) =>
                setOfferList({
                  ...offerLists,
                  sell_start_date: moment(date).format("YYYY-MM-DD"),
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              name="sell_end_date"
              autoOk="true"
              label={<IntlMessages id="sell.end.date" />}
              format="DD/MM/YYYY"
              value={
                offerLists.sell_end_date === ""
                  ? null
                  : moment(offerLists.sell_end_date).format("YYYY-MM-DD")
              }
              onChange={(date) =>
                setOfferList({
                  ...offerLists,
                  sell_end_date: moment(date).format("YYYY-MM-DD"),
                })
              }
              animateYearScrolling
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              name="stay_start_date"
              autoOk="true"
              label={<IntlMessages id="stay.start.date" />}
              format="DD/MM/YYYY"
              value={
                offerLists.stay_start_date === ""
                  ? null
                  : moment(offerLists.stay_start_date).format("YYYY-MM-DD")
              }
              onChange={(date) => {
                setOfferList({
                  ...offerLists,
                  stay_start_date: moment(date).format("YYYY-MM-DD"),
                });
              }}
              animateYearScrolling
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              name="stay_end_date"
              autoOk="true"
              format="DD/MM/YYYY"
              value={
                offerLists.stay_end_date === ""
                  ? null
                  : moment(offerLists.stay_end_date).format("YYYY-MM-DD")
              }
              onChange={(date) => {
                setOfferList({
                  ...offerLists,
                  stay_end_date: moment(date).format("YYYY-MM-DD"),
                });
              }}
              label={<IntlMessages id="stay.end.date" />}
              animateYearScrolling
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs={12} container style={{ marginTop: 25, marginBottom: 25 }}>
            <Grid item xs={2} style={{ marginLeft: 10 }}>
              <Autocomplete
                options={Object.values(operators)}
                onChange={(e, value) => {
                  let event = {
                    name: "operator",
                    value: value != null ? value.name : "",
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="Select Operator"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: 10 }}>
              <Autocomplete
                options={Object.values(offerTypesinOperator)}
                onChange={(e, value) => {
                  let event = {
                    name: "offerType",
                    value: value?.label != null ? value?.label : "",
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="Select Offer Type"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            {//subtype
            offerLists?.offerType == "SPECIAL OFFER" ? (
              <Grid item xs={2} style={{ marginLeft: 10 }}>
                <Autocomplete
                  options={Object.keys(
                    Object.values(offerTypesinOperator).find((offer_type) => {
                      return offer_type.id == 3;
                    })?.subTypes
                  )}
                  onChange={(e, value) => {
                    let event = {
                      name: "offerSubType",
                      value: value != null ? value : "",
                    };
                    filterHandleChange(event);
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder="Select Sub Type"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <MaterialTable
          title={""}
          columns={tableColumns}
          data={filteredData ? filteredData : []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40],
            search: false,
            actionsColumnIndex: -1,
            tableLayout: "auto",
            addRowPosition: "first",
          }}
          components={{
            OverlayLoading: () => <Loader />,
            Toolbar: (props) => {
              return (
                <div style={{ padding: "20px 0" }}>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={selectAllHandler}
                    style={{ marginRight: "12px" }}
                  >
                    Select All
                  </Button>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={resetSelectDelete}
                  >
                    Unselect All
                  </Button>

                  <Button
                    variant="contained"
                    color="grey"
                    aria-controls="customized-menu"
                    size="small"
                    style={{ float: "right" }}
                    onClick={() => filterSelected()}
                  >
                    <IntlMessages id="filter" />
                  </Button>
                </div>
              );
            },
          }}
        />
        <div style={{ float: "right", margin: "1rem" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={sendAllSelectionDeleteOffers}
            startIcon={<DeleteIcon />}
            style={{
              paddind: "0.5rem",
              backgroundColor: "red",
              color: "white",
            }}
            disabled={!isAuthDelete}
          >
            Delete Offers
          </Button>
        </div>
      </DialogContent>
    </Paper>
  );
}

export default OfferListModal;
