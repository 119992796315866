import { GET_MARKETS, ADD_MARKET, UPDATE_MARKET, DELETE_MARKET } from "./types";

export const getMarket = res => dispatch => {
  dispatch({
    type: GET_MARKETS,
    payload: res.data.data
  });
};
export const addMarket = res => dispatch =>
  dispatch({
    type: ADD_MARKET,
    payload: res.data.data
  });

export const updateMarket = res => dispatch =>
  dispatch({
    type: UPDATE_MARKET,
    payload: res.data.data
  });

export const deleteMarket = res => dispatch =>
  dispatch({
    type: DELETE_MARKET,
    payload: res.data.data
  });

export const addMeetpointMarket = res => dispatch =>
  dispatch({
    type: ADD_MARKET,
    payload: res.data.data.markets
  });
