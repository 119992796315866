// import axios from "axios";

// export default axios.create({
//     baseURL: "http://localhost:80/api/", //YOUR_API_URL HERE
//     headers: {
//         "Content-Type": "application/json",
//     },
// });



// ////stage api

// import axios from "axios";

// export default axios.create({
//     baseURL: "https://stagebotapi.mundosai.com/api/", //YOUR_API_URL HERE
//     headers: {
//         "Content-Type": "application/json",
//     },
// });


// live api (keyf)

import axios from "axios";

export default axios.create({
    baseURL: "https://bot.proxolab.com/api/", //YOUR_API_URL HERE
    headers: {
        "Content-Type": "application/json",
    },
});
