import {
  GET_PERMISSIONS_BY_AUTH_USER,
  ADD_USER_PERMISSION
} from "@jumbo/constants/ActionTypes";

const INITIAL_STATE = {
  permissionsByAuthUser: []
};

const permissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_BY_AUTH_USER:
      return { ...state, permissionsByAuthUser: action.payload };
    case ADD_USER_PERMISSION:
      if (
        state.permissionsByAuthUser[0].user_id === action.payload[0].user_id
      ) {
        return { ...state, permissionsByAuthUser: action.payload };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default permissionReducer;
