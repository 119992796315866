import { addUpdateNormalize } from "redux/schemas/addUpdateContract";
import { normalizeContractRooms } from "redux/schemas/contractRoom";
import {
  GET_CONTRACT,
  ADD_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  ADD_CONTRACT_ROOM,
  UPDATE_CONTRACT_ROOM,
  DELETE_CONTRACT_ROOM,
  GET_CONTRACT_ROOM,
  IS_SAVED_CONTRACT,
  SET_SELECTED_CONTRACT,
  CHANGE_CONTRACT_STATUS,
  IS_UPDATE_CONTRACT,
  COPY_CONTRACT,
  CHECK_CONTRACT_DATA_IS_UPDATE
} from "../actions/types";

const initialState = {
  contracts: [],
  selectedContract: 0,
  contract_room: [],
  room: [],
  contract_status: [],
  isSaved: "",
  contract_has_board: [],
  board: [],
  market: [],
  countries: [],
  contract_season: [],
  contract_allotment_type: [],
  contract_user: [],
  contract_room_price: [],
  isUpdate: "",
  checkContractDataIsUpdate: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT:
      return {
        ...state,
        contracts: action.payload.contracts ? action.payload.contracts : [],
        get_operator: action.payload.get_operator
          ? action.payload.get_operator
          : [],
        contract_room: action.payload.contract_room
          ? action.payload.contract_room
          : [],
        room: action.payload.room ? action.payload.room : [],
        contract_status: action.payload.contract_status,
        contract_has_board: action.payload.contract_has_board
          ? action.payload.contract_has_board
          : [],
        board: action.payload.board ? action.payload.board : [],
        contract_room_price: action.payload.contract_room_price
          ? action.payload.contract_room_price
          : [],
        contract_user: action.payload.contract_user
          ? action.payload.contract_user
          : [],
        contract_allotment_type: action.payload.contract_allotment_type
          ? action.payload.contract_allotment_type
          : [],
        contract_season: action.payload.contract_season
          ? action.payload.contract_season
          : [],
        market: action.payload.market ? action.payload.market : [],
        countries: action.payload.countries ? action.payload.countries : [],
        count: action.payload.count ? action.payload.count : 0
      };
    case ADD_CONTRACT:
      const contractData = [];
      contractData["contract"] = [action.payload];
      contractData["addContract"] = state.contracts;
      contractData["addContract_allotment_type"] =
        state.contract_allotment_type;
      contractData["addContract_has_board"] = state.contract_has_board;
      contractData["addContract_status"] = state.contract_status;
      contractData["addContract_user"] = state.contract_user;
      contractData["addBoard"] = state.board;
      contractData["addOperator"] = state.operator;
      contractData["addContract_season"] = state.contract_season;
      contractData["addMarket"] = state.market;
      contractData["addCountries"] = state.countries;

      const normalizeDataContract = addUpdateNormalize([contractData]).entities;
      return {
        ...state,
        contracts: normalizeDataContract.contracts
          ? normalizeDataContract.contracts
          : [],
        contract_status: normalizeDataContract.contract_status,
        contract_has_board: normalizeDataContract.contract_has_board
          ? normalizeDataContract.contract_has_board
          : [],
        board: normalizeDataContract.board ? normalizeDataContract.board : [],
        contract_user: normalizeDataContract.contract_user
          ? normalizeDataContract.contract_user
          : [],
        contract_allotment_type: normalizeDataContract.contract_allotment_type
          ? normalizeDataContract.contract_allotment_type
          : [],
        contract_season: normalizeDataContract.contract_season
          ? normalizeDataContract.contract_season
          : [],
        market: normalizeDataContract.market
          ? normalizeDataContract.market
          : [],
        countries: normalizeDataContract.countries
          ? normalizeDataContract.countries
          : [],
        count: action.payload.count ? action.payload.count : 0
      };
    case UPDATE_CONTRACT:
      const updatedData1 = [];
      updatedData1["contract"] = [action.payload];
      updatedData1["updatedContract"] = state.contracts;

      updatedData1["addContract_has_board"] = Object.values(
        state.contract_has_board
      ).filter(board => {
        return board.contract_id !== state.selectedContract;
      });
      updatedData1["addContract_allotment_type"] =
        state.contract_allotment_type;
      updatedData1["addContract_status"] = state.contract_status;
      updatedData1["addBoard"] = state.board;
      updatedData1["addOperator"] = state.operator;
      updatedData1["addContract_season"] = state.contract_season;
      updatedData1["addMarket"] = state.market;
      updatedData1["addCountries"] = state.countries;
      updatedData1["updateContractRooms"] = state.contract_room;
      const normalizeDataUpdate = addUpdateNormalize([updatedData1]).entities;
      return {
        ...state,
        contracts: normalizeDataUpdate.contracts
          ? normalizeDataUpdate.contracts
          : [],
        contract_room: normalizeDataUpdate.contract_room
          ? normalizeDataUpdate.contract_room
          : [],
        contract_room_price: normalizeDataUpdate.contract_room_price
          ? normalizeDataUpdate.contract_room_price
          : [],
        contract_status: normalizeDataUpdate.contract_status,
        contract_has_board: normalizeDataUpdate.contract_has_board
          ? normalizeDataUpdate.contract_has_board
          : [],
        board: normalizeDataUpdate.board ? normalizeDataUpdate.board : [],
        contract_user: normalizeDataUpdate.contract_user
          ? normalizeDataUpdate.contract_user
          : [],
        contract_allotment_type: normalizeDataUpdate.contract_allotment_type
          ? normalizeDataUpdate.contract_allotment_type
          : [],
        contract_season: normalizeDataUpdate.contract_season
          ? normalizeDataUpdate.contract_season
          : [],
        market: normalizeDataUpdate.market ? normalizeDataUpdate.market : [],
        countries: normalizeDataUpdate.countries
          ? normalizeDataUpdate.countries
          : []
      };

    case DELETE_CONTRACT:
      return {};
    case GET_CONTRACT_ROOM:
      return {
        ...state,
        contract_room: action.payload ? action.payload : []
      };
    case ADD_CONTRACT_ROOM:
      const data = [];
      data["contract_room"] = action.payload;
      data["state_contract_room"] = state.contract_room;
      data["contract"] =
        state.contracts[action.payload[0].contract_id].contract_room;
      data["priceState"] = state.contract_room_price;
      const normalizeData = normalizeContractRooms([data]);
      const contractRoomID = normalizeData.result[0].contract_room[0];
      const contract_id =
        normalizeData.entities.contract_room[
          normalizeData.result[0].contract_room[0]
        ].contract_id;
      const contractRooms = normalizeData.entities.contract_room;
      const contractRoomPrice = normalizeData.entities.contract_room_price;
      normalizeData.result[0].contract_room.forEach(room => {
        state.contracts[contract_id].contract_room.push(room);
      });
      return {
        ...state,
        contract_room: contractRooms,
        contract_room_price: contractRoomPrice
      };
    case IS_SAVED_CONTRACT:
      return { ...state, isSaved: action.payload };
    case UPDATE_CONTRACT_ROOM:
      const updatedData = [];
      updatedData["updated_contract_room"] = action.payload;
      updatedData["UpdatedPriceState"] = state.contract_room_price;
      const UpdatedNormalizeData = normalizeContractRooms([updatedData]);
      const updatedContractRoomID =
        UpdatedNormalizeData.result[0].updated_contract_room[0];
      const updatedContractRoomPrices =
        UpdatedNormalizeData.entities.contract_room_price;
      return {
        ...state,
        contract_room: {
          ...state.contract_room,
          [updatedContractRoomID]:
            UpdatedNormalizeData.entities.contract_room[updatedContractRoomID]
        },
        contract_room_price: updatedContractRoomPrices
      };
    case DELETE_CONTRACT_ROOM:
      const deletedData = [];
      deletedData["contract_room"] = [action.payload];
      const deletedNormalizeData = normalizeContractRooms([deletedData]);
      const deletedContractRoomID =
        deletedNormalizeData.result[0].contract_room[0];
      const deletedContractId =
        deletedNormalizeData.entities.contract_room[deletedContractRoomID]
          .contract_id;
      const findIndex = state.contracts[
        deletedContractId
      ].contract_room.findIndex(cr => cr === deletedContractRoomID);
      state.contracts[deletedContractId].contract_room.splice(findIndex, 1);
      const deleteCRooms = Object.keys(state.contract_room)
        .filter(id => parseInt(id) !== parseInt(action.payload.id))
        .reduce((obj, key) => {
          obj[key] = state.contract_room[key];
          return obj;
        }, {});
      const deleteCRoomsPrice = Object.keys(state.contract_room_price)
        .filter(
          price =>
            state.contract_room_price[price].contract_room_id !==
            action.payload.id
        )
        .reduce((obj, key) => {
          obj[key] = state.contract_room_price[key];
          return obj;
        }, {});
      return {
        ...state,
        contract_room: deleteCRooms,
        contract_room_price: deleteCRoomsPrice
      };
    case SET_SELECTED_CONTRACT:
      return { ...state, selectedContract: action.payload };
    case CHANGE_CONTRACT_STATUS:
      const updateContractStatus = [];
      updateContractStatus["contract"] = state.contracts;
      updateContractStatus["contract"][action.payload.id].contract_statuses_id =
        action.payload.contract_status[0].id;
      updateContractStatus["contract"][action.payload.id].contract_status =
        action.payload.contract_status;
      updateContractStatus["updateContractStatus"] = state.contract_status;
      const normalizeUpdateContractStatus = addUpdateNormalize([
        updateContractStatus
      ]);
      return {
        ...state,
        contract_status: normalizeUpdateContractStatus.entities.contract_status,
        contracts: normalizeUpdateContractStatus.entities.contracts
      };
    case IS_UPDATE_CONTRACT:
      return { ...state, isUpdate: action.payload };
    case CHECK_CONTRACT_DATA_IS_UPDATE:
      return { ...state, checkContractDataIsUpdate: action.payload };

    case COPY_CONTRACT:
      const copiedContract = [];
      copiedContract["contract"] = [action.payload];
      copiedContract["addContract"] = state.contracts;
      copiedContract["addContract_allotment_type"] =
        state.contract_allotment_type;
      copiedContract["addContract_has_board"] = state.contract_has_board;
      copiedContract["addContract_status"] = state.contract_status;
      copiedContract["addContract_user"] = state.contract_user;
      copiedContract["addBoard"] = state.board;
      copiedContract["addOperator"] = state.operator;
      copiedContract["addContract_season"] = state.contract_season;
      copiedContract["addMarket"] = state.market;
      copiedContract["addCountries"] = state.countries;
      copiedContract["updateContractRooms"] = state.contract_room;

      const normalizeDataContracts = addUpdateNormalize([copiedContract])
        .entities;
      return {
        ...state,
        contracts: normalizeDataContracts.contracts
          ? normalizeDataContracts.contracts
          : [],
        contract_status: normalizeDataContracts.contract_status,
        contract_has_board: normalizeDataContracts.contract_has_board
          ? normalizeDataContracts.contract_has_board
          : [],
        contract_room: normalizeDataContracts.contract_room
          ? normalizeDataContracts.contract_room
          : [],
        board: normalizeDataContracts.board ? normalizeDataContracts.board : [],
        contract_user: normalizeDataContracts.contract_user
          ? normalizeDataContracts.contract_user
          : [],
        contract_allotment_type: normalizeDataContracts.contract_allotment_type
          ? normalizeDataContracts.contract_allotment_type
          : [],
        contract_season: normalizeDataContracts.contract_season
          ? normalizeDataContracts.contract_season
          : [],
        market: normalizeDataContracts.market
          ? normalizeDataContracts.market
          : [],
        countries: normalizeDataContracts.countries
          ? normalizeDataContracts.countries
          : [],
        contract_room_price: normalizeDataContracts.contract_room_price
          ? normalizeDataContracts.contract_room_price
          : []
      };

    default:
      return state;
  }
};
