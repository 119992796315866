import React from "react";
import { IconButton, Popover, Tooltip, useTheme } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import PaletteIcon from "@material-ui/icons/Palette";
import clsx from "clsx";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import ThemeItem from "./ThemeItem";
//import ThemeColor from './ThemeColorItem';
import CmtCard from "../../../../../../@coremat/CmtCard";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &:focus": {
      color: theme.palette.common.white
    }
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 400
    }
  }
}));

const HeaderTheme = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {/*  <Tooltip title="Theme Settings">
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
          <PaletteIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Theme Settings"
            actionsPos="top-corner"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <CmtCardContent>
            <ThemeItem />
             <ThemeColor />
          </CmtCardContent>
        </CmtCard>
      </Popover>*/}
    </div>
  );
};

export default HeaderTheme;
