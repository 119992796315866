import { normalize, schema } from 'normalizr';

const city = new schema.Entity('city');
const sub_city = new schema.Entity('sub_city');
const country = new schema.Entity('country');
const region = new schema.Entity('region');
const permission = new schema.Entity('get_permission');
const pivot_office = new schema.Entity('pivot_office', {}, { idAttribute: 'office_id' });

const get_contract = new schema.Entity('get_contract');

const user = new schema.Entity('users', {
  get_permission: [permission],
  pivot_office: [pivot_office],
  get_contract: [get_contract],
});

const oficeData = new schema.Entity('xxx');

const addOfficeId = new schema.Entity('companies', { offices: [oficeData] });

const offices = new schema.Entity('offices', {
  get_company: addOfficeId,
  user: [user],
  city: city,
  sub_city: sub_city,
  region: region,
  country: country,
});

const companyState = new schema.Entity(
  'companies',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        ...entityB,
        offices: entityA.offices,
      };
    },
  },
);
const deleteOfficeID = new schema.Entity(
  'companies',
  { offices: [oficeData] },
  {
    processStrategy: (value, parent, key) => {
      let deleteOffice = value.offices.filter(element => element.id !== parent.id);
      return { ...value, offices: deleteOffice };
    },
  },
);

const updateOffice = new schema.Entity('offices', {
  get_company: addOfficeId,
  get_old_company: deleteOfficeID,
  user: [user],
  city: city,
  sub_city: sub_city,
  region: region,
  country: country,
});

const updateCompanies = new schema.Entity(
  'companies',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
        ...entityB,
        offices: entityA.offices,
      };
    },
  },
);

const deleteData = new schema.Entity(
  'companies',
  {},
  {
    processStrategy: (value, parent, key) => {
      let officeIDs = value.offices.filter(element => element !== value.deletedOfficeID);
      return { ...value, offices: officeIDs };
    },
  },
);

const response = {
  addOffice: [offices],
  addOfficeState: [companyState],
  updateOffice: [updateOffice],
  companyUpState: [updateCompanies],
  deleteData: [deleteData],
};

export const normalizrOffice = data => normalize(data, [response]);
