import { createSelector } from "reselect";

//GİRİŞ YAPAN KULLANICININ AUTHORİTY LEVEL BİLGİSİ
export const authUserMemo = createSelector(
  state => state.auth.authUser,

  authUser => {
    return authUser.authority_level;
  }
);

//GİRİŞ YAPAN KULLANICININ BİLGİLERİ
export const loginUser = createSelector(
  state => state.auth.authUser,

  authUser => {
    return authUser;
  }
);

//ENTİTİES (BÜTÜN DATALAR(COMPANY-OFFİCE-USER-REGON-COUNTRY-CİTY))
export const companyHasOfficeMemo = createSelector(
  state => state.companyHasOffice.entities,

  companyHasOffice => {
    return companyHasOffice;
  }
);

//BÜTÜN COMPANYLER OBJE OLARAK (NORMALİZR DATA ID_ATTR)
export const companyData = createSelector(
  state => state.companyHasOffice.entities.companies,

  compData => {
    return compData;
  }
);

//BÜTÜN USERLER ARRAY OLARAK
export const userMemo = createSelector(
  state => state.companyHasOffice.entities.users,

  user => {
    return Object.values(user);
  }
);

//BÜTÜN USERLER OBJE OLARAK (NORMALİZR DATA ID_ATTR)
export const usersObject = createSelector(
  state => state.companyHasOffice.entities.users,

  user => {
    return user;
  }
);

//BÜTÜN OFFİCELER OBJE OLARAK (NORMALİZR DATA ID_ATTR)
export const officeMemo = createSelector(
  state => state.companyHasOffice.entities.offices,

  offices => {
    return offices;
  }
);

//JSON DESTINATION
export const get_destinations = createSelector(
  state =>
    Object.values(state.companyHasOffice.entities.companies).filter(
      element => element.get_destination
    ),

  companies => {
    return companies;
  }
);

//BÜTÜN COMPANYLER DİZİ OLARAK
export const companyMemo = createSelector(
  state =>
    state.companyHasOffice.result.companiesId.map(
      id => state.companyHasOffice.entities.companies[id]
    ),

  allCompanies => {
    return allCompanies;
  }
);

/* companyState.filter(element => element.id == companyHasOfficeState.entities.offices[id.offices]), */

//USERIN AİT OLDUĞU COMPANYLER DİZİ OLARAK
export const authorityCompany = createSelector(
  state =>
    state.companyHasOffice.entities.users[state.auth.authUser.id]?.pivot_office
      .map(pivot => state.companyHasOffice.entities.offices[pivot.office_id])
      .map(
        officeData =>
          state.companyHasOffice.entities.companies[officeData.company_id]
      ),

  authCompany => {
    return authCompany;
  }
);

//USERIN AİT OLDUĞU OFFİCELER DİZİ OLARAK
export const authorityOffice = createSelector(
  state =>
    state.companyHasOffice.entities.users[
      state.auth.authUser.id
    ]?.pivot_office.map(
      pivot => state.companyHasOffice.entities.offices[pivot.office_id]
    ),

  authCompany => {
    return authCompany;
  }
);

//SADECE ENTERPRİSE OLANLAR
export const enterpriseCompany = createSelector(
  state => state.companyHasOffice.entities.companies,

  enterprise => {
    return Object.values(enterprise).filter(
      element => element.is_enterprise === 1
    );
  }
);

//ENTERPRİSE OLMAYANLAR
export const nonEnterprise = createSelector(
  state => state.companyHasOffice.entities.companies,

  enterprise => {
    return Object.values(enterprise).filter(
      element => element.is_enterprise !== 1
    );
  }
);

//REGİONLAR
export const regionsMemo = createSelector(
  state => state.companyHasOffice.entities.regions,

  region => {
    return region;
  }
);

//COUNTRYLER
export const countryMemo = createSelector(
  state => state.companyHasOffice.entities.country,

  country => {
    return country;
  }
);

export const divisionMemo = createSelector(
  state => state.companyHasOffice.entities.division,

  division => {
    return division;
  }
);

//ŞEHİRLER
export const cityMemo = createSelector(
  state => state.companyHasOffice.entities.city,

  city => {
    return city;
  }
);

//SUBCİTYLER
export const sub_cityMemo = createSelector(
  state => state.companyHasOffice.entities.sub_city,

  sub_city => {
    return sub_city;
  }
);

//OFFICECOUNTRYDESTINATION
export const officeDestinationCountries = createSelector(
  state => state.companyHasOffice.entities.officeHasDestination.country,

  destinationCountry => {
    return destinationCountry;
  }
);

//OFFICE COUNTRYID
export const allCountryID = createSelector(
  state => state.companyHasOffice.entities.officeHasDestination.allCountryID,

  countriesID => {
    return countriesID;
  }
);
export const allDivisions = createSelector(
  state => state.companyHasOffice.entities.officeHasDestination.division,

  division => {
    return division;
  }
);

//OFFICE CITYID
export const allCityID = createSelector(
  state => state.companyHasOffice.entities.officeHasDestination.city,

  cityID => {
    return cityID;
  }
);

// all users
export const allUsers = createSelector(
  state => state.users.allUsers,

  allUsers => {
    return allUsers;
  }
);
