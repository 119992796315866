import { normalize } from "normalizr";
import reservation from "redux/schemas/reservation";
import {
  GET_RESERVATIONS,
  ADD_RESERVATION,
  UPDATE_RESERVATION,
  DELETE_RESERVATION,
  SEND_HOTEL_MAIL,
  SEND_HOTEL_RESPONSE
} from "./types";

export const getReservations = data => dispatch => {
  const normalized_data = normalize(data, reservation);
  dispatch({
    type: GET_RESERVATIONS,
    payload: normalized_data
  });
};

export const addReservation = res => dispatch => {
  dispatch({
    type: ADD_RESERVATION,
    payload: res.data.data.reservations
  });
};
export const updateReservation = data => dispatch => {
  const normalized_data = normalize(data.data, reservation);
  dispatch({
    type: GET_RESERVATIONS,
    payload: normalized_data
  });
};

export const deleteReservation = res => dispatch =>
  dispatch({
    type: DELETE_RESERVATION,
    payload: res.data.data
  });

export const sendHotelMail = res => dispatch => {
  dispatch({
    type: SEND_HOTEL_MAIL,
    payload: res.data.data
  });
};

export const sendHotelResponse = res => dispatch =>
  dispatch({
    type: SEND_HOTEL_RESPONSE,
    payload: res.data.data
  });
