import React, { useState } from "react";
import CmtAvatar from "@coremat/CmtAvatar";
import CmtCard from "@coremat/CmtCard";
import { Typography } from "@material-ui/core";
import CmtCardFooter from "@coremat/CmtCard/CmtCardFooter";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { Box, Collapse } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardActions from "@coremat/CmtCard/CmtCardActions";
import Grid from "@material-ui/core/Grid";
import GridContainer from "@jumbo/components/GridContainer";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "95%",
    height: "100%",
    marginLeft: theme.spacing(5),
    fontSize: "12px!important",
    color: localStorage.getItem("theme-type") === "dark" ? "white" : "black"
  },

  title: {
    fontSize: "14px",
    color: "grey",
    margin: 5
  }
}));

export default function SSS() {
  const turkishValues = [
    {
      id: 1,
      question: " MundosaiBot nedir, ne için kullanılır?. ",
      answer: ` Birden fazla extranet üzerinde işlem yapması gereken seyahat acentelerinin, işlemlerini tek bir panel üzerinden daha hızlı ve  doğru bir şekilde
      yapmalarına olanak  sağlayan ürünümüzdür. Günlük olarak aktarım yapılması bağlı olarak gerekli aksiyonların alınmasının kullanıcı
       dostu bir ara yüz ile 
      yapılmasına
      yardımcı
       olan bir 
      asistandır.`,
      color: "primary"
    },
    {
      id: 2,
      question: "Sisteme yeni bir extranet (operatör) nasıl tanımlayabilirim?",
      answer: `Acentenin sistem üzerinde yeni bir extranet (operatör) 
      tanımı
      yapması
       mümkün 
      değildir.
       Sistemde 
      tanımlı
       olmayan extranetler ile 
      çalışmak
       istenirse konu öncelikle 
      proxolab 
      şirketi
       ile 
      paylaşılmalıdır.
       Bunun nedeni mundosaiBOT ile extranet 
      sistemlerinin entegrasyon ile 
      çalışmasıdır.`,
      color: "amber"
    },
    {
      id: 3,
      question: "  Kullandığım kontrat formatını sisteme nasıl aktarabilirim?",
      answer: `MundosaiBOT sistemine 2 farklı şekilde kolaylıkla kontrat aktarımı sağlanabilir.
      1)   Menüdeki New Contract  alanından, kontrat bilgileri girilerek sisteme aktarım sağlanabilir.
      2)   Menüdeki Home  ekranın CONTRACT OPERATION sekmesinde bulunan IMPORT CONTRACT butonu ile kontrat aktarımı sağlanabilir.
       Acentelerin IMPORT  CONTRACT   alanını kullanabilmesi için öncelikle kontrat formatına uygun bir geliştirme yapılması gerekmektedir. Bu nedenle IMPORT CONTRACT ile kontrat 
aktarımı yapmak isteyen acenteler 
şirketimiz ile  iletişime geçmelidir.`,
      color: "orange"
    },
    {
      id: 4,
      question:
        "MundosaiBot sisteminde Acentemize özel geliştirme yapıyor musunuz?",
      answer: `Evet. 
      Eğer acente talepleri mevcut sistem içerisinde  karşılanamıyorsa acentelerin gereksinimlerine göre sistem geliştirmesi yapıyoruz.`,
      color: "blue"
    },
    {
      id: 5,
      question:
        "  Satın alma ve kurulum sonrası sistemin kullanımı hakkında destek sağlıyor musunuz? ",
      answer: `Evet, destek ekibimiz size kurulum aşamasında, sonrasında ve teknik konularda her zaman yardımcı olacaktır.`,
      color: "grey"
    },
    {
      id: 6,
      question: "Destek ekibinizle nasıl iletişime geçebilirim?",
      answer: `Sistem içerisinde bulunan Help başlığı altındaki e-mail ve Whatsapp linkleri üzerinden destek ekibi ile iletişime geçebilirsiniz.`,
      color: "green"
    },
    {
      id: 7,
      question: "Sisteminizde hangi dil seçenekleri mevcut?",
      answer: `Sistemimizde  Türkçe  ve 
      İngilizce dil  seçenekleri  mevcuttur. Yaygın kullanılan dil seçeneklerini eklemek için çalışmalarımız devam etmektedir.`,
      color: "red"
    },
    {
      id: 8,
      question:
        "MundosaiBot gizliliğimi nasıl koruyor ve bilgilerimin güvenliğini nasıl sağlıyor?",
      answer: `Mundosai 
      kayıt
       olmak için gerekli bilgileriniz 
      dışında
       hiçbir bilgiyi sizden istemez. 
      Kullanıcınız,
       yöneticiniz veya bizim 
      tarafımızdan
      oluşturulduğunda
       verilen mail 
      adresine tamamen rastgele bir parola gönderilir.
      Gönderilen bu parola veri 
      tabanında
      şifreli
       bir 
      şekilde
      saklanır.
      Kullanıcı
       bilgileri 
      üçüncü 
      şahıslarla
      paylaşılmaz.`,
      color: "purple"
    },
    {
      id: 9,
      question: "Şifremi nasıl değiştirebilirim?",
      answer: `Şifrenizi
      unuttuysanız
       login 
      sayfasında
       bulunan “Forgot Password” 
      alanına
       e-posta 
      adresinizi yazarak yeni 
      şifrenizi
       e-mail yoluyla alabilirsiniz.
      Eğer
      şifrenizi
      sıfırlamak
      istiyorsanız
       menüdeki ADMIN OPERATIONS 
      alanında
       bulunan 
      USER sekmesi 
      altındaki
       “RESET PASSWORD”  linkine 
      tıkladığınızda
       e-mailinize yeni bir 
      şifre
       gelecektir.`,
      color: "pink"
    },
    {
      id: 10,
      question: " Kullanıcı yetkilerini nasıl düzenleyebilirim?",
      answer: `Admin olmayan 
      kullanıcılar
       kendi yetki düzenlemesini yapamazlar. Bu nedenle yetki 
      düzenlemesi yapmak isteyen 
      kullanıcılar
       acentelerinde bulunan admin 
      kullanıcıları
       ile 
      iletişime
       geçmelidir.`,
      color: "brown"
    },
    {
      id: 11,
      question:
        "Sisteme eklenen kullanıcıların olası bir durumda erişimini nasıl engelleyebilirim?",
      answer: ` Admin olan 
      kullanıcı,
        ADMIN OPERATIONS 
      alanındaki
       USER sekmesinde 
      kullanıcıların
      statüsünü aktife ve pasife alabilir. Pasife 
      alınan
      kullanıcılar
       sisteme 
      erişim
      sağlayamazlar.`,
      color: "cyan"
    },
    {
      id: 12,
      question:
        "Çalıştırdığım Info, Open/Stop, Offer, Offer Delete ve Contrat On Sale botlarını nasıl kontrol ve takip edebilirim?",
      answer: `Çalıştırılan
      botlar sistemin Home 
     alanındaki
      BOT OPERATIONS 
     ekranına
      log olarak 
     düşüyor.
      Bu ekranda filtreler 
     yapılarak
     çalıştırılan
     botların
      log 
     detaylarını
     görebilirsiniz. BOT OPERATIONS 
     alanında
      son 30 gün içerisinde 
     çalıştırılan
     botların
     log bilgileri listelenebilir ve görüntülenebilir. 30 gün öncesine ait loglar 
     görüntülenmek isteniyorsa proxolab 
     şirketi
      ile 
     iletişime
      geçilmelidir.`,
      color: "orange"
    }
  ];

  const englishValues = [
    {
      id: 1,
      question: "What is MundosaiBot, what is it used for? ",
      answer:
        "It is our product that allows travel agencies that need to transact on more than one extranet, to perform their transactions faster and more accurately through a single panel. It is an assistant that helps the data that needs to be transferred on a daily basis and the necessary actions to be taken with a user-friendly interface. Customers which are in need of more time and skilled staff can now work more efficiently and faster to load their data to various extranets. It is a single panel enables them to upload quickly and accurately and also lets them work with a user-friendly software.",
      color: "primary"
    },
    {
      id: 2,
      question: "How can I define a new extranet (operator) in the system?",
      answer:
        "You need to contact proxolab if you want to add a new extranet. The reason is that mundosaiBOT and extranet systems are working as integrated.",
      color: "amber"
    },
    {
      id: 3,
      question: "How can I transfer my contract to the system?",
      answer: `You can transfer your contracts in two ways. 
      1-) You can use “New Contract” options in main page.
      2) You can use IMPORT CONTRACT option in CONTRACT OPERATION menu. Agencies need to make an adjustment according to the contract format in order to use the IMPORT CONTRACT menu. Therefore, please contact us in order to use IMPORT CONTRACT menu to upload your contracts to the system.`,
      color: "orange"
    },
    {
      id: 4,
      question:
        "Do you make developments as per Agency needs in mundosaiBOT system?",
      answer:
        "Yes. If Agency needs cannot be met in the current system, we make developments according the Agency requirements. ",
      color: "blue"
    },
    {
      id: 5,
      question:
        "Do you provide support during purchasing and after installing the system?",
      answer:
        "Yes, our support team will help you out with all issues during purchasing, installing and afterwards.",
      color: "grey"
    },
    {
      id: 6,
      question: "How can I contact support team?",
      answer: `You can contact our support team via e-mail or Whatsapp link under Help menu.`,
      color: "green"
    },
    {
      id: 7,
      question: "Which languages can I use in the system in?",
      answer: `You can use mundosaiBOT in Turkish or English. We are working on to add more common languages in the near future.`,
      color: "red"
    },
    {
      id: 8,
      question:
        "How does mundosaiBOT protect my privacy and ensure security of my personal information?",
      answer: `MundosaiBOT does not require any extra information in order Agencies to sign up to the system. When the user is created by us or your administrator, a random password is sent to the e-mail address of the user. This password is stored in our database as encrypted. User details are not shared with third parties.`,
      color: "pink"
    },
    {
      id: 9,
      question: "How can I change my login password?",
      answer: `If you need to reset your password, click on “RESET PASSWORD” under USER menu in ADMIN OPERATIONS. Afterwards, your new password will be sent to your e-mail.
      In case you forget your password, you can get your new password by entering your e-mail address to the “Forgot Password” link on login page.`,
      color: "brown"
    },
    {
      id: 10,
      question: "How can I adjust user permissions?",
      answer:
        "You can modify user permissions under USER PERMISSION menu in ADMIN OPERATIONS. Users who are not Admin cannot adjust their permissions. They need to contact their Administrators to modify their authorizations. ",
      color: "cyan"
    },
    {
      id: 11,
      question: "How can I restrict the access of a user?",
      answer:
        " Admin can activate or deactivate the status of a user under USER menu in ADMIN OPERATIONS. Deactivated users cannot login to the system. ",
      color: "orange"
    },
    {
      id: 12,
      question:
        "How can I control and follow Info, Open/Stop, Offer, Offer Delete and Contract On Sale bots?",
      answer:
        "You can find logs of the bots in BOT OPERATIONS menu. Log details can be found in this menu through various filters. You can find the logs of the last 30 days in BOT OPERATIONS. If you need to view the logs more than 30 days before, you need to contact proxolab. ",
      color: "teal"
    }
  ];
  const valueList =
    JSON.parse(localStorage.getItem("language"))?.locale === "en"
      ? englishValues
      : turkishValues;

  const classes = useStyles();
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = id => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };
  return (
    <div className={classes.root}>
      {valueList.map(elem => {
        return (
          <CmtCard
            style={{
              marginTop: "0.5%",
              paddingLeft: "2%",
              paddingTop: "1%",
              cursor: "pointer"
            }}
            onClick={() => handleExpandClick(elem.id)}
          >
            <Grid container spacing={5} wrap="nowrap">
              <Grid item>
                <CmtAvatar color={elem.color} size="small">
                  {elem.id}
                </CmtAvatar>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: "14px" }}>
                  {elem.question}
                </Typography>
              </Grid>
            </Grid>
            <Box className={clsx(classes.root)} onClick={handleExpandClick}>
              <CmtCardActions disableSpacing>
                <GridContainer
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={1}>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded[elem.id]
                      })}
                      aria-expanded={expanded[elem.id]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                </GridContainer>
              </CmtCardActions>
              <Collapse in={expanded[elem.id]} timeout="auto" unmountOnExit>
                <CmtCardContent>
                  <CmtCardFooter
                    separator={{
                      color: "#bdbdbd",
                      borderWidth: 3,
                      borderStyle: "solid"
                    }}
                  />
                  <Typography style={{ fontSize: "13px" }}>
                    {elem.answer}
                  </Typography>
                </CmtCardContent>
              </Collapse>
            </Box>
          </CmtCard>
        );
      })}
    </div>
  );
}
