import React from "react";
import "./index.css";
// drag drop file component
export default function DragDropFile({ onChange, fileName }) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <input
        ref={inputRef}
        type="file"
        id="file"
        name="file"
        hidden
        accept=".xls, .csv , .xlsx"
        onChange={e => {
          onChange(e, "click");
        }}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>
            {fileName === "" ? "Upload File" : "Upload Another File"}
          </button>
        </div>
        {fileName !== "" && fileName !== undefined && (
          <div className="file-name">{fileName}</div>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={e => {
            onChange(e, "drop");
          }}
        ></div>
      )}
    </form>
  );
}
