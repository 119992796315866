function OfferAmountCalculate(rowData, funcData) {
  const {
    contract_room,
    contract_room_price,
    boardSupplaments,
    offers,
    early_booking_offers,
    special_discount_offers,
    currencySymbol,
  } = funcData;

  let language = JSON.parse(localStorage.getItem("language"));

  const durations = [
    { id: 1, name: "1 Day" },
    { id: 2, name: "2 Day" },
    { id: 3, name: "3 Day" },
    { id: 4, name: "6 Day" },
    { id: 5, name: "7 Day" },
    { id: 6, name: "10 Day" },
    { id: 7, name: "14 Day" },
  ];

  const calculateOfferAmount = (
    rowData,
    calculatedRate,
    duration,
    accomodation_id
  ) => {
    /* 
          Eğer bir offer seçilir ise önce offerın tipini buluyoruz. daha sonra seçili offerı bulup bunun discountunu ve stay type buluyoruz
          eğer stay_type per_day veya per_stay ise önceden hesaplanan veriye discount kadar yüzdelik indirim yapıyoruz 
          
          */

    //Eğer EB Offer ise
    if (offers[rowData.offerIds].offer_type_id == 1) {
      let ebOfferId = offers[rowData.offerIds];
      let discount = early_booking_offers[ebOfferId.table_id]?.discount;
      let ebStay_type =
        early_booking_offers[ebOfferId.table_id].apply_stay_type;
      if (ebStay_type == "1" || ebStay_type == "3") {
        if (accomodation_id) {
          return (
            calculatedRate * duration * accomodation_id -
            (calculatedRate * duration * accomodation_id * discount) / 100
          );
        } else {
          return (
            calculatedRate * duration -
            (calculatedRate * duration * discount) / 100
          );
        }
      } else {
        if (accomodation_id) {
          if (duration % 7 == 0) {
            return (
              calculatedRate * duration * accomodation_id -
              (calculatedRate * duration * accomodation_id * discount) / 100
            );
          } else {
            // Eğer offer per week seçilirse ve 7 ye tam bölünmez ise önce kalanı buluyoruz sonra tüm günden kalanı cıkartıp kaç tane 7 olduğunu buluyoruz
            // hesaplamada yukarı bulduğumuz kaç tane 7 var ise onu 7 ile çarpıp hesaplanacak değer ile çarpıp daha sonra offer indirimi uygulayıp çıkartıyoruz
            // en son kalan günlerin fiyatı ile topluyoruz
            let remainder = duration % 7;
            let weekDuration = (duration - remainder) / 7;
            return (
              calculatedRate * 7 * weekDuration * accomodation_id -
              (calculatedRate * 7 * weekDuration * accomodation_id * discount) /
                100 +
              calculatedRate * remainder * accomodation_id
            );
          }
        } else {
          if (duration % 7 == 0) {
            return (
              calculatedRate * duration -
              (calculatedRate * duration * discount) / 100
            );
          } else {
            // Eğer offer per week seçilirse ve 7 ye tam bölünmez ise önce kalanı buluyoruz sonra tüm günden kalanı cıkartıp kaç tane 7 olduğunu buluyoruz
            // hesaplamada yukarı bulduğumuz kaç tane 7 var ise onu 7 ile çarpıp hesaplanacak değer ile çarpıp daha sonra offer indirimi uygulayıp çıkartıyoruz
            // en son kalan günlerin fiyatı ile topluyoruz
            let remainder = duration % 7;
            let weekDuration = (duration - remainder) / 7;
            return (
              calculatedRate * 7 * weekDuration -
              (calculatedRate * 7 * weekDuration * discount) / 100 +
              calculatedRate * remainder
            );
          }
        }
      }
    }
    // Eğer SDO Offer ise
    else {
      let sdoOfferId = offers[rowData.offerIds];
      let discount = special_discount_offers[sdoOfferId.table_id].discount;
      let sdoStay_type =
        special_discount_offers[sdoOfferId.table_id].apply_stay_type;
      if (sdoStay_type == "1" || sdoStay_type == "3") {
        if (accomodation_id) {
          return (
            calculatedRate * duration * accomodation_id -
            (calculatedRate * duration * accomodation_id * discount) / 100
          );
        } else {
          return (
            calculatedRate * duration -
            (calculatedRate * duration * discount) / 100
          );
        }
      } else {
        if (accomodation_id) {
          if (duration % 7 == 0) {
            return (
              calculatedRate * duration * accomodation_id -
              (calculatedRate * duration * accomodation_id * discount) / 100
            );
          } else {
            // Eğer offer per week seçilirse ve 7 ye tam bölünmez ise önce kalanı buluyoruz sonra tüm günden kalanı cıkartıp kaç tane 7 olduğunu buluyoruz
            // hesaplamada yukarı bulduğumuz kaç tane 7 var ise onu 7 ile çarpıp hesaplanacak değer ile çarpıp daha sonra offer indirimi uygulayıp çıkartıyoruz
            // en son kalan günlerin fiyatı ile topluyoruz
            let remainder = duration % 7;
            let weekDuration = (duration - remainder) / 7;
            return (
              calculatedRate * 7 * weekDuration * accomodation_id -
              (calculatedRate * 7 * weekDuration * accomodation_id * discount) /
                100 +
              calculatedRate * remainder * accomodation_id
            );
          }
        } else {
          if (duration == 7 || duration == 14) {
            return (
              calculatedRate * duration -
              (calculatedRate * duration * discount) / 100
            );
          } else {
            // Eğer offer per week seçilirse ve 7 ye tam bölünmez ise önce kalanı buluyoruz sonra tüm günden kalanı cıkartıp kaç tane 7 olduğunu buluyoruz
            // hesaplamada yukarı bulduğumuz kaç tane 7 var ise onu 7 ile çarpıp hesaplanacak değer ile çarpıp daha sonra offer indirimi uygulayıp çıkartıyoruz
            // en son kalan günlerin fiyatı ile topluyoruz
            let remainder = duration % 7;
            let weekDuration = (duration - remainder) / 7;
            return (
              calculatedRate * 7 * weekDuration -
              (calculatedRate * 7 * weekDuration * discount) / 100 +
              calculatedRate * remainder
            );
          }
        }
      }
    }
  };

  let rate = 0;
  let rate_type = Number(contract_room[rowData.room_type_id].rate_id);
  let boardId, roomPriceIds, duration;
  let calculatedRate, findedSup, suppRate, amountWithCurrency;
  let calculatedAmount = 0;
  boardId = contract_room[rowData.room_type_id]?.board_id;
  roomPriceIds = contract_room[rowData.room_type_id]?.contract_room_price;
  duration = durations.find((item) => item.id === rowData.duration_id).name;
  duration = parseInt(duration.split(" ")[0]);
  let accomodation123 = parseInt(rowData.accomodation_id);
  findedSup = Object.values(boardSupplaments).find(
    (item) =>
      item.to_board == rowData.board_id &&
      item.room_types.includes(rowData.room_type_id)
  );
  //Find selected room's board and price from allotment agreement
  if (rowData.board_id === boardId) {
    const selectedRoomPrices = Object.values(contract_room_price).find(
      (price) =>
        price.period_id == rowData.period_id &&
        price.contract_room_id == rowData.room_type_id
    );
    if (selectedRoomPrices && selectedRoomPrices.price) {
      calculatedRate = selectedRoomPrices.price;
      if (rate_type == 2) {
        //NOTE - Hesaplamaya Offer Dahil edilmesi
        /* 
          Eğer bir offer seçilir ise önce offerın tipini buluyoruz. daha sonra seçili offerı bulup bunun discountunu ve stay type buluyoruz
          eğer stay_type per_day veya per_stay ise önceden hesaplanan veriye discount kadar yüzdelik indirim yapıyoruz 
          
          */
        if (rowData.offerIds) {
          //Eğer EB Offer ise
          if (offers[rowData.offerIds].offer_type_id == 1) {
            calculatedAmount = calculateOfferAmount(
              rowData,
              calculatedRate,
              duration
            );
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount = calculateOfferAmount(
              rowData,
              calculatedRate,
              duration
            );
          }
        }
        //Eğer seçili bir offer yok ise
        else {
          calculatedAmount = calculatedRate * duration;
        }
      } else {
        if (rowData.offerIds) {
          let accomodation_id = parseInt(rowData.accomodation_id);

          //Eğer EB Offer ise
          if (offers[rowData.offerIds].offer_type_id == 1) {
            calculatedAmount = calculateOfferAmount(
              rowData,
              calculatedRate,
              duration,
              accomodation_id
            );
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount = calculateOfferAmount(
              rowData,
              calculatedRate,
              duration,
              accomodation_id
            );
          }
        } else {
          calculatedAmount =
            calculatedRate * duration * parseInt(rowData.accomodation_id);
        }
      }
      rate = calculatedRate;
    } else if (!selectedRoomPrices || !selectedRoomPrices.price) {
      if (language.locale == "en") {
        return "The room rate for the period is not entered";
      } else {
        return "Bu periyot için oda fiyatı girilmemiş";
      }
    }

    //If allotment agreement and also board supplement has selected room's board
    else if (findedSup) {
      suppRate = findedSup.rate;
      if (rate_type == 2) {
        if (rowData.offerIds) {
          //Eğer EB Offer ise
          if (offers[rowData.offerIds].offer_type_id == 1) {
            calculatedAmount =
              suppRate * duration +
              calculateOfferAmount(rowData, rate, duration);
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount =
              suppRate * duration +
              calculateOfferAmount(rowData, rate, duration);
          }
        } else {
          calculatedAmount = (rate + suppRate) * duration;
        }
      } else {
        if (rowData.offerIds) {
          let accomodation_id = parseInt(rowData.accomodation_id);

          //Eğer EB Offer ise
          if (offers[rowData.offerIds].offer_type_id == 1) {
            calculatedAmount =
              suppRate * duration * accomodation_id +
              calculateOfferAmount(rowData, rate, duration, accomodation_id);
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount =
              suppRate * duration * accomodation_id +
              calculateOfferAmount(rowData, rate, duration, accomodation_id);
          }
        } else {
          calculatedAmount =
            (rate + suppRate) * duration * parseInt(rowData.accomodation_id);
        }
      }
    }
  }
  //If allotment agremeneet doesn't have room's board, look at the board supplement
  else {
    if (findedSup) {
      suppRate = findedSup.rate;
      let baseRoomAmount = 0;
      roomPriceIds.map((id) => {
        if (contract_room_price[id].period_id === rowData.period_id) {
          calculatedRate = contract_room_price[id].price;
          if (rate_type == 2) {
            baseRoomAmount = calculatedRate * duration;
          } else {
            baseRoomAmount =
              calculatedRate * duration * parseInt(rowData.accomodation_id);
          }
        } else if (!contract_room_price[id].period_id) {
          if (language.locale == "en") {
            return "The price for the period is entered";
          } else {
            return "Bu periyot için fiyat girilmiş";
          }
        }
      });
      if (rate_type == 2) {
        if (rowData.offerIds) {
          //Eğer EB Offer ise
          if (offers[rowData.offerIds].offer_type_id == 1) {
            calculatedAmount =
              suppRate * duration +
              calculateOfferAmount(rowData, calculatedRate, duration);
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount =
              suppRate * duration +
              calculateOfferAmount(rowData, calculatedRate, duration);
          }
        } else {
          calculatedAmount = baseRoomAmount + suppRate * duration;
        }
      } else {
        if (rowData.offerIds) {
          let accomodation_id = parseInt(rowData.accomodation_id);

          //Eğer EB Offer ise
          if (offers[rowData.offerIds]?.offer_type_id == 1) {
            calculatedAmount =
              suppRate * duration * accomodation_id +
              calculateOfferAmount(
                rowData,
                calculatedRate,
                duration,
                accomodation_id
              );
          }
          // Eğer SDO Offer ise
          else {
            calculatedAmount =
              suppRate * duration * accomodation_id +
              calculateOfferAmount(
                rowData,
                calculatedRate,
                duration,
                accomodation_id
              );
          }
        } else {
          calculatedAmount =
            baseRoomAmount +
            suppRate * duration * parseInt(rowData.accomodation_id);
        }
      }
    }
    //board supplement girilmeme durumu
    else {
      if (language.locale == "en") {
        return "The price of the board supplement has not been entered";
      } else {
        return "Board supplemente ait fiyat girilmemiş.";
      }
    }
  }

  amountWithCurrency = calculatedAmount + " " + currencySymbol;

  return amountWithCurrency;
}

export default OfferAmountCalculate;
