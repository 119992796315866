import React, { useState, useEffect, useSelector } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import CmtImage from "../../../../@coremat/CmtImage";
import ContentLoader from "../../ContentLoader";
import { AuhMethods } from "../../../../services/auth";
import IntlMessages from "../../../utils/IntlMessages";
import AuthWrapper from "./AuthWrapper";
import Grid from "@material-ui/core/Grid";
import axios from "services/axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { MemoizedCaptcha } from "./Captcha";
import { Row } from "react-grid-system";
const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => {
  return {
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "50%",
        order: 2
      }
    },
    authContent: {
      padding: 30,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: props => (props.variant === "default" ? "50%" : "100%"),
        order: 1
      },
      [theme.breakpoints.up("xl")]: {
        padding: 50
      }
    },
    titleRoot: {
      marginBottom: 14,
      color: theme.palette.text.primary,
      fontSize: 20
    },
    textFieldRoot: {
      "& label.Mui-focused": {
        color:
          window.localStorage.getItem("theme-type") == "dark" ? "white" : "grey"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor:
          window.localStorage.getItem("theme-type") == "dark" ? "white" : "grey"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor:
            window.localStorage.getItem("theme-type") == "dark"
              ? "white"
              : "grey"
        },
        "&.Mui-focused fieldset": {
          borderColor:
            window.localStorage.getItem("theme-type") == "dark"
              ? "white"
              : "grey"
        }
      }
    },
    formcontrolLabelRoot: {
      "& .MuiFormControlLabel-label": {
        [theme.breakpoints.down("xs")]: {
          fontSize: 12
        }
      }
    },
    logo: {
      textAlign: "end"
    }
  };
});
// variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
  match
}) => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [captchaFlag, setCaptchaFlag] = useState(false);
  const [password, setPassword] = useState("");
  const [submitCount, setSubmitCount] = useState(0); // DESC: it need for useEffect trigger for runing loggin submit button function.
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const requestUrl = match.url.replace(/\/$/, "");

  const theme = localStorage.getItem("theme-type");
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("email") ? true : false
  );
  const onSubmit = () => {
    // if (!captchaFlag) {
    //   console.log("email",email)
    //   console.log("password",password)
    //   MySwal.fire(
    //     `<span style='font-size:100px;'></span>`,
    //     `<h3 style="color:rgb(34 65 88); letter-spacing:3px">E-mail and Password and Captcha Required</h3>`,
    //     "warning"
    //   );
    // }
    // else {

    // console.log("submit run!!")

    axios
      .post("controluser", { email, password })
      .then(res => {
        if (res.data.code === 200) {
          if (requestUrl === "/login") {
            dispatch(AuhMethods[method].onLogin({ email, password }));
            rememberMe
              ? localStorage.setItem("email", email)
              : localStorage.removeItem("email");
          } else {
            dispatch(AuhMethods[method].onMasterLogin({ email, password }));
          }
        } else {
          setCaptchaFlag(true);
          MySwal.fire(
            `<span style='font-size:100px;'></span>`,
            `<h3 style="color:rgb(34 65 88); letter-spacing:3px">Email or Password Wrong</h3>`,
            "warning"
          );
        }
      })
      .catch(err => {});
    //  }
  };

  const [errParam, setErrParam] = useState();
  const errText = errParam ? "Please input valid email !" : "";

  //const [takePass, setTakePass] = useState();
  const onSubmitTakePass = () => {
    const counts = [];
    if (email === null) {
      setErrParam(true);
    } else {
      setErrParam(false);
      const takePassArray = email.split("");

      /*************Find @ symbol in input value array***********/
      const findTakePassEmailSymbol = takePassArray.some(function(symbol) {
        return symbol === "@";
      });
      /***************************************************************/

      if (findTakePassEmailSymbol === true) {
        /*******************Find @ symbol count in array for validation*********************/
        var controlEmailSymbolCount = takePassArray.forEach(function(x) {
          counts[x] = (counts[x] || 0) + 1;
        });
        /************************************************************************************/

        if (counts["@"] > 1) {
          setErrParam(true);
        } else {
          axios
            .post("forgot-password", email)
            .then(response => {
              MySwal.fire(
                "Report",
                `<h3 style="color:rgb(34 65 88); letter-spacing:3px">${response.data.message}</h3>`,
                "warning"
              );
            })
            .catch(err => {
              MySwal.fire(
                "Report",
                `<h3 style="color:rgb(34 65 88); letter-spacing:3px"> You might have entered an incorrect email address. Please try again.</h3>`,
                "warning"
              );
            });
        }
      } else {
        setErrParam(true);
      }
    }
  };

  /********************************************************************/
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      setSubmitCount(prev => prev + 1);
      // onSubmit(); // it is run directly login function without recaptcha validation
    }
  };

  /************************ For Forgot Password set State and Change Component********************/
  const [toggle, setToggle] = useState(true);
  const forgotPass = () => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);

  /***********************************************************************************************/

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src="/images/auth/login-img.png" />
        </Box>
      ) : null}

      {toggle === true ? (
        <Box className={classes.authContent}>
          <Box>
            <Row justify="center" align="center">
              <CmtImage src="/images/mundosai.png" style={{ width: "68%" }} />
            </Row>
            <Typography
              component="div"
              variant="h1"
              className={classes.titleRoot}
            >
              <span
                style={{
                  color:
                    theme == "light"
                      ? "rgb(34 65 88)"
                      : "rgba(255 255 255 0.6)",
                  letterSpacing: "4px"
                }}
              >
                LOGIN
              </span>
            </Typography>

            <>
              <form type="submit" onKeyDown={handleKeyDown}>
                <Box mb={2}>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={event => {
                      setEmail(event.target.value);
                      event.preventDefault();
                    }}
                    defaultValue={email}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    autoComplete="off"
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={event => {
                      setPassword(event.target.value);
                      event.preventDefault();
                    }}
                    defaultValue={password}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    autoComplete="new-password"
                    required
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={5}
                >
                  <FormControlLabel
                    className={classes.formcontrolLabelRoot}
                    control={
                      <Checkbox
                        name="checkedA"
                        onChange={() => setRememberMe(!rememberMe)}
                        checked={rememberMe}
                      />
                    }
                    label="Remember me"
                  />
                  <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                    <span
                      onClick={forgotPass}
                      style={{
                        marginTop: "1rem",
                        fontSize: "13px",
                        color:
                          theme == "light"
                            ? "rgb(34 65 88)"
                            : "rgba(255 255 255 0.6)",

                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                    >
                      <IntlMessages id="appModule.forgotPassword" /> ?
                    </span>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={5}
                ></Box>
              </form>
              <MemoizedCaptcha
                captchaFlag={captchaFlag}
                setCaptchaFlag={setCaptchaFlag}
                login={onSubmit}
                submitCount={submitCount}
              />
            </>
          </Box>

          {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

          <ContentLoader />
          {/* <Box className={classes.logo}>
            {theme === "dark" ? (
              <CmtImage style={{ width: "82px" }} src="images/logo-dark.png" />
            ) : (
              <CmtImage src="images/logo-white.png" />
            )}
          </Box> */}
        </Box>
      ) : (
        <Box className={classes.authContent}>
          <Typography
            component="div"
            variant="h1"
            className={classes.titleRoot}
          >
            <span style={{ color: "rgb(34 65 88)", letterSpacing: "4px" }}>
              FORGOT PASSWORD
            </span>
          </Typography>
          <form type="submit" onKeyDown={handleKeyDown}>
            <Box mb={2}>
              <TextField
                error={errParam}
                helperText={errText}
                label={<IntlMessages id="appModule.email" />}
                type="email"
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                autoComplete="off"
              />
            </Box>

            <Grid container>
              <Grid item xs={9}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={5}
                >
                  <Button
                    onClick={onSubmitTakePass}
                    variant="contained"
                    color="primary"
                  >
                    Take a Password
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box
                  display="flex"
                  alignItems="right"
                  justifyContent="space-between"
                  mb={5}
                >
                  <Button
                    onClick={forgotPass}
                    variant="contained"
                    color="primary"
                  >
                    Login Page
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>

          {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

          <ContentLoader />
          <Box className={classes.logo}>
            {theme === "dark" ? (
              <CmtImage style={{ width: "82px" }} src="images/logo-dark.png" />
            ) : (
              <CmtImage src="images/logo-white.png" />
            )}
          </Box>
        </Box>
      )}
    </AuthWrapper>
  );
};

export default SignIn;
