import {
  GET_OPERATOR_ROOMS,
  GET_MATCHED_ROOMS_BY_HOTEL,
  ADD_OPERATOR_ROOMS,
} from "./types";

export const getOperatorRooms = (res) => (dispatch) => {
  dispatch({
    type: GET_OPERATOR_ROOMS,
    payload: res.data.data,
  });
};
export const getMatchedRoomsByHotel = (res) => (dispatch) => {
  dispatch({
    type: GET_MATCHED_ROOMS_BY_HOTEL,
    payload: res.data.data,
  });
};
export const addOperatorRooms = (res) => (dispatch) => {
  dispatch({
    type: ADD_OPERATOR_ROOMS,
    payload: res.data.data,
  });
};
