export const changeForeignChars = str => {
  const map = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    Ñ: "N"
  };

  if (typeof str === "string") {
    return str.replace(/[áéíóúñÁÉÍÓÚÑ]/g, m => map[m]);
  } else {
    return str;
  }
};
