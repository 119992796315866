import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import {
  addSeason,
  deleteSeason,
  getSeason,
  updateSeason
} from "../redux/actions/season";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};

export const getSeasonService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("season")
    .then(res => {
      dispatch(getSeason(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addSeasonService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("season", data)
    .then(res => {
      dispatch(addSeason(res));
      sweetAlerts("success", "New Season created successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendSeasonLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateSeasonsService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("season", data)
    .then(res => {
      if (res.data.message == "Season updated") {
        dispatch(updateSeason(res));
        sweetAlerts("success", "Season updated successfully!");
        sendLogService.sendSeasonLog(res, data);
      }
      dispatch(fetchSuccess());
      return res.data;
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteSeasonsService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`season/${id}`)
    .then(res => {
      dispatch(deleteSeason(res));
      dispatch(fetchSuccess());
      sendLogService.sendSeasonLog(res, id);
      sweetAlerts("success", "Season deleted successfully!");
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sweetAlerts("error", "Something went wrong!");
      sendLogService.errorlog(e, id);
    });
};
