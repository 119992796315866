import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import { sendMail } from "redux/actions/sendMail";

export const sendMailServices = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  const formData = new FormData();

  for (var key in data) {
    formData.append(key, data[key]);
  }

  return axios
    .post(`sendmail`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      dispatch(sendMail(res.data));
      dispatch(fetchSuccess());
      return res.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
