import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { addMasterUserService, editMasterUserService } from "services/user";
import {
  emailNotValid,
  requiredMessage,
  passwordMisMatch
} from "../../../../constants/ErrorMessages";
import AppSelectBox from "../../../Common/formElements/AppSelectBox";
import AppTextInput from "../../../Common/formElements/AppTextInput";
import GridContainer from "../../../GridContainer";
import { isValidEmail } from "../../../../utils/commonHelper";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  }
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}

const AddEditUser = ({ open, onCloseDialog }) => {
  const { masterCompanies } = useSelector(({ company }) => company);
  const { masterUsers } = useSelector(({ user }) => user);
  const { currentMasterUser } = useSelector(({ user }) => user);
  const classes = useStyles();
  const [userData, setUserData] = useState({
    fullname: "",
    email: "",
    companyId: ""
  });
  const [errorsData, setErrorsData] = useState({
    fullname: "",
    email: "",
    companyId: ""
  });
  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentMasterUser) {
      setUserData({
        ...userData,
        fullname: currentMasterUser.fullname,
        email: currentMasterUser.email,
        companyId: currentMasterUser.owned_company.id
      });
    }
  }, [currentMasterUser]);

  useEffect(() => {
    if (masterCompanies) {
      setCompanies(masterCompanies.filter(company => company.owner === null));
    }
  }, [masterCompanies]);

  const onUserSave = () => {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = userData;
        let findedMail = masterUsers.some(item => item.email == user.email);
        if (findedMail) {
          sweetAlerts("error", "Ths mail address is already taken!");
          reject();
        } else {
          dispatch(addMasterUserService(user, () => onCloseDialog()));
          resolve();
        }
      });
    });
  };

  const onUserUpdate = () => {
    const user = {
      ...userData,
      id: currentMasterUser.id
    };
    dispatch(editMasterUserService(user, () => onCloseDialog()));
  };

  // const onCompanyChange = (id) => {
  //   setCompanyId(id);
  // };

  const isFormValid = () => {
    let isValid = true;
    if (!userData.fullname) {
      setErrorsData({ ...errorsData, fullname: requiredMessage });
      isValid = false;
    } else {
      setErrorsData({ ...errorsData, fullname: "" });
    }
    if (!userData.email) {
      setErrorsData({ ...errorsData, email: requiredMessage });
      isValid = false;
    } else if (!isValidEmail(userData.email)) {
      setErrorsData({ ...errorsData, email: emailNotValid });
      isValid = false;
    } else {
      setErrorsData({ ...errorsData, email: "" });
    }
    if (currentMasterUser) {
      return isValid;
    }
    if (!userData.companyId) {
      setErrorsData({ ...errorsData, companyId: requiredMessage });
      isValid = false;
    } else {
      setErrorsData({ ...errorsData, companyId: "" });
    }
    return isValid;
  };

  const changeData = (name, value) => {
    setUserData({ ...userData, [name]: value });
    setErrorsData({ ...errorsData, [name]: "" });
  };

  const onSubmitClick = () => {
    if (isFormValid()) {
      if (currentMasterUser) {
        onUserUpdate();
      } else {
        onUserSave();
      }
    } else {
      sweetAlerts("error", "Please enter valid data!");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      className={classes.dialogRoot}
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentMasterUser ? "Edit User Details" : "Create New User"}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="standard"
            id="standard-basic"
            label="Full Name"
            autoComplete="off"
            value={userData.fullname}
            onChange={e => {
              changeData("fullname", e.target.value);
            }}
            helperText={errorsData.fullname}
            error={!userData.fullname}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="standard"
            id="standard-basic"
            label="Email Address"
            autoComplete="off"
            value={userData.email}
            onChange={e => {
              changeData("email", e.target.value);
            }}
            helperText={errorsData.email}
            error={!isValidEmail(userData.email)}
          />
        </Box>
        {!currentMasterUser ? (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={12}>
              <AppSelectBox
                fullWidth
                data={companies}
                label="Company"
                valueKey="id"
                variant="standard"
                id="standard-basic"
                labelKey="name"
                value={userData.companyId}
                onChange={e => {
                  changeData("companyId", e.target.value);
                }}
                helperText={errorsData.companyId}
                error={!userData.companyId}
              />
            </Grid>
          </GridContainer>
        ) : null}
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmitClick()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
