// **React Imports
import React, { useState } from "react";

// **MUI Core Imports
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@material-ui/core";

// **  MUI Imports
import { Autocomplete, TextField } from "@mui/material";

// **  Third Party Libraries
import { DatePicker, Space } from "antd";

const operationTypes = [
  "Open Sale",
  "Stop Sale",
  "Offer",
  "Offer Delete",
  "Contract On Sale"
];

const TransactionFilter = props => {
  // **  Props
  const { hotels, filterHandler } = props;

  // **  Range Picker
  const { RangePicker } = DatePicker;

  // **  States
  const [operationType, setOperationType] = useState();
  const [operationDate, setOperationDate] = useState([]);
  const [hotel, setHotel] = useState();

  // **  State Handlers
  const operationTypeHandler = type => setOperationType(type);
  const operationDateHandler = time => setOperationDate(time);
  const hotelHandler = hotel => setHotel(hotel);

  return (
    <Card>
      <CardHeader title={"Filter"} />
      <CardContent>
        <Grid
          container
          spacing={5}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={6} md={3}>
            <Autocomplete
              options={operationTypes}
              onChange={(_, val) => operationTypeHandler(val)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Operation Type"
                  variant="outlined"
                  value={operationType}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography style={{ textAlign: "start" }}>
              Operation Date
            </Typography>
            <Space direction="vertical" size={12}>
              <RangePicker
                format={"YYYY-MM-DD"}
                placeholder={["from", "to"]}
                onChange={(_, val) => operationDateHandler(val)}
              />
            </Space>
          </Grid>
          <Grid item xs={6} md={3}>
            <Autocomplete
              options={Object.values(hotels)}
              getOptionLabel={option => option.name}
              onChange={(_, val) => hotelHandler(val)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Hotel"
                  variant="outlined"
                  value={hotel?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={() =>
                filterHandler({ operationType, operationDate, hotel })
              }
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransactionFilter;
