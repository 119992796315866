import { GET_USER_PERMISSION, ADD_USER_PERMISSION, UPDATE_USER_PERMISSION, DELETE_USER_PERMISSION } from './types';

export const getUserPermission = res => dispatch => {
  dispatch({ type: GET_USER_PERMISSION, payload: res.data.data });
};
export const addUserPermission = res => dispatch => {
  dispatch({ type: ADD_USER_PERMISSION, payload: res.data.data });
};
export const updateUserPermission = res => dispatch => {
  dispatch({ type: UPDATE_USER_PERMISSION, payload: res.data.data });
};
export const deleteUserPermission = res => dispatch => {
  dispatch({ type: DELETE_USER_PERMISSION, payload: res.data.data });
};
