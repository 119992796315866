import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getOffers,
  addOffer,
  updateOffer,
  deleteOffer,
  getBotOffer,
} from "redux/actions/offers";
import axios from "./axios";
import axiosLogger from "./axiosLogger";
import axiosBot from "./axiosBot";
import { apihost } from "./axiosBot";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
import { GET_BOT_OFFER } from "redux/actions/types";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

export const getOfferService = (contract_id) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("offer", {
      params: {
        contract_id: contract_id,
      },
    })
    .then((res) => {
      dispatch(getOffers(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const addOfferService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("offer", data)
    .then((res) => {
      dispatch(addOffer(res));
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, data);
      return res.data;
    })
    .catch((e) => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateOfferService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("offer", data)
    .then((res) => {
      dispatch(updateOffer(res));
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, data);
      return res.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sweetAlerts("error", "Something went wrong!");
      sendLogService.errorlog(e, data);
    });
};
export const deleteOfferService = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`offer/${id}`)
    .then((res) => {
      dispatch(deleteOffer(res));
      //sweetAlerts("success", "Offer successfully deleted.");
      dispatch(fetchSuccess());
      sendLogService.offerServiceLog(res, id);
      return res.data.code;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const sendBotOffersService = (data, setButtonControl) => (dispatch) => {
  const token = localStorage.getItem("token");
  //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  setButtonControl(true);
  // dispatch(fetchStart());
  let sendData = logDataForState(data);
  return axios
    .post("botOffers", sendData)
    .then(async function(response) {
      // await dispatch({
      //   type: GET_BOT_OFFER,
      //   payload: response?.data?.data,
      // });
      setButtonControl(false);
      if (response?.data?.code === 200) {
        successTostAlert(response?.data?.message);
      }
      await dispatch({
        type: GET_BOT_OFFER,
        payload: response?.data?.data,
      });
      await dispatch(
        getOfferMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId,
          message: response.data.msg,
        })
      );
      // sendLogService.SHLogs(response, data);
    })
    .catch(function(error) {
      setButtonControl(false);

      errorTostAlert("");

      dispatch(fetchError());
    });
};

// export const sendOfferServiceSH = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   //axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   // dispatch(fetchStart());
//   data["endpoint"] = apihost;
//   data["operator"] = "SH";
//   data["id"] = 2;
//   return axiosBot
//     .post("offers/SH", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//           message: response.data.msg,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("SH");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("SH");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceDN = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   // dispatch(fetchStart());
//   data["endpoint"] = apihost;
//   data["operator"] = "DN";
//   data["id"] = 3;
//   return axiosBot
//     .post("offers/DN", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("DN");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("DN");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceLMX = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "LMX";
//   data["id"] = 5;
//   return axiosBot
//     .post("offers/LMX", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("LMX");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("LMX");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServicePP = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "PP";
//   data["id"] = 1;
//   return axiosBot
//     .post("offers/PP", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("PP", response.data.message);
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("PP");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceW2M = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "W2M";
//   data["id"] = 1;
//   return axiosBot
//     .post("offers/W2M", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("W2M", response.data.message);
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("W2M");
//       dispatch(fetchError());
//     });
// };
// export const sendOfferServicePT = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "PT";
//   data["id"] = 1;
//   return axiosBot
//     .post("offers/PT", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("PT", response.data.message);
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("PT");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceFOR = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "FOR";
//   data["id"] = 6;
//   return axiosBot
//     .post("offers/FOR", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("FOR");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("FOR");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceSIT = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "SIT";
//   data["id"] = 7;
//   return axiosBot
//     .post("offers/SIT", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("SIT");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("SIT");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceXPUR = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "XPUR";
//   data["id"] = 11;
//   return axiosBot
//     .post("offers/XPUR", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       // sendLogService.SHLogs(response, data);
//       if (response.data.code != 302) {
//         errorTostAlert("XPUR");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("XPUR");
//       dispatch(fetchError());
//     });
// };
// export const sendOfferServiceWB = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "WB";
//   data["id"] = 4;
//   return axiosBot
//     .post("offers/WB", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("WB");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("WB");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceWEBBEDS = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "WEBBEDS";
//   data["id"] = 8;
//   return axiosBot
//     .post("offers/WEBBEDS", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         if (response.data.hasOwnProperty("message")) {
//           errorTostAlert("WEBBEDS", response.data.message);
//         }
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("WEBBEDS");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceLMT = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "LMT";
//   data["id"] = 10;
//   return axiosBot
//     .post("offers/LMT", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("LMT");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("LMT");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceIVC = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "IVC";
//   data["id"] = 10;
//   return axiosBot
//     .post("offers/IVC", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("IVC");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("IVC");
//       dispatch(fetchError());
//     });
// };

// export const sendOfferServiceJUMBO = (data) => (dispatch) => {
//   const token = localStorage.getItem("token");
//   axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
//   // dispatch(fetchStart());
//   dispatch({
//     type: GET_BOT_OFFER,
//     payload: logDataForState(data),
//   });
//   data["endpoint"] = apihost;
//   data["operator"] = "JUMBO";
//   data["id"] = 10;
//   return axiosBot
//     .post("offers/JUMBO", data)
//     .then(function(response) {
//       dispatch(
//         getOfferMongoDBService({
//           userEmail: data.user.email,
//           hotelId: data.hotelId,
//         })
//       );
//       if (response.data.code != 302) {
//         errorTostAlert("JUMBO");
//         dispatch(fetchError());
//       }
//     })
//     .catch(function(error) {
//       errorTostAlert("JUMBO");
//       dispatch(fetchError());
//     });
// };

export const getOfferMongoDBService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allOffer/`,
    data: data,
  })
    .then(async function(res) {
      // dispatch(getBotOffer(res));
      await dispatch({
        type: GET_BOT_OFFER,
        payload: res.data?.data,
      });
      //  await dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

const logDataForState = (data) => {
  const sellDates = {
    sellStartDate: data["sellStartDate"],
    sellEndDate: data["sellEndDate"],
  };
  const temp = {
    ...temp,
    sellDates: sellDates,
    hotelId: data["hotelId"],
    offers: data["offers"],
    operators: data.allOperators,
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date(),
  };
  return newData;
};

const errorTostAlert = (operator, message = null) => {
  Swal.fire({
    toast: "true",
    position: "top-end",
    icon: "error",
    title:
      "Operation could not be performed for " +
      operator +
      ". " +
      (message ? message : "Please try again!"),
    showConfirmButton: false,
    timer: 3000,
  });
};
const successTostAlert = (message) => {
  Swal.fire({
    toast: "true",
    position: "top-end",
    icon: "success",
    title: message,
    showConfirmButton: false,
    timer: 3000,
  });
};
