import {
  GET_MASTER_COMPANIES,
  ADD_MASTER_COMPANY,
  SET_MASTER_COMPANY_DETAILS,
  EDIT_MASTER_COMPANY,
} from '../../@jumbo/constants/ActionTypes';

export const getCompanies = companies => dispatch => {
  dispatch({ type: GET_MASTER_COMPANIES, payload: companies });
};
export const setCurrentMasterCompany = company => dispatch => {
  dispatch({ type: SET_MASTER_COMPANY_DETAILS, payload: company });
};
export const addMasterCompany = data => dispatch => {
  dispatch({ type: ADD_MASTER_COMPANY, payload: data });
};
export const editMasterCompany = data => dispatch => {
  dispatch({ type: EDIT_MASTER_COMPANY, payload: data });
};
