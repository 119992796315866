export const isNumeric = (e) =>
  e.key === "e" ||
  e.key === "+" ||
  e.key === "." ||
  e.key === "," ||
  e.key === "E" ||
  e.key === "/" ||
  e.key === "*" ||
  e.key === "-" ||
  e.key === "?" ||
  e.key === "\\" ||
  e.key === "_";
