import {
  GET_TURNOVER_BASIS,
  ADD_TURNOVER_BASIS,
  UPDATE_TURNOVER_BASIS,
  DELETE_TURNOVER_BASIS,
  GET_CONTRIBUTION_FEE,
  ADD_CONTRIBUTION_FEE,
  UPDATE_CONTRIBUTION_FEE,
  DELETE_CONTRIBUTION_FEE,
  ADD_REPRESENTATIVE_FEE,
  GET_REPRESENTATIVE_FEE,
  UPDATE_REPRESENTATIVE_FEE,
  DELETE_REPRESENTATIVE_FEE,
  ADD_ADVERTISING_FEE,
  GET_ADVERTISING_FEE,
  UPDATE_ADVERTISING_FEE,
  DELETE_ADVERTISING_FEE,
  ADD_MARKETING_PROMOTION,
  GET_MARKETING_PROMOTION,
  UPDATE_MARKETING_PROMOTION,
  DELETE_MARKETING_PROMOTION,
  ADD_CATALOGUE_CONTRIBUTION,
  GET_CATALOGUE_CONTRIBUTION,
  UPDATE_CATALOGUE_CONTRIBUTION,
  DELETE_CATALOGUE_CONTRIBUTION
} from "./types";

export const getTurnoverBasis = res => dispatch => {
  dispatch({
    type: GET_TURNOVER_BASIS,
    payload: res.data
  });
};

export const addTurnoverBasis = turnoverBasis => dispatch => {
  dispatch({
    type: ADD_TURNOVER_BASIS,
    payload: turnoverBasis
  });
};

export const updateTurnoverBasis = turnoverBasis => dispatch => {
  dispatch({
    type: UPDATE_TURNOVER_BASIS,
    payload: turnoverBasis
  });
};

export const deleteTurnoverBasis = turnoverBasis => dispatch => {
  dispatch({
    type: DELETE_TURNOVER_BASIS,
    payload: turnoverBasis
  });
};

export const getContributionFee = contributionFee => dispatch => {
  dispatch({
    type: GET_CONTRIBUTION_FEE,
    payload: contributionFee
  });
};

export const addContributionFee = contributionFee => dispatch => {
  dispatch({
    type: ADD_CONTRIBUTION_FEE,
    payload: contributionFee
  });
};

export const updateContributionFee = contributionFee => dispatch => {
  dispatch({
    type: UPDATE_CONTRIBUTION_FEE,
    payload: contributionFee
  });
};

export const deleteContributionFee = contributionFee => dispatch => {
  dispatch({
    type: DELETE_CONTRIBUTION_FEE,
    payload: contributionFee
  });
};

export const addRepresentativeFee = representativeFee => dispatch => {
  dispatch({
    type: ADD_REPRESENTATIVE_FEE,
    payload: representativeFee
  });
};

export const getRepresentativeFee = representativeFee => dispatch => {
  dispatch({
    type: GET_REPRESENTATIVE_FEE,
    payload: representativeFee
  });
};

export const updateRepresentativeFee = representativeFee => dispatch => {
  dispatch({
    type: UPDATE_REPRESENTATIVE_FEE,
    payload: representativeFee
  });
};

export const deleteRepresentativeFee = representativeFee => dispatch => {
  dispatch({
    type: DELETE_REPRESENTATIVE_FEE,
    payload: representativeFee
  });
};

export const addAdvertisingFee = advertisingFee => dispatch => {
  dispatch({
    type: ADD_ADVERTISING_FEE,
    payload: advertisingFee
  });
};

export const getAdvertisingFee = advertisingFee => dispatch => {
  dispatch({
    type: GET_ADVERTISING_FEE,
    payload: advertisingFee
  });
};

export const updateAdvertisingFee = advertisingFee => dispatch => {
  dispatch({
    type: UPDATE_ADVERTISING_FEE,
    payload: advertisingFee
  });
};

export const deleteAdvertisingFee = advertisingFee => dispatch => {
  dispatch({
    type: DELETE_ADVERTISING_FEE,
    payload: advertisingFee
  });
};

export const addMarketingPromotion = marketingPromotion => dispatch => {
  dispatch({
    type: ADD_MARKETING_PROMOTION,
    payload: marketingPromotion
  });
};

export const getMarketingPromotion = marketingPromotion => dispatch => {
  dispatch({
    type: GET_MARKETING_PROMOTION,
    payload: marketingPromotion
  });
};

export const updateMarketingPromotion = marketingPromotion => dispatch => {
  dispatch({
    type: UPDATE_MARKETING_PROMOTION,
    payload: marketingPromotion
  });
};

export const deleteMarketingPromotion = marketingPromotion => dispatch => {
  dispatch({
    type: DELETE_MARKETING_PROMOTION,
    payload: marketingPromotion
  });
};

export const addCatalogueContribution = catalogueContribution => dispatch => {
  dispatch({
    type: ADD_CATALOGUE_CONTRIBUTION,
    payload: catalogueContribution
  });
};

export const getCatalogueContribution = catalogueContribution => dispatch => {
  dispatch({
    type: GET_CATALOGUE_CONTRIBUTION,
    payload: catalogueContribution
  });
};

export const updateCatalogueContribution = catalogueContribution => dispatch => {
  dispatch({
    type: UPDATE_CATALOGUE_CONTRIBUTION,
    payload: catalogueContribution
  });
};

export const deleteCatalogueContribution = catalogueContribution => dispatch => {
  dispatch({
    type: DELETE_CATALOGUE_CONTRIBUTION,
    payload: catalogueContribution
  });
};
