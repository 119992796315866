import { GET_OPERATOR, ADD_OPERATOR, UPDATE_OPERATOR, DELETE_OPERATOR } from '../actions/types';
import { normalizeOperators } from 'redux/schemas/operators';

const initialState = {
  operators: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATOR:
      const data = [];
      data['operators'] = action.payload;
      const normalizeOperator = normalizeOperators([data]);
      return {
        ...state,
        operators: normalizeOperator.entities.operators ? normalizeOperator.entities.operators : [],
      };

    case ADD_OPERATOR:
      const addData = [];
      addData['operators'] = [action.payload];
      addData['addStateOperators'] = state.operators;
      const addNormalizeOperators = normalizeOperators([addData]);
      return {
        operators: addNormalizeOperators.entities.operators,
      };
    case UPDATE_OPERATOR:
      const updatedData = [];
      updatedData['updateStateOperators'] = [action.payload];
      const UpdatedNormalizeData = normalizeOperators([updatedData]);
      const updatedOperator = UpdatedNormalizeData.result[0].updateStateOperators[0];

      return {
        ...state,
        operators: {
          ...state.operators,
          [updatedOperator]: UpdatedNormalizeData.entities.operators[updatedOperator],
        },
      };

    case DELETE_OPERATOR:
      const deletedOperator = Object.keys(state.operators)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.operators[key];
          return obj;
        }, {});
      return {
        ...state,
        operators: deletedOperator,
      };
    default:
      return state;
  }
};
