import React from "react";
import { fetchStart, fetchSuccess } from "redux/actions";
import {
  getBoardMap,
  addBoardMap,
  deleteMapBoard
} from "redux/actions/boardMap";
import axios from "./axios";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getMapBoardDataService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("mapBoard")
    .then(res => {
      //   res.data.data["global_room_name"] = data.selections.room_name;
      //   sendLogService.roomMatchServiceLogs(res, data);
      dispatch(getBoardMap(res));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {});
};

export const addMapBoardDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("mapBoard", data)
    .then(res => {
      dispatch(addBoardMap(res));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {});
};

export const deleteMapBoardDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete("mapBoard", { data })
    .then(res => {
      dispatch(deleteMapBoard(data));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {});
};
