import { GET_OPERATOR_GLOBAL_ROOMS } from "../actions/types";

const initialState = {
  operatorGlobalRooms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATOR_GLOBAL_ROOMS:
      return { ...state, operatorGlobalRooms: action.payload };
    default:
      return state;
  }
};
