export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    // dateLocale:"en-US",
    name: 'English',
    icon: 'us',
    //  format: 'MM/DD/YYYY',
  },
  {
    languageId: 'turkish',
    locale: 'tr',
    // dateLocale:"tr-TR",
    name: 'Turkish',
    icon: 'tr',
    //   format: 'DD/MM/YYYY',
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   //  dateLocale:"zh-CN",
  //   icon: 'cn',
  //   format: 'MM/DD/YYYY',
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Spanish',
  //   // dateLocale:"es-ES",
  //   icon: 'es',
  //   format: 'MM/DD/YYYY',
  // },
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   //  dateLocale:"fr-BE",
  //   icon: 'fr',
  //   format: 'MM/DD/YYYY',
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   //  dateLocale:"it-IT",
  //   name: 'Italian',
  //   icon: 'it',
  //   format: 'MM/DD/YYYY',
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   //  dateLocale:"sa-IN",
  //   name: 'Arabic',
  //   icon: 'sa',
  //   format: 'MM/DD/YYYY',
  // },
];

export const flags = {
  en: '/images/flag/icons8-usa.png',
  tr: '/images/flag/icons8-turkey.png',
  zh: '/images/flag/icons8-china.png',
  es: '/images/flag/icons8-spain.png',
  fr: '/images/flag/icons8-france.png',
  it: '/images/flag/icons8-italy.png',
  ar: '/images/flag/icons8-saudi_arabia.png',
};
