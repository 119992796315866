import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getAllotmentActionType } from 'redux/actions/allotmentActionType';
import axios from './axios';

export const getAllotmentActionTypeService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('allotmentActionType')
    .then(res => {
      dispatch(getAllotmentActionType(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
