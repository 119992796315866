import {
  GET_OFFERS,
  ADD_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
  GET_BOT_OFFER
} from "./types";
import { normalizeTasks } from "redux/schemas/offer";

export const getOffers = res => dispatch => {
  const normalizeData = normalizeTasks(res.data.data);
  dispatch({
    type: GET_OFFERS,
    // payload: res.data.data,
    payload: normalizeData.entities
  });
};
export const addOffer = res => dispatch => {
  // const normalizeData = normalizeTasks([res.data.data]);
  dispatch({
    type: ADD_OFFER,
    // payload: normalizeData,
    payload: res.data.data
  });
};
export const updateOffer = res => dispatch =>
  dispatch({
    type: UPDATE_OFFER,
    payload: res.data.data
  });

export const deleteOffer = res => dispatch => {
  dispatch({
    type: DELETE_OFFER,
    payload: res.data.data
  });
};

export const getBotOffer = res => dispatch => {
  dispatch({
    type: GET_BOT_OFFER,
    payload: res.data.data
  });
};
