import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Card,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addOnSaleContractService,
  onSaleContractBotService,
} from "services/onSaleContract";
import { purple, red } from "@material-ui/core/colors";
import SendIcon from "@mui/icons-material/Send";
import {
  changeContractStatusService,
  ContractConfirmControlService,
} from "services/contract";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import _ from "lodash";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { getContractsByHotelService } from "services/operatorContracts";
import { useIntl } from "react-intl";
import { Alert } from "@mui/material";
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};
const theme = createTheme({
  palette: {
    primary: purple,
    secondary: red,
  },
});

export default function EditDashboardMarkup(props) {
  const { contracts } = useSelector(({ contract }) => contract);
  const { markets } = useSelector(({ markets }) => markets);
  const { operators } = useSelector(({ operators }) => operators);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { contract_room_price } = useSelector(({ contract }) => contract);
  const { markups } = useSelector(({ markups }) => markups);
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const { rate_ranges } = useSelector(({ markups }) => markups);
  const { cancellationPolicies } = useSelector(
    ({ cancellationPoliciesDetail }) => cancellationPoliciesDetail
  );
  const { contracts: operatorContracts } = useSelector(
    ({ operatorContracts }) => operatorContracts
  );
  const { PPhotels, W2Mhotels, WEBBEDShotels } = useSelector(
    ({ operatorHotels }) => operatorHotels
  );

  const cMarkets = [];
  const cOperators = [];
  const dispatch = useDispatch();

  const selectedContract = props.selectedContract;
  if (selectedContract !== null || undefined) {
    contracts[selectedContract].operators.map((op) =>
      cOperators.push(operators[op])
    );
    contracts[selectedContract].market.map((mr) => cMarkets.push(markets[mr]));
  }

  const themeType = localStorage.getItem("theme-type");

  const useStyles = makeStyles(() => ({
    root: {
      minWidth: "100%",
      minHeight: "100%",
      backgroundColor: themeType === "dark" ? "#363636" : "white",
    },
    rootcard: {
      minWidth: "100%",
      maxWidth: "100%",
      margin: "auto",
      backgroundColor: themeType === "dark" ? "#363636" : "white",
      color: themeType === "dark" ? "white" : "black",
    },
  }));

  const intl = useIntl();

  const [checked, setChecked] = useState([]);
  const [checkedMarket, setCheckedMarket] = useState([]);

  const [value, setValue] = useState(1);
  const [cancelPolicy, setCancelPolicy] = useState(1);
  const [priceType, setPriceType] = useState(1);
  const [selectedPPContract, setSelectedPPContract] = useState("");
  const [selectedW2MContract, setSelectedW2MContract] = useState("");
  const [selectedWEBBEDSContract, setSelectedWEBBEDSContract] = useState("");

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    dispatch(
      getContractsByHotelService(
        [1, 8, 15],
        [contracts[selectedContract].hotel_id]
      )
    );
  }, []);

  const handleToggleMarket = (marketData) => () => {
    const marketExists = checkedMarket.find(
      (market) => market.id === marketData.id
    );
    if (marketExists) {
      setCheckedMarket((markets) =>
        markets.filter((market) => market.id !== marketData.id)
      );
    } else {
      setCheckedMarket((markets) => [...markets, marketData]);
    }
  };

  const handleChange = (event) => {
    setValue(Number(event.target.value));
  };
  const handleChangeCancel = (event) => {
    setCancelPolicy(Number(event.target.value));
  };
  const handleChangePrice = (event) => {
    setPriceType(Number(event.target.value));
  };

  useEffect(() => {}, [value]);
  const classes = useStyles();
  const newData = {
    operator: checked,
    market: checkedMarket,
    markup: value,
    cancel_policies_id: cancelPolicy,
    contract_id: selectedContract,
    price_type: priceType,
  };

  function markupControlFunction() {
    let contract_room_ids = [];
    let nonPrice = [];
    let nonRateTypes = [];
    let control = true;
    let allRatesInContract = [];
    let allRatesInMarkup = [];
    //kontratın odaları ve rate idleri alınır
    Object.values(contract_room).map((con_room) => {
      if (con_room.contract_id == selectedContract) {
        contract_room_ids.push({
          roomId: con_room.id,
          rateId: Number(con_room.rate_id),
        });
        if (!allRatesInContract.includes(Number(con_room.rate_id))) {
          allRatesInContract.push(Number(con_room.rate_id));
        }
      }
    });
    //alınan oda idlerine göre her rate type için en yüksek fiyat alınır
    contract_room_ids.map((crId) => {
      let tempPrice = 0;
      Object.values(contract_room_price).map((price) => {
        if (price.contract_room_id == crId.roomId) {
          if (tempPrice < price.price) {
            tempPrice = price.price;
          }
        }
      });
      //aynı rate tipinde olan farklı odalar olabilir bu yüzden daha önceden nonPrice dizisine eklenmiş mi ona bakılıyor
      let index = nonPrice.findIndex((element) => {
        return element.rateId == crId.rateId;
      });
      //eğer eklenmiş ise index değeri -1 den farklı olacaktır bu yüzden o indexde ki değer ile karşılaştırma yapılarak değer değiştiriliyor
      if (index !== -1) {
        if (nonPrice[index].price < tempPrice) {
          nonPrice[index].price = tempPrice;
        }
      } else {
        //eğer index değeri -1 ise eklenmemiştir ve nonPrice dizisine direkt pushlanır
        nonPrice.push({ price: tempPrice, rateId: Number(crId.rateId) });
      }
    });
    //seçilen markup a bağlı olan rate rangler map ile dönülerek fiyat ve varlık kontrolü yapılır ikisini de sağlarsa on sale çıkabilir
    Object.values(rate_ranges).map((rateRange) => {
      if (rateRange.markup_id == newData.markup) {
        //bu döngüde rate range içinde olmayan rate tipleri nonRateTypes arrayi içinde toplanıyor

        allRatesInContract.map((rateType, index) => {
          if (
            rateRange.apply_type.includes(rateType) &&
            !nonRateTypes.includes(rateType)
          ) {
            nonRateTypes[index] = rateType;
          }
        });

        rateRange.apply_type.forEach((item) => {
          allRatesInMarkup.push(item);
        });

        //bu döngüde rate type eş olanların fiyat karşılaştırılması yapılıyor
        allRatesInMarkup.forEach((rateMarkup) => {
          nonPrice.forEach((nonP) => {
            if (
              allRatesInContract.includes(nonP.rateId) &&
              nonP.rateId == rateMarkup
            ) {
              if (
                nonP.price >= Number(rateRange.min_rate) &&
                nonP.price <= Number(rateRange.max_rate)
              ) {
                control = false;
              }
            }
          });
        });
        // nonPrice.map((nonP) => {
        //   rateRange.apply_type.map((rateR) => {
        //     if (allRatesInContract.includes(rateR)) {
        //       if (nonP.price > rateRange.max_rate && nonP.rateId == rateR) {
        //         control = true;
        //       }
        //     } else {
        //       control = true;
        //     }
        //   });
        // });
      }
    });
    //control false değerindeyse ya da nonRateTypes dizisinin içi boş ise kontrat satış için diğer fonk.lara bakılır
    if (
      !_.isEqual(allRatesInContract, nonRateTypes) ||
      !_.difference(nonRateTypes, allRatesInMarkup)
    ) {
      let nonRateTypeCodes = "";
      let differenceRate = _.difference(allRatesInContract, nonRateTypes);
      differenceRate.forEach((nrt) => {
        nonRateTypeCodes = nonRateTypeCodes + rateTypes[nrt].code + ",";
      });
      nonRateTypeCodes = nonRateTypeCodes.slice(0, -1);
      sweetAlerts(
        "warning",
        "The ones with the rate type " +
          nonRateTypeCodes +
          " in the selected markup are missing!"
      );
    } else if (control) {
      Swal.fire({
        title:
          '<div style="font-size:24px;font-weight:bold;">The price range of the selected markup and the contract price range do not match.</div>',
        icon: "warning",
        html:
          "The markup price range must not be lower than the contract price range!",
      });
    } else if (
      checked.find((element) => element.id == 1) &&
      (selectedPPContract === "" ||
        selectedPPContract === null ||
        selectedPPContract === undefined)
    ) {
      sweetAlerts("warning", "Please select a contract for Peakpoint !");
    } else if (
      checked.find((element) => element.id == 15) &&
      (selectedW2MContract === "" ||
        selectedW2MContract === null ||
        selectedW2MContract === undefined)
    ) {
      sweetAlerts("warning", "Please select a contract for World 2 Meet !");
    } else if (
      checked.find((element) => element.id == 8) &&
      (selectedWEBBEDSContract === "" ||
        selectedWEBBEDSContract === null ||
        selectedWEBBEDSContract === undefined)
    ) {
      sweetAlerts("warning", "Please select a contract for WEBBEDS !");
    } else {
      if (
        checked.find((element) => element.id == 1) &&
        (selectedPPContract !== "" ||
          selectedPPContract !== null ||
          selectedPPContract !== undefined) &&
        Object.values(operatorContracts).length > 0
      ) {
        newData.operator = newData.operator.map((operator) => {
          if (operator.id == 1) {
            return {
              ...operator,
              contract_id: selectedPPContract,
              contract_name: Object.values(operatorContracts).find(
                (operator_contract) =>
                  operator_contract.contract_id == selectedPPContract
              )?.contract_name,
            };
          }
        });
      }
      if (
        checked.find((element) => element.id == 8) &&
        (selectedWEBBEDSContract !== "" ||
          selectedWEBBEDSContract !== null ||
          selectedWEBBEDSContract !== undefined) &&
        Object.values(operatorContracts).length > 0
      ) {
        newData.operator = newData.operator.map((operator) => {
          if (operator.id == 8) {
            return {
              ...operator,
              contract_id: selectedWEBBEDSContract,
              contract_name: Object.values(operatorContracts).find(
                (operator_contract) =>
                  operator_contract.contract_id == selectedWEBBEDSContract
              )?.contract_name,
            };
          }
        });
      }

      if (
        checked.find((element) => element.id == 15) &&
        (selectedW2MContract !== "" ||
          selectedW2MContract !== null ||
          selectedW2MContract !== undefined) &&
        Object.values(operatorContracts).length > 0
      ) {
        newData.operator = newData.operator.map((operator) => {
          if (operator.id == 15) {
            return {
              ...operator,
              contract_id: selectedW2MContract,
              contract_name: Object.values(operatorContracts).find(
                (operator_contract) =>
                  operator_contract.contract_id == selectedW2MContract
              )?.contract_name,
            };
          }
        });
      }

      saveAll();
    }
  }
  const responseBot = (operators, res) => {
    let alertTexts = "";
    let tempOperator = [];
    //bottan dönen responslar arasında 200 olanlar varsa onlar temp operatöre atılıp onsale tablosuna kayıt edilir.
    //200 haricindeki responselar alerte bastırılmak için bir stringe atılıt.
    if (res.code != 500) {
      operators.map((operator, key) => {
        //satışa çıkamayıp hata dönüyorsa
        if (res[operator.name] != 200) {
          alertTexts =
            alertTexts +
            "<b style='color:red !important;'>&#9747;</b>" +
            res[operator.name] +
            "<br></br>";
        }
        if (res[operator.name] == 200) {
          //satışa çıkıp yapılamayan işlemler için hata dönüyorsa
          if (res[operator.name + "Message"]) {
            alertTexts =
              alertTexts +
              "<b style='color:orange !important;'>&#9888;</b>" +
              res[operator.name + "Message"] +
              "<br></br>";
          }
          //hata döndürmeden satışa çıkmış ise
          else {
            alertTexts =
              alertTexts +
              "<b style='color:#eed202 !important;'>&#10004;</b>" +
              operator.name +
              " operatöründe kontrat satışa açıldı" +
              "<br></br>";
          }
          tempOperator.push(operator);
        }
      });
      return { tempOperator, alertTexts };
    } else {
      Swal.fire(
        "Error!",
        intl.formatMessage({ id: "contract.not.send" }),
        "error"
      );
      return { tempOperator, alertTexts };
    }
  };
  const arrayContent = (data) => {
    let errorString = "";
    data.forEach((error) => {
      errorString = errorString + "-" + error + "<br></br>";
    });
    return errorString;
  };
  const OnSaleFunction = (newData) => {
    dispatch(onSaleContractBotService(newData)).then(async (res) => {
      let responseFunction = responseBot(newData.operator, res);
      let tempOperator = responseFunction["tempOperator"];
      let alertTexts = responseFunction["alertTexts"];
      // temp operatör birden fazla ise satışa en az bir operatörde açılmıştır.bunun için onsaleye çıkılır ve statü değiştirilir
      // inf:no alert parametresinin gönderilme sebebi=> eğer hata dönen operatör varsa hata alertinin bastırılması için
      if (Array.isArray(alertTexts)) {
        alertTexts = arrayContent(alertTexts);
      }
      if (tempOperator.length > 0) {
        newData["operator"] = tempOperator;
        let changeStatuData =
          alertTexts != ""
            ? {
                contract_id: selectedContract,
                status_id: 4,
                inf: "no_alert",
              }
            : { contract_id: selectedContract, status_id: 4 };
      }
      if (alertTexts != "") {
        await Swal.fire({
          icon: "warning",
          html: alertTexts,
        });
      }
      if (res.code == 702 || res.code == 707) {
        await dispatch(addOnSaleContractService(newData));
        await dispatch(
          changeContractStatusService({
            contract_id: selectedContract,
            status_id: 4,
          })
        );
      }
    });
  };

  const canSaveAll =
    newData.operator.length > 0 &&
    newData.market.length > 0 &&
    newData.markup &&
    newData.cancel_policies_id &&
    newData.price_type &&
    newData.contract_id;

  const saveAll = () => {
    if (canSaveAll) {
      //Revize modundan on saleye çıkarılıyorsa tekrardan konfirm kontrollerine girecek
      if (contracts[selectedContract]?.contract_statuses_id == 5) {
        dispatch(
          ContractConfirmControlService({
            id: selectedContract,
            status_id: 4,
            type: "waiting_bot_response",
          })
        ).then((res) => {
          if (res === 302) {
            OnSaleFunction(newData);
          }
        });
      } else {
        OnSaleFunction(newData);
      }
    } else {
      sweetAlerts("warning", "Please check the all fields!");
    }
  };

  let PeakPointContracts = [];
  let WEBBEDScontracts = [];
  let W2MContracts = [];

  if (operatorContracts) {
    Object.values(operatorContracts).map((cont) => {
      if (
        PPhotels != undefined &&
        PPhotels[cont.operator_hotel_id] !== undefined &&
        PPhotels[cont.operator_hotel_id].operator_id == 1
      ) {
        PeakPointContracts.push({
          id: cont.contract_id,
          label: cont.contract_name,
        });
      }
      if (
        WEBBEDShotels != undefined &&
        WEBBEDShotels[cont.operator_hotel_id] !== undefined &&
        WEBBEDShotels[cont.operator_hotel_id].operator_id == 8
      ) {
        WEBBEDScontracts.push({
          id: cont.contract_id,
          label: cont.contract_name,
        });
      }
      if (
        W2Mhotels != undefined &&
        W2Mhotels[cont.operator_hotel_id] !== undefined &&
        W2Mhotels[cont.operator_hotel_id].operator_id == 15
      ) {
        W2MContracts.push({
          id: cont.contract_id,
          label: cont.contract_name,
        });
      }
    });
  }

  const isMarketDisabled = (marketCountries) => {
    const selectedCountries = [];

    if (checked) {
      checked.forEach((check) =>
        check.countries.forEach((countryId) =>
          selectedCountries.push(countryId)
        )
      );
    }

    const disabled = marketCountries.some((country) =>
      selectedCountries.includes(country)
    );

    return !disabled;
  };

  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Alert severity="warning">
            {intl.formatMessage({
              id:
                "Please.make.sure.that.you.have.pulled.hotels.and.rooms.data.from.operators.before.you.on.sale.a.contract.if.there.is.a.missing.data.the.contract.will.not.be.on.sale",
            })}
          </Alert>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root} variant="outlined">
            <CardHeader
              title="OPERATOR"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent className={classes.rootcard}>
              <List className={classes.root}>
                {cOperators.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem
                      key={value}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value?.name} />
                      <ListItemSecondaryAction />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card className={classes.root} variant="outlined">
            <CardHeader
              title="MARKETS"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent className={classes.rootcard}>
              <List className={classes.root}>
                {cMarkets.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;
                  return (
                    <ListItem
                      key={value.id}
                      dense
                      button
                      // onClick={() => handleToggleMarket(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          onChange={handleToggleMarket(value)}
                          checked={
                            checkedMarket.find(
                              (market) => market.id === value.id
                            ) !== undefined
                          }
                          disableRipple
                          disabled={isMarketDisabled(value.countries)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value?.name} />
                      <ListItemSecondaryAction />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root} variant="outlined">
            <CardHeader
              title="MARKUPS"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent className={classes.rootcard}>
              <List>
                <FormControl component="fieldset">
                  <FormLabel component="legend" />
                  <RadioGroup
                    aria-label="gender"
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    {Object.values(markups).map((value) => {
                      return (
                        <FormControlLabel
                          value={value.id}
                          control={<Radio />}
                          label={
                            <span style={{ fontSize: "0.875rem" }}>
                              {value.name}
                            </span>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root} variant="outlined">
            <CardHeader
              title="CANCELLATION POLICIES"
              titleTypographyProps={{ variant: "subtitle2" }}
            />
            <CardContent className={classes.rootcard}>
              <List>
                <FormControl component="fieldset">
                  <FormLabel component="legend" />
                  <RadioGroup
                    aria-label="gender"
                    name="radio-buttons-group"
                    value={cancelPolicy}
                    onChange={handleChangeCancel}
                  >
                    {Object.values(cancellationPolicies).map((value) => {
                      return (
                        <FormControlLabel
                          label={
                            <span style={{ fontSize: "0.875rem" }}>
                              {value.name}
                            </span>
                          }
                          value={value.id}
                          control={<Radio />}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Card className={classes.root} variant="outlined">
          <CardHeader
            title="WHERE TO ADD MARKUP ?"
            titleTypographyProps={{ variant: "subtitle2" }}
          />
          <CardContent className={classes.rootcard}>
            <FormControl component="fieldset">
              <FormLabel component="legend" />
              <RadioGroup
                row
                aria-label="types"
                name="radio-buttons-group"
                onChange={handleChangePrice}
              >
                <FormControlLabel
                  label={
                    <span style={{ fontSize: "0.875rem" }}>{"Base Price"}</span>
                  }
                  value={"1"}
                  checked={priceType == 1}
                  control={<Radio />}
                />
                <FormControlLabel
                  label={
                    <span style={{ fontSize: "0.875rem" }}>
                      {"Calculated Price"}
                    </span>
                  }
                  value={"2"}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
            <Box style={{ float: "right", marginTop: "3px" }}>
              <ThemeProvider theme={theme}>
                <Button
                  style={{ marginLeft: "3px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    markupControlFunction();
                  }}
                  disabled={!canSaveAll}
                  startIcon={<SendIcon />}
                >
                  ON SALE
                </Button>
              </ThemeProvider>
            </Box>
          </CardContent>
        </Card>
        <Card className={classes.root} variant="outlined">
          {checked.find((operator) => {
            return operator.id == 1 || operator.id == 8 || operator.id == 15;
          }) && (
            <>
              {checked.find((operator) => operator.id == 1) && (
                <Grid item xs={2}>
                  <Card className={classes.root} variant="outlined">
                    <CardHeader
                      title="Peakpoint Contracts"
                      titleTypographyProps={{ variant: "subtitle2" }}
                    />
                    <CardContent className={classes.rootcard}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="peakpoint.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selectedPPContract ? false : true}
                          label={<IntlMessages id="peakpoint.contracts" />}
                          value={selectedPPContract ? selectedPPContract : ""}
                          onChange={(event, value) => {
                            setSelectedPPContract(event.target.value);
                          }}
                        >
                          {PeakPointContracts &&
                            PeakPointContracts.map((item, i) => (
                              <MenuItem value={item.id}>{item.label}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {checked.find((operator) => operator.id == 15) && (
                <Grid item xs={2}>
                  <Card className={classes.root} variant="outlined">
                    <CardHeader
                      title="World 2 Meet Contracts"
                      titleTypographyProps={{ variant: "subtitle2" }}
                    />
                    <CardContent className={classes.rootcard}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="world2meet.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selectedW2MContract ? false : true}
                          label={<IntlMessages id="world2meet.contracts" />}
                          value={selectedW2MContract ? selectedW2MContract : ""}
                          onChange={(event, value) => {
                            setSelectedW2MContract(event.target.value);
                          }}
                        >
                          {W2MContracts &&
                            W2MContracts.map((item, i) => (
                              <MenuItem value={item.id}>{item.label}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {checked.find((operator) => operator.id == 8) && (
                <Grid item xs={2}>
                  <Card className={classes.root} variant="outlined">
                    <CardHeader
                      title="webbeds Contracts"
                      titleTypographyProps={{ variant: "subtitle2" }}
                    />
                    <CardContent className={classes.rootcard}>
                      <FormControl fullWidth>
                        <InputLabel style={{ marginTop: "-11px" }}>
                          <IntlMessages id="webbeds.contracts" />{" "}
                        </InputLabel>
                        <Select
                          error={selectedWEBBEDSContract ? false : true}
                          label={<IntlMessages id="webbeds.contracts" />}
                          value={
                            selectedWEBBEDSContract
                              ? selectedWEBBEDSContract
                              : ""
                          }
                          onChange={(event, value) => {
                            setSelectedWEBBEDSContract(event.target.value);
                          }}
                        >
                          {WEBBEDScontracts &&
                            WEBBEDScontracts.map((item, i) => (
                              <MenuItem value={item.id}>{item.label}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </>
          )}
        </Card>
      </Grid>
    </div>
  );
}
