import { normalize, schema } from "normalizr";

const get_contract = new schema.Entity("get_contract");

const updatedUser = new schema.Entity("users", {
  get_contract: [get_contract],
});

const response = {
  updateUser: [updatedUser],
};

export const userUpdateNormalizr = (data) => normalize(data, [response]);
