import {
  GET_HOTEL_MATCH,
  ADD_HOTEL_MATCH,
  UPDATE_HOTEL_MATCH,
  DELETE_HOTEL_MATCH,
} from "../../redux/actions/types";

const initialState = {
  matched_hotels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_MATCH:
      return {
        ...state,
        matched_hotels: action.payload,
      };

    case ADD_HOTEL_MATCH:
      let getAddedMatch = Object.keys(action.payload)[0];

      return {
        ...state,
        matched_hotels: {
          ...state.matched_hotels,
          [getAddedMatch]: { ...action.payload[getAddedMatch] },
        },
      };

    case UPDATE_HOTEL_MATCH:
      let getUpdatedMatch = Object.keys(action.payload)[0];
      return {
        ...state,
        matched_hotels: {
          ...state.matched_hotels,
          [getUpdatedMatch]: { ...action.payload[getUpdatedMatch] },
        },
      };

    case DELETE_HOTEL_MATCH:


      let newState = Object.values(state.matched_hotels);

      newState = newState.filter(
        (item) => item.global_hotel_id != action.payload
      );
      let newStateObject = {};

      newState.forEach((item, index) => {
        newStateObject[index + 1] = item;
      });

      return {
        ...state,
        matched_hotels: newStateObject,
      };

    default:
      return state;
  }
};
