import * as React from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { IconButton } from "@material-ui/core";
import SyncIcon from "@mui/icons-material/Sync";
import { useDispatch, useSelector } from "react-redux";
import EditDashboardMarkup from "./EditDashboardMarkup";
//import { getOnSaleContractService } from 'services/onSaleContract';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, red } from "@material-ui/core/colors";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
const MySwal = withReactContent(Swal);

function ContractResyncModal(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { markups } = useSelector(({ markups }) => markups);
  const { operators } = useSelector(({ operators }) => operators);
  const { markets } = useSelector(({ markets }) => markets);
  const { cancellationPolicies } = useSelector(
    ({ cancellationPoliciesDetail }) => cancellationPoliciesDetail
  );

  const handleClose = () => {
    setOpen(false);
  };

  /* useEffect(() => {
    dispatch(getOnSaleContractService());
  }, [dispatch]);*/
  const theme = createTheme({
    palette: {
      primary: blue
    }
  });
  const themeCancel = createTheme({
    palette: {
      primary: red
    }
  });
  const handleCancel = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No!",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        props.setEditMarkupOpen(false);
      }
    });
  };
  const onSaleContract = useSelector(
    state => state.onSaleContract.onSaleContract
  );
  var onSaleData = [];
  if (props.editMarkupOpen == true) {
    onSaleContract.map(a => {
      if (a.contract_id == props.selectedContract) {
        var marketName = [];
        var operator = [];
        var markupName = [];
        var status = [];
        var cancelPolicy = [];
        var spl_market = a.market_id.split(",");
        var spl_operator = a.operator_id.split(",");

        spl_market.map(mrk => {
          Object.values(markets).map(pm => {
            if (pm.id == mrk) {
              marketName.push(pm.code, ", ");
            }
          });
        });
        spl_operator.map(op => {
          Object.values(operators).map(ops => {
            if (ops.id == op) {
              operator.push(ops.code, ", ");
            }
          });
        });
        Object.values(markups).map(mrkp => {
          if (mrkp.id == a.markup_id) {
            markupName.push(mrkp.name);
          }
        });
        Object.values(cancellationPolicies).map(cancelP => {
          if (cancelP.id == a.cancel_policies_id) {
            cancelPolicy.push(cancelP.name);
          }
        });
        if (props.contractSync == 1) {
          status.push([
            <Tooltip title="Contract Synced">
              <IconButton>
                <Button>
                  <DoneAllIcon color="success" />
                </Button>
              </IconButton>
            </Tooltip>
          ]);
        } else {
          status.push([
            <Tooltip title="Contract Could Not Be Synchronized">
              <IconButton>
                <Button>
                  <ErrorOutlineIcon color="error" />
                </Button>
              </IconButton>
            </Tooltip>
          ]);
        }
        onSaleData.push({
          market_id: marketName,
          operator_id: operator,
          markup_id: markupName,
          cancel_policies_id: cancelPolicy,
          status: status,
          price_type: a.price_type == 1 ? "Base Price" : "Calculated Price"
        });
      }
    });
  }
  const tableColumns = [
    {
      title: "Operator",
      field: "operator_id"
    },
    {
      title: "Market",
      field: "market_id"
    },
    {
      title: "Markup",
      field: "markup_id"
    },
    {
      title: "Price Type",
      field: "price_type"
    },
    {
      title: "Cancellation Policy",
      field: "cancel_policies_id"
    },
    {
      title: "Status",
      field: "status",
      editable: "never"
    },
    {
      title: "Resync",
      field: "resync",
      render: () => (
        <IconButton>
          <Button>
            <SyncIcon color="primary" /> Sync
          </Button>
        </IconButton>
      )
    }
  ];
  const useStyles = makeStyles(theme => ({
    root: {
      minWidth: "100%",
      minHeight: "100%",
      backgroundColor: theme.palette.background.paper
    },
    textColor: {
      backgroundColor: theme.palette.background.paper,
      color: localStorage.getItem("theme-type") === "dark" ? "white" : "black"
    },
    rootcard: {
      minWidth: "100%",
      maxWidth: "100%",
      margin: "auto",
      backgroundColor: theme.palette.background.paper
    },
    title: {
      fontSize: 12
    },
    margin: {
      margin: theme.spacing(1)
    },
    chip: {
      margin: 2
    }
  }));
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={props.editMarkupOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={classes.textColor}>
          Select Operator & Markup
        </DialogTitle>
        <DialogContent>
          {isAuthAdd && (
            <ThemeProvider theme={theme}>
              <EditDashboardMarkup selectedContract={props.selectedContract} />
            </ThemeProvider>
          )}

          <MaterialTable
            columns={tableColumns}
            data={onSaleData}
            title=""
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              padding: "6px 24px 7px 0",
              marginTop: 14
            }}
            options={{
              tableLayout: "auto",
              actionsColumnIndex: -1,
              search: false,
              addRowPosition: "first"
            }}
          />

          <ThemeProvider theme={themeCancel}>
            <Grid item xs={12} style={{ textAlign: "end", marginTop: 14 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                Close
              </Button>
            </Grid>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default React.memo(ContractResyncModal);
