import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { Button, Dialog, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { addContractRoomService } from "services/contract";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Card from "@material-ui/core/Card";
import { addRoomService } from "services/rooms";
import { red, green, grey, orange } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { FilterRow } from "devextreme-react/data-grid";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
  },
  test2: {
    margin: theme.spacing(2, 2, 2),
    //float: 'right',
  },
  paper: {
    //backgroundColor: '#2D2D2D',
    border: "2px solid",
    color: localStorage.getItem("theme-type") === "dark" ? "#fff" : "#000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    //maxWidth: '80rem',
    //minWidth: '80rem',
    maxHeight: "120rem",
    minHemaxHeight: "120rem",
  },
  button: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    maxWidth: "15%",
    // float: 'right',
  },
  dialogRoot: {
    position: "relative",
  },
  numberColumns: {
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
}));
const keysForRoomState = [
  "type_id",
  "grade_id",
  "base_id",
  "view_id",
  "sub_type_id",
  "promo_id",
];
const keysForRoomCode = [
  "types",
  "grades",
  "bases",
  "views",
  "subTypes",
  "promos",
];
export default function Rooms(props) {
  const {
    flag,
    handleClose,
    isAuthAdd,
    isAuthUpdate,
    isAuthDelete,
    tabIndex,
    setTabIndex,
    rowPerPage,
    setRowPerPage,
  } = props;
  const themeSave = createTheme({
    palette: {
      primary: green,
    },
  });
  const theme = createTheme({
    palette: {
      primary: grey,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const dispatch = useDispatch();

  const classes = useStyles();
  const intl = useIntl();

  //const contractRoomState = useSelector(state => state.contract.contract_room);
  const { contracts } = useSelector(({ contract }) => contract);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { rooms } = useSelector(({ rooms }) => rooms);
  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  const [all, setAll] = useState({});
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedItems, setSelectedItems] = useState({
    initialLoad: true,
    rows: [],
    columns: [],
  });
  let lastContractRoom = 0;
  let contractRooms = {};

  var data = [];
  contracts[selectedContract].contract_room.map((cr) => {
    data.push(contract_room[cr]);
  });
  lastContractRoom =
    contracts[selectedContract]?.contract_room[
      contracts[selectedContract]?.contract_room.length - 1
    ];
  contractRooms = data;
  const tableRef = React.createRef();

  const tableAddRoomColumns = [
    {
      title: <IntlMessages id="code" />,
      field: "code",
      editable: "never",
      editComponent: (props) => (
        <TextField
          id="code"
          name="code"
          inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
          defaultValue={props.rowData.code}
        />
      ),
    },
    {
      title: <IntlMessages id="type" />,
      field: "type_id",
      id: "type_id",
      validate: (rowData) => (rowData.type_id ? true : false),
      render: (rowData) => {
        return rooms.types[rowData.type_id]?.name
          ? rooms.types[rowData.type_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.types)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.types[props.rowData["type_id"]]}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.types[rowData.type_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
    {
      title: <IntlMessages id="grade" />,
      field: "grade_id",
      //lookup: all.grades,
      validate: (rowData) => (rowData.grade_id ? true : false),
      render: (rowData) => {
        return rooms.grades[rowData.grade_id]?.name
          ? rooms.grades[rowData.grade_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.grades)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.grades[props.rowData["grade_id"]]}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.grades[rowData.grade_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
    {
      title: <IntlMessages id="base" />,
      field: "base_id",
      initialEditValue: 1,
      validate: (rowData) => (rowData.base_id ? true : false),
      render: (rowData) => {
        return rooms.bases[rowData.base_id]?.name
          ? rooms.bases[rowData.base_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.bases)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.bases[props.rowData["base_id"]]}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.bases[rowData.base_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
    {
      title: <IntlMessages id="view" />,
      field: "view_id",
      initialEditValue: 1,
      validate: (rowData) => (rowData.view_id ? true : false),
      render: (rowData) => {
        return rooms.views[rowData.view_id]?.name
          ? rooms.views[rowData.view_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.views)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.views[props.rowData["view_id"]]}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.views[rowData.view_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
    {
      title: <IntlMessages id="sub.type" />,
      field: "sub_type_id",
      initialEditValue: 1,
      validate: (rowData) => (rowData.sub_type_id ? true : false),
      render: (rowData) => {
        return rooms.subTypes[rowData.sub_type_id]?.name
          ? rooms.subTypes[rowData.sub_type_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.subTypes)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.subTypes[props.rowData["sub_type_id"]]}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.subTypes[rowData.sub_type_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
    {
      title: <IntlMessages id="promo" />,
      field: "promo_id",
      initialEditValue: 1,
      validate: (rowData) => (rowData.promo_id ? true : false),
      render: (rowData) => {
        return rooms.promos[rowData.promo_id]?.name
          ? rooms.promos[rowData.promo_id]?.name
          : "";
      },
      editComponent: (props) => {
        return (
          <Autocomplete
            options={Object.values(rooms.promos)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={
              props.rowData["promo_id"] !== ""
                ? rooms.promos[props.rowData["promo_id"]]
                : ""
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        rooms.promos[rowData.promo_id]?.name
          .toUpperCase()
          .indexOf(term.toUpperCase()) > -1,
    },
  ];
  const clearSelect = () => {
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };
  const roomName = (room_id) => {
    let room = rooms.roomType[room_id];
    let roomName = "";
    roomName =
      rooms.types[room?.type_id]?.name +
      " " +
      rooms.grades[room?.grade_id]?.name +
      " " +
      (rooms.bases[room?.base_id]?.code !== "00"
        ? rooms.bases[room?.base_id]?.name + " "
        : "") +
      (rooms.views[room?.view_id]?.code !== "00"
        ? rooms.views[room?.view_id]?.name + " "
        : "") +
      (rooms.subTypes[room?.sub_type_id]?.code !== "0"
        ? rooms.subTypes[room?.sub_type_id]?.name + " "
        : "") +
      (rooms.promos[room?.promo_id]?.code !== "0"
        ? rooms.promos[room?.promo_id]?.name + " "
        : "");
    return roomName;
  };
  const addNewRoomName = (newData) => {
    let roomName = "";

    roomName =
      rooms.types[newData.type_id]?.name +
      " " +
      rooms.grades[newData.grade_id]?.name +
      " " +
      (rooms.bases[newData.base_id]?.code !== "00"
        ? rooms.bases[newData.base_id]?.name + " "
        : "") +
      " " +
      (rooms.views[newData.view_id]?.code !== "00"
        ? rooms.views[newData.view_id]?.name + " "
        : "") +
      " " +
      (rooms.subTypes[newData.sub_type_id]?.code !== "0"
        ? rooms.subTypes[newData.sub_type_id]?.name + " "
        : "") +
      " " +
      (rooms.promos[newData.promo_id]?.code !== "0"
        ? rooms.promos[newData.promo_id]?.name + " "
        : "");
    return roomName;
  };
  const saveSelectedRoomsasContractRooms = () => {
    clearSelect();
    let control = false;

    selectedItems.data.forEach((sr) => {
      sr["agency_room_name"] = roomName(sr.id);
      contractRooms.filter((cr) => {
        if (cr.contract_id == selectedContract && sr.id == cr.room_id) {
          control = true;
          sweetAlerts(
            "error",
            String(sr.code) + intl.formatMessage({ id: "is.already.exists" })
          );
        }
      });
    });
    if (control == false) {
      let data = {};
      data.rooms = selectedItems.data;
      data.contract_id = selectedContract;
      data.contract_room_no = contract_room[lastContractRoom]
        ? contract_room[lastContractRoom].contract_room_no + 1
        : 1;
      dispatch(addContractRoomService(data)).then((res) => {
        if (res === 201) {
          MySwal.fire(
            intl.formatMessage({ id: "success" }),
            intl.formatMessage({ id: "contract.room.added" }),
            "success"
          );
        }
      });
    }
  };
  const makeRoomCode = (data) => {
    let code = "";
    keysForRoomState.forEach((element, key) => {
      if (data[element] !== 0 && data[element] !== "code") {
        code = code.concat(rooms[keysForRoomCode[key]][data[element]].code);
      }
    });
    return code;
  };

  const handleCancel = () => {
    handleClose();
    clearSelect();
  };
  return (
    <Dialog
      open={flag}
      className={classes.dialogRoot}
      scroll="body"
      maxWidth="lg"
      fullWidth="on"
    >
      <Card className={classes.paper}>
        <MaterialTable
          tableRef={tableRef}
          //columns={tableAddRoomColumns}
          data={Object.values(roomType)}
          columns={
            selectedItems.initialLoad
              ? tableAddRoomColumns
              : selectedItems.columns
          }
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20],
            tableLayout: "fixed",
            actionsColumnIndex: -1,
            search: false,
            filtering: true,
            selection: true,
            showTitle: false,
            addRowPosition: "first",
            showTextRowsSelected: false,
            headerStyle: {
              fontSize: "16px",
              fontWeight: "bold",
            },
            //zaten eklenen odaların selectlerinin disable olması için ama toplu seçimi bozuyor
            /* selectionProps: rowData => {
              const selectable = selectableFilter(rowData.id) 
              //NOTE:: tableData object is not in documentation
              rowData.tableData.disabled = selectable==-1?true:false;
              return {
                disabled: !selectable
            }} */
          }}
          onRowsPerPageChange={(e) => setRowPerPage(e)}
          components={{
            OverlayLoading: () => <Loader />,
            EditRow: (props) => {
              return (
                <MTableEditRow
                  {...props}
                  onEditingCanceled={(mode, rowData) => {
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        if (mode == "update") {
                          Swal.fire({
                            title: intl.formatMessage({ id: "are.you.sure?" }),
                            text: intl.formatMessage({
                              id: "do.you.want.to.cancel.the.changes",
                            }),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#41C329",
                            allowOutsideClick: false,
                            cancelButtonColor: "#d33",
                            confirmButtonText: intl.formatMessage({
                              id: "yes",
                            }),
                            cancelButtonText: intl.formatMessage({ id: "no" }),
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props.onEditingCanceled(mode);
                              resolve();
                            } else if (result.isDenied) {
                              reject();
                            }
                          });
                        }
                        if (mode == "add") {
                          Swal.fire({
                            title: intl.formatMessage({ id: "are.you.sure?" }),
                            text: intl.formatMessage({
                              id: "do.you.want.to.cancel.the.changes",
                            }),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#41C329",
                            allowOutsideClick: false,
                            cancelButtonColor: "#d33",
                            confirmButtonText: intl.formatMessage({
                              id: "yes",
                            }),
                            cancelButtonText: intl.formatMessage({ id: "no" }),
                          }).then((result) => {
                            if (result.isConfirmed) {
                              props.onEditingCanceled(mode, rowData);
                              resolve();
                            } else if (result.isDenied) {
                              reject();
                            }
                          });
                        }
                        if (mode == "delete") {
                          props.onEditingCanceled(mode, rowData);
                        }
                      });
                    });
                  }}
                />
              );
            },
          }}
          icons={{
            Add: (props) => (
              <ThemeProvider theme={theme}>
                <ColorButton
                  variant="contained"
                  color="backgroundColor"
                  startIcon={<AddBoxIcon />}
                >
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
            Check: () => <ModeCheckIcon style={{ color: "green" }} />,
            Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
          }}
          editable={{
            isDeletable: (row) => false,
            isEditable: (row) => false,
            onRowAdd: isAuthAdd
              ? (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      newData["code"] = makeRoomCode(newData);
                      if (
                        !newData.type_id ||
                        !newData.grade_id ||
                        !newData.base_id ||
                        !newData.view_id ||
                        !newData.sub_type_id ||
                        !newData.promo_id
                      ) {
                        MySwal.fire(
                          "Oops...",
                          intl.formatMessage({
                            id: "please.fill.in.all.fields",
                          }),
                          "error"
                        );
                        reject();
                      } else if (
                        Object.values(roomType).filter(
                          (room) =>
                            room.base_id == newData.base_id &&
                            room.type_id == newData.type_id &&
                            room.grade_id == newData.grade_id &&
                            room.view_id == newData.view_id &&
                            room.sub_type_id == newData.sub_type_id &&
                            room.promo_id == newData.promo_id
                        ).length > 0
                      ) {
                        MySwal.fire(
                          "Oops...",
                          intl.formatMessage({ id: "room" }) +
                            intl.formatMessage({ id: "is.already.exists" }),
                          "error"
                        );
                        resolve();
                      } else {
                        newData.room_name = addNewRoomName(newData);
                        dispatch(addRoomService(newData)).then((res) => {
                          if (res === 201) {
                            MySwal.fire(
                              "Success...",
                              intl.formatMessage({ id: "room" }) +
                                intl.formatMessage({
                                  id: "created.successfully",
                                }),
                              "success"
                            );
                          }
                        });
                        resolve();
                      }
                    }, 1000);
                  })
              : undefined,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {}, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {}, 1000);
              }),
          }}
          //onSelectionChange={rows => setSelectedRooms(rows)}
          onSelectionChange={(rows) => {
            setSelectedItems({
              ...selectedItems,
              data: rows,
              initialLoad: false,
              columns: selectedItems.initialLoad
                ? tableAddRoomColumns
                : selectedItems.columns,
            });
          }}
        />

        <Grid
          className={classes.test2}
          item
          container
          xs={12}
          justifyContent="flex-end"
        >
          <ThemeProvider theme={themeCancel}>
            <Button
              className={classes.test2}
              variant="contained"
              color="primary"
              onClick={handleCancel}
            >
              <IntlMessages id="close" />
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={themeSave}>
            <Button
              // disabled={selectedItems.data.length ? false : true}
              className={classes.test2}
              variant="contained"
              color="primary"
              onClick={saveSelectedRoomsasContractRooms}
              startIcon={<SaveIcon />}
            >
              <IntlMessages id="add.room.to.contract" />
            </Button>
          </ThemeProvider>
        </Grid>
      </Card>
    </Dialog>
  );
}
