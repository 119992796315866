import React, { useEffect, useState } from "react";
import { Button, DialogActions } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import {
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { MemoizedContractCopyPeriod } from "./CopyPeriods";
import { copyContractService } from "services/contract";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import MultiSelect from "../../Common/MultiSelect";
import { controlContractConfilict } from "services/contract";
import { getBoardSupplamentService } from "services/boardSupplament";
import { getExtraService } from "services/extras";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const MySwal = withReactContent(Swal);

const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    minHeight: "100%",
    marginTop: "7vh",
    margin: theme.spacing(2),
  },
  rootcard: {
    minWidth: "100%",
    height: "6vh",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "65%",
    backgroundColor: theme.palette.background.paper,
    "& .rmsc .dropdown-heading .dropdown-heading-value": {
      maxWidth: "200px",
    },
    "& .dropdown-content ": {
      position: "absolute !important",
    },
  },
}));
const theme = createTheme({
  palette: {
    primary: green,
  },
});
const themeCancel = createTheme({
  palette: {
    primary: red,
  },
});
function CopyContract(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const { markets } = useSelector(({ markets }) => markets);
  const { marketCountries } = useSelector(({ markets }) => markets);
  const { operators } = useSelector(({ operators }) => operators);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { contracts } = useSelector(({ contract }) => contract);
  const { authUser } = useSelector(({ auth }) => auth);
  const countries = useSelector((state) => state.destinations.countries);
  const { boardSupplaments } = useSelector(
    ({ boardSupplament }) => boardSupplament
  );
  const { extras } = useSelector(({ extras }) => extras);
  const [selectedSeason, setSelectedSeason] = useState({
    season_id: "",
    season_code: "",
    start_date: "",
    end_date: "",
  });
  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [tempMarkets, setTempMarkets] = useState([]);
  const [operatorState, setOperatorState] = useState([]);
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;
  const handleClose = () => {
    setOpen(false);
    props.setCopyContractOpen(false);
  };
  const handleChangeDate = (name, value) => {
    let e = {
      target: {
        name: name,
        value: value,
      },
    };
    setSelectedSeason({
      ...selectedSeason,
      [e.target.name]: e.target.value,
    });
  };
  const [checked, setChecked] = React.useState({
    market: true,
    currency: true,
    operators: true,
    additionalPaxRates: true,
    hotelExtras: true,
    boardSup: true,
    offers: true,
    restrictions: true,
    specialNotes: true,
    paymentCon: true,
    termsCon: true,
    kickback: true,
    cancellationPol: true,
    periodPrice: true,
    period: false,
  });
  const handleToggle = (event) => {
    if (event.target.name == "market") {
      setChecked({
        ...checked,
        operators: event.target.checked,
        market: event.target.checked,
      });
    } else {
      setChecked({ ...checked, [event.target.name]: event.target.checked });
      if (event.target.name == "currency" && event.target.checked == true) {
        setSelectedMarkets({
          ...selectedMarkets,
          currency: "",
        });
      }
    }
  };
  useEffect(() => {
    dispatch(getBoardSupplamentService(props.selectedContract));
    dispatch(getExtraService(props.selectedContract));
  }, [props.selectedContract]);
  useEffect(() => {
    if (checked.period == true) {
      let seasonID = contracts[props.selectedContract].season_id;
      let seasonDATA = seasons[seasonID];

      setSelectedSeason({
        ...selectedSeason,
        season_id: seasonDATA.id,
        season_code: seasonDATA,
        start_date: moment(seasonDATA.start_date).format("YYYY-MM-DD"),
        end_date: moment(seasonDATA.end_date).format("YYYY-MM-DD"),
      });
    } else {
      setSelectedSeason({
        season_id: "",
        season_code: "",
        start_date: "",
        end_date: "",
      });
    }
  }, [props.selectedContract, checked.period]);

  const copyContract = async () => {
    let include_countries = [];
    let tempMarketsData = [];
    if (selectedMarkets.market && selectedMarkets.countries) {
      selectedMarkets.market.map((market) => {
        include_countries = [];
        selectedMarkets.countries.map((in_country) => {
          if (market.countries.includes(in_country.id)) {
            include_countries.push(in_country.id);
          }
        });

        tempMarketsData.push({
          market_id: market.id,
          includes: include_countries,
        });
      });
    }
    let contract_name_temp = "";
    contract_name_temp = seasons[selectedSeason.season_id].code + "-" + "C";
    if (selectedMarkets.length != 0) {
      if (
        selectedMarkets.hasOwnProperty("market") &&
        selectedMarkets.market.length
      ) {
        selectedMarkets.market.forEach(
          (element) =>
            (contract_name_temp =
              contract_name_temp + "-" + markets[element.id]?.code)
        );
      }
      if (selectedMarkets.currency !== undefined) {
        contract_name_temp =
          contract_name_temp + "-" + currencies[selectedMarkets.currency]?.code;
      } else {
        contract_name_temp =
          contract_name_temp +
          "-" +
          currencies[contracts[props.selectedContract]?.currency].code;
      }
    } else {
      contracts[props.selectedContract].market.forEach(
        (element) =>
          (contract_name_temp =
            contract_name_temp + "-" + markets[element]?.code)
      );
      if (selectedMarkets.currency !== undefined) {
        contract_name_temp =
          contract_name_temp + "-" + currencies[selectedMarkets.currency]?.code;
      } else {
        contract_name_temp =
          contract_name_temp +
          "-" +
          currencies[contracts[props.selectedContract].currency]?.code;
      }
    }
    if (moment(selectedSeason.start_date).isAfter(selectedSeason.end_date)) {
      sweetAlerts(
        "error",
        intl.formatMessage({ id: "start.date.must.be.before.end.date" })
      );
    } else if (
      selectedSeason.start_date == "Invalid date" ||
      selectedSeason.end_date == "Invalid date"
    ) {
      sweetAlerts("error", intl.formatMessage({ id: "invalid.dates" }));
    } else {
      const copyData = {
        season: selectedSeason,
        period: selectedPeriod,
        checked: { ...checked },
        market: checked.market ? [] : tempMarketsData,
        operator: checked.operators ? [] : selectedMarkets.operator,
        currency: selectedMarkets.currency,
        selectedContract: props.selectedContract,
        contract_name: contract_name_temp,
        user_id: authUser.id,
      };
      /* kontratın çakışma durumuna karşı backende istek atılıyor. 
      Oradan dönen sonuç true ise isThereContract değeri true yapılır(çakışan operator ya da ülkelerin isimleri gösteriliyor).
      False ise  isThereContract değeri false yapılır. */
      const dataControl = (copyData) => {
        //NOTE - Boardsupp ve Extra kopyalanacak eleman kontrolü
        /* 
        Boardsupp seçili ise yani kopyalanacak ise boardsuppları geziyoruz gezerken seçili boardsuppun(ilk mapte gezdiği eleman) diğer board supplar ile kontrolüne bşlıyoruz
        ilk mapte gezdiği boardsuppp tüm board supplar iler kontrolü yapılıyor eğer to_boardları aynı ise roomları bir değişkene atılıp diğer karşılaştırma yapılan
        boarsupp'un odaları içerisinde bulunuyorsa veya aynı ise true olarak atıyoruz.
        True olmayan elemanlar aynı boaarda ve roomlara ait olmayanlar yani kopyalanacak boardsupplardır.
        
        Aynısı Extra için geçerli
        */
        if (copyData.checked.boardSup) {
          if (copyData.checked.period) {
            copyData["copyBoardIds"] = Object.entries(boardSupplaments).reduce(
              (acc, [key, value]) => {
                if (value.contract_id == copyData.selectedContract) {
                  acc.push(value.id);
                }
                return acc;
              },
              []
            );
          } else {
            let toBoardObject = {};
            Object.values(boardSupplaments).map((supp) => {
              let roomType = supp.room_types;
              Object.values(boardSupplaments).map((board) => {
                if (board.id != supp.id && board.to_board == supp.to_board) {
                  let roomInclude = roomType.some(
                    (r) => board.room_types.indexOf(r) >= 0
                  );
                  if (roomInclude) {
                    toBoardObject[board.id] = true;
                  }
                } else if (!toBoardObject[board.id]) {
                  toBoardObject[board.id] = false;
                }
              });
            });
            let copyBoardIds = [];
            Object.keys(toBoardObject).map((key, index) => {
              if (toBoardObject[key] == false) {
                copyBoardIds.push(Number(key));
              }
            });
            copyData["copyBoardIds"] = copyBoardIds;
          }
        }
        if (copyData.checked.hotelExtras) {
          let toExtraObject = {};
          extras.map((extra) => {
            let roomType = extra.room_types;
            extras.map((ex) => {
              if (ex.id != extra.id && ex.to_board == extra.to_board) {
                let roomInclude = roomType?.some(
                  (r) => ex.room_types.indexOf(r) >= 0
                );
                if (roomInclude) {
                  toExtraObject[ex.id] = true;
                }
              } else if (!toExtraObject[ex.id]) {
                toExtraObject[ex.id] = false;
              }
            });
          });
          let copyExtraIds = [];
          Object.keys(toExtraObject).map((key, index) => {
            if (toExtraObject[key] == false) {
              copyExtraIds.push(Number(key));
            }
          });
          copyData["copyExtraIds"] = copyExtraIds;
        }
        return true;
      };

      const res = await dispatch(controlContractConfilict(copyData));

      let isThereContract = false;
      let confilictCountries = "";
      let confilictOpertors = "";
      if (res.data.result) {
        isThereContract = true;
        confilictCountries = Object.values(countries)
          .filter((country) =>
            res.data.countries
              .slice(0, -2)
              .split(", ")
              .includes(country.id.toString())
          )
          .map((country) => country.name)
          .join(",");
        let contrr = res.data.contractName;
        confilictOpertors = res.data.operators.slice(0, -2);
        await sweetAlerts(
          "warning",
          confilictOpertors +
            (confilictOpertors.split(",").length > 1
              ? " coded operators"
              : " coded operator") +
            " and " +
            confilictCountries +
            (confilictCountries.split(",").length > 1
              ? " countries "
              : " country ") +
            contrr +
            " available for coded contract. Change operator or country and try again!"
        );
      }
      if (!isThereContract) {
        if (copyData.checked.boardSup || copyData.checked.hotelExtras) {
          if (dataControl(copyData)) {
            if (!copyData.hasOwnProperty("copyExtraIds")) {
              copyData["copyExtraIds"] = [];
            }
            if (!copyData.hasOwnProperty("copyBoardIds")) {
              copyData["copyBoardIds"] = [];
            }

            dispatch(copyContractService(copyData)).then((res) => {
              if (res.data.code !== 500) {
                handleClose();
              }
            });
          }
          // dataControl(copyData) == true
          //   ? dispatch(copyContractService(copyData))
          //   : "";
        } else {
          copyData["copyExtraIds"] = [];
          copyData["copyBoardIds"] = [];
          dispatch(copyContractService(copyData)).then((res) => {
            if (res.data.code !== 500) {
              handleClose();
            }
          });
        }
      }
    }
  };

  const handleSelectMarket = (markets) => {
    let newCountries = [];
    setSelectedMarkets({
      ...selectedMarkets,
      market: markets ? markets : "",
    });
    markets.forEach((market) => {
      if (
        market.hasOwnProperty("countries") &&
        market.countries !== null &&
        market.countries !== undefined
      ) {
        market.countries.forEach((country) => {
          if (!newCountries?.find((c) => c.id == country)) {
            newCountries.push(
              Object.values(marketCountries).find(
                (value) => value.id === country
              )
            );
          }
        });
      }
    });
    setSelectedCountries(newCountries);
    if (markets.length == 0) {
      setSelectedMarkets((prevState) => ({
        ...prevState,
        market: [],
        countries: [],
        operator: [],
      }));
    }
  };

  const handleSelectCountry = (country) => {
    setSelectedMarkets({
      ...selectedMarkets,
      countries: country,
    });
  };
  const handleSelectOperator = (operator) => {
    setSelectedMarkets({
      ...selectedMarkets,
      operator: operator,
    });
  };

  const countriesOperator = () => {
    let tempOperator = [];
    if (selectedMarkets.countries && selectedMarkets.countries.length > 0) {
      // get Ids of selected countries
      const selectedMarketsCountriesIds = selectedMarkets.countries.map(
        (country) => country.id
      );

      tempOperator = Object.values(operators).reduce((acc, operator) => {
        if (
          operator.countries &&
          operator.countries != null &&
          operator.countries.length > 0
        ) {
          for (let i = 0; i < operator.countries.length; i++) {
            if (selectedMarketsCountriesIds.includes(operator.countries[i])) {
              acc.push(operator);
              break;
            }
          }
        }
        return acc;
      }, []);
    }
    return tempOperator;
  };
  const handleCancel = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage({
        id: "you.will.not.be.able.to.revert.this.action",
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" }),
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        props.setCopyContractOpen(false);
        setSelectedSeason([]);
      }
    });
  };
  const classes = useStyles();

  useEffect(() => {
    if (selectedMarkets.market) {
      let tempMarkets = [];
      selectedMarkets.market.map((m) => {
        if (tempMarkets.indexOf(m) == -1) {
          tempMarkets.push(markets[m.id]);
        }
      });
      setTempMarkets(tempMarkets);
    }
    if (selectedMarkets.operators) {
      let tempOperators = [];
      selectedMarkets.operators.map((op) => {
        tempOperators.push(operators[op]);
      });
      setOperatorState(tempOperators);
    }
  }, [selectedMarkets]);
  const dataCheck = () => {
    if (checked.period == true) {
      if (!checked.currency) {
        if (
          !(
            selectedMarkets.hasOwnProperty("currency") &&
            selectedMarkets.currency &&
            selectedMarkets.currency != ""
          )
        ) {
          return true;
        }
        // return false;
      }
      if (!checked.market) {
        if (
          !(
            selectedMarkets.hasOwnProperty("market") &&
            selectedMarkets.market.length &&
            selectedMarkets.hasOwnProperty("countries") &&
            selectedMarkets.countries.length &&
            selectedMarkets.hasOwnProperty("operator") &&
            selectedMarkets.operator.length
          )
        ) {
          return true;
        }
      }

      return false;
    } else {
      if (selectedPeriod.length > 0) {
        if (!checked.market) {
          if (
            !(
              selectedMarkets.hasOwnProperty("market") &&
              selectedMarkets.market.length &&
              selectedMarkets.hasOwnProperty("countries") &&
              selectedMarkets.countries.length &&
              selectedMarkets.hasOwnProperty("operator") &&
              selectedMarkets.operator.length
            )
          ) {
            return true;
          }
        }
        if (!checked.currency) {
          if (
            !(
              selectedMarkets.hasOwnProperty("currency") &&
              selectedMarkets.currency
            )
          ) {
            return true;
          }
        }
        return false;
      } else return true;
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={props.copyContractOpen}
        TransitionComponent={Transition}
        keepMounted
        onBackdropClick="false"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <IntlMessages id="copy.contract" />
        </DialogTitle>
        <DialogContent>
          <Card variant="outlined">
            <CardHeader
              title={intl.formatMessage({ id: "contract.informations" })}
            />
            <CardContent>
              <Grid container xs={12}>
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        value={
                          selectedSeason.season_id
                            ? selectedSeason.season_code
                            : null
                        }
                        options={Object.values(seasons).filter((season) =>
                          moment(season.end_date).isAfter(
                            moment(today).subtract(1, "days")
                          )
                        )}
                        onChange={(event, value) => {
                          setSelectedSeason({
                            ...selectedSeason,
                            season_id: value?.id,
                            season_code: value ? value : "",
                            start_date: moment(value?.start_date).format(
                              "YYYY-MM-DD"
                            ),
                            end_date: moment(value?.end_date).format(
                              "YYYY-MM-DD"
                            ),
                          });
                          if (selectedPeriod.length > 0) {
                            setSelectedPeriod([]);
                          }
                        }}
                        disabled={checked.period}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={intl.formatMessage({
                              id: "season.code",
                            })}
                            label={intl.formatMessage({ id: "season.code" })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="start_date"
                          format="dd/MM/yyyy"
                          autoOk="true"
                          onChange={(value) => {
                            handleChangeDate(
                              "start_date",
                              moment(value).format("YYYY-MM-DD")
                            );
                          }}
                          disabled={checked.period}
                          value={
                            selectedSeason.start_date
                              ? moment(selectedSeason.start_date).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          label={intl.formatMessage({ id: "start.date" })}
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      disabled={checked.period}
                      className={classes.formControl}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="end_date"
                          format="dd/MM/yyyy"
                          autoOk="true"
                          minDate={
                            selectedSeason.start_date
                              ? selectedSeason.start_date
                              : ""
                          }
                          disabled={checked.period}
                          value={
                            selectedSeason.end_date
                              ? moment(selectedSeason.end_date).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(value) => {
                            handleChangeDate(
                              "end_date",
                              moment(value).format("YYYY-MM-DD")
                            );
                          }}
                          label={intl.formatMessage({ id: "end.date" })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>

                  {checked.market === false ? (
                    <Grid container xs={12}>
                      <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                          <MultiSelect
                            options={multiDataSetter(
                              "name",
                              "id",
                              Object.values(markets).sort((a, b) =>
                                a.name > b.name ? 1 : -1
                              )
                            )}
                            value={multiDataSetter("name", "id", tempMarkets)}
                            handleChange={(value) => handleSelectMarket(value)}
                            label={"Markets"}
                            labelledBy="Select"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                          <MultiSelect
                            options={multiDataSetter(
                              "name",
                              "id",
                              selectedCountries.sort((a, b) =>
                                a.name > b.name ? 1 : -1
                              )
                            )}
                            value={multiDataSetter(
                              "name",
                              "id",
                              selectedMarkets.countries
                            )}
                            label={"Countries"}
                            handleChange={(e) => handleSelectCountry(e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                          <MultiSelect
                            options={multiDataSetter(
                              "name",
                              "id",
                              countriesOperator().sort((a, b) =>
                                a.name > b.name ? 1 : -1
                              )
                            )}
                            value={multiDataSetter(
                              "name",
                              "id",
                              selectedMarkets.operator
                            )}
                            handleChange={(value) => {
                              handleSelectOperator(value);
                            }}
                            label={"Operators"}
                            labelledBy="Select"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>

                {checked.currency === false ? (
                  <Grid container xs={12}>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          options={Object.values(currencies).sort((a, b) =>
                            a.name > b.name ? 1 : -1
                          )}
                          onChange={(event, value) => {
                            setSelectedMarkets({
                              ...selectedMarkets,
                              currency: value ? value.id : "",
                            });
                          }}
                          getOptionLabel={(option) => option.code}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={intl.formatMessage({
                                id: "currency",
                              })}
                              label={intl.formatMessage({ id: "currency" })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <List className={classes.root}>
                    <Grid container xs={12}>
                      <Grid xs={2}>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="market"
                                checked={checked.market}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "market" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="operators"
                                checked={checked.market}
                                // onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "operators" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="currency"
                                checked={checked.currency}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "currency" })}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid xs={2}>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="boardSup"
                                checked={checked.boardSup}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({
                              id: "board.supplements",
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="offers"
                                checked={checked.offers}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "offers" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="hotelExtras"
                                checked={checked.hotelExtras}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "hotel.extras" })}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid xs={2}>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="specialNotes"
                                checked={checked.specialNotes}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "special.notes" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="paymentCon"
                                checked={checked.paymentCon}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({
                              id: "payment.conditions",
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="restrictions"
                                checked={checked.restrictions}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "restrictions" })}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid xs={2}>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="termsCon"
                                checked={checked.termsCon}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({
                              id: "terms.and.conditions",
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="additionalPaxRates"
                                checked={checked.additionalPaxRates}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({
                              id: "additional.pax.rates",
                            })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="cancellationPol"
                                checked={checked.cancellationPol}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({
                              id: "cancellation.policies",
                            })}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid xs={2}>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="kickback"
                                checked={checked.kickback}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "kickbacks" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="period"
                                checked={checked.period}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "period" })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.rootcard}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="periodPrice"
                                checked={checked.periodPrice}
                                onClick={handleToggle}
                              />
                            }
                            label={intl.formatMessage({ id: "price" })}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid xs={2}>
                        <Grid item xs={3}>
                          <FormControl className={classes.formControl}>
                            <MemoizedContractCopyPeriod
                              selectedSeason={selectedSeason}
                              selectedPeriod={selectedPeriod}
                              checkedPeriod={checked?.period}
                              setSelectedPeriod={setSelectedPeriod}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DialogActions>
            <ThemeProvider theme={themeCancel}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                <IntlMessages id="cancel" />
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => copyContract()}
                disabled={dataCheck()}
                startIcon={<CopyAllIcon />}
              >
                <IntlMessages id="copy.contract" />
              </Button>
            </ThemeProvider>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default React.memo(CopyContract);
