import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { deleteOfferList } from "redux/actions/offerList";
import axios from "./axios";
import axiosBot from "./axiosBot";
import { apihost } from "./axiosBot";

export const deleteOfferListSH = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/sunhotel/", datas)
    .then(function(res) {
      res.data["SH"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListWEBBEDS = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/webbeds/", datas)
    .then(function(res) {
      res.data["WEBBEDS"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListJUMBO = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/jumbo/", datas)
    .then(function(res) {
      res.data["JUMBO"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListPP = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/peakpoint/", datas)
    .then(function(res) {
      res.data["PP"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListW2M = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/world2meet/", datas)
    .then(function(res) {
      res.data["W2M"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListPT = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/primetravel/", datas)
    .then(function(res) {
      res.data["PT"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListDN = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/dnata/", datas)
    .then(function(res) {
      res.data["DN"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListWB = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/welcomebeds/", datas)
    .then(function(res) {
      res.data["WB"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListLMX = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/lmx/", datas)
    .then(function(res) {
      res.data["LMX"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};
export const deleteOfferListFOR = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/foryou/", datas)
    .then(function(res) {
      res.data["FORYOU"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListSIT = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/sitalia/", datas)
    .then(function(res) {
      res.data["SIT"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListLMT = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/lastminute/", datas)
    .then(function(res) {
      res.data["LMT"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListXPUR = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/xpur/", datas)
    .then(function(res) {
      res.data["XPUR"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

export const deleteOfferListIVC = (data, operators, pId) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  let datas = {};
  datas["endpoint"] = apihost;
  datas["allOperators"] = operators;
  datas["data"] = data;
  datas["pId"] = pId;
  return axiosBot
    .post("offerdelete/ivector/", datas)
    .then(function(res) {
      res.data["IVC"] = res.data.hotel_offers;
      dispatch(deleteOfferList(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

