import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getOpenSale, addOpenSale } from "redux/actions/openSale";
import axios from "./axios";
import Swal from "sweetalert2";
import axiosBot from "./axiosBot";
import axiosLogger from "./axiosLogger";
import { apihost } from "./axiosBot";

/** PROXOLAB LOGGER * */
import sendLogService from "./proxolabLogger/Logger.js";
import { GET_OPEN_SALE } from "redux/actions/types";
/** PROXOLAB LOGGER * */

export const getOpenSaleService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("opensale", {
      params: {
        hotelId: id
      }
    })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addOpenSaleService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("opensale", data)
    .then(res => {
      dispatch(addOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const getOpenSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/lastOpenSales`,
    data: data
  })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const getAllOpenSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allOpenSales`,
    data: data
  })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const sendOpenSaleServiceSH = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });

  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/SH", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Sun Hotel. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceLMX = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/LMX", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for LMX. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceDN = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });

  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/DN", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for DNata. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServicePP = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/PP", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
        if (
          response.data.hasOwnProperty("message") &&
          response.data.message != ""
        ) {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: response.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Peakpoint. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendOpenSaleServiceW2M = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/W2M", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
        if (
          response.data.hasOwnProperty("message") &&
          response.data.message != ""
        ) {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: response.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for World 2 Meet. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendOpenSaleServicePT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/PT", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
        if (
          response.data.hasOwnProperty("message") &&
          response.data.message != ""
        ) {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: response.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for World 2 Meet. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceWB = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/WB", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Welcomebeds. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceFOR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/FOR", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for Foryou. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceSIT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/SIT", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Sitelia. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceWEBBEDS = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/WEBBEDS", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Sitelia. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceJUMBO = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/JUMBO", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for JUMBO. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendOpenSaleServiceLMT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/LMT", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for LMT. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendOpenSaleServiceXPUR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/XPUR", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for XPUR. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendOpenSaleServiceIVC = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("opensales/IVC", data)
    .then(function(response) {
      dispatch(
        getOpenSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for IVC. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
const logDataForState = data => {
  let temp_operators = [];
  let temp_rooms = [];
  Object.keys(data.operators).forEach(operator => {
    data.operators[operator]["operators"].status = 200;
    temp_operators.push(data.operators[operator]["operators"]);
    data.operators[operator]["rooms"].map(room => {
      if (!temp_rooms.find(troom => troom.id == room.id)) temp_rooms.push(room);
    });
  });
  const temp = {
    ...temp,
    dates: data["dates"],
    //allocation: data["allocation"],
    rooms: temp_rooms,
    hotelId: data["hotelId"],
    operators: temp_operators
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date()
  };
  return newData;
};
