import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MaterialTable, { MTableAction } from "material-table";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Loader from "./Loader";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import _ from "lodash";
import { getBoardsService } from "services/boards";
import {
  addMapBoardDataService,
  deleteMapBoardDataService,
  getMapBoardDataService,
} from "services/boardMap";

const useStyles = makeStyles((theme) => ({
  root: {
    color: localStorage.getItem("theme-type") === "dark" ? "#000" : "#fff",
    maxWidth: "100%",
    marginLeft: theme.spacing(15),
    marginRight: theme.spacing(15),
    fontSize: "12px!important",
  },
  gridStyle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "18%",
    maxWidth: "18%",
  },
  saveButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: "12px",
  },
  unmapButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#f44336",
    color: "#fff",
  },
  dataGridStyle: {
    marginLeft: theme.spacing(12),
  },
}));

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

export default function BoardMap() {
  const tableRef = React.useRef();
  let rightRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBoardsService());
    dispatch(getMapBoardDataService());
  }, [dispatch]);

  const [selectionBoard, setSelectionBoard] = useState();
  const [targetKeys, setTargetKeys] = useState([]);
  const { boards } = useSelector(({ boards }) => boards);
  const { mappedBoards } = useSelector(({ mappedBoards }) => mappedBoards);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.post === 1
  );

  const [columnsRight, setColumnsRight] = useState([
    {
      title: <IntlMessages id="global.boards" />,
      field: "name",
      cellStyle: {
        fontSize: "15",
      },
    },
  ]);

  useEffect(() => {
    mappedBoards.map(
      (board) => (board["board_name"] = boards[board.board_id]?.name)
    );
  }, [mappedBoards]);

  const columns = [
    {
      title: <IntlMessages id="board.name" />,
      field: "name",
      align: "left",
      type: "string",
    },
    {
      title: <IntlMessages id="global.board.name" />,
      field: "board_name",
      type: "string",
      align: "left",
    },
  ];

  const handleChangeBoard = (event) => {
    let ids = [];
    event.map((e) => {
      ids.push(e.id);
    });
    setTargetKeys({ ...targetKeys, ids });
  };

  function boardMapFunc() {
    if (
      selectionBoard &&
      targetKeys.hasOwnProperty("ids") &&
      targetKeys.ids.length > 0
    ) {
      if (tableRef.current) {
        tableRef.current.onAllSelected(false);
      }
      dispatch(
        addMapBoardDataService({
          mapBoards: targetKeys.ids,
          globalBoard: selectionBoard,
        })
      ).then((res) => {
        if (res === 201) {
          sweetAlerts("success", "Board Mapped!");
          rightRef.current.onAllSelected(false);
        }
      });
    } else {
      sweetAlerts("warning", "Please select Global Board and Target Board");
    }
  }

  return (
    <>
      <Card className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.dataGridStyle}>
            <MaterialTable
              columns={columns}
              tableRef={tableRef}
              data={mappedBoards}
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                selection: true,
                addRowPosition: "first",
              }}
              onSelectionChange={(change) => {
                handleChangeBoard(change);
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.dataGridStyle}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                addRowPosition: "first",
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => ({
                  disabled: selectionBoard
                    ? selectionBoard != rowData.id
                    : false,
                }),
              }}
              tableRef={rightRef}
              localization={{
                header: {
                  actions: "",
                },
              }}
              onSelectionChange={(rows) => {
                if (rows) {
                  setSelectionBoard(rows[0]?.id);
                }
              }}
              data={Object.values(boards)}
              columns={columnsRight}
              components={{
                OverlayLoading: () => <Loader />,
                Action: (props) => {
                  return <MTableAction {...props} />;
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButtonStyle}
              disabled={isAuthAdd ? false : true}
              onClick={() => {
                boardMapFunc();
              }}
            >
              <IntlMessages id="SAVE" />
            </Button>
          </ThemeProvider>
          <ThemeProvider>
            <Button
              variant="contained"
              color="error"
              className={classes.unmapButtonStyle}
              disabled={
                targetKeys.hasOwnProperty("ids") && targetKeys.ids.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(deleteMapBoardDataService(targetKeys.ids)).then(
                  (res) => {
                    if (res === 201) {
                      sweetAlerts("success", "Board Deleted Map!");
                      setTargetKeys([]);
                      tableRef.current.onAllSelected(false);
                    }
                  }
                );
              }}
            >
              <IntlMessages id="UNMAP" />
            </Button>
          </ThemeProvider>
        </Grid>
      </Card>
    </>
  );
}
