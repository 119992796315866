import {
  GET_REGIONS,
  GET_COUNTRIES,
  GET_DIVISIONS,
  GET_CITIES,
  GET_SUB_CITIES,
  RESET_CITIES,
  RESET_COUNTRIES,
  RESET_DIVISIONS,
  RESET_SUB_CITIES,
  GET_ALL_COUNTRIES,
} from '../actions/types';

const initialState = {
  regions: [],
  countries: [],
  allCountries: [],
  cities: [],
  subCities: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGIONS:
      return { ...state, regions: action.payload };
    case GET_COUNTRIES:
      return { ...state, countries: action.payload };
    case GET_ALL_COUNTRIES:
      return {...state, allCountries: action.payload};
    case GET_DIVISIONS:
      return { ...state, divisions: action.payload };
    case GET_CITIES:
      return { ...state, cities: action.payload };
    case GET_SUB_CITIES:
      return { ...state, subCities: action.payload };
    case RESET_COUNTRIES:
      return { ...state, countries: [], cities: [], subCities: [] };
    case RESET_DIVISIONS:
      return { ...state, divisions: [], cities: [], subCities: [] };
    case RESET_CITIES:
      return { ...state, cities: [], subCities: [] };
    case RESET_SUB_CITIES:
      return { ...state, subCities: [] };
    default:
      return state;
  }
};
