import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import {
  getRoom,
  addRoom,
  addRooms,
  updateRoom,
  deleteRoom,
  addExcelRoom,
  getExcelRoom
} from "../redux/actions/rooms";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
export const getRoomService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("rooms")
    .then(res => {
      dispatch(getRoom(res.data));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("rooms", data)
    .then(res => {
      dispatch(addRoom(res.data));
      dispatch(fetchSuccess());
      sendLogService.sendRoomLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("rooms", data)
    .then(res => {
      dispatch(updateRoom(res.data));
      sweetAlerts("success", "Room updated successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendRoomLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteRoomService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`rooms/${id}`)
    .then(res => {
      dispatch(deleteRoom(res.data));
      sweetAlerts("success", "Room deleted successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendRoomLog(res, res.data);
      return res.data.code;
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const addAllExcelRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("allExcelRooms", data)
    .then(res => {
      dispatch(addRooms(res.data));
      // dispatch(addRoom(res.data));
      sweetAlerts("success", "New Room created successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendRoomLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const addExcelRoomService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("excelRooms", data)
    .then(res => {
      dispatch(addRoom(res.data));
      sweetAlerts("success", "New Room created successfully!");
      dispatch(fetchSuccess());
      sendLogService.sendRoomLog(res, data);
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const getExcelRoomService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("excelRooms")
    .then(res => {
      dispatch(getRoom(res.data));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
