import { normalize, schema } from 'normalizr';

//const room = new schema.Entity('room', {}, { idAttribute: 'id' });
const operator = new schema.Entity('operator');
const board = new schema.Entity('board');
const contract_room_price = new schema.Entity('contract_room_price');
const contract_allotment_type = new schema.Entity('contract_allotment_type');
const contract_user = new schema.Entity('contract_user');
const contract_season = new schema.Entity('contract_season');
const countries = new schema.Entity('countries');

const market = new schema.Entity('market', {
  countries: [countries],
});
//const contractHasBoard = new schema.Entity('contractHasBoard');

const contract_room = new schema.Entity(
  'contract_room',
  { contract_room_price: [contract_room_price] },
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const get_operator = new schema.Entity('get_operator', {
  operator: [operator],
});

const contract_status = new schema.Entity('contract_status');
const contract_has_board = new schema.Entity('contract_has_board', {
  board,
});

const contract = new schema.Entity('contracts', {
  contract_room: [contract_room],
  contract_status: [contract_status],
  contract_has_board: [contract_has_board],
  contract_allotment_type: [contract_allotment_type],
  contract_user: [contract_user],
  contract_season: [contract_season],
  market: [market],
});

const updatedContract = new schema.Entity(
  'contracts',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);
const updateContractStatus = new schema.Entity(
  'contract_status',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return {
        ...entityA,
      };
    },
  },
);

const response = {
  contract: [contract],
  contract_status: [contract_status],
  contract_has_board: [contract_has_board],
  contract_room: [contract_room],
  contract_allotment_type: [contract_allotment_type],
  contract_season: [contract_season],
  addMarket: [market],
  addCountries: [countries],
  addContract: [contract],
  addContract_status: [contract_status],
  addContract_has_board: [contract_has_board],
  addBoard: [board],
  addContract_allotment_type: [contract_allotment_type],
  addContract_user: [contract_user],
  updatedContract: [updatedContract],
  updateContractStatus: [updateContractStatus],
  updateContractRooms: [contract_room],
};
export const addUpdateNormalize = data => normalize(data, [response]);
