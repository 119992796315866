import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import {
  getProductTypesService,
  addProductTypesService,
  updateProductTypesService,
  deleteProductTypesService,
} from "services/productTypes";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);

export default function ProductType({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (productTypesState.length === 0) {
      dispatch(getProductTypesService());
    }
  }, [dispatch]);
  const hotelState = useSelector((state) => state.hotels.hotels);
  const productTypesState = useSelector(
    (state) => state.productTypes.productTypes
  );
  const [productTypes, setProductTypes] = useState(
    productTypesState !== null ? productTypesState : []
  );
  useEffect(() => {
    setProductTypes(productTypesState);
  }, [productTypesState]);

  const intl = useIntl();

  const tableRef = React.createRef();
  const tableColumns = [
    {
      title: intl.formatMessage({ id: "code" }),
      field: "code",
    },
    {
      title: intl.formatMessage({ id: "name" }),
      field: "name",
    },
  ];

  const hasProduct = (product_type_id) => {
    //To check if the product type is in the use in hotels
    var has = true;

    Object.values(hotelState).forEach((hotel) => {
      if (hotel.product_type.id === product_type_id) {
        has = false;
      }
    });
    return has;
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={tableColumns}
      data={productTypes}
      title=""
      options={{
        // tableLayout: 'fixed',
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: "first",
        headerStyle: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: intl.formatMessage({ id: "you.are.not.authorized" }),
            },
      ]}
      icons={{
        Add: (props) => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: (props) => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={(e) => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({
                          id: "are.you.sure?",
                        }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),

                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({
                          id: "yes",
                        }),
                        cancelButtonText: intl.formatMessage({
                          id: "no",
                        }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({
                          id: "are.you.sure?",
                        }),

                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({
                          id: "yes",
                        }),
                        cancelButtonText: intl.formatMessage({
                          id: "no",
                        }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
      }}
      editable={{
        deleteTooltip: (row) =>
          isAuthDelete ? (
            "Delete"
          ) : (
            <IntlMessages id="you.are.not.authorized" />
          ),
        editTooltip: (row) =>
          isAuthUpdate ? (
            "Update"
          ) : (
            <IntlMessages id="you.are.not.authorized" />
          ),
        isDeletable: (row) => (isAuthDelete ? true : false),
        isEditable: (row) => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  if (newData.code && newData.name) {
                    if (
                      productTypes.filter(
                        (productT) =>
                          productT.code === newData.code.toUpperCase()
                      ).length === 0 &&
                      productTypes.filter(
                        (productT) =>
                          productT.name === newData.name.toUpperCase()
                      ).length === 0
                    ) {
                      newData.code = newData.code.toUpperCase();
                      newData.name = newData.name.toUpperCase();
                      dispatch(addProductTypesService(newData));

                      resolve();
                    } else {
                      /*    MySwal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      target: (
                        <>
                          <IntlMessages id="product.type" /> <IntlMessages id="already.exists" />
                        </>
                      ),
                    }); */
                      MySwal.fire(
                        "Oops...",
                        intl.formatMessage({
                          id: "product.type.already.exists",
                        }),
                        "error"
                      );

                      resolve();
                    }
                  } else {
                    //  MySwal.fire('Oops...', <IntlMessages id="please.fill.in.all.fields" />, 'error');
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({ id: "please.fill.in.all.fields" }),
                      "error"
                    );

                    resolve();
                  }
                }, 1000);
              })
          : undefined,

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (newData.code && newData.name) {
                const otherProductTypes = productTypes.filter(
                  (productT) =>
                    productT.code !== oldData.code &&
                    productT.name !== oldData.name
                );
                newData.name = newData.name.toUpperCase();
                newData.code = newData.code.toUpperCase();
                if (
                  otherProductTypes.filter(
                    (productT) => productT.code === newData.code
                  ).length === 0 &&
                  otherProductTypes.filter(
                    (productT) => productT.name === newData.name
                  ).length === 0
                ) {
                  dispatch(updateProductTypesService(newData));

                  resolve();
                } else {
                  /*  MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    target: (
                      <>
                        <IntlMessages id="product.type" /> <IntlMessages id="already.exists" />
                      </>
                    ),
                  }); */
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "product.type.already.exists" }),
                    "error"
                  );

                  resolve();
                }
              } else {
                //MySwal.fire('Oops...', <IntlMessages id="please.fill.in.all.fields" />, 'error');
                MySwal.fire(
                  "Oops...",
                  intl.formatMessage({ id: "please.fill.in.all.fields" }),
                  "error"
                );

                resolve();
              }
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (hasProduct(oldData.id)) {
                dispatch(deleteProductTypesService(oldData.id));
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "product.type.in.use",
                  }),
                });
              }
              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
