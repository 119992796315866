import React, { useEffect, useState, useRef } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { Container, Row } from "react-grid-system";
import { Grid, CardContent } from "@material-ui/core";
import CmtCard from "@coremat/CmtCard";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, Space } from "antd";
import { getHotelService } from "services/hotels";
import { getContractService } from "services/contract";
import { getOperatorService } from "services/operator";
import { getMarketService } from "services/market";
import { getBoardsService } from "services/boards";
import { getReservationsService } from "services/reservation";
import { getCurrenciesService } from "services/currency";
import { getRoomService } from "services/rooms";
import MaterialTable from "material-table";
import moment from "moment";
import { useIntl } from "react-intl";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { CSVLink, CSVDownload } from "react-csv";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import { TablePagination } from "@material-ui/core";
import axios from "../../../services/axios";
import {
  getPaginedOperatorReservationService,
  reservationOperatorMultiFilterService,
} from "services/reservationList";
import { read, utils, writeFile, writeFileXLSX } from "xlsx";
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];
const useStyles = makeStyles((theme) => ({
  selectBoxRoot: {
    marginBottom: 20,
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400],
    },
  },
  button: {
    height: "40px !important",
  },
}));

const CustomTablePagination = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ContractReport() {
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [bookingStatus, setBookingStatus] = useState([
    { name: "NEW", id: 1 },
    { name: "UPDATED", id: 2 },
    { name: "CANCELLED", id: 3 },
    { name: "NO SHOW", id: 4 },
  ]);
  const [communicationStatus, setCommunicationStatus] = useState([
    { name: "SENT", id: 1 },
    { name: "PENDING SENDING", id: 2 },
  ]);
  const [hotelStatus, setHotelStatus] = useState([
    // { name: "Not Send", id: 1 },
    // { name: "Waiting", id: 2 },
    { name: "Deleted", id: 3 },
    { name: "Confirmed", id: 4 },
  ]);
  const [tableState, setTableState] = useState(1);
  const [filterState, setFilterState] = useState({
    hotel_name: "",
    operator: "",
    voucher_no: "",
    reservation_status: "",
    user_id: "",
    selling_date: "",
    start_date: "",
    end_date: "",
  });
  const [changeExcelDownloadStatus, setChangeExcelDownloadStatus] = useState(
    true
  );
  useEffect(async () => {
    await dispatch(
      getContractService(
        authUser.authority_level,
        localStorage.getItem("selectedCompany")
      )
    );
    await dispatch(getBoardsService());
    await dispatch(getHotelService());
    await dispatch(getOperatorService());
    await dispatch(getMarketService());
    await dispatch(getReservationsService());
    await dispatch(getCurrenciesService());
    await dispatch(getRoomService());
  }, [dispatch]);

  const dispatch = useDispatch();
  let dataController;
  const changeTableDataToFilter = () => {
    dataController = Object.values(filterState).every(
      (stuation) => stuation == ""
    );
    dataController == true
      ? setTableState(Number(dataController))
      : setTableState(tableState + 3);
  };
  const { contracts } = useSelector(({ contract }) => contract);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operators } = useSelector(({ operators }) => operators);
  const { markets } = useSelector(({ markets }) => markets);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { boards } = useSelector(({ boards }) => boards);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { contract_room } = useSelector(({ contract }) => contract);

  const {
    rooms: { roomType },
  } = useSelector(({ rooms }) => rooms);
  /*   const reservations = useSelector(
      state => state.reservation?.entities?.reservations
    ); */

  const [reservationData, setReserationData] = useState([]);
  const reservation_rooms = useSelector(
    (state) => state.reservation?.entities?.rooms
  );

  /*   useEffect(() => {
      const waitforData = async () => {
        const res = await getPaginedOperatorReservationService(12, 0);
        count.current = await res.data.count;
        await setReserationData(res.data.data);
      };
      waitforData();
    }, []); */

  const [
    filteredPaginedReservationData,
    setFilteredPaginedReservationData,
  ] = useState([]);
  const filterHandleChange = (e) => {
    const { name, value } = e;
    setFilterState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [count, setCount] = useState(0);
  useEffect(() => {
    const waitforData = async () => {
      let res = await reservationOperatorMultiFilterService(filterState, 12, 0);
      setCount(res.data.count);
      setFilteredPaginedReservationData(res.data.data);
    };
    waitforData();
  }, [tableState]);

  const reservationMultifilter = () => {
    changeTableDataToFilter();
  };

  /*  const filterSelected = () => {
     setChangeExcelDownloadStatus(false);
     if (filteredReservationData !== undefined || filteredReservationData !== null) {
       let allReservations = filteredReservationData?.map(reservation => {
         let resHotel = hotels[filteredReservationData.hotel_id];
         console.log(resHotel)
         let newReservation = {
 
           ...reservation,
           hotelStatus:
             reservation.hotel_status === 1
               ? "NOT SENT"
               : reservation.hotel_status === 2
                 ? "WAITING"
                 : reservation.hotel_status === 3
                   ? "CANCELLED"
                   : "CONFIRMED",
           bookingStatus:
             reservation.booking_status === 1
               ? "NEW"
               : reservation.booking_status === 2
                 ? "UPDATED"
                 : reservation.booking_status === 3
                   ? "CANCELLED"
                   : "NO SHOW",
           communicationStatus:
             reservation.communication_status === 1 ? "SENT" : "PENDING SENDING",
           operator_name: operators[reservation.operator_id]?.code,
           tourOpNo: "",
           voucher_no: reservation.voucher_no,
           lead_name: reservation.lead_name,
           product_name: resHotel?.name,
           checkInDate: reservation.start_date,
           checkOutDate: reservation.end_date,
           selling_date: reservation.selling_date,
           noOfNights:
             (Date.parse(reservation.end_date) -
               Date.parse(reservation.start_date)) /
             (1000 * 60 * 60 * 24),
           room_type: (function () {
             let room_string = "";
             reservation.rooms.forEach(room => {
               room_string +=
                 roomType[
                   contract_room[reservation_rooms[room]?.contract_room_id]
                     ?.room_id
                 ]?.code + " ";
             });
             return room_string;
           })(),
           board_name:
             boards[contract_has_board[reservation.board_id]?.board_id]?.code,
           adult_num: reservation.adult_num,
           child_num: reservation.child_num,
           infant_num: reservation.infant_num,
           amount: "",
           currency_name:
             currencies[contracts[reservation.contract_id]?.currency]?.code,
           contract_name: contracts[reservation.contract_id]?.contract_name,
           market_name: (function () {
             let market_string = "";
             if (reservation.contract_id) {
               contracts[reservation.contract_id].market.forEach(market => {
                 market_string += markets[market]?.code + " ";
               });
             } else {
               market_string = "0";
             }
 
             return market_string;
           })()
         };
         return newReservation;
       });
       Object.keys(filterState).forEach(filterKey => {
         if (filterState[filterKey] !== "") {
           if (
             filterKey !== "start_date" &&
             filterKey !== "end_date" &&
             filterKey !== "selling_date"
           ) {
             allReservations = allReservations.filter(
               res => res[filterKey] == filterState[filterKey]
             );
           } else {
             allReservations = allReservations.filter(res =>
               moment(res[filterKey]).isBetween(
                 filterState[filterKey].from,
                 filterState[filterKey].to,
                 undefined,
                 "[]"
               )
             );
           }
         }
       });
       setReserationData(allReservations);
     }
   }; */

  const handleChangeStart = (name, value) => {
    if (name == null && value[0] == "") {
      setFilterState({
        ...filterState,
        start_date: {
          from: "",
          to: "",
        },
      });
    } else {
      setFilterState({
        ...filterState,
        start_date: {
          from: moment(value[0], "DD/MM/YYYY", false).format("YYYY-MM-DD"),
          to: moment(value[1], "DD/MM/YYYY", false).format("YYYY-MM-DD"),
        },
      });
    }
  };
  const handleChangeEnd = (name, value) => {
    if (name == null && value[0] == "") {
      setFilterState({
        ...filterState,
        end_date: {
          from: "",
          to: "",
        },
      });
    } else {
      setFilterState({
        ...filterState,
        end_date: {
          from: moment(value[0], "DD/MM/YYYY", false).format("YYYY-MM-DD"),
          to: moment(value[1], "DD/MM/YYYY", false).format("YYYY-MM-DD"),
        },
      });
    }
  };
  const handleChangeSelling = (name, value) => {
    setFilterState({
      ...filterState,
      selling_date: { from: value[0], to: value[1] },
    });
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - reservationData.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    const waitforData = async () => {
      let res = await reservationOperatorMultiFilterService(
        filterState,
        rowsPerPage,
        page
      );
      setCount(res.data.count);
      setFilteredPaginedReservationData(res.data.data);
    };
    waitforData();
  }, [page]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const intl = useIntl();
  const tableColumns = [
    {
      title: intl.formatMessage({ id: "operator.booking.no" }),
      field: "operator_booking_no",
    },
    {
      title: intl.formatMessage({ id: "room.type" }),
      field: "room_type",
    },
    {
      title: intl.formatMessage({ id: "guest.name" }),
      field: "guest_name",
    },
    {
      title: intl.formatMessage({ id: "adult.number" }),
      field: "adult_number",
    },
    {
      title: intl.formatMessage({ id: "child.number" }),
      field: "child_number",
    },
    {
      title: intl.formatMessage({ id: "reservation.status" }),
      field: "reservation_status",
    },
    {
      title: intl.formatMessage({ id: "meal.plan" }),
      field: "meal_plan",
    },
    {
      title: intl.formatMessage({ id: "price" }),
      field: "price",
    },
    {
      title: intl.formatMessage({ id: "hotel.name" }),
      field: "hotel_name",
    },
    {
      title: intl.formatMessage({ id: "operator" }),
      field: "operator",
    },
    {
      title: intl.formatMessage({ id: "agency.booking.no" }),
      field: "agency_booking_no",
    },
    {
      title: intl.formatMessage({ id: "check.in.date" }),
      field: "check_in_date",
      render: (rowData) => moment(rowData.check_in_date).format("DD/MM/YYYY"),
    },
    {
      title: intl.formatMessage({ id: "check.out.date" }),
      field: "check_out_date",
      render: (rowData) => moment(rowData.check_out_date).format("DD/MM/YYYY"),
    },
  ];
  const [info, setInfo] = useState();
  const { RangePicker } = DatePicker;
  const tableRef = useRef();
  const hiddenButton = useRef();
  const downloadCsv = (data, fileName) => {
    const filename = fileName;

    const book = utils.book_new();
    const sheet = utils.aoa_to_sheet(data);
    utils.book_append_sheet(book, sheet, "sheet1");
    writeFile(book, `${filename}.xls`);
  };
  const dateFormat = "DD-MM-YYYY";

  // useEffect(() => {
  //   if (changeExcelDownloadStatus && info !== undefined) {
  //     hiddenButton.current.link.click();
  //     setChangeExcelDownloadStatus(false);
  //   }
  // }, [changeExcelDownloadStatus, info]);
  return (
    <div className={classes.selectBoxRoot}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed">
                <Grid container spacing={10}>
                  <Grid item sm={3}>
                    <IntlMessages id="check.in.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeStart}
                        disabledDate={(current) => {
                          return (
                            current >
                            moment(
                              filterState.end_date.from,
                              "YYYY-MM-DD",
                              false
                            )
                          );
                        }}
                      />
                    </Space>
                  </Grid>
                  <Grid item sm={3}>
                    <IntlMessages id="check.out.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeEnd}
                        disabledDate={(current) => {
                          // disable before date from filter state start_date to
                          return (
                            current &&
                            current <
                              moment(
                                filterState.start_date.to,
                                "YYYY-MM-DD",
                                false
                              ).add(1, "days")
                          );
                        }}
                      />
                    </Space>
                  </Grid>
                  {/*   <Grid item sm={3}>
                    <IntlMessages id="selling.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeSelling}
                      />
                    </Space>
                  </Grid> */}
                  <Grid item sm={2}>
                    <TextField
                      id="outlined-basic"
                      name="voucher_no"
                      label={intl.formatMessage({ id: "booking.no" })}
                      variant="standard"
                      onChange={(e, value) => {
                        let event = {
                          name: "voucher_no",
                          value: e.target.value,
                        };
                        filterHandleChange(event);
                      }}
                    />
                  </Grid>
                  <Grid style={{ padding: "0px 15px" }} container spacing={10}>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(operators)}
                        onChange={(e, value) => {
                          let event = {
                            name: "operator",
                            value: value != null ? value.code : "",
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.operator",
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      {/*  <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: "hotel_id",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.product"
                            })}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      /> */}

                      <TextField
                        className={classes.textStyle}
                        id="select.product"
                        type="text"
                        placeholder="Hotel Name"
                        onChange={(e) => {
                          filterState.hotel_name = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={hotelStatus}
                        onChange={(e, value) => {
                          let event = {
                            name: "reservation_status",
                            value: value != null ? value.name : "",
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.confirm.status",
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'opBookNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.code}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Opeator Booking No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotelConfirmeNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Hotel Confirmation No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid> */}
                    <Grid item sm={3}>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={() => reservationMultifilter()}
                        style={{ backgroundColor: "gray", color: "white" }}
                      >
                        <IntlMessages id="filter" />
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item sm={12}>
                    <MaterialTable
                      tableRef={tableRef}
                      columns={tableColumns}
                      data={filteredPaginedReservationData}
                      title=""
                      options={{
                        pageSize: 12,
                        paging: true,
                        pageSizeOptions: [12, 20, 30, 40],
                        actionsColumnIndex: -1,
                        tableLayout: "auto",
                        search: false,
                        addRowPosition: "first",
                        editCellStyle: "10px",
                        exportButton: {
                          csv: true,
                          pdf: false,
                        },
                        exportDelimiter: ",",
                        exportFileName: "Reservation List",
                        exportAllData: true,

                        exportCsv: (columns, data) => {
                          const headerRow = columns.map((col) => {
                            if (typeof col.title === "object") {
                              return col.title.props.text;
                            }
                            return col.title;
                          });
                          // for displaying default title of columns even if there is no data
                          let excelData = [
                            {
                              "Operator Booking No": "",
                              "Room Type": "",
                              "Guest Name": "",
                              "Adult Number": "",
                              "Child Number": "",
                              "Reservation Status": "",
                              "Meal Plan": "",
                              Price: "",
                              "Hotel Name": "",
                              Operator: "",
                              "Agency Booking No": "",
                              "Check In Date": "",
                              "Check Out Date": "",
                            },
                          ];
                          data = [...excelData, ...data];

                          const dataRows = data.map(({ tableData, ...row }) => {
                            return {
                              [headerRow[0]]: row.operator_booking_no ?? "",
                              [headerRow[1]]: row.room_type ?? "",
                              [headerRow[2]]: row.guest_name ?? "",
                              [headerRow[3]]: row.adult_number ?? "",
                              [headerRow[4]]: row.child_number ?? "",
                              [headerRow[5]]: row.reservation_status ?? "",
                              [headerRow[6]]: row.meal_plan ?? "",
                              [headerRow[7]]: row.price ?? "",
                              [headerRow[8]]: row.hotel_name ?? "",
                              [headerRow[9]]: row.operator ?? "",
                              [headerRow[10]]: row.agency_booking_no ?? "",
                              [headerRow[11]]:
                                moment(row.check_in_date).format(
                                  "DD/MM/YYYY"
                                ) ?? "",
                              [headerRow[12]]:
                                moment(row.check_out_date).format(
                                  "DD/MM/YYYY"
                                ) ?? "",
                            };
                          });

                          const worksheet = utils.json_to_sheet(dataRows);

                          const newWorkBook = utils.book_new();
                          utils.book_append_sheet(
                            newWorkBook,
                            worksheet,
                            "SheetJS"
                          );
                          writeFile(newWorkBook, "Reservation List.xlsx");
                        },
                      }}
                      components={{
                        Pagination: (props) => (
                          <>
                            <TablePagination
                              style={{
                                display: "flex",
                                justifyContent: "right",
                              }}
                              rowsPerPageOptions={[12, 15, 20]}
                              colSpan={3}
                              count={count}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={CustomTablePagination}
                            />
                          </>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
    </div>
  );
}
