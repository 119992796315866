import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import Autocomplete from "@mui/material/Autocomplete";
import AppTextInput from "../../Common/formElements/AppTextInput";
import GridContainer from "../../GridContainer";
// import AppSelectBox from "../../../../@jumbo/components/Common/formElements/AppSelectBox";
import { addAdminUserService } from "services/user";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import SaveIcon from "@mui/icons-material/Save";
import {
  // authUserMemo,
  companyData,
  // officeMemo,
  // get_destinations,
} from "redux/selector/companyHasOfficeSelector";
// import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { isTurkish } from "@jumbo/utils/isTurkish";

// const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  option: {
    fontSize: 15,
    /* '& > span': {
      marginRight: 10,
      fontSize: 18,
    }, */
  },
  dark: {
    "& .MuiChip-label": {
      color: "white",
    },
  },
  light: {
    "& .MuiChip-label": {
      color: "#000", // black
    },
  },
}));

export default function AddUserDialog({ open, setOpen }) {
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });

  const dispatch = useDispatch();
  // const themeMode = localStorage.getItem("theme-type");
  const { error_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );
  const { success_message } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );

  const newUserAlert = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "user.successfully.added" }),
      text: intl.formatMessage({ id: "do.you.want.to.add.new.user" }),
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        setUser((prevState) => ({
          ...prevState,
          ...{ fullname: "", email: "" },
        }));
      } else {
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    if (
      user.fullname &&
      user.email &&
      // password &&
      // passwordConfirm &&
      error_message === "" &&
      success_message === "success"
    ) {
      newUserAlert();
    } else {
      if (error_message.email) {
        setErrorData({ ...errorData, email: error_message.email[0] });
      }
    }
  }, [error_message, success_message]);

  /* 
  useEffect(() => {
    if (
      fullname &&
      authorityLevel &&
      errorOffice === '' &&
      email &&
      password &&
      passwordConfirm &&
      success_message === 'success'
    ) {
      newUserAlert();
    }
  }, [success_message]); */

  // const officesState = useSelector(officeMemo);
  // const destinationData = useSelector(get_destinations);
  // const companyOrEnterprise = useSelector(authUserMemo);
  const companies = useSelector(companyData);

  const classes = useStyles();
  const intl = useIntl();
  const [user, setUser] = useState({
    fullname: "",
    email: "",
  });

  const [errorData, setErrorData] = useState({
    fullname: "",
    email: "",
  });
  // const [password, setPassword] = useState('');
  // const [passwordConfirm, setPasswordConfirm] = useState('');

  const [passControl, setPassControl] = useState("");
  const isValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onSubmitClick = () => {
    const authorityLevel = 20;
    const companyId = Object.values(companies)[0].id;
    const userData = {
      ...user,
      // password,
      // passwordConfirm,
      authorityLevel,
      companyId,
    };
    let errorDatas = {};
    if (userData.fullname === "") {
      errorDatas.fullname = requiredMessage;
    }
    if (userData.email === "") {
      errorDatas.email = requiredMessage;
    }
    if (
      userData.email !== "" &&
      isValidEmailRegex.test(userData.email) === false
    ) {
      errorDatas.email = intl.formatMessage({ id: "user.invalid.email" });
    }
    setErrorData(errorDatas);
    if (
      userData.fullname &&
      userData.email &&
      authorityLevel &&
      companyId &&
      isValidEmailRegex.test(userData.email) === true

      //  && password &&
      //   passwordConfirm &&
      //   password === passwordConfirm
    ) {
      dispatch(addAdminUserService(userData));
      setErrorData({ ...errorData, email: "" });
    }
  };

  const onCancelClick = () => {
    setOpen(false);
  };

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
    setErrorData({ ...errorData, [name]: "" });
  };
  const isTrueEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-])+([.])+[a-zA-Z0-9.-]{2,4}$/.test(
      email
    );
  };

  // const isTurkishName = (name) => {
  //   return /^[A-Za-z\s]+$/.test(name);
  // };
  return (
    <Dialog
      open={open}
      className={classes.dialogRoot}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <IntlMessages id="create.new.user" />
      </DialogTitle>
      <DialogContent dividers>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              label={<IntlMessages id="name" />}
              value={user.fullname}
              required
              helperText={errorData.fullname}
              onChange={(e) => {
                // if (e.target.value.trim().length != 0) {
                handleChange("fullname")(e);
                // }
              }}
            />
          </Grid>
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              label={<IntlMessages id="email" />}
              value={user.email}
              required
              error={
                user.email !== ""
                  ? isTrueEmail(user.email)
                    ? false
                    : true
                  : true
              }
              helperText={
                user.email !== ""
                  ? isTrueEmail(user.email)
                    ? errorData.email
                    : "Please enter a valid email with English characters"
                  : errorData.email
              }
              autoComplete="new-password"
              onChange={(e) => {
                // if (e.target.value.trim().length != 0) {
                handleChange("email")(e);
                // }
              }}
            />
          </Grid>
        </GridContainer>
        {/* 
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="outlined"
              label="Password"
              value={password}
              type="password"
              autoComplete="new-password"
              onChange={e => {
                setPassword(e.target.value);
              }}
              required
              helperText={passControl}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              label="Password Confirmation"
              type="password"
              valueKey="slug"
              variant="outlined"
              autoComplete="new-password"
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.target.value)}
              required
              helperText={passControl}
            />
          </Grid>
        </GridContainer> */}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <ThemeProvider theme={themeCancel}>
            <Button variant="contained" color="primary" onClick={onCancelClick}>
              <IntlMessages id="cancel" />
            </Button>
          </ThemeProvider>
          <Box ml={2}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                disabled={isTrueEmail(user.email) ? false : true}
                color="primary"
                startIcon={<SaveIcon />}
                onClick={onSubmitClick}
              >
                <IntlMessages id="save" />
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
