import React, { useEffect, useState, useRef } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { makeStyles } from "@material-ui/core/styles";
import {
  addPeriodService,
  deletePeriodService,
  getPeriodService,
  updatePeriodService,
  periodRowStatusService,
} from "services/period";
import { getDaysService } from "services/days";
import { getStatusService } from "services/restrictionStatus";
import { getTypesService } from "services/cincoutTypes";
import { getRoomService } from "services/rooms";
import { getRestrictionsService } from "services/restrictions";
import { getAllotmentActionTypeService } from "services/allotmenActionType";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useIntl } from "react-intl";
import { useWhyDidYouUpdate } from "@jumbo/utils/useWhyDidYouUpdate";
import InputBase from "@material-ui/core/InputBase";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";
import { isNumeric } from "@jumbo/utils/isNumeric";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};
//Seçilen contratın start ve end date'i kontrol edilecek.

export default function Periods(props) {
  const { setisPeriodsNewAndEdit } = props;
  const materialTableRef = useRef();
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const intl = useIntl();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "92%",
      height: "100%",
      margin: "0 auto",
    },
  }));
  const classes = useStyles();
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { contracts } = useSelector(({ contract }) => contract);
  const { periods } = useSelector(({ periods }) => periods);
  const [period, setPeriod] = useState(periods);
  const today = new Date();
  const [season_start_date, setSeason_start_date] = useState(today);
  const [season_end_date, setSeason_end_date] = useState(today);
  const [selectedPeriod, setSelectedPeriod] = useState([]);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const [nextStartDate, setNextStartDate] = useState(new Date());
  const [lastPeriod, setLastPeriod] = useState(0);
  const [isClick, setIsClick] = useState(false);
  const [addClickButton, setAddClickButton] = useState(false);
  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.delete === 1
  );

  const contractStatusControl = contracts[selectedContract]
    ? contracts[selectedContract].contract_statuses_id == 1 ||
      contracts[selectedContract].contract_statuses_id == 5
      ? true
      : false
    : true;

  useWhyDidYouUpdate("Periods", {
    selectedContract,
    contractStatusControl,
    isAuthAdd,
    isAuthUpdate,
    isAuthDelete,
    contracts,
    periods,
    period,
    season_start_date,
    season_end_date,
  });

  useEffect(() => {
    if (selectedContract !== 0) {
      dispatch(getPeriodService(selectedContract));
      dispatch(getRoomService());
    }
    dispatch(getAllotmentActionTypeService());
    dispatch(getRestrictionsService(selectedContract));
    dispatch(getDaysService());
    dispatch(getStatusService());
    dispatch(getTypesService());

    // dispatch(getPeriodService(1));
  }, [selectedContract]);

  useEffect(() => {
    setSeason_start_date(
      contracts
        ? contracts[selectedContract]
          ? contracts[selectedContract].start_date
          : today
        : today
    );
    setSeason_end_date(
      contracts[selectedContract] ? contracts[selectedContract].end_date : today
    );
    setPeriod(periods);
    setLastPeriod(Object.keys(periods).splice(-1)[0]);
  }, [periods]);

  // girilen inputun bir sonraki gününü döndürür
  const tomorrowDate = (my_date) => {
    let tomorrow_date = new Date();
    tomorrow_date = new Date(new Date(my_date).getTime() + 24 * 60 * 60 * 1000);
    tomorrow_date = `${tomorrow_date.getFullYear()}-${`0${tomorrow_date.getMonth() +
      1}`.slice(-2)}-${`0${tomorrow_date.getDate()}`.slice(-2)}`;
    setNextStartDate(tomorrow_date);
    return tomorrow_date;
  };

  useWhyDidYouUpdate("Periods", props);

  //For Invalid Date Control
  const controlInvalidDate = (date) => {
    let invalidFlag = true;
    if (date == "Invalid date" || date == undefined) {
      invalidFlag = false;
    }
    return invalidFlag;
  };

  const controlContractSeasonEndDate = (startDate, seasonEndDate) => {
    let res = true;
    if (startDate > seasonEndDate) {
      res = false;
    }
    return res;
  };

  //If period end date is before start date
  const isThEndDateBeforeTheStartDate = (start_date, end_date) => {
    if (moment(end_date).isBefore(start_date)) {
      return false;
    } else if (
      moment(contracts[selectedContract].end_date).isBefore(end_date)
    ) {
      return false;
    } else return true;
  };

  //If contract start and end date are equal year will be dafault

  //SECTION - Control Dates
  const checkContractYear = () => {
    let selectedYear = "";

    if (
      moment(contracts[selectedContract].start_date).format("YYYY") ===
      moment(contracts[selectedContract].end_date).format("YYYY")
    ) {
      selectedYear = moment(contracts[selectedContract].start_date).format(
        "YYYY"
      );
      return `dd/MM/${selectedYear}`;
    } else {
      return "dd/MM/yyyy";
    }
  };
  const checkContractYear2 = (date, onChange) => {
    let check = moment(date).format("YYYY-MM-DD");
    let selectedYear = "";
    let day = moment(date).format("DD");
    let month = moment(date).format("MM");
    /* NOTE: Eğer tarih invalid değilse contratın başlangıç ve bitiş yılları kontrol ediliyor ve ikisi eşit ise
    biz tarihte yılı farklı bir şey girsek bile onu kontratın yılının aynısı yapılıyor  (önceden öyle istenmiş)
    daha sonra girilen tarihin gününü ve ayını alarak bir yıl değişkeni oluşturuluyor.
    oluşturulan yıl stringi tarih objesine dönüştürülüyor. Tarih objesinde UTC yazma nedeni ise
    biz gün olarak 25'ini seçsek bile tarih farkından dolayı 3 saat geri gidip 24'dünün 21.00 olarak kayıt ediyor. 


    */

    if (check !== "Invalid date") {
      if (
        moment(contracts[selectedContract].start_date).format("YYYY") ===
        moment(contracts[selectedContract].end_date).format("YYYY")
      ) {
        selectedYear = moment(contracts[selectedContract].start_date).format(
          "YYYY"
        );
        let str = `${selectedYear}/${month}/${day}`;
        const myMomentObject = moment(new Date(`${str} UTC`))
          .utc()
          .format("YYYY-MM-DD HH:mm");
        onChange(myMomentObject);
      } else {
        onChange(check);
      }
    }
  };
  //Change start date according to editable end date
  const controlDates = (oldData) => {
    let newId = oldData.tableData.id;
    if (Object.values(periods).at(-1).tableData?.id > newId) {
      let editingRow = Object.values(period).find(
        (item) => item.tableData.id === newId + 1
      );
      editingRow.start_date = oldData.end_date;
      period[editingRow.id].start_date = oldData.end_date;
      setPeriod(period);
      return period[editingRow.id];
    }
    return false;
  };
  //!SECTION

  const onClickAdd = (e) => {
    setisPeriodsNewAndEdit(e);
    setAddClickButton(e);
  };

  const columns = [
    {
      title: <IntlMessages id="period">Period</IntlMessages>,
      field: "code",
      validate: (rowData) => (rowData.code ? true : false),
      editComponent: (props) => {
        props.rowData.code = periods.length == 0 ? "A" : props.value;
        //if rowData hasn't id property it means this row is added not updated

        return (
          <TextField
            id="code"
            autoComplete="off"
            value={periods.length == 0 ? "A" : props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            onBlur={(e) => {
              if (!props.rowData.hasOwnProperty("id")) {
                let a = Object.values(periods).filter(
                  (item) =>
                    item?.id !== props.rowData?.id &&
                    item.code == props.value.toUpperCase()
                );
                if (a && a.length > 0) {
                  props.rowData.release = a[0].release;
                  props.rowData.min_stay = a[0].min_stay;
                } else {
                  props.rowData.release = "";
                  props.rowData.min_stay = "";
                }
              }
            }}
          />
        );
      },
    },
    {
      title: <IntlMessages id="start.date">Start Date</IntlMessages>,
      field: "start_date",
      type: "date",
      aling: "center",
      render: (rowData) => {
        return moment(rowData.start_date).format("DD/MM/YYYY");
      },
      validate: (rowData) =>
        !controlInvalidDate(rowData.start_date) ||
        !isThEndDateBeforeTheStartDate(rowData.start_date, rowData.end_date)
          ? false
          : true,
      editComponent: (props) => (
        (props.rowData.start_date = props.rowData.start_date
          ? props.rowData.start_date
          : Object.values(period).length !== 0
          ? tomorrowDate(
              Object.values(period)[Object.values(period).length - 1]?.end_date
            )
          : season_start_date),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              name="start_date"
              format={checkContractYear()}
              autoOk="true"
              allowKeyboardControl={false}
              value={props.rowData.start_date}
              onChange={(date) =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              minDate={
                Object.values(period).length
                  ? props.rowData?.tableData
                    ? props.rowData?.tableData?.id == 0
                      ? season_start_date
                      : period[props.rowData?.tableData?.id - 1]?.start_date
                    : parseInt(lastPeriod) !== 0
                    ? tomorrowDate(
                        periods[lastPeriod] ? periods[lastPeriod].end_date : ""
                      )
                    : season_start_date
                  : season_start_date
              }
              InputLabelProps={{
                shrink: true,
              }}
              readOnly={true}
            />
          </MuiPickersUtilsProvider>
        )
      ),
    },
    {
      title: <IntlMessages id="end.date">End Date</IntlMessages>,
      field: "end_date",
      type: "date",
      aling: "center",
      render: (rowData) => {
        return moment(rowData.end_date).format("DD/MM/YYYY");
      },
      validate: (rowData) =>
        !controlInvalidDate(rowData.end_date) ||
        !isThEndDateBeforeTheStartDate(rowData.start_date, rowData.end_date)
          ? false
          : true,
      editComponent: (props) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="end_date"
            format={checkContractYear()}
            autoOk="true"
            error={props.value ? false : true}
            disabled={
              controlContractSeasonEndDate(
                props.rowData.start_date,
                season_end_date
              )
                ? false
                : true
            }
            value={moment(props.value).format("YYYY-MM-DD")}
            onChange={(date) => {
              checkContractYear2(date, props.onChange);
            }}
            minDate={props.rowData.start_date}
            maxDate={
              Object.values(period).length
                ? Object.values(period).at(-1).tableData?.id >
                  props.rowData?.tableData?.id
                  ? Object.values(period)[props.rowData?.tableData?.id]
                      ?.end_date
                  : season_end_date
                : season_end_date
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      title: <IntlMessages id="type"> Type </IntlMessages>,
      field: "type",
      type: "numeric",
      initialEditValue: 2,
      lookup: { 1: "Arrival", 2: "Stay" },
      aling: "center",
      validate: (rowData) => !!(rowData.type || rowData.type >= 0),
    },
    {
      title: <IntlMessages id="release"> Release </IntlMessages>,
      field: "release",
      type: "numeric",
      validate: (rowData) => !!rowData.release,
      editComponent: (props) => {
        return (
          <TextField
            error={props.value ? false : true}
            id="release"
            type="number"
            inputProps={{ min: 1 }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            onKeyPress={(e) => {
              if (isNumeric(e)) {
                e.preventDefault();
              }
            }}
          />
        );
      },
    },
    {
      title: <IntlMessages id="min_stay">Min Stay</IntlMessages>,
      field: "min_stay",
      type: "numeric",
      aling: "center",
      validate: (rowData) => !!rowData.min_stay && rowData.min_stay > 0,
      editComponent: (props) => {
        return (
          <TextField
            error={props.value ? (props.value > 0 ? false : true) : true}
            id="mins_stay"
            type="number"
            inputProps={{ min: 0 }}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            onKeyPress={(e) => {
              if (isNumeric(e)) {
                e.preventDefault();
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <InputBase
        inputProps={{
          style: {
            marginTop: "0.1%",
            textAlign: "center",
            color:
              localStorage.getItem("theme-type") === "dark" ? "white" : "black",
          },
        }}
        defaultValue={intl.formatMessage({ id: "PERIODS" })}
        fullWidth
        disabled
        margin="dense"
      />

      <MaterialTable
        tableRef={materialTableRef}
        title=""
        columns={columns}
        data={Object.values(periods)}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: "first",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <h4
                style={{
                  position: "center",
                  marginLeft: "center",
                  textAlign: "center",
                }}
              >
                <IntlMessages id="add.new.period" />
              </h4>
            ),
          },
        }}
        components={{
          OverlayLoading: () => <Loader />,
          EditRow: (props) => {
            if (props.mode == "update") {
              setisPeriodsNewAndEdit(true);
            }
            return (
              <MTableEditRow
                {...props}
                onKeyDown={(e) => {
                  if (
                    e.keyCode === 27 ||
                    e.keyCode === 109 ||
                    e.keyCode === 189
                  ) {
                    e.preventDefault();
                  }
                }}
                onEditingCanceled={(mode, rowData) => {
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (mode == "update") {
                        Swal.fire({
                          title: intl.formatMessage({
                            id: "are.you.sure?",
                          }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes",
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({
                            id: "yes",
                          }),
                          cancelButtonText: intl.formatMessage({
                            id: "no",
                          }),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "add") {
                        Swal.fire({
                          title: intl.formatMessage({
                            id: "are.you.sure?",
                          }),
                          text: intl.formatMessage({
                            id: "do.you.want.to.cancel.the.changes",
                          }),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#41C329",
                          allowOutsideClick: false,
                          cancelButtonColor: "#d33",
                          confirmButtonText: intl.formatMessage({
                            id: "yes",
                          }),
                          cancelButtonText: intl.formatMessage({
                            id: "no",
                          }),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.onEditingCanceled(mode, rowData);
                            resolve();
                          } else if (result.isDenied) {
                            reject();
                          }
                        });
                      }
                      if (mode == "delete") {
                        props.onEditingCanceled(mode, rowData);
                      }
                    });
                  });
                }}
              />
            );
          },

          onRowAdd: (props) => (
            <MTableEditRow
              {...props}
              onKeyDown={(e) => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
            />
          ),
        }}
        actions={[
          isAuthAdd
            ? undefined
            : !contractStatusControl
            ? {
                icon: "add",
                disabled: true,
                position: "toolbar",
                tooltip: intl.formatMessage({
                  id: "you.are.not.authorized",
                }),
              }
            : undefined,
        ]}
        icons={{
          Add: (props) => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
                hidden={!contractStatusControl}
                onClick={() => onClickAdd(!addClickButton)}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          ),
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon style={{ color: "red" }} />,

          // Edit: () => (
          //   <ModeEditIcon
          //     disabled={!isAuthUpdate}
          //     onClick={() => onClickAdd(true)}
          //   />
          // ),
        }}
        editable={{
          deleteTooltip: (row) =>
            isAuthDelete
              ? intl.formatMessage({ id: "delete" })
              : intl.formatMessage({ id: "you.are.not.authorized" }),
          editTooltip: (row) =>
            isAuthUpdate
              ? intl.formatMessage({ id: "update" })
              : intl.formatMessage({ id: "you.are.not.authorized" }),
          isDeletable: (rowData) =>
            isAuthDelete
              ? contractStatusControl
                ? rowData.id == lastPeriod
                  ? true
                  : false
                : false
              : false,
          isEditable: (row) =>
            isAuthUpdate ? (contractStatusControl ? true : false) : false,

          onRowAdd: isAuthAdd
            ? (newData) =>
                new Promise((resolve, reject) => {
                  setIsClick(true);

                  if (
                    newData.code &&
                    newData.start_date &&
                    newData.end_date &&
                    newData.type &&
                    newData.release &&
                    newData.min_stay
                  ) {
                    newData.code = newData.code?.toUpperCase();
                    //newData['start_date'] = Object.keys(periods).length;
                    /* ? tomorrowDate(periods[lastPeriod] ? periods[lastPeriod].end_date : '')
                      : season_start_date; */
                    setSelectedPeriod([...selectedPeriod, newData]);

                    if (newData.release < 1 || newData.min_stay < 0) {
                      sweetAlerts(
                        "error",
                        intl.formatMessage({
                          id:
                            "option.can.not.be.less.than.one.or.min.stay.can.not.be.less.than.zero",
                        })
                      );

                      reject();
                    } else {
                      dispatch(
                        addPeriodService(
                          newData,
                          selectedContract ? selectedContract : 0
                        )
                      );
                      resolve();
                    }
                    onClickAdd(false);
                  } else {
                    sweetAlerts(
                      "error",
                      intl.formatMessage({ id: "please.fill.all.fields" })
                    );
                    reject();
                  }
                })
            : undefined,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const newPeriods = [newData];
              if (
                newData.code &&
                newData.end_date &&
                newData.type &&
                newData.release &&
                newData.min_stay
              ) {
                if (newData.release < 1 || newData.min_stay < 0) {
                  sweetAlerts(
                    "error",
                    intl.formatMessage({
                      id:
                        "option.can.not.be.less.than.one.or.min.stay.can.not.be.less.than.zero",
                    })
                  );
                  reject();
                } else {
                  const editedRow = controlDates(oldData);
                  newData.code = newData.code?.toUpperCase();
                  if (editedRow) {
                    editedRow.start_date = tomorrowDate(newData.end_date);
                    newPeriods.push(editedRow);
                  }

                  // Min stay ve realse değişeceği zaman error veriyordu onu backende kontrol etmemesi için içersine control diye değişken atıyorum.
                  if (
                    newData.min_stay != oldData.min_stay ||
                    newData.release != oldData.release
                  ) {
                    newPeriods[0]["control"] = false;
                  } else {
                    newPeriods[0]["control"] = true;
                  }

                  dispatch(updatePeriodService(newPeriods)).then((res) => {
                    if (res.code == 500) {
                      sweetAlerts("error", res.message);
                      if (
                        res.message ==
                        "This period have a contract room price. If you want to edit, first delete contract room price."
                      ) {
                        setisPeriodsNewAndEdit(false);
                      }
                      reject();
                    } else {
                      sweetAlerts("success", "Period Updated!");
                      resolve();
                    }
                  });
                }
              } else {
                sweetAlerts(
                  "error",
                  intl.formatMessage({ id: "please.fill.all.fields" })
                );
                reject();
              }
              onClickAdd(false);
            }),

          onRowUpdateCancelled: () => onClickAdd(false),
          onRowAddCancelled: () => onClickAdd(false),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                dispatch(deletePeriodService(oldData.id));
                resolve();
              }, 1000);
            }),
        }}
      />
    </div>
  );
}

export const MemoizedPeriods = React.memo(Periods);
