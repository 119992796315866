import axios from "../services/axios";
import {fetchError, fetchSuccess} from "redux/actions";
import {getReservationList} from "redux/actions/reservationlist";
import axiosBot from "./axiosBot";
import {apihost} from "./axiosBot";

export const reservationListPP = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/PP/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};

export const reservationListW2M = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/W2M/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListPT = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/PT/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListSH = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/SH/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response.data;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};

export const reservationListDNATA = (
  data,
  setLoadingBookingList
) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/DN/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};

export const reservationListWB = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;
  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/WB/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response.data;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListWEBBEDS = (
  data,
  setLoadingBookingList
) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/WEBBEDS/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListIVC = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/IVC/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListLMT = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/LMT/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};
export const reservationListJU = (data, setLoadingBookingList) => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;

  let botData = {};
  botData["endpoint"] = apihost;
  botData["db_name"] = data;

  setLoadingBookingList(true);
  return axiosBot
    .post("reservationList/JU/", botData)
    .then(response => {
      dispatch(getReservationList(response));
      dispatch(fetchSuccess());
      setLoadingBookingList(false);
      return response;
    })
    .catch(event => {
      dispatch(fetchError(event.message));
    });
};

export const getPaginedOperatorReservationService = async (
  rowsPerPage,
  page
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const res = await axios.post("getAllReservations", {
    rowsPerPage: rowsPerPage,
    page: page
  });

  return res;
};

export const reservationOperatorMultiFilterService = async (
  filterObject,
  rowsPerPage,
  page
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const res = await axios.post("getAllReservations", {
    filterObject,
    rowsPerPage: rowsPerPage,
    page: page,
    stuation: 1
  });
  return res;
};
