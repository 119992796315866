import {
  GET_BOARD_MAP,
  ADD_BOARD_MAP,
  DELETE_BOARD_MAP
} from "../../redux/actions/types";

const initialState = {
  mappedBoards: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_MAP:
      return {
        ...state,
        mappedBoards: action.payload
      };
    case ADD_BOARD_MAP:
      return {
        ...state,
        mappedBoards: action.payload
      };
    case DELETE_BOARD_MAP:
      let tempState = state.mappedBoards;
      action.payload.map(item => {
        let tempBoard = tempState.find(board => board.id == item);
        tempBoard.board_id = 0;
        tempBoard.board_name = undefined;
        return tempBoard;
      });

      return {
        ...state,
        mappedBoards: tempState
      };
    default:
      return state;
  }
};
