import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { getHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, lightBlue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import {
  getMatchHotelDataService,
  addMatchHotelDataService,
  deleteMatchHotelDataService,
} from "services/hotelmatch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { getOperatorHotelsService } from "services/operatorhotels";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HotelMatchModal from "./HotelMatchModal";
import Loader from "./Loader";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { getOperatorService } from "services/operator";
const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

export default function HotelMatch() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState({});
  let operatorIds = {};
  useEffect(() => {
    dispatch(getOperatorService());
    dispatch(getHotelService());
    dispatch(getMatchHotelDataService());
  }, []);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { SHhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { DNhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { PPhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { LMXhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { WBhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { SIThotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { FORhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { WEBBEDShotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { JUMBOhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { LMThotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { XPURhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { IVChotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { SDNhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { JUhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { W2Mhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { PThotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { operators } = useSelector(({ operators }) => operators);
  const [matchHotelData, setMatchHotelData] = useState([]);

  const [operatorsArray, setOperatorsArray] = useState([]);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);

  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 5 && permission.delete === 1
  );

  useEffect(() => {
    if (Object.values(operators).length > 0) {
      setOperatorsArray(Object.values(operators));
      operatorIds = Object.values(operators).map((op) => {
        return op.id;
      });
      dispatch(getOperatorHotelsService(operatorIds));
    }
  }, [operators]);
  /******************THIS SECTION MADE FOR PREVENT WHEN PAGE RELOAD DATA THAT INSIDE REDUX HAS BEEN DELETING*************************/
  let hotelObj = {};

  hotelObj.sunHotels = SHhotels ? SHhotels : [];
  hotelObj.dnhotels = DNhotels ? DNhotels : [];
  hotelObj.pphotels = PPhotels ? PPhotels : [];
  hotelObj.lmxhotels = LMXhotels ? LMXhotels : [];
  hotelObj.wbhotels = WBhotels ? WBhotels : [];
  hotelObj.sithotels = SIThotels ? SIThotels : [];
  hotelObj.forhotels = FORhotels ? FORhotels : [];
  hotelObj.webbedshotels = WEBBEDShotels ? WEBBEDShotels : [];
  hotelObj.jumbohotels = JUMBOhotels ? JUMBOhotels : [];
  hotelObj.lmthotels = LMThotels ? LMThotels : [];
  hotelObj.xpurhotels = XPURhotels ? XPURhotels : [];
  hotelObj.ıvchotels = IVChotels ? IVChotels : [];
  hotelObj.sdnhotels = SDNhotels ? SDNhotels : [];
  hotelObj.juhotels = JUhotels ? JUhotels : [];
  hotelObj.w2mhotels = W2Mhotels ? W2Mhotels : [];
  hotelObj.pthotels = PThotels ? PThotels : [];

  //Control Function have to return always false
  function controlHotelObjects() {
    if (hotelObj.sunHotels != undefined) {
      if (hotelObj.sunHotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.dnhotels != undefined) {
      if (hotelObj.dnhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.sithotels != undefined) {
      if (hotelObj.sithotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.lmxhotels != undefined) {
      if (hotelObj.lmxhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.wbhotels != undefined) {
      if (hotelObj.wbhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.pphotels != undefined) {
      if (hotelObj.pphotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.forhotels != undefined) {
      if (hotelObj.forhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.webbedshotels != undefined) {
      if (hotelObj.webbedshotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.jumbohotels != undefined) {
      if (hotelObj.jumbohotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.lmthotels != undefined) {
      if (hotelObj.lmthotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.xpurhotels != undefined) {
      if (hotelObj.xpurhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.ıvchotels != undefined) {
      if (hotelObj.ıvchotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.sdnhotels != undefined) {
      if (hotelObj.sdnhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.juhotels != undefined) {
      if (hotelObj.juhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.w2mhotels != undefined) {
      if (hotelObj.w2mhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.pthotels != undefined) {
      if (hotelObj.pthotels.length != 0) {
        return true;
      }
    }

    return false;
    // const cont =
    //   (hotelObj.hasOwnProperty("sunHotels") &&
    //     hotelObj.sunHotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("dnhotels") && hotelObj.dnhotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("sithotels") &&
    //     hotelObj.sithotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("lmxhotels") && hotelObj.lmxhotels.length != 0);
    // return cont;
  }
  // IF REDUX STATE IS NULL DONT SAVE TO LOCALSTORAGE DATA ELSE NOT NULL SAVE TO LOCALSTORAGE DATA
  //This control is prevent data losing inside localstorage when page reloaded

  if (controlHotelObjects()) {
    localStorage.setItem("hotelMatch", JSON.stringify(hotelObj));
  }
  const newHotelObj = JSON.parse(localStorage.getItem("hotelMatch"));
  //WHEN COMPONENT UNMOUNT USEEFFECT RUN AND DELETE LOCALSTORAGE ------> localStorage.removeItem('hotelMatch')
  useEffect(() => {
    return () => {
      localStorage.removeItem("hotelMatch");
    };
  }, []);

  useEffect(() => {
    if (Object.values(matched_hotels).length > 0) {
      setMatchHotelData(Object.values(matched_hotels));
    }
  }, [matched_hotels]);

  /***************************************************************************************************/

  //For alert messages function
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const operatorNameFunc = (operator) => {
    let name =
      operator == "SH"
        ? "SUNHOTEL"
        : operator == "WB"
        ? "WELCOMEBEDS"
        : operator == "PP"
        ? "PEAKPOINT"
        : operator == "SIT"
        ? "SITALIA"
        : operator == "DN"
        ? "DNATA"
        : operator == "LMT"
        ? "LAST MINUTE"
        : operator == "IVC"
        ? "IVECTOR"
        : operator == "FOR"
        ? "FORYOU"
        : operator == "SDN"
        ? "SEDNA"
        : operator == "JU"
        ? "JOINUP"
        : operator == "W2M"
        ? "WORLD 2 MEET"
        : operator == "PT"
        ? "PRIME TRAVEL"
        : operator;

    return name;
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpenModal(true);
  };

  const deleteFunc = (id) => {
    Swal.fire({
      title: intl.formatMessage({
        id: "are.you.sure.delete",
      }),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#41C329",
      allowOutsideClick: false,
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMatchHotelDataService(id));
      }
    });
  };

  const inputChange = useRef();
  const extraNetInputChange = useRef();
  let filterResult = [];
  let lastResult = [];
  const filterChange = () => {
    let searchextraNetInputValue = extraNetInputChange.current?.value;
    let searchInputValue = inputChange.current?.value;
    let returnParam = Object.values(matched_hotels);
    let otels = Object.values(matched_hotels);
    const newFilterHotelArray = [];

    /********************* HOTEL SEARCH AREA *****************************/
    const arr = otels.map((p) => {
      return hotels[p.global_hotel_id]?.name;
    });
    if (searchInputValue.length > 0) {
      arr.map((v) => {
        if (v.toLowerCase().includes(searchInputValue.toLowerCase()) == true) {
          newFilterHotelArray.push(v);
        }
      });

      if (newFilterHotelArray.length > 0) {
        otels.map((val) => {
          Object.values(hotels).map((param, key) => {
            if (param.id == val.global_hotel_id) {
              newFilterHotelArray.map((resultName) => {
                if (param.name == resultName) {
                  lastResult.push(val);
                }
              });
            }
          });
        });
      }

      //search someting but empty find result
      if (lastResult.length == 0 && searchInputValue.length > 0) {
        inputChange.current.value = "";
        Swal.fire("Hotels not found");
      } //search someting and return result
      else if (lastResult.length > 0 && searchInputValue.length > 0) {
        returnParam = lastResult;
      } else {
        returnParam = Object.values(matched_hotels);
      }
    }
    /********************* EXTRANET HOTEL SEARCH AREA *****************************/
    let ekstraNetHotelArr = [];
    if (searchextraNetInputValue.length > 0) {
      returnParam.map((v) => {
        Object.values(v).map((ht) => {
          if (ht.hotel_name != undefined) {
            if (
              ht.hotel_name
                .toLowerCase()
                .includes(extraNetInputChange.current?.value.toLowerCase()) ==
              true
            ) {
              if (ekstraNetHotelArr.indexOf(v) === -1) {
                ekstraNetHotelArr.push(v);
              }
            }
          }
        });
      });
      //search someting but empty find result
      if (
        ekstraNetHotelArr.length == 0 &&
        searchextraNetInputValue.length > 0
      ) {
        extraNetInputChange.current.value = "";
        Swal.fire("Hotels not found");
      } else if (
        ekstraNetHotelArr.length > 0 &&
        searchextraNetInputValue.length > 0
      ) {
        returnParam = ekstraNetHotelArr;
      } else {
        returnParam = Object.values(matched_hotels);
      }
    }
    setMatchHotelData(returnParam);
  };
  return (
    <>
      <HotelMatchModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
      />
      <Box
        style={{
          width: "100%",
          height: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
          flexDirection: "row",
        }}
      >
        <Box>
          <input
            placeholder="Search For Hotel Name"
            ref={inputChange}
            style={{
              borderRadius: "3px",
              outline: "none",
              border: "none",
              boxShadow: "0 0 2px black,inset 0 0 2px black",
              textAlign: "center",
              height: "2rem",
              width: "30rem",
              type: "text",
              color: localStorage.getItem("theme-type") === "dark" && "black",
            }}
          />
        </Box>

        <Box>
          <input
            placeholder="Search For Extranet Hotel Name"
            ref={extraNetInputChange}
            style={{
              borderRadius: "3px",
              outline: "none",
              border: "none",
              marginLeft: "1.5rem",
              boxShadow: "0 0 2px black,inset 0 0 2px black",
              textAlign: "center",
              height: "2rem",
              width: "30rem",
              type: "text",
              color: localStorage.getItem("theme-type") === "dark" && "black",
            }}
          />
          <ManageSearchIcon
            onClick={filterChange}
            style={{
              color: "rgb(66, 165, 245)",
              cursor: "pointer",
              fontSize: "3rem",
              marginBottom: ".2rem",
              marginLeft: ".4rem",
            }}
          />
        </Box>
      </Box>

      <MaterialTable
        // columns={columns}
        data={matchHotelData} //setMaterialTableData(lastResult)
        title=""
        style={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          padding: "6px 24px 7px 0",
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: "first",
          tableLayout: "fixed",
        }}
        icons={{
          Add: (props) => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
                onClick={() => handleOpen({})}
                disabled={isAuthAdd ? false : true}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          ),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                // if (
                //   (newData.hasOwnProperty("SH") && newData.SH.hotel_id) ||
                //   (newData.hasOwnProperty("DN") && newData.DN.hotel_id) ||
                //   (newData.hasOwnProperty("PP") && newData.PP.hotel_id) ||
                //   (newData.hasOwnProperty("WB") && newData.WB.hotel_id) ||
                //   (newData.hasOwnProperty("LMX") && newData.LMX.hotel_id) ||
                //   (newData.hasOwnProperty("WEBBEDS") &&
                //     newData.WEBBEDS.hotel_id) ||
                //   (newData.hasOwnProperty("JUMBO") && newData.JUMBO.hotel_id) ||
                //   (newData.hasOwnProperty("FOR") &&
                //     newData.FOR.hotel_id) ||
                //   (newData.hasOwnProperty("SIT") && newData.SIT.hotel_id) ||
                //   (newData.hasOwnProperty("LMT") && newData.LMT.hotel_id) ||
                //   (newData.hasOwnProperty("XPUR") && newData.XPUR.hotel_id)(
                //     newData.hasOwnProperty("IVC") && newData.IVC.hotel_id
                //   )
                // ) {
                //   dispatch(addMatchHotelDataService(newData));
                // } else {
                //   sweetAlerts(
                //     "warning",
                //     "Please Select A Hotel From The Operator!"
                //   );
                // }
              }, 1000);
            }),
        }}
        components={{
          OverlayLoading: () => <Loader />,
          Header: (props) => (
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
          ),
          Body: (props) =>
            Object.keys(matched_hotels).length == 0 ? (
              <h4
                style={{
                  position: "center",
                  marginLeft: "center",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                <IntlMessages id="no.matched.global.hotel" />
              </h4>
            ) : (
              props.renderData.map((row) => (
                <TableBody>
                  <TableRow>
                    <TableRow key={row.id}>
                      <TableCell
                        align="left"
                        colSpan={15}
                        style={{ backgroundColor: blue[400] }}
                      >
                        {hotels[row.global_hotel_id]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* operator başlıkları var head içinde */}
                      <TableHead>
                        <TableRow>
                          {operatorsArray.map((dataKey) => (
                            <TableCell
                              align="left"
                              colSpan={15}
                              style={{
                                border: "10px",
                                fontWeight: "bold",
                                backgroundColor: lightBlue[100],
                                width: (props.scrollWidth + 1000) / 7,
                              }}
                            >
                              {operatorNameFunc(dataKey.code)}
                            </TableCell>
                          ))}
                          <TableCell
                            style={{
                              border: "10px",
                              fontWeight: "bold",
                              backgroundColor: lightBlue[100],
                              width: props.scrollWidth / 8,
                            }}
                            align="right"
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {operatorsArray.map((operator) => {
                          return (
                            <TableCell
                              align="left"
                              colSpan={15}
                              style={{
                                border: "10px",
                                fontWeight: "bold",
                                width: (props.scrollWidth + 1000) / 7,
                                overflowX: "hidden",
                              }}
                            >
                              {row[operator.code]?.hotel_name}
                            </TableCell>
                          );
                        })}
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              row["type"] = "update";
                              handleOpen(row);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              deleteFunc(row.global_hotel_id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableBody>
                    </TableRow>
                  </TableRow>
                </TableBody>
              ))
            ),
        }}
      />
    </>
  );
}
