import { GET_TYPES } from '../actions/types';

const initialState = {
  cincoutType: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPES: {
      return { ...state, cincoutType: action.payload.cincoutType ? action.payload.cincoutType : [] };
    }
    default:
      return state;
  }
};
