import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { addHotelService, updateHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegionsService,
  getCountriesService,
  getCitiesService,
  getSubCitiesService,
} from "services/destinations";
import {
  Button,
  FormControl,
  TextField,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { getProductTypesService } from "services/productTypes";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  resetCities,
  resetCountries,
  resetSubCities,
} from "redux/actions/destinations";
import { getStarRatesService } from "services/starRate";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, blue, red } from "@material-ui/core/colors";
import UpdateIcon from "@mui/icons-material/Update";
import { PhoneFormatCustom } from "../Common/NumberFormat/NumberFormat";
import { useIntl } from "react-intl";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useWhyDidYouUpdate } from "@jumbo/utils/useWhyDidYouUpdate";
import Loader from "./Loader";

const AirportCodesOptions = lazy(() => import("./AirportCodesOptions"));
const GiataCodesOptions = lazy(() => import("./GiataCodesOptions"));
const Destination = lazy(() => import("./NewHotelDestination/Destinations"));
const DestinationUpdate = lazy(() =>
  import("./NewHotelDestination/DestinationUpdate")
);

export default function NewHotel({ openModal, setOpenModal }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (starRates.length === 0) {
      dispatch(getStarRatesService());
    }
    if (regions.length === 0) {
      dispatch(getRegionsService());
    }
    if (productTypes.length === 0) {
      dispatch(getProductTypesService());
    }
  }, [dispatch]);
  //For alert message
  const MySwal = withReactContent(Swal);
  const { regions } = useSelector(({ destinations }) => destinations);
  const allCountries = useSelector((state) => state.destinations.allCountries);

  const theme = createTheme({
    palette: {
      primary: green,
      secondary: blue,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const [buttonState, setButtonState] = useState({
    edit: true,
    add: false,
  });
  const [values, setValues] = useState({
    name: "",
    product_type_id: "",
    company: "",
    tax_no: "",
    tax_office: "",
    tel: "(100) 000-0000",
    fax: "",
    e_mail: "",
    web_page: "",
    star_rating: "",
    airport_code_id: "",
    giata_code: "",
    address: "",
  });
  const [destinationValues, setDestinationValues] = useState({
    region_id: "",
    country_id: "",
    city_id: "",
    sub_city_id: "",
    district: "",
    countryIDS: [],
  });

  //To get the data from the state
  const addressState = useSelector((state) => state.destinations);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { productTypes } = useSelector(({ productTypes }) => productTypes);
  const { starRates } = useSelector(({ starRates }) => starRates);
  const { selectedHotel } = useSelector(({ hotels }) => hotels);

  useEffect(() => {
    resetData();
  }, [selectedHotel]);

  //For alert messages function
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const handleChange = useCallback((e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  });

  const resetData = () => {
    if (selectedHotel.length !== 0) {
      //The information of the row to be updated is get and placed in the inputs.
      const updatedState = {};
      let destinationValuesObject = {};
      Object.entries(selectedHotel ? hotels[selectedHotel] : []).map(
        (element) => {
          updatedState[element[0]] = element[1];
          element[0] === "region_id" &&
            (destinationValuesObject.region_id = element[1]);
          element[0] === "country_id" &&
            (destinationValuesObject.country_id = element[1]);
          element[0] === "city_id" &&
            (destinationValuesObject.city_id = element[1]);
          element[0] === "sub_city_id" &&
            (destinationValuesObject.sub_city_id = element[1]);
          element[0] === "district" &&
            (destinationValuesObject.district = element[1]);

          setValues({ ...values, ...updatedState });
        }
      );
      setDestinationValues(destinationValuesObject);
      setButtonState({ edit: false, add: true });
    } else {
      const emptyValuesState = {};
      Object.entries(values).map((element) => {
        emptyValuesState[element[0]] = "";
        setValues({ ...values, ...emptyValuesState });
      });
      setDestinationValues({
        region_id: "",
        country_id: "",
        city_id: "",
        sub_city_id: "",
        district: "",
      });
      setButtonState({ edit: true, add: false });
    }
  };

  useWhyDidYouUpdate("NewHotel", { values, openModal, setOpenModal });
  //For add hotel
  const onRowAdd = () => {
    if (
      values.name !== "" &&
      values.product_type_id !== "" &&
      values.star_rating !== "" &&
      values.tax_no !== "" &&
      values.tax_office !== "" &&
      destinationValues.region_id !== "" &&
      values.company !== "" &&
      destinationValues.country_id !== "" &&
      //values.city_id !== '' &&
      values.e_mail !== "" &&
      values.web_page !== "" &&
      values.tel !== "" &&
      values.airport_code_id !== "" &&
      values.tel.length == 18
    ) {
      let hotel_name_temp = "";
      let temp_name = values.name;
      let hotel_name_string = "";
      temp_name = temp_name.split(" ");
      temp_name.forEach((element) => {
        if (element.length >= 3) {
          hotel_name_string = hotel_name_string + element.slice(0, 3);
        }
      });
      regions.forEach((region) => {
        if (region.id === destinationValues.region_id) {
          hotel_name_temp = region.code;
        }
      });

      allCountries.forEach((country) => {
        if (country.id === destinationValues.country_id) {
          hotel_name_temp = hotel_name_temp + country.code;
        }
      });
      hotel_name_temp = hotel_name_string + hotel_name_temp;

      values["hotel_code"] = hotel_name_temp.toUpperCase();
      const mergeValues = { ...values, ...destinationValues };

      if (isTrueURL(values.web_page) && isTrueEmail(values.e_mail)) {
        if (
          Object.values(hotels).filter((htl) => htl.name == values.name)
            .length == 0
        ) {
          dispatch(addHotelService(mergeValues)).then((res) => {
            if (res.data.code == 201) {
              setDestinationValues({
                region_id: "",
                country_id: "",
                city_id: "",
                sub_city_id: "",
                district: "",
                countryIDS: [],
              });
            }
          });
        } else {
          sweetAlerts(
            "error",
            intl.formatMessage({ id: "hotel.already.exist" })
          );
        }

        const emptyValuesState = {};
        Object.entries(values).forEach((element) => {
          emptyValuesState[element[0]] = "";
          setValues({ ...values, ...emptyValuesState });
        });
        /* dispatch(resetCountries());
        dispatch(resetCities());
        dispatch(resetSubCities()); */
        setOpenModal(false);
      } else {
        sweetAlerts(
          "warning",
          intl.formatMessage({ id: "wrong.url.or.email" })
        );
      }
    } else {
      if (values.tel.length !== 18 || values.tel == "") {
        sweetAlerts(
          "warning",
          intl.formatMessage({ id: "please.fill.in.phone.fields" })
        );
      } else {
        if (
          values.name == "" &&
          values.product_type_id == "" &&
          values.star_rating == "" &&
          values.tax_no == "" &&
          values.tax_office == "" &&
          destinationValues.region_id == "" &&
          values.company == "" &&
          destinationValues.country_id == "" &&
          //values.city_id == '' &&
          values.e_mail == "" &&
          values.web_page == "" &&
          values.tel == "" &&
          values.airport_code_id == ""
        ) {
          sweetAlerts(
            "warning",
            intl.formatMessage({ id: "please.fill.in.all.fields" })
          );
        } else {
          sweetAlerts(
            "warning",
            intl.formatMessage({ id: "please.fill.in.all.fields" })
          );
        }
      }
    }
  };
  //For update hotel
  const onRowUpdate = () => {
    if (
      values.name !== "" &&
      values.product_type_id !== "" &&
      values.star_rating !== "" &&
      values.tax_no !== "" &&
      values.tax_office !== "" &&
      destinationValues.region_id !== "" &&
      values.company !== "" &&
      destinationValues.country_id !== "" &&
      destinationValues.city_id !== "" &&
      values.e_mail !== "" &&
      values.web_page !== "" &&
      values.tel !== "" &&
      values.airport_code_id !== null &&
      values.tel.length == 18
    ) {
      let hotel_name_temp = "";
      let temp_name = values.name;
      let hotel_name_string = "";
      temp_name = temp_name.split(" ");
      temp_name.forEach((element) => {
        if (element.length >= 3) {
          hotel_name_string = hotel_name_string + element.slice(0, 3);
        }
      });
      regions.forEach((region) => {
        if (region.id === destinationValues.region_id) {
          hotel_name_temp = region.code;
        }
      });

      allCountries.forEach((country) => {
        if (country.id === destinationValues.country_id) {
          hotel_name_temp = hotel_name_temp + country.code;
        }
      });
      hotel_name_temp = hotel_name_string + hotel_name_temp;

      values["hotel_code"] = hotel_name_temp.toUpperCase();
      const mergeValues = { ...values, ...destinationValues };
      if (isTrueURL(values.web_page) && isTrueEmail(values.e_mail)) {
        /*  if (Object.values(hotels).filter(htl => htl.name == values.name).length == 0) {
        dispatch(updateHotelService(values));
      } else {
        sweetAlerts('error', 'Hotel already exist!');
      } */
        setOpenModal(false);
        dispatch(updateHotelService(mergeValues)).then((res) => {
          if (res.data.code == 201) {
            setDestinationValues({
              region_id: "",
              country_id: "",
              city_id: "",
              sub_city_id: "",
              district: "",
              countryIDS: [],
            });
          }
        });

        dispatch(resetCountries());
        dispatch(resetCities());
        dispatch(resetSubCities());
      } else {
        sweetAlerts(
          "warning",
          intl.formatMessage({ id: "wrong.url.or.email" })
        );
      }
    } else {
      if (values.tel.length !== 18 || values.tel == "") {
        sweetAlerts(
          "warning",
          intl.formatMessage({ id: "please.fill.in.phone.fields" })
        );
      } else {
        if (
          values.name == "" &&
          values.product_type_id == "" &&
          values.star_rating == "" &&
          values.tax_no == "" &&
          values.tax_office == "" &&
          destinationValues.region_id == "" &&
          values.company == "" &&
          destinationValues.country_id == "" &&
          //values.city_id == '' &&
          values.e_mail == "" &&
          values.web_page == "" &&
          values.tel == "" &&
          values.airport_code_id == ""
        ) {
          sweetAlerts(
            "warning",
            intl.formatMessage({ id: "please.fill.in.all.fields" })
          );
        } else {
          sweetAlerts(
            "warning",
            intl.formatMessage({ id: "please.fill.in.all.fields" })
          );
        }
      }
    }
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      maxWidth: "100%",
    },
    rootcard: {
      margin: "0 auto",
      width: "95%",
      position: "center",
    },
    root: {
      width: "50%",
      position: "center",
      overflow: "visible !important",
      paddingBottom: "30px",
      "& .MuiCard-root": {
        overflow: "visible !important",
      },
    },
    test: {
      margin: theme.spacing(2),
      minWidth: "90%",
      textAlign: "center",
    },
    test1: {
      margin: theme.spacing(2),
      minWidth: "100%",
    },
    test2: {
      margin: theme.spacing(2),
      float: "right",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 8, 6),
    },
    modal: {
      alignItems: "center",
      textAlign: "-webkit-center",
      padding: "70px",
      justifyContent: "center",
      overflow: "scroll",
    },
  }));
  const isTrueEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/.test(
      email
    );
  };

  const isTrueURL = (url) => {
    return /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-z()]{2,5}\b)+/g.test(
      url
    );
  };
  const handleClose = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage({
        id: "you.will.not.be.able.to.revert.this.action",
      }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" }),
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setOpenModal(false);

        // Eğer modala kapatıldığında update durumu var ise dataları eski haline getiriyoruz eğer yok ise siliyoruz boş değer artıyoruz.
        resetData();
      }
    });
  };
  const classes = useStyles();
  const themeType = localStorage.getItem("theme-type");

  return (
    <>
      <Modal
        className={classes.modal}
        open={openModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.rootcard}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {buttonState.add === false ? (
                  <IntlMessages id="new.product" />
                ) : (
                  <IntlMessages id="update.product" />
                )}
              </Typography>
              <Grid container item xs={12} direction="row">
                <Grid item xs container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid item xs container direction="row" spacing={1}>
                          <Grid item xs={12}>
                            <FormControl className={classes.test}>
                              <TextField
                                name="name"
                                required
                                error={values.name !== "" ? false : true}
                                label={<IntlMessages id="hotel.name" />}
                                placeholder={intl.formatMessage({
                                  id: "hotel.name",
                                })}
                                autoComplete="off"
                                /* label={<IntlMessages id="hotel.name" />}
                                placeholder={<IntlMessages id="hotel.name" />} */
                                value={values.name}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl required className={classes.test1}>
                              <Autocomplete
                                options={productTypes}
                                onChange={(event, value) => {
                                  setValues({
                                    ...values,
                                    product_type_id: value ? value.id : "",
                                  });
                                }}
                                value={
                                  values.product_type_id !== ""
                                    ? productTypes[
                                        productTypes.findIndex(
                                          (i) => i.id === values.product_type_id
                                        )
                                      ]
                                    : ""
                                }
                                getOptionLabel={(option) =>
                                  option.name ?? option
                                }
                                getOptionDisabled={(option) => option.disabled}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={intl.formatMessage({
                                      id: "product.type",
                                    })}
                                    error={
                                      values.product_type_id !== ""
                                        ? false
                                        : true
                                    }
                                    label={<IntlMessages id="product.type" />}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <Autocomplete
                                options={starRates}
                                onChange={(event, value) => {
                                  setValues({
                                    ...values,
                                    star_rating: value ? value.id : "",
                                  });
                                }}
                                value={
                                  values.star_rating !== ""
                                    ? starRates[
                                        starRates.findIndex(
                                          (i) => i.id === values.star_rating
                                        )
                                      ]
                                    : ""
                                }
                                getOptionLabel={(option) =>
                                  option.value ?? option
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={
                                      values.star_rating !== "" ? false : true
                                    }
                                    label={<IntlMessages id="star.rating" />}
                                    placeholder={intl.formatMessage({
                                      id: "star.rating",
                                    })}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                required
                                label={<IntlMessages id="email" />}
                                value={values.e_mail}
                                error={
                                  values.e_mail !== ""
                                    ? isTrueEmail(values.e_mail)
                                      ? false
                                      : true
                                    : true
                                }
                                placeholder={intl.formatMessage({
                                  id: "email",
                                })}
                                name="e_mail"
                                autoComplete="new-password"
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                required
                                label={<IntlMessages id="website" />}
                                value={values.web_page}
                                error={
                                  values.web_page !== ""
                                    ? isTrueURL(values.web_page)
                                      ? false
                                      : true
                                    : true
                                }
                                autoComplete="off"
                                placeholder={intl.formatMessage({
                                  id: "website",
                                })}
                                name="web_page"
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                label="Fax"
                                value={values.fax}
                                name="fax"
                                onChange={handleChange}
                                placeholder="Fax"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                label={<IntlMessages id="phone" />}
                                required
                                value={values.tel ? values.tel : ""}
                                error={
                                  values.tel !== "" && values.tel.length == 18
                                    ? false
                                    : true
                                }
                                onChange={handleChange}
                                name="tel"
                                autoComplete="nope"
                                placeholder="+xx (xxx) xxx-xxxx"
                                // placeholder="Phone"
                                InputProps={{
                                  inputComponent: PhoneFormatCustom,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl className={classes.test1}>
                              <TextField
                                type="text"
                                label={<IntlMessages id="adress" />}
                                name="address"
                                multiline
                                value={values.address ? values.address : ""}
                                onChange={handleChange}
                                placeholder="Please enter address"
                                InputProps={{
                                  classes: {
                                    input:
                                      localStorage.getItem("theme-type") ===
                                      "dark"
                                        ? classes.darkTheme
                                        : classes.whiteTheme,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid item xs container direction="row" spacing={1}>
                          <Grid item xs={12}>
                            <FormControl required className={classes.test1}>
                              <TextField
                                label={<IntlMessages id="company" />}
                                required
                                name="company"
                                placeholder={intl.formatMessage({
                                  id: "company",
                                })}
                                autoComplete="off"
                                error={values.company !== "" ? false : true}
                                value={values.company}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                label={<IntlMessages id="tax.office" />}
                                required
                                name="tax_office"
                                placeholder={intl.formatMessage({
                                  id: "tax.office",
                                })}
                                autoComplete="off"
                                error={values.tax_office !== "" ? false : true}
                                value={values.tax_office}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className={classes.test}>
                              <TextField
                                required
                                label={<IntlMessages id="tax.no" />}
                                value={values.tax_no}
                                placeholder={intl.formatMessage({
                                  id: "tax.no",
                                })}
                                autoComplete="off"
                                error={values.tax_no !== "" ? false : true}
                                name="tax_no"
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    {buttonState.add === false ? (
                      <Suspense fallback={<Loader />}>
                        <Destination
                          values={destinationValues}
                          setValues={setDestinationValues}
                        />
                      </Suspense>
                    ) : (
                      <Suspense fallback={<Loader />}>
                        <DestinationUpdate
                          values={destinationValues}
                          setValues={setDestinationValues}
                        />
                      </Suspense>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Card variant="outlined" style={{ paddingBottom: "5rem" }}>
                      <CardContent>
                        <Grid item xs container direction="row" spacing={1}>
                          <Suspense fallback={<Loader />}>
                            <GiataCodesOptions
                              values={values}
                              setValues={setValues}
                              destinationValues={destinationValues}
                              setDestinationValues={setDestinationValues}
                            />
                          </Suspense>
                          <Suspense fallback={<Loader />}>
                            <AirportCodesOptions
                              values={values}
                              setValues={setValues}
                            />
                          </Suspense>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              {buttonState.edit && (
                <FormControl className={classes.test2}>
                  <Grid item container xs={12}>
                    <ThemeProvider theme={themeCancel}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                      >
                        <IntlMessages id="cancel" />
                      </Button>
                    </ThemeProvider>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onRowAdd}
                        disabled={buttonState.add}
                        startIcon={<SaveIcon />}
                      >
                        <IntlMessages id="save" />
                      </Button>
                    </ThemeProvider>
                  </Grid>
                </FormControl>
              )}
              {buttonState.add && (
                <FormControl className={classes.test2}>
                  <Grid item container xs={12}>
                    <ThemeProvider theme={themeCancel}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                      >
                        <IntlMessages id="cancel" />
                      </Button>
                    </ThemeProvider>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={buttonState.edit}
                        onClick={onRowUpdate}
                        startIcon={<UpdateIcon />}
                      >
                        <IntlMessages id="update" />
                      </Button>
                    </ThemeProvider>
                  </Grid>
                </FormControl>
              )}
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}
