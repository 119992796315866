const getEarlyBookings = (data) => {
  const filteredData = data.map((row) => {
    return Object.values(row);
  });

  const marketIndex = filteredData
    .map((row, index) => {
      return row[0] === "E  A  R  L  Y     B  O  O  K  I  N  G  S"
        ? index
        : null;
    })
    .filter((item) => item !== null)[0];
  if (marketIndex === -1 || marketIndex === undefined) return null;
  const headers = [...["EB NAME"], ...filteredData[marketIndex + 2]];

  const ebNotesIndex = filteredData.findIndex((row, index) => {
    if (typeof row[0] === "string" && row[0] !== null && row[0] !== undefined) {
      return row[0]?.includes("EB Note") && index > marketIndex;
    }
  });
  const contributionsIndex = filteredData.findIndex((row, index) => {
    if (typeof row[0] === "string" && row[0] !== null && row[0] !== undefined) {
      return (
        row[0]?.includes("C   O   N   T   R   I   B   U   T   I   O   N   S") &&
        index > marketIndex
      );
    }
  });

  const specialDiscountIndex = filteredData.findIndex((row, index) => {
    if (typeof row[0] === "string" && row[0] !== null && row[0] !== undefined) {
      if (row[0]?.includes("(") && row[0]?.includes(")")) {
        return row[0].split("(")[0].replaceAll(" ", "") === "SPECIALDISCOUNTS";
      } else {
        return row[0].replaceAll(" ", "") === "SPECIALDISCOUNTS";
      }
    }
  });

  const notesForAccountingIndex = filteredData.findIndex((row, index) => {
    if (typeof row[0] === "string" && row[0] !== null && row[0] !== undefined) {
      return row[0].replaceAll(" ", "") === "NOTESFORACCOUNTING";
    }
  });

  // get lowest index
  const lowestIndex = [
    ebNotesIndex,
    contributionsIndex,
    specialDiscountIndex,
    notesForAccountingIndex,
  ]
    .filter((item) => item !== -1)
    .sort((a, b) => a - b)[0];

  const earlyBookings = filteredData.filter((row, index) => {
    if (typeof row[0] === "string" && row[0] !== null && row[0] !== undefined) {
      return (
        index > marketIndex && row[0]?.includes("EB") && index < lowestIndex
      );
    }
  });

  const earlyBooingWithHeader = earlyBookings.map((row) => {
    return headers.reduce((acc, header, index) => {
      acc[header] = row[index];
      return acc;
    }, {});
  });

  return [earlyBookings, earlyBooingWithHeader];
};

export default getEarlyBookings;
