import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  deleteCotractRoom,
  getContract,
  updateCotractRoom,
  addContract,
  addCotractRoom,
  getContractRoom,
  updateContract,
  changeContractStatus,
  copyContract,
} from "redux/actions/contract";
import axios from "./axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SET_SELECTED_CONTRACT } from "redux/actions/types";
/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
import { addAddPaxRates, deleteAddPaxRates } from "redux/actions/addPaxRates";
/**PROXOLAB LOGGER **/
export const ControlContent = (data) => {
  let errorString = "";
  data.forEach((error) => {
    errorString = errorString + "-" + error + "<br></br>";
  });
  return errorString;
};
const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

export const getContractService = (authLevel, companyID, page, rowsPerPage) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("getContract", {
      props: {
        authLevel: authLevel,
        companyID: companyID,
        page: page !== null ? page * rowsPerPage : null,
        rowsPerPage: rowsPerPage ? rowsPerPage : null,
      },
    })
    .then((res) => {
      dispatch(getContract(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getSearchedContractService = (
  authLevel,
  companyID,
  page,
  rowsPerPage,
  payload,
  e
) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axios
    .post("getSearchedContract", {
      props: {
        authLevel: authLevel,
        companyID: companyID,
        page: page * rowsPerPage,
        rowsPerPage: rowsPerPage,
        columnNames: payload.columnNames,
        columnValues: payload.columnNameWithValues,
        sorting: e ?? null,
      },
    })
    .then((res) => {
      dispatch(getContract(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getDashboardCount = (authLevel) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axios
    .post("getDashboardCount", {
      props: {
        authLevel: authLevel,
      },
    })
    .then((res) => {
      if (res.status == 200) {
        dispatch(fetchSuccess());
        return res.data;
      }
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getContractRoomsService = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoom/${id}`)
    .then((res) => {
      dispatch(getContractRoom(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const addContractRoomService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contractRoom", data)
    .then((res) => {
      dispatch(addCotractRoom(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      return res.data.code;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateContractRoomService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractRoom", data)
    .then((res) => {
      dispatch(updateCotractRoom(res));
      //addpaxın action reducerinin yapısına uyması için
      if (
        res.data.newInfant &&
        res.data.newInfant !== "" &&
        res.data.newInfant !== null &&
        res.data.newInfant !== undefined
      ) {
        if (res.data.newInfant.get_pax_rooms.length > 0) {
          res.data.newInfant["rooms"] = [];
          res.data.newInfant.get_pax_rates.map(
            (rate) => (res.data.newInfant[rate.period_id] = rate.value)
          );
          res.data.newInfant.get_pax_rooms.map((room) =>
            res.data.newInfant["rooms"].push(room.contract_room_id)
          );
          delete res.data.newInfant.get_pax_rates;
          delete res.data.newInfant.get_pax_rooms;
          dispatch(addAddPaxRates(res.data.newInfant));
        } else {
          dispatch(deleteAddPaxRates(res.data.newInfant.id));
        }
      }
      if (
        res.data.newChild &&
        res.data.newChild !== "" &&
        res.data.newChild.length > 0
      ) {
        if (res.data.newChild.length > 1) {
          res.data.newChild.map((child) => {
            if (child.get_pax_rooms.length > 0) {
              child["rooms"] = [];
              child.get_pax_rates.map(
                (rate) => (child[rate.period_id] = rate.value)
              );
              child.get_pax_rooms.map((room) =>
                child["rooms"].push(room.contract_room_id)
              );
              delete child.get_pax_rates;
              delete child.get_pax_rooms;
              dispatch(addAddPaxRates(child));
            } else {
              dispatch(deleteAddPaxRates(child.id));
            }
          });
        } else {
          if (res.data.newChild[0].get_pax_rooms.length > 0) {
            res.data.newChild[0]["rooms"] = [];
            res.data.newChild[0].get_pax_rates.map(
              (rate) => (res.data.newChild[0][rate.period_id] = rate.value)
            );
            res.data.newChild[0].get_pax_rooms.map((room) =>
              res.data.newChild[0]["rooms"].push(room.contract_room_id)
            );
            delete res.data.newChild[0].get_pax_rates;
            delete res.data.newChild[0].get_pax_rooms;
            dispatch(addAddPaxRates(res.data.newChild[0]));
          } else {
            dispatch(deleteAddPaxRates(res.data.newChild[0].id));
          }
        }
      }
      if (
        res.data.newAdult &&
        res.data.newAdult !== "" &&
        res.data.newAdult.length > 0
      ) {
        if (res.data.newAdult.length > 1) {
          res.data.newAdult.map((adult) => {
            if (adult.get_pax_rooms.length > 0) {
              adult["rooms"] = [];
              adult.get_pax_rates.map(
                (rate) => (adult[rate.period_id] = rate.value)
              );
              adult.get_pax_rooms.map((room) =>
                adult["rooms"].push(room.contract_room_id)
              );
              delete adult.get_pax_rates;
              delete adult.get_pax_rooms;
              dispatch(addAddPaxRates(adult));
            } else {
              dispatch(deleteAddPaxRates(adult.id));
            }
          });
        } else {
          if (res.data.newAdult[0].get_pax_rooms.length > 0) {
            res.data.newAdult[0]["rooms"] = [];
            res.data.newAdult[0].get_pax_rates.map(
              (rate) => (res.data.newAdult[0][rate.period_id] = rate.value)
            );
            res.data.newAdult[0].get_pax_rooms.map((room) =>
              res.data.newAdult[0]["rooms"].push(room.contract_room_id)
            );
            delete res.data.newAdult[0].get_pax_rates;
            delete res.data.newAdult[0].get_pax_rooms;
            dispatch(addAddPaxRates(res.data.newAdult[0]));
          } else {
            dispatch(deleteAddPaxRates(res.data.newAdult[0].id));
          }
        }
      }
      if (
        res.data.rateUpdate &&
        res.data.rateUpdate !== "" &&
        res.data.rateUpdate.length > 0
      ) {
        if (res.data.rateUpdate.length > 1) {
          res.data.rateUpdate.map((adult) => {
            if (adult.get_pax_rooms.length > 0) {
              adult["rooms"] = [];
              adult.get_pax_rates.map(
                (rate) => (adult[rate.period_id] = rate.value)
              );
              adult.get_pax_rooms.map((room) =>
                adult["rooms"].push(room.contract_room_id)
              );
              delete adult.get_pax_rates;
              delete adult.get_pax_rooms;
              dispatch(addAddPaxRates(adult));
            } else {
              dispatch(deleteAddPaxRates(adult.id));
            }
          });
        } else {
          if (res.data.rateUpdate[0].get_pax_rooms.length > 0) {
            res.data.rateUpdate[0]["rooms"] = [];
            res.data.rateUpdate[0].get_pax_rates.map(
              (rate) => (res.data.rateUpdate[0][rate.period_id] = rate.value)
            );
            res.data.rateUpdate[0].get_pax_rooms.map((room) =>
              res.data.rateUpdate[0]["rooms"].push(room.contract_room_id)
            );
            delete res.data.rateUpdate[0].get_pax_rates;
            delete res.data.rateUpdate[0].get_pax_rooms;
            dispatch(addAddPaxRates(res.data.rateUpdate[0]));
          } else {
            dispatch(deleteAddPaxRates(res.data.rateUpdate[0].id));
          }
        }
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      //sweetAlerts("success", "Contract Room Updated!");
      return res.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteContractRoomService = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`contractRoom/${id}`)
    .then((res) => {
      dispatch(deleteCotractRoom(res));
      //res.data.newInfant=>oda silinirken default kaydedilen infattan da silinmesi için
      //addpaxın action reducerinin yapısına uyması için
      if (res.data.newInfant) {
        if (res.data.newInfant.get_pax_rooms.length > 0) {
          res.data.newInfant["rooms"] = [];
          res.data.newInfant.get_pax_rates.map(
            (rate) => (res.data.newInfant[rate.period_id] = rate.value)
          );
          res.data.newInfant.get_pax_rooms.map((room) =>
            res.data.newInfant["rooms"].push(room.contract_room_id)
          );
          delete res.data.newInfant.get_pax_rates;
          delete res.data.newInfant.get_pax_rooms;
          dispatch(addAddPaxRates(res.data.newInfant));
        } else {
          dispatch(deleteAddPaxRates(res.data.newInfant.id));
        }
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, id);
      sweetAlerts("success", "Contract Room Deleted!");
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
export const controlContractRoomRateType = (contract_id, room_id) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoomUpdateControl/${contract_id}/${room_id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      return res.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const contractRoomBoardControl = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post(`contractRoomBoardControl`, data)
    .then((res) => {
      dispatch(fetchSuccess());
      return res.data.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
export const addContractService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("contract", data)
    .then((res) => {
      dispatch(addContract(res));
      dispatch({ type: SET_SELECTED_CONTRACT, payload: res.data.data.id });
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const updateContractService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contract", data)
    .then((res) => {
      if (res.data.message == "Not updated") {
        dispatch(fetchSuccess());

        return res;
      } else {
        dispatch(updateContract(res));
        dispatch(fetchSuccess());
        sendLogService.sendContractServiceLog(res, data);
      }
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const changeContractStatusService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("contractStatus", data)
    .then((res) => {
      dispatch(changeContractStatus(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractStatusServiceLog(res, data);
      if (!(data.inf && data.inf == "no_alert")) {
        sweetAlerts(
          "success",
          `Contract saved as ${res.data.data.contract_status[0].name.toLowerCase()}.`
        );
      }
      return res.data.code;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const copyContractService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("copyContract", data)
    .then((res) => {
      if (res.data.code == 500) {
        sweetAlerts("error", `${res.data.message}`);
        dispatch(fetchError(res.data.message));
        return res;
      } else {
        dispatch(copyContract(res));
        dispatch(fetchSuccess());
        sweetAlerts("success", "Contract Copied Successfully!");
        sendLogService.sendContractServiceLog(res, data);
        return res;
      }
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const ContractConfirmControlService = (data) => async (dispatch) => {
  let code = 0;
  //status id draft modunda yollanmıyor.sadece on Sale modunda yollanıyor
  const text = data.status_id
    ? "Satışa Açmak ister misiniz?"
    : "Konfirm etmek ister misiniz?";
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const res = await axios
    .post("confirmControl", {
      id: data.id,
    })
    .then((res) => {
      return res.data;
    });
  if (res.data.length != 0) {
    await Swal.fire({
      icon: "warning",
      html: ControlContent(res.data),
    });
  } else {
    let ConfimStatusData = {
      contract_id: data.id,
      status_id: data.status_id ? data.status_id : 2,
    };
    //dönen datada sub kontroller varsa
    if (res.sub_control.length != 0) {
      await Swal.fire({
        icon: "info",
        html: ControlContent(res.sub_control),
        title: "Kontrata Eksik Veriler Var." + text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Evet",
        denyButtonText: `Hayır`,
      }).then(async (result) => {
        //sub kontroller onaylanırsa
        if (result.isConfirmed) {
          //eğer onSaleye çıkılacaksa else girer. changeContractStatusService isteği
          // botlara atılan istekten sonra yollanacağı için burada yollanmıyor
          if (!data.status_id) {
            await dispatch(changeContractStatusService(ConfimStatusData)).then(
              (resp) => {
                code = resp;
              }
            );
          } else {
            code = 302;
          }
        }
        //sub kontroller reddedilirse
        else {
          code = 400;
        }
      });
    } else {
      //sub kontrol yoksa ve on saleye çıkılmıyorsa
      if (!data.status_id) {
        await dispatch(changeContractStatusService(ConfimStatusData)).then(
          (resp) => {
            code = resp;
          }
        );
      } else {
        //sub kontrol yoksa ve on saleye çıkılıyorsa
        code = 302;
      }
    }
  }
  //status id on Sale modunda yollanıyor bu yüzden 200 haricinde bir kod yollanıyor yukarıdaki
  //if ve elsede status idnin kontrol edilme sebebi önce botların başarılı bir şekilde çalışması
  //daha sonra statunun değiştirilmesi
  //componentin içinde botlardan dönen response başarılı olursa changeContractStatusService'e istek atılıyor.
  return data.status_id ? (res.data.length != 0 ? 400 : code) : code;
};
export const controlContractRoominUseService = (contractId, id) => (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get(`contractRoomDeleteControl/${contractId}/${id}`)
    .then((res) => {
      let control = false;
      if (res.data.data.length == 0) {
        control = true;
      } else {
        Swal.fire({
          icon: "warning",
          html: ControlContent(res.data.data),
        });
        control = false;
      }
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, id);
      return control;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};

export const addExcelContractService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("excelContract", data)
    .then((res) => {
      dispatch(addContract(res));
      dispatch({ type: SET_SELECTED_CONTRACT, payload: res.data.data.id });
      dispatch(fetchSuccess());
      sendLogService.sendContractServiceLog(res, data);
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const addExcelContractRoomService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("excelContractRoom", data)
    .then((res) => {
      dispatch(addCotractRoom(res));
      dispatch(fetchSuccess());
      sendLogService.sendContractRoomServiceLog(res, data);
      return res.data.code;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};
export const addMeetingPointExcelContractRoomService = (data) => async (
  dispatch
) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  try {
    const res = await axios.post("createxcelContractAllRooms", data);

    await dispatch(addCotractRoom(res));
    dispatch(fetchSuccess());
    sendLogService.sendContractRoomServiceLog(res, data);
    return res.data.code;
  } catch (e) {
    dispatch(fetchError(e.message));
    sendLogService.errorlog(e, data);
  }
};

export const controlContractConfilict = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (data["include_countries"]) {
    if (typeof data["include_countries"][0] == "object") {
      data["include_countries"] = data["include_countries"].map(
        (item) => item.id
      );
    }
  }

  dispatch(fetchStart());
  return axios
    .post("contractConfilict", data)
    .then((res) => {
      dispatch(fetchSuccess());
      return res.data;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};
