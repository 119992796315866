import { normalize, schema } from 'normalizr';

const boards = new schema.Entity(
  'boards',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  boards: [boards],
  addBoard: [boards],
  stateBoard: [boards],
  updatedBoard: [boards],
};
export const normalizeBoards = data => normalize(data, [response]);