import React from "react";
import {
  ArrowForward,
  People,
  Edit,
  Settings,
  HomeWork
} from "@material-ui/icons";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import IntlMessages from "../../../utils/IntlMessages";
import DescriptionIcon from "@mui/icons-material/Description";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Swal from "sweetalert2";
import HomeIcon from "@mui/icons-material/Home";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";

const logoutFunc = () => {
  Swal.fire({
    title: "Are you sure you want to log out?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes!",
    cancelButtonText: "No!",
    reverseButtons: true,
    confirmButtonColor: "green",
    cancelButtonColor: "#d33"
  }).then(result => {
    if (result.isConfirmed) {
      localStorage.removeItem("token");
      localStorage.removeItem("selectedCompany");
      localStorage.removeItem("userCompanies");
      localStorage.removeItem("selectedOffice");
      localStorage.removeItem("userOffices");
      localStorage.removeItem("selectedCompanyName");
      return (window.location = "/login");
    }
  });
};

export const sidebarNavs = companyName => {
  return [
    {
      name: "",
      icon: <HomeIcon />,
      type: "section",
      children: [
        {
          name:
            companyName.length > 0
              ? companyName
              : localStorage.getItem("selectedCompanyName"),
          icon: <HomeIcon />,
          link: "/dashboard"
        }
      ]
    },

    {
      name: "",
      icon: <People />,
      type: "section",
      children: [
        {
          name: <IntlMessages id="admin.operations" />,
          type: "item",
          icon: <People />,
          link: "/admin"
        }
      ]
    },
    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="product.definitions" />,
          type: "collapse",
          icon: <Edit />,
          children: [
            {
              name: <IntlMessages id="definitions" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/definitions"
            },
            {
              name: <IntlMessages id="markup.management" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/definition/markup"
            },
            {
              name: <IntlMessages id="cancellation.policies" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/definition/cancellationpolicies"
            },
            {
              name: <IntlMessages id="terms.and.conditions" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/definition/termsConditions"
            }
          ]
        }
      ]
    },

    {
      name: "",
      icon: <RateReviewIcon />,
      type: "section",
      children: [
        {
          name: <IntlMessages id="new.contract" />,
          type: "item",
          icon: <RateReviewIcon />,
          link: "/contract/newcontract"
        }
      ]
    },
    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="bot.management" />,
          type: "collapse",
          icon: <SettingsEthernetIcon />,
          children: [
            {
              name: <IntlMessages id="extranet.credentials" />,
              type: "item",
              icon: <BackupTableIcon />,
              link: "/botmanagement/extranetcredentials"
            },
            {
              name: <IntlMessages id="hotel.map" />,
              type: "item",
              icon: <LocationCityIcon />,
              link: "/botmanagement/hotelmap"
            },
            {
              name: <IntlMessages id="room.map" />,
              type: "item",
              icon: <MeetingRoomIcon />,
              link: "/botmanagement/roommap"
            },
            // {
            //   name: <IntlMessages id="board.map" />,
            //   type: "item",
            //   icon: <LocalHotelIcon />,
            //   link: "/botmanagement/boardmap"
            // }
          ]
        },
        {
          name: <IntlMessages id="sale.management" />,
          type: "collapse",
          icon: <Settings />,
          children: [
            // {
            //   name: <IntlMessages id="Allotment Update" />,
            //   type: "item",
            //   icon: <ArrowForward />,
            //   link: "/salemanagement/allotmentUpdate"
            // },
            {
              name: <IntlMessages id="open/stop.sale" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/salemanagement/openstopsale"
            },
            {
              name: <IntlMessages id="offers" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/salemanagement/offers"
            },
            {
              name: <IntlMessages id="allotment.list" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/salemanagement/allotmentList"
            }
          ]
        }
      ]
    },
    // {
    //   name: "",
    //   type: "section",
    //   children: [
    //     // {
    //     //   name: <IntlMessages id="calculate.reservation" />,
    //     //   type: "item",
    //     //   icon: <ArrowForward />,
    //     //   link: "/reservation/calculate"
    //     // }
    //     // {
    //     //   name: <IntlMessages id="reservation.operations" />,
    //     //   type: "collapse",
    //     //   icon: <LocalLibraryIcon />,
    //     //   children: [
    //     //     {
    //     //       name: <IntlMessages id="new.reservation" />,
    //     //       type: "item",
    //     //       icon: <ArrowForward />,
    //     //       link: "/reservation/create"
    //     //     },
    //     //     {
    //     //       name: <IntlMessages id="show.reservation" />,
    //     //       type: "item",
    //     //       icon: <ArrowForward />,
    //     //       link: "/reservation/show"
    //     //     },
    //     //   ]
    //     // }
    //   ],
    // },
    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="reports.operations" />,
          type: "collapse",
          icon: <DescriptionIcon />,
          children: [
            {
              name: <IntlMessages id="reservation.report" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/reports/reservationReport"
            },
            {
              name: <IntlMessages id="contract.report" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/reports/contractReport"
            },
            {
              name: <IntlMessages id="hotel.report" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/reports/hotelReport"
            }
            /*   {
                name: <IntlMessages id="accounting.report" />,
                type: "item",
                icon: <ArrowForward />,
                link: "/reports/accountingReport"
              } */
          ]
        }
      ]
    },

    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="payment.operations" />,
          type: "collapse",
          icon: <PaymentIcon />,
          children: [
            {
              name: <IntlMessages id="balance.transactions" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/payment/balanceTransactions"
            },
            {
              name: <IntlMessages id="transaction.informations" />,
              type: "item",
              icon: <ArrowForward />,
              link: "/payment/transactionInformations"
            }
          ]
        }
      ]
    },

    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="help" />,
          type: "collapse",
          icon: <SupportAgentIcon />,
          children: [
            {
              name: <IntlMessages id="whatsapp.support" />,
              type: "item",
              icon: <WhatsAppIcon />,
              link: "/whatsappsupport"
            },
            {
              name: <IntlMessages id="email" />,
              type: "item",
              icon: <LocalPostOfficeIcon />,
              link: "/sendmail"
            },
            {
              name: <IntlMessages id="sss" />,
              type: "item",
              icon: <QuestionMarkIcon />,
              link: "/sss"
            }
          ]
        }
      ]
    },

    {
      name: "",
      type: "section",
      children: [
        {
          name: <IntlMessages id="user.settings" />,
          type: "collapse",
          icon: <SettingsIcon />,
          children: [
            {
              name: <IntlMessages id="profile" />,
              type: "item",
              icon: <PersonIcon />,
              link: "/profile"
            }
          ]
        }
      ]
    },
    {
      name: "",
      icon: <PersonIcon />,
      type: "section",
      children: [
        {
          name: <IntlMessages id="logout" />,
          type: "item",
          link: "/logout",
          icon: <LogoutIcon />,
          onClickFunc: logoutFunc
        }
      ]
    }
  ];
};

export const sidebarNavsOfficeUser = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: localStorage.getItem("selectedCompanyName"),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },

  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "item",
        icon: <People />,
        link: "/admin"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="bot.management" />,
        type: "collapse",
        icon: <SettingsEthernetIcon />,
        children: [
          {
            name: <IntlMessages id="extranet.credentials" />,
            type: "item",
            icon: <BackupTableIcon />,
            link: "/botmanagement/extranetcredentials"
          },
          {
            name: <IntlMessages id="hotel.map" />,
            type: "item",
            icon: <LocationCityIcon />,
            link: "/botmanagement/hotelmap"
          },
          {
            name: <IntlMessages id="room.map" />,
            type: "item",
            icon: <MeetingRoomIcon />,
            link: "/botmanagement/roommap"
          },
          // {
          //   name: <IntlMessages id="board.map" />,
          //   type: "item",
          //   icon: <LocalHotelIcon />,
          //   link: "/botmanagement/boardmap"
          // }
        ]
      },
      {
        name: "",
        type: "collapse",
        icon: <Settings />,
        children: [
          // {
          //   name: <IntlMessages id="Allotment Update" />,
          //   type: "item",
          //   icon: <ArrowForward />,
          //   link: "/salemanagement/allotmentUpdate"
          // },
          {
            name: <IntlMessages id="open/stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="offers" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/offers"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          },
          {
            name: <IntlMessages id="accounting.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/accountingReport"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="payment.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="balance.transactions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/payment/balance-transactions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="sss" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: localStorage.getItem("selectedCompanyName"),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },

  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "item",
        icon: <People />,
        link: "/admin"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="bot.management" />,
        type: "collapse",
        icon: <SettingsEthernetIcon />,
        children: [
          {
            name: <IntlMessages id="extranet.credentials" />,
            type: "item",
            icon: <BackupTableIcon />,
            link: "/botmanagement/extranetcredentials"
          },
          {
            name: <IntlMessages id="hotel.map" />,
            type: "item",
            icon: <LocationCityIcon />,
            link: "/botmanagement/hotelmap"
          },
          {
            name: <IntlMessages id="room.map" />,
            type: "item",
            icon: <MeetingRoomIcon />,
            link: "/botmanagement/roommap"
          },
          // {
          //   name: <IntlMessages id="board.map" />,
          //   type: "item",
          //   icon: <LocalHotelIcon />,
          //   link: "/botmanagement/boardmap"
          // }
        ]
      },
      {
        name: "",
        type: "collapse",
        icon: <Settings />,
        children: [
          // {
          //   name: <IntlMessages id="Allotment Update" />,
          //   type: "item",
          //   icon: <ArrowForward />,
          //   link: "/salemanagement/allotmentUpdate"
          // },
          {
            name: <IntlMessages id="open/stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="offers" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/offers"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          },
          {
            name: <IntlMessages id="accounting.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/accountingReport"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="payment.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="balance.transactions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/payment/balance-transactions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="sss" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: "",
    icon: <HomeIcon />,
    type: "section",
    children: [
      {
        name: localStorage.getItem("selectedCompanyName"),
        type: "item",
        icon: <HomeIcon />,
        link: "/dashboard"
      }
    ]
  },

  {
    name: "",
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="admin.operations" />,
        type: "item",
        icon: <People />,
        link: "/admin"
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="product.definitions" />,
        type: "collapse",
        icon: <Edit />,
        children: [
          {
            name: <IntlMessages id="definitions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/definitions"
          },
          {
            name: <IntlMessages id="markup.management" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/markup"
          },
          {
            name: <IntlMessages id="cancellation.policies" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/cancellationpolicies"
          },
          {
            name: <IntlMessages id="terms.and.conditions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/contract/termsConditions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    icon: <RateReviewIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="new.contract" />,
        type: "item",
        icon: <RateReviewIcon />,
        link: "/contract/newcontract"
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="bot.management" />,
        type: "collapse",
        icon: <SettingsEthernetIcon />,
        children: [
          {
            name: <IntlMessages id="extranet.credentials" />,
            type: "item",
            icon: <BackupTableIcon />,
            link: "/botmanagement/extranetcredentials"
          },
          {
            name: <IntlMessages id="hotel.map" />,
            type: "item",
            icon: <LocationCityIcon />,
            link: "/botmanagement/hotelmap"
          },
          {
            name: <IntlMessages id="room.map" />,
            type: "item",
            icon: <MeetingRoomIcon />,
            link: "/botmanagement/roommap"
          },
          // {
          //   name: <IntlMessages id="board.map" />,
          //   type: "item",
          //   icon: <LocalHotelIcon />,
          //   link: "/botmanagement/boardmap"
          // }
        ]
      },
      {
        name: "",
        type: "collapse",
        icon: <Settings />,
        children: [
          // {
          //   name: <IntlMessages id="Allotment Update" />,
          //   type: "item",
          //   icon: <ArrowForward />,
          //   link: "/salemanagement/allotmentUpdate"
          // },
          {
            name: <IntlMessages id="open/stop.sale" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/openstopsale"
          },
          {
            name: <IntlMessages id="offers" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/offers"
          },
          {
            name: <IntlMessages id="allotment.list" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/salemanagement/allotmentList"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reservation.operations" />,
        type: "collapse",
        icon: <LocalLibraryIcon />,
        children: [
          {
            name: <IntlMessages id="new.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/create"
          },
          {
            name: <IntlMessages id="show.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/show"
          },
          {
            name: <IntlMessages id="calculate.reservation" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reservation/calculate"
          }
        ]
      }
    ]
  },
  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="reports.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="reservation.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/reservationReport"
          },
          {
            name: <IntlMessages id="contract.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/contractReport"
          },
          {
            name: <IntlMessages id="hotel.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/hotelReport"
          },
          {
            name: <IntlMessages id="accounting.report" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/reports/accountingReport"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="payment.operations" />,
        type: "collapse",
        icon: <DescriptionIcon />,
        children: [
          {
            name: <IntlMessages id="balance.transactions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/payment/balance-transactions"
          },
          {
            name: <IntlMessages id="balance.transactions" />,
            type: "item",
            icon: <ArrowForward />,
            link: "/payment/balance-transactions"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="help" />,
        type: "collapse",
        icon: <SupportAgentIcon />,
        children: [
          {
            name: <IntlMessages id="whatsapp.support" />,
            type: "item",
            icon: <WhatsAppIcon />,
            link: "/whatsappsupport"
          },
          {
            name: <IntlMessages id="email" />,
            type: "item",
            icon: <LocalPostOfficeIcon />,
            link: "/sendmail"
          },
          {
            name: <IntlMessages id="sss" />,
            type: "item",
            icon: <QuestionMarkIcon />,
            link: "/sss"
          }
        ]
      }
    ]
  },

  {
    name: "",
    type: "section",
    children: [
      {
        name: <IntlMessages id="user.settings" />,
        type: "collapse",
        icon: <SettingsIcon />,
        children: [
          {
            name: <IntlMessages id="profile" />,
            type: "item",
            icon: <PersonIcon />,
            link: "/profile"
          }
        ]
      }
    ]
  },
  {
    name: "",
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        link: "/logout",
        icon: <LogoutIcon />,
        onClickFunc: logoutFunc
      }
    ]
  }
];

export const masterSidebarNavs = [
  {
    name: <IntlMessages id="profile" />,
    icon: <PersonIcon />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="logout" />,
        type: "item",
        icon: <LogoutIcon />,
        link: "/master/logout",
        onClickFunc: logoutFunc
      }
    ]
  },
  {
    name: <IntlMessages id="master.operations" />,
    icon: <People />,
    type: "section",
    children: [
      {
        name: <IntlMessages id="company" />,
        type: "item",
        icon: <HomeWork />,
        link: "/master/company"
      },
      {
        name: <IntlMessages id="users" />,
        type: "item",
        icon: <People />,
        link: "/master/user"
      },
      {
        name: <IntlMessages id="logger" />,
        type: "item",
        icon: <SettingsIcon />,
        link: "/master/logger"
      }
    ]
  }
];
