import { normalizeServices } from 'redux/schemas/service';
import { GET_SERVICES, ADD_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../actions/types';

const initialState = {
  services: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES:
      const data = [];
      data['services'] = action.payload;
      const normalizeService = normalizeServices([data]);
      return {
        ...state,
        services: normalizeService.entities.services ? normalizeService.entities.services : [],
      };
    case ADD_SERVICE:
      const addData = [];
      addData['services'] = [action.payload];
      addData['addStateServices'] = state.services;
      const addNormalizeService = normalizeServices([addData]);
      return {
        services: addNormalizeService.entities.services,
      };
    case UPDATE_SERVICE:
      const updatedData = [];
      updatedData['updateStateServices'] = [action.payload];
      const UpdatedNormalizeData = normalizeServices([updatedData]);
      const updatedService = UpdatedNormalizeData.result[0].updateStateServices[0];

      return {
        ...state,
        services: {
          ...state.services,
          [updatedService]: UpdatedNormalizeData.entities.services[updatedService],
        },
      };

    case DELETE_SERVICE:
      const deletedServices = Object.keys(state.services)
        .filter(id => Number(id) !== action.payload.id)
        .reduce((obj, key) => {
          obj[key] = state.services[key];
          return obj;
        }, {});
      return {
        ...state,
        services: deletedServices,
      };
    default:
      return state;
  }
};
