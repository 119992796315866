import { GET_OPERATOR, ADD_OPERATOR, UPDATE_OPERATOR, DELETE_OPERATOR } from './types';

export const getOperator = res => dispatch => {
  dispatch({
    type: GET_OPERATOR,
    payload: res.data.data,
  });
};

export const addOperator = res => dispatch =>
  dispatch({
    type: ADD_OPERATOR,
    payload: res.data.data,
  });

export const updateOperator = res => dispatch =>
  dispatch({
    type: UPDATE_OPERATOR,
    payload: res.data.data,
  });

export const deleteOperator = res => dispatch =>
  dispatch({
    type: DELETE_OPERATOR,
    payload: res.data.data,
  });
