import React, { useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addServiceService,
  deleteServiceService,
  getServiceService,
  updateServiceService,
} from "services/services";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, InputBase } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { getAllExtraService } from "services/extras";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400],
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  Input: {
    fontSize: "15px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
}));

export default function NewService({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (Object.values(services).length === 0) {
      dispatch(getServiceService());
    }
    if (Object.values(allExtras).length === 0) {
      dispatch(getAllExtraService());
    }
  }, [dispatch]);

  const classes = useStyles();

  const tableRef = React.createRef();
  const { services } = useSelector(({ services }) => services);
  const { allExtras } = useSelector(({ extras }) => extras);

  const tableColumns = [
    {
      title: intl.formatMessage({ id: "code" }),
      field: "code",
      width: "20%",
      lookup: services.code,
      validate: (rowData) => (rowData.code ? true : false),
    },
    {
      title: intl.formatMessage({ id: "name" }),
      field: "name",
      width: "20%",
      lookup: services.name,
      validate: (rowData) => (rowData.name ? true : false),
    },
  ];
  const hasUsedService = (service_id) => {
    let has = true;
    if (
      Object.values(allExtras).filter(
        (extra) => extra.service_id === service_id
      ).length > 0
    ) {
      has = false;
    }
    return has;
  };
  return (
    <MaterialTable
      tableRef={tableRef}
      columns={tableColumns}
      data={Object.values(services)}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: "first",
        headerStyle: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: "You are not authorized",
            },
      ]}
      icons={{
        Add: (props) => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon style={{ color: "red" }} />,
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: (props) => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={(e) => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes",
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" }),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        ),
      }}
      editable={{
        deleteTooltip: (row) =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: (row) =>
          isAuthUpdate ? "Update" : "You are not authorized",
        isDeletable: (row) => (isAuthDelete ? true : false),
        isEditable: (row) => (isAuthUpdate ? true : false),
        // onRowUpdateCancelled: rowData => 'Row editing cancelled',

        onRowAdd: isAuthAdd
          ? (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  if (newData.code && newData.name) {
                    newData.code = newData.code.toUpperCase();
                    newData.name = newData.name.toUpperCase();
                    if (
                      Object.values(services).filter(
                        (service) => service.code === newData.code
                      ).length === 0 &&
                      Object.values(services).filter(
                        (service) => service.name === newData.name
                      ).length === 0
                    ) {
                      dispatch(addServiceService(newData));
                      resolve();
                    } else {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: intl.formatMessage({
                          id: "service.already.exists",
                        }),
                      });
                      resolve();
                    }
                  } else {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({ id: "please.fill.in.all.fields" }),
                      "error"
                    );
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (
                oldData.code !== newData.code ||
                oldData.name !== newData.name
              ) {
                if (newData.code && newData.name) {
                  if (
                    Object.values(services).filter(
                      (service) => service.code === newData.code
                    ).length === 0 ||
                    Object.values(services).filter(
                      (service) => service.name === newData.name
                    ).length === 0
                  ) {
                    newData.code = newData.code.toUpperCase();
                    newData.name = newData.name.toUpperCase();
                    dispatch(updateServiceService(newData));
                    resolve();
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: intl.formatMessage({
                        id: "service.already.exists",
                      }),
                    });
                    resolve();
                  }
                } else {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "please.fill.in.all.fields" }),
                    "error"
                  );
                  resolve();
                }
              } else {
                MySwal.fire(
                  "Oops...",
                  intl.formatMessage({ id: "please.fill.in.all.fields" }),
                  "error"
                );
                resolve();
              }
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (hasUsedService(oldData.id) === true) {
                dispatch(deleteServiceService(oldData.id));
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: intl.formatMessage({ id: "service.in.use" }),
                });
              }
              resolve();
            }, 1000);
          }),
      }}
    />
  );
}
