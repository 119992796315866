import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { CardActions, IconButton, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  TableContainer,
  Paper,
  Grid,
  InputLabel,
  Input
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  expand: {
    maxHeight: "30px",
    transform: "rotate(0deg)",
    textAlign: "left",
    marginLeft: "auto",
    fontSize: 11,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    maxHeight: "30px",
    fontSize: 11,
    transform: "rotate(180deg)"
  },
  root: {
    minWidth: "100%",
    fontSize: 11,
    height: "100%",
    padding: "12px"
  },
  bottomNavigate: {
    width: 400,
    fontSize: 11,
    backgroundColor: theme.palette.background.default
  },
  rootCard: {
    minWidth: 600,
    fontSize: 11,
    margin: "0 auto"
  },
  cardContent: {
    padding: 4,
    textAlign: "left",
    "&:last-child": {
      paddingBottom: 2
    }
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: "48%",
    fontSize: 11,
    marginTop: "0.1%",
    marginBottom: "2%",

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      backgroundColor: "white",
      marginTop: "-5px"
    }
  },
  formControlTextField: {
    marginTop: "0.1%",
    margin: theme.spacing(4),
    fontSize: 11,
    minWidth: "94%"
  },
  title: {
    marginTop: "1%",
    fontSize: 11,
    margin: theme.spacing(4)
  },
  textStyle: {
    //margin: theme.spacing(2),
    //marginTop: '0.2%',
    fontSize: 11
  },
  cardActions: {
    fontSize: 11,
    maxWidth: "94%",
    display: "block",
    cursor: "pointer"
  },
  button: {
    fontSize: 11,
    margin: "0 auto",
    marginLeft: "10px"
  },
  addRoomButton: {
    backgroundColor: "cadetblue",
    fontSize: 10
  },
  buttonCard: {
    fontSize: 11,
    marginTop: "0.6%"
  },
  textField: {
    minWidth: 450,
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "black"
    }
  },
  textFieldiforDetail: {
    minWidth: 450,
    maxHeight: 30
  },
  formInCard: {
    minWidth: 100,
    fontSize: 10,
    margin: "0 auto",
    maxHeight: "55px",
    align: "center"
  },
  numberColumns: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  collapse: {
    margin: "0 auto"
  },

  container: {
    paddingBottom: 4,
    paddingRight: 4,
    marginTop: 4,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    maxHeight: 400
  }
}));

export default function ContractCompanyDetails({
  contracts,
  selectedContract
}) {
  const classes = useStyles();

  const [officeExpanded, setOfficeExpanded] = useState(false);
  const handleExpandOfficeClick = () => {
    setOfficeExpanded(!officeExpanded);
  };

  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const companyInfo =
    entities.companies[contracts[selectedContract].company_id];

  return (
    <FormControl className={classes.formControl}>
      <Card className={classes.rootCard}>
        <CardContent className={classes.cardContent}>
          <FormControl
            className={classes.formInCard}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <TextField
              disabled
              id="outlined-disabled"
              margin="dense"
              inputProps={{ style: { textAlign: "center" } }}
              variant="outlined"
              label={<IntlMessages id="company" />}
              // fullWidth
              //defaultValue="KEYF TRAVEL"
              value={companyInfo?.name}
              className={classes.textField}
            />

            {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
              Office Details
            </Typography> */}

            <CardActions className={classes.cardActions}>
              <Button
                style={{ fontSize: 11, maxHeight: "45px" }}
                size="small"
                onClick={handleExpandOfficeClick}
              >
                <IntlMessages id="company.details" />
                <IconButton
                  className={
                    (classes.expand,
                    {
                      [classes.expandOpen]: officeExpanded
                    })
                  }
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Button>
            </CardActions>
          </FormControl>
          <Collapse in={officeExpanded} timeout="auto">
            <Card variant="outlined">
              <CardContent className={classes.content}>
                <Grid container className={classes.container} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="name-helper">
                        <IntlMessages id="name" />
                      </InputLabel>
                      <Input
                        disabled
                        id="name-helper"
                        variant="outlined"
                        label={<IntlMessages id="name" />}
                        defaultValue={companyInfo?.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="taxno-helper">
                        <IntlMessages id="phone" />
                      </InputLabel>
                      <Input
                        disabled
                        id="taxno-helper"
                        variant="outlined"
                        label={<IntlMessages id="phone" />}
                        fullWidth
                        defaultValue={companyInfo?.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="taxoffice-helper">
                        <IntlMessages id="email" />
                      </InputLabel>
                      <Input
                        disabled
                        id="taxoffice-helper"
                        variant="outlined"
                        label={<IntlMessages id="email" />}
                        fullWidth
                        defaultValue={companyInfo?.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="name-helper">
                        <IntlMessages id="tax.office" />
                      </InputLabel>
                      <Input
                        disabled
                        id="type-helper"
                        variant="outlined"
                        label={<IntlMessages id="tax.office" />}
                        defaultValue={companyInfo?.tax_office}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="email-helper">
                        <IntlMessages id="tax.no" />
                      </InputLabel>
                      <Input
                        disabled
                        id="email-helper"
                        variant="outlined"
                        label={<IntlMessages id="tax.no" />}
                        fullWidth
                        defaultValue={companyInfo?.tax_no}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="name-helper">
                        <IntlMessages id="continent" />
                      </InputLabel>
                      <Input
                        disabled
                        id="type-helper"
                        variant="outlined"
                        label={<IntlMessages id="continent" />}
                        defaultValue={
                          entities.regions[companyInfo?.region_id]?.name
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="email-helper">
                        <IntlMessages id="country" />
                      </InputLabel>
                      <Input
                        disabled
                        id="email-helper"
                        variant="outlined"
                        label={<IntlMessages id="country" />}
                        fullWidth
                        defaultValue={
                          entities.country[companyInfo?.country_id]?.name
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.textStyle}>
                      <InputLabel htmlFor="name-helper">
                        <IntlMessages id="city" />
                      </InputLabel>
                      <Input
                        disabled
                        id="type-helper"
                        variant="outlined"
                        label={<IntlMessages id="city" />}
                        defaultValue={entities.city[companyInfo?.city_id]?.name}
                      />
                    </FormControl>
                  </Grid>
                  {companyInfo?.subcity_id ? (
                    <Grid item xs={6}>
                      <FormControl fullWidth className={classes.textStyle}>
                        <InputLabel htmlFor="email-helper">
                          <IntlMessages id="sub.city" />
                        </InputLabel>
                        <Input
                          disabled
                          id="email-helper"
                          variant="outlined"
                          label={<IntlMessages id="sub.city" />}
                          fullWidth
                          defaultValue={
                            entities.sub_city
                              ? entities.sub_city[companyInfo?.subcity_id]?.name
                              : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Collapse>
        </CardContent>
      </Card>
    </FormControl>
  );
}

export const MemoizedContractCompanyDetails = React.memo(
  ContractCompanyDetails
);
