/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtGridView from '@coremat/CmtGridView';
import { THEME_TYPE_OPTIONS } from '@jumbo/constants/CustomizerOptions';
import CmtImage from '@coremat/CmtImage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import CmtCard from '@coremat/CmtCard';
import { THEME_TYPES } from '../../../../../constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      padding: '4px 16px',
    },
    '& .Cmt-card-content': {
      padding: 16,
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    fontSize: 14,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  subTitleRoot: {
    letterSpacing: 0.4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 0,
  },
}));

const ThemeItem = ({ item }) => {
  const classes = useStyles();
  const { themeType, setThemeType } = useContext(AppContext);

  const onResetTheme = () => {
    localStorage.setItem('theme-type', 'light');
    setThemeType('light');
  };

  const onSetThemeType = type => {
    localStorage.setItem('theme-type', type);
    setThemeType(type);
  };
  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title="Theme">
        <Button onClick={onResetTheme}>RESET</Button>
      </CmtCardHeader>
      <CmtCardContent>
        <CmtGridView
          itemPadding={16}
          data={THEME_TYPE_OPTIONS}
          renderRow={(item, index) => (
            <div className="pointer" onClick={() => onSetThemeType(item.type)}>
              <div style={{ position: 'relative' }}>
                <CmtImage key={index} src={item.image} alt={item.title} />
                {themeType === item.type && (
                  // <h1>{themeType} | {item.type}</h1>
                  <CheckCircleIcon className={classes.checkIcon} />
                )}
              </div>
              <div className="mt-2">{item.title}</div>
            </div>
          )}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default ThemeItem;
