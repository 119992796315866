import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { getHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, lightBlue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import {
  getMatchHotelDataService,
  addMatchHotelDataService,
  deleteMatchHotelDataService
} from "services/hotelmatch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import { getOperatorHotelsService } from "services/operatorhotels";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HotelMatchModal from "../HotelMatchModal";
import Loader from "../Loader";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import swal from "sweetalert";
import axios from "services/axios";
import { getOperatorService } from "services/operator";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});
const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

export default function HotelMatch({ hotelInfo, setShowFirstRoomMatchPage }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  let operatorIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,15,16];
  const operators = [
    "PP",
    "SH",
    "DN",
    "WB",
    "LMX",
    "FOR",
    "SIT",
    "WEBBEDS",
    "JUMBO",
    "LMT"
  ];
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState({});
  useEffect(() => {
    dispatch(getOperatorHotelsService(operatorIds));
    dispatch(getHotelService());
    dispatch(getMatchHotelDataService());
  }, []);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { SHhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { DNhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { PPhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { LMXhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { WBhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { SIThotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { FORhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { WEBBEDShotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { JUMBOhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { LMThotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);

  /******************THIS SECTION MADE FOR PREVENT WHEN PAGE RELOAD DATA THAT INSIDE REDUX HAS BEEN DELETING*************************/
  let hotelObj = {};

  hotelObj.sunHotels = SHhotels ? SHhotels : [];
  hotelObj.dnhotels = DNhotels ? DNhotels : [];
  hotelObj.pphotels = PPhotels ? PPhotels : [];
  hotelObj.lmxhotels = LMXhotels ? LMXhotels : [];
  hotelObj.wbhotels = WBhotels ? WBhotels : [];
  hotelObj.sithotels = SIThotels ? SIThotels : [];
  hotelObj.forhotels = FORhotels ? FORhotels : [];
  hotelObj.webbedshotels = WEBBEDShotels ? WEBBEDShotels : [];
  hotelObj.jumbohotels = JUMBOhotels ? JUMBOhotels : [];
  hotelObj.lmthotels = LMThotels ? LMThotels : [];

  //Control Function have to return always false
  function controlHotelObjects() {
    if (hotelObj.sunHotels != undefined) {
      if (hotelObj.sunHotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.dnhotels != undefined) {
      if (hotelObj.dnhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.sithotels != undefined) {
      if (hotelObj.sithotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.lmxhotels != undefined) {
      if (hotelObj.lmxhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.wbhotels != undefined) {
      if (hotelObj.wbhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.pphotels != undefined) {
      if (hotelObj.pphotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.forhotels != undefined) {
      if (hotelObj.forhotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.webbedshotels != undefined) {
      if (hotelObj.webbedshotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.jumbohotels != undefined) {
      if (hotelObj.jumbohotels.length != 0) {
        return true;
      }
    }
    if (hotelObj.lmthotels != undefined) {
      if (hotelObj.lmthotels.length != 0) {
        return true;
      }
    }
    return false;
    // const cont =
    //   (hotelObj.hasOwnProperty("sunHotels") &&
    //     hotelObj.sunHotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("dnhotels") && hotelObj.dnhotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("sithotels") &&
    //     hotelObj.sithotels.length != 0) ||
    //   (hotelObj.hasOwnProperty("lmxhotels") && hotelObj.lmxhotels.length != 0);
    // return cont;
  }
  // IF REDUX STATE IS NULL DONT SAVE TO LOCALSTORAGE DATA ELSE NOT NULL SAVE TO LOCALSTORAGE DATA
  //This control is prevent data losing inside localstorage when page reloaded

  if (controlHotelObjects()) {
    localStorage.setItem("hotelMatch", JSON.stringify(hotelObj));
  }
  const newHotelObj = JSON.parse(localStorage.getItem("hotelMatch"));
  //WHEN COMPONENT UNMOUNT USEEFFECT RUN AND DELETE LOCALSTORAGE ------> localStorage.removeItem('hotelMatch')
  useEffect(() => {
    return () => {
      localStorage.removeItem("hotelMatch");
    };
  }, []);

  /***************************************************************************************************/

  //For alert messages function
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };

  const operatorNameFunc = operator => {
    let name =
      operator == "SH"
        ? "SUNHOTEL"
        : operator == "WB"
        ? "WELCOMEBEDS"
        : operator == "PP"
        ? "PEAKPOINT"
        : operator == "SIT"
        ? "SITALIA"
        : operator == "DN"
        ? "DNATA"
        : operator == "LMT"
        ? "LAST MINUTE"
        : operator;

    return name;
  };

  const handleOpen = data => {
    setRowData(data);
    setOpenModal(true);
  };

  const deleteFunc = id => {
    Swal.fire({
      title: intl.formatMessage({
        id: "are.you.sure.delete"
      }),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#41C329",
      allowOutsideClick: false,
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      cancelButtonText: intl.formatMessage({ id: "no" })
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteMatchHotelDataService(id));
      }
    });
  };

  const inputChange = useRef();
  const [searchInputValuee, setSearchInputValuee] = useState("");
  let lastResult = [];

  /********************* EXTRANET HOTEL SEARCH AREA *****************************/
  const extraNetInputChange = useRef();

  /******************************************************************************/
  const setMaterialTableData = () => {
    let returnParam = "";

    returnParam =
      Object.values(matched_hotels).find(
        hotel => hotels[hotel.global_hotel_id]?.name === hotelInfo.name
      ) || [];

    return returnParam.length > 0 ? [returnParam] : [];
  };

  if (inputChange.current?.value == "") {
    lastResult.length = 0;
  }
  return (
    <>
      <HotelMatchModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setShowFirstRoomMatchPage={setShowFirstRoomMatchPage}
      />
      <MaterialTable
        // columns={columns}
        data={setMaterialTableData()}
        title=""
        style={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          padding: "6px 24px 7px 0"
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: "first",
          tableLayout: "fixed"
        }}
        icons={{
          Add: props => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
                onClick={() => handleOpen({})}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          )
        }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                if (
                  (newData.hasOwnProperty("SH") && newData.SH.hotel_id) ||
                  (newData.hasOwnProperty("DN") && newData.DN.hotel_id) ||
                  (newData.hasOwnProperty("PP") && newData.PP.hotel_id) ||
                  (newData.hasOwnProperty("WB") && newData.WB.hotel_id) ||
                  (newData.hasOwnProperty("LMX") && newData.LMX.hotel_id) ||
                  (newData.hasOwnProperty("WEBBEDS") &&
                    newData.WEBBEDS.hotel_id) ||
                  (newData.hasOwnProperty("JUMBO") && newData.JUMBO.hotel_id) ||
                  (newData.hasOwnProperty("FOR") && newData.FOR.hotel_id) ||
                  (newData.hasOwnProperty("SIT") && newData.SIT.hotel_id) ||
                  (newData.hasOwnProperty("LMT") && newData.LMT.hotel_id)
                ) {
                  dispatch(addMatchHotelDataService(newData));
                } else {
                  sweetAlerts(
                    "warning",
                    "Please Select A Hotel From The Operator!"
                  );
                }
              }, 1000);
            })
        }}
        components={{
          OverlayLoading: () => <Loader />,
          Header: props => (
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
          ),
          Body: props =>
            Object.keys(matched_hotels).length == 0 ? (
              <h4
                style={{
                  position: "center",
                  marginLeft: "center",
                  textAlign: "center",
                  marginTop: "2rem"
                }}
              >
                <IntlMessages id="no.matched.global.hotel" />
              </h4>
            ) : (
              props.renderData.map(row => (
                <TableBody>
                  <TableRow>
                    <TableRow key={row.id}>
                      <TableCell
                        align="left"
                        colSpan={15}
                        style={{ backgroundColor: blue[400] }}
                      >
                        {hotels[row.global_hotel_id]?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* operator başlıkları var head içinde */}
                      <TableHead>
                        <TableRow>
                          {operators.map(dataKey => (
                            <TableCell
                              align="left"
                              colSpan={15}
                              style={{
                                border: "10px",
                                fontWeight: "bold",
                                backgroundColor: lightBlue[100],
                                width: (props.scrollWidth + 1000) / 7
                              }}
                            >
                              {operatorNameFunc(dataKey)}
                            </TableCell>
                          ))}
                          <TableCell
                            style={{
                              border: "10px",
                              fontWeight: "bold",
                              backgroundColor: lightBlue[100],
                              width: props.scrollWidth / 8
                            }}
                            align="right"
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {operators.map(operator => {
                          return (
                            <TableCell
                              align="left"
                              colSpan={15}
                              style={{
                                border: "10px",
                                fontWeight: "bold",
                                width: (props.scrollWidth + 1000) / 7,
                                overflowX: "hidden"
                              }}
                            >
                              {row[operator]?.hotel_name}
                            </TableCell>
                          );
                        })}
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              row["type"] = "update";
                              handleOpen(row);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              deleteFunc(row.global_hotel_id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableBody>
                    </TableRow>
                  </TableRow>
                </TableBody>
              ))
            )
        }}
      />
    </>
  );
}
