import {
  getPriority,
  addPriority,
  updatePriority,
  deletePriority
} from "redux/actions/priority";
import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text
  });
};
export const getPriorityService = selectedContract => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("priority", { params: { contract_id: selectedContract } })
    .then(res => {
      dispatch(getPriority(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addPriorityService = (contract_id, data) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .post("priority", data, { params: { contract_id: contract_id } })
    .then(res => {
      dispatch(addPriority(res));
      dispatch(fetchSuccess());
      sweetAlerts("success", "Priority created successfully!");
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
    });
};
export const updatePriorityService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .put("priority", data)
    .then(res => {
      dispatch(updatePriority(res));
      dispatch(fetchSuccess());
      sweetAlerts("success", "Priority update successfully!");
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
    });
};

export const deletePriorityService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());

  return axios
    .delete(`priority/${id}`)
    .then(res => {
      dispatch(fetchSuccess());
      dispatch(deletePriority(res));
    })
    .catch(e => {
      sweetAlerts("error", "Something went wrong!");
      dispatch(fetchError(e.message));
    });
};
