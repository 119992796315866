import { normalize, schema } from "normalizr";

const SH = new schema.Entity("SH");
const DN = new schema.Entity("DN");
const WB = new schema.Entity("WB");
const PP = new schema.Entity("PP");
const LMX = new schema.Entity("LMX");
const FOR = new schema.Entity("FOR");
const SIT = new schema.Entity("SIT");
const WEBBEDS = new schema.Entity("WEBBEDS");
const JUMBO = new schema.Entity("JUMBO");
const LMT = new schema.Entity("LMT");
const XPUR = new schema.Entity("XPUR");
const IVC = new schema.Entity("IVC");
const SDN = new schema.Entity("SDN");
const JU = new schema.Entity("JU");
const W2M = new schema.Entity("W2M");
const PT = new schema.Entity("PT");

const hotelData = new schema.Entity("hotelData", {
  SH: [SH],
  DN: [DN],
  PP: [PP],
  WB: [WB],
  LMX: [LMX],
  FOR: [FOR],
  SIT: [SIT],
  WEBBEDS: [WEBBEDS],
  JUMBO: [JUMBO],
  LMT: [LMT],
  XPUR: [XPUR],
  IVC: [IVC],
  SDN: [SDN],
  JU: [JU],
  W2M: [W2M],
  PT: [PT],
});

export const normalizeOperatorHotels = (data) => normalize(data, [hotelData]);
