import React, {
  useEffect,
  useState,
  Suspense,
  useCallback,
  useRef
} from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  addContractService,
  controlContractConfilict,
  updateContractService
} from "services/contract";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { isSavedConract } from "redux/actions/contract";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { getAdminCompaniesService } from "services/company";
import { green, purple } from "@material-ui/core/colors";
import InputBase from "@material-ui/core/InputBase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  FormControl,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  InputLabel
} from "@material-ui/core";
import { AgeNumberFormatCustom } from "../Common/NumberFormat/NumberFormat";
import moment from "moment";
import {
  nonEnterprise,
  companyData
} from "redux/selector/companyHasOfficeSelector";
import _, { reject } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import SelectAll from "./SelectAll";
import { CHECK_CONTRACT_DATA_IS_UPDATE } from "redux/actions/types";
import IntlMessages from "@jumbo/utils/IntlMessages";
import SelectAllTest from "./SelectAllTest";
/* const SelectAll = React.lazy(() => import("./SelectAll")); */
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import MultiSelect from "../Common/MultiSelect";
import "../../../styles/css/contractSeason.css";
import { useParams } from "react-router";
import { ContactMail } from "@material-ui/icons";
import ContractInfo from "./ContractGraph/ContractInfo";
import Loader from "./Loader";
import { useWhyDidYouUpdate } from "@jumbo/utils/useWhyDidYouUpdate";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ContractSeason(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isSavedConract(""));
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  let param = useParams();

  //dropdownlarda görüntülenmesi g0ereken veriler
  const filter = createFilterOptions();
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const { boards } = useSelector(({ boards }) => boards);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { markets } = useSelector(({ markets }) => markets);
  const { marketCountries } = useSelector(({ markets }) => markets);
  const { authUser } = useSelector(({ auth }) => auth);
  const { allotmentType } = useSelector(({ allotmentType }) => allotmentType);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { board } = useSelector(({ contract }) => contract);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { market } = useSelector(({ contract }) => contract);
  const { contracts } = useSelector(({ contract }) => contract);
  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const { countries } = useSelector(({ contract }) => contract);
  const destinationsCountries = useSelector(
    state => state.destinations.countries
  );

  const onlyCompany = useSelector(nonEnterprise);
  const objectCompany = useSelector(companyData);
  const theme = createTheme({
    palette: {
      primary: green,
      secondary: purple
    }
  });
  //tarihler için default değerler
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(selectedStartDate);
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const [contractInfo, setContractInfo] = useState(
    selectedContract != 0 || !param.hasOwnProperty("contractId")
      ? {
          ...contracts[selectedContract],
          max_infant_age: "01.99"
        }
      : { max_infant_age: "01.99" }
  );
  const [hotelInformation, setHotelInformation] = useState({});
  const [tempMarkets, setTempMarkets] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [operatorState, setOperatorState] = useState([]);
  const [boardState, setBoardState] = useState([]);
  const [rateTypeState, setRateTypeState] = useState([]);
  const [allotmentTypeState, setAllotmentTypeState] = useState([]);
  const [currenciesState, setCurrenciesState] = useState([]);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [hotelCache, setHotelCache] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [isThereContract, setisThereContract] = useState();

  // const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState();
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;
  const contractStatusControl = contracts[selectedContract]
    ? contracts[selectedContract].contract_statuses_id == 1 ||
      contracts[selectedContract].contract_statuses_id == 5
      ? true
      : false
    : true;

  let countryInMarket = {};
  Object.entries(markets).forEach((key, value) => {
    if (key[1].countries !== null) {
      countryInMarket[value] = key[1];
    }
  });

  const getMarketCountries = () => {
    let temp = [];
    contractInfo.market.length > 0 &&
      contractInfo.market.forEach(value => {
        temp.push(markets[value]?.countries);
      });

    // flat array and remove duplicate
    temp = temp.flat().filter((v, i, a) => a.indexOf(v) === i);

    return destinationsCountries.filter(item => {
      return temp.includes(item.id);
    });
  };
  const [allCountries, setAllCountries] = useState(
    selectedContract != 0 || !param.hasOwnProperty("contractId")
      ? getMarketCountries
      : []
  );
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };

  let confilictCountries = "";
  let confilictOpertors = "";
  let isAuthAdd = permissionsByAuthUser.some(
    permission =>
      permission.route_id == 2 &&
      (permission.post === 1 || permission.post == true)
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission =>
      permission.route_id == 2 &&
      (permission.put === 1 || permission.post == true)
  );
  let countriesRef = useRef(destinationsCountries);
  useEffect(() => {
    dispatch({
      type: CHECK_CONTRACT_DATA_IS_UPDATE,
      payload: updateButtonDisable
    });
  }, [updateButtonDisable]);

  useEffect(() => {
    if (onlyCompany.length != 0) {
      //Sadece 1 tane company varsa seçim yapmasın otomatik olarak o company seçili gelsin
      if (onlyCompany.length == 1) {
        setSelectedCompany(onlyCompany[0]);
      }
    }
  }, [onlyCompany]);
  const setContractCountriesState = market => {
    // let tempMarkets = [];
    // let ids = [];
    // let tempCountries = [];
    // tempMarkets.push(market);
    // tempMarkets.forEach(tempMarket => {
    //   tempMarket.map(element => {
    //     ids.push(element.id);
    //     if (selectedContract != 0 && element.countries) {
    //       element.countries.map(country => {
    //         tempCountries = tempCountries.concat(marketCountries[country]);
    //       });
    //     } else {
    //       if (element.countries) {
    //         element.countries.map(country => {
    //           tempCountries = tempCountries.concat(marketCountries[country]);
    //         });
    //       }
    //     }
    //   });
    //   tempCountries = tempCountries.filter((value, index) => {
    //     const _value = JSON.stringify(value);
    //     return (
    //       index ===
    //       tempCountries.findIndex(obj => {
    //         return JSON.stringify(obj) === _value;
    //       })
    //     );
    //   });
    // });
    // setAllCountries(tempCountries);
    // return ids;
    const ids = market?.map(k => k.id);
    const baseCountries = Object.values(marketCountries);
    let selectCountries = [];
    for (const v of market) {
      for (const l of v.countries) {
        if (!selectCountries.find(t => t === l)) selectCountries.push(l);
      }
    }
    setAllCountries(
      baseCountries
        .map(k => (selectCountries.includes(k.id) ? k : false))
        .filter(k => k !== false)
    );
    return ids;
  };

  useEffect(() => {
    if (selectedContract != 0 || !param.hasOwnProperty("contractId")) {
      let tempOperators = [];
      let tempBoards = [];
      let tempRateTypes = [];
      let tempMarkets = [];
      if (
        contractInfo.max_child_age != null ||
        contractInfo.max_infant_age != null
      ) {

        // countries.map(country=>{
        let contractBoards = Object.values(contract_has_board)
          .map(bb => {
            if (bb.contract_id == selectedContract) {
              return bb.board_id;
            }
          })
          .filter(board => board !== undefined);
        // })
        setContractInfo({
          ...contractInfo,
          // checkChild: false,
          // disabledChild: false,
          // disabledInf: false,
          // checkInf: false,
          include_countries: countriesRef.current.filter(country => {
            return contractInfo.contractCountries.includes(country.id);
          }),
          boards: Object.values(boards)
            .map(board => {
              if (contractBoards.includes(board.id)) {
                return board.id;
              }
            })
            .filter(brd => brd !== undefined)
        });
      } else {
        setContractInfo({
          ...contractInfo,
          // checkChild: true,
          // disabledChild: true,
          // disabledInf: true,
          // checkInf: true,
          include_countries: countriesRef.current.filter(country => {
            return contractInfo.contractCountries.includes(country.id);
          }),
          boards: Object.keys(board)
        });
      }
      setSelectedCompany(objectCompany[contractInfo.company_id]);
      //setHotelInformationState(hotels[contractInfo.hotel_id]);
      setHotelInformation(hotels[contractInfo.hotel_id]);
      //  setContractInfo({ ...contractInfo, include_countries: Object.values(countries) });
      contractInfo.operators.map(op => {
        tempOperators.push(operators[op]);
      });
      contractInfo.contract_has_board.map(b => {
        tempBoards.push(boards[contract_has_board[b].board_id]);
      });
      contractInfo.rate_types.map(r => {
        if (r !== null) {
          return tempRateTypes.push(rateTypes[r]);
        }
      });
      contractInfo.market.map(m => {
        if (tempMarkets.indexOf(m) == -1) {
          tempMarkets.push(market[m]);
        }
      });
      setOperatorState(tempOperators);
      setBoardState(tempBoards);
      setRateTypeState(tempRateTypes);
      setAllotmentTypeState(allotmentType[contractInfo.allotment_type_id]);
      setCurrenciesState(currencies[contractInfo.currency]);
      setTempMarkets(tempMarkets);
    }
  
  }, [hotels,isThereContract]);
  console.log(contractInfo);
  /*   const changeToDefaultValueMinChild = () => {
    const oldData = contracts[selectedContract].min_child_age
    contracts[selectedContract].min_child_age = "02.00"
  } */
  const handleOnChange = (name, value) => {
    setUpdateButtonDisable(false);
    let tempOperators = [];
    let tempBoards = [];
    let tempOperatorsCode = [];
    let tempRateTypes = [];
    if (name == "hotel_id") {
      //setHotelInformationState(value);
      setHotelInformation(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "market") {
      let ids = setContractCountriesState(value);
      let temp_include_countries = [];
      value.map(market => {
        if (contractInfo.include_countries) {
          contractInfo.include_countries.map(include_country => {
            if (market.countries.includes(include_country.id)) {
              temp_include_countries.push(include_country);
            }
          });
        }
      });
      let inc_countries = [];
      inc_countries = temp_include_countries.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          temp_include_countries.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      setTempMarkets(value);

      setContractInfo({
        ...contractInfo,
        [name]: ids,
        include_countries: inc_countries,
        operators: []
      });
      setOperatorState([]);
    } else if (name == "operators") {
      value.map(val => {
        tempOperators.push(val.id);
        tempOperatorsCode.push({ id: val.id, code: val.code });
      });
      setOperatorState(value);
      setContractInfo({
        ...contractInfo,
        [name]: tempOperators,
        tempOperatorCodes: tempOperatorsCode
      });
    } else if (name == "boards") {
      value.map(val => {
        tempBoards.push(val.id);
      });
      setBoardState(value);
      setContractInfo({ ...contractInfo, [name]: tempBoards });
    } else if (name == "rate_types") {
      value.map(val => {
        tempRateTypes.push(val.id);
      });
      setRateTypeState(value);
      setContractInfo({ ...contractInfo, [name]: tempRateTypes });
    } else if (name == "allotment_type_id") {
      setAllotmentTypeState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "currency") {
      setCurrenciesState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "season_id") {
      setContractInfo({
        ...contractInfo,
        [name]: value,
        start_date: seasons[value]?.start_date,
        end_date: seasons[value]?.end_date
      });
    } else if (name == "company_id") {
      //setCompanyInformationState(value);
      setSelectedCompany(objectCompany[value]);
      setContractInfo({ ...contractInfo, [name]: value });
    } else if (name == "country") {
      setUpdateButtonDisable(false);
      setContractInfo({
        ...contractInfo,
        include_countries: value,
        operators: []
      });
      setOperatorState([]);
    } else {
      setContractInfo({ ...contractInfo, [name]: value });
    }
  };

  /* ülkeler için allSelect buradan*/
  // const handleToggleOption = selectedOptions => {
  //   setContractInfo({ ...contractInfo, include_countries: selectedOptions });
  //   setSelectedOptions(selectedOptions);
  // };
  // const handleClearOptions = () => {
  //   setSelectedOptions([]);
  //   setContractInfo({ ...contractInfo, include_countries: [] });
  // };
  // const handleSelectAll = isSelected => {
  //   if (isSelected) {
  //     setSelectedOptions(allCountries);
  //   } else {
  //     setContractInfo({ ...contractInfo, include_countries: selectedOptions });

  //     handleClearOptions();
  //   }
  // };
  // const handleToggleSelectAll = () => {
  //   handleSelectAll && handleSelectAll(!allSelected);
  // };
  // const handleChangeForCountries = (
  //   event,
  //   selectedOptions,
  //   reason,
  //   selectedOption
  // ) => {
  //   if (reason === "select-option" || reason === "remove-option") {
  //     const selectAll = selectedOptions.find(
  //       option => option.value === "select-all"
  //     );
  //     if (
  //       selectedOption?.option?.name === "Select All" &&
  //       allCountries.length <= selectedOptions.length
  //     ) {
  //       setContractInfo({ ...contractInfo, include_countries: [] });
  //       setSelectedOptions([]);
  //     } else {
  //       if (selectAll) {
  //         handleToggleSelectAll();
  //         let result = [];
  //         result = allCountries.filter(el => el.value !== "select-all");
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: result
  //         });
  //       } else {
  //         handleToggleOption && handleToggleOption(selectedOptions);
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: selectedOptions
  //         });
  //       }
  //     }
  //   } else if (reason === "clear") {
  //     handleClearOptions && handleClearOptions();
  //   }
  // };
  /* ülkeler için allSelect buraya*/
  const handleChange = event => {
    setUpdateButtonDisable(false);
    if (event.target.type === "checkbox" && event.target.name == "checkChild") {
      if (event.target.name === "checkChild" && event.target.checked == true) {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked
        });
      } else {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked,
          // disabledChild: event.target.checked,
          // disabledInf: event.target.checked,
          ageInf: 0
        });
      }
    } else if (event.target.type === "checkbox") {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.checked
      });
    } else {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.value
      });
    }
    //changeToDefaultValueMinChild()
  };
  const dateConfilict = (
    old_start_date,
    old_end_date,
    new_start_date,
    new_end_date
  ) => {
    return (
      moment(new_start_date).isBetween(old_start_date, old_end_date) ||
      moment(new_start_date).isSame(old_end_date) ||
      moment(new_start_date).isSame(old_start_date) ||
      moment(new_end_date).isBetween(old_start_date, old_end_date) ||
      moment(new_end_date).isSame(old_start_date) ||
      moment(new_end_date).isSame(old_end_date)
    );
  };


  const saveAndUpdateSeason = async () => {
    setisThereContract(false)
    let flag=false;
    if (
      contractInfo.hasOwnProperty("base_board_id") &&
      contractInfo.base_board_id > 0 &&
      contractInfo.hasOwnProperty("rate_types") &&
      contractInfo.rate_types.length > 0 &&
      (!contractInfo.hasOwnProperty("checkChild") ||
      contractInfo.checkChild == false
        ? contractInfo.hasOwnProperty("max_child_age") &&
          contractInfo.max_child_age != "" &&
          contractInfo.min_child_age != null &&
          contractInfo.hasOwnProperty("max_child_age") &&
          contractInfo.min_child_age != "" &&
          contractInfo.min_child_age != null
        : true)
    ) {
      contractInfo["company_id"] = selectedCompany?.id;
      // contractInfo["include_countries"] = contractInfo.include_countries.map(
      //   country => country.id
      // );

      const res = await dispatch(controlContractConfilict(contractInfo));
      /* kontratın çakışma durumuna karşı backende istek atılıyor. 
      Oradan dönen sonuç true ise isThereContract değeri true yapılır(çakışan operator ya da ülkelerin isimleri gösteriliyor).
      False ise  isThereContract değeri false yapılır. */
      console.log(res);
      if (res.data.result) {
        setisThereContract(true)
        flag=true
        confilictCountries = countriesRef.current
          .filter(country =>
            res.data.countries
              .slice(0, -2)
              .split(", ")
              .includes(country.id.toString())
          )
          .map(country => country.name)
          .join(",");
        let contrr = res.data.contractName;
        confilictOpertors = res.data.operators.slice(0, -2);
        await sweetAlerts(
          "warning",
          confilictOpertors +
            (confilictOpertors.split(",").length > 1
              ? " coded operators"
              : " coded operator") +
            " and " +
            confilictCountries +
            (confilictCountries.split(",").length > 1
              ? " countries "
              : " country ") +
            contrr +
            " available for coded contract. Change operator or country and try again!"
        );
      }

      if (!flag) {
        if (
          contractInfo.hasOwnProperty("max_infant_age") &&
          contractInfo.hasOwnProperty("max_child_age") &&
          Number(contractInfo.max_infant_age) >=
            Number(contractInfo.max_child_age)
        ) {
          sweetAlerts(
            "warning",
            "Child age cannot be younger than infant age!"
          );
        } else if (
          (contractInfo.hasOwnProperty("max_infant_age") &&
            contractInfo.hasOwnProperty("max_child_age") &&
            contractInfo.max_child_age > 20) ||
          contractInfo.max_infant_age > 20
        ) {
          sweetAlerts(
            "warning",
            "Child age and infant age cannot be older than 20!"
          );
        } else if (
          moment(contractInfo.start_date).isAfter(contractInfo.end_date)
        ) {
          sweetAlerts("warning", "Start date cannot be later than end date!");
        } else if (
          contractInfo.start_date == "Invalid date" ||
          contractInfo.end_date == "Invalid date"
        ) {
          sweetAlerts("warning", "Please enter a valid date!");
        } else {
          let tempMarketsData = [];
          let include_countries = [];
          let exclude_countries = [];
          if (tempMarkets && contractInfo.include_countries) {
            tempMarkets.map(market => {
              include_countries = [];
              exclude_countries = [];
              ///BURASI DÜZELTİLECEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEKKKKKKKKKKKKK EXCLUDE YANLIŞ ÇALIŞIYO
              contractInfo.include_countries.map(in_country => {
                if (in_country.hasOwnProperty("id")) {
                  if (market.countries.includes(in_country.id)) {
                    include_countries.push(in_country.id);
                  } else {

                    exclude_countries.push(in_country.id);
                  }
                } else {
                  if (market.countries.includes(in_country)) {
                    include_countries.push(in_country);
                  } else {
                    exclude_countries.push(in_country);
                  }
                }

                // if (market.countries[market.countries.findIndex(i => i === in_country.id)]) {
                //   market.countries.map(ex_country => {

                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == ex_country;
                //     });
                //     if (
                //       !countryCount.length > 0 &&
                //       market.countries.includes(ex_country) &&
                //       !exclude_countries.includes(ex_country.id)
                //     ) {
                //       exclude_countries.push(ex_country);
                //     }
                //   });
                //   market.countries.map(in_country => {
                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == in_country;
                //     });
                //     if (countryCount > 0 && market.countries.includes(in_country) && !include_countries.includes(in_country.id)) {

                //       include_countries.push(in_country);
                //     }
                //   });
                // }
              });
              tempMarketsData.push({
                market_id: market.id,
                includes: include_countries,
                excludes: exclude_countries
              });
            });
          }
          contractInfo["market"] = tempMarketsData;
          contractInfo["user"] = authUser;
          contractInfo["start_date"] = contractInfo.start_date;
          contractInfo["end_date"] = contractInfo.end_date;
          // contractInfo['include_countries'] =  contractInfo.include_countries.map(country => country.id);
          // contractInfo['start_date'] = new Date(contractInfo.start_date);
          // contractInfo['end_date'] = new Date(contractInfo.end_date);
          // contractInfo['company'] = selectedCompany?.id;
          let contract_name_temp = "";
          contract_name_temp = seasons[contractInfo.season_id].code;
          Object.values(contractInfo?.market).forEach(
            element =>
              (contract_name_temp =
                contract_name_temp + "-" + markets[element.market_id].code)
          );
          contract_name_temp =
            contract_name_temp + "-" + currencies[contractInfo.currency].code;
          contractInfo["contract_name"] = contract_name_temp;
          if (selectedContract != 0) {
            let tempContract = contractInfo;
            if (
              contractInfo.hasOwnProperty("checkChild") &&
              contractInfo.checkChild == true
            ) {
              setContractInfo({
                ...contractInfo,
                min_child_age: "02.00"
              });
              tempContract = {
                ...contractInfo,
                min_child_age: "02.00"
              };
            }
            dispatch(updateContractService(tempContract)).then(res => {
              if (res?.data.message == "Not updated") {
                sweetAlerts("warning", `${res.data.data}`);
                dispatch(isSavedConract(""));
              } else {
                dispatch(isSavedConract("isSaved"));
              }
            });
          } else {
            let tempContract = contractInfo;
            if (
              contractInfo.hasOwnProperty("checkChild") &&
              contractInfo.checkChild == true
            ) {
              setContractInfo({
                ...contractInfo,
                min_child_age: "02.00"
              });
              tempContract = {
                ...contractInfo,
                min_child_age: "02.00"
              };
            }
            dispatch(addContractService(tempContract));
            dispatch(isSavedConract("isSaved"));
          }
          // const emptyValuesState = {};
          // Object.entries(contractInfo).forEach(element => {
          //   if (element[0] === 'rate_types' || element[0] === 'boards' || element[0] === 'market') {
          //     emptyValuesState[element[0]] = [];
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   } else {
          //     emptyValuesState[element[0]] = '';
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   }
          // });
        }
      }
    } else {
      sweetAlerts(
        "warning",
        intl.formatMessage({ id: "please.fill.in.all.fields" })
      );
    }
  };

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: "20px auto 0 auto",
      fontSize: "12px!important",
      overflow: "inherit",
      "& .MuiInputLabel-root": {
        fontWeight: "bold"
      },
      "& .MuiTypography-h6": {
        color: "black", // or black
        fontWeight: "bold!important"
      }
    },
    formControl: {
      margin: theme.spacing(2),
      fontSize: "12px!important",
      minWidth: "-webkit-fill-available;",
      maxWidth: "-webkit-fill-available;"
    },
    multiSelectFormControl: {
      margin: theme.spacing(2),
      fontSize: "12px!important",
      minWidth: "-webkit-fill-available;",
      maxWidth: "-webkit-fill-available;"
    },
    rootcard: {
      minWidth: "100%",
      maxWidth: "100%",
      margin: "0 auto",
      fontSize: "12px!important",
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      overflow: "inherit"
    },
    title: {
      fontSize: "12px!important"
    },
    formElement: {
      minWidth: "100%",
      fontSize: "10px!important",
      margin: theme.spacing(2)
    },
    container: {
      display: "flex",
      fontSize: "12px!important",
      flexWrap: "wrap"
    },
    button: {
      float: "right",
      margin: theme.spacing(2),
      fontSize: "12px!important",
      maxWidth: "50%",
      top: "-25px"
    },
    contractName: {
      textAlign: "center"
    },
    margin: {
      marginTop: "15px"
    },
    baseBoard: {
      padding: "2px",
      display: "flex",
      alignItems: "end"
    },

    baseBoardLabel: {
      fontWeight: "400!important",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "12px!important",

      '&[data-shrink="false"]': {
        color: "#aaa"
      }
    }
  }));

  const classes = useStyles();
  const allSelected = allCountries.length === selectedOptions.length;
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      allCountries.length > 0 && option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.name}
      </React.Fragment>
    );
  };
  const countriesOperator = useCallback(
    // To avoid re-rendering of the function
    () => {
      let tempOperator = [];

      if (
        contractInfo.include_countries &&
        contractInfo.include_countries.length > 0
      ) {
        // get Ids of selected countries
        const contractCountryIds = contractInfo.include_countries.map(
          country => country.id
        );
        tempOperator = Object.values(operators).reduce((acc, operator) => {
          if (operator.countries != null && operator.countries.length > 0) {
            for (let i = 0; i < operator.countries.length; i++) {
              if (contractCountryIds.includes(operator.countries[i])) {
                acc.push(operator);
                break;
              }
            }
          }
          return acc;
        }, []);
      }
      return tempOperator;
    },
    [contractInfo.include_countries, contractInfo.market]
  );

  /**** Calculate Max Child Age Here****/
  const maxChild = 19.99;
  const maxChildVal = [];

  let maxage = _.range(2.99, maxChild, 1);

  maxage.forEach(param => {
    maxChildVal.push({ age: param.toFixed(2) });
  });
  /*****Calculate Min Child Age****/
  const minChild = 19;
  const minChildVal = [];

  let minage = _.range(2, minChild, 1);

  minage.forEach(param => {
    minChildVal.push({ age: param.toFixed(2) });
  });
  /*************************************/

  const dataControl = () => {
    return contractInfo.hotel_id &&
      contractInfo.season_id &&
      contractInfo.currency &&
      contractInfo.allotment_type_id &&
      contractInfo.operators.length > 0 &&
      contractInfo.hasOwnProperty("include_countries") &&
      contractInfo.include_countries?.length > 0 &&
      contractInfo.boards.length > 0 &&
      contractInfo.market.length > 0 &&
      (parseInt(contractInfo.max_child_age) >=
        parseInt(contractInfo.min_child_age) ||
        (contractInfo.min_child_age == undefined &&
          contractInfo.max_child_age &&
          contractInfo.checkChild))
      ? false
      : true;
  };
  return (
    <div className={classes.root}>
      <InputBase
        inputProps={{
          style: {
            marginTop: "0.1%",
            textAlign: "center",
            color:
              localStorage.getItem("theme-type") === "dark" ? "white" : "black"
          }
        }}
        defaultValue={intl.formatMessage({ id: "contract.definitions" })}
        fullWidth
        disabled
        margin="dense"
      />
      <Card className={classes.root}>
        <CardContent className={classes.rootcard}>
          <Typography
            sx={{ fontSize: 18 }}
            className={classes.contractName}
            color="text.secondary"
            gutterBottom
          >
            <b>{contractInfo?.contract_name}</b>
          </Typography>
          <Grid container item xs={12} direction="row">
            <Grid item xs container direction="row" spacing={1}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== "edit" ? (
                          <Autocomplete
                            options={onlyCompany}
                            freeSolo
                            disabled={
                              authUser?.authority_level == 11 ||
                              authUser?.authority_level == 20 ||
                              authUser?.authority_level == 30
                            }
                            value={selectedCompany ? selectedCompany.name : ""}
                            onChange={(event, value) => {
                              // handleOnChange('company', value ? value?.id : {});
                              handleOnChange(
                                "company_id",
                                value ? value?.id : ""
                              );
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                disabled={
                                  authUser?.authority_level == 11 ||
                                  authUser?.authority_level == 20 ||
                                  authUser?.authority_level == 30
                                }
                                placeholder={intl.formatMessage({
                                  id: "company"
                                })}
                                //error={!contractInfo.company_id}
                                label={<IntlMessages id="company" />}
                                value={
                                  selectedCompany ? selectedCompany.name : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  readOnly: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  value: selectedCompany
                                    ? selectedCompany.name
                                    : ""
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            placeholder={intl.formatMessage({
                              id: "company"
                            })}
                            className={classes.formElement}
                            label={<IntlMessages id="company" />}
                            value={selectedCompany ? selectedCompany.name : ""}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true
                            }}
                            disabled
                          />
                        )}
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails hidden={selectedCompany == undefined}>
                      <Card
                        className={classes.rootcard}
                        hidden={
                          selectedContract != 0 ? false : contractInfo.hotelInfo
                        }
                      >
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <FormControl className={classes.formControl}>
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.name" />}
                                defaultValue="Company Name"
                                value={
                                  selectedCompany ? selectedCompany.name : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                                disabled
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.taxoffice" />}
                                defaultValue="Tax Office & Number"
                                value={
                                  selectedCompany
                                    ? selectedCompany.tax_office +
                                      " / " +
                                      selectedCompany.tax_no
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                                disabled
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="city" />}
                                defaultValue="City"
                                value={
                                  selectedCompany
                                    ? entities.city[selectedCompany.city_id]
                                        ?.name
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                                disabled
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="phone.number" />}
                                value={
                                  selectedCompany ? selectedCompany.phone : ""
                                }
                                defaultValue="Tel"
                                InputProps={{
                                  readOnly: true
                                }}
                                disabled
                              />

                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label="Email"
                                defaultValue="Email"
                                value={
                                  selectedCompany ? selectedCompany.email : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                                disabled
                              />
                            </FormControl>
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== "edit" ? (
                          <Autocomplete
                            options={Object.values(hotels)}
                            freeSolo
                            value={selectedCompany ? hotelInformation : ""}
                            onChange={(event, value) => {
                              handleOnChange("hotel_id", value ? value : {});
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder={intl.formatMessage({
                                  id: "hotel"
                                })}
                                label={<IntlMessages id="hotel" />}
                                required={!contractInfo.hotel_id}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            placeholder={intl.formatMessage({
                              id: "hotel"
                            })}
                            className={classes.formElement}
                            label={<IntlMessages id="hotel" />}
                            value={hotelInformation.name}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true
                            }}
                            disabled
                          />
                        )}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      hidden={
                        contractInfo.hasOwnProperty("hotel_id") &&
                        contractInfo.hotel_id != undefined
                          ? false
                          : true
                      }
                    >
                      <Card
                        className={classes.rootcard}
                        hidden={
                          selectedContract != 0 ? false : contractInfo.hotelInfo
                        }
                      >
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <IntlMessages id="hotel.info" />
                          </Typography>
                          <FormControl className={classes.formControl}>
                            <Grid container item xs={12} direction="row">
                              <Grid
                                item
                                xs
                                container
                                direction="row"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="hotel.name" />}
                                    value={hotelInformation.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="tax.office" />}
                                    value={hotelInformation.tax_office}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="No"
                                    value={hotelInformation.tax_no}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="star.rating" />}
                                    value={hotelInformation.star_rating}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    value={hotelInformation.region?.name}
                                    label={<IntlMessages id="continent" />}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="country" />}
                                    value={hotelInformation.country?.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="city" />}
                                    value={hotelInformation.city?.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    value={
                                      hotelInformation.sub_city == null ||
                                      hotelInformation.sub_city == undefined ||
                                      hotelInformation.sub_city == ""
                                        ? ""
                                        : hotelInformation.sub_city.name
                                    }
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="sub.city" />}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="address" />}
                                    value={
                                      hotelInformation.district === null ||
                                      hotelInformation.district === undefined ||
                                      hotelInformation.district === ""
                                        ? ""
                                        : hotelInformation.district
                                    }
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="website" />}
                                    value={hotelInformation.web_page}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="phone.number" />}
                                    value={hotelInformation.tel}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Fax"
                                    value={
                                      hotelInformation.fax == null ||
                                      hotelInformation.fax == ""
                                        ? ""
                                        : hotelInformation.fax
                                    }
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Email"
                                    value={hotelInformation.e_mail}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  {contractStatusControl ? (
                    <Autocomplete
                      value={
                        contractInfo.season_id
                          ? seasons[contractInfo.season_id]
                          : ""
                      }
                      options={Object.values(seasons)
                        .filter(season =>
                          moment(season.end_date).isAfter(
                            moment(today).subtract(1, "days")
                          )
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))}
                      onChange={(event, value) => {
                        handleOnChange("season_id", value ? value.id : "");
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: "season.name"
                          })}
                          required={
                            contractInfo.season_id != undefined ? false : true
                          }
                          label={<IntlMessages id="season.name" />}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      placeholder={intl.formatMessage({
                        id: "season.name"
                      })}
                      className={classes.formElement}
                      label={<IntlMessages id="season.name" />}
                      value={seasons[contractInfo.season_id].name}
                      InputLabelProps={{
                        readOnly: true
                      }}
                      disabled
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="start_date"
                      disablePast
                      format="dd/MM/yyyy"
                      autoOk="true"
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.start_date).format("YYYY-MM-DD")
                          : moment(selectedStartDate).format("YYYY-MM-DD")
                      }
                      required={contractInfo.season_id ? false : true}
                      onChange={value => {
                        handleOnChange(
                          "start_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      label={<IntlMessages id="start.date" />}
                      animateYearScrolling
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl
                      }}
                      readOnly={!contractStatusControl}
                      disabled={contractInfo.contract_statuses_id === 5}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="end_date"
                      disablePast
                      format="dd/MM/yyyy"
                      autoOk="true"
                      //value={contractInfo.season_id ? contractInfo.end_date : selectedEndDate}
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.end_date).format("YYYY-MM-DD")
                          : moment(selectedEndDate).format("YYYY-MM-DD")
                      }
                      required={contractInfo.season_id ? false : true}
                      minDate={contractInfo.start_date}
                      onChange={value => {
                        handleOnChange(
                          "end_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      label={<IntlMessages id="end.date" />}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl
                      }}
                      readOnly={!contractStatusControl}
                      disabled={!seasons[contractInfo.season_id]}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.multiSelectFormControl}>
                  <MultiSelect
                    options={multiDataSetter(
                      "name",
                      "id",
                      Object.values(countryInMarket).sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    )}
                    value={multiDataSetter("name", "id", tempMarkets)}
                    handleChange={event => handleOnChange("market", event)}
                    label={"Markets"}
                    labelledBy="Market"
                    placeholder="Select Markets"
                    style={{
                      color:
                        contractInfo.base_board_id >= 0 ? "#A3A3A3" : "black"
                    }}
                  />
                  {/* <Autocomplete
                    options={Object.values(markets).sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )}
                    limitTags={2}
                    value={contractInfo.market ? tempMarkets : []}
                    onChange={(event, value) => {
                      if (contractInfo.hasOwnProperty("market")) {
                        if (
                          tempMarkets.filter(
                            i => i?.name == event.target.outerText
                          ).length < 1
                        ) {
                          handleOnChange("market", value ? value : {});
                        }
                      } else {
                        handleOnChange("market", value ? value : {});
                      }
                    }}
                    getOptionLabel={option => option.name}
                    disableCloseOnSelect
                    multiple
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        placeholder={intl.formatMessage({
                          id: "market"
                        })}
                        error={contractInfo.market?.length > 0 ? false : true}
                        label={<IntlMessages id="market" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  /> */}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.multiSelectFormControl}>
                  <Suspense fallback={<Loader />}>
                    {/* {console.log(
                      allCountries.sort((a, b) => (a.name > b.name ? 1 : -1))
                    )} */}
                    {
                      // <SelectAll
                      //   optionLabel={"name"}
                      //   options={allCountries.sort((a, b) =>
                      //     a.name > b.name ? 1 : -1
                      //   )}
                      //   onChange={val => {
                      //     setUpdateButtonDisable(false);
                      //     setContractInfo({
                      //       ...contractInfo,
                      //       include_countries: val
                      //     });
                      //   }}
                      //   value={contractInfo.include_countries ?? []}
                      //   placeholder={"Included Countries"}
                      //   disabled={!contractStatusControl}
                      // />

                      // <SelectAllTest
                      //   options={allCountries.sort((a, b) =>
                      //     a.name > b.name ? 1 : -1
                      //   )}
                      //   onChange={val => {
                      //     setUpdateButtonDisable(false);
                      //     setContractInfo({
                      //       ...contractInfo,
                      //       include_countries: val
                      //     });
                      //   }}
                      //   labelKey={"name"}
                      //   valueKey={"id"}
                      //   data={contractInfo.include_countries ?? []}
                      //   placeholder={"Included Countries"}
                      // />
                      <>
                        <MultiSelect
                          options={multiDataSetter(
                            "name",
                            "id",
                            allCountries.sort((a, b) =>
                              a.name > b.name ? 1 : -1
                            )
                          )}
                          value={multiDataSetter(
                            "name",
                            "id",
                            contractInfo.include_countries
                          )}
                          label={"Countries"}
                          handleChange={e => handleOnChange("country", e)}
                        />
                      </>
                    }
                  </Suspense>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.multiSelectFormControl}>
                  <Suspense fallback={<Loader />}>
                    {
                      // <SelectAll
                      //   optionLabel={"name"}
                      //   options={countriesOperator().sort((a, b) =>
                      //     a.name > b.name ? 1 : -1
                      //   )}
                      //   onChange={(value) => {
                      //     handleOnChange("operators", value ? value : {});
                      //   }}
                      //   value={contractInfo.operators ? operatorState : []}
                      //   placeholder={"Operator"}
                      //   disabled={!contractStatusControl}
                      //   linkedElement={{
                      //     available: true,
                      //     value: contractInfo.include_countries
                      //       ? contractInfo.include_countries
                      //       : [],
                      //   }}
                      // />
                      <>
                        <MultiSelect
                          options={multiDataSetter(
                            "name",
                            "id",
                            countriesOperator().sort((a, b) =>
                              a.name > b.name ? 1 : -1
                            )
                          )}
                          value={multiDataSetter("name", "id", operatorState)}
                          handleChange={e => handleOnChange("operators", e)}
                          label={"Operators"}
                          labelledBy="Select"
                        />
                      </>
                    }
                  </Suspense>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl className={classes.multiSelectFormControl}>
                  <Suspense fallback={<Loader />}>
                    {
                      // <SelectAll
                      //   optionLabel={"code"}
                      //   options={Object.values(boards)}
                      //   onChange={(value) => {
                      //     contractInfo.boards?.includes(
                      //       contractInfo.base_board_id
                      //     ) ||
                      //     contractInfo.boards?.includes(
                      //       JSON.stringify(contractInfo.base_board_id)
                      //     )
                      //       ? (contractInfo.base_board_id =
                      //           contractInfo.base_board_id)
                      //       : (contractInfo.base_board_id = 0);
                      //     handleOnChange("boards", value ? value : {});
                      //   }}
                      //   value={contractInfo.boards ? boardState : []}
                      //   placeholder={"Boards"}
                      //   disabled={!contractStatusControl}
                      // />
                      <>
                        <MultiSelect
                          options={multiDataSetter(
                            "name",
                            "id",
                            Object.values(boards)
                          )}
                          value={multiDataSetter("name", "id", boardState)}
                          handleChange={e => handleOnChange("boards", e)}
                          label={"Boards"}
                          labelledBy="Select"
                          style={
                            localStorage.getItem("theme-type") === "dark"
                              ? "#A3A3A3"
                              : "black"
                          }
                        />
                      </>
                    }
                  </Suspense>
                </FormControl>
              </Grid>
              <Grid item className={classes.baseBoard} xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-standard-label"
                    className={classes.baseBoardLabel}
                  >
                    {" "}
                    Base Board{" "}
                  </InputLabel>
                  <Select
                    disabled={!boardState.length > 0}
                    labelId="demo-simple-select-label"
                    label="Base Board"
                    required={
                      contractInfo.base_board_id &&
                      (contractInfo.boards?.includes(
                        contractInfo.base_board_id
                      ) ||
                        contractInfo.boards?.includes(
                          JSON.stringify(contractInfo.base_board_id)
                        ))
                        ? false
                        : true
                    }
                    placeholder={intl.formatMessage({ id: "base.board" })}
                    style={{ width: "100%" }}
                    value={contractInfo.base_board_id}
                    onChange={e => {
                      handleOnChange("base_board_id", e.target.value);
                    }}
                    readOnly={!contractStatusControl}
                    //disabled={props.type == "edit" ? true : true}
                  >
                    {contractInfo.boards?.map(option => (
                      <MenuItem key={option} value={option}>
                        {boards[option]?.code}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <Autocomplete
                    //error={contractInfo.base_board_id ? false : true}
                    options={baseBoardOptions ? baseBoardOptions : []}
                    //value={contractInfo.base_board_id}
                    onChange={(event, value) => {
                      handleOnChange('base_board_id', value ? value : {});
                    }}
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Base Board"
                        // error={!contractInfo.allotment_type_id}
                        error={!contractInfo.base_board_id}
                        label="Base Board"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                      />
                    )}
                  /> */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.multiSelectFormControl}>
                  <Suspense fallback={<Loader />}>
                    {
                      // <SelectAll
                      //   optionLabel={"name"}
                      //   options={Object.values(rateTypes)
                      //     .filter((rt) => {
                      //       return rt.id == 1 || rt.id == 2;
                      //     })
                      //     .sort((a, b) => (a.name > b.name ? 1 : -1))}
                      //   onChange={(value) => {
                      //     handleOnChange("rate_types", value ? value : {});
                      //   }}
                      //   value={contractInfo ? rateTypeState : []}
                      //   placeholder={intl.formatMessage({
                      //     id: "rate.type",
                      //   })}
                      //   disabled={!contractStatusControl}
                      // />
                      <>
                        <MultiSelect
                          options={multiDataSetter(
                            "name",
                            "id",
                            Object.values(rateTypes)
                              .filter(rt => {
                                return rt.id == 1 || rt.id == 2;
                              })
                              .sort((a, b) => (a.name > b.name ? 1 : -1))
                          )}
                          value={multiDataSetter("name", "id", rateTypeState)}
                          handleChange={e => handleOnChange("rate_types", e)}
                          label={"Rate Types"}
                          labelledBy="Select"
                        />
                      </>
                    }
                  </Suspense>
                </FormControl>
              </Grid>
              <Grid item className={classes.margin} xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    error={allotmentTypeState?.length > 0 ? false : true}
                    options={Object.values(allotmentType)}
                    value={
                      contractInfo.allotment_type_id ? allotmentTypeState : ""
                    }
                    onChange={(event, value) => {
                      handleOnChange("allotment_type_id", value ? value : {});
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: "allotment.type"
                        })}
                        // error={!contractInfo.allotment_type_id}
                        required={!contractInfo.allotment_type_id}
                        label={<IntlMessages id="allotment.type" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item className={classes.margin} xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    limitTags={2}
                    options={Object.values(currencies).sort((a, b) =>
                      a.code > b.code ? 1 : -1
                    )}
                    value={contractInfo.currency ? currenciesState : ""}
                    onChange={(event, value) => {
                      handleOnChange("currency", value ? value : {});
                    }}
                    getOptionLabel={option => option.code}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: "currency"
                        })}
                        // error={!contractInfo.currency}
                        required={!contractInfo.currency}
                        label={<IntlMessages id="currency" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            contractInfo.checkChild
                              ? contractInfo.checkChild
                              : false
                          }
                          onChange={handleChange}
                          name="checkChild"
                          disabled={!contractStatusControl}
                        />
                      }
                      label={<IntlMessages id="only.adult" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      defaultValue={(function() {
                        contractInfo.min_child_age = "02,00";
                        return contractInfo.min_child_age;
                      })()}
                      placeholder={intl.formatMessage({
                        id: "min.child.age"
                      })}
                      label={<IntlMessages id="min.child.age" />}
                      name="min_child_age"
                      InputProps={{
                        inputComponent: AgeNumberFormatCustom,
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                        readOnly: true
                      }}
                      onChange={handleChange}
                      disabled={
                        !contractStatusControl || contractInfo.checkChild
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      limitTags={2}
                      options={maxChildVal}
                      value={Object.values(maxChildVal).find(
                        item => item.age == contractInfo.max_child_age
                      )}
                      onChange={(e, value) => {
                        handleOnChange("max_child_age", value ? value.age : "");
                      }}
                      getOptionLabel={option => option.age}
                      renderInput={params => (
                        <TextField
                          sx={{
                            "& label": { display: "flex", fontSize: "14px" }
                          }}
                          {...params}
                          placeholder={intl.formatMessage({
                            id: "max.child.age"
                          })}
                          error={
                            parseInt(contractInfo.max_child_age) <
                            parseInt(contractInfo.min_child_age)
                          }
                          required={!contractInfo.max_child_age}
                          label={<IntlMessages id="max.child.age" />}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      disabled={!contractStatusControl}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={contractInfo.checkInf}
                          disabled={
                            /* contractInfo.disabledInf */ !contractStatusControl ||
                            contractInfo.checkChild
                          }
                          onChange={handleChange}
                          name="checkInf"
                        />
                      }
                      label={<IntlMessages id="should.infant.count.as.pax" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      defaultValue={(function() {
                        contractInfo.max_infant_age = "01,99";
                        return contractInfo.max_infant_age;
                      })()}
                      placeholder={intl.formatMessage({
                        id: "infant.age"
                      })}
                      label={<IntlMessages id="infant.age" />}
                      name="max_infant_age"
                      InputProps={{
                        inputComponent: AgeNumberFormatCustom,
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                        readOnly: true
                      }}
                      onChange={handleChange}
                      disabled={
                        !contractStatusControl || contractInfo.checkChild
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormControl className={classes.button}>
            <ThemeProvider theme={theme}>
              {selectedContract != 0 ? (
                isAuthUpdate ? (
                  <Button
                    variant="contained"
                    disabled={updateButtonDisable || dataControl()}
                    color="secondary"
                    onClick={() => saveAndUpdateSeason()}
                    startIcon={<UpdateIcon />}
                    hidden={!contractStatusControl}
                  >
                    <IntlMessages id="update" />
                  </Button>
                ) : (
                  undefined
                )
              ) : isAuthAdd ? (
                <Button
                  variant="contained"
                  disabled={
                    dataControl()
                    // contractInfo.hotel_id &&
                    // contractInfo.season_id &&
                    // contractInfo.currency &&
                    // contractInfo.allotment_type_id &&
                    // contractInfo.operators.length > 0 &&
                    // contractInfo.hasOwnProperty("include_countries") &&
                    // contractInfo.include_countries?.length > 0 &&
                    // contractInfo.boards.length > 0 &&
                    // contractInfo.market.length > 0
                    //   ? false
                    //   : true
                  }
                  color="primary"
                  onClick={() => saveAndUpdateSeason()}
                  startIcon={<SaveIcon />}
                  hidden={!contractStatusControl}
                >
                  <IntlMessages id="save" />
                </Button>
              ) : (
                undefined
              )}
            </ThemeProvider>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
}

export default ContractSeason;

export const MemoizedContractSeason = React.memo(ContractSeason);
