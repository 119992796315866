import React, { useState, useEffect, Suspense, lazy, useRef } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketService,
  addMarketService,
  deleteMarketService,
  updateMarketService
} from "services/market";
import {
  getRegionsService,
  getAllCountriesService
} from "services/destinations";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange, green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import { Table, Tag, Transfer } from "antd";
import difference from "lodash/difference";
import Loader from "./Loader";
const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});
const themeButton = createTheme({
  palette: {
    primary: green
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);
const MySwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(2, 4)
  },
  list: {
    width: 300,
    height: 330,
    backgroundColor: theme.palette.background.default,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(1, 0)
  },
  buttonStyle: {
    margin: theme.spacing(1, 0)
  }
}));

const tableRef = React.createRef();

function Market({ isAuthAdd, isAuthUpdate, isAuthDelete, isAuthView }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (Object.values(markets).length === 0) {
      dispatch(getMarketService());
    }
    dispatch(getRegionsService());
    dispatch(getAllCountriesService());
  }, [dispatch]);
  const { markets } = useSelector(({ markets }) => markets);
  const { contracts } = useSelector(({ contract }) => contract);

  const [isAddClick, setIsAddClick] = useState(true);

  const clickEdit = () => {
    setIsAddClick(!isAddClick);
  };

  const hasCountries = data => {
    if (data.countries) {
      const flag = data.countries.length > 0 ? true : false;

      return flag;
    } else {
      return false;
    }
  };

  let isUpdating = useRef(false);
  let isAdding = useRef(false);

  // Expand last rowData when a new market is added
  useEffect(() => {
    let rowData;
    let tableDataId;
    //find markets rowData by code
    if (
      tableRef.current.state.data.length >= 0 &&
      Object.values(markets).length >= 0 &&
      isUpdating.current === false &&
      isAdding.current === true
    ) {
      rowData =
        tableRef.current.state.data[tableRef.current.state.data.length - 1];
      // find Material Table data id
      if (rowData !== undefined) {
        tableDataId = rowData.tableData.id;
      } else {
        tableDataId = -1;
      }
      // toggle detail panel
      tableRef.current.onToggleDetailPanel(
        [tableDataId + 1],
        tableRef.current.props.detailPanel[0].render
      );
    }
  }, [markets]);

  //Expand selected rowData when the selected market is updated
  const expandRowDetail = (code, name) => {
    //find cancellationPoliciesDetail rowData by code
    const rowData = tableRef.current.state.data.find(
      c => c.code == code && c.name == name
    );
    // find Material Table data id
    const tableDataId = rowData.tableData.id;
    // toggle detail panel
    tableRef.current.onToggleDetailPanel(
      [tableDataId],
      tableRef.current.props.detailPanel[0].render
    );
  };
  const [columnsMarket, setColumnsBaseMarket] = useState([
    {
      title: intl.formatMessage({ id: "code" }),
      align: "center",
      field: "code",
      validate: rowData => (rowData.code ? true : false)
    },
    {
      title: intl.formatMessage({ id: "market.name" }),
      align: "center",
      field: "name",
      validate: rowData => (rowData.name ? true : false)
    },
    {
      align: "center",
      render: rowData => {
        return (
          !hasCountries(rowData) && (
            <Tooltip
              title={
                <>
                  <Typography style={{ fontSize: "9px", textAlign: "center" }}>
                    <IntlMessages id="the.market.has.no.country" />
                  </Typography>
                  <Typography style={{ fontSize: "9px", textAlign: "center" }}>
                    <IntlMessages id="please.click.and.add.a.country.to.the.market" />
                  </Typography>
                </>
              }
              style={{ "font-size": "12px", width: "70px" }}
            >
              <IconButton>
                <WarningAmberIcon
                  style={{ color: "#ff9800", width: "70px", height: "25px" }}
                />
              </IconButton>
            </Tooltip>
          )
        );
      }
    }
  ]);

  const hasUseMarket = market_id => {
    var contractMarkets = [];
    var has = true;

    Object.values(contracts).forEach(contract => {
      contract.market.forEach(market => {
        if (markets[market].id == market_id) {
          has = false;
        }
      });
    });
    Object.values(contracts).forEach(contract => {
      contractMarkets = [...contractMarkets, ...contract.market];
    });
    if (contractMarkets.find(market => market.id == market_id)) {
      has = false;
    }
    return has;
  };

  console.log("markets", markets);
  console.log(columnsMarket);
  return (
    <MaterialTable
      tableRef={tableRef}
      columns={columnsMarket}
      data={Object.values(markets)}
      title=""
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        search: false,
        addRowPosition: "first",
        headerStyle: {
          fontSize: "16px",
          fontWeight: "bold"
        }
      }}
      detailPanel={[
        {
          //icon: "add_circle",
          tooltip: <IntlMessages id="add.country" />,
          render: rowData => {
            return isAuthView ? (
              <MarketCountries rowData={rowData} isAuthUpdate={isAuthUpdate} />
            ) : null;
          }
        }
      ]}
      onRowClick={(event, rowData, togglePanel) => {
        return isAddClick ? togglePanel() : null;
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: "You are not authorized"
            }
      ]}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        )
      }}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
              onClick={clickEdit}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Edit: () => (
          <ModeEditIcon onClick={clickEdit} disabled={!isAuthUpdate} />
        ),
        Clear: () => <ModeCloseIcon style={{ color: "red" }} />
      }}
      editable={{
        deleteTooltip: row =>
          isAuthDelete ? "Delete" : "You are not authorized",

        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  setIsAddClick(true);
                  if (newData.code && newData.name) {
                    if (
                      Object.values(markets).filter(
                        market => market.code === newData.code.toUpperCase()
                      ).length == 0 &&
                      Object.values(markets).filter(
                        market => market.name === newData.name.toUpperCase()
                      ).length == 0
                    ) {
                      const addData = async () => {
                        newData.code = newData.code.toUpperCase();
                        newData.name = newData.name.toUpperCase();
                        //set is Adding to true to get inside of Useffect Control
                        isAdding.current = true;
                        await dispatch(addMarketService(newData));
                        resolve();
                      };
                      // Call addData function and set isAdding to false after finishing adding the data
                      addData().then(() => {
                        isAdding.current = false;
                      });
                    } else {
                      MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: intl.formatMessage({
                          id: "market.already.exists"
                        })
                      });
                      reject();
                    }
                  } else {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({ id: "please.fill.all.fields" }),
                      "error"
                    );
                    reject();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setIsAddClick(true);
              console.log(newData);
              if (
                oldData.code !== newData.code ||
                oldData.name !== newData.name
              ) {
                if (newData.code && newData.name) {
                  if (
                    Object.values(markets).filter(
                      market => market.code === newData.code.toUpperCase()
                    ).length == 0 ||
                    Object.values(markets).filter(
                      market => market.name === newData.name.toUpperCase()
                    ).length == 0
                  ) {
                    const data = {
                      id: newData.id,
                      code: newData.code.toUpperCase(),
                      name: newData.name.toUpperCase()
                    };
                    //Call expand function after updating the data
                    const updateData = async () => {
                      isUpdating.current = true;
                      await dispatch(updateMarketService(data));
                      expandRowDetail(
                        newData.code.toUpperCase(),
                        newData.name.toUpperCase()
                      );
                      resolve();
                    };
                    // Call updateData function and set isUpdating to false after finishing updating the data
                    updateData().then(() => {
                      isUpdating.current = false;
                    });
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: (
                        <>
                          <IntlMessages id="market" />
                        </>
                      )
                    });
                    reject();
                  }
                } else {
                  MySwal.fire(
                    "Oops...",
                    <IntlMessages id="please.fill.in.all.fields" />,
                    "error"
                  );
                  reject();
                }
              } else {
                resolve();
              }
            }, 1000);
          }),
        onRowUpdateCancelled: () => {
          setIsAddClick(true);
        },
        onRowAddCancelled: () => {
          setIsAddClick(true);
        },
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              if (hasUseMarket(oldData.id)) {
                dispatch(deleteMarketService(oldData.id));
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Market in use!"
                });
              }

              resolve();
            }, 1000);
          })
      }}
    />
  );
}

export default Market;

function MarketCountries(props) {
  console.log(props);
  const dispatch = useDispatch();
  const classes = useStyles();
  const countryState = useSelector(state => state.destinations.allCountries);
  const regionState = useSelector(state => state.destinations.regions);
  const [selectedCountries, setSelectedCountries] = useState(
    props.rowData.countries == null
      ? []
      : () => {
          let objectCountries = props.rowData.countries.map(country => {
            return countryState.find(
              countryInState => countryInState.id === country
            );
          });
          return objectCountries;
        }
  );

  regionState.map(region => {
    countryState.map(country => {
      if (country.region_id == region.id) {
        country["region_name"] = region.name;
      }
    });
  });

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: item => ({
            disabled:
              listDisabled || item.disabled || props.isAuthUpdate == false
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },

          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedCountry: listSelectedKeys,
          selectedRowKeys: listSelectedKeys
        };
        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{
              pointerEvents: listDisabled ? "none" : undefined
            }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              }
            })}
          />
        );
      }}
    </Transfer>
  );
  const transferData = countryState.map((item, i) => ({
    key: item.id.toString(),
    title: item.name,
    tag: item.region_name,
    item: item
  }));
  const rightTable = selectedCountries.map((item, i) => item.id.toString());

  const leftTableColumns = [
    {
      dataIndex: "title",
      title: "COUNTRY"
    },
    {
      dataIndex: "tag",
      title: "CONTINENT",
      render: tag => <Tag>{tag}</Tag>
    }
  ];
  const rightTableColumns = [
    {
      dataIndex: "title",
      title: "COUNTRY"
    },
    {
      dataIndex: "tag",
      title: "CONTINENT",
      render: tag => <Tag>{tag}</Tag>
    }
  ];
  const [targetKeys, setTargetKeys] = useState(rightTable);
  const [flag, setFlag] = useState(false);
  const onChange = nextTargetKeys => {
    let selectCountry = [];
    transferData.map(transfer => {
      if (nextTargetKeys.includes(transfer.key)) {
        selectCountry = [...selectCountry, transfer.item];
      }
    });
    setSelectedCountries(selectCountry);
    setTargetKeys(nextTargetKeys);
    setFlag(true);
  };
  function saveAll() {
    const row = props.rowData;
    const countryIds = selectedCountries.map(country => country.id);
    row.countries = JSON.stringify(countryIds);

    dispatch(updateMarketService(row));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} style={{ marginTop: "20px" }}>
          <TableTransfer
            dataSource={transferData}
            targetKeys={targetKeys}
            showSearch={true}
            onChange={e => props.isAuthUpdate == true && onChange(e)}
            filterOption={(inputValue, item) =>
              item.title.toLowerCase().indexOf(inputValue.toLowerCase()) !==
                -1 ||
              item.tag.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
            titles={["COUNTRY SELECTION", "SELECTED COUNTRIES"]}
          />
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={themeButton}>
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={saveAll}
              disabled={flag == true || props.isAuthUpdate ? false : true}
              color="primary"
              style={{
                marginLeft: "7rem",
                marginTop: "280px"
              }}
            >
              <IntlMessages id="save" />
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
