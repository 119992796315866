import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getStopSale, addStopSale } from "redux/actions/stopSale";
import Swal from "sweetalert2";
import axios from "./axios";
import axiosBot from "./axiosBot";
import axiosLogger from "./axiosLogger";
import { apihost } from "./axiosBot";

/** PROXOLAB LOGGER * */
import sendLogService from "./proxolabLogger/Logger.js";
import { GET_STOP_SALE } from "redux/actions/types";
/** PROXOLAB LOGGER * */

export const getStopSaleService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("stopsale", {
      params: {
        hotelId: id
      }
    })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addStopSaleService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("stopsale", data)
    .then(res => {
      dispatch(addStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const getStopSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/lastStopSales`,
    data: data
  })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getAllStopSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allStopSales`,
    data: data
  })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const sendStopSaleServiceSH = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/SH", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Sun Hotel. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceDN = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/DN", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for DNata. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServicePP = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/PP", data)
    .then(res => {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (res.status === 200) {
        if (res.data.hasOwnProperty("message") && res.data.message != "") {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(res, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Peakpoint. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceW2M = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/W2M", data)
    .then(res => {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (res.status === 200) {
        if (res.data.hasOwnProperty("message") && res.data.message != "") {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(res, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for World 2 Meet. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendStopSaleServicePT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/PT", data)
    .then(res => {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (res.status === 200) {
        if (res.data.hasOwnProperty("message") && res.data.message != "") {
          Swal.fire({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000
          });
        }
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(res, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Prime Travel. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendStopSaleServiceWB = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/WB", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Welcomebeds. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceLMX = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/LMX", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for LMX. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceFOR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/FOR", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for Foryou. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceSIT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/SIT", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        // Swal.fire({
        //   toast: "true",
        //   position: "top-end",
        //   icon: "success",
        //   title: "Your work has been saved successfully",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        dispatch(fetchSuccess());
        // sendLogService.stopSaleLogs(response, data);
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Sitelia. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceWEBBEDS = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/WEBBEDS", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for Webbeds. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceLMT = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/LMT", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for LMT. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendStopSaleServiceJUMBO = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/JUMBO", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for Jumbo. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

export const sendStopSaleServiceXPUR = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/XPUR", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title: "Operation could not be performed for Xpur. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};
export const sendStopSaleServiceIVC = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosBot.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  // dispatch(fetchStart());
  data["endpoint"] = apihost;
  return axiosBot
    .post("stopsales/IVC", data)
    .then(function(response) {
      dispatch(
        getStopSaleMongoDBService({
          userEmail: data.user.email,
          hotelId: data.hotelId
        })
      );
      if (response.status === 200) {
        dispatch(fetchSuccess());
      }
    })
    .catch(function(error) {
      Swal.fire({
        toast: "true",
        position: "top-end",
        icon: "error",
        title:
          "Operation could not be performed for IVECTOR. Please try again!",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch(fetchError());
    });
};

const logDataForState = data => {
  data.operators.forEach(operator => {
    return (operator["status"] = 100);
  });
  const temp = {
    ...temp,
    dates: data["dates"],
    rooms: data["rooms"],
    hotelId: data["hotelId"],
    operators: data.operators
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date()
  };
  return newData;
};
