import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getStatus } from 'redux/actions/restrictionStatus';
import axios from './axios';

export const getStatusService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('status')
    .then(res => {
      dispatch(getStatus(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
