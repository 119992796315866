import moment from "moment";
import React from "react";
import { PeriodHeader } from "./getPeriods";

const getSpecialDiscount = (data) => {
  let filteredData = data.map((row) => {
    return Object.values(row);
  });

  filteredData = filteredData.filter((row) => row[0] !== "");

  const specialDiscountIndex = filteredData.findIndex((row, index) => {
    return (
      row[0] !== undefined &&
      row[0] !== null &&
      row[0] === "S  P  E  C  I  A  L     D  I S  C  O  U  N  T  S"
    );
  });
  if (specialDiscountIndex === -1) return null;

  const contributionsIndex = filteredData.findIndex((row, index) => {
    return row[0]?.includes(
      "C   O   N   T   R   I   B   U   T   I   O   N   S"
    );
  });

  const accountingNotes = filteredData.findIndex((row, index) => {
    return row[0]?.includes(
      "N  O  T  E  S    F  O  R    A  C  C  O  U  N  T  I  N  G"
    );
  });

  const galaMeals = filteredData.findIndex((row, index) => {
    return row[0]?.includes("G A L A  M E A L S");
  });

  const lowestIndex = [contributionsIndex, accountingNotes, galaMeals]
    .filter((item) => item !== -1)
    .sort((a, b) => a - b)[0];

  const specialDiscount = filteredData.filter((row, index) => {
    return (
      index > specialDiscountIndex + 1 && index < lowestIndex && row.length > 1
    );
  });

  return {
    specialDiscount,
    specialDiscountIndex,
    filteredData,
  };
};

export default getSpecialDiscount;
