import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import axios from "./axios";
import { addMeetPointExcel } from "redux/actions/excel";
import {
  addContract,
  addCotractRoom,
  addMeetingPointContract,
} from "redux/actions/contract";
import { addPeriod } from "redux/actions/periods";
import { SET_SELECTED_CONTRACT } from "redux/actions/types";
import { addHotel, addMeetPointHotel } from "redux/actions/hotels";
import { addMeetingPointSeason, addSeason } from "redux/actions/season";
import { addMarket, addMeetpointMarket } from "redux/actions/market";

export const addMeetingPointExcel = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("addMeetPointExcel", data)
    .then((res) => {
      dispatch(addMeetingPointSeason(res));
      return res;
    })
    .then((res) => {
      dispatch({
        type: SET_SELECTED_CONTRACT,
        payload: res.data.data.contract.id,
      });
      dispatch(addMeetingPointContract(res));
      dispatch(fetchSuccess());
      return res;
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMeetingPointRooms = async (data) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const response = await axios.post("getMeetingPointRooms", data);

  return response;
};

export const addMeetingPointEarlyBookingsService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("addMeetingPointEarlyBookings", data)
    .then((res) => {
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

export const getMatchedHotel = async (id) => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const response = await axios.post("getMatchedHotel", { id });
  return response.data.data;
};
