import {
  GET_PRIORITY,
  ADD_PRIORITY,
  UPDATE_PRIORITY,
  DELETE_PRIORITY
} from "../actions/types";

const initialState = {
  priority: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIORITY:
      return {
        ...state,
        priority: action.payload.priority ? action.payload.priority : []
      };
    case ADD_PRIORITY:
      return {
        priority: action.payload
      };
    case UPDATE_PRIORITY:
      return {
        priority: action.payload
      };
    case DELETE_PRIORITY:
      return {
        priority: action.payload
      };

    default:
      return state;
  }
};
